import React, { FC, useEffect, useMemo } from 'react';
import { IShiftPlanComponentProps } from './IShiftPlanComponentProps';
import {
    CButton,
    CCard,
    CDatePicker,
    CPicker,
    CText,
    CTextInput,
    WorkplaceZipCodeTypeahead,
} from '../../../components';
import {
    convertWeekAndYearToDate,
    getWeekNumber,
} from '../../../utilities/functions';
import { ECollections, EShiftPlannerType } from '../../../enums';
import { actionMessages } from '../../../utilities/messages';
import { shiftPlanMessages } from '../shiftPlan.messages';
import { shiftPlanTypeMessages } from '../shiftPlanType.messages';
import { useFormat } from '../../../utilities/intl';
import { View } from 'react-native';
import { useStyle } from '../../../utilities/styles';
import { useFireBase } from '../../../utilities/firebase';
import { MShiftPlan } from '../../../models';

export const PreSelect: FC<IShiftPlanComponentProps> = ({
    shiftPlan,
    setPlan,
    goForward,
}) => {
    const { userWorkplaces, getDataIndex } = useFireBase();
    const format = useFormat();
    const style = useStyle();
    /**
     * memoized current workplace
     */
    const currentWorkplace = useMemo(
        () =>
            userWorkplaces.find((wp) => wp.documentId === shiftPlan.documentId),
        [shiftPlan, userWorkplaces],
    );
    /**
     * effect to set workplaceid if unset
     */
    useEffect(() => {
        if (userWorkplaces.length) {
            setPlan((prev) => {
                return prev.workplaceId
                    ? prev
                    : new MShiftPlan({
                          ...prev,
                          workplaceId: userWorkplaces[0].documentId,
                      });
            });
        }
    }, [userWorkplaces]);
    /**
     * effect to load most recent plan for current workplace
     */
    useEffect(() => {
        if (shiftPlan.workplaceId) {
            getDataIndex(
                `${ECollections.workplaces}/${shiftPlan.workplaceId}/${ECollections.shiftPlans}`,
                {
                    orderBy: 'createdOn',
                    filter: [{ field: 'type', value: shiftPlan.type }],
                    asc: false,
                    limit: 1,
                },
            ).then((r) => {
                const res = r as MShiftPlan[];
                if (res.length === 1) {
                    const next = new MShiftPlan(res[0]);
                    if (next.createdOn < shiftPlan.createdOn) {
                        setPlan((prev) => {
                            return new MShiftPlan({
                                ...prev,
                                groups: next.groups,
                                workers: next.workers,
                            });
                        });
                    }
                }
            });
        }
    }, [shiftPlan]);
    /**
     * render
     */
    return (
        <View>
            <CCard>
                <CText
                    secondaryHeadline
                    message={shiftPlanMessages.configurePlan}
                />
            </CCard>
            <CCard>
                <CPicker
                    title={shiftPlanMessages.pickType}
                    value={shiftPlan.type}
                    values={Object.values(EShiftPlannerType).map((t) => ({
                        value: t,
                        label: format(shiftPlanTypeMessages[t]),
                    }))}
                    onChange={(v) => setPlan((prev) => ({ ...prev, type: v }))}
                />
                <WorkplaceZipCodeTypeahead
                    currentWorkplace={currentWorkplace}
                    onChangeWorkplace={(wp) =>
                        setPlan(
                            (prev) =>
                                new MShiftPlan({
                                    ...prev,
                                    workplaceId: wp.documentId,
                                }),
                        )
                    }
                />
                <CDatePicker
                    title={shiftPlanMessages.pickWeek}
                    value={convertWeekAndYearToDate(
                        shiftPlan.year,
                        shiftPlan.week,
                    )}
                    onChange={(v) =>
                        setPlan((prev) => ({
                            ...prev,
                            week: getWeekNumber(v),
                            year: v.getFullYear(),
                        }))
                    }
                />
                <CTextInput
                    label={shiftPlanMessages.planTitle}
                    value={shiftPlan.name}
                    onChangeText={(t) =>
                        setPlan((prev) => ({ ...prev, name: t }))
                    }
                    autoExtend
                />
            </CCard>
            <View style={[style.horizontal, { justifyContent: 'center' }]}>
                <CButton title={actionMessages.continue} onPress={goForward} />
            </View>
        </View>
    );
};
