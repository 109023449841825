import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { View } from 'react-native';
import { IGroupExtensionProps } from '../IShiftPlanComponentProps';
import { MShiftGroupChildCare } from '../../../../models';
import { useStyle } from '../../../../utilities/styles';
import { dayMessages, generalMessages } from '../../../../utilities/messages';
import { CText, CTextInput } from '../../../../components';
import { childcareShiftPlanMessages } from './childcareShiftPlan.messages';

export const ChildCareGroupExtension: FC<IGroupExtensionProps> = ({
    shiftPlan,
    setPlan,
    gIndex,
}) => {
    const style = useStyle();

    const group = useMemo(
        () => new MShiftGroupChildCare({ ...shiftPlan.groups[gIndex] }),
        [shiftPlan],
    );

    const onChangeGroup = useCallback(
        (next: Partial<MShiftGroupChildCare>, index: number) =>
            setPlan((prev) => {
                const nextGroups = prev.groups.map(
                    (g) => new MShiftGroupChildCare(g),
                );
                nextGroups.splice(index, 1, { ...nextGroups[index], ...next });
                return { ...prev, groups: nextGroups };
            }),
        [],
    );

    const changeDay = useCallback(
        (
            next: Partial<{
                day: number;
                to: number;
                from: number;
                preTime: number;
                postTime: number;
            }>,
            groupIndex: number,
            dayIndex: number,
        ) => {
            const nextActiveDays = [
                ...shiftPlan.groups[groupIndex].activeDays,
            ].map((v) => ({
                preTime: 0,
                postTime: 0,
                ...v,
            }));
            const i = nextActiveDays.findIndex((d) => d.day === dayIndex);
            nextActiveDays[i] = {
                ...nextActiveDays[i],
                ...next,
            };
            onChangeGroup(
                {
                    activeDays: nextActiveDays,
                },
                groupIndex,
            );
        },
        [shiftPlan],
    );
    /**
     * effect to set values based on kids values if incorrect
     */
    useEffect(() => {
        // TODO:
    }, [shiftPlan]);

    return (
        <View>
            <CText
                secondaryHeadline
                message={childcareShiftPlanMessages.borderTimes}
            />
            <View style={style.horizontalSpaced}>
                {Object.keys(dayMessages)
                    .filter((v) => !v.includes('Short'))
                    .map((key, i) => {
                        const day = group.activeDays.find((d) => d.day === i);
                        return (
                            <View
                                key={key}
                                style={[style.flex1, style.horizontalPadded]}
                            >
                                {day && (
                                    <>
                                        <CTextInput
                                            label={
                                                childcareShiftPlanMessages.preBorderTime
                                            }
                                            value={`${day.preTime || 0}`}
                                            onChangeText={(t) =>
                                                !isNaN(+t) &&
                                                changeDay(
                                                    { preTime: +t },
                                                    gIndex,
                                                    i,
                                                )
                                            }
                                            unit={generalMessages.minutes}
                                            length={2}
                                        />
                                        <CTextInput
                                            label={
                                                childcareShiftPlanMessages.postBorderTime
                                            }
                                            value={`${day.postTime || 0}`}
                                            onChangeText={(t) =>
                                                !isNaN(+t) &&
                                                changeDay(
                                                    { postTime: +t },
                                                    gIndex,
                                                    i,
                                                )
                                            }
                                            unit={generalMessages.minutes}
                                            length={2}
                                        />
                                    </>
                                )}
                            </View>
                        );
                    })}
            </View>
            <CText
                secondaryHeadline
                message={childcareShiftPlanMessages.childInfos}
            />
            <CTextInput
                label={childcareShiftPlanMessages.kidsUnder3}
                value={`${group.kidsUnder3 || 0}`}
                onChangeText={(t) =>
                    !isNaN(+t) && onChangeGroup({ kidsUnder3: +t }, gIndex)
                }
            />
            <CTextInput
                label={childcareShiftPlanMessages.kidsOver3}
                value={`${group.kidsOver3 || 0}`}
                onChangeText={(t) =>
                    !isNaN(+t) && onChangeGroup({ kidsOver3: +t }, gIndex)
                }
            />
            <CTextInput
                label={childcareShiftPlanMessages.kidsInSchool}
                value={`${group.kidsInSchool || 0}`}
                onChangeText={(t) =>
                    !isNaN(+t) && onChangeGroup({ kidsInSchool: +t }, gIndex)
                }
            />
        </View>
    );
};
