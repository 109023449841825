import React, { FC } from 'react';
import { View } from 'react-native';
import { CButton } from '../../components';
import { useFireBase } from '../../utilities/firebase';
import { actionMessages } from '../../utilities/messages';
import { useStyle } from '../../utilities/styles';

export const Logout: FC = () => {
    const { logOut } = useFireBase();
    const style = useStyle();
    return (
        <View
            style={[
                style.horizontalSpaced,
                style.verticalHeavyPadded,
                { width: '100%' },
            ]}
        >
            <CButton
                icon="logout"
                onPress={logOut}
                title={actionMessages.logout}
                danger
            />
        </View>
    );
};
