/* eslint-disable react/no-unescaped-entities */
/**
 * Web platform entry point
 */

import React from 'react';
// @ts-ignore
import { render } from 'react-native';

import App from './App';

render(
    <React.Fragment>
        <App />
    </React.Fragment>,
    // @ts-ignore
    document.getElementById('root'),
);
