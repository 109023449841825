import { MAddress } from '../../models';
import g from 'react-geocode';
interface IAdrComponent {
    long_name: string;
    short_name: string;
    types: string[];
}
/**
 * converts lat lng to an address with google geocoding api
 * @param lat latitute
 * @param lng longitude
 * @param language language the address should be in
 * @returns address in given language
 */
export async function fromLatLngToAddress(
    lat: number,
    lng: number,
    language: 'en' | 'de' = 'en',
): Promise<MAddress> {
    g.setApiKey('AIzaSyBBVSSv6R3jOeuwSwWVE_ElTHRLqwjibgw');
    g.setLanguage(language);
    g.setLocationType('ROOFTOP');
    return new Promise((resolve) => {
        g.fromLatLng(`${lat}`, `${lng}`).then(
            (response) => {
                const addressComponents: IAdrComponent[] =
                    response.results[0].address_components;
                const country = addressComponents.find((component) =>
                    component.types.includes('country'),
                )?.long_name;
                const city = addressComponents.find((component) =>
                    component.types.includes('locality'),
                )?.long_name;
                const zipCode = addressComponents.find((component) =>
                    component.types.includes('postal_code'),
                )?.long_name;
                const street = addressComponents.find((component) =>
                    component.types.includes('route'),
                )?.long_name;
                const number = addressComponents.find((component) =>
                    component.types.includes('street_number'),
                )?.long_name;
                const address = new MAddress({
                    city,
                    zipCode,
                    street,
                    number,
                    country,
                });
                resolve(address);
            },
            (error) => {
                console.error(error);
            },
        );
    });
}
/**
 * converts an address to lat lng with google geocoding api
 * @param address address to convert
 * @param language language the address is in
 * @returns lat lng
 */
export async function fromAddressToLatLng(
    address: string,
    language: 'en' | 'de' = 'en',
): Promise<{ lat: number; lng: number }> {
    g.setApiKey('AIzaSyBBVSSv6R3jOeuwSwWVE_ElTHRLqwjibgw');
    g.setLanguage(language);
    g.setLocationType('ROOFTOP');
    return new Promise((resolve, reject) => {
        g.fromAddress(address).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;

                resolve({ lat, lng });
            },
            (error) => {
                console.error(error);
                reject();
            },
        );
    });
}
/**
 * calculates the distance between two points in km
 * @param lat1 lat of point 1
 * @param lng1 lng of point 1
 * @param lat2 lat of point 2
 * @param lng2 lng of point 2
 * @returns// Distance in km
 */
export function getDistanceFromLatLonInKm(
    lat1: number,
    lng1: number,
    lat2: number,
    lng2: number,
): number {
    function deg2rad(deg: number) {
        return deg * (Math.PI / 180);
    }
    const earthRadius = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lng2 - lng1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) *
            Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return earthRadius * c;
}
