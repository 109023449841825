import React, { FC, useMemo, useState } from 'react';
import { Image, Platform, View } from 'react-native';
import { assets, getAsset } from '../../../assets';
import { useStyle } from '../../../utilities/styles';
import { imageSize } from '../../../utilities/styles/imageSize';
import { useDimensions } from '../../../utilities/hooks/useDimensions';
import { useEnvironment } from '../../../utilities/contexts';
import { EEnvironment } from '../../../enums';

interface ICImageProps {
    image: keyof typeof assets;
    uri?: string;
    maxWidth?: number;
    maxHeight?: number;
    relativeWidth?: number;
    fixedHeight?: number;
}

export const CImage: FC<ICImageProps> = ({
    image,
    uri,
    maxWidth,
    maxHeight,
    relativeWidth,
    fixedHeight,
}) => {
    const style = useStyle();
    const { environment } = useEnvironment();
    const { width: screenWidth } = useDimensions();
    const [dims, setDims] = useState({ height: 0, width: 0 });
    /**
     * memoized source
     */
    const source = useMemo(() => {
        const a = uri || getAsset(image, environment);
        if (Platform.OS === 'ios' || Platform.OS === 'android') {
            const properObject = Image.resolveAssetSource(a);
            if (properObject && properObject.uri) {
                Image.getSize(
                    properObject.uri,
                    (width, height) => {
                        setDims({ width, height });
                    },
                    (e) => console.log(e),
                );
            }
        } else {
            Image.getSize(
                a,
                (width, height) => {
                    setDims({ width, height });
                },
                (e) => console.log(e),
            );
        }
        return a;
    }, [image, environment, uri]);
    /**
     * memoized width x height after ratio
     */
    const { width, height } = useMemo(() => {
        if (!dims.height || !dims.width) {
            return dims;
        }
        const mW = maxWidth || 500;
        const mH = maxHeight || Number.MAX_SAFE_INTEGER;
        const ratio = dims.height / dims.width;
        if (fixedHeight) {
            return { width: fixedHeight / ratio, height: fixedHeight };
        } else {
            return imageSize(screenWidth, relativeWidth || 0.7, ratio, mW, mH);
        }
    }, [maxWidth, dims, relativeWidth]);

    return (
        <View style={style.horizontalSpaced}>
            <Image
                resizeMode="contain"
                source={source}
                style={{
                    width,
                    height,
                }}
            />
        </View>
    );
};
