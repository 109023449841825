import { defineMessages } from 'react-intl';

const scope = 'medic.doctor.position.';
/**
 * position names
 * is used for position picker
 */
export const doctorPositionMessages = defineMessages({
    physicianInApprobation: {
        id: scope + 'physicianInApprobation',
        defaultMessage: 'Physician in approbation',
    },
    assistantphysician: {
        id: scope + 'assistantphysician',
        defaultMessage: 'Assistant Physician',
    },
    specialistphysician: {
        id: scope + 'specialistphysician',
        defaultMessage: 'Specialist Physician',
    },
    seniorphysician: {
        id: scope + 'seniorphysician',
        defaultMessage: 'Senior Physician',
    },
    chiefphysician: {
        id: scope + 'chiefphysician',
        defaultMessage: 'Chief Physician',
    },
});
