import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useFireBase } from '../../../utilities/firebase';
import { ECollections } from '../../../enums';
import { MSupportRequest } from '../../../models';
import { CButton, CText } from '../../../components';
import { useStyle } from '../../../utilities/styles';
import { generalMessages } from '../../../utilities/messages';
import { ScrollProvider } from '../../../utilities/contexts';
import { SupportRequestRow } from './components/SupportRequestRow';
import { supportRequestMessages } from '../supportRequest.messages';
/**
 * view for super users to list support requests and their respective chats
 * @returns
 */
export const SupportRequestList: FC = () => {
    // global state
    const { getDataIndex } = useFireBase();
    const style = useStyle();
    // local state
    const [requests, setRequests] = useState<MSupportRequest[]>([]);
    const [mode, setMode] = useState<'open' | 'closed' | 'active'>('open');
    /**
     * effect to load requests
     */
    useEffect(() => {
        getDataIndex(ECollections.supportRequests, {
            filter: [{ field: 'state', value: mode }],
            orderBy: 'createdOn',
        }).then((response) => {
            setRequests((response as any[]).map((v) => new MSupportRequest(v)));
        });
    }, [mode]);
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={style.card}>
                <View style={[style.horizontalSplit, style.verticalPadded]}>
                    <CText
                        message={generalMessages.supportRequests}
                        headline
                        bold
                    />
                </View>
                <View style={style.horizontalWrap}>
                    <CButton
                        title={supportRequestMessages.open}
                        onPress={() => setMode('open')}
                        minor={mode !== 'open'}
                        small
                        style={style.horizontalPadded}
                    />
                    <CButton
                        title={supportRequestMessages.active}
                        onPress={() => setMode('active')}
                        minor={mode !== 'active'}
                        small
                        style={style.horizontalPadded}
                    />
                    <CButton
                        title={supportRequestMessages.closed}
                        onPress={() => setMode('closed')}
                        minor={mode !== 'closed'}
                        small
                        style={style.horizontalPadded}
                    />
                </View>
            </View>
            {requests.map((request) => (
                <SupportRequestRow request={request} key={request.documentId} />
            ))}
        </ScrollProvider>
    );
};
