export class MChat {
    public documentId = '';
    public participants: string[] = [];

    public editedOn = Date.now();
    public createdOn = Date.now();
    public closedOn = 0;

    public supportRequestId?: string;
    public contractId?: string;

    constructor(params: Partial<MChat> = {}) {
        Object.assign(this, params);
    }
}
