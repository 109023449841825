import React, { FC } from 'react';
import { Pressable, View } from 'react-native';
import { useStyle, useTheme } from '../../utilities/styles';
import { CIcon } from '../elements';

export const StarInput: FC<{
    stars: number;
    onChange: (next: number) => void;
}> = ({ stars, onChange }) => {
    const style = useStyle();
    const { theme } = useTheme();
    return (
        <View style={style.horizontal}>
            {[1, 2, 3, 4, 5].map((i) => (
                <Pressable onPress={() => onChange(i)} key={i}>
                    <CIcon
                        icon={stars >= i ? 'star' : 'star_outline'}
                        tint={
                            stars >= i ? theme.accentColor : theme.textMainColor
                        }
                        size={24}
                    />
                </Pressable>
            ))}
        </View>
    );
};
