import { defineMessages } from 'react-intl';

const scope = 'con.shiftPlan.';

export const shiftPlanMessages = defineMessages({
    createShiftPlanner: {
        id: scope + 'createShiftPlanner',
        defaultMessage: 'Create a new shift plan',
    },
    configurePlan: {
        id: scope + 'configurePlan',
        defaultMessage: 'Configure shift plan',
    },
    configureWorkers: {
        id: scope + 'configureWorkers',
        defaultMessage: 'Configure shift workers',
    },
    generateShiftPlan: {
        id: scope + 'generateShiftPlan',
        defaultMessage: 'Generate a shift plan',
    },
    noWorkers: {
        id: scope + 'noWorkers',
        defaultMessage: 'No shift workers',
    },
    noGroups: {
        id: scope + 'noGroups',
        defaultMessage: 'No workgroups',
    },
    configureGroups: {
        id: scope + 'configureGroups',
        defaultMessage: 'Configure workgroups',
    },
    addGroup: {
        id: scope + 'addGroup',
        defaultMessage: 'Add workgroup',
    },
    groupTitle: {
        id: scope + 'groupTitle',
        defaultMessage: 'Group Title',
    },
    group: {
        id: scope + 'group',
        defaultMessage: 'Workgroup',
    },
    workerCount: {
        id: scope + 'workerCount',
        defaultMessage: 'Simultanious worker in group',
    },
    noBreaks: {
        id: scope + 'noBreaks',
        defaultMessage: 'Group needs enough workers to be present',
    },
    worker: {
        id: scope + 'worker',
        defaultMessage: 'Worker',
    },
    workerTitle: {
        id: scope + 'workerTitle',
        defaultMessage: 'Worker name',
    },
    workerHoursPerWeek: {
        id: scope + 'workerHoursPerWeek',
        defaultMessage: 'Hours per week',
    },
    addWorker: {
        id: scope + 'addWorker',
        defaultMessage: 'Add worker',
    },
    planTitle: {
        id: scope + 'planTitle',
        defaultMessage: 'Shift plan title',
    },
    pickWeek: {
        id: scope + 'pickWeek',
        defaultMessage: 'Select week',
    },
    pickType: {
        id: scope + 'pickType',
        defaultMessage: 'Select type',
    },
    incomplete: {
        id: scope + 'incomplete',
        defaultMessage: 'Incomplete',
    },
});
