/**
 * type of employee transfer (new meaning)
 */
export enum EEmploymentType {
    fullTime = 'fullTime',
    partTime = 'partTime',
    selfEmployed = 'selfEmployed',
    freelance = 'freelance',
    contract = 'contract',
    internship = 'internship',
    apprenticeship = 'apprenticeship',
    civilServiceInternship = 'civilServiceInternship',
    lifetimeCivilServant = 'lifetimeCivilServant',
    coOp = 'coOp',
    workStudy = 'workStudy',
    // ! this enum is now solely used for the differentiation between direkt releases and leasing
    personnelLeasing = 'personnelLeasing',
    direct = 'direct',
}
