import React, { FC, useContext } from 'react';
import { useStyle } from '../../../../../utilities/styles';
import { View } from 'react-native';
import { CImage, CText, CButton } from '../../../../../components';
import { contractMessages } from '../../../contract.messages';
import { ContractContext } from '../ContractContext';

export const InReview: FC<{ creating?: boolean; noUndo?: boolean }> = ({
    creating,
    noUndo,
}) => {
    const style = useStyle();
    const { handleClose, handleUndo } = useContext(ContractContext);

    return (
        <View style={style.card}>
            <CImage image={'inReview'} />
            <CText
                centered
                headline
                message={
                    creating
                        ? contractMessages.beingCreated
                        : contractMessages.beingReviewed
                }
            />
            <View
                style={[
                    style.centeredItems,
                    style.verticalPadded,
                    {
                        alignItems: 'stretch',
                        marginHorizontal: 'auto',
                    },
                ]}
            >
                {!creating && !noUndo && (
                    <CButton
                        cy={'undo'}
                        title={contractMessages.undoNegotiation}
                        onPress={handleUndo}
                        warning
                    />
                )}
                <CButton
                    cy={'cancel'}
                    title={contractMessages.closeNegotiation}
                    onPress={handleClose}
                    danger
                />
            </View>
        </View>
    );
};
