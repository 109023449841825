import { defineMessages } from 'react-intl';

const scope = 'con.shiftPlan.childcare.';

export const childcareShiftPlanMessages = defineMessages({
    level1: {
        id: scope + 'level1',
        defaultMessage: 'Category 3',
    },
    level2: {
        id: scope + 'level2',
        defaultMessage: 'Category 2',
    },
    level3: {
        id: scope + 'level3',
        defaultMessage: 'Category 1',
    },
    level1Description: {
        id: scope + 'level1Description',
        defaultMessage: 'Trainee',
    },
    level2Description: {
        id: scope + 'level2Description',
        defaultMessage: 'Assistant',
    },
    level3Description: {
        id: scope + 'level3Description',
        defaultMessage: 'Teacher',
    },
    kidsUnder3: {
        id: scope + 'kidsUnder3',
        defaultMessage: 'Kids under or at the age of 3',
    },
    kidsOver3: {
        id: scope + 'kidsOver3',
        defaultMessage: 'Kids over the age of 3',
    },
    kidsInSchool: {
        id: scope + 'kidsInSchool',
        defaultMessage: 'Kids in school',
    },
    childInfos: {
        id: scope + 'childInfos',
        defaultMessage: 'Child infos',
    },
    borderTimes: {
        id: scope + 'borderTimes',
        defaultMessage: 'Border times',
    },
    preBorderTime: {
        id: scope + 'preBorderTime',
        defaultMessage: 'Pre time',
    },
    postBorderTime: {
        id: scope + 'postBorderTime',
        defaultMessage: 'Post time',
    },
});
