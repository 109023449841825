import { defineMessages } from 'react-intl';

const scope = 'con.agency.timeView.';

export const timeViewMessages = defineMessages({
    user: {
        id: scope + 'user',
        defaultMessage: 'User',
    },
    date: {
        id: scope + 'date',
        defaultMessage: 'Date',
    },
    start: {
        id: scope + 'start',
        defaultMessage: 'Start',
    },
    end: {
        id: scope + 'end',
        defaultMessage: 'End',
    },
    shiftLength: {
        id: scope + 'shiftLength',
        defaultMessage: 'Shift length (min)',
    },
    timeOnBreak: {
        id: scope + 'timeOnBreak',
        defaultMessage: 'Time on break (min)',
    },
    totalLength: {
        id: scope + 'totalLength',
        defaultMessage: 'Total length (h)',
    },
    noShiftsForSelection: {
        id: scope + 'noShiftsForSelection',
        defaultMessage: 'Could not find shifts for selection',
    },
});
