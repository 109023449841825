import React, { FC, useMemo } from 'react';
import { View } from 'react-native';
import { CLabel } from '../../../components';
import { EApplicationType, EContractStatus } from '../../../enums';
import { contractMessages } from '../contract.messages';
import { useFireBase } from '../../../utilities/firebase';
import { MContract } from '../../../models';
import { useTheme } from '../../../utilities/styles';
import { isEmployer, isPeasant } from '../../../utilities/auth';
import { day } from '../../../utilities/functions';

export const ContractState: FC<{
    contract: MContract;
}> = ({ contract }) => {
    const { userData } = useFireBase();
    const { theme } = useTheme();
    /**
     * tint of label to highlight states thjat need care
     */
    const tint = useMemo(() => {
        const statesForYer = [
            EContractStatus.creating,
            EContractStatus.review_yer,
            EContractStatus.accepted,
        ];
        const statesForYee = [
            EContractStatus.review_yee,
            EContractStatus.accepted,
        ];
        if (
            contract.status === EContractStatus.signed &&
            contract.from > Date.now() &&
            contract.from < Date.now() + day * 7 &&
            contract.type !== EApplicationType.default
        ) {
            return theme.warningColor;
        }
        if (
            contract.to < Date.now() &&
            contract.status === EContractStatus.signed
        ) {
            return theme.warningColor;
        }
        if (isEmployer(userData) && statesForYer.includes(contract.status)) {
            return theme.errorColor;
        } else if (
            isPeasant(userData) &&
            statesForYee.includes(contract.status)
        ) {
            return theme.errorColor;
        }
        return theme.accentColor;
    }, [contract, theme, userData]);
    /**
     * memoized status message since it is not a simple status translation
     */
    const statusMessage = useMemo(() => {
        if (isPeasant(userData)) {
            if (contract.status === EContractStatus.review_yee) {
                return contractMessages.toReview;
            } else if (contract.status === EContractStatus.review_yer) {
                return contractMessages.inReview;
            } else if (contract.status === EContractStatus.creating) {
                return contractMessages.inCreation;
            }
        } else if (isEmployer(userData)) {
            if (contract.status === EContractStatus.review_yee) {
                return contractMessages.inReview;
            } else if (contract.status === EContractStatus.review_yer) {
                return contractMessages.toReview;
            } else if (contract.status === EContractStatus.creating) {
                return contractMessages.toCreate;
            }
        }
        if (contract.status === EContractStatus.accepted) {
            return contractMessages.toSign;
        } else if (contract.status === EContractStatus.paid) {
            return contractMessages.paid;
        } else if (contract.status === EContractStatus.signed) {
            if (contract.type === EApplicationType.default) {
                return contractMessages.open;
            }
            if (contract.to < Date.now()) {
                return contractMessages.toFinish;
            } else if (Date.now() > contract.from) {
                return contractMessages.active;
            } else if (
                contract.from > Date.now() &&
                contract.from < Date.now() + day * 7
            ) {
                return contractMessages.aboutToStart;
            }
            return contractMessages.upcoming;
        } else if (contract.status === EContractStatus.completed) {
            return contractMessages.completed;
        }
        return contract.status;
    }, [contract, theme, userData]);
    /**
     * render
     */
    return (
        <View>
            {!!statusMessage && (
                <CLabel title={statusMessage} small color={tint} />
            )}
        </View>
    );
};
