import React, {
    createContext,
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { View } from 'react-native';
import { CButton, CPicker, CText, CTextInput } from '../../components';
import { CImage } from '../../components/elements/CImage';
import { ECollections, EUserType } from '../../enums';
import { MHelp } from '../../models/MHelp';
import { isSuperUser } from '../../utilities/auth';
import { ScrollProvider } from '../../utilities/contexts/Scroll';
import { useFireBase } from '../../utilities/firebase';
import { useFormat } from '../../utilities/intl';
import { LanguageContext } from '../../utilities/intl';
import { generalMessages } from '../../utilities/messages/general.messages';
import { useStyle } from '../../utilities/styles';
import { HelpRow } from './components/HelpRow';
import { helpCenterMessages } from './helpCenter.messages';
import { useEnvironment } from '../../utilities/contexts';
import { MSupportRequest } from '../../models';
import { useDialog } from '../../utilities/dialog';
import { supportRequestMessages } from '../SupportRequest/supportRequest.messages';
import { useNavigate } from '../../utilities/routing';
import { IFilter } from '../../utilities/firebase/store';
/**
 * context for dedicated child views to avoid prop drilling
 */
export const HelpContext = createContext(
    {} as {
        helps: MHelp[];
        reload: () => void;
    },
);
/**
 * help center container
 * @returns
 */
export const HelpCenter: FC = () => {
    const style = useStyle();
    const navigate = useNavigate();
    const format = useFormat();
    const dialog = useDialog();
    const { environment } = useEnvironment();
    const { userData, getDataIndex, post } = useFireBase();
    const { language } = useContext(LanguageContext);
    const [helps, setHelps] = useState<MHelp[]>([]);
    const [sValue, setSValue] = useState('');
    const [currentGroup, setCurrentGroup] = useState(EUserType.user);
    const [supportRequest, setSupportRequest] = useState<MSupportRequest>();
    const [reload, setReload] = useState(true);
    /**
     * memoized helps filtered after search query
     */
    const filteredHelps = useMemo(() => {
        if (sValue) {
            const lowerCaseSValue = sValue.toLowerCase();
            return helps.filter(
                (h) =>
                    h.title.toLowerCase().includes(lowerCaseSValue) ||
                    h.message.toLocaleLowerCase().includes(lowerCaseSValue),
            );
        } else {
            return helps.filter((h) => !h.parent);
        }
    }, [helps, sValue]);
    /**
     * callback to initiate a new support request
     */
    const handleNewSupportRequest = useCallback(async () => {
        let reason = '';
        if (
            await dialog({
                message: supportRequestMessages.openMessage,
                title: supportRequestMessages.openTitle,
                textInputs: [
                    {
                        id: 'reason',
                        title: supportRequestMessages.reasonInputTitle,
                    },
                ],
                buttons: [
                    {
                        text: supportRequestMessages.open,
                        onPress: (inputs) => {
                            const reasonInput = inputs?.find(
                                (i) => i.id === 'reason',
                            );
                            if (reasonInput) {
                                reason = reasonInput.value;
                            }
                        },
                        disabled: (inputs) => {
                            const reasonInput = inputs?.find(
                                (i) => i.id === 'reason',
                            );
                            return !(reasonInput && reasonInput.value);
                        },
                    },
                ],
            })
        ) {
            const next = new MSupportRequest({
                userId: userData.documentId,
                createdOn: Date.now(),
                reason,
            });
            const postResult = await post(ECollections.supportRequests, next);

            if (postResult) {
                setSupportRequest({ ...next, documentId: postResult.id });
            }
        }
    }, [userData]);
    /**
     * effect to load helpy
     */
    useEffect(() => {
        const filter: IFilter[] = [
            { field: 'environment', value: environment },
            { field: 'lang', value: language },
        ];
        if (!isSuperUser(userData)) {
            filter.push({
                field: 'groups',
                value: userData.type,
                operator: 'array-contains',
            });
        }
        getDataIndex(ECollections.helps, {
            filter,
        }).then((responses) => {
            setHelps(
                (responses as MHelp[])
                    .map((r) => new MHelp(r))
                    .sort((a, b) => b.order - a.order),
            );
            setReload(false);
        });
    }, [reload, environment]);
    /**
     * effect to load support request
     */
    useEffect(() => {
        if (userData) {
            getDataIndex(ECollections.supportRequests, {
                filter: [{ field: 'userId', value: userData.documentId }],
            }).then((responses) => {
                const sR = (responses as MSupportRequest[]).map(
                    (r) => new MSupportRequest(r),
                );
                const activeSR = sR.filter((v) => v.state !== 'closed');
                if (activeSR.length) {
                    setSupportRequest(activeSR[0]);
                }
            });
        }
    }, [userData]);
    /**
     * render
     */
    return (
        <HelpContext.Provider value={{ helps, reload: () => setReload(true) }}>
            <ScrollProvider style={style.paddedScrollableMainView}>
                <View style={[style.card]}>
                    <CText bold headline>
                        {format(generalMessages.helpCenter)}
                    </CText>
                </View>
                <View style={style.card}>
                    <CImage image={'help'} />
                    <View style={style.verticalPadded}>
                        <CTextInput
                            value={sValue}
                            onChangeText={setSValue}
                            autoExtend
                            placeholder={format(
                                helpCenterMessages.searchPlaceholder,
                            )}
                        />
                    </View>
                </View>
                {filteredHelps.map((help) => (
                    <HelpRow key={help.documentId} item={help} />
                ))}
                {isSuperUser(userData) ? (
                    <View style={style.horizontalSpaced}>
                        <View style={style.horizontal}>
                            <CPicker
                                values={Object.values(EUserType)
                                    .filter((v) => v !== EUserType.default)
                                    .map((v) => ({
                                        label: generalMessages[v],
                                        value: v,
                                    }))}
                                value={currentGroup}
                                onChange={(g: EUserType) => {
                                    if (!g) {
                                        return;
                                    }
                                    setCurrentGroup(g);
                                }}
                            />
                            <CButton
                                icon="plus"
                                title={'create new'}
                                onPress={() => {
                                    post(
                                        ECollections.helps,
                                        new MHelp({
                                            lang: language,
                                            environment,
                                            order:
                                                (helps[helps.length - 1]
                                                    ?.order || 200) - 1,
                                            groups: [currentGroup],
                                        }),
                                    ).then(() => setReload(true));
                                }}
                            />
                        </View>
                    </View>
                ) : !supportRequest ? (
                    <View style={[style.card, style.horizontalSpaced]}>
                        <View style={style.verticalPadded}>
                            <CText
                                message={helpCenterMessages.stillNeedHelp}
                                centered
                            />
                            <CButton
                                title={supportRequestMessages.openRequest}
                                onPress={handleNewSupportRequest}
                                transparent
                                minor
                            />
                        </View>
                    </View>
                ) : supportRequest.chatId ? (
                    <View style={[style.card, style.horizontalSpaced]}>
                        <View style={style.verticalPadded}>
                            <CText
                                message={helpCenterMessages.stillNeedHelp}
                                centered
                            />
                            <CButton
                                title={helpCenterMessages.talkToYourAgent}
                                onPress={() => {
                                    navigate('/chat/' + supportRequest.chatId);
                                }}
                                transparent
                                minor
                            />
                        </View>
                    </View>
                ) : (
                    <View style={[style.card, style.horizontalSpaced]}>
                        <View style={style.verticalPadded}>
                            <CText
                                message={
                                    helpCenterMessages.aAgentWillContactYou
                                }
                                centered
                            />
                        </View>
                    </View>
                )}
            </ScrollProvider>
        </HelpContext.Provider>
    );
};
