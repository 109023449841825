import React, { FC, useEffect, useState } from 'react';
import { Platform, NativeModules } from 'react-native';
import { IDefaultProps } from '../../IDefaultProps';
import { EnvironmentContext } from './EnvironmentContext';
import { EEnvironment, ERegion } from '../../../enums';
import { getBundleId } from './functions/getBundleId';
/**
 * Environment provider
 * opens and controls context to set environment
 * @param param0
 * @returns
 */
export const EnvironmentProvider: FC<IDefaultProps> = ({ children }) => {
    const [environment, setEnvironment] = useState(EEnvironment.SITUS);
    const [region, setRegion] = useState(ERegion.de);
    /**
     * effect to set page title on web
     */
    useEffect(() => {
        if (Platform.OS === 'web' && environment) {
            // @ts-ignore
            const iconLink = document.querySelector('link[rel="icon"]');
            // @ts-ignore
            const shortcutLink = document.querySelector('link[rel="shortcut"]');
            switch (environment) {
                case EEnvironment.Lieblingsjob:
                    // @ts-ignore
                    document.title = 'Lieblingsjob';
                    if (iconLink) {
                        iconLink.setAttribute('href', '/lieblingsjob.ico');
                    }
                    if (shortcutLink) {
                        shortcutLink.setAttribute('href', '/lieblingsjob.ico');
                    }
                    break;
                case EEnvironment.SITUS:
                    // @ts-ignore
                    document.title = 'SITUS.docs';
                    if (iconLink) {
                        iconLink.setAttribute('href', '/situs.ico');
                    }
                    if (shortcutLink) {
                        shortcutLink.setAttribute('href', '/situs.ico');
                    }
                    break;
                case EEnvironment.SITUSCARE:
                    // TODO: situscare icon?
                    // @ts-ignore
                    document.title = 'SITUS.care';
                    if (iconLink) {
                        iconLink.setAttribute('href', '/situs.ico');
                    }
                    if (shortcutLink) {
                        shortcutLink.setAttribute('href', '/situs.ico');
                    }
                    break;
                case EEnvironment.TimePloyees:
                    // @ts-ignore
                    document.title = 'TimePloyees';
                    if (iconLink) {
                        iconLink.setAttribute('href', '/tp.ico');
                    }
                    if (shortcutLink) {
                        shortcutLink.setAttribute('href', '/tp.ico');
                    }
                    break;
            }
        }
    }, [environment]);
    /**
     * effect to lock environment in production
     */
    useEffect(() => {
        if (Platform.OS === 'web') {
            // @ts-ignore
            const pathname = window?.location?.hostname;
            if (pathname.includes('lieblingsjob')) {
                setEnvironment(EEnvironment.Lieblingsjob);
            } else if (pathname.includes('timeployees')) {
                setEnvironment(EEnvironment.TimePloyees);
            } else if (pathname.includes('situsdocs')) {
                setEnvironment(EEnvironment.SITUS);
            } else if (pathname.includes('situscare')) {
                setEnvironment(EEnvironment.SITUSCARE);
            }
            if (pathname.includes('.de')) {
                setRegion(ERegion.de);
            } else if (pathname.includes('.co.za')) {
                setRegion(ERegion.za);
            }
        } else {
            const bundleId = getBundleId();
            if (bundleId.includes('timeployees')) {
                setEnvironment(EEnvironment.TimePloyees);
            }
        }
    }, []);
    /**
     * render
     */
    return (
        <EnvironmentContext.Provider
            value={{
                environment,
                setEnvironment,
                region,
                setRegion,
            }}
        >
            {children}
        </EnvironmentContext.Provider>
    );
};
