import { Platform } from 'react-native';
// SVG IMPORTS
import adminLogoSVG from './images/logo_admin.svg';
import applySVG from './images/47Bewerben.svg';
import tpApplications from './images/239Bewerbungen.svg';
import placeholderDSVG from './images/217-Logoneutral.svg';
import placeholderDBSVG from './images/217-Logoneutral.svg';
import placeholderFSVG from './images/placeholderF.svg';
import placeholderFBSVG from './images/placeholderFB.svg';
import placeholderHSVG from './images/placeholderH.svg';
import placeholderMSVG from './images/placeholderM.svg';
import placeholderMBSVG from './images/placeholderMB.svg';
import tpPlaceholderWPSVG from './images/234arbeitgeberohnemensch.svg';
import professionalHistorySVG from './images/24.svg';
import tpProfHistorySVG from './images/238Arbeitserfahrungen.svg';
import logoSVG from './images/logo.svg';
import logocareSVG from './images/logocare.svg';
import tpLogoSVG from './images/tp_logo.svg';
import tpTitleSVG from './images/tp_title.svg';
import settingsSVG from './images/42Wartungsarbeiten.svg';
import tpSettingsSVG from './images/226Einstellungen.svg';
import paymentSVG from './images/51.svg';
import signOnDesktopSVG from './images/53.svg';
import tpSignOnDesktopSVG from './images/202-Webapp-2.svg';
import signOnMobileSVG from './images/54.svg';
import helpSVG from './images/27FragenAntworten.svg';
import tpHelpSVG from './images/222QandA.svg';
import tpPaymentSVG from './images/224Abrechnungen.svg';
import referralSVG from './images/21Empfehlungangepasst.svg';
import tpReferralSVG from './images/213-Menschen-Bewertung.svg';
import todoSVG from './images/38.svg';
import tpTodoSVG from './images/201-Checkliste-1.svg';
import searchSVG from './images/37Search.svg';
import tpSearchSVG from './images/214-Suchleiste.svg';
import notificationSVG from './images/45Benachrichtigung.svg';
import tpNotificationSVG from './images/215-Brief-Nachricht-Posteingang.svg';
import tpNegotiationsSVG from './images/243OffeneVerhandlung.svg';
import tpContractsSVG from './images/223Vertragunterschreiben.svg';
import tpProfProfilesSVG from './images/202-Webapp-2.svg';
import tpApplicantsSVG from './images/242SuchenachBewerbern.svg';
import tpInReviewSVG from './images/244.svg';
import front from './images/250Front.svg';
import back from './images/251Back.svg';
import frontWithHuman from './images/249FrontmitBild.svg';
import frontS from './images/56.svg';
import backS from './images/57.svg';
import frontWithHumanS from './images/55.svg';
import rocketman from './images/256Rakete.svg';
import documents from './images/240Vertragerstellen.svg';
const adminLogoPNG = require('./images/logo_admin.png');
const tpAdminLogoPNG = require('./images/tp_admin_logo.png');
const applyPNG = require('./images/47.png');
const tpApplicationsPNG = require('./images/239.png');
const doctorPrescriptionGIF = require('./images/doctor-prescription.gif');
const gLogoPNG = require('./images/g-logo.png');
const helpPNG = require('./images/27.png');
const tpHelpPNG = require('./images/222QandA.svg');
const logoPNG = require('./images/logo.png');
const logocarePNG = require('./images/logocare.png');
const tpLogoPNG = require('./images/tp_logo.png');
const ljLogoPNG = require('./images/lieblingsjob_logo.png');
const tpTitlePNG = require('./images/tp_title.png');
const notificationPNG = require('./images/46.png');
const tpNotificationPNG = require('./images/215-Brief-Nachricht-Posteingang.png');
const paymentPNG = require('./images/51.png');
const tpPaymentPNG = require('./images/224Abrechnungen.png');
const agencyMPlaceholderPNG = require('./images/218ProfilbildMann2.png');
const agencyFPlaceholderPNG = require('./images/216ProfilbildFrau.png');
const placeholderDPNG = require('./images/217-Logoneutral.png');
const placeholderDBPNG = require('./images/217-Logoneutral.png');
const placeholderFPNG = require('./images/placeholderF.png');
const placeholderFBPNG = require('./images/placeholderFB.png');
const placeholderHPNG = require('./images/placeholderH.png');
const placeholderMPNG = require('./images/placeholderM.png');
const placeholderMBPNG = require('./images/placeholderMB.png');
const professionalHistoryPNG = require('./images/24.png');
const tpPlaceholderWP = require('./images/234.png');
const tpProfHistoryPNG = require('./images/238.png');
const referralPNG = require('./images/21.png');
const tpReferralPNG = require('./images/213-Menschen-Bewertung.png');
const searchPNG = require('./images/37.png');
const tpSearchPNG = require('./images/214-Suchleiste.png');
const settingsPNG = require('./images/42.png');
const tpSettingsPNG = require('./images/226Einstellungen.png');
const signOnDesktopPNG = require('./images/53.png');
const tpSignOnDesktopPNG = require('./images/202-Webapp-2.png');
const signOnMobilePNG = require('./images/54.png');
const titlePNG = require('./images/title.png');
const todoPNG = require('./images/38.png');
const tpTodoPNG = require('./images/201-Checkliste-1.png');
const situsSigil = require('./images/situsSigil.png');
const situsAdminSigil = require('./images/situsAdminSigil.png');
const tpSigil = require('./images/tpSigil.png');
const tpAdminSigil = require('./images/tpAdminSigil.png');
const tpNegotiationsPNG = require('./images/243OffeneVerhandlung.png');
const tpContractsPNG = require('./images/223Vertragunterschreiben.png');
const tpProfProfilesPNG = require('./images/202-Webapp-2.png');
const tpApplicantsPNG = require('./images/242.png');
const tpInReview = require('./images/244.png');
const frontPNG = require('./images/250.png');
const backPNG = require('./images/251.png');
const frontWithHumanPNG = require('./images/249.png');
const frontSPNG = require('./images/56.png');
const backSPNG = require('./images/57.png');
const frontWithHumanSPNG = require('./images/55.png');
const rocketmanPNG = require('./images/256.png');
const documentsPNG = require('./images/240.png');

import de from './images/flags/de.svg';
import en from './images/flags/gb.svg';
import za from './images/flags/za.svg';
const depng = require('./images/flags/de.png');
const enpng = require('./images/flags/gb.png');
const zapng = require('./images/flags/za.png');

import { EEnvironment } from '../enums';

interface IAsset {
    png?: any;
    svg?: any;
}
interface IBaseAsset {
    png?: any;
    svg?: any;
    tp?: IAsset;
    lieblingsjob?: IAsset;
    care?: IAsset;
}

export const getAsset = (
    asset: keyof typeof assets,
    environment: EEnvironment,
) => {
    let pivot = assets[asset] as IBaseAsset;
    if (environment === EEnvironment.TimePloyees && pivot.tp) {
        pivot = pivot.tp;
    }
    if (environment === EEnvironment.Lieblingsjob && pivot.lieblingsjob) {
        pivot = pivot.lieblingsjob;
    }
    if (environment === EEnvironment.SITUSCARE && pivot.care) {
        pivot = pivot.care;
    }

    if (Platform.OS === 'web' && pivot.svg) {
        return pivot.svg;
    } else if (pivot.png) {
        return pivot.png;
    } else {
        return helpPNG;
    }
};

export const assets = {
    adminLogo: {
        png: adminLogoPNG,
        svg: adminLogoSVG,
        tp: {
            png: tpAdminLogoPNG,
        },
    },
    front: {
        svg: frontS,
        png: frontSPNG,
        tp: {
            svg: front,
            png: frontPNG,
        },
    },
    back: {
        svg: backS,
        png: backSPNG,
        tp: { svg: back, png: backPNG },
    },
    frontWithHuman: {
        svg: frontWithHumanS,
        png: frontWithHumanSPNG,
        tp: { svg: frontWithHuman, png: frontWithHumanPNG },
    },
    apply: {
        png: applyPNG,
        svg: applySVG,
        tp: {
            svg: tpApplications,
            png: tpApplicationsPNG,
        },
    },
    doctorPrescription: {
        png: doctorPrescriptionGIF,
    },
    gLogo: {
        png: gLogoPNG,
    },
    help: {
        png: helpPNG,
        svg: helpSVG,
        tp: {
            png: tpHelpPNG,
            svg: tpHelpSVG,
        },
    },
    sigil: {
        png: situsSigil,
        tp: {
            png: tpSigil,
        },
        lieblingsjob: {
            png: ljLogoPNG,
        },
    },
    adminSigil: {
        png: situsAdminSigil,
        tp: {
            png: tpAdminSigil,
        },
    },
    logo: {
        png: logoPNG,
        svg: logoSVG,
        tp: {
            png: tpLogoPNG,
            svg: tpLogoSVG,
        },
        lieblingsjob: {
            png: ljLogoPNG,
        },
        care: {
            png: logocarePNG,
            svg: logocareSVG,
        },
    },
    notifications: {
        png: notificationPNG,
        svg: notificationSVG,
        tp: {
            png: tpNotificationPNG,
            svg: tpNotificationSVG,
        },
    },
    payment: {
        png: paymentPNG,
        svg: paymentSVG,
        tp: {
            png: tpPaymentPNG,
            svg: tpPaymentSVG,
        },
    },
    agencyMPlaceholder: {
        png: agencyMPlaceholderPNG,
        // svg: tpAgencyMPlaceholder,
    },
    agencyFPlaceholder: {
        png: agencyFPlaceholderPNG,
        // svg: tpAgencyFPlaceholder,
    },
    placeholderD: {
        png: placeholderDPNG,
        svg: placeholderDSVG,
    },
    placeholderDB: {
        png: placeholderDBPNG,
        svg: placeholderDBSVG,
    },
    placeholderF: {
        png: placeholderFPNG,
        svg: placeholderFSVG,
    },
    placeholderFB: {
        png: placeholderFBPNG,
        svg: placeholderFBSVG,
    },
    placeholderH: {
        png: placeholderHPNG,
        svg: placeholderHSVG,
        tp: {
            png: tpPlaceholderWP,
            svg: tpPlaceholderWPSVG,
        },
    },
    placeholderM: {
        png: placeholderMPNG,
        svg: placeholderMSVG,
    },
    placeholderMB: {
        png: placeholderMBPNG,
        svg: placeholderMBSVG,
    },
    professionalHistory: {
        png: professionalHistoryPNG,
        svg: professionalHistorySVG,
        tp: {
            png: tpProfHistoryPNG,
            svg: tpProfHistorySVG,
        },
    },
    referral: {
        png: referralPNG,
        svg: referralSVG,
        tp: {
            png: tpReferralPNG,
            svg: tpReferralSVG,
        },
    },
    search: {
        png: searchPNG,
        svg: searchSVG,
        tp: {
            png: tpSearchPNG,
            svg: tpSearchSVG,
        },
    },
    settings: {
        png: settingsPNG,
        svg: settingsSVG,
        tp: {
            png: tpSettingsPNG,
            svg: tpSettingsSVG,
        },
    },
    signOnDesktop: {
        png: signOnDesktopPNG,
        svg: signOnDesktopSVG,
        tp: {
            png: tpSignOnDesktopPNG,
            svg: tpSignOnDesktopSVG,
        },
    },
    signOnMobile: {
        png: signOnMobilePNG,
        svg: signOnMobileSVG,
        tp: {
            png: tpApplicationsPNG,
            svg: tpApplications,
        },
    },
    title: {
        png: titlePNG,
        tp: {
            png: tpTitlePNG,
            svg: tpTitleSVG,
        },
    },
    contracts: {
        tp: {
            png: tpContractsPNG,
            svg: tpContractsSVG,
        },
    },
    negotiations: {
        tp: {
            png: tpNegotiationsPNG,
            svg: tpNegotiationsSVG,
        },
    },
    profProfiles: {
        tp: {
            png: tpProfProfilesPNG,
            svg: tpProfProfilesSVG,
        },
    },
    applicants: {
        tp: {
            png: tpApplicantsPNG,
            svg: tpApplicantsSVG,
        },
    },
    inReview: {
        tp: {
            png: tpInReview,
            svg: tpInReviewSVG,
        },
    },
    todo: {
        png: todoPNG,
        svg: todoSVG,
        tp: {
            png: tpTodoPNG,
            svg: tpTodoSVG,
        },
    },
    de: {
        svg: de,
        png: depng,
    },
    en: {
        svg: en,
        png: enpng,
    },
    za: {
        svg: za,
        png: zapng,
    },
    rocketman: {
        tp: {
            svg: rocketman,
            png: rocketmanPNG,
        },
    },
    documents: {
        svg: applySVG,
        png: applyPNG,
        tp: {
            svg: documents,
            png: documentsPNG,
        },
    },
};

export { icons } from './icons';
