import { defineMessages } from 'react-intl';

const scope = 'drop.';

export const dropMessages = defineMessages({
    drop: {
        id: scope + 'drop',
        defaultMessage: 'Drop file here to initiate upload',
    },
});
