import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { MContract, MJob } from '../../../../models';
import { useFireBase } from '../../../../utilities/firebase';
import { useStyle } from '../../../../utilities/styles';
import { EUserType, ECollections, EContractStatus } from '../../../../enums';
import { isSuperUser } from '../../../../utilities/auth';
import { IFilter } from '../../../../utilities/firebase/store';
import { ScrollProvider } from '../../../../utilities/contexts';
import { CButton, CImage, CText, Spinner } from '../../../../components';
import { generalMessages } from '../../../../utilities/messages';
import { ContractRow } from '../../List/components/ContractRow';
import { contractMessages } from '../../contract.messages';
import { useNavigate } from '../../../../utilities/routing';
import { JobTitle } from '../../../Job/components/JobTitle';
import { jobMessages } from '../../../Job/job.messages';

export const WorkplaceNegotiationList: FC = () => {
    const style = useStyle();
    const navigate = useNavigate();
    const { getDataIndex, userData, userWorkplaces, getDataById } =
        useFireBase();
    const [negotiations, setNegotiations] = useState<MContract[]>([]);
    const [jobs, setJobs] = useState<MJob[]>([]);
    const [loadingNegotiations, setLoadingNegotiations] = useState(true);
    /**
     * negotiations grouped by Job
     */
    const negotiationsGroupedByJob = useMemo(
        () =>
            negotiations.reduce((acc, n) => {
                const identification = n.jobId || 'undefined';
                const pivot = acc.find((t) => t.jobId === identification);
                if (pivot) {
                    pivot.negotiations.push(n);
                } else if (identification) {
                    acc.push({ jobId: identification, negotiations: [n] });
                }
                return acc;
            }, [] as { jobId: string; negotiations: MContract[] }[]),
        [negotiations],
    );
    /**
     * memoized grouped by job with job data
     */
    const negotiationsGroupedByJobWithJob = useMemo(
        () =>
            negotiationsGroupedByJob.map((v) => {
                const job = jobs.find((j) => j.documentId === v.jobId);
                return { ...v, job };
            }),
        [negotiationsGroupedByJob, jobs],
    );
    /**
     * effect to load negotiations
     */
    useEffect(() => {
        const basefilter = [] as IFilter[];
        if (!isSuperUser(userData)) {
            if (userData.type === EUserType.employer) {
                if (!userWorkplaces.length) {
                    return;
                }
                basefilter.push({
                    field: 'workplaceUsers',
                    value: userData.documentId,
                    operator: 'array-contains',
                });
            } else {
                basefilter.push({
                    field: 'employeeId',
                    value: userData.documentId,
                });
            }
        }
        getDataIndex(ECollections.contracts, {
            filter: [
                ...basefilter,
                {
                    field: 'status',
                    operator: 'in',
                    value: [
                        EContractStatus.creating,
                        EContractStatus.review_yee,
                        EContractStatus.review_yer,
                        EContractStatus.accepted,
                    ],
                },
            ],
        }).then((res) => {
            const nextContracts = (res as MContract[]).map(
                (n) => new MContract(n),
            );
            setNegotiations(nextContracts);
            setLoadingNegotiations(false);
        });
    }, [userWorkplaces]);
    /**
     * effect to load jobs for later matching
     */
    useEffect(() => {
        Promise.all(
            negotiationsGroupedByJob.map(async (ngjr) => {
                if (ngjr.jobId !== 'undefined') {
                    return new MJob(
                        await getDataById(ECollections.jobs, ngjr.jobId),
                    );
                }
            }),
        ).then((jarr) => setJobs(jarr.filter((j) => !!j) as MJob[]));
    }, [negotiationsGroupedByJob]);
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={style.card}>
                <CText headline message={generalMessages.negotiations} />
                <View style={style.horizontal}>
                    <CButton
                        title={generalMessages.negotiations}
                        onPress={() => {
                            // nothing to do
                        }}
                        small
                        style={style.smallMargin}
                    />
                    <CButton
                        title={generalMessages.contracts}
                        onPress={() => {
                            navigate('/contracts');
                        }}
                        small
                        minor
                        style={style.smallMargin}
                    />
                    <View style={style.verticalPaddedThinSeparator} />
                    <CButton
                        title={contractMessages.history}
                        onPress={() => {
                            navigate('/contracthistory');
                        }}
                        small
                        minor
                        style={style.smallMargin}
                    />
                </View>
            </View>
            {!loadingNegotiations ? (
                <>
                    {negotiationsGroupedByJobWithJob.map((jr) => (
                        <View key={jr.jobId} style={style.card}>
                            <View
                                style={[
                                    style.horizontalSplit,
                                    style.centeredItems,
                                ]}
                            >
                                {!jr.job ? (
                                    jr.jobId === 'undefined' ? (
                                        <CText
                                            message={
                                                contractMessages.joblessNegotiations
                                            }
                                        />
                                    ) : (
                                        <CText message={jr.jobId} />
                                    )
                                ) : (
                                    <JobTitle job={jr.job} />
                                )}
                                {!!jr.job && (
                                    <CButton
                                        title={jobMessages.viewJob}
                                        onPress={() =>
                                            navigate(
                                                '/japplication/view/' +
                                                    jr.jobId,
                                            )
                                        }
                                        small
                                    />
                                )}
                            </View>
                            <View style={style.paddedThinSeparator} />
                            {jr.negotiations.map((n) => (
                                <ContractRow
                                    key={n.documentId}
                                    negotiation={n}
                                    embedded
                                    onPress={() =>
                                        navigate(
                                            '/negotiations/' + n.documentId,
                                        )
                                    }
                                />
                            ))}
                        </View>
                    ))}
                    {!negotiations.length && (
                        <View style={style.card}>
                            <CImage image="negotiations" />
                            <CText
                                centered
                                headline
                                message={contractMessages.noOpenNegotiations}
                            />
                        </View>
                    )}
                </>
            ) : (
                <Spinner />
            )}
        </ScrollProvider>
    );
};
