/**
 * wage values split to type
 */
export class MWage {
    public wage = 0;
    public regularWage = 0;
    public standbyWage = 0;
    public onCallActiveWage = 0;
    public onCallPassiveWage = 0;

    constructor(params: Partial<MWage> = {}) {
        Object.assign(this, params);
    }
}
/**
 * wage values split to type
 */
export class MRates extends MWage {
    public useNightWage = false;
    public useNightFactor = false;
    public nightFactor = 1;
    public nightWage = new MWage();

    public useHolidayWage = false;
    public useHolidayFactor = false;
    public holidayFactor = 1;
    public holidayWage = new MWage();

    public useSundayWage = false;
    public useSundayFactor = false;
    public sundayFactor = 1;
    public sundayWage = new MWage();

    constructor(params: Partial<MRates> = {}) {
        super(params);
        Object.assign(this, params);
        MRates.fixConstructor(this, params);
    }

    static fixConstructor = (that: MRates, params: Partial<MRates> = {}) => {
        const unsafeParams = params as any;
        if (params.holidayWage) {
            that.holidayWage = new MWage(params.holidayWage);
        }
        if (params.sundayWage) {
            that.sundayWage = new MWage(params.sundayWage);
        }

        if (
            ![null, undefined].includes(unsafeParams.nightWage) &&
            typeof unsafeParams.nightWage === 'number'
        ) {
            that.nightWage = new MWage({
                wage: unsafeParams.wage,
                regularWage: unsafeParams.nightWage,
            });
            that.useNightWage = true;
        } else if (params.nightWage) {
            that.nightWage = new MWage(params.nightWage);
        }
    };
}
