import { defineMessages } from 'react-intl';

const scope = 'general.undefined.education.';
/**
 * doctorEducation names
 * is used for doctorEducation picker
 */
export const generalFieldEducationMessages = defineMessages({
    serviceStaff: {
        id: scope + 'serviceStaff',
        defaultMessage: 'Service staff',
    },
    promoter: {
        id: scope + 'promoter',
        defaultMessage: 'Promoter',
    },
    eventHost: {
        id: scope + 'eventHost',
        defaultMessage: 'Host/hostess for events',
    },
    eventHelper: {
        id: scope + 'eventHelper',
        defaultMessage: 'Event helper',
    },
    cashier: {
        id: scope + 'cashier',
        defaultMessage: 'Cashier',
    },
    salesperson: {
        id: scope + 'salesperson',
        defaultMessage: 'Salesperson',
    },
    warehouseAssistant: {
        id: scope + 'warehouseAssistant',
        defaultMessage: 'Warehouse assistant',
    },
    officeAssistant: {
        id: scope + 'officeAssistant',
        defaultMessage: 'Office assistant',
    },
    orderPicker: {
        id: scope + 'orderPicker',
        defaultMessage: 'Order fulfillment associate',
    },
    otherHelp: {
        id: scope + 'otherHelp',
        defaultMessage: 'Other temporary help',
    },
});
