import React from 'react';
import { ITheme } from './ITheme';
import { makeGlobalStyles } from './styles';

const StyleContext = React.createContext(
    {} as {
        theme: ITheme;
        isDark: boolean;
        setIsDark: (next: boolean) => void;
        styles: ReturnType<typeof makeGlobalStyles>;
    },
);

export default StyleContext;
