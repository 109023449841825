export enum ELeaveType {
    none = 'none',
    sick = 'sick',
    vacation = 'vacation',
    holidayLeave = 'holidayLeave',
    free = 'free',
    unexcused = 'unexcused',
    compensation = 'compensation',
    otherContract = 'otherContract',
    other = 'other',
}
