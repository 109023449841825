import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFireBase } from '../../../utilities/firebase';
import { MChat } from '../../../models/MChat';
import { useNavigate, useParams } from '../../../utilities/routing';
import { ECollections } from '../../../enums';
import { useDimensions } from '../../../utilities/hooks/useDimensions';
/**
 * use chat list state
 * @returns values for render
 */
export const useChatListState = () => {
    // globals
    const { userData, getDataIndex } = useFireBase();
    const { id } = useParams<{ id: string }>();
    const { width } = useDimensions();
    const navigate = useNavigate();
    // locals
    const [chats, setChats] = useState<MChat[]>([]);
    const [closedChats, setClosedChats] = useState<MChat[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showClosed, setShowClosed] = useState(false);
    const [chatOrder, setChatOrder] = useState<
        {
            chat: string;
            createdOn: number;
        }[]
    >([]);
    const [didSort, setDidSort] = useState(false);
    /**
     * memoized sorted chat order
     */
    const sortedChatOrder = useMemo(
        () => chatOrder.sort((a, b) => b.createdOn - a.createdOn),
        [chatOrder],
    );
    /**
     * memoized ordered chats to be rendered
     * ordered by last message
     */
    const orderedChats = useMemo(() => {
        if (chats.length && chats.length === sortedChatOrder.length) {
            setDidSort(true);
            const next = sortedChatOrder
                .map((order) => {
                    return chats.find(
                        (c) => c.documentId === order.chat,
                    ) as MChat;
                })
                .filter((c) => !!c);
            return next;
        } else {
            return chats;
        }
    }, [chats, sortedChatOrder]);
    /**
     * add new createdOn for given chat id to order array
     */
    const addToOrder = useCallback(
        (next: { chat: string; createdOn: number }) => {
            setChatOrder((prev) => {
                const i = prev.findIndex((p) => p.chat === next.chat);
                if (i >= 0) {
                    prev.splice(i, 1, next);
                } else {
                    prev.push(next);
                }
                return Array.from(prev);
            });
        },
        [],
    );
    /**
     * effect to load chats
     */
    useEffect(() => {
        if (userData && userData.documentId) {
            getDataIndex(ECollections.chats, {
                filter: [
                    {
                        field: 'participants',
                        value: userData.documentId,
                        operator: 'array-contains',
                    },
                    {
                        field: 'closedOn',
                        value: 0,
                    },
                ],
            }).then((result) => {
                setChats((result as MChat[]).map((v) => new MChat(v)));
                setLoading(false);
            });
            getDataIndex(ECollections.chats, {
                filter: [
                    {
                        field: 'participants',
                        value: userData.documentId,
                        operator: 'array-contains',
                    },
                ],
                inequalities: [
                    {
                        field: 'closedOn',
                        value: 0,
                        operator: '>',
                    },
                ],
            }).then((result) => {
                setClosedChats((result as MChat[]).map((v) => new MChat(v)));
            });
        }
    }, [userData]);
    /**
     * effect to open top most chat after ordering was completed
     */
    useEffect(() => {
        if (width >= 1000 && !id && orderedChats.length && didSort) {
            // const outOfOrderChat = orderedChats.find(
            //     (aC, i) =>
            //         i !==
            //         sortedChatOrder.findIndex(
            //             (bC) => aC.documentId === bC.chat,
            //         ),
            // );
            // console.log(outOfOrderChat);

            // if (!outOfOrderChat) {
            // }
            navigate('/chat/' + orderedChats[0].documentId);
        }
    }, [orderedChats, id, didSort]);
    /**
     * return values for render
     */
    return {
        loading,
        id,
        chats,
        orderedChats,
        addToOrder,
        closedChats,
        setShowClosed: (next: boolean) => {
            setChatOrder([]);
            setShowClosed(next);
        },
        showClosed,
    };
};
