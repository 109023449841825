import { MWage } from '../../models';

export const makeDisplayWage = (wage: MWage) => {
    return {
        regularWage: wage.regularWage ? wage.regularWage + wage.wage : 0,
        standbyWage: wage.standbyWage ? wage.standbyWage + wage.wage : 0,
        onCallActiveWage: wage.onCallActiveWage
            ? wage.onCallActiveWage + wage.wage
            : 0,
        onCallPassiveWage: wage.onCallPassiveWage
            ? wage.onCallPassiveWage + wage.wage
            : 0,
    };
};
