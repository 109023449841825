import React, { FC, useEffect } from 'react';
import { CText } from '../../../../components';
import { profProfileMessages } from '../../ProfProfile.messages';
import { MProfessionalProfile } from '../../../../models';
import { Rates } from '../../../../components/Wage/Rates';

interface IProfessionalProfileProps {
    onChange: (next: Partial<MProfessionalProfile>) => void;
    onValidityChange: (issue: string, key?: string) => void;
    curData: MProfessionalProfile;
    prevData?: MProfessionalProfile;
    onboarding?: boolean;
    save?: () => void;
}

export const ProfessionalWage: FC<IProfessionalProfileProps> = ({
    curData,
    onChange,
    onValidityChange,
}) => {
    useEffect(() => {
        onValidityChange('', 'wage');
    }, [curData]);

    return (
        <>
            <CText secondaryHeadline message={profProfileMessages.rate} />
            <CText message={profProfileMessages.rateHint} />
            <Rates
                curData={curData.wage}
                onChange={(wage) => onChange({ wage })}
            />
        </>
    );
};
