import { defineMessages } from 'react-intl';

const scope = 'medic.nurse.station.';

export const stationMessages = defineMessages({
    allStations: {
        id: `${scope}allStations`,
        defaultMessage: `All Stations`,
    },
    acutePsychiatry: {
        id: `${scope}acutePsychiatry`,
        defaultMessage: `Acute Psychiatry`,
    },
    generalSurgery: {
        id: `${scope}generalSurgery`,
        defaultMessage: `General surgery / intensive care`,
    },
    outpatientNursingService: {
        id: `${scope}outpatientNursingService`,
        defaultMessage: `Outpatient nursing service`,
    },
    outpatientClinic: {
        id: `${scope}outpatientClinic`,
        defaultMessage: `Outpatient Clinic`,
    },
    anaesthesia: {
        id: `${scope}anaesthesia`,
        defaultMessage: `Anaesthesia`,
    },
    angiology: {
        id: `${scope}angiology`,
        defaultMessage: `Angiology`,
    },
    recoveryRoom: {
        id: `${scope}recoveryRoom`,
        defaultMessage: `Recovery room`,
    },
    ophthalmology: {
        id: `${scope}ophthalmology`,
        defaultMessage: `Ophthalmology`,
    },
    chestPainUnit: {
        id: `${scope}chestPainUnit`,
        defaultMessage: `Chest Pain Unit`,
    },
    coloproctology: {
        id: `${scope}coloproctology`,
        defaultMessage: `Coloproctology`,
    },
    covidIntensiveCareUnit: {
        id: `${scope}covidIntensiveCareUnit`,
        defaultMessage: `Covid intensive care unit`,
    },
    covidNormalStation: {
        id: `${scope}covidNormalStation`,
        defaultMessage: `Covid Normal Station`,
    },
    dermatology: {
        id: `${scope}dermatology`,
        defaultMessage: `Dermatology`,
    },
    diabetology: {
        id: `${scope}diabetology`,
        defaultMessage: `Diabetology`,
    },
    surgery: {
        id: `${scope}surgery`,
        defaultMessage: `Surgery`,
    },
    dialysis: {
        id: `${scope}dialysis`,
        defaultMessage: `Dialysis`,
    },
    endocrinology: {
        id: `${scope}endocrinology`,
        defaultMessage: `Endocrinology`,
    },
    endocrinologyOrPaediatrics: {
        id: `${scope}endocrinologyOrPaediatrics`,
        defaultMessage: `Endocrinology / Paediatrics`,
    },
    endoscopy: {
        id: `${scope}endoscopy`,
        defaultMessage: `Endoscopy`,
    },
    occupationalTherapy: {
        id: `${scope}occupationalTherapy`,
        defaultMessage: `Occupational Therapy`,
    },
    forensics: {
        id: `${scope}forensics`,
        defaultMessage: `Forensics`,
    },
    gynaecologyandObstetricsOrIntensiveCareMedicine: {
        id: `${scope}gynaecologyandObstetricsOrIntensiveCareMedicine`,
        defaultMessage: `Gynaecology and Obstetrics / Intensive Care Medicine`,
    },
    gastroenterology: {
        id: `${scope}gastroenterology`,
        defaultMessage: `Gastroenterology`,
    },
    gastroenterologyOrEndocrinology: {
        id: `${scope}gastroenterologyOrEndocrinology`,
        defaultMessage: `Gastroenterology / Endocrinology`,
    },
    gastroenterologyOrPaediatrics: {
        id: `${scope}gastroenterologyOrPaediatrics`,
        defaultMessage: `Gastroenterology / Paediatrics`,
    },
    obstetrics: {
        id: `${scope}obstetrics`,
        defaultMessage: `Obstetrics`,
    },
    vascularSurgery: {
        id: `${scope}vascularSurgery`,
        defaultMessage: `Vascular Surgery`,
    },
    geriatrics: {
        id: `${scope}geriatrics`,
        defaultMessage: `Geriatrics`,
    },
    geriatricsOrNightClinic: {
        id: `${scope}geriatricsOrNightClinic`,
        defaultMessage: `Geriatrics / night clinic`,
    },
    geriatricsOrFocusOnGynaecology: {
        id: `${scope}geriatricsOrFocusOnGynaecology`,
        defaultMessage: `Geriatrics / focus on Gynaecology`,
    },
    geriatricsOrDayClinic: {
        id: `${scope}geriatricsOrDayClinic`,
        defaultMessage: `Geriatrics / day clinic`,
    },
    gerontopsychiatry: {
        id: `${scope}gerontopsychiatry`,
        defaultMessage: `Gerontopsychiatry`,
    },
    gynaecology: {
        id: `${scope}gynaecology`,
        defaultMessage: `Gynaecology`,
    },
    earNoseAndThroatMedicine: {
        id: `${scope}earNoseAndThroatMedicine`,
        defaultMessage: `Ear, nose and throat medicine`,
    },
    otorhinolaryngologyOrIntensiveCareMedicine: {
        id: `${scope}otorhinolaryngologyOrIntensiveCareMedicine`,
        defaultMessage: `Otorhinolaryngology / intensive care medicine`,
    },
    haematologyAndInternalOncology: {
        id: `${scope}haematologyAndInternalOncology`,
        defaultMessage: `Haematology and internal oncology`,
    },
    haematologyAndInternalOncologyOrGynaecology: {
        id: `${scope}haematologyAndInternalOncologyOrGynaecology`,
        defaultMessage: `Haematology and internal oncology / gynaecology`,
    },
    haematologyAndInternalOncologyOrPaediatrics: {
        id: `${scope}haematologyAndInternalOncologyOrPaediatrics`,
        defaultMessage: `Haematology and internal oncology / Paediatrics`,
    },
    haematologyAndInternalOncologyOrRadiotherapeutics: {
        id: `${scope}haematologyAndInternalOncologyOrRadiotherapeutics`,
        defaultMessage: `Haematology and Internal Oncology / Radiotherapeutics`,
    },
    handSurgery: {
        id: `${scope}handSurgery`,
        defaultMessage: `Hand Surgery`,
    },
    therapeuticDepartment: {
        id: `${scope}therapeuticDepartment`,
        defaultMessage: `Therapeutic Department`,
    },
    cardiacSurgery: {
        id: `${scope}cardiacSurgery`,
        defaultMessage: `Cardiac Surgery`,
    },
    cardiacCatheterLaboratory: {
        id: `${scope}cardiacCatheterLaboratory`,
        defaultMessage: `Cardiac Catheter Laboratory`,
    },
    hygiene: {
        id: `${scope}hygiene`,
        defaultMessage: `Hygiene`,
    },
    vaccinationCentre: {
        id: `${scope}vaccinationCentre`,
        defaultMessage: `Vaccination Centre`,
    },
    infectiousDiseases: {
        id: `${scope}infectiousDiseases`,
        defaultMessage: `Infectious Diseases`,
    },
    internalMedicine: {
        id: `${scope}internalMedicine`,
        defaultMessage: `Internal Medicine`,
    },
    intensiveMedicineOrCardiothoracicSurgery: {
        id: `${scope}intensiveMedicineOrCardiothoracicSurgery`,
        defaultMessage: `Intensive Medicine / Cardiothoracic Surgery`,
    },
    intensiveMedicineOrInternalMedicine: {
        id: `${scope}intensiveMedicineOrInternalMedicine`,
        defaultMessage: `Intensive Medicine / Internal Medicine`,
    },
    intensiveCareUnit: {
        id: `${scope}intensiveCareUnit`,
        defaultMessage: `Intensive Care Unit`,
    },
    intermediateCare: {
        id: `${scope}intermediateCare`,
        defaultMessage: `Intermediate Care`,
    },
    cardiology: {
        id: `${scope}cardiology`,
        defaultMessage: `Cardiology`,
    },
    cardiologyOrIntensiveCare: {
        id: `${scope}cardiologyOrIntensiveCare`,
        defaultMessage: `Cardiology / Intensive Care`,
    },
    childAndAdolescentPsychiatry: {
        id: `${scope}childAndAdolescentPsychiatry`,
        defaultMessage: `Child and adolescent psychiatry`,
    },
    paediatricSurgery: {
        id: `${scope}paediatricSurgery`,
        defaultMessage: `Paediatric Surgery`,
    },
    paediatricIntensiveCareUnit: {
        id: `${scope}paediatricIntensiveCareUnit`,
        defaultMessage: `Paediatric Intensive Care Unit`,
    },
    paediatricCardiology: {
        id: `${scope}paediatricCardiology`,
        defaultMessage: `Paediatric Cardiology`,
    },
    paediatricCardiologyOrIntensiveCare: {
        id: `${scope}paediatricCardiologyOrIntensiveCare`,
        defaultMessage: `Paediatric Cardiology / Intensive Care`,
    },
    deliveryRoom: {
        id: `${scope}deliveryRoom`,
        defaultMessage: `Delivery room`,
    },
    laboratory: {
        id: `${scope}laboratory`,
        defaultMessage: `Laboratory`,
    },
    longTermCareChildren: {
        id: `${scope}longTermCareChildren`,
        defaultMessage: `Long-term care children`,
    },
    naturopathy: {
        id: `${scope}naturopathy`,
        defaultMessage: `Naturopathy`,
    },
    neonatology: {
        id: `${scope}neonatology`,
        defaultMessage: `Neonatology`,
    },
    nephrology: {
        id: `${scope}nephrology`,
        defaultMessage: `Nephrology`,
    },
    nephrologyOrIntensiveCare: {
        id: `${scope}nephrologyOrIntensiveCare`,
        defaultMessage: `Nephrology / Intensive Care`,
    },
    nephrologyOrPaediatrics: {
        id: `${scope}nephrologyOrPaediatrics`,
        defaultMessage: `Nephrology / Paediatrics`,
    },
    neurosurgery: {
        id: `${scope}neurosurgery`,
        defaultMessage: `Neurosurgery`,
    },
    neurosurgeryOrIntensiveCare: {
        id: `${scope}neurosurgeryOrIntensiveCare`,
        defaultMessage: `Neurosurgery / Intensive Care`,
    },
    neurology: {
        id: `${scope}neurology`,
        defaultMessage: `Neurology`,
    },
    neurologyOrIntensiveCare: {
        id: `${scope}neurologyOrIntensiveCare`,
        defaultMessage: `Neurology / Intensive Care`,
    },
    neurologyOrPaediatrics: {
        id: `${scope}neurologyOrPaediatrics`,
        defaultMessage: `Neurology / Paediatrics`,
    },
    normalStation: {
        id: `${scope}normalStation`,
        defaultMessage: `Normal station`,
    },
    emergencyAmbulance: {
        id: `${scope}emergencyAmbulance`,
        defaultMessage: `Emergency ambulance`,
    },
    nuclearMedicine: {
        id: `${scope}nuclearMedicine`,
        defaultMessage: `Nuclear Medicine`,
    },
    op: {
        id: `${scope}op`,
        defaultMessage: `OP`,
    },
    orthopaedicsAndTraumaSurgery: {
        id: `${scope}orthopaedicsAndTraumaSurgery`,
        defaultMessage: `Orthopaedics and trauma surgery`,
    },
    paediatrics: {
        id: `${scope}paediatrics`,
        defaultMessage: `Paediatrics`,
    },
    palliativeMedicine: {
        id: `${scope}palliativeMedicine`,
        defaultMessage: `Palliative medicine`,
    },
    nursingHome: {
        id: `${scope}nursingHome`,
        defaultMessage: `Nursing home`,
    },
    physiotherapy: {
        id: `${scope}physiotherapy`,
        defaultMessage: `Physiotherapy`,
    },
    plasticSurgery: {
        id: `${scope}plasticSurgery`,
        defaultMessage: `Plastic surgery`,
    },
    pneumology: {
        id: `${scope}pneumology`,
        defaultMessage: `Pneumology`,
    },
    pneumologyOrPaediatrics: {
        id: `${scope}pneumologyOrPaediatrics`,
        defaultMessage: `Pneumology / Paediatrics`,
    },
    practice: {
        id: `${scope}practice`,
        defaultMessage: `Practice`,
    },
    privateStation: {
        id: `${scope}privateStation`,
        defaultMessage: `Private station`,
    },
    psychiatry: {
        id: `${scope}psychiatry`,
        defaultMessage: `Psychiatry`,
    },
    psychosomaticsOrPsychotherapy: {
        id: `${scope}psychosomaticsOrPsychotherapy`,
        defaultMessage: `Psychosomatics / Psychotherapy`,
    },
    radiology: {
        id: `${scope}radiology`,
        defaultMessage: `Radiology`,
    },
    rehab: {
        id: `${scope}rehab`,
        defaultMessage: `Rehab`,
    },
    rheumatology: {
        id: `${scope}rheumatology`,
        defaultMessage: `Rheumatology`,
    },
    rheumatologyOrPaediatrics: {
        id: `${scope}rheumatologyOrPaediatrics`,
        defaultMessage: `Rheumatology / Paediatrics`,
    },
    painTherapy: {
        id: `${scope}painTherapy`,
        defaultMessage: `Pain therapy`,
    },
    otherSpecialistDepartment: {
        id: `${scope}otherSpecialistDepartment`,
        defaultMessage: `Other specialist department`,
    },
    radiotherapy: {
        id: `${scope}radiotherapy`,
        defaultMessage: `Radiotherapy`,
    },
    strokeUnit: {
        id: `${scope}strokeUnit`,
        defaultMessage: `Stroke Unit`,
    },
    addictionMedicine: {
        id: `${scope}addictionMedicine`,
        defaultMessage: `Addiction Medicine`,
    },
    testCentre: {
        id: `${scope}testCentre`,
        defaultMessage: `Test Centre`,
    },
    thoracicSurgery: {
        id: `${scope}thoracicSurgery`,
        defaultMessage: `Thoracic Surgery`,
    },
    tumourResearch: {
        id: `${scope}tumourResearch`,
        defaultMessage: `Tumour Research`,
    },
    urology: {
        id: `${scope}urology`,
        defaultMessage: `Urology`,
    },
    urologyOrIntensiveCare: {
        id: `${scope}urologyOrIntensiveCare`,
        defaultMessage: `Urology / Intensive Care`,
    },
    visceralSurgery: {
        id: `${scope}visceralSurgery`,
        defaultMessage: `Visceral surgery`,
    },
    electiveStation: {
        id: `${scope}electiveStation`,
        defaultMessage: `Elective station`,
    },
    weaning: {
        id: `${scope}weaning`,
        defaultMessage: `Weaning`,
    },
    spinalSurgery: {
        id: `${scope}spinalSurgery`,
        defaultMessage: `Spinal surgery`,
    },
    dentistryOralAndMaxillofacialSurgery: {
        id: `${scope}dentistryOralAndMaxillofacialSurgery`,
        defaultMessage: `Dentistry, oral and maxillofacial surgery`,
    },
    centralEmergencyRoom: {
        id: `${scope}centralEmergencyRoom`,
        defaultMessage: `Central emergency room`,
    },
});

// 'All stations
//Acute Psychiatry
//General surgery / intensive care
//Outpatient nursing service
//Outpatient clinic
//Anaesthesia
//Angiology
//Recovery room
//Ophthalmology
//Chest Pain Unit
//Surgery
//Coloproctology
//Covid intensive care unit
//Covid Normal Station
//Dermatology
//Diabetology
//Dialysis
//Endocrinology
//Endocrinology / Paediatrics
//Endoscopy
//Occupational Therapy
//Forensics
//Gynaecology and Obstetrics / Intensive Care Medicine
//Gastroenterology
//Gastroenterology / Endocrinology
//Gastroenterology / Paediatrics
//Obstetrics
//Vascular surgery
//Geriatrics
//Geriatrics / night clinic
//Geriatrics / focus on gynaecology
//Geriatrics / day clinic
//Gerontopsychiatry
//Gynaecology
//Ear, nose and throat medicine
//Otorhinolaryngology / intensive care medicine
//Haematology and internal oncology
//Haematology and internal oncology / gynaecology
//Haematology and internal oncology / Paediatrics
//Haematology and Internal Oncology / Radiotherapeutics
//Hand Surgery
//Therapeutic department
//Cardiac surgery
//Cardiac Catheter Laboratory
//Hygiene
//Vaccination Centre
//Infectious Diseases
//Internal Medicine
//Intensive Medicine / Cardiothoracic Surgery
//Intensive Medicine / Internal Medicine
//Intensive Care Unit
//Intermediate Care
//Cardiology
//Cardiology / Intensive Care
//Child and adolescent psychiatry
//Paediatric Surgery
//Paediatric Intensive Care Unit
//Paediatric Cardiology
//Paediatric Cardiology / Intensive Care
//Delivery room
//Laboratory
//Long-term care children
//Naturopathy
//eonatology
//Nephrology
//Nephrology / Intensive Care
//Nephrology / Paediatrics
//Neurosurgery
//Neurosurgery / Intensive Care
//Neurology
//Neurology / Intensive Care
//Neurology / Paediatrics
//Normal station
//Emergency ambulance
//Nuclear Medicine
//OP
//Orthopaedics and trauma surgery
//Paediatrics
//Palliative medicine
//Nursing home
//Physiotherapy
//Plastic surgery
//Pneumology
//Pneumology / Paediatrics
//Practice
//Private station
//Psychiatry
//Psychosomatics / Psychotherapy
//Radiology
//Rehab
//Rheumatology
//Rheumatology / Paediatrics
//Pain therapy
//Other specialist department
//Radiotherapy
//Stroke Unit
//Addiction Medicine
//Test Centre
//Thoracic Surgery
//Tumour Research
//Urology
//Urology / Intensive Care
//Visceral surgery
//Elective station
//Weaning
//Spinal surgery
