import { defineMessages } from 'react-intl';

const scope = 'con.billingCenter.';

export const billingCenterMessages = defineMessages({
    empty: {
        id: scope + 'empty',
        defaultMessage:
            'In this view you will be able to see payment details as soon as you finished a contract',
    },
    status: {
        id: scope + 'status',
        defaultMessage: 'Status',
    },
    createdOn: {
        id: scope + 'createdOn',
        defaultMessage: 'Created on',
    },
    from: {
        id: scope + 'from',
        defaultMessage: 'Started on',
    },
    to: {
        id: scope + 'to',
        defaultMessage: 'Finished on',
    },
    files: {
        id: scope + 'files',
        defaultMessage: 'Files',
    },
    workplace: {
        id: scope + 'workplace',
        defaultMessage: 'Workplace',
    },
    profession: {
        id: scope + 'profession',
        defaultMessage: 'Profession',
    },
    education: {
        id: scope + 'education',
        defaultMessage: 'Education',
    },
    field: {
        id: scope + 'field',
        defaultMessage: 'Field',
    },
    jobDescription: {
        id: scope + 'jobDescription',
        defaultMessage: 'Job description',
    },
    talentName: {
        id: scope + 'talentName',
        defaultMessage: 'Talent name',
    },
});
