import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useNavigate } from '../../../utilities/routing';
import { CButton, CText, Spinner } from '../../../components';
import { useFormat } from '../../../utilities/intl';
import { generalMessages } from '../../../utilities/messages/general.messages';
import { useStyle } from '../../../utilities/styles';
import { useFireBase } from '../../../utilities/firebase';
import { MJob } from '../../../models';
import { JobRow } from '../../Job/List/components/JobRow';
import { IFilter } from '../../../utilities/firebase/store';
import { ECollections, EUserType } from '../../../enums';
import { CImage } from '../../../components/elements/CImage';
import { dashboardMessages } from '../Dashboard.messages';
import { useEnvironment } from '../../../utilities/contexts';
/**
 * card to display 5 matching jobs
 * @param param0
 * @returns
 */
export const MatchingJobs: FC = () => {
    // global state
    const style = useStyle();
    const { getDataIndex, userData } = useFireBase();
    const format = useFormat();
    const navigate = useNavigate();
    const [jobs, setJobs] = useState<MJob[]>([]);
    const [loading, setLoading] = useState(true);
    /**
     * effect to load matching jobs
     */
    useEffect(() => {
        if (userData.documentId && userData.type !== EUserType.default) {
            const filter: IFilter[] = [
                {
                    field: 'status',
                    value: 'public',
                },
                { field: 'region', value: userData.region },
            ];
            if (userData.type === EUserType.user) {
                if (!userData.educations.length) {
                    setLoading(false);
                    return;
                }
                filter.push({
                    field: 'employeeEducations',
                    value: userData.educations,
                    operator: 'array-contains-any',
                });
            } else if (userData.fields.length) {
                filter.push({
                    field: 'employeeField',
                    operator: 'in',
                    value: userData.fields,
                });
            }
            getDataIndex(ECollections.jobs, {
                orderBy: 'createdOn',
                filter,
                limit: 5,
            }).then((result) => {
                const next = (result as MJob[]).map((r) => new MJob(r));
                setJobs(next);
                setLoading(false);
            });
        }
    }, [userData]);
    /**
     *
     */
    if (loading) {
        return <Spinner />;
    }
    /**
     * render
     */
    return (
        <View style={[style.card]}>
            <CText bold headline>
                {userData.type === EUserType.user
                    ? format(generalMessages.matchingJobs)
                    : format(dashboardMessages.newestJobs)}
            </CText>
            {jobs.map((j) => (
                <JobRow key={j.documentId} job={j} inCard smallButtons />
            ))}
            {!jobs.length && (
                <CImage image={'apply'} relativeWidth={0.7} maxWidth={250} />
            )}
            <View style={style.horizontalSpaced}>
                <CButton
                    title={format(generalMessages.showMore)}
                    onPress={() => navigate('/job')}
                    transparent
                    minor
                />
            </View>
        </View>
    );
};
