import { defineMessages } from 'react-intl';

const scope = 'con.shiftPlan.type.';

export const shiftPlanTypeMessages = defineMessages({
    default: {
        defaultMessage: 'Default',
        id: scope + 'default',
    },
    childcare: {
        defaultMessage: 'Child day care',
        id: scope + 'childcare',
    },
});
