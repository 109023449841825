import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { View } from 'react-native';
import {
    IDialogReviewSection,
    IDialogReviewText,
    IDialogReviewValue,
} from '../IDialog';
import { useStyle } from '../../styles';
import { CText, CTextInput, StarInput } from '../../../components';
import { MessageDescriptor } from 'react-intl';
import { reviewMessages } from '../../../container/Reviews/review.messages';

export const ReviewSections: FC<{
    reviewSections: IDialogReviewSection[];
    onChange: Dispatch<SetStateAction<IDialogReviewValue[]>>;
    values: IDialogReviewValue[];
    onChangeText: Dispatch<SetStateAction<IDialogReviewText[]>>;
    texts: IDialogReviewText[];
}> = ({ reviewSections, onChange, values, onChangeText, texts }) => {
    const style = useStyle();
    /**
     * callback to update review value in local state
     */
    const updateReviewValue = useCallback(
        (value: number, sectionId: string, id: string) =>
            onChange((prev: IDialogReviewValue[]) => {
                const pivot = prev.find(
                    (vv) => vv.sectionId === sectionId && vv.id === id,
                );
                if (pivot) {
                    pivot.value = value;
                    return prev.map((v) => ({
                        ...v,
                    }));
                } else {
                    if (
                        id === 'general' &&
                        prev.filter((v) => v.sectionId === sectionId).length ===
                            0
                    ) {
                        return [
                            ...prev,
                            {
                                value,
                                labelKey:
                                    (
                                        reviewSections.find(
                                            (v) => v.id === sectionId,
                                        )?.generalTitle as MessageDescriptor
                                    ).id || 'error',
                                sectionId,
                                id,
                            },
                            ...(reviewSections
                                ?.find((s) => s.id === sectionId)
                                ?.values.map((v) => ({
                                    id: v.id,
                                    labelKey:
                                        (v.label as MessageDescriptor).id ||
                                        'error',
                                    sectionId,
                                    value,
                                })) || []),
                        ];
                    }
                    return [
                        ...prev,
                        {
                            value,
                            labelKey:
                                (
                                    reviewSections.find(
                                        (v) => v.id === sectionId,
                                    )?.generalTitle as MessageDescriptor
                                ).id || 'error',
                            sectionId,
                            id,
                        },
                    ];
                }
            }),
        [reviewSections],
    );
    /**
     * render
     */
    return (
        <View>
            {reviewSections.map((section) => (
                <View key={section.id}>
                    {!!section.title && (
                        <CText message={section.title} secondaryHeadline />
                    )}
                    <View style={[style.horizontalSplit, style.centeredItems]}>
                        <CText message={section.generalTitle} />
                        <StarInput
                            stars={
                                values.find(
                                    (v) =>
                                        v.sectionId === section.id &&
                                        v.id === 'general',
                                )?.value || 0
                            }
                            onChange={(value) =>
                                updateReviewValue(value, section.id, 'general')
                            }
                        />
                    </View>
                    {section.values && section.values.length && (
                        <View>
                            <View style={[style.paddedThinSeparator]} />
                            {section.values.map((v) => (
                                <View
                                    style={[
                                        style.horizontalSplit,
                                        style.centeredItems,
                                        style.verticalPadded,
                                    ]}
                                    key={v.id}
                                >
                                    <CText message={v.label} />
                                    <StarInput
                                        stars={
                                            values.find(
                                                (vv) =>
                                                    vv.sectionId ===
                                                        section.id &&
                                                    vv.id === v.id,
                                            )?.value || 0
                                        }
                                        onChange={(next) =>
                                            updateReviewValue(
                                                next,
                                                section.id,
                                                v.id,
                                            )
                                        }
                                    />
                                </View>
                            ))}
                        </View>
                    )}
                    <CTextInput
                        multiline={4}
                        value={
                            texts.find((t) => t.sectionId === section.id)
                                ?.text || ''
                        }
                        onChangeText={(s) => {
                            onChangeText((prev) => {
                                const pivot = prev.findIndex(
                                    (v) => v.sectionId === section.id,
                                );
                                const next = [...prev];
                                if (pivot >= 0) {
                                    next.splice(pivot, 1, {
                                        sectionId: section.id,
                                        text: s,
                                    });
                                } else {
                                    next.push({
                                        sectionId: section.id,
                                        text: s,
                                    });
                                }
                                return next;
                            });
                        }}
                        autoExtend
                        placeholder={reviewMessages.freeFormTextReview}
                    />
                </View>
            ))}
        </View>
    );
};
