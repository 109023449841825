import React, { FC, useCallback, useContext } from 'react';
import {
    ref,
    uploadBytes,
    getMetadata,
    deleteObject,
    getDownloadURL,
    getBytes,
    FullMetadata,
} from 'firebase/storage';

import { IDefaultProps } from '../../IDefaultProps';
import { FireAppContext } from '../app';
import { FireStorageContext } from './FireStorageContext';
import { FireAuthContext } from '../auth';

export const FireStorageProvider: FC<IDefaultProps> = ({ children }) => {
    const { storage } = useContext(FireAppContext);
    const { refreshToken } = useContext(FireAuthContext);
    /**
     * function to upload a file
     * @param bytes filename
     * @param dir filename
     * @param progressCB
     */
    const uploadToStorage = useCallback(
        async (
            filename: string,
            source: Uint8Array | string,
            progressCB?: (m: string) => void,
            options?: { didRefreshToken?: boolean },
        ): Promise<FullMetadata> => {
            if (typeof source === 'string') {
                throw 'invalid usage of upload function';
            }
            const tStorage = storage;
            const storageRef = ref(tStorage, filename);
            if (progressCB) {
                progressCB('starting upload');
            }
            try {
                await uploadBytes(storageRef, source);
            } catch (e) {
                const code = (e as { code: string }).code;

                if (
                    code.includes('unauthorized') &&
                    !options?.didRefreshToken
                ) {
                    await refreshToken();
                    console.log('retrying upload after token refresh');
                    return uploadToStorage(filename, source, progressCB, {
                        didRefreshToken: true,
                    });
                } else {
                    throw e;
                }
            }
            if (progressCB) {
                progressCB('upload complete');
            }
            return await getMetadata(storageRef);
        },
        [storage, refreshToken],
    );
    /**
     * function get metadata of a file
     * @param filename filename
     */
    const getFileMetadata = useCallback(
        async (filename: string) => {
            const tStorage = storage;
            const storageRef = ref(tStorage, filename);
            return await getMetadata(storageRef);
        },
        [storage],
    );
    /**
     * function to get file bytes
     * @param filename filename
     */
    const getFile = useCallback(
        async (filename: string) => {
            const tStorage = storage;
            const storageRef = ref(tStorage, filename);
            return await getBytes(storageRef);
        },
        [storage],
    );
    /**
     * function get the download url of a file
     * @param filename filename
     */
    const getFileDownloadUrl = useCallback(
        async (filename: string) => {
            const tStorage = storage;
            const storageRef = ref(tStorage, filename);
            return await getDownloadURL(storageRef);
        },
        [storage],
    );
    /**
     * function to remove a file
     * @param fullPath fullPath
     */
    const removeFile = useCallback(
        async (fullPath: string) => {
            try {
                const tStorage = storage;
                const storageRef = ref(tStorage, fullPath);
                await deleteObject(storageRef);
                return;
            } catch (e) {
                throw e;
            }
        },
        [storage],
    );
    /**
     * provide context
     */
    return (
        <FireStorageContext.Provider
            value={{
                uploadToStorage,
                getFileMetadata,
                removeFile,
                getFileDownloadUrl,
                getFile,
            }}
        >
            {children}
        </FireStorageContext.Provider>
    );
};
