import React, { FC, useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { CText, CButton } from '../../../../components';
import {
    MChat,
    MChatMessage,
    MSupportRequest,
    MUserData,
} from '../../../../models';
import { supportRequestMessages } from '../../supportRequest.messages';
import { useFireBase } from '../../../../utilities/firebase';
import { ECollections } from '../../../../enums';
import { useNavigate } from '../../../../utilities/routing';
import { useStyle } from '../../../../utilities/styles';

export const SupportRequestRow: FC<{ request: MSupportRequest }> = ({
    request,
}) => {
    // global state
    const { post, put, userData, getDataById } = useFireBase();
    const navigate = useNavigate();
    const style = useStyle();
    // local state
    const [user, setUser] = useState<MUserData>();
    const [closedBy, setClosedBy] = useState<MUserData>();
    const [assignedAgent, setAssignedAgent] = useState<MUserData>();
    /**
     * callback to open chat with user who issued the request
     */
    const openChat = useCallback(
        (request: MSupportRequest) => {
            if (request.chatId) {
                navigate('/chat/' + request.chatId);
            } else {
                post(
                    ECollections.chats,
                    new MChat({
                        createdOn: Date.now(),
                        supportRequestId: request.documentId,
                        participants: [userData.documentId, request.userId],
                    }),
                ).then((res) => {
                    if (res) {
                        post(
                            ECollections.chatMessages,
                            new MChatMessage({
                                message: request.reason,
                                createdOn: Date.now(),
                                chat: res.id,
                                from: 'system',
                                participants: [
                                    userData.documentId,
                                    request.userId,
                                ],
                            }),
                        );
                        put(ECollections.supportRequests, request.documentId, {
                            ...request,
                            chatId: res.id,
                            state: 'active',
                            managedBy: userData.documentId,
                        }).then(() => navigate('/chat/' + res.id));
                    }
                });
            }
        },
        [userData, request],
    );
    /**
     * effect to load author to request
     */
    useEffect(() => {
        getDataById(ECollections.users, request.userId).then((rawUser) => {
            setUser(new MUserData(rawUser));
        });
    }, [request]);
    /**
     * effect to load assigned agent to request
     */
    useEffect(() => {
        if (request.managedBy) {
            getDataById(ECollections.users, request.managedBy).then((rawUser) =>
                setAssignedAgent(rawUser),
            );
        }
    }, [request]);
    /**
     * effect to load closing user to request
     */
    useEffect(() => {
        if (request.closedBy) {
            getDataById(ECollections.users, request.closedBy).then(
                (rawUser) => {
                    setClosedBy(new MUserData(rawUser));
                },
            );
        }
    }, [request]);
    /**
     * render
     */
    return (
        <View
            key={request.documentId}
            style={[style.card, style.horizontalSplit]}
        >
            <View>
                <View style={style.horizontal}>
                    <CText
                        message={new Date(request.createdOn).toLocaleDateString(
                            'de',
                        )}
                    />
                    <View style={style.horizontalPadded} />
                    <CText
                        message={new Date(request.createdOn).toLocaleTimeString(
                            'de',
                            { hour: '2-digit', minute: '2-digit' },
                        )}
                    />
                </View>
                <CText secondaryHeadline message={request.reason} />
                {user && (
                    <CText message={`${user.firstName} ${user.lastName}`} />
                )}
            </View>
            {request.state !== 'closed' && (
                <CButton
                    title={supportRequestMessages.initChat}
                    onPress={() => openChat(request)}
                />
            )}
            {!!assignedAgent && (
                <CText
                    message={`${assignedAgent.firstName} ${assignedAgent.lastName}`}
                />
            )}
            {!!closedBy && (
                <CText message={`${closedBy.firstName} ${closedBy.lastName}`} />
            )}
        </View>
    );
};
