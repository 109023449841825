import { defineMessages } from 'react-intl';

const scope = 'component.ooc.';

export const oocMessages = defineMessages({
    componentOutOfContext: {
        id: scope + 'componentOutOfContext',
        defaultMessage:
            'Component {component} was rendered outside of intended context',
    },
});
