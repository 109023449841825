import { ERegion } from '../enums';
import { MAddress } from './MAddress';
import { MBankDetails } from './MBankDetails';
import { MAgencyFile } from './MFile';
/**
 * agency model
 */
export class MAgency {
    /**
     * document id set during load
     */
    public documentId = '';
    /**
     * name of the institution
     */
    public name = '';
    public picture = '';
    public taxNumber = '';
    public registerNumber = '';
    /**
     * uid of owner (first creator)
     */
    public owner = '';
    public users: string[] = [];

    public licenseUntil = 0;
    /**
     * bank details of agency
     */
    public bankDetails = new MBankDetails();

    public address = new MAddress();
    public billingAddressDiffers = false;
    public billingAddress = new MAddress();
    /**
     * files associated with agency
     */
    public files: MAgencyFile[] = [];

    public region = ERegion.de;

    public editedOn = Date.now();
    public createdOn = Date.now();

    public enableTimeTracking = false;
    public useGeolocation = false;
    public shiftLength = 480;
    public breakLength = 45;

    public averageRating = 0;
    public averageProfileRating = 0;
    public reviewCount = 0;

    constructor(params: Partial<MAgency> = {}) {
        Object.assign(this, params);

        if (params.bankDetails) {
            this.bankDetails = new MBankDetails(params.bankDetails);
        }
        if (params.owner && !params.users?.length) {
            this.users = [params.owner];
        }
        if (params.files) {
            this.files = params.files.map((f) => {
                if (!f.path) {
                    return new MAgencyFile({ path: f.name, type: f.type });
                } else {
                    return new MAgencyFile(f);
                }
            });
        }
    }
}
