import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { useNavigate } from '../../utilities/routing';
import { CButton } from '../../components';
import { useFireBase } from '../../utilities/firebase';
import { useStyle } from '../../utilities/styles';
import { ProfileCompletion } from './components/ProfileCompletion';
import { RecentNotifications } from './components/RecentNotifications';
import { MatchingJobs } from './components/MatchingJobs';
import {
    isAgencyUser,
    isEmployer,
    isPeasant,
    isSuperUser,
} from '../../utilities/auth';
import { HintCarousel } from './components/HintCarousel';
import { ProfileCompletionContext } from '../../utilities/contexts';
import { TodaysSignUps } from './components/TodaysSignUps';
import { TodaysNewApplications } from './components/TodaysNewApplications';
import { SendCustomMail } from './components/SendCustomMail';
import { ScrollProvider } from '../../utilities/contexts/Scroll';
import { EUserType } from '../../enums';
import { ActiveJobs } from './components/ActiveJobs';
import { ActiveNegotiations } from './components/ActiveNegotiations';
import { jobMessages } from '../Job/job.messages';
import { OpenApplications } from './components/OpenApplications';
import { DashboardComponentContracts } from './components/Contracts';
import { Welcome } from './components/Welcome';
import { AgencyTimeTracking } from './components/AgencyTimeTracking';
import { useDialog } from '../../utilities/dialog';

export const Dashboard: FC = () => {
    // global state
    const { userData, userAgencies } = useFireBase();
    const style = useStyle();
    const navigate = useNavigate();
    const dialog = useDialog();
    const { progress } = useContext(ProfileCompletionContext);
    // local state
    const [cHidden, setCHidden] = useState(false);
    const [hHidden, setHHidden] = useState(false);
    /**
     * effect to check if completion should be hidden
     */
    useEffect(() => {
        if (progress == 1) {
            AsyncStorage.getItem('completionHidden', (e, result) => {
                if (e) {
                    throw e;
                } else if (result) {
                    if (result === userData.documentId) {
                        setCHidden(true);
                    } else {
                        AsyncStorage.removeItem('completionHidden');
                    }
                }
            });
            AsyncStorage.getItem('hintsHidden', (e, result) => {
                if (e) {
                    throw e;
                } else if (result) {
                    if (result === userData.documentId) {
                        setHHidden(true);
                    } else {
                        AsyncStorage.removeItem('hintsHidden');
                    }
                }
            });
        }
    }, [progress]);
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <Welcome />
            {isAgencyUser(userData) && userAgencies[0]?.enableTimeTracking && (
                <AgencyTimeTracking />
            )}
            {!cHidden ? (
                <ProfileCompletion changeHidden={() => setCHidden(true)} />
            ) : !hHidden ? (
                [EUserType.agency, EUserType.user].includes(userData.type) && (
                    <HintCarousel changeHidden={() => setHHidden(true)} />
                )
            ) : (
                <Fragment />
            )}
            {isEmployer(userData) && userData.verified && (
                <View style={{ marginHorizontal: 40 }}>
                    <CButton
                        title={jobMessages.createNew}
                        onPress={() =>
                            dialog({
                                title: jobMessages.createFromDraftDialog,
                                message: jobMessages.createFromDraftDialogText,
                                buttons: [
                                    {
                                        text: jobMessages.openDrafts,
                                        onPress: () =>
                                            navigate(
                                                '/japplications?mode=draft',
                                            ),
                                    },
                                    {
                                        text: jobMessages.createNew,
                                        onPress: () => navigate('/job/new'),
                                    },
                                ],
                            })
                        }
                        icon="plus"
                        nativeId="createJob-button"
                    />
                </View>
            )}
            {(isEmployer(userData) || isPeasant(userData)) &&
                userData.verified && <DashboardComponentContracts />}
            {isSuperUser(userData) && (
                <View style={[style.horizontalWrap]}>
                    <View
                        style={{
                            flexGrow: 1,
                        }}
                    >
                        <TodaysSignUps />
                    </View>
                    <View
                        style={{
                            flexGrow: 1,
                        }}
                    >
                        <TodaysNewApplications />
                    </View>
                </View>
            )}
            {isEmployer(userData) && (
                <View style={[style.horizontalWrap]}>
                    <View
                        style={{
                            flexGrow: 1,
                        }}
                    >
                        <ActiveJobs />
                    </View>
                    <View
                        style={{
                            flexGrow: 1,
                        }}
                    >
                        <OpenApplications />
                    </View>
                    <View
                        style={{
                            flexGrow: 1,
                        }}
                    >
                        <ActiveNegotiations />
                    </View>
                </View>
            )}
            <View style={[style.horizontalWrap]}>
                {isPeasant(userData) && (
                    <View
                        style={{
                            flexGrow: 1,
                            flexShrink: 1,
                            maxWidth: isPeasant(userData) ? 520 : undefined,
                        }}
                    >
                        <MatchingJobs />
                    </View>
                )}
                <View
                    style={{
                        flexGrow: 1,
                        flexShrink: 1,
                        maxWidth: isPeasant(userData) ? 520 : undefined,
                    }}
                >
                    <RecentNotifications />
                </View>
            </View>
            {isSuperUser(userData) && <SendCustomMail />}
        </ScrollProvider>
    );
};
