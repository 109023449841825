import React, { FC, useMemo, useState } from 'react';
import { View } from 'react-native';
import { CButton, CIcon, CText } from '../../../../components';
import { MEducation } from '../../../../models';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { EducationEdit } from './EducationEdit';
import { monthMessages } from '../../../../utilities/messages';
import { useFormat } from '../../../../utilities/intl';

export interface IEducationProps {
    education: MEducation;
    onChange: (next: Partial<MEducation>) => void;
    zIndex: number;
    removeSelf: () => void;
    fresh: boolean;
}

export const EducationView: FC<IEducationProps> = ({
    education,
    onChange,
    zIndex,
    removeSelf,
    fresh,
}) => {
    const style = useStyle();
    const { theme } = useTheme();
    const format = useFormat();
    const [edit, setEdit] = useState(fresh || false);

    const months = useMemo(() => {
        const monthKeys = Object.keys(monthMessages).filter(
            (m) => !m.includes('Short'),
        );

        return monthKeys.map(
            (k) => monthMessages[k as keyof typeof monthMessages],
        );
    }, []);

    if (edit) {
        return (
            <EducationEdit
                education={education}
                onChange={onChange}
                endEdit={() => setEdit(false)}
                zIndex={zIndex}
                removeSelf={removeSelf}
                fresh={fresh}
            />
        );
    }
    return (
        <View style={[style.horizontalSplit, style.verticalPadded]}>
            <View style={[style.horizontal, style.centeredItems]}>
                <View
                    style={{
                        width: 10,
                        height: 10,
                        borderRadius: 5,
                        backgroundColor: theme.accentColor,
                    }}
                />
                <View style={style.leftPadded}>
                    <CText headline>{education.institution}</CText>
                    <CText>
                        {education.degree} - {education.field}
                    </CText>
                    <View style={[style.horizontal]}>
                        <CIcon icon="calendar_outline" size={16} />
                        <CText style={style.leftPadded}>
                            {`${format(
                                months[new Date(education.from).getMonth()],
                            )} ${new Date(
                                education.from,
                            ).getFullYear()} - ${format(
                                months[new Date(education.to).getMonth()],
                            )} ${new Date(education.to).getFullYear()}`}
                        </CText>
                    </View>
                </View>
            </View>
            <View style={style.horizontal}>
                <CButton
                    transparent
                    icon={'close'}
                    iconColor={theme.errorColor}
                    onPress={removeSelf}
                />
                <CButton
                    transparent
                    icon={'cog'}
                    onPress={() => {
                        setEdit(true);
                    }}
                />
            </View>
        </View>
    );
};
