import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useNavigate, useParams } from '../../../utilities/routing';
import { useFormat } from '../../../utilities/intl';
import { useFireBase } from '../../../utilities/firebase';
import { useStyle, useTheme } from '../../../utilities/styles';
import { ECollections } from '../../../enums';
import { MJob } from '../../../models';
import { JobRow } from '../List/components/JobRow';
import { StandaloneWrapper } from '../../../utilities/wrappers';
import { CButton, Spinner } from '../../../components';
import { startMessages } from '../../Start/start.messages';
import { actionMessages } from '../../../utilities/messages';

const UnwrappedJobView: FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { theme } = useTheme();
    const { getDataById, user, isLoggedIn } = useFireBase();
    const format = useFormat();
    const style = useStyle();
    // local state
    const [job, setJob] = useState(
        new MJob({
            from: Date.now(),
            createdOn: Date.now(),
            author: user?.uid || '',
        }),
    );
    const [loading, setLoading] = useState(true);

    /**
     * effect to load prev data
     */
    useEffect(() => {
        if (id && id !== 'new') {
            getDataById(ECollections.jobs, id).then((result) => {
                const next = new MJob(result);
                setJob(next);
                setLoading(false);
            });
        } else {
            navigate('/');
        }
    }, [id]);

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <View
                style={[
                    isLoggedIn ? style.card : style.embeddedCard,
                    ,
                    style.horizontalSplit,
                ]}
            >
                <CButton
                    cy={'back'}
                    onPress={async () => {
                        !isLoggedIn ? navigate('/jobs') : navigate(-1);
                    }}
                    icon={'chevronLeft'}
                    small
                />
                {!isLoggedIn && (
                    <CButton
                        cy={'register'}
                        title={actionMessages.register}
                        onPress={() => navigate('/')}
                        small
                    />
                )}
            </View>
            <JobRow job={job} forceOpen noButtons={!isLoggedIn} />
        </>
    );
};

export const JobView: FC = () => {
    const { isLoggedIn } = useFireBase();
    const style = useStyle();

    if (isLoggedIn) {
        return (
            <View style={style.paddedMainView}>
                <UnwrappedJobView />
            </View>
        );
    } else {
        return (
            <StandaloneWrapper>
                <UnwrappedJobView />
            </StandaloneWrapper>
        );
    }
};
