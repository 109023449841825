import { FullMetadata } from 'firebase/storage';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useNavigate, useParams } from '../../../utilities/routing';
import { CButton, CText, Spinner } from '../../../components';
import PDFViewer from '../../../components/PDFViewer';
import { Signature } from '../../../components/Signature';
import { ECollections, EUserType } from '../../../enums';
import { MContract } from '../../../models';
import { isPeasant } from '../../../utilities/auth';
import { ScrollProvider } from '../../../utilities/contexts/Scroll';
import { useFireBase } from '../../../utilities/firebase';
import { capitalize, useFormat } from '../../../utilities/intl';
import { actionMessages } from '../../../utilities/messages';
import { useStyle } from '../../../utilities/styles';
import { contractMessages } from '../../Contract/contract.messages';
import { Login } from '../../Start/components';
import { StandaloneWrapper } from '../../../utilities/wrappers';
/**
 * sign the contract from both sides
 * @param param0 props
 * @returns
 */
export const SignContractFromUrl: FC = () => {
    // global state
    const style = useStyle();
    const { negotiationId, hash } =
        useParams<{ negotiationId: string; hash: string }>();
    const format = useFormat();
    const navigate = useNavigate();
    const { callFunction, getFileMetadata, userData, isLoggedIn, getDataById } =
        useFireBase();
    // local state
    const [contractFilename, setContractFilename] = useState<string>();
    const [contractMeta, setContractMeta] = useState<FullMetadata>();
    /**
     * memoized loading value. returns true while filename or meta is unset
     */
    const loading = useMemo(
        () => !contractFilename || !contractMeta,
        [contractFilename, contractMeta],
    );
    /**
     * memoized value if user has signed by metaData (set by sign fcf)
     */
    const hasSigned = useMemo(() => {
        if (contractMeta) {
            const cM = contractMeta.customMetadata;
            if (cM) {
                const signaturePositions: {
                    type: EUserType;
                    signed: boolean;
                }[] = JSON.parse(cM.signaturePositions);
                console.log(signaturePositions);
                return !!signaturePositions.find(
                    (sP) =>
                        (userData.type === EUserType.agency
                            ? sP.type === EUserType.user
                            : sP.type === userData.type) && sP.signed,
                );
            }
        }
        return false;
    }, [contractMeta, userData]);
    /**
     * handle signature
     */
    const handleSignature = useCallback(
        async (signature: string) => {
            if (contractFilename && negotiationId) {
                await callFunction('signContract', {
                    contractFilename,
                    signature,
                    negotiationId,
                });
            }
        },
        [contractFilename, negotiationId],
    );
    /**
     * effect to set filename and read meta (meta should already be set)
     */
    useEffect(() => {
        if (negotiationId && hash && isLoggedIn) {
            getDataById(ECollections.contracts, negotiationId).then((v) => {
                const next = new MContract(v);

                const filepath = `contracts/${negotiationId}/${
                    userData.type === EUserType.user
                        ? userData.documentId
                        : next.workplaceId
                }/contract_${hash}.pdf`;
                setContractFilename(filepath);
                getFileMetadata(filepath).then((nM) => setContractMeta(nM));
            });
        }
    }, [negotiationId, hash, isLoggedIn]);
    /**
     * return login component if user is not logged in
     */
    if (!isLoggedIn) {
        return (
            <StandaloneWrapper>
                <Login forgot={() => navigate('/?fogot=1')} />
            </StandaloneWrapper>
        );
    }
    /**
     * while document not present return spinner
     */
    if (loading) {
        return <Spinner />;
    }
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            {contractFilename && <PDFViewer filename={contractFilename} />}
            {(userData.type === EUserType.employer || isPeasant(userData)) &&
            !hasSigned ? (
                <Signature
                    text={capitalize(format(actionMessages.sign))}
                    onOK={handleSignature}
                />
            ) : (
                <View style={[style.centeredItems, style.verticalPadded]}>
                    <CText>{format(contractMessages.alreadySigned)}</CText>
                </View>
            )}
            <View style={style.horizontalSpaced}>
                <CButton
                    title={format(actionMessages.cancel)}
                    onPress={() => navigate('/')}
                    warning
                />
            </View>
        </ScrollProvider>
    );
};
