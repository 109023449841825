import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { View } from 'react-native';
import { MAddress } from '../../models/MAddress';
import { useFormat } from '../../utilities/intl';
import { CText, CTextInput, InfoBox, Spinner } from '../';
import { addressMessages } from './address.messages';
import { useDebounce } from '../../utilities/hooks/useDebounce';
import {
    fromAddressToLatLng,
    fromLatLngToAddress,
} from '../../utilities/functions';
import { LanguageContext } from '../../utilities/intl';
import { useStyle } from '../../utilities/styles';
import { isLocalhost } from '../../utilities/constants';
import { Message } from '../../utilities/types';
/**
 * interface for address eidt
 */
export interface IAddressEdit {
    cyId?: string;
    address: MAddress;
    setAddress: (address: MAddress) => void;
    title: Message;
    cy?: string;
    titleAsHeadline?: boolean;
    titleAsSecondaryHeadline?: boolean;
}
/**
 * address input component which automatically gets lat lng based on input address
 * @param param0 props
 * @returns
 */
export const AddressInput: FC<IAddressEdit> = ({
    address,
    setAddress,
    title,
    cy,
    titleAsHeadline,
    titleAsSecondaryHeadline,
}) => {
    // global state
    const format = useFormat();
    const style = useStyle();
    const { language } = useContext(LanguageContext);
    // local state
    const [checkedAddress, setCheckedAddress] = useState<MAddress>();
    const [prevCheckAddress, setPreviouslyCheckedAddress] =
        useState<MAddress>();

    const [checking, setChecking] = useState(false);
    const [unableToCheck, setUnableToCheck] = useState(false);
    // debounced address to be used in checking and write back
    const debouncedAddress = useDebounce<MAddress>(address, 1000);
    /**
     * check if the check differs from current input
     */
    const checkDiffers = useMemo(() => {
        if (
            checkedAddress &&
            (address.street !== checkedAddress.street ||
                address.city !== checkedAddress.city ||
                address.zipCode !== checkedAddress.zipCode ||
                address.country !== checkedAddress.country)
        ) {
            return true;
        }
        return false;
    }, [checkedAddress, address]);
    /**
     * update callback sets local address and set checking to true
     */
    const update = useCallback(
        (input: Partial<MAddress>) => {
            const next = new MAddress(address);
            Object.assign(next, input);
            setAddress(next);
        },
        [address],
    );
    /**
     * effect based on debounced local address to get lat lng and check address
     */
    useEffect(() => {
        if (debouncedAddress) {
            if (
                debouncedAddress.street &&
                debouncedAddress.number &&
                debouncedAddress.city &&
                debouncedAddress.country &&
                debouncedAddress.zipCode &&
                JSON.stringify(debouncedAddress) !==
                    JSON.stringify(prevCheckAddress) &&
                JSON.stringify(debouncedAddress) !==
                    JSON.stringify(checkedAddress)
            ) {
                setPreviouslyCheckedAddress(debouncedAddress);
                setChecking(true);
                setUnableToCheck(false);
                const adrStr = `${debouncedAddress.street} ${debouncedAddress.number}, ${debouncedAddress.zipCode} ${debouncedAddress.city}, ${debouncedAddress.country}`;
                fromAddressToLatLng(adrStr, language)
                    .then((geolocation) => {
                        const next = { ...geolocation };
                        update(next);
                        fromLatLngToAddress(
                            geolocation.lat,
                            geolocation.lng,
                            language,
                        ).then((value) => {
                            setChecking(false);
                            setCheckedAddress({ ...value, ...geolocation });
                        });
                    })
                    .catch(() => {
                        setChecking(false);
                        setUnableToCheck(true);
                    });
            }
        }
    }, [debouncedAddress]);
    /**
     * render
     */
    return (
        <View>
            <CText
                message={title}
                secondaryHeadline={titleAsSecondaryHeadline}
                headline={titleAsHeadline}
            />
            <CTextInput
                cy={`${cy}-street`}
                label={addressMessages.street}
                placeholder={addressMessages.streetPlaceholder}
                onChangeText={(street) => update({ street })}
                value={address.street}
                autoExtend
            />
            <CTextInput
                cy={`${cy}-number`}
                label={addressMessages.number}
                placeholder={addressMessages.numberPlaceholder}
                onChangeText={(number) => update({ number })}
                value={address.number}
                autoExtend
            />
            <CTextInput
                cy={`${cy}-city`}
                label={addressMessages.city}
                placeholder={addressMessages.cityPlaceholder}
                onChangeText={(city) => update({ city })}
                value={address.city}
                autoExtend
            />
            <CTextInput
                cy={`${cy}-zipCode`}
                label={addressMessages.zipCode}
                placeholder={addressMessages.zipCodePlaceholder}
                onChangeText={(zipCode) => update({ zipCode })}
                value={address.zipCode}
                autoExtend
            />
            <CTextInput
                cy={`${cy}-country`}
                label={addressMessages.country}
                placeholder={addressMessages.countryPlaceholder}
                onChangeText={(country) => update({ country })}
                value={address.country}
                autoExtend
            />
            {isLocalhost() && address.lat && address.lng && (
                <CText>
                    lat: {address.lat} lng: {address.lng}
                </CText>
            )}
            {checking && <Spinner />}
            {!checking && (
                <View style={style.verticalPadded}>
                    {checkedAddress && checkDiffers && (
                        <InfoBox
                            type={'warning'}
                            message={format(addressMessages.checkDiffers)}
                            text={`${checkedAddress.street} ${checkedAddress.number}, ${checkedAddress.zipCode} ${checkedAddress.city}, ${checkedAddress.country}`}
                            customFunction={() => {
                                setAddress(checkedAddress);
                            }}
                        />
                    )}
                    {unableToCheck && (
                        <InfoBox
                            type={'error'}
                            message={format(addressMessages.unableToCheck)}
                            text={format(
                                addressMessages.maybeSomeThingWrongWithAddress,
                            )}
                        />
                    )}
                </View>
            )}
        </View>
    );
};
