import React, { FC, useMemo } from 'react';
import { makeLabelStyles } from './styles';
import { StyleProp, ViewStyle } from 'react-native';
import { useStyle, useTheme } from '../../../utilities/styles';
import { CText } from '../CText';
import { CIcon } from '../CIcon';
import { TouchableView } from '../../TouchableView';
import { Message } from '../../../utilities/types';

export interface ICLabelProps {
    checked?: boolean;
    title: Message;
    style?: StyleProp<ViewStyle>;
    color?: string;
    onCheckedChanged?: (check: boolean) => void;
    cyId?: any;
    endIcon?: string;
    startIcon?: string;
    small?: boolean;
    smaller?: boolean;
}

export const CLabel: FC<ICLabelProps> = ({
    cyId,
    startIcon,
    endIcon,
    title,
    onCheckedChanged,
    color,
    checked,
    small,
    smaller,
    style: propStyle,
}) => {
    // global state
    const { theme } = useTheme();
    const style = useStyle();
    // local state
    const localStyles = useMemo(() => makeLabelStyles(theme), [theme]);
    /**
     * render
     */
    return (
        <TouchableView
            {...{ dataSet: cyId }}
            onPress={
                onCheckedChanged ? () => onCheckedChanged(!checked) : undefined
            }
            style={[
                localStyles.label,
                checked === false ? localStyles.unchecked : localStyles.check,
                style.horizontal,
                !!color && {
                    borderColor: color,
                },
                small && localStyles.buttonSmall,
                smaller && localStyles.buttonSmaller,
                propStyle,
            ]}
        >
            {!!startIcon && (
                <CIcon
                    size={20}
                    icon={startIcon}
                    tint={color || theme.accentColor}
                />
            )}
            <CText
                style={[
                    !checked && localStyles.labelUnchecked,
                    checked && [localStyles.labelChecked, { color: color }],
                    smaller && { fontSize: 12 },
                ]}
                message={title}
            />
            {!!endIcon && (
                <CIcon
                    size={20}
                    icon={endIcon}
                    tint={color || theme.accentColor}
                />
            )}
        </TouchableView>
    );
};
