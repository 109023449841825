import React, { Dispatch, FC, SetStateAction } from 'react';
import { View } from 'react-native';
import { IDialogPickers } from '../IDialog';
import { CPicker } from '../../../components';

export const PickerInputs: FC<{
    values: { id: string; value: any }[];
    pickerInputs: IDialogPickers[];
    onChange: Dispatch<SetStateAction<{ id: string; value: any }[]>>;
}> = ({ values, onChange, pickerInputs }) => {
    return (
        <View>
            {pickerInputs.map((pI, i) => {
                const value = values.find((tiv) => tiv.id === pI.id);
                return (
                    <CPicker
                        key={pI.id}
                        values={pI.values}
                        placeholder={pI.placeholder}
                        title={pI.title}
                        onChange={(v) => {
                            onChange((prev) => {
                                const p = prev.find((tiv) => tiv.id === pI.id);
                                if (p) {
                                    p.value = v;
                                } else {
                                    prev.push({ id: pI.id, value: v });
                                }
                                return Array.from(prev);
                            });
                        }}
                        value={value ? value.value : undefined}
                    />
                );
            })}
        </View>
    );
};
