import { defineMessages } from 'react-intl';

const scope = 'medic.doctor.education.';
/**
 * doctorEducation names
 * is used for doctorEducation picker
 */
export const doctorEducationMessages = defineMessages({
    generalSurgery: {
        id: `${scope}generalSurgery`,
        defaultMessage: 'General Surgery',
    },
    generalMedicine: {
        id: `${scope}generalMedicine`,
        defaultMessage: 'General Medicine',
    },
    anesthesiology: {
        id: `${scope}anesthesiology`,
        defaultMessage: 'Anesthesiology',
    },
    anatomy: { id: `${scope}anatomy`, defaultMessage: 'Anatomy' },
    occupationalMedicine: {
        id: `${scope}occupationalMedicine`,
        defaultMessage: 'Occupational Medicine',
    },
    ophthalmology: {
        id: `${scope}ophthalmology`,
        defaultMessage: 'Ophthalmology',
    },
    biochemistry: {
        id: `${scope}biochemistry`,
        defaultMessage: 'Biochemistry',
    },
    surgery: { id: `${scope}surgery`, defaultMessage: 'Surgery' },
    gynaecology: { id: `${scope}gynaecology`, defaultMessage: 'Gynaecology' },
    otorhinolaryngology: {
        id: `${scope}otorhinolaryngology`,
        defaultMessage: 'Otorhinolaryngology',
    },
    speechAndVoiceChildhoodHearingDisorders: {
        id: `${scope}speechAndVoiceChildhoodHearingDisorders`,
        defaultMessage: 'Speech, voice and childhood hearing disorders',
    },
    skinAndVenerealDiseases: {
        id: `${scope}skinAndVenerealDiseases `,
        defaultMessage: 'Skin and venereal diseases',
    },
    cardiacSurgery: {
        id: `${scope}cardiacSurgery`,
        defaultMessage: 'Cardiac Surgery',
    },
    humanGenetics: {
        id: `${scope}humanGenetics`,
        defaultMessage: 'Human Genetics',
    },
    hygieneAndEnvironmentalMedicine: {
        id: `${scope}hygieneAndEnvironmentalMedicine`,
        defaultMessage: 'Hygiene and Environmental Medicine',
    },
    internist: { id: `${scope}internist`, defaultMessage: 'Internist' },
    paediatricsAndAdolescentMedicine: {
        id: `${scope}paediatricsAndAdolescentMedicine`,
        defaultMessage: 'Paediatrics and Adolescent Medicine',
    },
    paediatricSurgery: {
        id: `${scope}paediatricSurgery`,
        defaultMessage: 'Paediatric Surgery',
    },
    paediatrics: {
        id: `${scope}paediatrics`,
        defaultMessage: 'Child and Adolescent Medicine (Paediatrics)',
    },
    ChildAndAdolescentPsychiatryAndPsychotherapy: {
        id: `${scope}ChildAndAdolescentPsychiatryAndPsychotherapy`,
        defaultMessage: 'Child and Adolescent Psychiatry and Psychotherapy',
    },
    clinicalPharmacology: {
        id: `${scope}clinicalPharmacology`,
        defaultMessage: 'Clinical Pharmacology',
    },
    laboratoryMedicine: {
        id: `${scope}laboratoryMedicine`,
        defaultMessage: 'Laboratory Medicine (Clinical Chemistry)',
    },
    microbiologyAndEpidemiology: {
        id: `${scope}microbiologyAndEpidemiology`,
        defaultMessage:
            'Microbiology, Virology and Infectious Disease Epidemiology',
    },
    oralAndMaxillofacialSurgery: {
        id: `${scope}oralAndMaxillofacialSurgery`,
        defaultMessage: 'Oral and Maxillofacial Surgery',
    },
    neurosurgery: {
        id: `${scope}neurosurgery`,
        defaultMessage: 'Neurosurgery',
    },
    neurology: { id: `${scope}neurology`, defaultMessage: 'Neurology' },
    neuropathology: {
        id: `${scope}neuropathology`,
        defaultMessage: 'Neuropathology',
    },
    neuroRehab: {
        id: `${scope}neuroRehab`,
        defaultMessage: 'Neurological rehab',
    },
    nuclearMedicine: {
        id: `${scope}nuclearMedicine`,
        defaultMessage: 'Nuclear Medicine',
    },
    publicHealth: {
        id: `${scope}publicHealth`,
        defaultMessage: 'Public Health',
    },
    orthopaedics: {
        id: `${scope}orthopaedics`,
        defaultMessage: 'Orthopaedics',
    },
    orthopaedicsAndTraumaSurgery: {
        id: `${scope}orthopaedicsAndTraumaSurgery`,
        defaultMessage: 'Orthopaedics and Trauma Surgery',
    },
    pathology: { id: `${scope}pathology`, defaultMessage: 'Pathology' },
    pharmacology: {
        id: `${scope}pharmacology`,
        defaultMessage: 'Pharmacology (and Toxicology)',
    },
    phoniatricsAndPaediatrics: {
        id: `${scope}phoniatricsAndPaediatrics`,
        defaultMessage: 'Phoniatrics and Paediatrics',
    },
    phoniatricsAndPaediatricAudiology: {
        id: `${scope}phoniatricsAndPaediatricAudiology`,
        defaultMessage: 'Phoniatrics and Paediatric Audiology',
    },
    physicalAndRehabilitationMedicine: {
        id: `${scope}physicalAndRehabilitationMedicine`,
        defaultMessage: 'Physical and Rehabilitation Medicine',
    },
    physiology: { id: `${scope}physiology`, defaultMessage: 'Physiology' },
    plasticAndAestheticSurgery: {
        id: `${scope}plasticAndAestheticSurgery and Aesthetic Surgeryrnist`,
        defaultMessage: 'Plastic and Aesthetic Surgery',
    },
    psychiatryAndPsychotherapy: {
        id: `${scope}psychiatryAndPsychotherapy`,
        defaultMessage: 'Psychiatry and Psychotherapy',
    },
    psychologicalPsychotherapy: {
        id: `${scope}psychologicalPsychotherapy`,
        defaultMessage: 'Psychological Psychotherapy',
    },
    psychosomaticMedicineAndPsychotherapy: {
        id: `${scope}psychosomaticMedicineAndPsychotherapy`,
        defaultMessage: 'Psychosomatic Medicine and Psychotherapy',
    },
    psychotherapeuticMedicine: {
        id: `${scope}psychotherapeuticMedicine`,
        defaultMessage: 'Psychotherapeutic Medicine',
    },
    gerontopsychiatry: {
        id: `${scope}gerontopsychiatry`,
        defaultMessage: 'Gerontopsychiatry',
    },
    radiology: { id: `${scope}radiology`, defaultMessage: 'Radiology' },
    forensicMedicine: {
        id: `${scope}forensicMedicine`,
        defaultMessage: 'Forensic Medicine',
    },
    reproductiveMedicine: {
        id: `${scope}reproductiveMedicine`,
        defaultMessage: 'Reproductive Medicine',
    },
    radiotherapy: {
        id: `${scope}radiotherapy`,
        defaultMessage: 'Radiotherapy',
    },
    thoracicSurgery: {
        id: `${scope}thoracicSurgery`,
        defaultMessage: 'Thoracic Surgery',
    },
    transfusionMedicine: {
        id: `${scope}transfusionMedicine`,
        defaultMessage: 'Transfusion Medicine',
    },
    urology: { id: `${scope}urology`, defaultMessage: 'Urology' },
    visceralSurgery: {
        id: `${scope}visceralSurgery`,
        defaultMessage: 'Visceral Surgery',
    },
    dentistry: { id: `${scope}dentistry`, defaultMessage: 'Dentistry' },
    geriatricRehab: {
        id: `${scope}geriatricRehab`,
        defaultMessage: 'Geriatric Rehab',
    },
});
