import { useEffect, useState } from 'react';

/**
 * hook to debounce any rapidly changing value (text value controlled by input f.e.)
 * @param value value to debounce
 * @param delay debounc delay in ms
 * @returns debounced value
 */
export function useDebounce<T>(value: T | undefined, delay: number) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState<T | undefined>(value);
    /**
     * effect to handle debounce magic
     */
    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay], // Only re-call effect if value or delay changes
    );
    /**
     * return debouncedValue
     */
    return debouncedValue;
}
