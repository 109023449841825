import React, {
    FC,
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { View } from 'react-native';
import { useFireBase } from '../../../utilities/firebase';
import { isAgencyUser } from '../../../utilities/auth';
import { ECollections } from '../../../enums';
import { MFromTo, MShift, MTimesheet } from '../../../models';
import { CButton, CCard, CText, Spinner } from '../../../components';
import { hour, minute, second, timeString } from '../../../utilities/functions';
import { dashboardMessages } from '../Dashboard.messages';
import { useStyle } from '../../../utilities/styles';
import { useFormat } from '../../../utilities/intl';
import { agencyMessages } from '../../Agency/agency.messages';
import { useSecureNavigate } from '../../../utilities/routing';

export const AgencyTimeTracking: FC = () => {
    const style = useStyle();
    const format = useFormat();
    const { secureNavigate } = useSecureNavigate();
    const { userData, userAgencies, getDataById, getDataIndex, put, post } =
        useFireBase();
    // local state
    const [timesheet, setTimesheet] = useState<MTimesheet>();
    const [shifts, setShifts] = useState<MShift[]>([]);
    const [openShifts, setOpenShifts] = useState<MShift[]>([]);
    const [now, setNow] = useState(Date.now());
    /**
     * uncompleted shift that startet within the last shift period
     */
    const activeShift = useMemo(() => {
        return shifts.find(
            (shift) =>
                shift.from >
                    Date.now() - userAgencies[0].shiftLength * minute &&
                !shift.complete,
        );
    }, [shifts]);
    /**
     * memoized currently active break in active shift
     */
    const activeBreak = useMemo(() => {
        if (activeShift) {
            const boi = activeShift.breaks[activeShift.breaks.length - 1];
            if (boi && boi.to > Date.now()) return boi;
        }
    }, [activeShift]);
    /**
     * memoized time in active shift
     */
    const timeInActiveShift = useMemo(() => {
        if (!activeShift) return '';
        const bTime = activeShift.breaks
            .filter(
                (_, i) => i < activeShift.breaks.length - (activeBreak ? 1 : 0),
            )
            .reduce((acc, b) => {
                acc += b.to - b.from;
                return acc;
            }, 0);
        const t =
            now -
            activeShift.from -
            bTime -
            (activeBreak ? now - activeBreak.from : 0);
        const h = Math.floor(t / hour);
        const m = Math.floor((t - hour * h) / minute);
        const s = Math.floor((t - hour * h - minute * m) / second);
        return timeString(h, m, s);
    }, [activeShift, activeBreak, now]);
    /**
     * maximum shift duration
     */
    const activeShiftEnd = useMemo(() => {
        if (!activeShift) return { to: 0, toHour: 0, toMinute: 0 };
        const at = activeShift.from + userAgencies[0].shiftLength * minute;
        const d = new Date(at);
        return {
            to: d.getTime(),
            toHour: d.getHours(),
            toMinute: d.getMinutes(),
        };
    }, [activeShift, userAgencies]);
    /**
     * maximum break duration
     */
    const activeBreakEnd = useMemo(() => {
        if (!activeBreak) return { to: 0, toHour: 0, toMinute: 0 };
        const at = activeBreak.from + userAgencies[0].breakLength * minute;
        const d = new Date(at);
        return {
            to: d.getTime(),
            toHour: d.getHours(),
            toMinute: d.getMinutes(),
        };
    }, [activeBreak, userAgencies]);
    /**
     * callback to complete the active shift
     */
    const completeShift = useCallback(() => {
        if (!activeShift) return;
        setShifts((prev) => {
            const index = prev.findIndex(
                (s) => s.documentId === activeShift.documentId,
            );
            const t = Date.now();
            const at = activeShift.from + userAgencies[0].shiftLength * minute;
            /**
             * TO Date to set. Either max shift length defined by agency or Date.now()
             */
            const d = new Date(t < at ? t : at);
            /**
             * shift to replace active shift with
             */
            const next = new MShift({
                ...activeShift,
                to: d.getTime(),
                toHour: d.getHours(),
                toMinute: d.getMinutes(),
                complete: true,
            });
            put(
                `${ECollections.agencies}/${userAgencies[0].documentId}/${ECollections.timesheets}/${userData.documentId}/${ECollections.shifts}`,
                next.documentId,
                next,
            );
            prev.splice(index, 1, next);
            return [...prev];
        });
    }, [activeShift]);
    /**
     * callback to start a new shift
     */
    const startNewShift = useCallback(async () => {
        const d = new Date();
        const newShift = new MShift({
            from: d.getTime(),
            fromHour: d.getHours(),
            fromMinute: d.getMinutes(),
            to: d.getTime(),
            toHour: d.getHours(),
            toMinute: d.getMinutes(),
            date: `${d.getDate()}`,
            month: `${d.getMonth() + 1}`,
            year: `${d.getFullYear()}`,
            timesheetId: userData.documentId,
        });
        const postResult = await post(
            `${ECollections.agencies}/${userAgencies[0].documentId}/${ECollections.timesheets}/${userData.documentId}/${ECollections.shifts}`,
            newShift,
        );
        if (postResult) {
            newShift.documentId = postResult.id;
            setShifts((prev) => {
                return [...prev, newShift];
            });
        }
    }, []);
    /**
     * callback to start a new break inside active shift
     */
    const takeBreak = useCallback(
        async () =>
            setShifts((prev) => {
                const fromD = new Date();
                const toD = new Date(
                    Date.now() + userAgencies[0].breakLength * minute,
                );
                const next = [...prev];
                const soi = next[prev.length - 1];
                soi.breaks = [
                    ...soi.breaks,
                    new MFromTo({
                        from: fromD.getTime(),
                        fromHour: fromD.getHours(),
                        fromMinute: fromD.getMinutes(),
                        to: toD.getTime(),
                        toHour: toD.getHours(),
                        toMinute: toD.getMinutes(),
                    }),
                ];
                next.splice(next.length - 1, 1, new MShift({ ...soi }));
                put(
                    `${ECollections.agencies}/${userAgencies[0].documentId}/${ECollections.timesheets}/${userData.documentId}/${ECollections.shifts}`,
                    soi.documentId,
                    soi,
                );
                return next;
            }),
        [userAgencies],
    );
    /**
     * callback to complete currently active break early
     */
    const completeBreakEarly = useCallback(() => {
        if (!activeBreak) return;
        setShifts((prev) => {
            const d = new Date();
            const next = [...prev];
            const soi = next[prev.length - 1];
            const boi = soi.breaks[soi.breaks.length - 1];
            boi.to = d.getTime();
            boi.toHour = d.getHours();
            boi.toMinute = d.getMinutes();
            put(
                `${ECollections.agencies}/${userAgencies[0].documentId}/${ECollections.timesheets}/${userData.documentId}/${ECollections.shifts}`,
                soi.documentId,
                soi,
            );
            soi.breaks = [...soi.breaks];
            soi.breaks.splice(
                soi.breaks.length - 1,
                1,
                new MFromTo({ ...boi }),
            );
            next.splice(next.length - 1, 1, new MShift({ ...soi }));
            return next;
        });
    }, [activeBreak]);
    /**
     * effect to close open shifts
     */
    useEffect(() => {
        const openNonActiveShifts = openShifts.filter(
            (shift) =>
                shift.from < Date.now() - userAgencies[0].shiftLength * minute,
        );
        openNonActiveShifts.forEach((shift) => {
            shift.to = shift.from + userAgencies[0].shiftLength * minute;
            shift.toHour = new Date(shift.to).getHours();
            shift.toMinute = new Date(shift.to).getMinutes();
            shift.complete = true;
            put(
                `${ECollections.agencies}/${userAgencies[0].documentId}/${ECollections.timesheets}/${userData.documentId}/${ECollections.shifts}`,
                shift.documentId,
                shift,
            );
        });
    }, []);
    /**
     * callback to load time sheet for user from agency timesheets
     */
    useEffect(() => {
        if (!userAgencies.length) return;
        getDataById(
            `${ECollections.agencies}/${userAgencies[0].documentId}/${ECollections.timesheets}`,
            userData.documentId,
        ).then((v) => {
            const next = new MTimesheet({
                ...v,
                documentId: userData.documentId,
            });
            if (v) {
                setTimesheet(next);
            } else {
                next.isAgency = true;
                next.employeeId = userData.documentId;
                next.agencyId = userAgencies[0].documentId;
                next.employeeId = userAgencies[0].documentId;
                put(
                    `${ECollections.agencies}/${userAgencies[0].documentId}/${ECollections.timesheets}`,
                    userData.documentId,
                    next,
                ).then(() => setTimesheet(next));
            }
        });
    }, [userAgencies, userData]);
    /**
     * effect to load potentially active shifts
     */
    useEffect(() => {
        if (!userAgencies.length || !timesheet?.documentId) return;
        getDataIndex(
            `${ECollections.agencies}/${userAgencies[0].documentId}/${ECollections.timesheets}/${timesheet.documentId}/${ECollections.shifts}`,
            {
                inequalities: [
                    {
                        value:
                            Date.now() - userAgencies[0].shiftLength * minute,
                        operator: '>=',
                        field: 'from',
                    },
                ],
            },
        ).then((r) => setShifts((r as MShift[]).map((s) => new MShift(s))));
    }, [timesheet, userAgencies, userData]);
    /**
     * effect to load open shifts
     */
    useEffect(() => {
        if (!userAgencies.length || !timesheet?.documentId) return;
        getDataIndex(
            `${ECollections.agencies}/${userAgencies[0].documentId}/${ECollections.timesheets}/${timesheet.documentId}/${ECollections.shifts}`,
            {
                filter: [
                    {
                        value: false,
                        operator: '==',
                        field: 'complete',
                    },
                ],
            },
        ).then((r) => setOpenShifts((r as MShift[]).map((s) => new MShift(s))));
    }, [timesheet, userAgencies, userData]);
    /**
     * effect to start 1fps
     */
    useEffect(() => {
        const timeout = setInterval(() => setNow(Date.now()), second);
        /**
         * return proper unsubscribe
         * * would probably also unsubscribe without this but good practice
         */
        return () => clearTimeout(timeout);
    }, []);
    /**
     * return empty fragment for non agency users
     */
    if (!isAgencyUser(userData)) {
        return <Fragment />;
    }
    /**
     * return spinner during initial load
     */
    if (!timesheet) {
        return <Spinner />;
    }
    /**
     * render
     */
    return (
        <CCard>
            <CText message={dashboardMessages.timeTracking} headline />
            <View>
                <View style={style.horizontal}>
                    <CText
                        message={
                            activeShift
                                ? format(dashboardMessages.shiftActiveSince, {
                                      x: timeString(
                                          activeShift.fromHour,
                                          activeShift.fromMinute,
                                      ),
                                  })
                                : dashboardMessages.notOnShift
                        }
                    />
                    {!!activeShift && (
                        <CText
                            style={style.leftPadded}
                            message={format(dashboardMessages.inShiftUntil, {
                                x: timeString(
                                    activeShiftEnd.toHour,
                                    activeShiftEnd.toMinute,
                                ),
                            })}
                        />
                    )}
                </View>
                {!!activeBreak && (
                    <CText
                        message={format(dashboardMessages.onBreakUntil, {
                            x: timeString(
                                activeBreakEnd.toHour,
                                activeBreakEnd.toMinute,
                            ),
                        })}
                        style={style.warning}
                    />
                )}
                {!!timeInActiveShift && (
                    <CText headline message={timeInActiveShift} />
                )}
            </View>
            <View style={style.horizontal}>
                {activeShift ? (
                    <>
                        {activeBreak ? (
                            <CButton
                                title={dashboardMessages.endBreak}
                                onPress={completeBreakEarly}
                                warning
                            />
                        ) : (
                            <CButton
                                title={dashboardMessages.takeBreak}
                                onPress={takeBreak}
                            />
                        )}
                        <CButton
                            title={dashboardMessages.endShift}
                            onPress={completeShift}
                            warning
                        />
                    </>
                ) : (
                    <CButton
                        title={dashboardMessages.startShift}
                        onPress={startNewShift}
                        disableOnClick
                    />
                )}
                {userData.documentId === userAgencies[0].owner && (
                    <CButton
                        title={agencyMessages.viewTimes}
                        onPress={() =>
                            secureNavigate(
                                '/agency/times/' + userAgencies[0].documentId,
                            )
                        }
                        icon="eye"
                    />
                )}
            </View>
        </CCard>
    );
};
