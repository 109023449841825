import { MShiftPlanShift } from '../../../../../models';
/**
 * check if 2 given shifts overlap
 * @param shiftA
 * @param shiftB
 * @returns
 */
export const checkForOverlap = (
    shiftA: MShiftPlanShift,
    shiftB: MShiftPlanShift,
) => {
    /**
     * is on the same day
     */
    const dayOverlap = shiftA.day === shiftB.day;
    /**
     * return verdict
     */
    return dayOverlap && shiftA.from <= shiftB.to && shiftB.from <= shiftA.to;
};
