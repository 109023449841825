import React, { FC, Fragment, useEffect, useState } from 'react';
import { View } from 'react-native';
import { MContract, MUserData, MWorkplace } from '../../../models';
import { useFireBase } from '../../../utilities/firebase';
import { isSuperUser } from '../../../utilities/auth';
import { ECollections } from '../../../enums';
import { CPicker } from '../../../components';
import { contractMessages } from '../contract.messages';
import { useStyle } from '../../../utilities/styles';

export const SelectSigningEmployer: FC<{
    contract: MContract;
    onChange: (next: string) => void;
}> = ({ contract, onChange }) => {
    const { userWorkplaces, userData, getDataById } = useFireBase();
    const style = useStyle();

    const [workplace, setWorkplace] = useState(
        userWorkplaces.find((wp) => wp.documentId === contract.workplaceId),
    );
    const [users, setUsers] = useState<MUserData[]>([]);

    useEffect(() => {
        if (isSuperUser(userData)) {
            getDataById(ECollections.workplaces, contract.workplaceId).then(
                (wp) => setWorkplace(new MWorkplace(wp)),
            );
        }
    }, [contract.workplaceId]);

    useEffect(() => {
        if (!workplace) {
            return;
        }
        const uids = Array.from(
            new Set([...workplace.signatureUsers, workplace.owner]),
        );
        Promise.all(
            uids.map(
                async (uid) => await getDataById(ECollections.publicUsers, uid),
            ),
        ).then((values) => setUsers(values.map((v) => new MUserData(v))));
    }, [workplace]);
    /**
     * dont offer picker if there is just one signature user
     */
    if (users.length <= 1) {
        return <Fragment />;
    }
    /**
     * render
     */
    return (
        <View style={style.card}>
            <CPicker
                title={contractMessages.signingWorkplaceUser}
                value={contract.signingEmployerId}
                values={users.map((user) => ({
                    value: user.documentId,
                    label: `${user.firstName} ${user.lastName}`,
                }))}
                onChange={(v) => onChange(v)}
            />
        </View>
    );
};
