import React, {
    FC,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { View } from 'react-native';
import { CModalContext } from '../../../utilities/contexts';
import { useStyle, useTheme } from '../../../utilities/styles';
import { CButton } from '../CButton';
import { Message } from '../../../utilities/types';

export interface IKeyButton {
    title: Message;
    icon?: string;
    onPress: () => void;
    cy?: string;
}

export interface ICMultiButtonProps {
    buttons: (IKeyButton | ReactNode)[];
    cy?: string;
}
/**
 * multi button to expand on click
 * ! buttons prop must be a well memoized value
 * @param param0
 * @returns
 */
export const CMultiButton: FC<ICMultiButtonProps> = ({ buttons, cy }) => {
    const { pushModal, popModal, removeModal } = useContext(CModalContext);
    const style = useStyle();
    const { theme } = useTheme();
    // local state
    const [position, setPosition] = useState<{ x: number; y: number }>();
    const [ref, setRef] = useState<View>();
    const [open, setOpen] = useState(false);

    const buttonRender = useMemo(
        () => (
            <View
                style={{
                    backgroundColor: theme.backgroundMainColor,
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: theme.accentColor,
                    overflow: 'hidden',
                    shadowColor: theme.shadowColor,
                    shadowOffset: {
                        width: 0,
                        height: 2,
                    },
                    shadowOpacity: 0.2,
                    shadowRadius: 3.8,
                    elevation: 5,
                    zIndex: 200,
                    width: 200,
                }}
            >
                {buttons.map((b, i) => {
                    if ((b as IKeyButton).title && (b as IKeyButton).onPress) {
                        const safeB = b as IKeyButton;
                        return (
                            <CButton
                                cy={safeB.cy}
                                style={{
                                    margin: 0,
                                    borderRadius: 0,
                                    borderWidth: 0,
                                    borderBottomWidth: 1,
                                }}
                                minor
                                key={i}
                                title={safeB.title}
                                onPress={async () => {
                                    await safeB.onPress();
                                    setOpen(false);
                                    removeModal('multibutton');
                                }}
                                icon={safeB.icon}
                            />
                        );
                    }
                    return b as ReactNode;
                })}
            </View>
        ),
        [buttons, removeModal],
    );

    const calculatePositions = useCallback(() => {
        if (ref) {
            ref.measureInWindow((x, y) => {
                const offsetX = x - 200;
                if (!position || position.x !== offsetX || position.y !== y)
                    setPosition({ x: offsetX, y });
            });
        }
    }, [ref]);

    useEffect(() => {
        calculatePositions();
    }, [calculatePositions, open]);

    useEffect(() => {
        if (open && position) {
            const params = {
                ...position,
                transparentBackground: true,
                onClose: () => setOpen(false),
                id: 'multibutton',
            };
            pushModal(buttonRender, params);
            return () => {
                popModal();
            };
        }
    }, [open, position, buttonRender]);

    return (
        <View
            ref={(view) => {
                if (!view || ref) return;
                setRef(view);
            }}
        >
            <CButton
                cy={cy}
                transparent
                icon={'dotsVertical'}
                onPress={() => {
                    calculatePositions();
                    setOpen(!open);
                }}
                style={[{ padding: 0, margin: 0 }, style.verticalPadded]}
                // onBlur={() => setOpen(false)}
            />
        </View>
    );
};
