import { MShiftPlan, MShiftPlanShift } from '../../../../../models';
import { checkForOverlap } from './checkForOverlap';

export const assignWorkersToShifts = (shiftPlan: MShiftPlan) => {
    const timeSums: { workerId: string; time: number }[] = [];
    shiftPlan.shifts = shiftPlan.shifts.map((shift) => {
        return new MShiftPlanShift({ ...shift, worker: undefined });
    });
    const problematicShifts: MShiftPlanShift[] = [];
    for (const shift of shiftPlan.shifts) {
        const group = shiftPlan.groups.find((g) => g.id === shift.group);
        if (!group) continue;
        const nextTime = shift.to - shift.from;
        const workersOfAdequateLevel = shiftPlan.workers.filter((w) =>
            shift.level ? w.level >= shift.level : true,
        );
        const workersSortedByTimes = workersOfAdequateLevel.sort((a, b) => {
            const tsA = timeSums.find((v) => v.workerId === a.id);
            const currentTimeA = tsA?.time || 0;
            const tsB = timeSums.find((v) => v.workerId === b.id);
            const currentTimeB = tsB?.time || 0;
            return currentTimeA - currentTimeB;
        });
        /**
         * worker who has a another non overlapping shift to assign and skip looking for any other
         */
        const workerWithOtherShiftToday = workersOfAdequateLevel.find((w) => {
            /**
             * shifts of interest
             * - at same day
             * - in same group
             * - for this worker
             * - not overlapping
             */
            const soi = shiftPlan.shifts.find(
                (s) =>
                    s.day === shift.day &&
                    s.group === shift.group &&
                    s.worker === w.id &&
                    !checkForOverlap(s, shift),
            );
            /**
             * overlapping shift from other groups
             */
            const overlap = shiftPlan.shifts.find(
                (s) => s.worker === w.id && checkForOverlap(s, shift),
            );
            return !!soi && !overlap;
        });
        if (workerWithOtherShiftToday) {
            shift.worker = workerWithOtherShiftToday.id;
            const ts = timeSums.find(
                (v) => v.workerId === workerWithOtherShiftToday.id,
            );
            const currentTime = ts?.time || 0;
            if (
                currentTime + nextTime <=
                workerWithOtherShiftToday.hoursPerWeek
            ) {
                if (ts) {
                    ts.time = currentTime + nextTime;
                } else {
                    // ! THIS SHOULD BE IMPOSSIBLE BUT OK
                    timeSums.push({
                        workerId: workerWithOtherShiftToday.id,
                        time: nextTime,
                    });
                }
                continue;
            }
        }
        const groupWorkers = workersSortedByTimes.filter((w) =>
            w.groups.includes(group.id),
        );
        const freeWorkers = workersSortedByTimes.filter(
            (w) => !w.groups.length,
        );
        const otherGroupWorkers = workersSortedByTimes.filter(
            (w) => w.groups.length && !w.groups.includes(group.id),
        );
        const workersOrderedByInterest = [...groupWorkers, ...freeWorkers];
        for (const worker of workersOrderedByInterest) {
            const todaysShiftsForWorker = shiftPlan.shifts.filter(
                (s) =>
                    s.worker && s.worker === worker.id && s.day === shift.day,
            );
            const overlappingShift = todaysShiftsForWorker.find((s) =>
                checkForOverlap(s, shift),
            );
            if (!overlappingShift) {
                const ts = timeSums.find((v) => v.workerId === worker.id);
                const currentTime = ts?.time || 0;
                if (currentTime + nextTime <= worker.hoursPerWeek) {
                    shift.worker = worker.id;
                    if (ts) {
                        ts.time = currentTime + nextTime;
                    } else {
                        timeSums.push({
                            workerId: worker.id,
                            time: nextTime,
                        });
                    }
                    break;
                }
            }
        }
        if (!shift.worker) {
            problematicShifts.push(shift);
        }
    }
    // TODO: juggle around
    for (const shift of problematicShifts) {
        const unassignedShifts = shiftPlan.shifts.filter((s) => !s.worker);
        if (
            unassignedShifts.filter(
                (s) => s.day === shift.day && checkForOverlap(s, shift),
            ).length <= 1
        ) {
            console.log('it is ok to exist', shift);
            continue;
        }
        const nextTime = shift.to - shift.from;
        /**
         * search for worker who has a another non overlapping shift to swap to
         */
        for (const worker of shiftPlan.workers) {
            /**
             * shifts of interest
             * - at another day
             * - in same group
             * - for this worker
             * - not overlapping
             * - not overlapping with other problem shifts
             */
            const soi = shiftPlan.shifts.find(
                (s) =>
                    s.day !== shift.day &&
                    s.group === shift.group &&
                    s.worker === worker.id &&
                    !checkForOverlap(s, shift) &&
                    !unassignedShifts.find((ps) => checkForOverlap(s, ps)),
            );
            /**
             * overlapping shift from other groups
             */
            const overlap = shiftPlan.shifts.find(
                (s) => s.worker === worker.id && checkForOverlap(s, shift),
            );
            if (!!soi && !overlap) {
                const soiTime = soi.to - soi.from;
                const ts = timeSums.find((v) => v.workerId === worker.id);
                const currentTime = ts?.time || 0;
                const nextTimeSum = currentTime - soiTime + nextTime;
                if (nextTimeSum <= worker.hoursPerWeek) {
                    soi.worker = undefined;
                    shift.worker = worker.id;
                    if (ts) {
                        ts.time = nextTimeSum;
                    } else {
                        // ! THIS SHOULD BE IMPOSSIBLE BUT OK
                        timeSums.push({
                            workerId: worker.id,
                            time: nextTime,
                        });
                    }
                    break;
                }
            }
        }
    }
    return new MShiftPlan(shiftPlan);
};
