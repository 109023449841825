import React, { FC } from 'react';
import './CDataTableRow.css';
import { View } from 'react-native';
import { IRowProps } from '../CDataTable';
import { makeTableStyles } from '../CDataTableStyles';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { CDataTableCell } from './CDataTableCell';

interface IDataTableRowProps extends IRowProps {
    headers: {
        headerKey: string;
        label?: string | undefined;
    }[];
}

export const CDataTableRow: FC<IDataTableRowProps> = (props) => {
    const style = useStyle();
    const { theme } = useTheme();

    const localStyle = makeTableStyles(theme);

    return (
        <View>
            <div
                className="ctablerow"
                style={{ ...style.horizontal, ...localStyle.row }}
            >
                {props.headers.map((header, i) => {
                    const value = props.values.find(
                        (v) => v.headerKey === header.headerKey,
                    );
                    if (value) {
                        return (
                            <CDataTableCell
                                {...value}
                                key={header.headerKey}
                                first={!i}
                                rowKey={props.rowKey || ''}
                            />
                        );
                    } else {
                        return (
                            <CDataTableCell
                                key={header.headerKey}
                                value="?"
                                headerKey={header.headerKey}
                                first={!i}
                                rowKey={props.rowKey || ''}
                            />
                        );
                    }
                })}
            </div>
        </View>
    );
};
