import { useCallback } from 'react';
import { actionMessages, generalMessages } from '../messages';
import { useDialog } from './useDialog';

export const useAdminDialog = () => {
    const dialog = useDialog();
    const adminDialog = useCallback(async () => {
        return dialog({
            icon: 'warning',
            title: generalMessages.adminSaveConfirm,
            message: generalMessages.adminSaveConfirmText,
            buttons: [{ text: actionMessages.confirm }],
            cancelButton: { text: actionMessages.cancel },
        });
    }, [dialog]);

    return adminDialog;
};
