import React, { FC, useState } from 'react';
import { View } from 'react-native';
import { MContract } from '../../../models';
import { ContractOverviewBar } from '../components/ContractOverviewBar';
import { CButton, Spinner } from '../../../components';
import { useStyle, useTheme } from '../../../utilities/styles';
import { contractMessages } from '../contract.messages';
import { ContractTitle } from '../components/ContractTitle';
import { useNavigate } from 'react-router-native';
import { ContractFiles } from '../View/components/ContractFiles';
/**
 * Contract view
 * should have:
 * overview over contract values
 * all documents related to contract
 * options to upload documents and request a signature
 * display the chat related to this contract
 * @returns
 */
export const ContractSideCard: FC<{
    contract: MContract;
    onClose?: () => void;
}> = ({ contract, onClose }) => {
    const style = useStyle();
    const { theme } = useTheme();
    const navigate = useNavigate();
    // local state
    const [infoHeight, setInfoHeight] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);
    /**
     * return spinner if no contract
     */
    if (!contract) {
        return <Spinner />;
    }

    /**
     * render
     */
    return (
        <View
            style={{ flex: 1, maxWidth: 450 }}
            onLayout={(e) => setContainerHeight(e.nativeEvent.layout.height)}
        >
            <View onLayout={(e) => setInfoHeight(e.nativeEvent.layout.height)}>
                <View style={[style.card]}>
                    <View style={[style.centeredItems, style.horizontalSplit]}>
                        <ContractTitle contract={contract} horizontalPadded />
                        {onClose && (
                            <CButton
                                transparent
                                icon="close"
                                onPress={onClose}
                                iconColor={theme.textMainColor}
                            />
                        )}
                    </View>
                    <View style={[style.verticalPadded]}>
                        <CButton
                            small
                            onPress={() =>
                                navigate(
                                    '/contract/negotiation/' +
                                        contract.documentId,
                                )
                            }
                            title={contractMessages.openContract}
                        />
                    </View>
                </View>
                <ContractOverviewBar contract={contract} />
            </View>
            <View style={{ height: containerHeight - infoHeight }}>
                <ContractFiles
                    contract={contract}
                    maxHeight={containerHeight - infoHeight}
                />
            </View>
        </View>
    );
};
