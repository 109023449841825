import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { CButton, InfoBox, CText } from '../../../components';
import { useStyle } from '../../../utilities/styles';
import { Linking, View } from 'react-native';
import { useFormat } from '../../../utilities/intl';
import { profileMessages } from '../Profile.messages';
import { generalMessages } from '../../../utilities/messages/general.messages';
import { ProfileContext } from '../Profile.context';
import { useFireBase } from '../../../utilities/firebase';
import { useSecureNavigate } from '../../../utilities/routing';
import { documentScanMessages } from '../../DocumentScan/documentScan.messages';
import { MUserIdentDoc } from '../../../models';
import { ECollections } from '../../../enums';
import { useEnvironment } from '../../../utilities/contexts';
/**
 * section for identity card managment && subscription ish digital signature tracking
 * @param param0 props
 * @returns component
 */
export const ProfileSectionDigitalCertification: FC = () => {
    // global state
    const format = useFormat();
    const style = useStyle();
    const { environment } = useEnvironment();
    const { mfaInfo, getDataIndex } = useFireBase();
    const { secureNavigate } = useSecureNavigate();
    // parent state
    const { curUserData, onValidityChange } = useContext(ProfileContext);
    const [curDocs, setCurDocs] = useState<MUserIdentDoc[]>([]);
    const expiredDocs = useMemo(
        () => curDocs.filter((d) => d.expiresOn <= Date.now()),
        [curDocs],
    );
    const reviewedAndActiveDocs = useMemo(
        () => curDocs.filter((d) => d.reviewed && d.expiresOn > Date.now()),
        [curDocs],
    );
    /**
     * effect to clear validity
     */
    useEffect(() => {
        onValidityChange('');
    }, [curUserData]);
    /**
     * effect to load id docs
     */
    useEffect(() => {
        if (!curUserData?.documentId) {
            return;
        }
        getDataIndex(
            `${ECollections.users}/${curUserData.documentId}/${ECollections.identDocuments}`,
        ).then((res) => {
            setCurDocs((res as any[]).map((r) => new MUserIdentDoc(r)));
        });
    }, [curUserData]);
    /**
     * render
     */
    return (
        <>
            <View>
                {!!reviewedAndActiveDocs.length && (
                    <InfoBox
                        type="success"
                        message={profileMessages.youHaveAvalidAndActiveIdDoc}
                    />
                )}
                {!mfaInfo && (
                    <View style={style.verticalPadded}>
                        <InfoBox
                            type="warning"
                            message={generalMessages.addMFAText}
                        />
                    </View>
                )}
                <CButton
                    disabled={!mfaInfo}
                    title={documentScanMessages.uploadANewIdentityDocument}
                    onPress={() => {
                        secureNavigate('/documentScan');
                    }}
                />
            </View>
            <View style={style.paddedThinSeparator} />
            <View>
                <CText message={profileMessages.qes} secondaryHeadline />
                <CText
                    message={format(profileMessages.qesHint, {
                        platform: environment,
                    })}
                />
                <View
                    style={[
                        style.horizontalSplit,
                        style.verticalHeavyPadded,
                        style.centeredItems,
                    ]}
                >
                    <CText message={profileMessages.availableQes} />
                    <CText message={`${curUserData?.qes}`} secondaryHeadline />
                    <CButton
                        title={profileMessages.orderQes}
                        onPress={() => {
                            // TODO:
                            Linking.openURL(`mailto:support@timeployees.de`);
                        }}
                        disabled={!mfaInfo}
                    />
                </View>
            </View>
        </>
    );
};
