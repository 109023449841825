import { defineMessages } from 'react-intl';

const scope = 'ride.contract.';

export const contractRideMessages = defineMessages({
    welcome: {
        id: scope + 'welcome',
        defaultMessage:
            'Congratiulations on your first contract. This is a brief introduction to the contract view.',
    },
    details: {
        id: scope + 'details',
        defaultMessage:
            'Here you can see the contract details which you agreed upon during negotiation',
    },
    chat: {
        id: scope + 'chat',
        defaultMessage:
            'This is a chat directly with you contract partner. Here you can sort out all missing informations.',
    },
    documents: {
        id: scope + 'documents',
        defaultMessage:
            'This is a document list. You can exchange contract relevant files and request signatures here.',
    },
});
