import { addressMessages } from '../../../../../components/AddressInput/address.messages';
import { MAddress } from '../../../../../models';
import { useDialog } from '../../../../../utilities/dialog';
import { actionMessages } from '../../../../../utilities/messages';
import { contractMessages } from '../../../contract.messages';

export const requestCourierDialog = async (
    dialog: ReturnType<typeof useDialog>,
    recipient: string,
    address: MAddress,
) => {
    const result = await dialog({
        icon: 'info',
        title: contractMessages.signWithCourier,
        message: contractMessages.signWithCourierText,
        textInputs: [
            {
                id: 'recipient',
                title: contractMessages.recipient,
                defaultValue: recipient,
            },
            {
                id: 'street',
                title: addressMessages.street,
                defaultValue: address.street,
            },
            {
                id: 'number',
                title: addressMessages.number,
                defaultValue: address.number,
            },
            {
                id: 'zipCode',
                title: addressMessages.zipCode,
                defaultValue: address.zipCode,
            },
            {
                id: 'city',
                title: addressMessages.city,
                defaultValue: address.city,
            },
            {
                id: 'country',
                title: addressMessages.country,
                defaultValue: address.country,
            },
        ],
        buttons: [
            {
                text: actionMessages.ok,
                onPress: (inputs) => {
                    address = new MAddress({
                        zipCode:
                            inputs?.find(({ id }) => id === 'zipCode')?.value ||
                            '',
                        city:
                            inputs?.find(({ id }) => id === 'city')?.value ||
                            '',
                        number:
                            inputs?.find(({ id }) => id === 'number')?.value ||
                            '',
                        street:
                            inputs?.find(({ id }) => id === 'street')?.value ||
                            '',
                        country:
                            inputs?.find(({ id }) => id === 'country')?.value ||
                            '',
                    });
                    recipient =
                        inputs?.find(({ id }) => id === 'recipient')?.value ||
                        '';
                },
            },
        ],
        cancelButton: { text: actionMessages.cancel },
    });
    if (result) {
        return { recipient, address };
    }
};
