import { defineMessages } from 'react-intl';

const scope = 'con.onboard.';

export const onboardingMessages = defineMessages({
    onboardingPersonal: {
        id: scope + 'onboardingPersonal',
        defaultMessage: 'Personal Information',
    },
    termsAndConditions: {
        id: scope + 'termsAndConditions',
        defaultMessage: 'Terms and conditions',
    },
    onboardingJob: {
        id: scope + 'onboardingJob',
        defaultMessage: 'Information about your profession',
    },
    wichTypeOfEmployment: {
        id: scope + 'whichTypeOfEmployment',
        defaultMessage: 'What type of employment are you interested in?',
    },
    youAre: {
        id: scope + 'youAre',
        defaultMessage: 'You are:',
    },
    aJobAsDoctor: {
        id: scope + 'aJobAsDoctor',
        defaultMessage: 'Doctor',
    },
    aJobAsDoctorDescription: {
        id: scope + 'aJobAsDoctorDescription',
        defaultMessage: 'I am a doctor looking for a job',
    },
    aJobAsNurse: {
        id: scope + 'aJobAsNurse',
        defaultMessage: 'Nurse',
    },
    aJobAsNurseDescription: {
        id: scope + 'aJobAsNurseDescription',
        defaultMessage: 'I am a nurse looking for a job',
    },
    aJobAsSocialWorker: {
        id: scope + 'aJobAsSocialWorker',
        defaultMessage: 'Social worker',
    },
    aJobAsSocialWorkerDescription: {
        id: scope + 'aJobAsSocialWorkerDescription',
        defaultMessage: 'I am a social worker looking for a job',
    },
    aJobAsTeacher: {
        id: scope + 'aJobAsTeacher',
        defaultMessage: 'Teacher',
    },
    aJobAsTeacherDescription: {
        id: scope + 'aJobAsTeacherDescription',
        defaultMessage: 'I am a teacher looking for a job',
    },
    aJobAsStudent: {
        id: scope + 'aJobAsStudent',
        defaultMessage: 'Medical student',
    },
    aJobAsStudentDescription: {
        id: scope + 'aJobAsStudentDescription',
        defaultMessage: 'I am a student looking for an opportunity',
    },
    aWorkplace: {
        id: scope + 'aWorkplace',
        defaultMessage: 'Workplace',
    },
    aWorkplaceDescription: {
        id: scope + 'aWorkplaceDescription',
        defaultMessage: 'I am looking for professional personnel',
    },
    aHospital: {
        id: scope + 'aHospital',
        defaultMessage: 'Hospital',
    },
    aHospitalDescription: {
        id: scope + 'aHospitalDescription',
        defaultMessage: 'I am looking for professional personnel',
    },
    aBasic: {
        id: scope + 'aBasic',
        defaultMessage: 'None of the above',
    },
    aBasicDescription: {
        id: scope + 'aBasicDescription',
        defaultMessage:
            'You have received a contract invite and are neither representing a workplace or agency',
    },
    aAgency: {
        id: scope + 'aAgency',
        defaultMessage: 'Employment leasing agency',
    },
    aAgencyDescription: {
        id: scope + 'aAgencyDescription',
        defaultMessage: 'I am looking for jobs for my professional personnel',
    },
    onboardingLanguage: {
        id: scope + 'onboardingLanguage',
        defaultMessage: 'Details to language',
    },
    complete: {
        id: scope + 'complete',
        defaultMessage: 'You completed the onboarding',
    },
    completeBody: {
        id: scope + 'completeBody',
        defaultMessage: 'You can now use the app',
    },
    workplaceName: {
        id: scope + 'workplaceName',
        defaultMessage: 'Workplace name',
    },
    agencyName: {
        id: scope + 'agencyName',
        defaultMessage: 'Name of the institution you intend to represent.',
    },
    agencyData: {
        id: scope + 'agencyData',
        defaultMessage: 'Agency information',
    },
    agencyProfileCreate: {
        id: scope + 'agencyProfileCreate',
        defaultMessage: 'Tell us about your employees',
    },
    taxNumber: {
        id: scope + 'taxNumber',
        defaultMessage:
            'Tax number of the institution you intend to represent.',
    },
    registerNumber: {
        id: scope + 'registerNumber',
        defaultMessage:
            'Register number of the institution you intend to represent.',
    },
    noHospitalName: {
        id: scope + 'noHospitalName',
        defaultMessage: 'No workplace name',
    },
    noAgencyName: {
        id: scope + 'noAgencyName',
        defaultMessage: 'No agency name',
    },
    workplaceSignUpInfo: {
        id: scope + 'workplaceSignUpInfo',
        defaultMessage:
            'If you sign on as employer, you provide information we will use afterwards to verify the legitimacy of your request. After verification you will gain access to employer features',
    },
    onboardingPayment: {
        id: scope + 'onboardingPayment',
        defaultMessage: 'Select your preferred interval of payment',
    },
    provideAddress: {
        id: scope + 'provideAddress',
        defaultMessage: 'Please provide a complete address',
    },
    noRegisterNumber: {
        id: scope + 'noRegisterNumber',
        defaultMessage: 'No register number',
    },
    noTaxNumber: {
        id: scope + 'noTaxNumber',
        defaultMessage: 'No tax number',
    },
    onboardingMarketing: {
        id: scope + 'onboardingMarketing',
        defaultMessage: 'Marketing survey',
    },
    howDidYouFindOut: {
        id: scope + 'howDidYouFindOut',
        defaultMessage: 'How did you find out about {platform}?',
    },
    google: {
        id: scope + 'google',
        defaultMessage: 'Google',
    },
    indeed: {
        id: scope + 'indeed',
        defaultMessage: 'Indeed',
    },
    linkedin: {
        id: scope + 'linkedin',
        defaultMessage: 'LinkedIn',
    },
    friendsAndFamily: {
        id: scope + 'friendsAndFamily',
        defaultMessage: 'Friends and Family',
    },
    additionalProfessions: {
        id: scope + 'additionalProfessions',
        defaultMessage: 'Add another profession',
    },
    noMoreProfessionsForField: {
        id: scope + 'noMoreProfessionsForField',
        defaultMessage:
            'There are no more professions in you selected field. Please select another one or continue. If you think a profession is missing you can contact us and we will look into it.',
    },
    invalidAdress: {
        id: scope + 'invalidAdress',
        defaultMessage: 'You need to provide a valid address',
    },
    profProfileCreation: {
        id: scope + 'profProfileCreation',
        defaultMessage:
            'You can select any number of professions that represent your available staff. We will create minimalistic profiles for those, on which you can fill in more detailed information later on. You can also skip this step and create detailed profiles after the onboarding process.',
    },
    yourTalents: {
        id: scope + 'yourTalents',
        defaultMessage: 'Your Talent Profiles:',
    },
    fieldInfo: {
        id: scope + 'fieldInfo',
        defaultMessage:
            'Select fields you are working in to streamline your experience.',
    },
    restrictiveFields: {
        id: scope + 'restrictiveFields',
        defaultMessage:
            'Your selection determines which fields you see during regular app usage. You will only see job data from the selected fields. You can expand or reduce this list at anytime within your profile settings.',
    },
    selectRegion: {
        id: scope + 'selectRegion',
        defaultMessage:
            'Please select your region. The region will restrict you to seeing only content from that region.',
    },
});
