import { defineMessages } from 'react-intl';

const scope = 'com.typeahead.';

export const typeaheadMessages = defineMessages({
    workplaceZipCodePlaceholder: {
        id: scope + 'workplaceZipCodePlaceholder',
        defaultMessage:
            'Type in the ZIP code of the workplace you are looking for',
    },
    noWorkplacesWithCurrentZipCode: {
        id: scope + 'noWorkplacesWithCurrentZipCode',
        defaultMessage: 'No workplaces with current zip code',
    },
    workplace: {
        id: scope + 'workplace',
        defaultMessage: 'Workplace',
    },
});
