import { defineMessages } from 'react-intl';

const scope = 'con.calendar.';

export const calendarMessages = defineMessages({
    month: {
        id: scope + 'month',
        defaultMessage: 'Month',
    },
    year: {
        id: scope + 'year',
        defaultMessage: 'Year',
    },
    addAvailability: {
        id: scope + 'addAvailability',
        defaultMessage: 'Add new availability',
    },
    editAvailability: {
        id: scope + 'editAvailability',
        defaultMessage: 'Edit availability',
    },
    fromToInvalid: {
        id: scope + 'fromToInvalid',
        defaultMessage: 'From has to be lesser than to',
    },
    toInvalid: {
        id: scope + 'toInvalid',
        defaultMessage: 'To has to be lesser than 48',
    },
    repeatWeekly: {
        id: scope + 'repeatWeekly',
        defaultMessage: 'Repeat this availability weekly',
    },
    repeatCounts: {
        id: scope + 'repeatCounts',
        defaultMessage: 'Amount to repeat',
    },
    start: {
        id: scope + 'start',
        defaultMessage: 'Start',
    },
    confirmDelete: {
        id: scope + 'confirmDelete',
        defaultMessage: 'You are about to delete this availability',
    },
    confirmDeleteText: {
        id: scope + 'confirmDeleteText',
        defaultMessage:
            'Please confirm that you want to delete this availability. This action will be irreversible.',
    },
    note: {
        id: scope + 'note',
        defaultMessage: 'Note',
    },
    notePlaceholder: {
        id: scope + 'notePlaceholder',
        defaultMessage: 'Enter a note',
    },
    returnToProfile: {
        id: scope + 'returnToProfile',
        defaultMessage: 'Return to profile',
    },
    selectedJob: {
        id: scope + 'selectedJob',
        defaultMessage: 'Selected job:',
    },
    todaysJobs: {
        id: scope + 'todaysJobs',
        defaultMessage: 'Jobs available on {date}:',
    },
    noJobsToday: {
        id: scope + 'noJobsToday',
        defaultMessage: 'You have not created a job for this day.',
    },
    todaysContracts: {
        id: scope + 'todaysContracts',
        defaultMessage: 'Contracts on {date}:',
    },
    noContractsForThisDay: {
        id: scope + 'noContractsForThisDay',
        defaultMessage: 'You have no contract for this day.',
    },
    from: {
        id: scope + 'from',
        defaultMessage: 'Start',
    },
    to: {
        id: scope + 'to',
        defaultMessage: 'Finish',
    },
    fromWeek: {
        id: scope + 'fromWeek',
        defaultMessage: 'Start date',
    },
    toWeek: {
        id: scope + 'toWeek',
        defaultMessage: 'Last date',
    },
    everyDay: {
        id: scope + 'everyDay',
        defaultMessage: 'Every weekday',
    },
    everyWeekDay: {
        id: scope + 'everyWeekDay',
        defaultMessage: 'Every work day (Mo. - Fr.)',
    },
    isUnavailability: {
        id: scope + 'isUnavailability',
        defaultMessage: 'Treat this configuration as "Not available"',
    },
});
