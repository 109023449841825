import React, { createContext, FC, useCallback, useState } from 'react';
import { IDefaultProps } from '../IDefaultProps';

export const SecureNavigateContext = createContext(
    {} as {
        setNavigationLock: (nlock: () => Promise<boolean>) => () => void;
        clearNavigationLock: () => void;
        preventNavigate: () => Promise<boolean>;
    },
);

export const SecureNavigateProvider: FC<IDefaultProps> = ({ children }) => {
    const [navigationLock, setNavigationLockState] =
        useState<() => Promise<boolean>>();

    const clearNavigationLock = useCallback(() => {
        setNavigationLockState(() => undefined);
    }, []);

    const setNavigationLock = useCallback(
        (nlock: () => Promise<boolean>) => {
            setNavigationLockState(() => nlock);
            return clearNavigationLock;
        },
        [clearNavigationLock],
    );
    /**
     * preventNavigate function checks if there is a lock and then tries to check if it prevents navigation
     * await navigationLock() shall return true to prevent navigation
     */
    const preventNavigate = useCallback(
        async () => !!(navigationLock && (await navigationLock())),
        [navigationLock],
    );

    return (
        <SecureNavigateContext.Provider
            value={{
                setNavigationLock,
                clearNavigationLock,
                preventNavigate,
            }}
        >
            {children}
        </SecureNavigateContext.Provider>
    );
};
