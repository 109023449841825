import React, { FC, useCallback, useState } from 'react';
import { useFireBase } from '../../../../../utilities/firebase';
import { ECollections } from '../../../../../enums';
import { MProfessionalProfile } from '../../../../../models';
import { CButton } from '../../../../../components';

export const TouchProfiles: FC = () => {
    const { getDataIndex, put } = useFireBase();

    const [touchingProfiles, setTouchingProfiles] = useState(false);
    /**
     * callback to touch all userData
     */
    const touchProfiles = useCallback(() => {
        setTouchingProfiles(true);
        getDataIndex(ECollections.profProfiles).then((users) => {
            Promise.all(
                (users as any[]).map((user) => {
                    const next = new MProfessionalProfile(user);
                    return put(ECollections.profProfiles, user.documentId, {
                        ...next,
                        touched: Date.now(),
                    });
                }),
            ).then(() => setTouchingProfiles(false));
        });
    }, [getDataIndex, put]);

    return (
        <CButton
            onPress={touchProfiles}
            title="Touch all profiles"
            warning
            disabled={touchingProfiles}
        />
    );
};
