import React, { FC, useCallback } from 'react';
import { View } from 'react-native';
import { CButton } from '../../../../components';
import { useFireBase } from '../../../../utilities/firebase';
import { useDialog } from '../../../../utilities/dialog';
import { contractMessages } from '../../contract.messages';
import { actionMessages } from '../../../../utilities/messages';
import { useStyle } from '../../../../utilities/styles';
import { MContract } from '../../../../models';
import { useLock } from '../../../../utilities/hooks';
import { useNavigate } from 'react-router-native';

export const ContractCancelButton: FC<{
    contract: MContract;
}> = ({ contract }) => {
    const { callFunction } = useFireBase();
    const dialog = useDialog();
    const style = useStyle();
    const { lock } = useLock();
    const navigate = useNavigate();

    const cancelContract = useCallback(async () => {
        let reason = '';
        const ok = await dialog({
            title: contractMessages.cancelEarly,
            message: contractMessages.cancelEarlyMessage,
            cancelButton: {
                text: actionMessages.cancel,
            },
            buttons: [
                {
                    text: actionMessages.ok,
                    onPress: (inputs) => {
                        reason =
                            inputs?.find((i) => i.id === 'reason')?.value || '';
                    },
                    disabled: (inputs) =>
                        !inputs?.find((i) => i.id === 'reason')?.value.length,
                },
            ],
            textInputs: [
                {
                    id: 'reason',
                    title: contractMessages.negotiationCancellationReason,
                },
            ],
            icon: 'warning',
        });
        if (!ok) return;
        const unlock = lock();
        await callFunction('cancelContract', {
            contractId: contract.documentId,
            cancelReason: reason,
        });
        unlock();
        await dialog({
            title: contractMessages.contractWasClosed,
            icon: 'success',
        });
        navigate('/contracthistory');
    }, [contract]);

    return (
        <View>
            <CButton
                title={contractMessages.cancelContract}
                onPress={cancelContract}
                minor
                small
                style={style.smallMargin}
            />
        </View>
    );
};
