import React, { FC, useMemo } from 'react';
import { Linking } from 'react-native';
import { icons } from '../../../assets';
import { useLocation, useSecureNavigate } from '../../../utilities/routing';
import { useTheme } from '../../../utilities/styles';
import { CButton } from '../../elements';
import { makeMenuStyles } from '../menu.style';
import { Message } from '../../../utilities/types';

export interface IMenuButtonBaseProps {
    detail?: boolean;
    closeSidebar?: () => void;
}
export interface IMenuButtonProps extends IMenuButtonBaseProps {
    title: Message;
    icon: string;
    targetLocation?: string;
    targetLocations?: string[];
    customFunction?: () => void;
    exact?: boolean;
    cy?: string;
}

export const MenuButton: FC<IMenuButtonProps> = ({
    closeSidebar,
    detail,
    icon,
    title,
    customFunction,
    targetLocation,
    targetLocations,
    exact,
    cy,
}) => {
    const { theme } = useTheme();
    const currentLocation = useLocation();
    const localStyles = useMemo(() => makeMenuStyles(theme), [theme]);

    const { secureNavigate } = useSecureNavigate();

    const navigateAndClose = () => {
        if (targetLocation) {
            if (targetLocation.includes('https://')) {
                Linking.openURL(targetLocation);
            } else {
                secureNavigate(targetLocation);
                if (closeSidebar) {
                    closeSidebar();
                }
            }
        }
    };

    const active = useMemo(() => {
        const l = [targetLocation, ...(targetLocations || [])].filter(
            (s) => s,
        ) as string[];
        if (exact) {
            return l.includes(currentLocation.pathname);
        }
        return !!l.find(
            (location) =>
                currentLocation.pathname.includes(location) ||
                currentLocation.pathname.includes(
                    location.slice(0, location.length - 1),
                ),
        );
    }, [exact, currentLocation, targetLocation, targetLocations]);

    return (
        <CButton
            nativeId={'menu-' + icon}
            cy={
                cy
                    ? cy
                    : targetLocation &&
                      `${targetLocation?.substring(1) || 'home'}-menu-button`
            }
            onPress={
                !!targetLocation
                    ? navigateAndClose
                    : !!customFunction
                    ? customFunction
                    : () => console.error('misused component')
            }
            title={detail ? title : undefined}
            icon={
                // @ts-ignore
                !active && icons[icon + '_outline'] ? icon + '_outline' : icon
            }
            style={[
                localStyles.navButton,
                detail && localStyles.exNavButton,
                // environment === EEnvironment.TimePloyees && {
                //     backgroundColor: theme.darkAccentColor,
                // },
            ]}
            fontStyles={[
                localStyles.navButtonText,
                // environment === EEnvironment.TimePloyees && {
                //     backgroundColor: theme.darkAccentColor,
                //     color: theme.textAccentColor,
                // },
            ]}
            transparent
            iconColor={
                active
                    ? theme.accentColor
                    : // : environment === EEnvironment.TimePloyees
                      // ? theme.textAccentColor
                      theme.textMainColor
            }
        />
    );
};
