import { useCallback } from 'react';
import { actionMessages, generalMessages } from '../messages';
import { useDialog } from './useDialog';
import { IDialogButton } from './IDialog';
import { useSecureNavigate } from '../routing';
import { Linking } from 'react-native';

export const useJobNotIncludedDialog = () => {
    const dialog = useDialog();
    const { secureNavigate } = useSecureNavigate();
    const jobNotIncludedDialog = useCallback(async () => {
        const buttons: IDialogButton[] = [
            {
                text: generalMessages.checkSettings,
                onPress: () => {
                    secureNavigate('/profile/basic');
                },
            },
            {
                text: generalMessages.contactUs,
                onPress: () => {
                    Linking.openURL('mailto:anfragen@timeployees.de');
                },
            },
        ];
        return dialog({
            title: generalMessages.jobNotIncluded,
            message: generalMessages.jobNotIncludedText,
            cancelButton: {
                text: actionMessages.back,
            },
            buttons,
            verticalButtons: true,
            icon: 'question',
        });
    }, [dialog]);

    return jobNotIncludedDialog;
};
