import { defineMessages } from 'react-intl';

const scope = 'con.jobApplications.';

export const jobApplicationMessages = defineMessages({
    applicantXofN: {
        id: scope + 'applicantXofN',
        defaultMessage: 'Applicant {x} of {n}',
    },
    applicants: {
        id: scope + 'applicants',
        defaultMessage: 'Applicants',
    },
    contracts: {
        id: scope + 'contracts',
        defaultMessage: 'Open contracts for this job',
    },
    negotiations: {
        id: scope + 'negotiations',
        defaultMessage: 'Active negotiations for this job',
    },
    completeJob: {
        id: scope + 'completeJob',
        defaultMessage: 'Do you want to close the related Job listing?',
    },
    accept: {
        id: scope + 'accept',
        defaultMessage: 'Negotiate',
    },
    justAccept: {
        id: scope + 'justAccept',
        defaultMessage: 'Start negotiation and leave job listing open',
    },
    acceptAndClose: {
        id: scope + 'acceptAndClose',
        defaultMessage: 'Start negotiation and close job listing',
    },
    completeJobDescription: {
        id: scope + 'completeJobDescription',
        defaultMessage:
            'All other applications will be rejected and the listing will be marked as "filled"',
    },
    currentlyNoJobs: {
        id: scope + 'currentlyNoJobs',
        defaultMessage:
            'No jobs found for your search. You could change your criteria or create a new job.',
    },
    thereIsAnOpenNegotiation: {
        id: scope + 'thereIsAnOpenNegotiation',
        defaultMessage:
            'You already have a open negotiation or contract for this job.',
    },
    thereIsAnOpenNegotiationMessage: {
        id: scope + 'thereIsAnOpenNegotiationMessage',
        defaultMessage: 'Please confirm that you want to open another one',
    },
    noMoreThanThreeNegotiations: {
        id: scope + 'noMoreThanThreeNegotiations',
        defaultMessage:
            'You have reached the limit of 3 negotiations or contracts for one job.',
    },
    noMoreThanThreeNegotiationsMessage: {
        id: scope + 'noMoreThanThreeNegotiationsMessage',
        defaultMessage: 'That is the limit.',
    },
    orderBy: {
        id: scope + 'orderBy',
        defaultMessage: 'Order by',
    },
    rate: {
        id: scope + 'rate',
        defaultMessage: 'Rate',
    },
    yearsOfExperience: {
        id: scope + 'yearsOfExperience',
        defaultMessage: 'Years of experience',
    },
    applicationsFromPreferred: {
        id: scope + 'applicationsFromPreferred',
        defaultMessage: 'Applications from preferred agencies',
    },
    otherApplications: {
        id: scope + 'otherApplications',
        defaultMessage: 'Other applications',
    },
    share: {
        id: scope + 'share',
        defaultMessage: 'Share job with known agencies',
    },
    jobOverview: {
        id: scope + 'jobOverview',
        defaultMessage: 'Job overview',
    },
    confimApplicationReject: {
        id: scope + 'confimApplicationReject',
        defaultMessage: 'Confirm rejection of Application.',
    },
    confimApplicationRejectText: {
        id: scope + 'confimApplicationRejectText',
        defaultMessage: 'Confirm rejection of Application',
    },
    selectWorkplace: {
        id: scope + 'selectWorkplace',
        defaultMessage: 'Select workplaces to filter jobs.',
    },
    closeJob: {
        id: scope + 'closeJob',
        defaultMessage: 'Close job',
    },
    closeJobDialog: {
        id: scope + 'closeJobDialog',
        defaultMessage:
            'Close this job listing and reject all associated applications?',
    },
    closeJobDialogText: {
        id: scope + 'closeJobDialogText',
        defaultMessage:
            'Please confirm that you want to close this job listing. All associated applications will get rejected.',
    },
});
