import { ITheme } from '../ITheme';

const light: ITheme = {
    backgroundMainColor: '#FFF',
    backgroundSecondaryColor: '#F4F2EE',
    backgroundTertiaryColor: '#f5f5f5',
    borderColor: '#cdced5',
    textMainColor: '#222b54',
    textSecondaryColor: '#0a3c5f',
    textTertiaryColor: '#a7aeb3',
    accentColor: '#62c1c5',
    darkAccentColor: '#00666b',
    textAccentColor: '#fafcfb',
    fontMain: 'Poppins',
    fontThin: 'Poppins',
    errorColor: '#c56663',
    warningColor: '#c59763',
    successColor: '#91c563',
    mainFontFamily: 'Lota Grotesque',
    headlineFontFamily: 'Lota Grotesque',
    shadowColor: '#000000',
};

const dark: ITheme = {
    backgroundMainColor: '#191919',
    backgroundSecondaryColor: '#111111',
    backgroundTertiaryColor: '#474646',
    borderColor: '#404042',
    textMainColor: '#fafcfb',
    textSecondaryColor: '#a1a7c7',
    textTertiaryColor: '#656c70',
    accentColor: '#62c1c5',
    darkAccentColor: '#00666b',
    textAccentColor: '#fafcfb',
    fontMain: 'Poppins',
    fontThin: 'Poppins',
    errorColor: '#c56663',
    warningColor: '#c59763',
    successColor: '#91c563',
    mainFontFamily: 'Lota Grotesque',
    headlineFontFamily: 'Lota Grotesque',
    shadowColor: '#6F6F6F',
};

export const timeployeesTheme = { light, dark };
