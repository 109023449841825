import React, { FC } from 'react';
import { FileInput, Spinner } from '../../../../components';
import { EAgencyFiles, ECollections } from '../../../../enums';
import { MAgency } from '../../../../models';
/**
 * section for documents of agency
 * - more or less equivalent to user docs
 */
export const AgencyDocuments: FC<{
    agency: MAgency;
    onChange: (next: Partial<MAgency>) => void;
}> = ({ agency, onChange }) => {
    if (!agency.documentId) {
        return <Spinner />;
    }
    return (
        <FileInput
            collection={`${ECollections.agencies}/${agency.documentId}/${ECollections.files}`}
            directory={`${ECollections.agencies}/${agency.documentId}`}
            fileTypes={Object.values(EAgencyFiles)}
            defaultType={EAgencyFiles.license}
            legacyFiles={agency.files}
            clearLegacyFiles={() => onChange({ files: [] })}
        />
    );
};
