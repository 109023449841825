import React, { FC, useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';
import { CButton, CFieldStringInput } from '../../../../components';
import { useFormat } from '../../../../utilities/intl';
import { generalMessages } from '../../../../utilities/messages';
import { useStyle } from '../../../../utilities/styles';
import { workplaceMessages } from '../../workplace.messages';
import { isSuperUser } from '../../../../utilities/auth';
import { useFireBase } from '../../../../utilities/firebase';

export enum EWorkplaceFilterModes {
    all = 'all',
    verified = 'verified',
    unverified = 'unverified',
    disabled = 'disabled',
}

interface IWorkplaceFilterBarProps {
    mode: EWorkplaceFilterModes[];
    onSet: (values: { mode: EWorkplaceFilterModes[] }) => void;
    addMode: (next: EWorkplaceFilterModes) => void;
    setCustomStringFilter: React.Dispatch<
        React.SetStateAction<
            | {
                  field: string;
                  value: string;
              }
            | undefined
        >
    >;
    customStringFilter?: { field: string; value: string };
    embedded?: boolean;
}

export const WorkplaceFilterBar: FC<IWorkplaceFilterBarProps> = ({
    mode,
    onSet,
    addMode,
    setCustomStringFilter,
    customStringFilter,
    embedded,
}) => {
    const style = useStyle();
    const format = useFormat();
    const { userData } = useFireBase();
    const [localMore, setMore] = useState(false);
    const more = useMemo(() => {
        return localMore;
    }, [localMore]);

    const handleNextCustomStringFilter = useCallback(
        (next: { value: string; field: string }) =>
            setCustomStringFilter((prev) => {
                if (next.value) {
                    // setMode([]);
                    // clearFilters();
                    return next;
                } else if (prev?.value) {
                    return { field: next.field, value: '' };
                } else {
                    return prev;
                }
            }),
        [],
    );

    return (
        <View>
            {isSuperUser(userData) && !embedded && (
                <View style={style.horizontalWrap}>
                    <CButton
                        title={format(generalMessages.all)}
                        onPress={() => {
                            onSet({
                                mode: [EWorkplaceFilterModes.all],
                            });
                            setMore(false);
                        }}
                        minor={!mode.includes(EWorkplaceFilterModes.all)}
                        small
                        style={style.smallMargin}
                    />
                    <View style={style.verticalPaddedThinSeparator} />
                    <CButton
                        title={format(workplaceMessages.verified)}
                        onPress={() => {
                            addMode(EWorkplaceFilterModes.verified);
                        }}
                        minor={!mode.includes(EWorkplaceFilterModes.verified)}
                        small
                        style={style.smallMargin}
                    />
                    <CButton
                        title={format(workplaceMessages.disabled)}
                        onPress={() => {
                            addMode(EWorkplaceFilterModes.disabled);
                        }}
                        minor={!mode.includes(EWorkplaceFilterModes.disabled)}
                        small
                        style={style.smallMargin}
                    />
                    {/* <CButton
                    title={format(workplaceMessages.unverified)}
                    onPress={() => {
                        addMode(EWorkplaceFilterModes.unverified);
                    }}
                    minor={!mode.includes(EWorkplaceFilterModes.unverified)}
                    small
                    style={style.smallMargin}
                /> */}
                </View>
            )}
            {more && <></>}
            <CFieldStringInput
                value={customStringFilter}
                fields={['zipCode', 'name'].map((f) => {
                    return {
                        label: format(
                            workplaceMessages[
                                f as keyof typeof workplaceMessages
                            ],
                        ),
                        field: f,
                    };
                })}
                onChange={handleNextCustomStringFilter}
                cy={'workplace-text-filter'}
            />
        </View>
    );
};
