import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams, useSecureNavigate } from '../../../utilities/routing';
import { MChat, MUserData } from '../../../models';
import { useFireBase } from '../../../utilities/firebase';
import { useTheme } from '../../../utilities/styles';
import { ECollections, EUserType } from '../../../enums';
import {
    actionMessages,
    doctorTitleMessages,
} from '../../../utilities/messages';
import { useFormat } from '../../../utilities/intl';
import { userMessages } from '../user.messages';
import {
    useAdminDialog,
    useDialog,
    useUnsavedChangesDialog,
} from '../../../utilities/dialog';
import { notificationMessages } from '../../../utilities/messages/notification.messages';
import { useCustomFormat } from '../../../utilities/intl/useCustomFormat';
import { LanguageContext } from '../../../utilities/intl/CustomIntlProvider';
import { useEnvironment } from '../../../utilities/contexts';
import { isSuperUser } from '../../../utilities/auth';
import { useLock } from '../../../utilities/hooks';
/**
 * state of user view
 *
 * @returns callbacks memos and states
 */
export const useUserViewState = () => {
    // globale state
    const { getDataById, put, getDataIndex, callFunction, userData } =
        useFireBase();
    const { id } = useParams<{ id: string }>();
    const { lock } = useLock();
    const format = useFormat();
    const { language } = useContext(LanguageContext);
    const dialog = useDialog();
    const unsavedChangesDialog = useUnsavedChangesDialog();
    const adminDialog = useAdminDialog();
    const { secureNavigate, setNavigationLock } = useSecureNavigate();
    const { theme } = useTheme();
    const { environment } = useEnvironment();
    // local state
    const [user, setUser] = useState<MUserData>(new MUserData());
    const [agents, setAgents] = useState<MUserData[]>([]);
    const [activeChat, setActiveChat] = useState<MChat>();
    const [prevUser, setPrevUser] = useState<MUserData>(new MUserData());
    const [verificator, setVerificator] = useState<MUserData>(new MUserData());
    const [note, setNote] = useState(
        `Approbation seit:<br><br>Einsatz gesucht:<br><br>Einsatzstandort:<br><br>Einsatzzeiten:<br><br>Besondere Erfahrung:<br><br>Führungserfahrung/Potential:<br><br>Unterkunft:<br><br>Wie sind Sie auf SITUS.docs gekommen?`,
    );
    const [prevNote, setPrevNote] = useState(
        `Approbation seit:<br><br>Einsatz gesucht:<br><br>Einsatzstandort:<br><br>Einsatzzeiten:<br><br>Besondere Erfahrung:<br><br>Führungserfahrung/Potential:<br><br>Unterkunft:<br><br>Wie sind Sie auf SITUS.docs gekommen?`,
    );
    const [editingNote, setEditingNote] = useState(false);
    const cFormat = useCustomFormat(language);
    const loading = useMemo(
        () => id !== 'new' && (!user.documentId || !prevUser.documentId),
        [id, user, prevUser],
    );
    /**
     * deep compare curData with prev data
     */
    const unsavedChanges = useMemo(
        () =>
            JSON.stringify(prevUser) !== JSON.stringify(user) ||
            prevNote !== note,
        [prevUser, user, note, prevNote],
    );
    /**
     * memoized check if address is complete
     */
    const addressComplete = useMemo(
        () =>
            !!(
                user.address.city &&
                user.address.country &&
                user.address.number &&
                user.address.street &&
                user.address.zipCode &&
                user.address.lat &&
                user.address.lng
            ),
        [user],
    );
    /**
     * memoized check if user prof data is complete
     */
    const profDataComplete = useMemo(
        () => !!(user.profession && user.educations.length),
        [user],
    );
    /**
     * memoized check if bankinfo is complete
     */
    const bankInfoComplete = useMemo(
        () =>
            !!(
                user.bankDetails.IBAN &&
                user.bankDetails.BIC &&
                user.bankDetails.accountHolderName
            ),
        [user],
    );
    /**
     * change current user
     */
    const onChange = useCallback((next: Partial<MUserData>) => {
        setUser((prev) => {
            return { ...prev, ...next };
        });
    }, []);
    /**
     * handle validity
     */
    const handleValidity = useCallback(async (v: string | undefined) => {
        if (v) {
            console.log('Validity unhandled:', v);
        }
    }, []);
    /**
     * handle save
     */
    const handleSave = useCallback(
        async (variation?: MUserData, skiptNavigate?: boolean) => {
            if (!user || !prevUser.documentId) {
                return console.error('unexpected access to this function');
            }
            if (isSuperUser(userData) && !(await adminDialog())) return;

            const unlock = lock();
            const next = new MUserData(
                variation
                    ? { ...variation }
                    : {
                          ...user,
                      },
            );
            await put(ECollections.users, next.documentId, next);

            unlock();
            if (!variation && !skiptNavigate) {
                secureNavigate('/user', { force: true });
            } else {
                setUser(new MUserData(next));
                setPrevUser(new MUserData(next));
            }

            return true;
        },
        [prevUser, user, userData],
    );
    /**
     * async function to await before notification send
     */
    const confirmNotificationSend = useCallback(
        async () =>
            await dialog({
                title: format(userMessages.confirmNotificationSendTitle),
                message: format(userMessages.confirmNotificationSendMessage, {
                    mail: user.mail,
                }),
                cancelButton: {
                    text: format(actionMessages.back),
                },
                buttons: [
                    {
                        text: format(actionMessages.continue),
                    },
                ],
                icon: 'warning',
            }),
        [user],
    );
    /**
     * callback for notification for missed onboardings
     */
    const handleMissedOnboardingNotification = useCallback(async () => {
        const variables = {
            title: user.title ? cFormat(doctorTitleMessages[user.title]) : '',
            lastName: user.lastName,
            zoomLink: '',
        };
        const procceed = await dialog({
            title: format(userMessages.onboardingReminderSendTitle),
            message: format(userMessages.onboardingReminderSendMessage, {
                mail: user.mail,
            }),
            cancelButton: {
                text: format(actionMessages.back),
            },
            textInputs: [
                {
                    title: format(userMessages.zoomLink),
                    id: 'zoomLink',
                },
            ],
            buttons: [
                {
                    text: format(userMessages.missedOnboarding),
                },
                {
                    text: format(userMessages.sendReminder),
                    disabled: (inputs) =>
                        !inputs ||
                        !inputs.length ||
                        !!inputs.find((i) => !i.value),
                    onPress: (inputs) => {
                        if (inputs) {
                            variables.zoomLink =
                                inputs.find((i) => i.id === 'zoomLink')
                                    ?.value || '';
                        }
                    },
                },
            ],
            icon: 'warning',
        });
        if (procceed && variables.zoomLink) {
            callFunction('sendManualNotification', {
                title: cFormat(notificationMessages.onboardingReminder),
                titleKey: notificationMessages.onboardingReminder.id,
                message: cFormat(
                    notificationMessages.onboardingReminderBody,
                    variables,
                ),
                messageKey: notificationMessages.onboardingReminderBody.id,
                button: cFormat(notificationMessages.onboardingReminderButton),
                url: variables.zoomLink,
                // linkTo: '/latepoint',
                mail: user.mail,
                uid: user.documentId,
                variables,
            });
        } else if (procceed) {
            callFunction('sendManualNotification', {
                title: cFormat(notificationMessages.missedOnboarding),
                titleKey: notificationMessages.missedOnboarding.id,
                message: cFormat(
                    notificationMessages.missedOnboardingBody,
                    variables,
                ),
                messageKey: notificationMessages.missedOnboardingBody.id,
                button: cFormat(notificationMessages.missedOnboardingButton),
                url: 'https://www.situsdocs.de/termin-buchen/',
                linkTo: '/latepoint',
                mail: user.mail,
                uid: user.documentId,
                variables,
            });
        }
    }, [confirmNotificationSend, user]);
    /**
     * setExtraAvailable
     */
    const setExtraAvailable = useCallback(async () => {
        if (
            await dialog({
                title: format(userMessages.changingExtraAvailable),
                message: format(userMessages.changingExtraAvailableText),
                cancelButton: {
                    text: format(actionMessages.cancel),
                },
                icon: 'question',
            })
        ) {
            const nextCur = new MUserData({
                ...user,
                extraAvailable: !prevUser.extraAvailable,
            });
            const nextPrev = new MUserData({
                ...prevUser,
                extraAvailable: !prevUser.extraAvailable,
            });
            setUser(nextCur);
            setPrevUser(nextPrev);
            handleSave(nextPrev);
        }
    }, [user, prevUser, handleSave]);
    /**
     * setExtraMobile
     */
    const setExtraMobile = useCallback(async () => {
        if (
            await dialog({
                title: format(userMessages.changingExtraMobile),
                message: format(userMessages.changingExtraMobileText),
                cancelButton: {
                    text: format(actionMessages.cancel),
                },
                icon: 'question',
            })
        ) {
            const nextCur = new MUserData({
                ...user,
                extraMobile: !prevUser.extraMobile,
                ...(!prevUser.extraMobile
                    ? {
                          willingToTravel: true,
                          notWillingToTravel: false,
                      }
                    : {}),
            });
            const nextPrev = new MUserData({
                ...prevUser,
                extraMobile: !prevUser.extraMobile,
            });
            setUser(nextCur);
            setPrevUser(nextPrev);
            handleSave(nextPrev);
        }
    }, [user, prevUser, handleSave]);
    /**
     * disableUser
     */
    const setDisabled = useCallback(async () => {
        if (
            await dialog({
                title: format(userMessages.changingDisabledStatus),
                message: format(userMessages.changingDisabledStatusText),
                cancelButton: {
                    text: format(actionMessages.cancel),
                },
                buttons: [
                    {
                        text: format(
                            prevUser.disabled
                                ? userMessages.enableUser
                                : userMessages.disableUser,
                        ),
                        color: prevUser.disabled
                            ? theme.successColor
                            : theme.errorColor,
                    },
                ],
                icon: 'question',
            })
        ) {
            const nextCur = new MUserData({
                ...user,
                disabled: !prevUser.disabled,
            });
            const nextPrev = new MUserData({
                ...prevUser,
                disabled: !prevUser.disabled,
            });
            setUser(nextCur);
            setPrevUser(nextPrev);
            handleSave(nextPrev);
        }
    }, [user, prevUser, handleSave]);
    /**
     * toggle Delete issue
     */
    const toggleDeleteIssuedOn = useCallback(async () => {
        const resolve = () => {
            const change = {
                deleteIssuedOn: user.deleteIssuedOn ? 0 : Date.now(),
            };
            const nextCur = new MUserData({
                ...user,
                ...change,
            });
            const nextPrev = new MUserData({
                ...prevUser,
                ...change,
            });
            setUser(nextCur);
            setPrevUser(nextPrev);
            handleSave(nextPrev);
        };
        if (!user.deleteIssuedOn) {
            dialog({
                title: format(userMessages.deleteAccountWarning),
                message: format(userMessages.deleteAccountWarningText),

                buttons: [
                    {
                        text: format(actionMessages.delete),
                        onPress: () => {
                            resolve();
                        },
                        color: theme.errorColor,
                    },
                ],

                cancelButton: {
                    text: format(actionMessages.back),
                },
                icon: 'warning',
            });
        } else {
            resolve();
        }
    }, [user, prevUser, handleSave]);
    /**
     * handle verify change
     * verfiy button handles saving the change it just has to be passed down to prev and cur user
     */
    const handleVerifyChange = useCallback(
        (next: MUserData) => {
            const nextCur = new MUserData({
                ...user,
                verified: next.verified,
                verifiedFrom: next.verifiedFrom,
                verifiedOn: next.verifiedOn,
                assignedAgents: next.assignedAgents,
            });
            const nextPrev = new MUserData({
                ...prevUser,
                verified: next.verified,
                verifiedFrom: next.verifiedFrom,
                verifiedOn: next.verifiedOn,
                assignedAgents: next.assignedAgents,
            });
            setUser(nextCur);
            setPrevUser(nextPrev);
        },
        [user, prevUser, handleSave],
    );
    /**
     * handle save of note
     */
    const handleNoteSave = useCallback(async () => {
        if (!user) {
            return console.error('unexpected access to this function');
        }

        await put(ECollections.userNotes, user.documentId, { note });

        setPrevNote(note);
    }, [user, note]);
    /**
     * handle personal reminder
     */
    const sendProfileReminder = useCallback(async () => {
        if (await confirmNotificationSend()) {
            callFunction('sendManualNotification', {
                title: cFormat(notificationMessages.completeProfileDocuments),
                titleKey: notificationMessages.completeProfileDocuments.id,
                message: cFormat(
                    notificationMessages.completeProfileDocumentsBody,
                    {
                        platform: environment,
                    },
                ),
                messageKey:
                    notificationMessages.completeProfileDocumentsBody.id,
                button: cFormat(
                    notificationMessages.completeProfileDocumentsButton,
                ),
                url: 'https://app.situsdocs.de/profile/documents',
                linkTo: '/profile/documents',
                mail: user.mail,
                uid: user.documentId,
                variables: {
                    platform: environment,
                },
            });
        }
    }, [user, confirmNotificationSend, cFormat, environment]);
    /**
     * handle profession reminder
     */
    const sendProfessionReminder = useCallback(async () => {
        if (await confirmNotificationSend()) {
            callFunction('sendManualNotification', {
                title: cFormat(notificationMessages.completeProfileProfession),
                titleKey: notificationMessages.completeProfileProfession.id,
                message: cFormat(
                    notificationMessages.completeProfileProfessionBody,
                ),
                messageKey:
                    notificationMessages.completeProfileProfessionBody.id,
                button: cFormat(
                    notificationMessages.completeProfileProfessionButton,
                ),
                url: 'https://app.situsdocs.de/profile/personal',
                linkTo: '/profile/personal',
                mail: user.mail,
                uid: user.documentId,
            });
        }
    }, [user, confirmNotificationSend, cFormat]);
    /**
     * handle address reminder
     */
    const sendAddressReminder = useCallback(async () => {
        if (await confirmNotificationSend()) {
            callFunction('sendManualNotification', {
                title: cFormat(notificationMessages.completeProfileAddress),
                titleKey: notificationMessages.completeProfileAddress.id,
                message: cFormat(
                    notificationMessages.completeProfileAddressBody,
                ),
                messageKey: notificationMessages.completeProfileAddressBody.id,
                button: cFormat(
                    notificationMessages.completeProfileAddressButton,
                ),
                url: 'https://app.situsdocs.de/profile/address',
                linkTo: '/profile/address',
                mail: user.mail,
                uid: user.documentId,
            });
        }
    }, [user, confirmNotificationSend, cFormat]);
    /**
     * handle bank reminder
     */
    const sendBankReminder = useCallback(async () => {
        if (await confirmNotificationSend()) {
            callFunction('sendManualNotification', {
                title: cFormat(notificationMessages.completeProfileBanking),
                titleKey: notificationMessages.completeProfileBanking.id,
                message: cFormat(
                    notificationMessages.completeProfileBankingBody,
                ),
                messageKey: notificationMessages.completeProfileBankingBody.id,
                button: cFormat(
                    notificationMessages.completeProfileBankingButton,
                ),
                url: 'https://app.situsdocs.de/profile/banking',
                linkTo: '/profile/banking',
                mail: user.mail,
                uid: user.documentId,
            });
        }
    }, [user, confirmNotificationSend]);
    /**
     * navigation lock. ask for discard before navigation
     */
    const navigationLock = useCallback(async () => {
        const locking = unsavedChanges && !(await unsavedChangesDialog());
        return locking;
    }, [unsavedChanges, unsavedChangesDialog]);
    /**
     * register lock as current navigationlock
     */
    useEffect(() => setNavigationLock(navigationLock), [navigationLock]);
    /**
     * effect to load user data
     */
    useEffect(() => {
        if (id && id !== 'new') {
            getDataById(ECollections.userNotes, id).then((result) => {
                if (result && result.note) {
                    setNote(result.note);
                    setPrevNote(result.note);
                }
            });
            getDataById(ECollections.users, id).then((result) => {
                setUser(new MUserData(result));
                setPrevUser(new MUserData(result));
            });
        }
    }, [id]);
    /**
     * effect to load verificator
     */
    useEffect(() => {
        if (user && user.verified && user.verifiedFrom) {
            getDataById(ECollections.users, user.verifiedFrom).then(
                (result) => {
                    setVerificator(new MUserData(result));
                },
            );
        }
    }, [user]);
    /**
     * effect to load active chat
     */
    useEffect(() => {
        if (user && user.documentId) {
            getDataIndex(ECollections.chats, {
                filter: [
                    {
                        operator: 'array-contains',
                        value: user.documentId,
                        field: 'participants',
                    },
                ],
            }).then((results) => {
                const chats = (results as MChat[])
                    .map((c) => new MChat(c))
                    .filter((c) => c.participants.includes(user.documentId));
                if (chats.length) {
                    setActiveChat(chats[0]);
                }
            });
        }
    }, [user]);
    /**
     * effect to load all agents
     */
    useEffect(() => {
        getDataIndex(ECollections.users, {
            filter: [
                {
                    field: 'type',
                    operator: 'in',
                    value: [EUserType.admin, EUserType.moderator],
                },
            ],
        }).then((result) => {
            setAgents((result as MUserData[]).map((u) => new MUserData(u)));
        });
    }, []);
    /**
     * return everything for render
     */
    return {
        loading,
        user,
        prevUser,
        agents,
        verificator,
        onChange,
        setDisabled,
        setExtraAvailable,
        setExtraMobile,
        handleSave,
        handleVerifyChange,
        handleMissedOnboardingNotification,
        handleValidity,
        cFormat,
        note,
        setNote,
        editingNote,
        setEditingNote,
        handleNoteSave,
        confirmNotificationSend,
        addressComplete,
        profDataComplete,
        bankInfoComplete,
        activeChat,
        toggleDeleteIssuedOn,
        sendProfileReminder,
        sendProfessionReminder,
        sendAddressReminder,
        sendBankReminder,
    };
};
