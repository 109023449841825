import { RideStep } from './Ride';
import { dashboardRideMessages } from '../messages';

export const dashboardRide: RideStep[] = [
    {
        content: dashboardRideMessages.welcome,
        placement: 'center',
        target: 'body',
    },
    {
        content: dashboardRideMessages.navigation,
        target: '#menu-content',
        placement: 'right',
    },
    {
        content: dashboardRideMessages.youAreOnDashboard,
        target: '#menu-home',
        placement: 'right',
    },
    {
        content: dashboardRideMessages.progression,
        target: '#profile-completion',
    },
    {
        content: dashboardRideMessages.thisIsYou,
        target: '#profile-picture',
        placement: 'bottom',
    },
];
