import { useCallback, useEffect, useMemo, useState } from 'react';
import { IMessage } from 'react-native-gifted-chat';
import { useNavigate, useParams } from '../../../utilities/routing';
import { useFireBase } from '../../../utilities/firebase';
import { MChatMessage } from '../../../models/MChatMessage';
import { MChat } from '../../../models/MChat';
import { Unsubscribe } from 'firebase/firestore';
import { MContract, MFile, MSupportRequest, MUserData } from '../../../models';
import {
    ECollections,
    EContractFiles,
    EGeneralFiles,
    EPureContractFiles,
} from '../../../enums';
import { IChatViewProps } from './ChatView';
import { useLock } from '../../../utilities/hooks';
import {
    actionMessages,
    filenameMessages,
    generalMessages,
} from '../../../utilities/messages';
import { useDialog } from '../../../utilities/dialog';
import { contractMessages } from '../../Contract/contract.messages';
import { isSuperUser } from '../../../utilities/auth';
import { chatMessages } from '../Chat.messages';
import { useFileUpload } from '../../../utilities/hooks/useFileUpload';
/**
 * chat view state
 * @param param0 props
 * @returns values for render
 */
export const useChatViewState = ({
    preFile: propsPrefile,
    handleFile: propsHandleFile,
    chat: propsChat,
}: IChatViewProps) => {
    // global state
    const { userData, getDataIndex, getDataById, post, put, callFunction } =
        useFireBase();
    const { id } = useParams<{ id: string }>();
    const { lock } = useLock();
    const dialog = useDialog();
    const navigate = useNavigate();
    const fileUpload = useFileUpload();
    // local state
    const [loadCount, setLoadCount] = useState(0);
    const [messages, setMessages] = useState<MChatMessage[]>([]);
    const [chat, setChat] = useState(propsChat);
    const [participants, setParticipants] = useState<MUserData[]>([]);
    const [unsubscribe, setUnsubscribe] = useState<Unsubscribe>();
    const [supportRequest, setSupportRequest] = useState<MSupportRequest>();
    const [contract, setContract] = useState<MContract>();
    const [loading, setLoading] = useState(true);
    /**
     * opposing correspondent
     */
    const mainCorrespondent = useMemo(() => {
        if (participants.length === 1) {
            return participants[0];
        }
    }, [participants, userData]);
    /**
     * memoized value if there is a possbility of loading more messages
     *
     * TODO: take in account if live messages where added
     */
    const hasEarlier = useMemo(
        () => !!(loadCount && loadCount % 25 === 0),
        [messages],
    );
    /**
     * memoized IMessage interpretation from MChatMessages
     */
    const messagesToDisplay = useMemo(() => {
        const toReturn: IMessage[] = messages.map((m) => {
            const ud = participants.find((p) => p.documentId === m.from);
            return {
                _id: m.documentId,
                createdAt: new Date(m.createdOn),
                text: m.message,
                user: {
                    _id: m.from,
                    name: ud ? ud.firstName + ' ' + ud.lastName : m.from,
                    avatar: ud?.picture || m.picture,
                },
            };
        });
        return toReturn.sort(
            (a, b) => (b.createdAt as number) - (a.createdAt as number),
        );
    }, [messages]);
    /**
     * effect to load chat from id
     */
    useEffect(() => {
        if (!propsChat && id) {
            setLoading(true);
            setMessages([]);
            getDataById(ECollections.chats, id).then((res) => {
                setChat(new MChat(res));
                setLoading(false);
            });
        }
    }, [propsChat, id]);
    /**
     * function to load more possibly with offset
     */
    const loadMore = useCallback(
        (offset?: string) => {
            if (chat && chat.documentId) {
                const params = {
                    orderBy: 'createdOn',
                    filter: [{ field: 'chat', value: chat.documentId }],
                    limit: 25,
                    startDocumentId: undefined as string | undefined,
                };
                if (offset) {
                    params.startDocumentId = offset;
                }
                getDataIndex(ECollections.chatMessages, params).then((res) => {
                    const next = (res as MChatMessage[]).map(
                        (r) => new MChatMessage(r),
                    );
                    setMessages((prev) => [...prev, ...next]);
                    setLoadCount((prev) => prev + next.length);
                    if (!next.length) {
                        // increment load count to tip it over since not a single message was loaded
                        setLoadCount((prev) => prev++);
                    }
                });
            }
        },
        [chat],
    );
    /**
     * initial trigger chat messages load and participant data
     */
    useEffect(() => {
        if (chat && chat.documentId) {
            const params = {
                orderBy: 'createdOn',
                filter: [{ field: 'chat', value: chat.documentId }],
                limit: 25,
                startDocumentId: undefined as string | undefined,
            };
            getDataIndex(
                ECollections.chatMessages,
                params,
                (event) => {
                    if (event.type === 'added') {
                        const id = event.doc.id;
                        setMessages((prev) => {
                            if (prev.find((p) => p.documentId === id)) {
                                return prev;
                            } else {
                                const d = event.doc.data();
                                const cm = new MChatMessage({
                                    ...d,
                                    documentId: id,
                                });
                                const isNotRead =
                                    !cm.read.find(
                                        (r) => r.from === userData.documentId,
                                    ) && cm.from !== userData.documentId;

                                if (isNotRead) {
                                    cm.read.push({
                                        from: userData.documentId,
                                        on: Date.now(),
                                    });
                                    put(
                                        ECollections.chatMessages,
                                        cm.documentId,
                                        cm,
                                    );
                                }
                                return [cm, ...prev];
                            }
                        });
                    }
                },
                (us) => {
                    setUnsubscribe(() => us);
                },
            ).then(() => {
                setLoading(false);
            });
            Promise.all(
                chat.participants.map(
                    (uid) =>
                        new Promise<MUserData | undefined>((resolve) => {
                            if (uid !== userData.documentId) {
                                getDataById(ECollections.publicUsers, uid).then(
                                    (u) => resolve(new MUserData(u)),
                                );
                            } else {
                                resolve(undefined);
                            }
                        }),
                ),
            ).then((res) => {
                setParticipants(res.filter((r) => !!r) as MUserData[]);
            });
        }
    }, [chat, loadMore]);
    /**
     * effect to register unsubscribe as cleanup function
     * it gets triggered if react thinks it is time to clean up
     */
    useEffect(() => {
        return unsubscribe;
    }, [unsubscribe]);
    /**
     * send message
     */
    const onSend = useCallback(
        (messages: IMessage[] = []) => {
            const message = (messages as IMessage[])[0];
            if (message.text && chat && chat.documentId) {
                const m = new MChatMessage({
                    chat: chat.documentId,
                    participants: chat.participants,
                    message: message.text,
                    from: userData.documentId,
                    createdOn: Date.now(),
                    picture: userData.picture,
                });
                post(ECollections.chatMessages, m).then((v) => {
                    if (v) {
                        m.documentId = v.id;
                        setMessages((cur) => {
                            if (cur.find((p) => p.documentId === v.id)) {
                                return cur;
                            } else {
                                return [...cur, m];
                            }
                        });
                    }
                });
            }
        },
        [chat],
    );
    /**
     * handle file callback gets overwritten from props
     */
    const handleFile = useCallback(
        async (
            newFn: string,
            file: Uint8Array,
            preFileResult: { nextType: string },
        ) => {
            if (propsHandleFile) {
                return propsHandleFile(newFn, file, preFileResult);
            }
            if (chat?.contractId) {
                const unlock = lock();
                try {
                    const baseFile = await fileUpload(
                        `contracts/${chat.contractId}/${userData.documentId}`,
                        newFn,
                        file,
                    );
                    /**
                     * call set contract to let firebase cloud function move the file to an appropriate location
                     * and add it to the contract
                     * reload contract afterwards
                     */
                    await callFunction('setContractFile', {
                        filename: baseFile.name,
                        path: baseFile.path,
                        filetype: preFileResult.nextType,
                        contractId: chat.contractId,
                    });

                    unlock();
                    // TODO: issue file upload as chat message
                    // setReload(Date.now());
                } catch (e) {
                    unlock();
                    dialog({
                        title: generalMessages.errorOccured,
                        message: `${e}`,
                        icon: 'error',
                    });
                }
            }
            if (chat?.supportRequestId) {
                const sr = new MSupportRequest(
                    await getDataById(
                        ECollections.supportRequests,
                        chat.supportRequestId,
                    ),
                );
                const unlock = lock();
                try {
                    const baseFile = await fileUpload(
                        `${ECollections.users}/${sr.userId}/${ECollections.supportRequests}/${sr.documentId}`,
                        newFn,
                        file,
                    );
                    await post(
                        `${ECollections.supportRequests}/${sr.documentId}/${ECollections.files}`,
                        new MFile({
                            ...baseFile,
                            author: userData.documentId,
                        }),
                    );
                    // TODO: issue file upload as chat message
                    // if (chat && chat.documentId) {
                    //     const url = await getFileDownloadUrl(fileMeta.fullPath);
                    //     const m = new MChatMessage({
                    //         chat: chat.documentId,
                    //         participants: chat.participants,
                    //         message: format(chatMessages.documentUploaded, {
                    //             documentUrl: url,
                    //         }),
                    //         from: userData.documentId,
                    //         createdOn: Date.now(),
                    //         picture: userData.picture,
                    //     });
                    //     post(ECollections.chatMessages, m).then((v) => {
                    //         if (v) {
                    //             m.documentId = v.id;
                    //             setMessages((cur) => {
                    //                 if (
                    //                     cur.find((p) => p.documentId === v.id)
                    //                 ) {
                    //                     return cur;
                    //                 } else {
                    //                     return [...cur, m];
                    //                 }
                    //             });
                    //         }
                    //     });
                    // }
                    unlock();
                } catch (e) {
                    unlock();
                    dialog({
                        title: generalMessages.errorOccured,
                        message: `${e}`,
                        icon: 'error',
                    });
                }
            }
        },
        [propsHandleFile, chat, userData, fileUpload],
    );
    /**
     * pre file callback gets overwritte from props
     */
    const preFile = useCallback(async () => {
        if (propsPrefile) {
            return propsPrefile();
        }
        if (chat?.contractId) {
            let nextType: EContractFiles = EGeneralFiles.other;

            if (
                await dialog({
                    title: contractMessages.selectFileType,
                    message: contractMessages.selectFileTypeText,
                    icon: 'info',
                    pickerInputs: [
                        {
                            id: 'type',
                            title: contractMessages.fileType,
                            placeholder: contractMessages.fileTypePlaceholder,
                            values: [
                                ...[
                                    ...Object.values(EPureContractFiles),
                                    EGeneralFiles.other,
                                ].map((ecf) => {
                                    return {
                                        label: filenameMessages[ecf],
                                        value: ecf,
                                    };
                                }),
                            ],
                        },
                    ],
                    buttons: [
                        {
                            text: actionMessages.ok,
                            disabled: (inputs) =>
                                !inputs?.find((i) => i.id === 'type'),
                            onPress: (inputs) => {
                                const ioi = inputs?.find(
                                    (i) => i.id === 'type',
                                );
                                if (ioi) {
                                    nextType = ioi.value as EContractFiles;
                                }
                            },
                        },
                    ],
                    cancelButton: { text: actionMessages.cancel },
                })
            ) {
                return { nextType };
            }
        }
        return true;
    }, [propsPrefile, chat]);

    const enableFileUpload = useMemo(
        () =>
            !chat?.closedOn &&
            (propsHandleFile || chat?.contractId || chat?.supportRequestId),
        [chat, propsHandleFile],
    );

    const closeSupportRequest = useCallback(async () => {
        if (!isSuperUser(userData)) {
            return;
        }
        if (
            !(await dialog({
                title: chatMessages.closeSupportRequest,
                message: chatMessages.closeSupportRequestText,
                buttons: [{ text: actionMessages.close }],
                cancelButton: { text: actionMessages.cancel },
                icon: 'warning',
            }))
        ) {
            return;
        }
        const unlock = lock();
        const srid = chat?.supportRequestId;
        const chid = chat?.documentId;
        if (!srid || !chid) {
            return;
        }
        const sr = await getDataById(ECollections.supportRequests, srid);
        const supportRequest = new MSupportRequest(sr);
        await put(ECollections.supportRequests, srid, {
            ...supportRequest,
            state: 'closed',
            closedBy: userData.documentId,
        });
        await put(ECollections.chats, chid, {
            ...chat,
            closedOn: Date.now(),
        });
        unlock();
        navigate('/supportRequests');
    }, [chat, userData]);
    /**
     * effect to load support request / contract and clear them
     */
    useEffect(() => {
        if (chat?.contractId) {
            getDataById(ECollections.contracts, chat.contractId).then(
                (result) => setContract(new MContract(result)),
            );
        } else {
            setContract(undefined);
        }
        if (chat?.supportRequestId) {
            getDataById(
                ECollections.supportRequests,
                chat.supportRequestId,
            ).then((result) => setSupportRequest(new MSupportRequest(result)));
        } else {
            setSupportRequest(undefined);
        }
    }, [chat]);
    /**
     * values for render
     */
    return {
        chat,
        messagesToDisplay,
        loading,
        onSend,
        participants,
        loadMore,
        messages,
        hasEarlier,
        handleFile,
        preFile,
        enableFileUpload,
        closeSupportRequest,
        supportRequest,
        contract,
        mainCorrespondent,
    };
};
