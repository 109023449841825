import { defineMessages } from 'react-intl';

const scope = 'day.';

export const dayMessages = defineMessages({
    monday: {
        id: scope + 'monday',
        defaultMessage: 'Monday',
    },
    tuesday: {
        id: scope + 'tuesday',
        defaultMessage: 'Tuesday',
    },
    wednesday: {
        id: scope + 'wednesday',
        defaultMessage: 'Wednesday',
    },
    thursday: {
        id: scope + 'thursday',
        defaultMessage: 'Thursday',
    },
    friday: {
        id: scope + 'friday',
        defaultMessage: 'Friday',
    },
    saturday: {
        id: scope + 'saturday',
        defaultMessage: 'Saturday',
    },
    sunday: {
        id: scope + 'sunday',
        defaultMessage: 'Sunday',
    },
    mondayShort: {
        id: scope + 'mondayShort',
        defaultMessage: 'Mo',
    },
    tuesdayShort: {
        id: scope + 'tuesdayShort',
        defaultMessage: 'Tu',
    },
    wednesdayShort: {
        id: scope + 'wednesdayShort',
        defaultMessage: 'We',
    },
    thursdayShort: {
        id: scope + 'thursdayShort',
        defaultMessage: 'Th',
    },
    fridayShort: {
        id: scope + 'fridayShort',
        defaultMessage: 'Fr',
    },
    saturdayShort: {
        id: scope + 'saturdayShort',
        defaultMessage: 'Sa',
    },
    sundayShort: {
        id: scope + 'sundayShort',
        defaultMessage: 'Su',
    },
});
