import { StyleSheet } from 'react-native';
import { ITheme } from '../../../utilities/styles/ITheme';

export const makeLabelStyles = (theme: ITheme) =>
    StyleSheet.create({
        check: {
            backgroundColor: 'transparent',
            color: theme.accentColor,
            borderColor: theme.accentColor,
        },
        unchecked: {
            backgroundColor: theme.backgroundTertiaryColor,
        },
        label: {
            color: theme.accentColor,
            borderStyle: 'solid',
            borderWidth: 1,
            margin: 5,
            minHeight: 40,
            paddingHorizontal: 25,
            borderRadius: 10,
            alignItems: 'center',
            justifyContent: 'space-around',
            minWidth: 75,
        },
        buttonSmall: {
            borderRadius: 10,
            padding: 2,
            paddingHorizontal: 15,
            alignItems: 'center',
            minHeight: 24,
            justifyContent: 'center',
            zIndex: 2,
        },
        buttonSmaller: {
            borderRadius: 10,
            padding: 2,
            paddingHorizontal: 15,
            alignItems: 'center',
            minHeight: 16,
            justifyContent: 'center',
            zIndex: 2,
        },
        labelChecked: {
            color: theme.accentColor,
        },
        labelUnchecked: {
            color: theme.textMainColor,
        },
    });
