import React, { FC, useMemo } from 'react';
import { EField } from '../../enums';
import { useFormat } from '../../utilities/intl';
import { fieldMessages } from '../../utilities/messages';
import { CPicker } from '../elements';
import { pickerMessages } from './picker.messages';
import { useFireBase } from '../../utilities/firebase';
import { isSuperUser } from '../../utilities/auth';
import { View } from 'react-native';
import { Message } from '../../utilities/types';

interface IFieldPickerProps {
    onChange: (next: EField) => void;
    fields?: EField[];
    value?: EField;
    titleOverride?: Message;
    placeholder?: Message;
    cy?: string;
    optionsFilter?: string[];
    allowEmpty?: boolean;
    allowUndefined?: boolean;
    horizontal?: boolean;
}

export const FieldPicker: FC<IFieldPickerProps> = ({
    value,
    onChange,
    fields,
    cy,
    titleOverride,
    optionsFilter,
    allowEmpty,
    placeholder,
    horizontal,
}) => {
    const format = useFormat();
    const { userData } = useFireBase();
    /**
     * professions filtered and sorted
     */
    const filteredFields = useMemo(() => {
        const filter = optionsFilter || [];
        return Object.values(EField)
            .filter((v) => !filter.includes(v))
            .filter((v) => v !== EField.undefined || isSuperUser(userData))
            .filter((v) =>
                fields && fields.length
                    ? fields.includes(v)
                    : !userData.fields.length || userData.fields.includes(v),
            )
            .map((v) => {
                return {
                    label: format(fieldMessages[v]),
                    value: v,
                };
            })
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [optionsFilter]);

    if (!filteredFields.length) {
        return <View />;
    }

    return (
        <CPicker
            cy={cy || 'field-picker'}
            title={titleOverride || format(pickerMessages.field)}
            values={filteredFields}
            onChange={onChange}
            value={value || ''}
            placeholder={
                placeholder || `-${format(pickerMessages.fieldPlaceholder)}-`
            }
            allowEmpty={allowEmpty}
            horizontal={horizontal}
        />
    );
};
