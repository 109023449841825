import React, { FC, useCallback, useState } from 'react';
import { useFireBase } from '../../../../../utilities/firebase';
import { ECollections } from '../../../../../enums';
import { CButton } from '../../../../../components';
import { MWorkplace } from '../../../../../models';

export const TouchWorkplaces: FC = () => {
    const { getDataIndex, put } = useFireBase();

    const [touchingWorkplaces, setTouchingWorkplaces] = useState(false);
    /**
     * callback to move all workplaces to workplaces collection
     */
    const touchWorkplaces = useCallback(() => {
        setTouchingWorkplaces(true);
        getDataIndex(ECollections.workplaces as any).then((workplaces) => {
            Promise.all(
                (workplaces as MWorkplace[]).map((workplace) => {
                    const next = new MWorkplace(workplace);
                    return put(ECollections.workplaces, workplace.documentId, {
                        ...next,
                        touched: Date.now(),
                    });
                }),
            ).then(() => {
                setTouchingWorkplaces(false);
            });
        });
    }, [getDataIndex, put]);

    return (
        <CButton
            onPress={touchWorkplaces}
            title="Touch workplaces"
            warning
            disabled={touchingWorkplaces}
        />
    );
};
