import React, { FC, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useFireBase } from '../../utilities/firebase';
import { generalMessages } from '../../utilities/messages';
import { useStyle } from '../../utilities/styles';
import { CText } from '../elements';
import PDFViewer from '../PDFViewer';
import { Spinner } from '../Spinner';
import { useEnvironment } from '../../utilities/contexts';
import { LanguageContext } from '../../utilities/intl';

export const ResumeView: FC<{
    applicationId?: string;
    availabilityId?: string;
    self?: boolean;
    profProfileId?: string;
    additionalButtons?: {
        onPress: () => void;
        title: string;
        icon: string;
        color: string;
        cy?: string;
    }[];
    override?: string;
}> = ({
    applicationId,
    availabilityId,
    self,
    profProfileId,
    additionalButtons,
    override,
}) => {
    // global state
    const style = useStyle();
    const { callFunction } = useFireBase();
    const { environment } = useEnvironment();
    const { language } = useContext(LanguageContext);
    // local state
    const [filename, setFileName] = useState<string>();
    /**
     * effect to load filenames and verify access to files and state of files
     */
    useEffect(() => {
        const options: any = { environment, lang: language };
        if (profProfileId) {
            options.profProfileId = profProfileId;
        } else if (self || applicationId) {
            options.applicationId = applicationId;
        }
        if (availabilityId) {
            options.availabilityId = availabilityId;
        }
        callFunction('getResume', options)
            .then((res) => {
                setFileName(res);
            })
            .catch((e) => console.log(e));
    }, [
        self,
        applicationId,
        availabilityId,
        profProfileId,
        language,
        environment,
    ]);
    /**
     * return spinner during load
     */
    if (!filename && !override) {
        return (
            <View style={[style.centeredContent, style.verticalPadded]}>
                <CText centered message={generalMessages.loadingResume} />
                <Spinner />
            </View>
        );
    }
    /**
     * render
     */
    return (
        <PDFViewer
            filename={override || filename || ''}
            additionalButtons={additionalButtons}
        />
    );
};
