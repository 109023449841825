import { defineMessages } from 'react-intl';

const scope = 'con.docscan.';

export const documentScanMessages = defineMessages({
    submitForReview: {
        id: scope + 'submitForReview',
        defaultMessage: 'Submit for review',
    },
    uploadANewIdentityDocument: {
        id: scope + 'uploadANewIdentityDocument',
        defaultMessage:
            'Upload photo ID for authentication to enable eSignature usage',
    },
    front: {
        id: scope + 'front',
        defaultMessage: 'Front',
    },
    back: {
        id: scope + 'back',
        defaultMessage: 'Back',
    },
    documentWithUser: {
        id: scope + 'documentWithUser',
        defaultMessage: 'You holding the document',
    },
    person: {
        id: scope + 'person',
        defaultMessage: 'You holding the document',
    },
    documentNumber: {
        id: scope + 'documentNumber',
        defaultMessage: 'Document number',
    },
    documentIssuedBy: {
        id: scope + 'documentIssuedBy',
        defaultMessage: 'Document issued by',
    },
    documentScanList: {
        id: scope + 'documentScanList',
        defaultMessage: 'Identity document review',
    },
    verify: {
        id: scope + 'verify',
        defaultMessage: 'Verify',
    },
    reject: {
        id: scope + 'reject',
        defaultMessage: 'Reject',
    },
    confirmVerify: {
        id: scope + 'confirmVerify',
        defaultMessage: 'Confirm verification',
    },
    confirmVerifyText: {
        id: scope + 'confirmVerifyText',
        defaultMessage: 'Confirm verification',
    },
    confirmReject: {
        id: scope + 'confirmReject',
        defaultMessage: 'Confirm rejection of verification',
    },
    confirmRejectText: {
        id: scope + 'confirmRejectText',
        defaultMessage: 'Confirm rejection of verification',
    },
    reviewedBy: {
        id: scope + 'reviewedBy',
        defaultMessage: 'Reviewed by',
    },
    uploadPictures: {
        id: scope + 'uploadPictures',
        defaultMessage:
            'Please upload only picture files. If you have uploaded the wrong files and the upload accepted them please restart this process.',
    },
    submittedForReview: {
        id: scope + 'submittedForReview',
        defaultMessage: 'Successfully submitted for review.',
    },
    submittedForReviewText: {
        id: scope + 'submittedForReviewText',
        defaultMessage:
            'You have successfully submitted your document for review. One of our agents will manually verify that everything went correctly and after that you will be able to use digital signatures until your document expires.',
    },
    documentDidNotMatchExpectedType: {
        id: scope + 'documentDidNotMatchExpectedType',
        defaultMessage: 'Uploaded document did not match expected type {type}.',
    },
    documentDidNotMatchExpectedTypeText: {
        id: scope + 'documentDidNotMatchExpectedTypeText',
        defaultMessage:
            'The document you uploaded was not usable as type {type}.',
    },
    restart: {
        id: scope + 'restart',
        defaultMessage: 'Restart',
    },
    documentExpiryDate: {
        id: scope + 'documentExpiryDate',
        defaultMessage: 'Document expires on:',
    },
    documentIssuedOn: {
        id: scope + 'documentIssuedOn',
        defaultMessage: 'Document issued on:',
    },
});
