export enum EContractStatus {
    creating = 'creating',
    accepted = 'accepted',
    review_yer = 'review_yer',
    review_yee = 'review_yee',
    signed = 'signed',
    completed = 'completed',
    paid = 'paid',
    canceled = 'canceled',
}
