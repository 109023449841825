import { defineMessages } from 'react-intl';

const scope = 'medic.nurse.education.';
/**
 * nursingEducation names
 * is used for nursingEducation picker
 */
export const nursingEducationMessages = defineMessages({
    ata: { id: scope + 'ata', defaultMessage: 'ATA' },
    geriatricNurseAssistant: {
        id: scope + 'geriatricNurseAssistant',
        defaultMessage: 'Geriatric Nurse Assistant',
    },
    geriatricNurse: {
        id: scope + 'geriatricNurse',
        defaultMessage: 'Geriatric Nurse',
    },
    headOfDepartment: {
        id: scope + 'headOfDepartment',
        defaultMessage: 'Head Of Department',
    },
    surgicalTechnicalAssistant: {
        id: scope + 'surgicalTechnicalAssistant',
        defaultMessage: 'Surgical Technical Assistant',
    },
    facilityHomeManagement: {
        id: scope + 'facilityHomeManagement',
        defaultMessage: 'Facility/Home Management',
    },
    ergotherapist: {
        id: scope + 'ergotherapist',
        defaultMessage: 'Ergotherapist',
    },
    childcareWorker: {
        id: scope + 'childcareWorker',
        defaultMessage: 'Childcare Worker',
    },
    healthAndPaediatricNurse: {
        id: scope + 'healthAndPaediatricNurse',
        defaultMessage: 'Health and Paediatric Nurse',
    },
    healthAndNursingProfessional: {
        id: scope + 'healthAndNursingProfessional',
        defaultMessage: 'Health and Nursing Professional',
    },
    healthAndNursingAssistant: {
        id: scope + 'healthAndNursingAssistant',
        defaultMessage: 'Health and Nursing Assistant',
    },
    midwifeMaternityNurse: {
        id: scope + 'midwifeMaternityNurse',
        defaultMessage: 'Midwife/Maternity Nurse',
    },
    curativeEducationNurse: {
        id: scope + 'curativeEducationNurse',
        defaultMessage: 'Curative Education Nurse',
    },
    curativeEducationNurseAssistant: {
        id: scope + 'curativeEducationNurseAssistant',
        defaultMessage: 'Curative Education Nurse Assistant',
    },
    paediatricNurse: {
        id: scope + 'paediatricNurse',
        defaultMessage: 'Paediatric Nurse',
    },
    nurse: { id: scope + 'nurse', defaultMessage: 'Nurse' },
    managementActivity: {
        id: scope + 'managementActivity',
        defaultMessage: 'Management Activity',
    },
    logopaed: { id: scope + 'logopaed', defaultMessage: 'Logopaed' },
    mtla: { id: scope + 'mtla', defaultMessage: 'MTLA (laboratory)' },
    mtra: { id: scope + 'mtra', defaultMessage: 'MTRA (Radiology)' },
    medicalAssistant: {
        id: scope + 'medicalAssistant',
        defaultMessage: 'Medical Assistant',
    },
    emergencyParamedic: {
        id: scope + 'emergencyParamedic',
        defaultMessage: 'Emergency Paramedic',
    },
    ota: { id: scope + 'ota', defaultMessage: 'OTA' },
    pta: { id: scope + 'pta', defaultMessage: 'PTA' },
    nursingDirector: {
        id: scope + 'nursingDirector',
        defaultMessage: 'Nursing Director',
    },
    nursingDirectorate: {
        id: scope + 'nursingDirectorate',
        defaultMessage: 'Nursing Directorate',
    },
    nursingSpecialist: {
        id: scope + 'nursingSpecialist',
        defaultMessage: 'Nursing Specialist',
    },
    physicianAssistant: {
        id: scope + 'physicianAssistant',
        defaultMessage: 'Physician Assistant',
    },
    physiotherapist: {
        id: scope + 'physiotherapist',
        defaultMessage: 'Physiotherapist',
    },
    podiatrist: { id: scope + 'podiatrist', defaultMessage: 'Podiatrist' },
    medicalOfficeManager: {
        id: scope + 'medicalOfficeManager',
        defaultMessage: 'Medical Office Manager',
    },
    ConsultantToTheNursingDirectorate: {
        id: scope + 'ConsultantToTheNursingDirectorate',
        defaultMessage: 'Consultant to the Nursing Directorate',
    },
    regionalManager: {
        id: scope + 'regionalManager',
        defaultMessage: 'Regional Manager',
    },
    rescueAssistant: {
        id: scope + 'rescueAssistant',
        defaultMessage: 'Rescue Assistant',
    },
    deputyNursingDirector: {
        id: scope + 'deputyNursingDirector',
        defaultMessage: 'Deputy Nursing Director',
    },
    deputyNursingDirectorate: {
        id: scope + 'deputyNursingDirectorate',
        defaultMessage: 'Deputy Nursing Directorate',
    },
    deputyWardManager: {
        id: scope + 'deputyWardManager',
        defaultMessage: 'Deputy Ward Manager',
    },
    zma: { id: scope + 'zma', defaultMessage: 'ZMA' },
});
