import Geolocation from '@react-native-community/geolocation';
import React, {
    createContext,
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Platform, View } from 'react-native';
import { CIcon, CText, Spinner, TouchableView } from '../../../components';
import { CButton } from '../../../components/elements';
import { CImage } from '../../../components/elements/CImage';
import {
    ECollections,
    EField,
    EJobStatus,
    EProfession,
    EUserType,
} from '../../../enums';
import { MAddress, MJob, MWorkplace } from '../../../models';
import { isAgencyUser, isSuperUser } from '../../../utilities/auth';
import {
    ProfileCompletionContext,
    useEnvironment,
} from '../../../utilities/contexts';
import { ScrollProvider } from '../../../utilities/contexts/Scroll';
import { useDialog } from '../../../utilities/dialog';
import { useFireBase } from '../../../utilities/firebase';
import { IFilter, IInequality } from '../../../utilities/firebase/store';
import { fromLatLngToAddress } from '../../../utilities/functions';
import { useFormat } from '../../../utilities/intl';
import { actionMessages } from '../../../utilities/messages';
import { generalMessages } from '../../../utilities/messages/general.messages';
import { useNavigate } from '../../../utilities/routing';
import { useStyle, useTheme } from '../../../utilities/styles';
import { jobMessages } from '../job.messages';
import { JobRow } from './components/JobRow';
import { EFilterMode, JobsFilterBar } from './components/JobsFilterBar';
import { applicationMessages } from '../../Application/application.messages';
import { StandaloneWrapper } from '../../../utilities/wrappers';
/**
 * context to reload jobs list from child
 */
export const JobsContext = createContext(
    {} as { reload: () => void; loadOn: number },
);
/**
 * List of public unfilled jobs to be applied to for agency and user users
 * admins might check in out of curiosity
 * TODO: revamp pinning to be its own table and then load pinned items by id not by indexing
 * @returns
 */
const UnwrappedJobList: FC = () => {
    // global state
    const { completePersonal } = useContext(ProfileCompletionContext);
    const style = useStyle();
    const { theme } = useTheme();
    const { userData } = useFireBase();
    const format = useFormat();
    const navigate = useNavigate();
    const dialog = useDialog();
    const { environment } = useEnvironment();
    const {
        getDataIndex,
        getDataById,
        put,
        getLength,
        isLoggedIn,
        userAgencies,
    } = useFireBase();
    // parent state
    const { loadOn } = useContext(JobsContext);
    // local state
    const [jobs, setJobs] = useState<MJob[]>([]);
    const [privateJobs, setPrivateJobs] = useState<MJob[]>([]);
    const [hidePrivate, setHidePrivate] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [shouldReload, setShouldReload] = useState(Date.now());
    const [totalJobs, setTotalJobs] = useState(0);
    const [currentJobs, setCurrentJobs] = useState(0);
    const [pins, setPins] = useState<string[]>([]);
    // permission state
    const [creator, setCreator] = useState(false);
    // filter state
    const [mode, setMode] = useState<EFilterMode[]>([EFilterMode.forMe]);
    const [address, setAddress] = useState<MAddress>();
    const [sValue, setSValue] = useState('');
    const [curField, setCurField] = useState<EField>();
    const [curProfession, setCurProfession] = useState<EProfession>();
    const [curEducation, setCurEducation] = useState<string>();
    const [curWorkplace, setCurWorkplace] = useState<MWorkplace>();
    const [curId, setCurId] = useState(0);
    const [dateFilter, setDateFilter] = useState(0);
    /**
     * memoized string filtered local jobs
     * * unused due to locally filtering being suboptimal
     */
    const filteredJobs = useMemo(
        () =>
            sValue
                ? jobs.filter(
                      (j) =>
                          j.employeeEducations.join(',').includes(sValue) ||
                          j.employeeSpecializations.join(',').includes(sValue),
                  )
                : jobs,
        [sValue, jobs],
    );
    /**
     * callback to add or remove a mode (toggle)
     */
    const toggleMode = useCallback(
        (nextMode: EFilterMode) => {
            const prevIndex = mode.indexOf(nextMode);
            if (prevIndex >= 0) {
                setMode((prev) => {
                    prev.splice(prevIndex, 1);
                    return Array.from(prev);
                });
            } else {
                setMode((prev) => {
                    prev.push(nextMode);
                    return Array.from(prev);
                });
            }
        },
        [mode],
    );
    /**
     * callback to remove job pin from pins
     */
    const removePin = useCallback(
        (next: string) => {
            setPins((p) => {
                p.splice(p.indexOf(next), 1);
                put(ECollections.jobPins, userData.documentId, {
                    pins: p,
                });
                return [...p];
            });
        },
        [userData],
    );
    /**
     * callback to add a pin to the pins list if it is smaller than 10
     */
    const addPin = useCallback(
        (next: string) => {
            setPins((prev) => {
                if (prev.length < 10) {
                    const nextPins = Array.from(new Set([...prev, next]));
                    put(ECollections.jobPins, userData.documentId, {
                        pins: nextPins,
                    });
                    return nextPins;
                } else {
                    dialog({
                        title: format(jobMessages.maximumPins),
                        message: format(jobMessages.noMoreThan10Pins),
                        icon: 'error',
                    });
                    return prev;
                }
            });
        },
        [userData],
    );
    /**
     * handle create button
     */
    const handleCreate = useCallback(() => {
        if (!userData.verified) {
            dialog({
                title: applicationMessages.youAreNotVerified,
                message: format(applicationMessages.verifyYourself, {
                    platform: environment,
                }),
                buttons: [
                    {
                        text: actionMessages.ok,
                        onPress: () => {
                            navigate(
                                '/helpcenter/SxEn23PTl0nTNBs98B3g/Kusb54mKu0p5QOO98fWp',
                            );
                        },
                    },
                ],
                icon: 'error',
            });
        } else {
            navigate('/job/new');
        }
    }, [userData, navigate, environment]);
    /**
     * load more callback with offset (offset is the last createdOn value)
     * ! this callback is pretty huge. there needs to be a smooth way of outsourcing it or parts of it
     * TODO: find a better way of writing filtered lists
     */
    const loadMore = useCallback(
        (offset?: MJob) => {
            const params = {
                orderBy: 'createdOn',
                filter: [] as IFilter[],
                inequalities: [] as IInequality[],
                asc: false,
            };
            if (!isSuperUser(userData)) {
                params.filter.push({
                    field: 'status',
                    value: EJobStatus.public,
                });
            }
            if (!isSuperUser(userData)) {
                params.filter.push({
                    field: 'region',
                    value: userData.region,
                });
            }

            if (mode.includes(EFilterMode.forMe)) {
                if (userData.type === EUserType.user) {
                    params.filter.push({
                        field: 'employeeEducations',
                        operator: 'array-contains-any',
                        // add any to search for jobs including any
                        value: [...userData.educations, 'anyEducation'],
                    });
                    params.filter.push({
                        field: 'employeeProfession',
                        value: userData.profession,
                    });
                }
            } else if (curProfession) {
                if (curEducation) {
                    params.filter.push({
                        field: 'employeeEducations',
                        operator: 'array-contains-any',
                        value: [curEducation, 'anyEducation'],
                    });
                }
                params.filter.push({
                    field: 'employeeProfession',
                    value: curProfession,
                });
            } else if (curField) {
                params.filter.push({
                    field: 'employeeField',
                    value: curField,
                });
            } else if (userData.type === EUserType.user) {
                params.filter.push({
                    field: 'employeeField',
                    value: userData.field,
                });
            } else if (userData.fields.length) {
                params.filter.push({
                    field: 'employeeField',
                    operator: 'in',
                    value: userData.fields,
                });
            }
            if (mode.includes(EFilterMode.nearby) && address) {
                params.filter.push({
                    field: 'location',
                    value: address.zipCode.substring(0, 2),
                });
            } else if (mode.includes(EFilterMode.nearby)) {
                // prevent default and replace view with loading indicator
                setLoading(true);
                return;
            }
            if (curWorkplace) {
                params.filter.push({
                    field: 'workplace',
                    value: curWorkplace.documentId,
                });
            }
            if (curId) {
                params.filter.push({ field: 'id', value: curId });
            }
            if (!offset && params.filter.length) {
                getDataIndex(ECollections.jobs, {
                    ...params,
                    getLength: true,
                }).then((res) => {
                    setCurrentJobs(res as number);
                });
            } else {
                setCurrentJobs(totalJobs);
            }
            getDataIndex(ECollections.jobs, {
                ...params,
                startDocumentId: offset ? offset.documentId : undefined,
                limit: 10,
            }).then((result) => {
                const next = (result as MJob[]).map((r) => new MJob(r));
                if (!offset) {
                    setJobs(next);
                    setLoading(false);
                    // TODO: revamp: remove pins that are no longe existing
                    // if (
                    //     mode.length === 1 &&
                    //     mode.includes(EFilterMode.pinned)
                    // ) {
                    //     pins.filter(
                    //         (p) => !next.find((j) => j.documentId === p),
                    //     ).forEach((p) => removePin(p));
                    // }
                } else {
                    setJobs((current) => {
                        return [...current, ...next];
                    });
                    setLoadingMore(false);
                }
            });
        },
        [
            mode,
            address,
            dateFilter,
            userData,
            curWorkplace,
            shouldReload,
            pins,
            curId,
            curField,
            curProfession,
            curEducation,
            totalJobs,
        ],
    );
    /**
     * effect to load private jobs
     */
    useEffect(() => {
        if (!isAgencyUser(userData)) {
            return;
        }
        const params = {
            orderBy: 'createdOn',
            filter: [] as IFilter[],
        };
        params.filter.push({
            field: 'status',
            value: EJobStatus.private,
        });
        params.filter.push({
            field: 'agencies',
            operator: 'array-contains-any',
            value: userAgencies.map((a) => a.documentId),
        });
        getDataIndex(ECollections.jobs, {
            ...params,
        }).then((result) => {
            setPrivateJobs((result as any[]).map((r) => new MJob(r)));
        });
    }, [userAgencies, userData]);
    /**
     * effect to load job pins for user
     */
    useEffect(() => {
        if (userData.documentId) {
            getDataById(ECollections.jobPins, userData.documentId).then(
                (res) => {
                    if (res && res.pins?.length) {
                        setPins(Array.from(new Set(res.pins)));
                    }
                },
            );
        }
    }, [userData]);
    /**
     * effect to load workplaces as employer and elevate him if exists to be able to create jobs
     * and clear filters for admins
     */
    useEffect(() => {
        if (userData.type !== EUserType.user) {
            setMode([]);
        }
        if (userData.type === EUserType.employer) {
            getDataIndex(ECollections.workplaces, {
                filter: [
                    {
                        field: 'users',
                        operator: 'array-contains',
                        value: userData.documentId,
                    },
                ],
                getLength: true,
            }).then((res) => {
                // check if response length
                if (!isNaN(res as number) && (res as number)) {
                    setCreator(true);
                }
            });
        } else if (isSuperUser(userData)) {
            setCreator(true);
        }
    }, [userData]);
    /**
     * effect to load geolocation if nearby filter is selected
     */
    useEffect(() => {
        if (mode.includes(EFilterMode.nearby) && !address) {
            if (Platform.OS !== 'web') {
                Geolocation.requestAuthorization();
            }
            Geolocation.getCurrentPosition((pos) => {
                fromLatLngToAddress(
                    pos.coords.latitude,
                    pos.coords.longitude,
                ).then(setAddress);
            });
        }
    }, [mode]);
    /**
     * load number of public jobs
     */
    useEffect(() => {
        getLength('publicJobs').then((res) => {
            setTotalJobs(res as number);
        });
    }, [userData]);
    /**
     * effect to trigger reload
     */
    useEffect(() => {
        setLoading(true);
        loadMore();
    }, [loadMore]);
    /**
     * effect to redirect employers to job application view
     */
    useEffect(() => {
        if (userData.type === EUserType.employer) {
            navigate('/japplications');
        }
    }, [userData, navigate]);

    /**
     * callback to issue a reload
     */
    useEffect(() => setShouldReload(loadOn), [loadOn]);
    /**
     * render
     */
    return (
        <>
            <View
                style={[
                    isLoggedIn ? style.card : style.embeddedCard,
                    { zIndex: 100 },
                ]}
            >
                <View
                    style={[
                        style.horizontalSplit,
                        style.verticalPadded,
                        { alignItems: 'center' },
                    ]}
                >
                    <CText bold headline>
                        {format(generalMessages.jobs)}
                    </CText>
                    {creator && (
                        <CButton
                            icon={'plus'}
                            title={format(jobMessages.createNew)}
                            onPress={handleCreate}
                        />
                    )}
                    {!isLoggedIn && (
                        <CButton
                            cy={'register'}
                            title={actionMessages.register}
                            onPress={() => navigate('/')}
                            small
                        />
                    )}
                </View>
                {isLoggedIn && userData.type !== EUserType.employer && (
                    <JobsFilterBar
                        mode={mode}
                        clearMode={() => setMode([])}
                        toggleMode={toggleMode}
                        pins={pins}
                        setDateFilter={setDateFilter}
                        dateFilter={0}
                        setCurWorkplace={setCurWorkplace}
                        curWorkplace={curWorkplace}
                        setCurId={setCurId}
                        curId={curId}
                        setCurField={setCurField}
                        curField={curField}
                        setCurProfession={setCurProfession}
                        curProfession={curProfession}
                        setCurEducation={setCurEducation}
                        curEducation={curEducation}
                    />
                )}
            </View>
            {!!privateJobs.length && (
                <>
                    <TouchableView
                        style={[
                            style.card,
                            style.horizontal,
                            style.centeredItems,
                        ]}
                        onPress={() => setHidePrivate(!hidePrivate)}
                    >
                        <CIcon
                            icon={hidePrivate ? 'chevronRight' : 'chevronDown'}
                            tint={theme.textMainColor}
                        />
                        <CText
                            secondaryHeadline
                            message={jobMessages.privateJobs}
                        />
                    </TouchableView>
                    {!hidePrivate &&
                        privateJobs.map((job) => (
                            <JobRow
                                key={job.documentId}
                                job={job}
                                noButtons={!isLoggedIn}
                            />
                        ))}
                </>
            )}
            {loading ? (
                <Spinner />
            ) : (
                <>
                    {isLoggedIn && (
                        <View style={style.horizontalSpaced}>
                            <CText>
                                {format(jobMessages.searchMatchesXofY, {
                                    y: totalJobs,
                                    x: !mode.includes(EFilterMode.all)
                                        ? currentJobs
                                        : totalJobs,
                                })}
                            </CText>
                        </View>
                    )}
                    {filteredJobs.map((job) => {
                        const pinned = pins.includes(job.documentId);
                        return (
                            <JobRow
                                key={job.documentId}
                                job={job}
                                pinned={pinned}
                                onChangePinned={() => {
                                    if (pinned) {
                                        removePin(job.documentId);
                                    } else {
                                        addPin(job.documentId);
                                    }
                                }}
                                noButtons={!isLoggedIn}
                            />
                        );
                    })}
                    {loadingMore ? (
                        <Spinner />
                    ) : (
                        !!jobs.length &&
                        ((!mode.length && jobs.length % 10 === 0) ||
                            jobs.length < currentJobs) && (
                            <View
                                style={[
                                    style.horizontalSpaced,
                                    style.verticalPadded,
                                ]}
                            >
                                <CButton
                                    onPress={() => {
                                        setLoadingMore(true);
                                        loadMore(jobs[jobs.length - 1]);
                                    }}
                                    minor
                                    title={format(actionMessages.loadMore)}
                                />
                            </View>
                        )
                    )}
                    {!filteredJobs.length && (
                        <View style={style.card}>
                            <CImage image={'search'} />
                            <View
                                style={[
                                    style.horizontalSpaced,
                                    style.verticalPadded,
                                ]}
                            >
                                <CText
                                    bold
                                    centered
                                    headline
                                    message={
                                        completePersonal
                                            ? format(
                                                  jobMessages.currentlyNoJobs,
                                              )
                                            : format(jobMessages.noJobsFound)
                                    }
                                />
                            </View>
                        </View>
                    )}
                </>
            )}
        </>
    );
};

export const JobList: FC = () => {
    const style = useStyle();
    const { isLoggedIn } = useFireBase();
    const [loadOn, issueReload] = useState(Date.now());

    if (!isLoggedIn) {
        return (
            <StandaloneWrapper>
                <JobsContext.Provider
                    value={{ reload: () => issueReload(Date.now()), loadOn }}
                >
                    <ScrollProvider>
                        <UnwrappedJobList />
                    </ScrollProvider>
                </JobsContext.Provider>
            </StandaloneWrapper>
        );
    }
    return (
        <JobsContext.Provider
            value={{ reload: () => issueReload(Date.now()), loadOn }}
        >
            <ScrollProvider style={style.paddedScrollableMainView}>
                <UnwrappedJobList />
            </ScrollProvider>
        </JobsContext.Provider>
    );
};
