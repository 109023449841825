import React, { FC, useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import {
    CButton,
    CPicker,
    CText,
    CTextInput,
} from '../../../components/elements';
import { RichTextEditor } from '../../../components/RichText';
import { MUserData } from '../../../models';
import { useDialog } from '../../../utilities/dialog';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { actionMessages, generalMessages } from '../../../utilities/messages';
import { notificationMessages } from '../../../utilities/messages/notification.messages';
import { useStyle } from '../../../utilities/styles';
import { dashboardMessages } from '../Dashboard.messages';
import { useEnvironment } from '../../../utilities/contexts';
import { EEnvironment, EUserType } from '../../../enums';

interface ISendCustomMailProps {
    target?: MUserData;
}

export const SendCustomMail: FC<ISendCustomMailProps> = ({ target }) => {
    const format = useFormat();
    const { callFunction } = useFireBase();
    const { environment } = useEnvironment();
    const style = useStyle();
    const dialog = useDialog();
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [groups, setGroups] = useState<EUserType[]>([]);

    const [tested, setTested] = useState(false);

    useEffect(() => {
        setTested(false);
    }, [title, message]);

    const sendCustomMail = useCallback(
        async (test?: boolean) => {
            if (
                test ||
                (await dialog({
                    title: format(
                        target
                            ? dashboardMessages.confirmMailSendWithTarget
                            : dashboardMessages.confirmMailSend,
                        { user: `${target?.firstName} ${target?.lastName}` },
                    ),
                    message: format(
                        target
                            ? dashboardMessages.confirmMailSendWithTargetText
                            : dashboardMessages.confirmMailSendText,
                        { user: `${target?.firstName} ${target?.lastName}` },
                    ),
                    cancelButton: {
                        text: format(actionMessages.back),
                    },
                    buttons: [
                        {
                            text: format(actionMessages.continue),
                        },
                    ],
                    icon: 'warning',
                }))
            ) {
                callFunction(test ? 'sendCustomMailTest' : 'sendCustomMail', {
                    title,
                    message,
                    button: format(notificationMessages.toApp),
                    url: 'https://app.situsdocs.de/',
                    linkTo: '/',
                    target,
                    groups: groups.length ? groups : undefined,
                    timeployees: environment === EEnvironment.TimePloyees,
                });
                if (test) {
                    setTested(true);
                }
            }
        },
        [title, message, target, environment, groups],
    );

    return (
        <View style={style.card}>
            <CText bold headline>
                {format(dashboardMessages.sendCustomMarketingMail)}
            </CText>
            {!target && (
                <CPicker
                    values={Object.values(EUserType)
                        .filter((v) => v !== EUserType.default)
                        .map((v) => ({
                            label: generalMessages[v],
                            value: v,
                        }))}
                    multiValues={groups}
                    onChange={(g: EUserType) => {
                        if (!g) {
                            return;
                        }
                        if (!groups.includes(g)) setGroups([...groups, g]);
                    }}
                    onChangeMulti={setGroups}
                    keepOpen
                />
            )}
            <CTextInput
                label={format(dashboardMessages.mailSubject)}
                placeholder={format(dashboardMessages.mailSubjectPlaceholder)}
                value={title}
                onChangeText={setTitle}
                autoExtend
            />
            <RichTextEditor text={message} onChange={setMessage} />
            <CButton
                title={
                    tested
                        ? format(actionMessages.send)
                        : format(actionMessages.test)
                }
                onPress={() => sendCustomMail(!tested)}
                minor
            />
        </View>
    );
};
