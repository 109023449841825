import { useState, useEffect, useMemo } from 'react';
import { ICCalendarProps } from './ICCalendarProps';
import { day } from '../../../utilities/functions';

export const useCalendarState = (props: ICCalendarProps) => {
    const { curDate, availabilities, contracts, jobs, negotiations } = props;
    /**
     * stolen code for activeStart date magic
     */
    const [startDate, setStartDate] = useState<Date>();
    const disableActiveStartDate = () => {
        setTimeout(() => setStartDate(undefined));
    };
    /**
     * stolen effect to reset start date if cur date changes
     */
    useEffect(() => {
        if (curDate instanceof Date) {
            setStartDate(curDate);
        }
    }, [curDate]);
    /**
     * memoize days in negotiation
     */
    const negotiationDays = useMemo(() => {
        if (!negotiations) {
            return [];
        }
        return negotiations.reduce((curr, contract) => {
            return Array.from(new Set([...curr, ...[...contract.activeDates]]));
        }, [] as string[]);
    }, [negotiations]);
    /**
     * memoized contract days
     */
    const contractDays = useMemo(() => {
        if (!contracts) {
            return [];
        }
        return contracts.reduce((curr, contract) => {
            return Array.from(new Set([...curr, ...[...contract.activeDates]]));
        }, [] as string[]);
    }, [contracts]);
    /**
     * memoized contract days
     */
    const jobDays = useMemo(() => {
        if (!jobs) {
            return [];
        }
        return jobs
            .filter(
                (j) => !(contracts || []).find((c) => c.jobId === j.documentId),
            )
            .filter(
                (j) =>
                    !(negotiations || []).find((c) => c.jobId === j.documentId),
            )
            .reduce((curr, job) => {
                return Array.from(new Set([...curr, ...[...job.activeDates]]));
            }, [] as string[]);
    }, [jobs]);
    /**
     * memoice unavailable days
     */
    const unAvailableDays = useMemo(() => {
        return availabilities
            .filter((a) => a.negate)
            .reduce((acc, a) => {
                acc.push(...a.activeDates);
                return acc;
            }, [] as string[]);
    }, [availabilities]);
    /**
     * memoize available days
     */
    const availableDays = useMemo(() => {
        return availabilities
            .filter((a) => !a.negate)
            .reduce((acc, a) => {
                acc.push(...a.activeDates);
                return acc;
            }, [] as string[])
            .filter((string) => !unAvailableDays.includes(string));
    }, [availabilities, unAvailableDays]);

    return {
        startDate,
        disableActiveStartDate,
        availableDays,
        unAvailableDays,
        negotiationDays,
        contractDays,
        jobDays,
    };
};
