import { Unsubscribe } from 'firebase/auth';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { ECollections } from '../../../enums';
import { MProfessionalProfile } from '../../../models';
import { useFireBase } from '../../../utilities/firebase';
import { useLocation } from '../../../utilities/routing';
import { useTheme } from '../../../utilities/styles';
import { makeMenuStyles } from '../menu.style';
import { IMenuButtonProps, MenuButton } from './MenuButton';
/**
 * Self conscious notification menu button who watches for new unread messages
 * @param param0
 * @returns
 */
export const ProfProfileMenuButton: FC<IMenuButtonProps> = ({
    closeSidebar,
    detail,
    icon,
    title,
    customFunction,
    targetLocation,
    exact,
}) => {
    // gloabl
    const { theme } = useTheme();
    const currentLocation = useLocation();
    const { watchDataIndex, userData, userAgencies } = useFireBase();
    // local
    const [pp, setPP] = useState<MProfessionalProfile[]>([]);
    const localStyles = useMemo(() => makeMenuStyles(theme), [theme]);
    const [unsubscribe, setUnsubscribe] = useState<Unsubscribe>();
    /**
     * has updates
     */
    const hasUpdates = useMemo(() => !!pp.length, [pp]);
    /**
     * check if button is active and remove hasUpdates in case of acitve
     */
    const active = useMemo(() => {
        const isActive = exact
            ? targetLocation === currentLocation.pathname
            : targetLocation &&
              currentLocation.pathname.includes(targetLocation);
        if (isActive && hasUpdates) {
            setPP([]);
        }
        return isActive;
    }, [exact, currentLocation, hasUpdates]);
    /**
     * callback to handle events from get dataIndex effect
     */
    const handleNewProfile = useCallback(
        (data: any) => {
            if (data && !active) {
                const nextPP = new MProfessionalProfile(data);
                setPP((prev) => [...prev, nextPP]);
                /**
                 * ! notification will be send throught notifications collection
                 */
                // sendNotification(
                //     format(generalMessages.newNotification),
                //     note.message,
                //     note.url || `Https://app.situsdocs.de/notification`,
                //     !active,
                // );
            }
        },
        [active],
    );
    /**
     * effect to hook into professionalProfiles
     */
    useEffect(() => {
        if (!userAgencies.length) {
            return;
        }
        const queryOptions = {
            orderBy: 'filledOn',
            filter: [
                {
                    field: 'agencyId',
                    value: userAgencies[0].documentId,
                },
            ],
            limit: 1,
        };
        watchDataIndex(ECollections.profProfiles, queryOptions, (event) => {
            if (event.type === 'added') {
                handleNewProfile(event.doc.data());
            }
        }).then((us) => setUnsubscribe(() => us));
    }, [userData, handleNewProfile, userAgencies]);
    /**
     * effect to register unsubscribe as cleanup function
     * it gets triggered if react thinks it is time to clean up
     */
    useEffect(() => {
        return unsubscribe;
    }, [unsubscribe]);
    /**
     * render (could have used menu button component but it did cause warnings)
     */
    return (
        <View>
            <MenuButton
                closeSidebar={closeSidebar}
                detail={detail}
                icon={icon}
                title={title}
                customFunction={customFunction}
                targetLocation={targetLocation}
                exact={exact}
            />
            {hasUpdates && (
                <View
                    {...{ dataSet: { cy: 'new-profilefromform-menu-bubble' } }}
                    style={localStyles.notificationBubble}
                />
            )}
        </View>
    );
};
