import AsyncStorage from '@react-native-async-storage/async-storage';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Linking, View } from 'react-native';
import { CButton, CText, InfoBox } from '../../../components';
import { CImage } from '../../../components/elements/CImage';
import { ProgressBar } from '../../../components/Progress';
import {
    EAgencyFiles,
    ECollections,
    EEnvironment,
    ERegion,
    EUserType,
} from '../../../enums';
import { ProfileCompletionContext } from '../../../utilities/contexts/ProfileCompletion';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { generalMessages } from '../../../utilities/messages/general.messages';
import { useStyle, useTheme } from '../../../utilities/styles';
import { dashboardMessages } from '../Dashboard.messages';
import { useDialog } from '../../../utilities/dialog';
import { actionMessages } from '../../../utilities/messages';
import { useEnvironment } from '../../../utilities/contexts';
import { jobMessages } from '../../Job/job.messages';
import { useNavigate } from 'react-router-native';
/**
 * component to display completion of user profile
 * @returns
 */
export const ProfileCompletion: FC<{ changeHidden: () => void }> = ({
    changeHidden,
}) => {
    const { theme } = useTheme();
    const { environment, region } = useEnvironment();
    const style = useStyle();
    const {
        userData,
        user,
        callFunction,
        userAgencies,
        userWorkplaces,
        getDataIndex,
    } = useFireBase();
    const navigate = useNavigate();
    const dialog = useDialog();
    const [mailRunning, setMailRunning] = useState(false);
    const format = useFormat();
    const {
        emailVerified,
        completeBank,
        completeAddress,
        completePersonal,
        licensedAgency,
        addressAgency,
        pictureAgency,
        progress,
        verified,
        mfa,
        idDoc,
    } = useContext(ProfileCompletionContext);

    const [didFire, setDidFire] = useState(false);
    /**
     * effect to harass workplace users that are verified and did not post a job jet
     */
    useEffect(() => {
        if (
            !didFire &&
            userData.type === EUserType.employer &&
            userData.verified
        ) {
            if (userWorkplaces.length) {
                getDataIndex(ECollections.jobs, {
                    getLength: true,
                    limit: 1,
                    filter: [
                        {
                            field: 'workplaceUsers',
                            operator: 'array-contains',
                            value: userData.documentId,
                        },
                    ],
                }).then((res) => {
                    if (!res) {
                        setDidFire(true);
                        dialog({
                            icon: 'info',
                            title: dashboardMessages.createYourFirstJob,
                            message: dashboardMessages.createYourFirstJobText,
                            buttons: [
                                {
                                    text: jobMessages.createNew,
                                    onPress: () => navigate('/job/new'),
                                },
                            ],
                            cancelButton: { text: actionMessages.later },
                        });
                    }
                });
            }
        }
    }, [userData, userWorkplaces, didFire]);
    /**
     * render
     */
    return (
        <View style={style.card} nativeID="profile-completion">
            {progress !== 1 && (
                <View style={style.horizontalSpaced}>
                    <CImage
                        image={'todo'}
                        relativeWidth={
                            environment === EEnvironment.SITUS ? 0.7 : 0.5
                        }
                    />
                </View>
            )}
            <CText
                bold
                headline
                message={
                    progress === 1
                        ? dashboardMessages.accountComplete
                        : userData.type === EUserType.employer
                        ? dashboardMessages.accountCompletion
                        : dashboardMessages.accountCompletionWorkplace
                }
            />
            <View style={style.verticalPadded}>
                <CText
                    style={{
                        color:
                            progress > 0.7
                                ? theme.successColor
                                : theme.accentColor,
                    }}
                    headline
                    bold
                    message={`${Math.round(progress * 100)} %`}
                />
                <ProgressBar percentage={progress} />
            </View>
            <View style={style.verticalPadded}>
                <InfoBox
                    type={
                        mailRunning
                            ? 'working'
                            : emailVerified
                            ? 'success'
                            : 'error'
                    }
                    {...(emailVerified
                        ? {
                              message: generalMessages.eMailVerified,
                          }
                        : {
                              message: generalMessages.eMailNotVerified,
                              text: generalMessages.sendVerification,
                              customFunction: () => {
                                  if (user && !mailRunning) {
                                      setMailRunning(true);
                                      callFunction('emailVerify', {
                                          mail: user.email,
                                      })
                                          .then(() =>
                                              dialog({
                                                  message:
                                                      dashboardMessages.successfulMailSendMessage,
                                                  title: dashboardMessages.successfulMailSend,
                                                  icon: 'success',
                                                  buttons: [
                                                      {
                                                          text: actionMessages.ok,
                                                          onPress: () =>
                                                              setMailRunning(
                                                                  false,
                                                              ),
                                                      },
                                                  ],
                                              }),
                                          )
                                          .catch(() =>
                                              dialog({
                                                  message:
                                                      dashboardMessages.unsuccessfulMailSendMessage,
                                                  title: dashboardMessages.unsuccessfulMailSend,
                                                  icon: 'error',
                                                  buttons: [
                                                      {
                                                          text: actionMessages.ok,
                                                          onPress: () =>
                                                              setMailRunning(
                                                                  false,
                                                              ),
                                                      },
                                                  ],
                                              }),
                                          );
                                  }
                              },
                          })}
                />
            </View>
            <View style={style.verticalPadded}>
                <InfoBox
                    type={verified ? 'success' : 'error'}
                    {...(verified
                        ? {
                              message: format(generalMessages.userVerified, {
                                  platform: environment,
                              }),
                          }
                        : {
                              message: generalMessages.userNotVerified,
                              text: generalMessages.requestVerificationFromAgent,
                              //   path: '/latepoint',
                              customFunction: () =>
                                  Linking.openURL(
                                      environment === EEnvironment.SITUS
                                          ? 'https://www.situsdocs.de/termin-buchen/'
                                          : region === ERegion.de
                                          ? userData.type === EUserType.agency
                                              ? 'https://timeployees.de/termin-buchen-de-anu/'
                                              : 'https://timeployees.de/termin-buchen-de-wp/'
                                          : 'https://timeployees.de/termin-buchen/',
                                  ),
                          })}
                />
            </View>
            {userData.type === EUserType.user && (
                <View style={style.verticalPadded}>
                    <InfoBox
                        type={completeAddress ? 'success' : 'warning'}
                        {...(completeAddress
                            ? {
                                  message:
                                      generalMessages.completedAddressInformation,
                              }
                            : {
                                  message:
                                      generalMessages.missingAddressInformation,
                                  path: '/profile/address',
                              })}
                    />
                </View>
            )}
            {userData.type === EUserType.user && (
                <>
                    <View style={style.verticalPadded}>
                        <InfoBox
                            type={completeBank ? 'success' : 'warning'}
                            {...(completeBank
                                ? {
                                      message:
                                          generalMessages.completedBankingInformation,
                                  }
                                : {
                                      message:
                                          generalMessages.missingBankingInformation,
                                      path: '/profile/bank',
                                  })}
                        />
                    </View>
                    <View style={style.verticalPadded}>
                        <InfoBox
                            type={completePersonal ? 'success' : 'warning'}
                            {...(completePersonal
                                ? {
                                      message:
                                          generalMessages.completedPersonalInformations,
                                  }
                                : {
                                      message:
                                          generalMessages.missingPersonalInformations,
                                      path: '/profile/personal',
                                  })}
                        />
                    </View>
                </>
            )}
            {userData.type === EUserType.agency && (
                <View style={style.verticalPadded}>
                    <InfoBox
                        type={
                            licensedAgency
                                ? 'success'
                                : userAgencies[0].files.find(
                                      (f) => f.type === EAgencyFiles.license,
                                  )
                                ? 'warning'
                                : 'error'
                        }
                        {...(licensedAgency
                            ? {
                                  message: generalMessages.licensedAgency,
                              }
                            : userAgencies[0].files.find(
                                  (f) => f.type === EAgencyFiles.license,
                              )
                            ? {
                                  message: generalMessages.licenseInReview,
                                  path:
                                      '/agency/edit/' +
                                      userAgencies[0].documentId +
                                      '?documents=1',
                              }
                            : {
                                  message: generalMessages.unlicensedAgency,
                                  path:
                                      '/agency/edit/' +
                                      userAgencies[0].documentId +
                                      '?documents=1',
                              })}
                    />
                </View>
            )}
            {userData.type === EUserType.agency && (
                <View style={style.verticalPadded}>
                    <InfoBox
                        type={addressAgency ? 'success' : 'error'}
                        {...(addressAgency
                            ? {
                                  message: generalMessages.addressAgency,
                              }
                            : {
                                  message: generalMessages.noAddressAgency,
                                  path:
                                      '/agency/edit/' +
                                      userAgencies[0].documentId,
                              })}
                    />
                </View>
            )}
            {userData.type === EUserType.agency && (
                <View style={style.verticalPadded}>
                    <InfoBox
                        type={pictureAgency ? 'success' : 'error'}
                        {...(pictureAgency
                            ? {
                                  message: generalMessages.pictureAgency,
                              }
                            : {
                                  message: generalMessages.noPictureAgency,
                                  path:
                                      '/agency/edit/' +
                                      userAgencies[0].documentId,
                              })}
                    />
                </View>
            )}
            <View style={style.verticalPadded}>
                <InfoBox
                    type={mfa ? 'success' : 'warning'}
                    {...(mfa
                        ? {
                              message: generalMessages.addedMFA,
                          }
                        : {
                              message: generalMessages.addMFA,
                              text: generalMessages.addMFAText,
                              path: '/multifactorsetup',
                          })}
                />
            </View>
            {/* <View style={style.verticalPadded}>
                <InfoBox
                    type={idDoc ? 'success' : 'warning'}
                    {...(idDoc
                        ? {
                              message: generalMessages.addedIdDoc,
                          }
                        : {
                              message: generalMessages.addIdDoc,
                              text: generalMessages.addIdDocText,
                              path: '/profile/digicert',
                          })}
                />
            </View> */}
            {progress === 1 && (
                <View style={style.horizontalSpaced}>
                    <CButton
                        title={dashboardMessages.hideProgress}
                        onPress={() => {
                            AsyncStorage.setItem(
                                'completionHidden',
                                `${userData.documentId}`,
                            );
                            changeHidden();
                        }}
                    />
                </View>
            )}
        </View>
    );
};
