import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { View } from 'react-native';
import { Unsubscribe } from 'firebase/auth';
import { ECollections } from '../../../enums';
import { MChatMessage } from '../../../models';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { generalMessages } from '../../../utilities/messages';
import { PushNotificationContext } from '../../../utilities/pushNotifications';
import { useLocation } from '../../../utilities/routing';
import { useTheme } from '../../../utilities/styles';
import { IMenuButtonProps, MenuButton } from './MenuButton';
import { makeMenuStyles } from '../menu.style';
/**
 * Self conscious chat menu button who watches for new unread messages
 * @param param0
 * @returns
 */
export const ChatMenuButton: FC<IMenuButtonProps> = ({
    closeSidebar,
    detail,
    icon,
    title,
    customFunction,
    targetLocation,
    exact,
}) => {
    // global state
    const { theme } = useTheme();
    const format = useFormat();
    const currentLocation = useLocation();
    const { watchDataIndex, userData } = useFireBase();
    const { sendNotification } = useContext(PushNotificationContext);
    // local state
    const localStyles = useMemo(() => makeMenuStyles(theme), [theme]);
    const [unsubscribe, setUnsubscribe] = useState<Unsubscribe>();
    const [hasUpdatesFrom, setHasUpdates] = useState(0);
    const [hasSeenUpdatesFrom, setHasSeenUpdates] = useState(0);
    /**
     * has updates
     */
    const hasUpdates = useMemo(
        () => hasSeenUpdatesFrom < hasUpdatesFrom,
        [hasUpdatesFrom, hasSeenUpdatesFrom],
    );
    /**
     * check if button is active and remove hasUpdates in case of acitve
     */
    const active = useMemo(() => {
        const isActive = exact
            ? targetLocation === currentLocation.pathname
            : targetLocation &&
              currentLocation.pathname.includes(targetLocation);
        if (isActive && hasUpdates) {
            setHasSeenUpdates(hasUpdatesFrom);
        }
        return isActive;
    }, [exact, currentLocation, hasUpdates, hasUpdatesFrom]);
    /**
     * callback to handle events from get dataIndex effect
     */
    const handleNewMessageEvent = useCallback(
        (data: any) => {
            const cm = new MChatMessage(data);
            if (
                cm.from !== userData.documentId &&
                !cm.read.find((r) => r.from === userData.documentId)
            ) {
                setHasUpdates(cm.createdOn);
                if (hasSeenUpdatesFrom < cm.createdOn) {
                    sendNotification(
                        format(generalMessages.newChatMessage),
                        cm.message,
                        `Https://app.situsdocs.de/chat/${cm.chat}`,
                        !active,
                    );
                }
            }
        },
        [active, hasSeenUpdatesFrom],
    );
    /**
     * effect to hook into chat messages
     */
    useEffect(() => {
        const queryOptions = {
            orderBy: 'createdOn',
            filter: [
                {
                    field: 'participants',
                    operator: 'array-contains' as const,
                    value: userData.documentId,
                },
            ],
            limit: 1,
        };
        watchDataIndex(ECollections.chatMessages, queryOptions, (event) => {
            if (event.type === 'added') {
                handleNewMessageEvent(event.doc.data());
            }
        }).then((us) => setUnsubscribe(() => us));
    }, [userData, handleNewMessageEvent]);
    /**
     * effect to register unsubscribe as cleanup function
     * it gets triggered if react thinks it is time to clean up
     */
    useEffect(() => {
        return unsubscribe;
    }, [unsubscribe]);
    /**
     * render (could have used menu button component but it did cause warnings)
     */
    return (
        <View>
            <MenuButton
                closeSidebar={closeSidebar}
                detail={detail}
                icon={icon}
                title={title}
                customFunction={customFunction}
                targetLocation={targetLocation}
                exact={exact}
            />
            {hasUpdates && (
                <View
                    {...{ dataSet: { cy: 'new-chat-menu-bubble' } }}
                    style={localStyles.notificationBubble}
                />
            )}
        </View>
    );
};
