import React, { useState, useEffect, FC } from 'react';
import { FirebaseApp, initializeApp } from 'firebase/app';

import { initializeFirestore } from 'firebase/firestore';

import { FireAppContext } from './FireAppContext';
import { IDefaultProps } from '../../IDefaultProps';
import { Spinner } from '../../../components';
import {
    Auth,
    browserLocalPersistence,
    browserPopupRedirectResolver,
    connectAuthEmulator,
    initializeAuth,
} from 'firebase/auth';
import {
    connectFirestoreEmulator,
    Firestore,
    getFirestore,
} from 'firebase/firestore';
import {
    connectFunctionsEmulator,
    Functions,
    getFunctions,
} from 'firebase/functions';
import {
    connectStorageEmulator,
    FirebaseStorage,
    getStorage,
} from 'firebase/storage';
import { isLocalhost, shouldUseEmulators } from '../../constants';
// firebaseConfig
let firebaseConfig: any;
// set config based on enviroment
if (isLocalhost()) {
    // devConfig;
    firebaseConfig = {
        apiKey: 'AIzaSyCP6HGg1TKxRxDtJB92ams3SIdwLtUwRRM',
        authDomain: 'fbtest-10f56.firebaseapp.com',
        projectId: 'fbtest-10f56',
        storageBucket: 'fbtest-10f56.appspot.com',
        messagingSenderId: '784509622995',
        appId: '1:784509622995:web:4cb2e02b805747447c1226',
    };
} else {
    // prodConfig;
    firebaseConfig = {
        apiKey: 'AIzaSyBi1HCOJDp1iTLAEMRzEao80jCPygkyoZY',
        authDomain: 'situsdocs.firebaseapp.com',
        projectId: 'situsdocs',
        storageBucket: 'situsdocs.appspot.com',
        messagingSenderId: '705435702124',
        appId: '1:705435702124:web:a84cfaad400586d2385949',
        measurementId: 'G-VY5DMGN26L',
    };
}

export const FireAppProvider: FC<IDefaultProps> = ({ children }) => {
    const [app, setApp] = useState<FirebaseApp>();
    const [auth, setAuth] = useState<Auth>();
    const [db, setDB] = useState<Firestore>();
    const [functions, setFunctions] = useState<Functions>();
    const [storage, setStorage] = useState<FirebaseStorage>();
    /**
     * effect to initialize firebaseapp
     */
    useEffect(() => {
        /**
         * skip this effect to avoid crash in dev
         */
        if (app && auth && db && functions && storage) return;
        /**
         * initialize firebase app
         */
        const fbApp = initializeApp(firebaseConfig);
        setApp(fbApp);
        /**
         * fire auth
         */
        const fbAuth = initializeAuth(fbApp, {
            persistence: [browserLocalPersistence],
            popupRedirectResolver: browserPopupRedirectResolver,
        });
        setAuth(fbAuth);
        if (shouldUseEmulators()) {
            connectAuthEmulator(fbAuth, 'http://127.0.0.1:9099');
        }
        /**
         * firestore
         */
        if (shouldUseEmulators()) {
            // NOTE: do NOT put this in production.
            const fbDb = initializeFirestore(fbApp, {
                experimentalForceLongPolling: true,
            });
            connectFirestoreEmulator(fbDb, '127.0.0.1', 9299);
            setDB(fbDb);
        } else {
            const fbDb = getFirestore(fbApp);
            setDB(fbDb);
        }
        /**
         * fire storage
         */
        const fbStorage = getStorage(app);
        setStorage(fbStorage);
        if (shouldUseEmulators()) {
            connectStorageEmulator(fbStorage, '127.0.0.1', 9199);
        }
        /**
         * fire functions
         */
        const fbFunctions = getFunctions(app, 'europe-west1');
        setFunctions(fbFunctions);
        // enable emulator usage in localhost
        if (shouldUseEmulators()) {
            connectFunctionsEmulator(fbFunctions, '127.0.0.1', 5001);
        }
    }, []);
    /**
     * return loading during init
     */
    if (!app || !auth || !db || !functions || !storage) {
        return <Spinner />;
    }
    /**
     * provide context
     */
    return (
        <FireAppContext.Provider
            value={{
                app,
                auth,
                db,
                functions,
                storage,
            }}
        >
            {children}
        </FireAppContext.Provider>
    );
};
