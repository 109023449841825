import { createContext } from 'react';
import { FirebaseApp } from 'firebase/app';
import { Auth } from 'firebase/auth';
import { Firestore } from 'firebase/firestore';
import { Functions } from 'firebase/functions';
import { FirebaseStorage } from 'firebase/storage';

export const FireAppContext = createContext(
    {} as {
        app: FirebaseApp;
        auth: Auth;
        db: Firestore;
        functions: Functions;
        storage: FirebaseStorage;
    },
);
