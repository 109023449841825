import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useNavigate } from 'react-router-native';
import { CButton, CText } from '../../../components';
import { ECollections } from '../../../enums';
import { isSuperUser } from '../../../utilities/auth';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { generalMessages } from '../../../utilities/messages';
import { useStyle } from '../../../utilities/styles';
import { day } from '../../../utilities/functions';
import { useDimensions } from '../../../utilities/hooks/useDimensions';
import { dashboardMessages } from '../Dashboard.messages';
/**
 * display todays sign up counter
 *
 * ! is enforcing custom max width because flexing is inconsistent
 *
 * @returns
 */
export const TodaysSignUps: FC = () => {
    // global state
    const style = useStyle();
    const format = useFormat();
    const navigate = useNavigate();
    const { width } = useDimensions();
    const { userData, getDataIndex } = useFireBase();
    // local state
    const [count, setCount] = useState(0);
    /**
     * max width is screen width - 80 because:
     * padded main view 20 * 2 and card (padding + margin = 20) * 2
     * ! possible point of design missbehavior
     */
    const maxWidth = useMemo(() => width - 80, [width]);
    /**
     * load count
     */
    useEffect(() => {
        if (isSuperUser(userData))
            getDataIndex(ECollections.users, {
                orderBy: 'createdOn',
                inequalities: [
                    {
                        value: Date.now() - day,
                        field: 'createdOn',
                        operator: '>=',
                    },
                ],
                filter: [{ field: 'disabled', value: false }],
                getLength: true,
            }).then((res) => {
                setCount(res as number);
            });
    }, [userData]);
    /**
     * render
     */
    return (
        <View style={style.card}>
            <View
                style={[
                    style.centeredItems,
                    { justifyContent: 'space-between', maxWidth },
                ]}
            >
                <CText bold headline wrap centered>
                    {format(dashboardMessages.todaysSignUps)}
                </CText>
                <CText style={[style.importantValue]} bold>
                    {count}
                </CText>
                <CButton
                    title={format(generalMessages.showMore)}
                    onPress={() => navigate('/user?mode=today')}
                    transparent
                    minor
                />
            </View>
        </View>
    );
};
