import React, { createContext, ReactNode } from 'react';
import { IModal } from './CModalProvider';

export interface IModalParams {
    x?: number | undefined;
    y?: number | undefined;
    id?: string;
    onClose?: (() => void) | undefined;
    transparentBackground?: boolean | undefined;
    lock?: boolean;
}

export const CModalContext = createContext(
    {} as {
        pushModal: (next?: ReactNode, params?: IModalParams) => void;
        unshiftModal: (next?: ReactNode, params?: IModalParams) => void;
        popModal: () => void;
        removeModal: (id: string) => void;
        setHideModals: (next: boolean) => void;
        removeLockModal: () => void;
        modals: IModal[];
        lockModal?: IModal;
    },
);
