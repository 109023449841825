import React, { FC, useCallback, useState } from 'react';
import { useFireBase } from '../../../../../utilities/firebase';
import { ECollections } from '../../../../../enums';
import { MApplication } from '../../../../../models';
import { CButton } from '../../../../../components';

export const TouchApplications: FC = () => {
    const { getDataIndex, put } = useFireBase();

    const [touchingApplications, setTouchingApplications] = useState(false);
    /**
     * callback to touch all userData
     */
    const touchApplications = useCallback(() => {
        setTouchingApplications(true);
        getDataIndex(ECollections.applications).then((users) => {
            Promise.all(
                (users as MApplication[]).map((app) => {
                    const next = new MApplication(app);
                    return put(ECollections.applications, app.documentId, {
                        ...next,
                        touched: Date.now(),
                    });
                }),
            ).then(() => setTouchingApplications(false));
        });
    }, [getDataIndex, put]);

    return (
        <CButton
            onPress={touchApplications}
            title="Touch all applications"
            warning
            disabled={touchingApplications}
        />
    );
};
