import { EField, EProfession } from '../../enums';

export class MShiftGroup {
    public name = '';
    /**
     * hopefully identifying enought
     */
    public id = Date.now().toString(36);

    public field?: EField;
    public profession?: EProfession;
    public educations: string[] = [];
    public specializations: string[] = [];

    public noBreaks = false;
    public workerCount = 1;

    public activeDays: {
        day: number;
        /**
         * 0 - 24
         */
        from: number;
        /**
         * 0 - 24
         */
        to: number;
    }[] = [];

    constructor(params: Partial<MShiftGroup> = {}) {
        Object.assign(this, params);
    }
}

export class MShiftGroupChildCare extends MShiftGroup {
    public kidsUnder3 = 0;
    public kidsOver3 = 0;
    public kidsInSchool = 0;

    public singlePreparationTime = 0;
    public groupPreparationTime = 0;

    public activeDays: {
        day: number;
        /**
         * 0 - 24
         */
        from: number;
        /**
         * 0 - 24
         */
        to: number;
        preTime?: number;
        postTime?: number;
    }[] = [];

    constructor(params: Partial<MShiftGroupChildCare> = {}) {
        super(params);
        Object.assign(this, params);
    }
}
