import { Unsubscribe } from 'firebase/firestore';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { EApplicationStatus, ECollections } from '../../../enums';
import { useFireBase } from '../../../utilities/firebase';
import { useTheme } from '../../../utilities/styles';
import { IMenuButtonProps, MenuButton } from './MenuButton';
import { CText } from '../../elements';
import { makeMenuStyles } from '../menu.style';
/**
 * Self conscious notification menu button who watches for new unread messages
 * @param param0
 * @returns
 */
export const JobApplicationsButton: FC<IMenuButtonProps> = ({
    closeSidebar,
    detail,
    icon,
    title,
    customFunction,
    targetLocation,
    exact,
}) => {
    // gloabl
    const { theme } = useTheme();
    const { watchDataIndex, userWorkplaces, getDataIndex } = useFireBase();
    // local
    const localStyles = useMemo(() => makeMenuStyles(theme), [theme]);
    const [unsubscribe, setUnsubscribe] = useState<Unsubscribe>();
    const [applicationCount, setApplicationCount] = useState(0);
    /**
     * effect to hook into application collection
     */
    useEffect(() => {
        if (!userWorkplaces.length) return;

        const watchers = [];

        for (const wp of userWorkplaces) {
            const queryOptions = {
                filter: [
                    {
                        field: 'jobWorkplace',
                        value: wp.documentId,
                    },
                    { field: 'status', value: EApplicationStatus.open },
                ],
            };
            // getDataIndex(ECollections.applications, {
            //     ...queryOptions,
            //     getLength: true,
            // }).then((l) => {
            //     setApplicationCount(l as number);
            // });
            watchers.push(
                watchDataIndex(
                    ECollections.applications,
                    queryOptions,
                    (event) => {
                        // if (event.type === 'added') {
                        //     setApplicationCount((prev) => prev + 1);
                        // }
                        getDataIndex(ECollections.applications, {
                            ...queryOptions,
                            getLength: true,
                        }).then((v) => setApplicationCount(v as number));
                    },
                ),
            );
        }
        Promise.all(watchers).then((us) =>
            setUnsubscribe(() => () => {
                for (const f of us) {
                    f();
                }
            }),
        );
    }, [userWorkplaces]);
    /**
     * effect to register unsubscribe as cleanup function
     * it gets triggered if react thinks it is time to clean up
     */
    useEffect(() => {
        return unsubscribe;
    }, [unsubscribe]);
    /**
     * render (could have used menu button component but it did cause warnings)
     */
    return (
        <View>
            <MenuButton
                closeSidebar={closeSidebar}
                detail={detail}
                icon={icon}
                title={title}
                customFunction={customFunction}
                targetLocation={targetLocation}
                exact={exact}
            />
            {!!applicationCount &&
                (detail ? (
                    <CText
                        {...{ dataSet: { cy: 'application-menu-count' } }}
                        style={{
                            zIndex: 50,
                            position: 'absolute',
                            color: theme.accentColor,
                            borderRadius: 5,
                            height: 10,
                            top: 16,
                            right: 0,
                        }}
                        message={`${applicationCount}`}
                    />
                ) : (
                    <View
                        {...{ dataSet: { cy: 'new-notification-menu-bubble' } }}
                        style={localStyles.notificationBubble}
                    />
                ))}
        </View>
    );
};
