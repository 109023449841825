import { createContext } from 'react';
import { MContract } from '../../../../models';

export const ContractContext = createContext(
    {} as {
        contract: MContract;
        originalContract: MContract;
        onChange: (next: Partial<MContract>) => void;
        handleClose: () => Promise<void>;
        handleUndo: () => Promise<void>;
    },
);
