import { defineMessages } from 'react-intl';
import { doctorEducationMessages } from './doctor.messages';
import { nursingEducationMessages } from './nurse.messages';
import { socialWorkerEducationMessages } from './socialWorker.messages';
import { teacherEducationMessages } from './teacher.messages';
import { generalFieldEducationMessages } from './general.messages';
/**
 * scope for general education messages
 */
const scope = 'general.education.';
/**
 * general education messages for special behaviors possibly applicable to multiple professions
 */
export const generalEducationMessages = defineMessages({
    anyEducation: {
        id: scope + 'anyEducation',
        default: 'any',
    },
});
/**
 * all education messages joined together to use for translation references
 */
export const educationMessages = {
    ...doctorEducationMessages,
    ...nursingEducationMessages,
    ...teacherEducationMessages,
    ...socialWorkerEducationMessages,
    ...generalFieldEducationMessages,
    ...generalEducationMessages,
};
