import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
    View,
    Platform,
    NativeSyntheticEvent,
    TextInputKeyPressEventData,
} from 'react-native';
import { CButton } from '../../../components/elements';
import { CTextInput } from '../../../components';
import { useFireBase } from '../../../utilities/firebase';
import { EFBSignInTypes } from '../../../utilities/firebase/auth/EFBSignInTypes';
import { useFormat } from '../../../utilities/intl';
import { actionMessages } from '../../../utilities/messages/action.messages';
import { useStyle } from '../../../utilities/styles';
import { startMessages } from '../start.messages';
import { GoogleButton } from './GoogleButton';
import { useDimensions } from '../../../utilities/hooks/useDimensions';
import { useDialog } from '../../../utilities/dialog';
import { generalMessages } from '../../../utilities/messages';
import { CImage } from '../../../components/elements/CImage';
import { useSearchParams } from '../../../utilities/routing';
import base64 from 'base-64';
import { testMail } from '../../../utilities/functions';
import { useEnvironment } from '../../../utilities/contexts';
import { EEnvironment } from '../../../enums';
import { MultiFactorError, RecaptchaVerifier } from 'firebase/auth';

interface ILoginProps {
    forgot: () => void;
}

export const Login: FC<ILoginProps> = ({ forgot }) => {
    const { logIn, multiFactorLogin, disabledAttempt, auth } = useFireBase();
    const { environment } = useEnvironment();
    const style = useStyle();
    const [urlSearchParams] = useSearchParams();
    const { width } = useDimensions();
    const format = useFormat();
    const [recaptcha, setRecaptcha] = useState<RecaptchaVerifier>();
    const [un, setUn] = useState('');
    const [pw, setPw] = useState('');
    const [setActive, setSetActive] = useState<(next: boolean) => void>();
    const dialog = useDialog();
    /**
     * memoized check for webplatform (also returns true on small webpages)
     */
    const web = useMemo(() => Platform.OS === 'web' && width > 800, [width]);
    /**
     * effect to insult disable users
     */
    useEffect(() => {
        if (disabledAttempt) {
            dialog({
                title: format(generalMessages.accountIsDisabled),
                message: format(generalMessages.accountIsDisableText, {
                    domain:
                        environment === EEnvironment.SITUS
                            ? 'situsdocs.de'
                            : 'timeployees.de',
                }),
                icon: 'error',
            });
        }
    }, [disabledAttempt]);
    /**
     * login using current username and password values
     */
    const loginWUnPw = useCallback(async () => {
        try {
            if (Platform.OS === 'web') {
                if (recaptcha) {
                    await recaptcha.verify();
                } else {
                    throw new Error('Recaptcha missing');
                }
            }
            await logIn({
                type: EFBSignInTypes.userAndPassword,
                mail: un,
                password: pw,
            });
        } catch (e) {
            console.log(e);
            const eString = `${e}`;
            if (eString.includes('auth/multi-factor-auth-required')) {
                if (!recaptcha && web) {
                    throw 'this point should not be reachable';
                }
                // The user is a multi-factor user. Second factor challenge is required.
                await multiFactorLogin(e as MultiFactorError, recaptcha);
            } else if (eString.includes('auth/user-not-found')) {
                dialog({
                    title: format(startMessages.wrongUsername),
                    message: format(startMessages.wrongHint),
                    icon: 'error',
                });
            } else if (eString.includes('no credentials supplied')) {
                dialog({
                    title: format(startMessages.noCredentials),
                    message: format(startMessages.wrongHint),
                    icon: 'error',
                });
            } else {
                dialog({
                    title: format(startMessages.wrongPassword),
                    message: format(startMessages.wrongHint),
                    icon: 'error',
                });
            }
        }
    }, [un, pw, format, recaptcha, web]);
    /**
     * login from enter key event inside text inputs
     */
    const loginWUnPwFromTextfieldEnter = useCallback(
        async (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
            if (event.nativeEvent.key === 'Enter') {
                event.preventDefault();
                if (setActive) {
                    setActive(true);
                }
                await loginWUnPw();
                if (setActive) {
                    setActive(false);
                }
            }
        },
        [setActive, loginWUnPw, recaptcha],
    );
    /**
     * effect to read mail from b64 encrypted m param
     */
    useEffect(() => {
        const prefilledMail = urlSearchParams.get('m');
        if (prefilledMail) {
            const decodedMail = base64.decode(prefilledMail);
            if (testMail(decodedMail)) {
                setUn(decodedMail);
            }
        }
    }, [urlSearchParams]);
    /**
     * effect to set rechaptcha
     */
    useEffect(() => {
        if (Platform.OS === 'web') {
            const rccv = new RecaptchaVerifier(
                auth,
                'login-rechaptcha-element',
                {
                    size: 'invisible',
                },
            );
            setRecaptcha(rccv);
        }
    }, [auth]);
    /**
     * render
     */
    return (
        <>
            <View style={[style.verticalPadded, style.horizontalSpaced]}>
                <CImage
                    image={web ? 'signOnDesktop' : 'signOnMobile'}
                    maxWidth={web ? 500 : 200}
                />
            </View>
            <CTextInput
                cy={'email'}
                value={un}
                placeholder={startMessages.mail}
                onChangeText={setUn}
                label={startMessages.mail}
                autoExtend
                onKeyPress={loginWUnPwFromTextfieldEnter}
                type={'email-address'}
            />
            <CTextInput
                cy={'password'}
                value={pw}
                placeholder={startMessages.passwordPlaceholder}
                onChangeText={setPw}
                secure
                autoExtend
                label={startMessages.password}
                onKeyPress={loginWUnPwFromTextfieldEnter}
            />
            <View style={[style.verticalPadded, { marginHorizontal: 'auto' }]}>
                <View nativeID="login-rechaptcha-element" />
                <CButton
                    nativeId={'login-button'}
                    cy={'login-action'}
                    title={actionMessages.login}
                    onPress={loginWUnPw}
                    disableOnClick
                    registerActiveHook={setSetActive}
                />
                <View style={style.paddedThinSeparator} />
                <GoogleButton />
                <CButton
                    title={startMessages.forgotPassword}
                    onPress={forgot}
                    transparent
                    minor
                />
            </View>
        </>
    );
};
