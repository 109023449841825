export class MChatMessage {
    public documentId = '';

    public message = '';

    public editedOn = Date.now();
    public createdOn = Date.now();

    public read: { from: string; on: number }[] = [];

    public picture = '';
    public from = '';
    public chat = '';
    public participants: string[] = [];

    public fromPicture = '';

    constructor(params: Partial<MChatMessage> = {}) {
        Object.assign(this, params);
    }
}
