import { EUserType } from '../enums';
import { MUserData } from '../models';
import { AGENCYUSERS, LOWUSERS, MANAGINGUSERS, SUPERUSERS } from './constants';
/**
 * check if user is member of coalition of users that have great to full access to all kind of data
 */
export const isSuperUser = (userData: MUserData) => {
    return SUPERUSERS.includes(userData.type);
};
/**
 * check if user is member of coalition of users that are able to manage job content
 */
export const isAgencyUser = (userData: MUserData) => {
    return AGENCYUSERS.includes(userData.type);
};
/**
 * check if user is member of coalition of users that are able to manage job content
 */
export const isManagingUser = (userData: MUserData) => {
    return MANAGINGUSERS.includes(userData.type);
};
/**
 * check if user is member of coalition of lowest user types. usable to hide data or apply restrictions
 */
export const isPeasant = (userData: MUserData) => {
    return LOWUSERS.includes(userData.type);
};
/**
 * check if user is member of coalition of employer user types. usable to hide data or apply restrictions
 */
export const isEmployer = (userData: MUserData) => {
    return [EUserType.employer].includes(userData.type);
};
