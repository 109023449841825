import React, { FC, useEffect, useMemo, useState } from 'react';
import { MJob, MWorkplace } from '../../../../../../models';
import { View } from 'react-native';
import { CIcon, CText } from '../../../../../../components';
import { jobMessages } from '../../../../job.messages';
import { useFormat } from '../../../../../../utilities/intl';
import { useStyle, useTheme } from '../../../../../../utilities/styles';
import {
    convertNumberToCurrencyString,
    day,
    getCurrencySymbol,
    hour,
} from '../../../../../../utilities/functions';
import { useFireBase } from '../../../../../../utilities/firebase';
import { useEnvironment } from '../../../../../../utilities/contexts';
import { generalMessages } from '../../../../../../utilities/messages';

export const JobRowSubHead: FC<{
    job: MJob;
    showDistance?: boolean;
    showId?: boolean;
    workplace?: MWorkplace;
}> = ({ job, showDistance, showId, workplace }) => {
    const format = useFormat();
    const style = useStyle();
    const { theme } = useTheme();
    const { region } = useEnvironment();
    const { callFunction, user, userData } = useFireBase();

    const timeOpen = useMemo(() => Date.now() - job.createdOn, [job]);
    const timeOpenInDays = useMemo(() => Math.ceil(timeOpen / day), [timeOpen]);
    const timeOpenInHours = useMemo(
        () => Math.ceil(timeOpen / hour),
        [timeOpen],
    );
    /**
     * distance to job workplace location in km
     */
    const [distance, setDistance] = useState(0);
    /**
     * effect to issue distance calculation
     */
    useEffect(() => {
        if (user && userData.address.street && job.documentId && showDistance) {
            callFunction('getJobDistance', { jobId: job.documentId }).then(
                (v) => {
                    if (v) {
                        setDistance(v);
                    }
                },
            );
        }
    }, [job, showDistance, user, userData]);

    return (
        <View style={[style.horizontalSplit, style.horizontalPadded]}>
            <View
                style={[
                    style.verticalPadded,
                    {
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flex: 1,
                    },
                ]}
            >
                {!job.workPlaceId && (
                    <View style={[style.horizontal]}>
                        <CIcon
                            icon="alert"
                            size={16}
                            tint={theme.warningColor}
                        />
                        <CText
                            style={[
                                style.leftPadded,
                                { color: theme.warningColor },
                            ]}
                            message={format(jobMessages.unverified)}
                        />
                    </View>
                )}
                {!!job.location && (
                    <View style={[style.horizontal]}>
                        <CIcon
                            icon="mapMarker_outline"
                            size={16}
                            tint={theme.textSecondaryColor}
                        />
                        <CText style={style.leftPadded}>
                            {`${format(jobMessages.zipCodeArea)}: ${
                                job.useFullAddress && job.fullAddress
                                    ? job.fullAddress.zipCode
                                    : job.location
                            }`}
                        </CText>
                    </View>
                )}
                <View style={[style.horizontal]}>
                    <CIcon
                        icon="calendar_outline"
                        size={16}
                        tint={theme.textSecondaryColor}
                    />
                    <CText
                        style={style.leftPadded}
                        message={
                            job.fromNowUntillUndefined
                                ? format(jobMessages.fromNow)
                                : job.from > Date.now()
                                ? `${new Date(job.from).toLocaleDateString(
                                      'de',
                                  )} - ${
                                      job.to
                                          ? new Date(job.to).toLocaleDateString(
                                                'de',
                                            )
                                          : format(jobMessages.unlimited)
                                  }`
                                : format(jobMessages.onRequest)
                        }
                    />
                </View>
                {!!job.wage.wage ? (
                    <View style={[style.horizontal]}>
                        <CText>
                            {`${getCurrencySymbol(region)} / ${format(
                                generalMessages.hour,
                            )}: ${convertNumberToCurrencyString(
                                job.wage.wage + job.wage.regularWage,
                                region,
                            )}`}
                        </CText>
                    </View>
                ) : (
                    <View style={[style.horizontal]}>
                        <CText message={jobMessages.noJobRate} />
                    </View>
                )}
            </View>
            <View
                style={[
                    style.verticalPadded,
                    {
                        display: 'flex',
                        justifyContent: 'flex-end',
                    },
                ]}
            >
                {/* {onChangePinned && (
                    <CButton
                        style={{ margin: 'auto', marginRight: 0 }}
                        icon={'pin'}
                        title={
                            !pinned
                                ? format(jobMessages.pin)
                                : format(jobMessages.unpin)
                        }
                        minor={!pinned}
                        onPress={onChangePinned}
                        small
                    />
                )} */}
                {!!job.id && showId && (
                    <CText
                        style={{
                            textAlign: 'right',
                            paddingTop: 10,
                        }}
                    >
                        {`${format(jobMessages.id)}: ${job.id}`}
                    </CText>
                )}
                {!!distance && (
                    <CText
                        style={{
                            textAlign: 'right',
                            paddingTop: 10,
                        }}
                    >
                        {distance < 50
                            ? format(jobMessages.jobDistanceLessThan50km)
                            : distance < 100
                            ? format(jobMessages.jobDistanceLessThan100km)
                            : distance < 200
                            ? format(jobMessages.jobDistanceLessThan200km)
                            : distance < 500
                            ? format(jobMessages.jobDistanceLessThan500km)
                            : format(jobMessages.jobDistanceGreaterThan500km)}
                    </CText>
                )}
                <CText style={{ textAlign: 'right' }}>
                    {timeOpen < day
                        ? timeOpenInHours === 1
                            ? format(jobMessages.openForHour)
                            : format(jobMessages.openForHours, {
                                  x: timeOpenInHours,
                              })
                        : timeOpenInDays === 1
                        ? format(jobMessages.openforDay)
                        : format(jobMessages.openForDays, {
                              x: Math.ceil(timeOpen / day),
                          })}
                </CText>
                <CText style={{ textAlign: 'right' }}>
                    {workplace && workplace.name}
                </CText>
            </View>
        </View>
    );
};
