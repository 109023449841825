import 'intl';
import { Platform } from 'react-native';
import 'intl/locale-data/jsonp/en';

if (Platform.OS === 'android') {
    // See https://github.com/expo/expo/issues/6536 for this issue.
    if (typeof (Intl as any).__disableRegExpRestore === 'function') {
        (Intl as any).__disableRegExpRestore();
    }
}
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IntlShape, createIntl, createIntlCache } from 'react-intl';
import AsyncStorage from '@react-native-async-storage/async-storage';

import lang from '../../assets/translations';
import { flattenObject } from '../functions/flattenObject';
import { IDefaultProps } from '../IDefaultProps';
import { ELanguage, ERegion } from '../../enums';
import { useEnvironment } from '../contexts';
import { createContext } from 'react';
import { IntlErrorCode } from '@formatjs/intl';
/**
 * context to serve by this provider
 */
export const LanguageContext = createContext(
    {} as {
        onChangeLanguage: (language: ELanguage) => void;
        language: ELanguage;
        intl: IntlShape;
    },
);
/**
 * provide language context to its childrens
 * enables useFormat hook
 * @param param0
 * @returns
 */
export const CustomIntlProvider: FC<IDefaultProps> = ({ children }) => {
    const [locale, setLocale] = useState<ELanguage>(ELanguage.de);
    const [didLoad, setDidLoad] = useState(false);
    const { region } = useEnvironment();
    /**
     * cache
     */
    const cache = useMemo(() => createIntlCache(), []);
    /**
     * intl to take format from
     */
    const intl = useMemo(() => {
        return createIntl(
            {
                locale,
                messages: flattenObject(lang[locale]),
                onError: (err) => {
                    if (err.code === IntlErrorCode.MISSING_TRANSLATION) {
                        console.warn(err.message);
                    } else {
                        console.error(err.code, err.name, err.message);
                    }
                },
                defaultLocale: 'en',
                fallbackOnEmptyString: true,
            },
            cache,
        );
    }, [locale]);
    /**
     * function to change language and store current language in storage
     */
    const onChangeLanguage = useCallback((language: ELanguage, save = true) => {
        if (save) {
            AsyncStorage.setItem('language', `${language}`, (e) => {
                if (e) {
                    console.error('failed to save language', `${e}`, 'error');
                }
            });
            setDidLoad(true);
        }
        if (language) {
            setLocale(language);
        }
    }, []);
    /**
     * effect to load language from storage on startup
     */
    useEffect(() => {
        AsyncStorage.getItem('language', (e, result) => {
            if (e) {
                throw e;
            } else if (result && Object.keys(lang).includes(result)) {
                onChangeLanguage(`${result}` as ELanguage, false);
                setDidLoad(true);
            }
        });
    }, []);
    /**
     * effect to set language based on locale if not userDefined
     */
    useEffect(() => {
        if (!didLoad) {
            if (region === ERegion.za) {
                setLocale(ELanguage.en);
            } else if (region === ERegion.de) {
                setLocale(ELanguage.de);
            }
        }
    }, [region, didLoad]);
    /**
     * render
     */
    return (
        <LanguageContext.Provider
            value={{ onChangeLanguage, language: locale, intl }}
        >
            {children}
        </LanguageContext.Provider>
    );
};
