import { defineMessages } from 'react-intl';

const scope = 'con.dash.';
const scopeHelp = scope + 'help.';
/**
 * gender names
 * is used for gender picker
 */
export const dashboardMessages = defineMessages({
    hideProgress: {
        id: scope + 'hideProgress',
        defaultMessage: 'Hide Checklist',
    },
    todaysSignUps: {
        id: scope + 'todaysSignUps',
        defaultMessage: 'New users today',
    },
    todaysApplications: {
        id: scope + 'todaysApplications',
        defaultMessage: 'New applications today',
    },
    openApplications: {
        id: scope + 'openApplications',
        defaultMessage: 'Open applications',
    },
    tipsHelp: {
        id: scope + 'tipsHelp',
        defaultMessage:
            'This tips help you increase the chance to get matched with a job',
    },
    addMoreLanguages: {
        id: scopeHelp + 'addMoreLanguages',
        defaultMessage: 'Add all languages you can speak.',
    },
    contactAgents: {
        id: scopeHelp + 'contactAgents',
        defaultMessage:
            'Contact us to receive free consulting and more personalized Job offers.',
    },
    checkHelpCenter: {
        id: scopeHelp + 'checkHelpCenter',
        defaultMessage:
            'Check the Helpcenter to find answers to commonly asked Questions.',
    },
    getTheMobileApp: {
        id: scopeHelp + 'getTheMobileApp',
        defaultMessage:
            'Get our app from the Appstore to stay up to date while on the move',
    },
    addHistory: {
        id: scopeHelp + 'addHistory',
        defaultMessage:
            'Add your professional and educational history to enhance your resume',
    },
    increaseTravel: {
        id: scopeHelp + 'increaseTravel',
        defaultMessage: 'Increase travel availability',
    },
    addPublications: {
        id: scopeHelp + 'addPublications',
        defaultMessage: 'Add your publications to enhance your resume',
    },
    sendCustomMarketingMail: {
        id: scope + 'sendCustomMarketingMail',
        defaultMessage: 'Send Marketing Mail',
    },
    confirmMailSend: {
        id: scope + 'confirmMailSend',
        defaultMessage:
            'Please confirm that you want to send a mail to all users',
    },
    confirmMailSendWithTarget: {
        id: scope + 'confirmMailSendWithTarget',
        defaultMessage: 'Please confirm that you want to send a mail to {user}',
    },
    confirmMailSendText: {
        id: scope + 'confirmMailSendText',
        defaultMessage: 'This message will go out to all users',
    },
    confirmMailSendWithTargetText: {
        id: scope + 'confirmMailSendWithTargetText',
        defaultMessage: 'This message will go out to {user}',
    },
    mailSubject: {
        id: scope + 'mailSubject',
        defaultMessage: 'Subject',
    },
    mailSubjectPlaceholder: {
        id: scope + 'mailSubjectPlaceholder',
        defaultMessage: 'Subject',
    },
    mailText: {
        id: scope + 'mailText',
        defaultMessage: 'Text',
    },
    addToBookmarks: {
        id: scope + 'addToBookmarks',
        defaultMessage: 'Add the {platform} App to your bookmarks',
    },
    bookmarkShortcut: {
        id: scope + 'bookmarkShortcut',
        defaultMessage: 'Press {shortcut} to add this page to your bookmarks',
    },
    accountComplete: {
        id: scope + 'accountComplete',
        defaultMessage: 'Your account data is complete',
    },
    accountCompletion: {
        id: scope + 'accountCompletion',
        defaultMessage:
            'Please complete your account. Afterwards you can apply on your first Job!',
    },
    accountCompletionWorkplace: {
        id: scope + 'accountCompletionWorkplace',
        defaultMessage:
            'Please complete your account. Afterwards you can create Job listings and accept applicants',
    },
    successfulMailSend: {
        id: scope + 'successfulMailSend',
        defaultMessage: 'Mail sent successfully',
    },
    successfulMailSendMessage: {
        id: scope + 'successfulMailSendMessage',
        defaultMessage: 'Please check your inbox and also your spam folder.',
    },
    unsuccessfulMailSend: {
        id: scope + 'unsuccessfulMailSend',
        defaultMessage: 'Mail was not sent',
    },
    unsuccessfulMailSendMessage: {
        id: scope + 'unsuccessfulMailSendMessage',
        defaultMessage:
            'An error occurred. Please wait and try again. If it keeps happening contact an administrator',
    },
    newestJobs: {
        id: scope + 'newestJobs',
        defaultMessage: 'Newest jobs',
    },
    letTalentsCreateProfiles: {
        id: scope + 'letTalentsCreateProfiles',
        defaultMessage:
            'You can send invite links to your talents, so that they can create rich profiles for you to apply with.',
    },
    postOneJobPerTimeAndLocation: {
        id: scope + 'postOneJobPerTimeAndLocation',
        defaultMessage:
            'Create one job per location and time window to allow applicants to apply for more specific contracts.',
    },
    activeJobs: {
        id: scope + 'activeJobs',
        defaultMessage: 'Active jobs',
    },
    activeNegotiations: {
        id: scope + 'activeNegotiations',
        defaultMessage: 'Active negotiations',
    },
    createYourFirstJob: {
        id: scope + 'createYourFirstJob',
        defaultMessage: 'Create your first job',
    },
    createYourFirstJobText: {
        id: scope + 'createYourFirstJobText',
        defaultMessage:
            'You have been verified from one of our agents. You can now create your first job listing and receive applications.',
    },
    yourContracts: {
        id: scope + 'yourContracts',
        defaultMessage: 'Your contracts',
    },
    openContract: {
        id: scope + 'openContract',
        defaultMessage: 'View contract',
    },
    openContractChat: {
        id: scope + 'openContractChat',
        defaultMessage: 'Open chat',
    },
    contractStartsIn: {
        id: scope + 'contractStartsIn',
        defaultMessage: 'Starts in {x} days',
    },
    contractStartsTomorrow: {
        id: scope + 'contractStartsTomorrow',
        defaultMessage: 'Starts tomorrow',
    },
    noContracts: {
        id: scope + 'noContracts',
        defaultMessage: 'No contracts in current selection.',
    },
    endShift: {
        id: scope + 'endShift',
        defaultMessage: 'End shift',
    },
    startShift: {
        id: scope + 'startShift',
        defaultMessage: 'Start shift',
    },
    takeBreak: {
        id: scope + 'takeBreak',
        defaultMessage: 'Take break',
    },
    endBreak: {
        id: scope + 'endBreak',
        defaultMessage: 'End break',
    },
    notOnShift: {
        id: scope + 'notOnShift',
        defaultMessage: 'No shift active',
    },
    shiftActiveSince: {
        id: scope + 'shiftActiveSince',
        defaultMessage: 'Shift active since {x}',
    },
    inShiftUntil: {
        id: scope + 'inShiftUntil',
        defaultMessage: 'Shift will finish at {x}',
    },
    onBreakUntil: {
        id: scope + 'onBreakUntil',
        defaultMessage: 'On break until {x}',
    },
    timeTracking: {
        id: scope + 'timeTracking',
        defaultMessage: 'Time tracking',
    },
    confirmHideTips: {
        id: scope + 'confirmHideTips',
        defaultMessage: 'Do you want to hide this section?',
    },
    confirmHideTipsText: {
        id: scope + 'confirmHideTipsText',
        defaultMessage:
            'Are you shure you are completely set up and ready to use this application? If not you might want to keep this section visible.',
    },
});
