import { createContext } from 'react';

export const ScrollContext = createContext(
    {} as {
        y: number;
        maxScrollY: number;
        setY: (y: number) => void;
        disableScroll: () => void;
        enableScroll: () => void;
    },
);
