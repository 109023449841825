import React, { FC, useCallback, useState } from 'react';
import { useFireBase } from '../../../../../utilities/firebase';
import { ECollections } from '../../../../../enums';
import { MUserData } from '../../../../../models';
import { CButton } from '../../../../../components';

export const TouchAllUsers: FC = () => {
    const { getDataIndex, put } = useFireBase();

    const [touchingUsers, setTouchingUsers] = useState(false);
    /**
     * callback to touch all userData
     */
    const touchUsers = useCallback(() => {
        setTouchingUsers(true);
        getDataIndex(ECollections.users).then((users) => {
            Promise.all(
                (users as MUserData[]).map((user) => {
                    const next = new MUserData(user);
                    return put(ECollections.users, user.documentId, {
                        ...next,
                        touched: Date.now(),
                    });
                }),
            ).then(() => setTouchingUsers(false));
        });
    }, [getDataIndex, put]);

    return (
        <CButton
            onPress={touchUsers}
            title="Touch all users"
            warning
            disabled={touchingUsers}
        />
    );
};
