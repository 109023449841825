import { defineMessages } from 'react-intl';

const scope = 'ride.dashboard.employer.';

export const dashboardEmployerRideMessages = defineMessages({
    activeContracts: {
        id: scope + 'activeContracts',
        defaultMessage:
            'Here you can see your signed contracts that are currently in use.',
    },
    soonUpcomingContracts: {
        id: scope + 'soonUpcomingContracts',
        defaultMessage:
            'This shows you all contracts that have been successfully signed and will begin within the next 7 days.',
    },
    upcomingContracts: {
        id: scope + 'upcomingContracts',
        defaultMessage:
            'Here you can see all contracts that have been successfully signed extending beyond 7 days.',
    },
    toFinishContracts: {
        id: scope + 'toFinishContracts',
        defaultMessage:
            "Here you can see past contracts for which tasks such as 'uploading time sheets' still need to be completed.",
    },
    yourPostedJobs: {
        id: scope + 'yourPostedJobs',
        defaultMessage: 'Here you can see your posted jobs.',
    },
    yourApplications: {
        id: scope + 'yourApplications',
        defaultMessage:
            'Here you can see if you have received new applicants for a job and have not yet accepted or rejected them.',
    },
    yourNegotiations: {
        id: scope + 'yourNegotiations',
        defaultMessage:
            'Here you can see the number of your active negotiations with agencies for placements.',
    },
    createJobButton: {
        id: scope + 'createJobButton',
        defaultMessage:
            'Here you can quickly and easily create and post a job.',
    },
});
