import { defineMessages } from 'react-intl';

const scope = 'pdfViewer.';

export const pdfViewerMessages = defineMessages({
    signaturePosition: {
        id: scope + 'signaturePosition',
        defaultMessage: 'Signature position',
    },
});
