import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { ProfileContext } from '../../../Profile/Profile.context';
import { useCollectionIndex } from '../../../../utilities/firebase/store/useIndex';
import { ECollections, ELeaveType } from '../../../../enums';
import { MContract, MFile, MShift, MTimesheet } from '../../../../models';
import { useFireBase } from '../../../../utilities/firebase';
import { CPicker, CText } from '../../../../components';
import { hour } from '../../../../utilities/functions';
import { useStyle } from '../../../../utilities/styles';
import { wageMessages } from '../../../../components/Wage/wage.messages';
import { profileMessages } from '../../../Profile/Profile.messages';
import { OutOfContractTimesheetRow } from './OutOfContractTimesheetRow';
import { profProfileMessages } from '../../ProfProfile.messages';
import { IParamOptions } from '../../../../utilities/firebase/store';
import { calendarMessages } from '../../../Calendar/Calendar.messages';
import { monthMessages } from '../../../../utilities/messages';
import { timesheetMessages } from '../../../Timesheet/timesheet.messages';

export const ProfessionalTimesheetSection: FC = () => {
    // global state
    const { curData } = useContext(ProfileContext);
    const style = useStyle();
    const { userAgencies, getDataIndex, getDataById } = useFireBase();
    // local state
    const [timesheets, setTimesheets] = useState<MTimesheet[]>([]);
    const [fileRefs, setFileRefs] = useState<MFile[]>([]);
    const [shifts, setShifts] = useState<MShift[]>([]);
    // filter state
    const [year, setYear] = useState(`${new Date().getFullYear()}`);
    const [month, setMonth] = useState<string | undefined>();
    /**
     * memoized params for the contract collection index
     */
    const contractParams = useMemo(() => {
        const params: IParamOptions = {
            filter: [
                { field: 'profileId', value: curData.documentId },
                {
                    field: 'agencyId',
                    operator: 'in' as const,
                    value: userAgencies.map((a) => a.documentId),
                },
            ],
        };

        if (month) {
            params.filter?.push({ field: month, value: true });
        }
        if (year) {
            params.filter?.push({ field: `activeYears.y${year}`, value: true });
        }
        return params;
    }, [curData.documentId, month, year]);
    const contractCollectionIndex = useCollectionIndex(
        ECollections.contracts,
        contractParams,
    );
    const contracts = useMemo(
        () => contractCollectionIndex.map((c) => new MContract(c)),
        [contractCollectionIndex],
    );
    /**
     * memoized timesheets joined with their shifts and calculated netSums
     */
    const timesheetsWithShifts = useMemo(
        () =>
            timesheets
                .filter(
                    (v) =>
                        (!year || `${v.year}` === year) &&
                        (!month ||
                            (v.month &&
                                `${
                                    Object.keys(monthMessages).filter(
                                        (m) => !m.includes('Short'),
                                    )[v.month]
                                }` === month)),
                )
                .map((ts) => {
                    const thisFile = fileRefs.find(
                        (f) => f.documentId === ts.fileId,
                    );
                    const thisShifts = shifts.filter(
                        (s) => s.timesheetId === ts.documentId,
                    );
                    const nonSickShifts: MShift[] = [];
                    const sickShifts: MShift[] = [];
                    const vacationShifts: MShift[] = [];
                    const netSum = thisShifts.reduce(
                        (acc, s) => {
                            const shiftLength = s.to - s.from; // -
                            const breakLength = s.breaks.reduce(
                                (acc, b) => acc + (b.to - b.from),
                                0,
                            );
                            const shiftLengthWithoutBreaks =
                                shiftLength - breakLength;

                            if (s.leaveType === ELeaveType.none) {
                                nonSickShifts.push(s);
                                if (!new Date(s.from).getDay()) {
                                    acc.sunday += shiftLengthWithoutBreaks;
                                } else {
                                    acc[s.type] += shiftLengthWithoutBreaks;
                                }
                            } else if (s.leaveType === ELeaveType.vacation) {
                                vacationShifts.push(s);
                                acc.vacation += shiftLengthWithoutBreaks;
                                acc.vacationDays++;
                            } else {
                                sickShifts.push(s);
                                acc.sick += shiftLengthWithoutBreaks;
                                acc.sickDays++;
                            }
                            return acc;
                        },
                        {
                            regular: 0,
                            night: 0,
                            holiday: 0,
                            sunday: 0,
                            sick: 0,
                            sickDays: 0,
                            vacation: 0,
                            vacationDays: 0,
                        },
                    );
                    return {
                        ...ts,
                        shifts: thisShifts,
                        sickShifts,
                        nonSickShifts,
                        netSum,
                        file: thisFile,
                    };
                })
                .sort((a, b) => b.createdOn - a.createdOn),
        [timesheets, shifts],
    );
    /**
     * total net sums
     */
    const netSums = useMemo(
        () =>
            timesheetsWithShifts.reduce(
                (acc, t) => {
                    Object.keys(acc).forEach((k) => {
                        const key = k as keyof typeof acc;
                        acc[key] += t.netSum[key];
                    });

                    return acc;
                },
                {
                    regular: 0,
                    night: 0,
                    holiday: 0,
                    sunday: 0,
                    sick: 0,
                    sickDays: 0,
                    vacation: 0,
                    vacationDays: 0,
                },
            ),
        [timesheetsWithShifts],
    );
    /**
     * effect to load timesheets and their shifts. may take a while....
     */
    useEffect(() => {
        Promise.all(
            contracts.map((c) =>
                getDataIndex(
                    `${ECollections.contracts}/${c.documentId}/${ECollections.timesheets}`,
                ),
            ),
        ).then(async (sheets) => {
            const timesheets = (sheets as MTimesheet[][])
                .map((ta) => ta.map((t) => new MTimesheet(t)))
                .reduce((acc, s) => [...acc, ...s], [] as MTimesheet[]);
            const shifts: MShift[] = [];
            const files: MFile[] = [];
            for (const timesheet of timesheets) {
                const contractPath = `${ECollections.contracts}/${timesheet.contractId}`;
                const shiftResponse = await getDataIndex(
                    `${contractPath}/${ECollections.timesheets}/${timesheet.documentId}/${ECollections.shifts}`,
                );
                shifts.push(
                    ...(shiftResponse as MShift[]).map((s) => new MShift(s)),
                );
                if (timesheet.fileId) {
                    const fileResponse = await getDataById(
                        `${contractPath}/${ECollections.files}`,
                        timesheet.fileId,
                    );
                    files.push(new MFile(fileResponse));
                }
            }
            setFileRefs(files);
            setTimesheets(timesheets);
            setShifts(shifts);
        });
    }, [contracts]);
    /**
     * render
     */
    return (
        <View>
            <CText
                secondaryHeadline
                message={profProfileMessages.timesheetSection}
            />
            <View style={style.horizontal}>
                <CPicker
                    horizontal
                    title={calendarMessages.year}
                    value={year}
                    values={Array.from(
                        new Set([
                            ...Array(10).keys(),
                            new Date().getFullYear() -
                                (new Date().getFullYear() - 2),
                        ]),
                    ).map((y, i) => ({
                        label: `${y + new Date().getFullYear() - 2}`,
                        value: `${y + new Date().getFullYear() - 2}`,
                    }))}
                    onChange={setYear}
                />
                <CPicker
                    title={calendarMessages.month}
                    value={month}
                    values={[
                        ...Object.keys(monthMessages)
                            .filter((m) => !m.includes('Short'))
                            .map((m, i) => ({
                                label: monthMessages[
                                    m as keyof typeof monthMessages
                                ],
                                value: m,
                            })),
                        { label: '-', value: undefined },
                    ]}
                    onChange={setMonth}
                    horizontal
                    allowEmpty
                />
            </View>
            <View style={[style.horizontalSplit, style.verticalHeavyPadded]}>
                {Object.keys(netSums).reduce((acc, k) => {
                    const key = k as keyof typeof netSums;
                    if (['sick', 'vacation'].includes(key)) {
                        return acc;
                    } else if (key.includes('Days')) {
                        acc.push(
                            <View key={key} style={style.centeredItems}>
                                <CText
                                    message={
                                        key === 'sickDays'
                                            ? profProfileMessages.sickDays
                                            : profProfileMessages.vacationDays
                                    }
                                />
                                <CText secondaryHeadline>
                                    {netSums[key]}
                                    {(key === 'vacationDays' &&
                                        !month &&
                                        curData.yearlyAvailableVacationDays &&
                                        `/${curData.yearlyAvailableVacationDays}`) ||
                                        ''}
                                </CText>
                            </View>,
                        );
                    } else {
                        acc.push(
                            <View key={key} style={style.centeredItems}>
                                <CText
                                    message={
                                        key !== 'sick'
                                            ? wageMessages[
                                                  (key +
                                                      'Wage') as keyof typeof wageMessages
                                              ]
                                            : profileMessages.sick
                                    }
                                />
                                <CText secondaryHeadline>
                                    {(netSums[key] / hour).toFixed(2)}
                                </CText>
                            </View>,
                        );
                    }
                    return acc;
                }, [] as React.ReactNode[])}
            </View>
            <CText
                style={style.verticalPadded}
                secondaryHeadline
                message={timesheetMessages.timesheetList}
            />
            {timesheetsWithShifts.map((t) => (
                <OutOfContractTimesheetRow
                    key={t.documentId}
                    sheet={t}
                    shifts={t.shifts}
                    file={t.file}
                    embedded
                />
            ))}
            {!timesheetsWithShifts.length && (
                <CText
                    style={style.verticalHeavyPadded}
                    message={profProfileMessages.noTimesheetsForSelectedPeriod}
                />
            )}
        </View>
    );
};
