import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useNavigate } from 'react-router-native';
import { CButton, CIcon, CLabel, CText, InfoBox } from '../../../components';
import { ExpandableCard } from '../../../components/ExpandableCard';
import { ResumeView } from '../../../components/ResumeView/ResumeView';
import { EApplicationStatus, ECollections, EUserType } from '../../../enums';
import { MApplication } from '../../../models/MApplication';
import { isManagingUser, isSuperUser } from '../../../utilities/auth';
import { getApplicationStatusColor } from '../../../utilities/constants';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import {
    actionMessages,
    educationMessages,
    generalMessages,
} from '../../../utilities/messages';
import { useStyle, useTheme } from '../../../utilities/styles';
import { JobTable } from '../../Job/components/JobTable';
import { jobMessages } from '../../Job/job.messages';
import { applicationMessages } from '../application.messages';
import { ApplicantData } from './ApplicantData';
import { useDialog } from '../../../utilities/dialog';
import {
    MProfessionalInfo,
    MProfessionalProfile,
    MWorkplace,
} from '../../../models';
import { useLock } from '../../../utilities/hooks';

interface IApplicationRow {
    application: MApplication;
    embedded?: boolean;
    noResume?: boolean;
    reload?: () => void;
}

export const ApplicationRow: FC<IApplicationRow> = ({
    application,
    reload,
    embedded,
    noResume,
}) => {
    // global state
    const style = useStyle();
    const format = useFormat();
    const dialog = useDialog();
    const { lock } = useLock();
    const { theme } = useTheme();
    const { userData, callFunction, getDataById } = useFireBase();
    const navigate = useNavigate();
    // local state
    const [authorData, setAuthorData] = useState<MProfessionalInfo>();
    const [talent, setTalent] = useState<MProfessionalProfile>();
    const [workplace, setWorkplace] = useState<MWorkplace>();
    /**
     * educations as string memoized translated
     */
    const educationString = useMemo(
        () =>
            application.job.employeeEducations
                .map((edu) => {
                    const key = edu as keyof typeof educationMessages;
                    return educationMessages[key]
                        ? format(educationMessages[key])
                        : '';
                })
                .join(', '),
        [application],
    );
    /**
     * effect to load author data for agency applications
     */
    useEffect(() => {
        if (!application.profileId) return;
        getDataById(ECollections.publicUsers, application.applicantUid).then(
            (res) => setAuthorData(new MProfessionalInfo(res)),
        );
        getDataById(ECollections.profProfiles, application.profileId).then(
            (res) => setTalent(new MProfessionalProfile(res)),
        );
        getDataById(
            ECollections.publicWorkplaces,
            application.jobWorkplace,
        ).then((res) => setWorkplace(new MWorkplace(res)));
    }, []);
    /**
     * render
     */
    return (
        <ExpandableCard
            embedded={embedded}
            head={
                <View style={[style.flex1, style.horizontalSplit]}>
                    <View style={style.flex1}>
                        <View style={style.horizontalSplit}>
                            <CLabel
                                checked={true}
                                title={applicationMessages[application.status]}
                                color={getApplicationStatusColor(
                                    application.status,
                                )}
                                small
                            />
                        </View>
                        <CText bold headline>
                            {talent && workplace
                                ? talent.lastName + ' - ' + workplace.name
                                : format(applicationMessages.applicationFor, {
                                      job: educationString,
                                  })}
                        </CText>
                        {talent && workplace && (
                            <CText>{educationString}</CText>
                        )}
                    </View>
                    <View>
                        <CText>
                            {`${format(
                                applicationMessages.applyTime,
                            )}: ${new Date(
                                application.createdOn,
                            ).toLocaleDateString('de')}`}
                        </CText>
                        {application.status === EApplicationStatus.open && (
                            <CButton
                                icon={'close'}
                                iconColor={theme.errorColor}
                                title={actionMessages.close}
                                minor
                                small
                                onPress={async () => {
                                    if (
                                        await dialog({
                                            title: applicationMessages.cancelApplication,
                                            message:
                                                applicationMessages.cancelApplicationText,
                                            cancelButton: {
                                                text: actionMessages.cancel,
                                            },
                                            icon: 'warning',
                                        })
                                    ) {
                                        const unlock = lock();
                                        await callFunction(
                                            'changeApplicationStatus',
                                            {
                                                applicationId:
                                                    application.documentId,
                                                nextStatus:
                                                    EApplicationStatus.closed,
                                            },
                                        );
                                        unlock();
                                        reload ? reload() : undefined;
                                    }
                                }}
                            />
                        )}
                    </View>
                </View>
            }
            subHead={
                <View style={style.horizontalSplit}>
                    <View>
                        <View style={style.horizontal}>
                            <CIcon icon="calendar_outline" size={16} />
                            <CText
                                style={style.leftPadded}
                                message={jobMessages.fromTo}
                            />
                            <CText style={style.leftPadded}>
                                {application.job.from > Date.now()
                                    ? `${new Date(
                                          application.job.from,
                                      ).toLocaleDateString('de')} - ${
                                          application.job.to
                                              ? new Date(
                                                    application.job.to,
                                                ).toLocaleDateString('de')
                                              : format(jobMessages.unlimited)
                                      }`
                                    : format(jobMessages.onRequest)}
                            </CText>
                        </View>
                        <View style={[style.horizontal]}>
                            <CIcon icon="mapMarker_outline" size={16} />
                            <CText style={style.leftPadded}>
                                {`${format(jobMessages.zipCodeArea)}: ${
                                    application.job.useFullAddress &&
                                    application.job.fullAddress
                                        ? application.job.fullAddress.zipCode
                                        : application.job.location
                                }`}
                            </CText>
                        </View>
                        {(isSuperUser(userData) ||
                            isManagingUser(userData)) && (
                            <View
                                style={[style.horizontal, style.verticalPadded]}
                            >
                                {application.job.employeeEducations.find((e) =>
                                    application.educations.includes(e),
                                ) ? (
                                    <InfoBox
                                        message={applicationMessages.overlapEdu}
                                        type="success"
                                    />
                                ) : (
                                    <InfoBox
                                        message={
                                            applicationMessages.noOverlapEdu
                                        }
                                        type="error"
                                    />
                                )}
                                <View style={{ width: 5 }} />
                                {!!application.job.employeeSpecializations.find(
                                    (spec) =>
                                        application.specializations.find(
                                            (eSpec) => eSpec === spec,
                                        ),
                                ) ? (
                                    <InfoBox
                                        message={
                                            applicationMessages.overlapSpec
                                        }
                                        type="success"
                                    />
                                ) : (
                                    <InfoBox
                                        message={
                                            applicationMessages.noOverlapSpec
                                        }
                                        type="error"
                                    />
                                )}
                            </View>
                        )}
                    </View>
                    {authorData && (
                        <View style={style.horizontal}>
                            <CText>
                                {`${format(generalMessages.createdBy)} ${
                                    authorData.firstName
                                } ${authorData.lastName}`}
                            </CText>
                        </View>
                    )}
                </View>
            }
            body={
                <>
                    <CText
                        bold
                        headline
                        style={style.verticalPadded}
                        message={jobMessages.jobInfos}
                    />
                    <JobTable job={application.job} />
                    <CText
                        bold
                        headline
                        style={style.verticalPadded}
                        message={applicationMessages.applicantData}
                    />
                    <ApplicantData
                        applicantData={new MProfessionalInfo(application)}
                    />
                    {!noResume && (
                        <ResumeView
                            self={userData.type === EUserType.user}
                            applicationId={application.documentId}
                        />
                    )}
                </>
            }
            customOnClick={
                (application.contractId &&
                    (application.status === EApplicationStatus.negotiating
                        ? () =>
                              navigate(
                                  '/contract/negotiation/' +
                                      application.contractId,
                              )
                        : application.status === EApplicationStatus.accepted
                        ? () => navigate('/contract/' + application.contractId)
                        : undefined)) ||
                undefined
            }
        />
    );
};
