import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Platform, View } from 'react-native';
import { CButton } from '../../../components/elements';
import { CText } from '../../../components';
import { CTextInput } from '../../../components';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { actionMessages } from '../../../utilities/messages/action.messages';
import { useStyle, useTheme } from '../../../utilities/styles';
import { startMessages } from '../start.messages';
import { GoogleButton } from './GoogleButton';
import { useDimensions } from '../../../utilities/hooks/useDimensions';
import { ProgressBar } from '../../../components/Progress';
import { useDialog } from '../../../utilities/dialog';
import { CImage } from '../../../components/elements/CImage';
import { useSearchParams } from '../../../utilities/routing';
import base64 from 'base-64';
import { testMail } from '../../../utilities/functions';
import { RecaptchaVerifier } from 'firebase/auth';

export const Register: FC<{ mail?: string }> = ({ mail: propMail }) => {
    // global state
    const { auth, register } = useFireBase();
    const style = useStyle();
    const { theme } = useTheme();
    const [urlSearchParams] = useSearchParams();
    const dialog = useDialog();
    const format = useFormat();
    const { width } = useDimensions();
    // local state
    const [mail, setMail] = useState(propMail || '');
    const [pw, setPw] = useState('');
    const [pwc, setPwc] = useState('');
    const [recaptcha, setRecaptcha] = useState<RecaptchaVerifier>();
    /**
     * memoized value if mobile platform or web
     */
    const web = useMemo(() => Platform.OS === 'web' && width > 800, [width]);
    /**
     * memoized password strength
     */
    const passwordStrength = useMemo(() => {
        let score = 0;
        if (pw.length > 6) {
            score += pw.length * 2;
        }
        if (/\d/.test(pw)) {
            score += 10;
        }
        if (/[a-z]/.test(pw)) {
            score += 10;
        }
        if (/[A-Z]/.test(pw)) {
            score += 20;
        }
        const specials = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (specials.test(pw)) {
            score += 30;
        }
        return score / 100;
    }, [pw]);
    /**
     * memoized translation of password strength to color
     */
    const strengthColor = useMemo(
        () =>
            passwordStrength < 0.5
                ? theme.errorColor
                : passwordStrength < 0.75
                ? theme.warningColor
                : theme.successColor,

        [passwordStrength],
    );
    /**
     * callback to test if mail matches common format
     */
    const mailValidity = useCallback((mail: string) => {
        if (!testMail(mail)) {
            return format(startMessages.invalidMail);
        }
    }, []);
    /**
     * callback to check if password matches minimum requirements
     */
    const passwordValidity = useCallback(
        (pw: string) => {
            if (pw.length < 6) {
                return format(startMessages.passwordToShort);
            } else if (!/\d/.test(pw)) {
                return format(startMessages.passwordMustContainNumber);
            } else if (!/[a-zA-Z]/.test(pw)) {
                return format(startMessages.passwordMustContainCharacter);
            }
        },
        [pw],
    );
    /**
     * callback to check if passwords match
     */
    const passwordConfirmValidity = useCallback(
        (pw2: string) => {
            if (pw2 !== pw) {
                return format(startMessages.passwordsDoNotMatch);
            }
        },
        [pw],
    );
    /**
     * callback function to register with username and password
     */
    const registerWUnPw = useCallback(async () => {
        const validity =
            mailValidity(mail) ||
            passwordValidity(pw) ||
            passwordConfirmValidity(pwc);
        if (validity) {
            dialog({
                title: validity,
                message: format(startMessages.wrongHint),
                icon: 'error',
            });
        } else {
            try {
                await register({
                    mail,
                    password: pw,
                });
            } catch (e) {
                dialog({
                    title: format(startMessages.registerUserExistsError),
                    message: format(startMessages.wrongHint),
                    icon: 'error',
                });
            }
        }
    }, [mail, pw, pwc]);
    /**
     * effect to read mail from b64 encrypted m param
     */
    useEffect(() => {
        const prefilledMail = urlSearchParams.get('m');
        if (prefilledMail) {
            const decodedMail = base64.decode(prefilledMail);
            if (testMail(decodedMail)) {
                setMail(decodedMail);
            }
        }
    }, [urlSearchParams]);
    /**
     * effect to set rechaptcha
     */
    useEffect(() => {
        if (Platform.OS === 'web') {
            const rccv = new RecaptchaVerifier(
                auth,
                'register-rechaptcha-element',
                {
                    size: 'invisible',
                },
            );
            setRecaptcha(rccv);
        }
    }, [auth]);
    /**
     * render
     */
    return (
        <>
            <View style={[style.verticalPadded, style.horizontalSpaced]}>
                <CImage
                    image={web ? 'signOnDesktop' : 'signOnMobile'}
                    maxWidth={web ? 500 : 200}
                />
            </View>
            <CText
                style={{ marginHorizontal: 'auto' }}
                message={format(startMessages.signUpToGetStarted)}
            />
            <CTextInput
                cy={'email'}
                value={mail}
                onChangeText={setMail}
                placeholder={format(startMessages.mailPlaceholder)}
                label={format(startMessages.mail)}
                autoExtend
                customCheckInvalidity={mailValidity}
                type={'email-address'}
            />
            <CTextInput
                cy={'password'}
                value={pw}
                onChangeText={setPw}
                label={format(startMessages.password)}
                placeholder={format(startMessages.passwordPlaceholder)}
                secure
                autoExtend
                customCheckInvalidity={passwordValidity}
            />
            <CText style={{ color: strengthColor }}>
                {passwordStrength < 0.5
                    ? format(startMessages.weakPassword)
                    : passwordStrength < 0.75
                    ? format(startMessages.okPassword)
                    : format(startMessages.strongPassword)}
            </CText>
            <ProgressBar percentage={passwordStrength} color={strengthColor} />
            <CTextInput
                cy={'password-confirm'}
                value={pwc}
                onChangeText={setPwc}
                label={format(startMessages.confirmPassword)}
                placeholder={format(startMessages.confirmPasswordPlaceholder)}
                secure
                autoExtend
                customCheckInvalidity={passwordConfirmValidity}
            />
            <View style={[style.verticalPadded, { marginHorizontal: 'auto' }]}>
                <View nativeID="register-rechaptcha-element" />
                <CButton
                    nativeId={'register-button'}
                    cy={'register-action'}
                    disabled={
                        !!(
                            mailValidity(mail) ||
                            passwordValidity(pw) ||
                            passwordConfirmValidity(pwc)
                        )
                    }
                    title={format(actionMessages.register)}
                    onPress={async () => {
                        if (Platform.OS === 'web') {
                            if (recaptcha) {
                                await recaptcha.verify();
                            }
                        }
                        await registerWUnPw();
                    }}
                    disableOnClick
                />
                <View style={style.paddedThinSeparator} />
                <GoogleButton />
            </View>
        </>
    );
};
