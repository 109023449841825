import { requestSignatureRideMessages } from '../messages/requestSignature.messages';
import { RideStep } from './Ride';

export const requestSignatureRide: RideStep[] = [
    {
        content: requestSignatureRideMessages.welcome,
        placement: 'center',
        target: 'body',
    },
    {
        content: requestSignatureRideMessages.yourPositions,
        placement: 'right',
        target: '#yourPositions',
    },
    {
        content: requestSignatureRideMessages.partnerPositiosn,
        placement: 'left',
        target: '#partnerPositions',
    },
    {
        content: requestSignatureRideMessages.moreSignatureLocations,
        target: '#morePositions',
    },
    {
        content: requestSignatureRideMessages.saveAndContinue,
        placement: 'top',
        target: '#saveAndContinue',
    },
];
