import React, { FC, Fragment, useCallback, useContext, useEffect } from 'react';
import { IFilePickerProps } from './IFilePickerProps';
import { DropContext } from '../../utilities/contexts/Drop/DropContext';

export const FileDropper: FC<IFilePickerProps> = ({
    onFile,
    disabled,
    preFile,
}) => {
    const { setDropActive, setOnDrop, clearOnDrop } = useContext(DropContext);

    const onDrop = useCallback(
        async (acceptedFiles: any[]) => {
            console.log('yahooo');
            const files = await Promise.all(
                acceptedFiles.map(
                    async (file) =>
                        new Promise<{ name: string; content: Buffer }>(
                            (resolve) => {
                                const reader = new FileReader();

                                reader.onabort = () =>
                                    console.log('file reading was aborted');
                                reader.onerror = () =>
                                    console.log('file reading has failed');
                                reader.onload = () => {
                                    const binaryStr = reader.result;
                                    const name = file.path.replace('./', '');
                                    resolve({
                                        name,
                                        content: binaryStr as Buffer,
                                    });
                                };
                                reader.readAsArrayBuffer(file);
                            },
                        ),
                ),
            );
            for (const f of files) {
                const pfResult = preFile ? await preFile() : true;

                if (preFile && !pfResult) return;

                await onFile(f.name, new Uint8Array(f.content), pfResult);
            }
        },
        [preFile, onFile],
    );
    /**
     * effect to populate values to drop provider to signal on drop is handled
     */
    useEffect(() => {
        setDropActive(true);
        setOnDrop(onDrop);
        return () => {
            clearOnDrop();
            setDropActive(false);
        };
    }, [onDrop, setOnDrop, setDropActive, clearOnDrop]);

    /**
     * render
     */
    return <Fragment />;
};
