import { EApplicationStatus } from '../enums';
import { EApplicationType } from '../enums/EApplicationType';
import { IDayAvailability } from './MAvailability';
import { MRates } from './MRates';
/**
 * class for a request from a employer for an availability
 */
export class MRequest {
    public documentId = '';

    public workplaceId = '';
    public author = '';
    public availabilityId = '';
    public userId?: string;
    public agencyId?: string;
    public profileId?: string;

    public editedOn = Date.now();
    public createdOn = Date.now();

    public from = 0;
    public to = 0;
    public fromNow = false;

    public status: EApplicationStatus = EApplicationStatus.open;
    public type: EApplicationType = EApplicationType.default;
    public contractId?: string;

    public wage: MRates = new MRates();

    public days: IDayAvailability[] = [];

    constructor(params: Partial<MRequest> = {}) {
        Object.assign(this, params);
        /**
         * wage assign
         */
        this.wage = new MRates(params.wage);
    }
}
