import React, { FC, useMemo } from 'react';
import './CCard.css';
import { useStyle } from '../../../utilities/styles';
import { ICCardProps } from './ICCardProps';
import { StyleSheet, View } from 'react-native';
import { reduceStyle } from './functions/reduceStyle';

export const CCard: FC<ICCardProps> = ({
    children,
    style: propStyle,
    hoverEffects,
    onPress,
    nativeId,
    embedded,
    inverted,
    cy,
    onLayout,
    onRef,
    outsideStyle,
}) => {
    const style = useStyle();

    return (
        <View
            onLayout={onLayout}
            ref={(view) => {
                if (!view || !onRef) return;
                onRef(view);
            }}
            style={[outsideStyle]}
        >
            <div
                className={[
                    !embedded ? (inverted ? 'invertedccard' : 'ccard') : '',
                    hoverEffects ? 'ccardHoverable' : '',
                    onPress ? 'ccardclickable' : '',
                ]
                    .filter((v) => !!v)
                    .join(' ')}
                style={
                    StyleSheet.flatten([
                        embedded ? style.embeddedCard : style.card,
                        { backgroundColor: false },
                        propStyle,
                    ]) as any
                }
                onClick={onPress}
                id={nativeId}
                data-cy={cy}
            >
                {children}
            </div>
        </View>
    );
};
