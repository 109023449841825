import React, { FC, useEffect, useState } from 'react';
import { Button, View } from 'react-native';
import { MAgency, MUserData, MWorkplace } from '../../../../models';
import { useNavigate } from 'react-router-native';
import { CButton, CImage, CText } from '../../../../components';
import { ExpandableCard } from '../../../../components/ExpandableCard';
import { ECollections } from '../../../../enums';
import { isAgencyUser, isSuperUser } from '../../../../utilities/auth';
import { useFireBase } from '../../../../utilities/firebase';
import { useFormat } from '../../../../utilities/intl';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { agencyMessages } from '../../agency.messages';
import { ProfilePicture } from '../../../../components/ProfilePicture';

interface IAgencyRowProps {
    agency: MAgency;
    workplaces?: MWorkplace[];
    onRemoveWorkplace?: (workplaceId: string) => void;
    embedded?: boolean;
}

export const AgencyRow: FC<IAgencyRowProps> = ({
    agency,
    workplaces,
    onRemoveWorkplace,
    embedded,
}) => {
    // global state
    const { theme } = useTheme();
    const { getDataById, userData } = useFireBase();
    const style = useStyle();
    const navigate = useNavigate();
    const format = useFormat();
    // local state
    const [owner, setOwner] = useState<MUserData>();

    useEffect(() => {
        if (agency.owner) {
            try {
                getDataById(ECollections.publicUsers, agency.owner).then(
                    (response) => {
                        setOwner(new MUserData({ ...response }));
                    },
                );
            } catch (e) {
                setOwner(new MUserData({ firstName: 'unavailable' }));
            }
        }
    }, []);
    return (
        <ExpandableCard
            cy={'edit-agency'}
            head={
                <View style={[style.horizontalSplit, { width: '100%' }]}>
                    <View style={[style.horizontal, style.centeredItems]}>
                        <ProfilePicture data={agency} small />
                        <CText bold headline>
                            {agency.name}
                        </CText>
                    </View>
                    {isSuperUser(userData) && (
                        <View
                            style={[
                                style.verticalPadded,
                                style.horizontalPadded,
                            ]}
                        >
                            <CImage image={agency.region} fixedHeight={20} />
                        </View>
                    )}
                </View>
            }
            subHead={
                <View style={style.horizontalSplit}>
                    <View>
                        {/* <CText>
                        {format(addressInputMessages.zipCode)}:{' '}
                        {agency.address.zipCode}
                    </CText> */}
                        <CText>
                            {`${format(agencyMessages.agencyId)}: ${
                                agency.documentId
                            }`}
                        </CText>
                        <CText>
                            {owner &&
                                `${format(agencyMessages.owner)}: ${
                                    owner.firstName
                                } ${owner.lastName}`}
                        </CText>
                        {(isAgencyUser(userData) || isSuperUser(userData)) && (
                            <CText
                                style={
                                    !agency.licenseUntil && {
                                        color: theme.errorColor,
                                    }
                                }
                            >
                                {agency.licenseUntil
                                    ? `${format(
                                          agencyMessages.licensedUntil,
                                      )}: ${new Date(
                                          agency.licenseUntil,
                                      ).toLocaleDateString('de')}`
                                    : format(agencyMessages.unlicensed)}
                            </CText>
                        )}
                    </View>
                    {workplaces && (
                        <View style={{ alignItems: 'flex-end' }}>
                            <CText message={agencyMessages.workplaces} />
                            {workplaces.map((wp) => (
                                <View
                                    style={[
                                        style.horizontal,
                                        style.centeredItems,
                                    ]}
                                    key={wp.documentId}
                                >
                                    <CText message={wp.name} />
                                    {onRemoveWorkplace && (
                                        <CButton
                                            icon="close"
                                            transparent
                                            onPress={() =>
                                                onRemoveWorkplace(wp.documentId)
                                            }
                                            minor
                                            iconColor={theme.errorColor}
                                            smaller
                                        />
                                    )}
                                </View>
                            ))}
                        </View>
                    )}
                </View>
            }
            customOnClick={
                (isSuperUser(userData) ||
                    agency.owner === userData.documentId) &&
                (() => navigate('/agency/edit/' + agency.documentId))
            }
            embedded={embedded}
        />
    );
};
