export class MEducation {
    public institution = '';
    public degree = '';
    public field = '';

    public from = 0;
    public to = 0;

    constructor(params: Partial<MEducation> = {}) {
        Object.assign(this, params);
    }
}
