import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import {
    CButton,
    CCard,
    CCheckBox,
    CDatePicker,
} from '../../../../../../../components';
import { useFireBase } from '../../../../../../../utilities/firebase';
import { useStyle } from '../../../../../../../utilities/styles';
import { contractMessages } from '../../../../../contract.messages';
import { ContractContext } from '../../../ContractContext';
import { actionMessages } from '../../../../../../../utilities/messages';
import { View } from 'react-native';
import { jobMessages } from '../../../../../../Job/job.messages';
import { WorkHoursDisplay } from '../../../../../components/WorkHoursDisplay';
import { useDialog } from '../../../../../../../utilities/dialog';
import { day } from '../../../../../../../utilities/functions';
import { MContract } from '../../../../../../../models';

export const InitNegotiation: FC = () => {
    const style = useStyle();
    const { callFunction } = useFireBase();
    const dialog = useDialog();

    const { contract, handleClose, onChange } = useContext(ContractContext);
    const [wageLock, setWageLock] = useState(false);
    const [confirmedWage, setConfirmedWage] = useState(false);

    const initContract = useCallback(async () => {
        await callFunction('initContract', {
            contractId: contract.documentId,
            ...contract.wage,
            from: contract.from,
            to: contract.to,
            usualWage: contract.usualWage,
            wasInitialized: wageLock,
            short: true,
        });
    }, [contract, wageLock]);
    /**
     * handle from change with dialog to adjust to also
     */
    const handleFromChange = useCallback(
        async (next: Date) => {
            const from = next.getTime();
            const defaultToValue =
                contract.to > from ? contract.to : from + day * 3;
            await dialog({
                title: contractMessages.fromChanged,
                message: contractMessages.fromChangedText,
                dateInputs: [
                    {
                        id: 'to',
                        title: jobMessages.to,
                        defaultValue: defaultToValue,
                        lowerLimit: from,
                    },
                ],
                buttons: [
                    {
                        text: actionMessages.ok,
                        onPress: (inputs) => {
                            const nextTo = inputs?.find((i) => i.id === 'to');
                            const change: Partial<MContract> = {
                                from,
                            };
                            if (nextTo) {
                                change.to = nextTo?.value;
                            } else {
                                change.to = defaultToValue;
                            }
                            onChange(change);
                        },
                    },
                ],
            });
        },
        [contract],
    );

    useEffect(() => {
        if (contract.originalParameters.wage.wage) {
            setWageLock(true);
        }
    }, [contract]);
    return (
        <CCard>
            <CCheckBox
                cy={'confirmUsualWage'}
                // disabled={!contract.usualWage}
                checked={confirmedWage}
                onCheckedChanged={setConfirmedWage}
                title={contractMessages.usualWageStatementNoWage}
            />
            <View style={[style.horizontalSplit]}>
                <View style={[style.horizontalSpaced, style.flex1]}>
                    <CDatePicker
                        title={jobMessages.from}
                        value={new Date(contract.from)}
                        onChange={handleFromChange}
                    />
                </View>
                <View style={[style.horizontalSpaced, style.flex1]}>
                    <CDatePicker
                        title={jobMessages.to}
                        value={new Date(contract.to)}
                        onChange={(next) =>
                            onChange({
                                to: next.getTime(),
                            })
                        }
                    />
                </View>
            </View>
            <View style={style.verticalPadded}>
                <WorkHoursDisplay days={contract.days} />
            </View>
            <View style={style.horizontalSplit}>
                <View style={[style.horizontalSpaced, style.flex1]}>
                    <CButton
                        cy={'cancel'}
                        title={actionMessages.cancel}
                        onPress={handleClose}
                        warning
                    />
                </View>
                <View style={[style.horizontalSpaced, style.flex1]}>
                    <CButton
                        cy={'continue'}
                        title={actionMessages.continue}
                        onPress={initContract}
                        disableOnClick
                        disabled={!confirmedWage}
                    />
                </View>
            </View>
        </CCard>
    );
};
