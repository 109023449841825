import React, { FC, useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useNavigate } from '../../utilities/routing';
import { CButton, CTable, CText } from '../../components';
import { useFormat } from '../../utilities/intl';
import { actionMessages, generalMessages } from '../../utilities/messages';
import { useStyle, useTheme } from '../../utilities/styles';
import { referralMessages } from './Referal.messages';
import { useFireBase } from '../../utilities/firebase';
import { isSuperUser } from '../../utilities/auth';
import { MReferral } from '../../models/MReferral';
import { ECollections } from '../../enums';
import { useDialog } from '../../utilities/dialog';
import { CImage } from '../../components/elements/CImage';
import { ScrollProvider } from '../../utilities/contexts/Scroll';
/**
 * referral component
 * @returns
 */
export const Referrals: FC = () => {
    // global state
    const style = useStyle();
    const format = useFormat();
    const { theme } = useTheme();
    const { getDataById, getDataIndex, userData, put } = useFireBase();
    const dialog = useDialog();
    const navigate = useNavigate();
    // local state
    const [code, setCode] = useState('');
    const [referrals, setReferrals] = useState<MReferral[]>([]);
    /**
     * effect to load data
     */
    useEffect(() => {
        if (userData.documentId) {
            getDataById(ECollections.referralCodes, userData.documentId).then(
                (res) => {
                    setCode(res.code);
                },
            );
            const referralsFilter = [];
            if (!isSuperUser(userData)) {
                referralsFilter.push({
                    field: 'referrer',
                    value: userData.documentId,
                });
            }
            getDataIndex(ECollections.referrals, {
                filter: referralsFilter,
            }).then((res) => {
                setReferrals((res as MReferral[]).map((v) => new MReferral(v)));
            });
        }
    }, []);
    /**
     * function to update or create referralCode
     */
    const putCode = useCallback(() => {
        if (userData.documentId) {
            put(ECollections.referralCodes, userData.documentId, {
                code: 'asdf',
            });
        }
    }, [code, userData]);
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={style.headlineCard}>
                <CText bold headline>
                    {format(generalMessages.referrals)}
                </CText>
            </View>
            <View style={style.card}>
                <View style={style.verticalPadded}>
                    <CImage image="referral" />
                </View>
                <View style={{ marginHorizontal: 'auto' }}>
                    <CButton
                        onPress={() => {
                            dialog({
                                title: format(referralMessages.youCant),
                                message: format(
                                    referralMessages.youHaveToCompleteAJob,
                                ),
                                cancelButton: {
                                    text: format(actionMessages.cancel),
                                },
                                buttons: [
                                    {
                                        text: format(
                                            referralMessages.gotToJobs,
                                        ),
                                        onPress: () => {
                                            navigate('/job');
                                        },
                                    },
                                ],
                                icon: 'error',
                            });
                        }}
                        title={format(referralMessages.create)}
                    />
                </View>
                {!!code && (
                    <View style={{ marginHorizontal: 'auto' }}>
                        <CText bold headline>
                            {format(referralMessages.yourLink)}: {code}
                        </CText>
                    </View>
                )}
            </View>
            {(code || isSuperUser(userData)) && (
                <View style={style.card}>
                    <CTable
                        headers={[
                            isSuperUser(userData) && {
                                key: 'referrer',
                                label: format(referralMessages.referrer),
                            },
                            {
                                key: 'referee',
                                label: format(referralMessages.referee),
                            },
                            {
                                key: 'referredOn',
                                label: format(referralMessages.referredOn),
                            },
                            {
                                key: 'referralComplete',
                                label: format(
                                    referralMessages.referralComplete,
                                ),
                            },
                            {
                                key: 'referralPaid',
                                label: format(referralMessages.referralPaid),
                            },
                        ]}
                        rows={referrals.map((v) => {
                            return {
                                values: [
                                    { key: 'referrer', value: v.referrer },
                                    { key: 'referee', value: v.referee },
                                    {
                                        key: 'referredOn',
                                        value: new Date(
                                            v.referredOn,
                                        ).toLocaleDateString('de'),
                                    },
                                    {
                                        key: 'referralComplete',
                                        value: v.referralComplete
                                            ? format(generalMessages.true)
                                            : format(generalMessages.false),
                                        color: v.referralComplete
                                            ? theme.successColor
                                            : theme.errorColor,
                                    },
                                    {
                                        key: 'referralPaid',
                                        value: v.referralPaid
                                            ? format(generalMessages.true)
                                            : format(generalMessages.false),
                                        color: v.referralPaid
                                            ? theme.successColor
                                            : theme.errorColor,
                                    },
                                ],
                            };
                        })}
                    />
                </View>
            )}
        </ScrollProvider>
    );
};
