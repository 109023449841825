import { useContext } from 'react';
import StylesContext from './StyleContext';
/**
 * hook into style context and return the main style sheet
 * @returns main style sheet
 */
export const useStyle = () => {
    const { styles } = useContext(StylesContext);
    return styles;
};
/**
 * hook into style context and return the current theme and values and their respective setters
 * @returns theme related values and setters for those
 */
export const useTheme = () => {
    const { theme, isDark, setIsDark } = useContext(StylesContext);
    return { theme, isDark, setIsDark };
};
