import { useEffect, useMemo, useState } from 'react';
import { useParams } from '../../../../../utilities/routing';
import { useFireBase } from '../../../../../utilities/firebase';
import { MChatMessage } from '../../../../../models/MChatMessage';
import {
    MAgency,
    MContract,
    MSupportRequest,
    MUserData,
} from '../../../../../models';
import { Unsubscribe } from 'firebase/firestore';
import { IChatListRowProps } from './IChatListRowProps';
import { ECollections, EUserType } from '../../../../../enums';
/**
 * chat list row state
 * @param param0 props
 * @returns values for render
 */
export const useChatListRowState = ({
    chat,
    setLastMessageCreatedOn,
}: IChatListRowProps) => {
    // global state
    const { userData, getDataIndex, getDataById } = useFireBase();
    const { id } = useParams<{ id: string }>();
    // local state
    const [lastMessage, setLastMessage] = useState<MChatMessage>();
    const [didOpenThisRow, setDidOpen] = useState(false);
    const [noMessages, setNoMessages] = useState(false);
    const [correspondents, setCorrespondents] = useState<MUserData[]>([]);
    const [unsubscribe, setUnsubscribe] = useState<Unsubscribe>();
    const [loading, setLoading] = useState(true);
    const [supportRequest, setSupportRequest] = useState<MSupportRequest>();
    const [contract, setContract] = useState<MContract>();
    const [agency, setAgency] = useState<MAgency>();
    /**
     * memoized message to display (to cut long messages)
     */
    const messageToDisplay = useMemo(() => {
        const maxLength = 23;
        if (!lastMessage) {
            return '';
        }
        if (lastMessage.message && lastMessage.message.length > maxLength) {
            return lastMessage.message.substring(0, maxLength - 3) + '...';
        }
        return lastMessage.message;
    }, [lastMessage]);
    /**
     * memoized check to see if there is a new message
     */
    const hasNewMessage = useMemo(() => {
        if (id === chat.documentId && !didOpenThisRow) {
            setDidOpen(true);
            return false;
        }
        return !!(
            !didOpenThisRow &&
            lastMessage &&
            lastMessage.from !== userData.documentId &&
            !lastMessage.read.find((r) => r.from === userData.documentId)
        );
    }, [lastMessage, id, didOpenThisRow]);
    /**
     * memoized main correspondent
     */
    const mainCorrespondent = useMemo(() => {
        return correspondents[0] || new MUserData();
    }, [correspondents]);
    /**
     * effect to load participant data from public users
     * effect to load / hook into chat messages from this chat to see if new
     */
    useEffect(() => {
        if (!userData.documentId) {
            return;
        }
        /**
         * get pubUserData for participants
         */
        const otherUserIds = chat.participants.filter(
            (v) => v !== userData.documentId,
        );
        const promises = otherUserIds.map(async (id) => {
            const res = await getDataById(ECollections.publicUsers, id);
            return new MUserData(res);
        });
        Promise.all(promises).then((users) => {
            setCorrespondents(users);
        });
        /**
         * check collection length to verify that there are no messages
         */
        getDataIndex(ECollections.chatMessages, {
            getLength: true,
            filter: [{ field: 'chat', value: chat.documentId }],
        }).then((result) => {
            if (!result) {
                setNoMessages(true);
            }
        });
        /**
         * get last message from chat
         */
        getDataIndex(
            ECollections.chatMessages,
            {
                limit: 1,
                orderBy: 'createdOn',
                filter: [{ field: 'chat', value: chat.documentId }],
            },
            (event) => {
                if (event.type === 'added') {
                    const documentId = event.doc.id;
                    const d = event.doc.data();
                    setLastMessage(
                        new MChatMessage({
                            ...d,
                            documentId,
                        }),
                    );
                    if (id !== chat.documentId) {
                        setDidOpen(false);
                    }
                }
            },
            (us) => {
                setUnsubscribe(() => us);
            },
        ).then(() => {
            setLoading(false);
        });
    }, [chat, userData, id]);
    /**
     * effect to register unsubscribe as cleanup function
     * it gets triggered if react thinks it is time to clean up
     */
    useEffect(() => {
        return unsubscribe;
    }, [unsubscribe]);
    /**
     * trigger callback to deliver createdOnValue to order by
     */
    useEffect(() => {
        if (!loading) {
            if (noMessages) {
                setLastMessageCreatedOn(chat.createdOn);
            }
            if (lastMessage) {
                setLastMessageCreatedOn(lastMessage.createdOn);
            }
        }
    }, [loading, lastMessage, noMessages]);
    /**
     * effect to load support request / contract and clear them
     */
    useEffect(() => {
        if (chat?.contractId) {
            getDataById(ECollections.contracts, chat.contractId).then(
                (result) => setContract(new MContract(result)),
            );
        } else {
            setContract(undefined);
        }
        if (chat?.supportRequestId) {
            getDataById(
                ECollections.supportRequests,
                chat.supportRequestId,
            ).then((result) => setSupportRequest(new MSupportRequest(result)));
        } else {
            setSupportRequest(undefined);
        }
    }, [chat]);
    /**
     * effect to load agency
     */
    useEffect(() => {
        if (
            mainCorrespondent?.type === EUserType.agency &&
            mainCorrespondent.agencyId
        ) {
            getDataById(
                ECollections.publicAgencies,
                mainCorrespondent.agencyId,
            ).then((result) => setAgency(new MAgency(result)));
        } else {
            setAgency(undefined);
        }
    }, [mainCorrespondent]);
    /**
     * return values for render
     */
    return {
        correspondents,
        messageToDisplay,
        hasNewMessage,
        chat,
        lastMessage,
        loading,
        mainCorrespondent,
        supportRequest,
        contract,
        agency,
    };
};
