import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import QRCode from 'react-qr-code';
import {
    Spinner,
    CText,
    Signature,
    CButton,
    InfoBox,
    CIcon,
    CCard,
} from '../../components';
import PDFViewer from '../../components/PDFViewer';
import { MSignaturePosition, MFile } from '../../models';
import { getRegionUrl, MOBILEWIDTH } from '../../utilities/constants';
import { ScrollProvider, useEnvironment } from '../../utilities/contexts';
import { useFireBase } from '../../utilities/firebase';
import { useDimensions, useFileUpload, useLock } from '../../utilities/hooks';
import { useFormat, capitalize } from '../../utilities/intl';
import { actionMessages } from '../../utilities/messages';
import { useStyle, useTheme } from '../../utilities/styles';
import { contractMessages } from '../Contract/contract.messages';
import { ECollections, EUserType } from '../../enums';
import {
    useParams,
    useSearchParams,
    useSecureNavigate,
} from '../../utilities/routing';
import { Unsubscribe } from 'firebase/auth';
import { signMessages } from './sign.messages';
import { useDialog } from '../../utilities/dialog';
import { userMessages } from '../User/user.messages';
import { StandaloneWrapper } from '../../utilities/wrappers';
import { IFilter } from '../../utilities/firebase/store';
import { useFileLock } from './useFileLock';
import Clipboard from '@react-native-clipboard/clipboard';

const UnwrappedSign: FC = () => {
    // global state
    const style = useStyle();
    const { theme } = useTheme();
    const { width } = useDimensions();
    const format = useFormat();
    const { environment, region } = useEnvironment();
    const { callFunction, userData, watchDataIndex, isLoggedIn } =
        useFireBase();
    const fileUpload = useFileUpload();
    const [searchParams] = useSearchParams();
    const params = useParams<{
        envelopeId: string;
        contractId: string;
        type: EUserType;
    }>();
    const { secureNavigate } = useSecureNavigate();
    const dialog = useDialog();
    const { lock } = useLock();
    // local state
    const [didSign, setDidSign] = useState(false);
    const [didRead, setDidRead] = useState(false);
    const [showUnverifiedQR, setShowUnverifiedQR] = useState(false);
    const [envelopeId, setEnvelopeId] = useState('');
    const [contractId, setContractId] = useState('');
    const [loading, setLoading] = useState(true);
    const [type, setType] = useState<EUserType>();
    const [files, setFiles] = useState<MFile[]>([]);
    const [currentFile, setCurrentFile] = useState<MFile>();
    const [unsubscribe, setUnsubscribe] = useState<Unsubscribe>();
    const { lockedByMe, lockRequestDenied, lockRequestDeniedTimerInSeconds } =
        useFileLock(files, contractId, type);
    /**
     * memoized signature positions
     */
    const signaturePositions = useMemo(() => {
        return files.reduce((acc, file) => {
            if (file.signaturePositions.length) {
                acc.push(...file.signaturePositions);
            }
            return acc;
        }, [] as MSignaturePosition[]);
    }, [files]);
    /**
     * memoized positions
     */
    const myPositions = useMemo(
        () =>
            signaturePositions.filter((s) =>
                type ? s.type === type : s.userId === userData.documentId,
            ),
        [signaturePositions],
    );
    /**
     * unverified sign url
     */
    const unverifiedSignUrl = useMemo(() => {
        const baseUrl = getRegionUrl(region, environment);
        const unverifiedType =
            type ||
            (userData.type === EUserType.employer
                ? EUserType.employer
                : EUserType.user);
        return `${baseUrl}/sign/envelope/${contractId}/${envelopeId}/${unverifiedType}`;
    }, [contractId, envelopeId, type]);
    /**
     * memoized sign url for qr code
     */
    const signUrl = useMemo(() => {
        const baseUrl = getRegionUrl(region, environment);
        if (envelopeId && contractId && type) {
            const url = unverifiedSignUrl;
            return url;
        }
        if (envelopeId && contractId) {
            const url = `${baseUrl}/sign?contractId=${contractId}&envelopeId=${envelopeId}`;
            return url;
        } else {
            return '';
        }
    }, [environment, region, envelopeId, contractId, unverifiedSignUrl]);
    /**
     * memoized value if user should sign
     */
    const shouldSign = useMemo(() => {
        return !!myPositions.length;
    }, [myPositions, userData]);
    /**
     * memoized value if user did sign
     */
    const hasSigned = useMemo(() => {
        const missingPositions = myPositions.filter((s) => !s.signed);
        if (missingPositions.length) {
            return false;
        }
        return true;
    }, [userData, myPositions]);
    /**
     * handle signature
     */
    const handleSignature = useCallback(
        async (signature: string) => {
            const unlock = lock();
            await callFunction('applySignatureToFile', {
                envelopeId: currentFile?.envelopeId,
                signature,
                contractId,
                type,
            }).then(() => {
                unlock();
                if (isLoggedIn) {
                    secureNavigate(-1);
                } else {
                    setDidSign(true);
                }
            });
        },
        [contractId, currentFile, type],
    );
    /**
     * callback to handle a new file upload
     */
    const handleFile = useCallback(
        async (newFn: string, file: Uint8Array) => {
            fileUpload(
                `${ECollections.contracts}/${contractId}/${userData.documentId}`,
                newFn,
                file,
            );
        },
        [contractId, userData, fileUpload],
    );
    /**
     * callback to handle exposure of signature
     */
    const exposeSignature = useCallback(async () => {
        let mail = '';
        const ok = await dialog({
            icon: 'question',
            title: signMessages.exposeSignature,
            message: signMessages.exposeSignatureText,
            textInputs: [{ title: userMessages.mail, id: 'mail' }],
            buttons: [
                {
                    text: signMessages.expose,
                    onPress: (inputs) => {
                        mail =
                            inputs?.find((i) => i.id === 'mail')?.value || '';
                    },
                    disabled: (inputs) =>
                        !inputs?.find((i) => i.id === 'mail')?.value,
                },
                { text: signMessages.exposeNoMail },
            ],
            cancelButton: { text: actionMessages.cancel },
            verticalButtons: true,
        });
        if (!ok) return;
        const unlock = lock();
        await callFunction('exposeUnverifiedSignature', {
            contractId,
            envelopeId,
            mail,
            forceExpose: !mail,
        });
        unlock();
        if (!mail) {
            setShowUnverifiedQR(true);
        } else {
            secureNavigate(-1);
        }
    }, [contractId, envelopeId]);
    /**
     * effect to read urlsearchparams
     */
    useEffect(() => {
        setEnvelopeId((prev) => searchParams.get('envelopeId') || prev);
        setContractId((prev) => searchParams.get('contractId') || prev);
        if (params.envelopeId && params.contractId && params.type) {
            setEnvelopeId(params.envelopeId);
            setContractId(params.contractId);
            setType(params.type);
        }
    }, [searchParams]);
    /**
     * effect to create contract / get read access and get metadata
     */
    useEffect(() => {
        if (contractId && envelopeId) {
            const filter: IFilter[] = [
                { field: 'envelopeId', value: envelopeId },
            ];
            if (type) {
                filter.push({ field: 'exposed', value: true });
            }
            watchDataIndex(
                `${ECollections.contracts}/${contractId}/${ECollections.files}`,
                { filter },
                (event) => {
                    setFiles((prev) => {
                        const next = [...prev];
                        const nextFile = new MFile(event.doc.data());
                        const pivot = next.findIndex(
                            (v) => v.documentId === nextFile.documentId,
                        );
                        if (pivot >= 0) {
                            next.splice(pivot, 1, nextFile);
                        } else {
                            next.push(nextFile);
                        }
                        return next;
                    });
                },
            ).then((us) => setUnsubscribe(() => us));
            // could be loading something from now on
            setLoading(false);
        }
    }, [contractId, envelopeId, type]);
    /**
     * current file init on load
     */
    useEffect(() => {
        if (!currentFile && files.length) {
            setCurrentFile(files[0]);
        }
    }, [files, currentFile]);
    /**
     * effect to register unsubscribe as cleanup function
     * it gets triggered if react thinks it is time to clean up
     */
    useEffect(() => {
        return unsubscribe;
    }, [unsubscribe]);
    /**
     * return unverified qr display
     */
    if (showUnverifiedQR) {
        return (
            <ScrollProvider style={style.paddedScrollableMainView}>
                <CCard>
                    <View style={[style.horizontal, style.centeredItems]}>
                        <CButton
                            cy={'back'}
                            onPress={async () => {
                                secureNavigate(-1);
                            }}
                            icon={'chevronLeft'}
                            small
                        />
                    </View>
                </CCard>
                <CCard style={[style.centeredItems]}>
                    <View style={[style.verticalPadded, style.centeredItems]}>
                        <QRCode value={unverifiedSignUrl} size={150} />
                        <CText
                            message={unverifiedSignUrl}
                            style={style.verticalPadded}
                        />
                        <View style={style.verticalPadded}>
                            <CButton
                                small
                                onPress={() =>
                                    Clipboard.setString(unverifiedSignUrl)
                                }
                                title={signMessages.copyUrl}
                            />
                        </View>
                    </View>
                </CCard>
            </ScrollProvider>
        );
    }
    /**
     * while document not present return spinner
     */
    if (loading) {
        return <Spinner />;
    }
    /**
     * did load but there are no files somehow
     */
    if (!loading && (!files.length || didSign)) {
        if (!isLoggedIn && didSign) {
            return (
                <View style={[style.centeredItems]}>
                    <CIcon icon="check" tint={theme.successColor} size={50} />
                    <CText secondaryHeadline message={signMessages.didSign} />
                </View>
            );
        } else if (!isLoggedIn) {
            return (
                <View style={[style.centeredItems]}>
                    <CIcon icon="signature" size={50} />
                    <CText
                        secondaryHeadline
                        message={signMessages.couldNotLoadCouldBeSigned}
                    />
                </View>
            );
        }
        return (
            <View style={[style.card, style.centeredItems]}>
                <CIcon icon="close" tint={theme.errorColor} size={50} />
                <CText secondaryHeadline message={signMessages.couldNotLoad} />
            </View>
        );
    }
    /**
     * render
     */
    return (
        <>
            <CCard>
                {!params.type && (
                    <View style={[style.horizontal, style.centeredItems]}>
                        <CButton
                            cy={'back'}
                            onPress={async () => {
                                secureNavigate(-1);
                            }}
                            icon={'chevronLeft'}
                            small
                        />
                    </View>
                )}
            </CCard>
            {files.length > 1 && (
                <View style={[style.card]}>
                    {files.map((f) => (
                        <CButton
                            key={f.documentId}
                            title={f.name}
                            onPress={() => {
                                setCurrentFile(f);
                            }}
                            minor={currentFile?.documentId !== f.documentId}
                        />
                    ))}
                </View>
            )}
            {currentFile && (
                <CCard>
                    <PDFViewer
                        filename={currentFile.path}
                        onReachedLastPage={() => setDidRead(true)}
                        positions={myPositions.filter(
                            (p) =>
                                p.fileId === currentFile.documentId &&
                                !p.signed,
                        )}
                    />
                </CCard>
            )}
            {shouldSign && !hasSigned ? (
                <>
                    {!lockedByMe && (
                        <CCard>
                            <InfoBox
                                message={
                                    lockRequestDenied > 0
                                        ? format(
                                              signMessages.fileLockedByCorrespondent,
                                              {
                                                  x: lockRequestDeniedTimerInSeconds,
                                              },
                                          )
                                        : signMessages.tryingToLock
                                }
                                type={'working'}
                            />
                        </CCard>
                    )}
                    <View style={isLoggedIn && style.card}>
                        <CText secondaryHeadline>
                            {format(signMessages.multipleWaysToSign)}
                        </CText>
                        <CText>- {format(signMessages.signInBrowser)}</CText>
                        <CText>- {format(signMessages.signInMobile)}</CText>
                        {!type && (
                            <>
                                <CText>
                                    -{' '}
                                    {format(
                                        signMessages.exposeSignatureExplanation,
                                    )}
                                </CText>
                                <View style={style.horizontal}>
                                    <CButton
                                        title={signMessages.expose}
                                        onPress={exposeSignature}
                                        minor
                                        transparent
                                        small
                                    />
                                </View>
                            </>
                        )}
                        {/* <CText>
                            - {format(contractMessages.signInReality)}
                        </CText> */}
                    </View>
                    {/* <View
                        style={[
                            style.card,
                            style.horizontal,
                            style.centeredContent,
                            style.centeredItems,
                        ]}
                    >
                        <CText>
                            {format(contractMessages.uploadSignedDocument)}
                        </CText>
                        <FilePicker onFile={handleFile} />
                    </View> */}
                    <View style={style.horizontal}>
                        <View
                            style={[
                                isLoggedIn ? style.card : style.verticalPadded,
                                { flex: 1 },
                            ]}
                        >
                            <Signature
                                text={capitalize(
                                    format(
                                        contractMessages.applySignatureUnqualified,
                                    ),
                                )}
                                onOK={handleSignature}
                                disabled={!lockedByMe} //!didRead}
                            />
                        </View>
                        {width >= MOBILEWIDTH && (
                            <>
                                <View style={style.horizontalPadded} />
                                <View
                                    style={[
                                        isLoggedIn
                                            ? style.card
                                            : style.verticalPadded,
                                        {
                                            minWidth: 0,
                                            maxWidth: 250,
                                            alignItems: 'center',
                                        },
                                    ]}
                                >
                                    <CText
                                        style={style.verticalPadded}
                                        centered
                                    >
                                        {format(
                                            contractMessages.scanToSignOnMobile,
                                        )}
                                    </CText>
                                    <QRCode value={signUrl} size={150} />
                                </View>
                            </>
                        )}
                    </View>
                </>
            ) : (
                <View style={[style.centeredItems, style.verticalPadded]}>
                    <CText>{format(contractMessages.alreadySigned)}</CText>
                </View>
            )}
        </>
    );
};

export const Sign: FC = () => {
    const { isLoggedIn } = useFireBase();
    const style = useStyle();

    if (isLoggedIn) {
        return (
            <ScrollProvider style={style.paddedScrollableMainView}>
                <UnwrappedSign />
            </ScrollProvider>
        );
    } else {
        return (
            <StandaloneWrapper>
                <UnwrappedSign />
            </StandaloneWrapper>
        );
    }
};
