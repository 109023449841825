import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { DocumentLocationPicker } from '../../components/DocumentLocationPicker/DocumentLocationPicker';
import { useNavigate, useSearchParams } from '../../utilities/routing';
import { CButton, CCard, CText, InfoBox, Spinner } from '../../components';
import { MContractFile, MFile, MSignaturePosition } from '../../models';
import { useStyle, useTheme } from '../../utilities/styles';
import { requestSignatureMessages } from './requestSignature.messages';
import { ScrollProvider } from '../../utilities/contexts';
import { ECollections, EUserType } from '../../enums';
import { useFireBase } from '../../utilities/firebase';
import { isEmployer } from '../../utilities/auth';
import { PositionRow } from './components/PositionRow';
import { useDialog } from '../../utilities/dialog';
import { actionMessages } from '../../utilities/messages';
import { useFormat } from '../../utilities/intl';

export const RequestSignature: FC = () => {
    const style = useStyle();
    const navigate = useNavigate();
    const format = useFormat();
    const { userData, getDataById } = useFireBase();
    const [searchParams] = useSearchParams();
    const { theme } = useTheme();
    const dialog = useDialog();

    const [files, setFiles] = useState<MContractFile[]>([]);
    const [file, setFile] = useState<MFile>();
    const [type, setType] = useState<string>();
    const [prevLocation, setPrevLocation] = useState<string>();

    const [positions, setPositions] = useState<MSignaturePosition[]>([]);

    const userPositions = useMemo(() => {
        const filteredPositions = positions.filter(
            (p) => p.type === EUserType.user,
        );

        return filteredPositions.map((p) => {
            return { position: p, index: positions.indexOf(p) };
        });
    }, [positions]);

    const employerPositions = useMemo(() => {
        const filteredPositions = positions.filter(
            (p) => p.type === EUserType.employer,
        );

        return filteredPositions.map((p) => {
            return { position: p, index: positions.indexOf(p) };
        });
    }, [positions]);

    const hasOnePositionForBoth = useMemo(
        () =>
            userPositions.filter((p) => p.position.x || p.position.y).length &&
            employerPositions.filter((p) => p.position.x || p.position.y)
                .length,
        [employerPositions, userPositions],
    );

    const [selectedIndex, setSelectedIndex] = useState(0);

    const currentPosition = useMemo(() => {
        const next = positions[selectedIndex];
        if (next) {
            return next;
        }
        return undefined;
    }, [positions, selectedIndex]);

    const removePosition = useCallback(
        (index: number) => {
            setPositions((prev) => {
                const next = [...prev];
                next.splice(selectedIndex, 1);
                return next;
            });
            if (selectedIndex === index) {
                setSelectedIndex(0);
            }
        },
        [selectedIndex],
    );
    /**
     * add blank position for selected type
     */
    // const addPosition = useCallback((type: EUserType) => {
    //     setPositions((prev) => {
    //         const next = [
    //             ...prev,
    //             new MSignaturePosition({
    //                 type,
    //             }),
    //         ];
    //         setSelectedIndex(next.length - 1);
    //         return next;
    //     });
    // }, []);
    /**
     * add position from location click
     */
    const addLocationPosition = useCallback(
        async (pos: { x: number; y: number; page: number }) => {
            if (!file) return;
            let target = isEmployer(userData)
                ? EUserType.employer
                : EUserType.user;
            const ok = await dialog({
                icon: 'question',
                title: requestSignatureMessages.addNewPosition,
                message: requestSignatureMessages.addNewPositionMessage,
                buttons: [
                    {
                        text: requestSignatureMessages.forSelf,
                    },
                    {
                        text: requestSignatureMessages.forPartner,
                        onPress: () => {
                            target = isEmployer(userData)
                                ? EUserType.user
                                : EUserType.employer;
                        },
                    },
                ],
                cancelButton: {
                    text: actionMessages.cancel,
                },
            });
            if (!ok) return;
            setPositions((prev) => {
                const next = [
                    ...prev,
                    new MSignaturePosition({
                        type: target,
                        ...pos,
                        fileId: file.documentId,
                        filename: file.path,
                    }),
                ];
                setSelectedIndex(next.length - 1);

                return next;
            });
        },
        [userData, file],
    );

    const handleSubmit = useCallback(() => {
        const pstring = positions
            .map((p) =>
                JSON.stringify({
                    x: p.x,
                    y: p.y,
                    page: p.page,
                    type: p.type,
                    fileId: p.fileId,
                }),
            )
            .join(',');
        navigate(
            `${prevLocation}?&type=${type}&posdata=[${pstring}]&fid=${files
                .map((f) => f.documentId)
                .join(',')}`,
            { replace: true },
        );
    }, [positions, type, files, prevLocation]);

    const generatePositionTitle = useCallback(
        (p: MSignaturePosition) => {
            const index = positions.indexOf(p) + 1;
            const self = isEmployer(userData)
                ? p.type === EUserType.employer
                : p.type === EUserType.user;
            return format(
                self
                    ? requestSignatureMessages.yourPositionX
                    : requestSignatureMessages.partnerPositionX,
                {
                    x: index,
                },
            );
        },
        [positions],
    );

    useEffect(() => {
        const nextCID = searchParams.get('contractId');
        const fid = searchParams.get('fid');
        const type = searchParams.get('type');
        const prev = searchParams.get('prev');
        setPrevLocation(prev || undefined);
        setType(type || undefined);
        if (fid && nextCID) {
            Promise.all(
                fid
                    .split(',')
                    .map(
                        async (id) =>
                            await getDataById(
                                `${ECollections.contracts}/${nextCID}/${ECollections.files}`,
                                id,
                            ),
                    ),
            ).then((results) => {
                setFiles(results.map((r) => new MContractFile(r)));
                setFile(new MContractFile(results[0]));
            });
        }
    }, [searchParams]);

    if (!file) {
        return <Spinner />;
    }

    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={style.card}>
                <View style={[style.horizontal, style.centeredItems]}>
                    <CButton
                        cy={'back'}
                        onPress={async () => {
                            navigate(-1);
                        }}
                        icon={'chevronLeft'}
                        small
                    />
                    <CText
                        style={style.horizontalPadded}
                        headline
                        message={
                            requestSignatureMessages.selectPositionsOnDocument
                        }
                    />
                </View>
            </View>
            {!hasOnePositionForBoth && (
                <View style={style.card}>
                    <InfoBox
                        message={
                            requestSignatureMessages.selectPositionsOnDocumentHint
                        }
                        type={'warning'}
                    />
                </View>
            )}
            <View style={[style.verticalPadded, style.horizontalSpaced]}>
                <View style={{ width: 180 }} nativeID="yourPositions">
                    <CCard>
                        <CText
                            message={requestSignatureMessages.yourPositions}
                            secondaryHeadline
                        />
                    </CCard>
                    {(isEmployer(userData)
                        ? employerPositions
                        : userPositions
                    ).map(({ position, index }, i) => (
                        <PositionRow
                            key={index}
                            index={i + 1}
                            active={index === selectedIndex}
                            position={position}
                            onPress={() => setSelectedIndex(index)}
                            onRemove={() => removePosition(index)}
                            generatePositionTitle={generatePositionTitle}
                        />
                    ))}
                    {/* <CButton
                        onPress={() =>
                            addPosition(
                                isEmployer(userData)
                                    ? EUserType.employer
                                    : EUserType.user,
                            )
                        }
                        title={requestSignatureMessages.addSignaturePosition}
                        nativeId="morePositions"
                    /> */}
                </View>
                <View
                    style={[style.card, { flex: 1 }]}
                    nativeID="morePositions"
                >
                    {files.length > 1 &&
                        files.map((f) => (
                            <CButton
                                key={f.documentId}
                                title={f.name}
                                onPress={() => {
                                    setFile(f);
                                }}
                                minor={file?.documentId !== f.documentId}
                            />
                        ))}
                    <View
                        style={[
                            style.verticalPadded,
                            {
                                marginHorizontal: 'auto',
                                borderColor: theme.borderColor,
                                borderWidth: 1,
                                borderStyle: 'solid',
                            },
                        ]}
                    >
                        <DocumentLocationPicker
                            filename={file.path}
                            position={currentPosition}
                            positions={positions}
                            onPosition={addLocationPosition}
                            generatePositionTitle={generatePositionTitle}
                        />
                    </View>
                </View>
                <View style={{ width: 180 }} nativeID="partnerPositions">
                    <CCard>
                        <CText
                            message={requestSignatureMessages.otherPositions}
                            secondaryHeadline
                        />
                    </CCard>
                    {(isEmployer(userData)
                        ? userPositions
                        : employerPositions
                    ).map(({ position, index }, i) => (
                        <PositionRow
                            key={index}
                            index={i + 1}
                            active={index === selectedIndex}
                            position={position}
                            onPress={() => setSelectedIndex(index)}
                            onRemove={() => removePosition(index)}
                            generatePositionTitle={generatePositionTitle}
                        />
                    ))}
                    {/* <CButton
                        onPress={() =>
                            addPosition(
                                isEmployer(userData)
                                    ? EUserType.user
                                    : EUserType.employer,
                            )
                        }
                        title={requestSignatureMessages.addSignaturePosition}
                        nativeId="morePositions"
                    /> */}
                </View>
            </View>
            <View style={[style.horizontalSpaced, style.verticalPadded]}>
                <CButton
                    title={requestSignatureMessages.confirmSelection}
                    onPress={handleSubmit}
                    disabled={!hasOnePositionForBoth}
                    nativeId="saveAndContinue"
                />
            </View>
        </ScrollProvider>
    );
};
