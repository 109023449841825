export enum EDenominations {
    rk = 'rk',
    ev = 'ev',
    lt = 'lt',
    rf = 'rf',
    ak = 'ak',
    is = 'is',
    fb = 'fb',
    ib = 'ib',
    fs = 'fs',
    fg = 'fg',
    fm = 'fm',
    jh = 'jh',
    jd = 'jd',
    ih = 'ih',
    iw = 'iw',
    il = 'il',
    fr = 'fr',
    fa = 'fa',
    none = 'none',
}
