import React, { FC, useCallback, useEffect, useState } from 'react';
import { CButton, CCard, CText, ExpandableCard } from '../../../../components';
import { Platform, View } from 'react-native';
import { useFireBase } from '../../../../utilities/firebase';
import { ECollections } from '../../../../enums';
import { MProfessionalProfile } from '../../../../models';
import { profProfileMessages } from '../../ProfProfile.messages';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useStyle, useTheme } from '../../../../utilities/styles';
import Clipboard from '@react-native-clipboard/clipboard';
import { useDialog } from '../../../../utilities/dialog';
import { actionMessages } from '../../../../utilities/messages';
import { profileMessages } from '../../../Profile/Profile.messages';
import { useFormat } from '../../../../utilities/intl';
import { useLock } from '../../../../utilities/hooks';

export const ProfessionalProfileInviteSection: FC = () => {
    // global state
    const { userData, post, userAgencies, getDataIndex, remove, callFunction } =
        useFireBase();
    const dialog = useDialog();
    const style = useStyle();
    const format = useFormat();
    const { theme } = useTheme();
    const { lock } = useLock();
    // local state
    const [fillableProfiles, setFillableProfiles] = useState<
        MProfessionalProfile[]
    >([]);
    const [shouldReload, reload] = useState(0);
    const [open, setOpen] = useState(true);
    /**
     * callback to create a fillable profile
     */
    const handleCreateProfileToFill = useCallback(async () => {
        if (!userAgencies.length) {
            return;
        }
        let mail = '';
        let name = '';
        const ok = await dialog({
            icon: 'info',
            title: profProfileMessages.invite,
            message: profProfileMessages.inviteText,
            textInputs: [
                {
                    title: `"${format(profileMessages.firstName)} ${format(
                        profileMessages.lastName,
                    )}" / "${format(profileMessages.lastName)}"`,
                    id: 'name',
                },
                {
                    title: profProfileMessages.mail,
                    id: 'mail',
                },
            ],
            buttons: [
                {
                    text: profProfileMessages.createInvite,
                    onPress: (inputs) => {
                        name =
                            inputs?.find((i) => i.id === 'name')?.value || '';
                    },
                },
                {
                    text: profProfileMessages.createInviteAndSendMail,
                    onPress: (inputs) => {
                        name =
                            inputs?.find((i) => i.id === 'name')?.value || '';
                        mail =
                            inputs?.find((i) => i.id === 'mail')?.value || '';
                    },
                    disabled: (inputs) =>
                        !inputs?.find((i) => i.id === 'name')?.value ||
                        !inputs?.find((i) => i.id === 'mail')?.value,
                },
            ],
            cancelButton: { text: actionMessages.cancel },
            verticalButtons: true,
        });

        if (!ok) {
            return;
        }

        const unlock = lock();

        const splitName = name.split(' ');

        const { lastName, firstName } = {
            lastName:
                splitName.length >= 1
                    ? splitName[splitName.length - 1]
                    : undefined,
            firstName: splitName.length === 1 ? undefined : splitName[0],
        };

        const postResult = await post(
            ECollections.profProfiles,
            new MProfessionalProfile({
                temp: true,
                invite: true,
                open: true,
                agencyId: userAgencies[0].documentId,
                author: userData.documentId,
                moderators: userAgencies[0].users,
                mail: mail || undefined,
                lastName,
                firstName,
            }),
        );
        if (!postResult) return;

        if (mail) {
            const mailresult = await callFunction('sendProfProfileInvite', {
                profileId: postResult.id,
            });
            unlock();
            if (mailresult) {
                await dialog({
                    icon: 'success',
                    title: format(profProfileMessages.mailSend, { mail }),
                    message: format(profProfileMessages.mailSendText, {
                        mail,
                    }),
                });
            }
        } else {
            unlock();
        }

        if (postResult) {
            reload(Date.now());
        }
    }, [userData, userAgencies]);
    /**
     * callback to delete a profile
     */
    const handleDelete = useCallback(
        async (profile: MProfessionalProfile, index: number) => {
            if (
                !(await dialog({
                    title: profProfileMessages.confirmFormulaClose,
                    message: profProfileMessages.confirmFormulaCloseText,
                    buttons: [
                        {
                            text: actionMessages.ok,
                        },
                    ],
                    cancelButton: { text: actionMessages.cancel },
                }))
            ) {
                return;
            }
            await remove(ECollections.profProfiles, profile.documentId);
            setFillableProfiles((prev) => {
                const next = [...prev];
                next.splice(index, 1);
                return next;
            });
        },
        [],
    );
    /**
     * load invite profiles for current agency
     */
    useEffect(() => {
        if (!userAgencies.length) {
            return;
        }
        getDataIndex(ECollections.profProfiles, {
            filter: [
                {
                    field: 'temp',
                    value: true,
                },
                { field: 'invite', value: true },
                { field: 'open', value: true },
                { field: 'agencyId', value: userAgencies[0].documentId },
            ],
        }).then((response) => {
            setFillableProfiles(
                (response as any[]).map((r) => new MProfessionalProfile(r)),
            );
        });
    }, [shouldReload, userAgencies]);
    /**
     * load open state from storage
     */
    useEffect(() => {
        AsyncStorage.getItem('hide-pp-accessibility').then((next) => {
            if (next === 'true') {
                setOpen(false);
            }
        });
    }, []);
    /**
     * set open state to storage
     */
    useEffect(() => {
        AsyncStorage.setItem('hide-pp-accessibility', `${!open}`);
    }, [open]);

    return (
        <View>
            <View style={[style.horizontalSpaced, style.verticalPadded]}>
                <View style={{ width: '50%' }}>
                    <CButton
                        onPress={handleCreateProfileToFill}
                        title={profProfileMessages.createInvite}
                        icon={'plane'}
                    />
                </View>
            </View>
            {!!fillableProfiles.length && (
                <View style={style.horizontalSpaced}>
                    <CButton
                        transparent
                        minor
                        onPress={() => setOpen(!open)}
                        title={
                            open
                                ? profProfileMessages.closeInvites
                                : profProfileMessages.viewInvites
                        }
                        icon={open ? 'chevronUp' : 'chevronRight'}
                    />
                </View>
            )}
            {open && (
                <View>
                    {/* {!!fillableProfiles.length && (
                        <CText message={profProfileMessages.openInvites} />
                    )} */}
                    {fillableProfiles.map((fP, i) => {
                        const basepath =
                            Platform.OS === 'web'
                                ? // @ts-ignore
                                  window?.location?.hostname
                                : 'app.timeployees.de';
                        const isLocalhost = basepath === 'localhost';
                        const inviteURL = `${
                            isLocalhost ? '' : 'https://'
                        }${basepath}${
                            isLocalhost ? ':3000' : ''
                        }/fill?profile=${fP.documentId}`;
                        return (
                            <View
                                key={fP.documentId}
                                style={[
                                    style.horizontalSplit,
                                    style.centeredItems,
                                ]}
                            >
                                <View>
                                    <CText
                                        bold
                                        message={
                                            fP.firstName
                                                ? fP.firstName +
                                                  ' ' +
                                                  fP.lastName
                                                : fP.lastName
                                        }
                                        style={{ color: theme.textMainColor }}
                                    />
                                    <CText message={inviteURL} />
                                </View>
                                <View style={style.horizontal}>
                                    <CButton
                                        icon={'close'}
                                        onPress={() => handleDelete(fP, i)}
                                        mouseOverTitle={actionMessages.delete}
                                        minor
                                        iconColor={theme.errorColor}
                                        transparent
                                        fontStyles={{ color: theme.errorColor }}
                                    />
                                    <CButton
                                        icon={'clipboardMulti_outline'}
                                        onPress={() => {
                                            Clipboard.setString(inviteURL);
                                        }}
                                        minor
                                        transparent
                                    />
                                </View>
                            </View>
                        );
                    })}
                </View>
            )}
        </View>
    );
};
