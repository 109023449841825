import { createContext } from 'react';
import { ECollections } from '../../../enums';
import {
    DocumentChange,
    DocumentData,
    DocumentReference,
    Unsubscribe,
} from 'firebase/firestore';
/**
 * acceptable firestore values for equalities / inequalities
 */
type TValue = string | number | boolean | null;
/**
 * index to describe a filter condition
 */
export interface IFilter {
    field: string;
    value: TValue | TValue[];
    operator?: '==' | '!=' | 'array-contains' | 'in' | 'array-contains-any';
}
/**
 * interface to describe a Inequality condition
 */
export interface IInequality {
    field: string;
    value: TValue | TValue[];
    operator?: '<' | '<=' | '>' | '>=';
}
/**
 * interface for params for getDataIndex calls
 */
export interface IParamOptions {
    orderBy?: string;
    start?: number;
    end?: number;
    startDocumentId?: string;
    limit?: number;
    asc?: boolean;
    filter?: IFilter[];
    inequalities?: IInequality[];
    partialMatch?: { field: string; value: string };
    getLength?: boolean;
    useCollectionGroup?: boolean;
    didRefreshToken?: boolean;
}
/**
 * context for everything firestore
 */
export const FirestoreContext = createContext(
    {} as {
        post: (
            table: ECollections | string,
            data: any,
        ) => Promise<DocumentReference<any> | undefined>;
        put: (
            table: ECollections | string,
            id: string,
            data: any,
        ) => Promise<void>;
        remove: (table: ECollections | string, id: string) => Promise<void>;
        getDataById: (table: ECollections | string, id: string) => Promise<any>;
        getDataIndex: (
            table: ECollections | string,
            paramOptions?: IParamOptions,
            subscriptionCB?: (event: DocumentChange<DocumentData>) => void,
            unsubscribeHandler?: (cb: Unsubscribe) => void,
        ) => Promise<any[] | number>;
        getLength: (length: string) => Promise<number>;
        watchDataById: (
            table: ECollections | string,
            id: string,
            watchCallback: (data: any) => void,
        ) => Unsubscribe;
        watchDataIndex: (
            table: ECollections | string,
            paramOptions?: IParamOptions,
            subscriptionCB?: (event: DocumentChange<DocumentData>) => void,
            // TODO: this does not has to be a promise but is a major hustle to untangle into promisless unsubscribe
        ) => Promise<Unsubscribe>;
    },
);
