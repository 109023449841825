import { defineMessages } from 'react-intl';

const scope = 'com.cFieldStringInput.';

export const cFieldStringInputMessages = defineMessages({
    exactSearch: {
        id: scope + 'exactSearch',
        defaultMessage:
            'Enter your search term. The search will be case sensitive and match only results starting with the term.',
    },
});
