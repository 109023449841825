import { useEffect, useMemo, useState } from 'react';
import { MFile } from '../../models';
import { minute, second } from '../../utilities/functions';
import { useFireBase } from '../../utilities/firebase';
import { EUserType } from '../../enums';
import { useSecureNavigate } from '../../utilities/routing';

export const useFileLock = (
    files: MFile[],
    contractId: string,
    type?: EUserType,
) => {
    const { userData, callFunction } = useFireBase();
    const { setNavigationLock } = useSecureNavigate();
    const [now, setNow] = useState(Date.now());
    /**
     * locked by if lock active
     */
    const lockedBy = useMemo(() => {
        if (!files.length || !contractId) return;
        const lockedOn = files.reduce(
            (acc, f) => (acc > f.lockedOn ? acc : f.lockedOn),
            0,
        );
        const lockedBy = files.length ? files[0].lockedBy : undefined;

        if (Date.now() - lockedOn < 5 * minute) {
            return lockedBy;
        }
    }, [files]);
    /**
     * locked by me
     */
    const lockedByMe = useMemo(() => {
        if (userData.documentId && lockedBy === userData.documentId) {
            return true;
        } else if (type && lockedBy === type) {
            return true;
        }
        return false;
    }, [lockedBy, type, userData]);
    /**
     * lockRequest issued by
     */
    const { lockRequestBy, lockRequestOn } = useMemo(() => {
        const toReturn = {
            lockRequestBy: undefined as undefined | string,
            lockRequestOn: 0,
        };
        if (!files.length || !contractId) return toReturn;
        const lockRequestOn = files.reduce(
            (acc, f) => (acc > f.lockRequest ? acc : f.lockRequest),
            0,
        );
        const lockRequestBy = files.length ? files[0].lockRequestBy : undefined;

        if (Date.now() - lockRequestOn < 5 * minute) {
            toReturn.lockRequestBy = lockRequestBy;
            toReturn.lockRequestOn = lockRequestOn;
        }
        return toReturn;
    }, [files]);
    /**
     * lockRequest from meeee
     */
    const lockRequestByMe = useMemo(() => {
        if (userData.documentId && lockRequestBy === userData.documentId) {
            return true;
        } else if (type && lockRequestBy === type) {
            return true;
        }
        return false;
    }, [lockRequestBy, type, userData]);
    /**
     * lockRequest from someone else
     */
    const lockRequestFromOther = useMemo(() => {
        return (
            userData.documentId &&
            lockRequestBy !== userData.documentId &&
            (!type || (type && lockRequestBy !== type))
        );
    }, [lockRequestBy, type, userData]);
    /**
     * lock request denied recently
     */
    const lockRequestDenied = useMemo(() => {
        if (!files.length || !contractId) return 0;
        const deniedOn = files.reduce(
            (acc, f) => (acc > f.lockBlock ? acc : f.lockBlock),
            0,
        );

        return deniedOn;
    }, [files]);
    /**
     * memoized lock request timer
     */
    const lockRequestDeniedTimerInSeconds = useMemo(() => {
        if (!lockRequestDenied) return;
        setTimeout(() => setNow(Date.now()), second);
        return Math.round(15 - (Date.now() - lockRequestDenied) / second);
    }, [lockRequestDenied, now]);
    /**
     * effect set lock on contract files
     */
    useEffect(() => {
        if (!files.length || !contractId) return;
        const lockedOn = files.reduce(
            (acc, f) => (acc > f.lockedOn ? acc : f.lockedOn),
            0,
        );
        /**
         * time remaining before current lock needs to be reset
         * (4 min since lock is respected 5 min)
         */
        const lockDuration = 5 * minute;
        const lockRefreshDuration = lockDuration - minute;
        const lockRequestBlockDuration = 15 * second;
        const requestAcceptDuration = 10 * second;
        /**
         * remaining lock time
         */
        const remainder = lockRefreshDuration - (Date.now() - lockedOn);
        /**
         * lock expired in ms
         */
        const expired = lockDuration - (Date.now() - lockedOn);
        /**
         * time since lock request issue
         */
        const timeSinceRequest = Date.now() - lockRequestOn;
        /**
         * lock request time since block
         */
        const lockRequestRemainder = requestAcceptDuration - timeSinceRequest;
        /**
         * lock request time since deny
         */
        const timeSinceDeny = Date.now() - lockRequestDenied;
        /**
         * lock deny time until expires
         */
        const timeUntilDenyExpires = lockRequestBlockDuration - timeSinceDeny;
        /**
         * timeout to clear on effect rerun.
         */
        let t: NodeJS.Timeout | undefined = undefined;
        /**
         * values for function call
         */
        const values = {
            fileIds: files.map((f) => f.documentId),
            contractId,
            lock: Date.now(),
            type,
        };
        /**
         * if file is not locked or the previous lock is expired or your lock is about to expire
         */
        if (
            // not locked
            !lockedBy ||
            // lock expired
            expired < 0 ||
            // locked by me and needs refresh
            (lockedBy === userData.documentId && remainder < 0)
        ) {
            console.log('trying to lock');
            t = setTimeout(
                () => callFunction('lockEnvelopeFiles', values),
                lockedOn && remainder > 0 ? remainder : 0,
            );
        } else if (
            // no pending lock request
            !lockRequestBy &&
            // not locked by me
            !lockedByMe
        ) {
            console.log('requesting to lock');
            const toi = timeUntilDenyExpires > 0 ? timeUntilDenyExpires : 0;
            console.log('Time until request:', toi);
            t = setTimeout(
                () =>
                    callFunction('lockEnvelopeFiles', {
                        ...values,
                        request: true,
                    }),
                toi,
            );
        } else if (
            // lock request
            lockRequestOn &&
            // lock request by other
            lockRequestFromOther &&
            // my lock active
            lockedByMe
        ) {
            console.log('denying the lockrequest');
            /**
             * deny active lock request
             */
            t = setTimeout(() =>
                callFunction('lockEnvelopeFiles', { ...values, deny: true }),
            );
        } else if (
            // lock request by me passed and not denied
            lockRequestByMe &&
            // not already locked by me
            !lockedByMe
        ) {
            console.log('trying to lock after request');
            const toi =
                lockRequestRemainder > timeUntilDenyExpires
                    ? lockRequestRemainder
                    : timeUntilDenyExpires;
            console.log('time until lock:', toi < 0 ? 0 : toi);
            t = setTimeout(
                () => callFunction('lockEnvelopeFiles', values),
                toi < 0 ? 0 : toi,
            );
        } else {
            console.log('empty lock event');
        }
        /**
         * clear timeout if set
         */
        if (t) return () => clearTimeout(t);
    }, [
        files,
        contractId,
        userData,
        lockedBy,
        lockedByMe,
        lockRequestOn,
        lockRequestBy,
        lockRequestByMe,
        lockRequestFromOther,
        lockRequestDenied,
    ]);
    /**
     * effect to clear lock on fb pre navigate
     */
    useEffect(
        () =>
            setNavigationLock(async () => {
                if (lockedByMe) {
                    await callFunction('lockEnvelopeFiles', {
                        fileIds: files.map((f) => f.documentId),
                        contractId,
                        lock: 0,
                    });
                }
                return false;
            }),
        [files, lockedByMe],
    );
    /**
     * return lock by me
     * return lock request failed
     */
    return { lockedByMe, lockRequestDenied, lockRequestDeniedTimerInSeconds };
};
