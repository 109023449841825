import React, { FC, useContext, useEffect } from 'react';
import RDatePicker, { registerLocale } from 'react-datepicker';
import { View } from 'react-native';
import 'react-datepicker/dist/react-datepicker.css';
import de from 'date-fns/locale/de';
import './CDatePicker.css';
import { ICDatePickerProps } from './ICDatePickerProps';
import { useStyle, useTheme } from '../../../utilities/styles';
import { CText } from '../../elements';
import { LanguageContext } from '../../../utilities/intl';

import { Portal } from 'react-overlays';
import { IDefaultProps } from '../../../utilities/IDefaultProps';

const CalendarContainer: FC<IDefaultProps> = ({ children }) => {
    // @ts-ignore
    const el = document.getElementById('calendar-portal');

    return <Portal container={el}>{children as any}</Portal>;
};

export const CDatePicker: FC<ICDatePickerProps> = ({
    value,
    onChange,
    title,
    customCheckInvalidity,
    zIndex,
    maxDate,
    minDate,
    cy,
    noTextInput,
    disabled,
    showMonthYearPicker,
    horizontal,
}) => {
    const style = useStyle();
    const { language } = useContext(LanguageContext);
    const { theme } = useTheme();
    /**
     * effect to register non english locales
     */
    useEffect(() => registerLocale('de', de), []);
    /**
     * render
     */
    return (
        <View
            style={[
                style.verticalPadded,
                { zIndex: zIndex || 21 },
                horizontal && style.horizontal,
                horizontal && style.centeredItems,
            ]}
        >
            <CText message={title} />
            <RDatePicker
                {...{ dataSet: cy }}
                disabled={disabled}
                popperClassName="cp"
                selected={value}
                onChange={(date: Date) => onChange(date)}
                dropdownMode={'select'}
                customInput={
                    noTextInput ? (
                        <View style={style.horizontalSpaced}>
                            <CText
                                centered
                                style={{ color: theme.accentColor }}
                            >
                                {value?.toLocaleDateString('de')}
                            </CText>
                        </View>
                    ) : undefined
                }
                popperContainer={CalendarContainer}
                locale={language}
                showYearDropdown
                showMonthDropdown
                dateFormat={'dd.MM.yyyy'}
                showMonthYearPicker={showMonthYearPicker}
                {...{
                    maxDate: maxDate ? new Date(maxDate) : undefined,
                    minDate: minDate ? new Date(minDate) : undefined,
                }}
            />
            {!!customCheckInvalidity && (
                <CText style={style.error}>
                    {customCheckInvalidity(value)}
                </CText>
            )}
        </View>
    );
};
