import { RideStep } from './Ride';
import { contractRideMessages } from '../messages';

export const contractViewRide: RideStep[] = [
    {
        content: contractRideMessages.welcome,
        placement: 'center',
        target: 'body',
    },
    {
        content: contractRideMessages.details,
        target: '#detail-buttons',
        placement: 'right',
    },
    {
        content: contractRideMessages.chat,
        target: '#contract-chat',
        placement: 'top-start', // ???????
    },
    {
        content: contractRideMessages.documents,
        target: '#contract-documents',
        placement: 'right',
    },
];
