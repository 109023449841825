import { defineMessages } from 'react-intl';

const scope = 'con.supportRequest.';

export const supportRequestMessages = defineMessages({
    openRequest: {
        id: scope + 'openRequest',
        defaultMessage: 'Open support request',
    },
    initChat: {
        id: scope + 'initChat',
        defaultMessage: 'Initiate chat',
    },
    goToChat: {
        id: scope + 'goToChat',
        defaultMessage: 'Go to chat',
    },
    reasonInputTitle: {
        id: scope + 'reasonInputTitle',
        defaultMessage: 'How can we help?',
    },
    openTitle: {
        id: scope + 'openTitle',
        defaultMessage: 'Open a new support request.',
    },
    openMessage: {
        id: scope + 'openMessage',
        defaultMessage:
            'A agent will contact you as soon as possible to help you with your inquiry',
    },
    open: {
        id: scope + 'open',
        defaultMessage: 'Open',
    },
    closed: {
        id: scope + 'closed',
        defaultMessage: 'Closed',
    },
    active: {
        id: scope + 'active',
        defaultMessage: 'Active',
    },
});
