import React, { FC, useContext } from 'react';
import { ProfileContext } from '../Profile.context';
import WorkplaceList from '../../Workplace/List';
/**
 * profile section workplace. contains a list of all workplaces for a user.
 * the list should usually be one long but could extend
 * @returns
 */
export const ProfileSectionWorkplace: FC = () => {
    const { curData } = useContext(ProfileContext);
    /**
     * render embedded workplace list for current user id
     */
    return <WorkplaceList uid={curData.documentId} />;
};
