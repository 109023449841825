export enum ECountries {
    afghanistan = 'afghanistan',
    alandIslands = 'alandIslands',
    albania = 'albania',
    algeria = 'algeria',
    americanSamoa = 'americanSamoa',
    andorra = 'andorra',
    angola = 'angola',
    anguilla = 'anguilla',
    antarctica = 'antarctica',
    antiguaAndBarbuda = 'antiguaAndBarbuda',
    argentina = 'argentina',
    armenia = 'armenia',
    aruba = 'aruba',
    australia = 'australia',
    austria = 'austria',
    azerbaijan = 'azerbaijan',
    bahamas = 'bahamas',
    bahrain = 'bahrain',
    bangladesh = 'bangladesh',
    barbados = 'barbados',
    belarus = 'belarus',
    belgium = 'belgium',
    belize = 'belize',
    benin = 'benin',
    bermuda = 'bermuda',
    bhutan = 'bhutan',
    bolivia = 'bolivia',
    bosniaAndHerzegovina = 'bosniaAndHerzegovina',
    botswana = 'botswana',
    bouvetIsland = 'bouvetIsland',
    brazil = 'brazil',
    britishIndianOceanTerritory = 'britishIndianOceanTerritory',
    bruneiDarussalam = 'bruneiDarussalam',
    bulgaria = 'bulgaria',
    burkinaFaso = 'burkinaFaso',
    burundi = 'burundi',
    cambodia = 'cambodia',
    cameroon = 'cameroon',
    canada = 'canada',
    capeVerde = 'capeVerde',
    caymanIslands = 'caymanIslands',
    centralAfricanRepublic = 'centralAfricanRepublic',
    chad = 'chad',
    chile = 'chile',
    china = 'china',
    christmasIsland = 'christmasIsland',
    cocosKeelingIslands = 'cocosKeelingIslands',
    colombia = 'colombia',
    comoros = 'comoros',
    congo = 'congo',
    congoDemocraticRepublic = 'congoDemocraticRepublic',
    cookIslands = 'cookIslands',
    costaRica = 'costaRica',
    coteDIvoire = 'coteDIvoire',
    croatia = 'croatia',
    cuba = 'cuba',
    cyprus = 'cyprus',
    czechRepublic = 'czechRepublic',
    denmark = 'denmark',
    djibouti = 'djibouti',
    dominica = 'dominica',
    dominicanRepublic = 'dominicanRepublic',
    ecuador = 'ecuador',
    egypt = 'egypt',
    elSalvador = 'elSalvador',
    equatorialGuinea = 'equatorialGuinea',
    eritrea = 'eritrea',
    estonia = 'estonia',
    ethiopia = 'ethiopia',
    falklandIslands = 'falklandIslands',
    faroeIslands = 'faroeIslands',
    fiji = 'fiji',
    finland = 'finland',
    france = 'france',
    frenchGuiana = 'frenchGuiana',
    frenchPolynesia = 'frenchPolynesia',
    frenchSouthernTerritories = 'frenchSouthernTerritories',
    gabon = 'gabon',
    gambia = 'gambia',
    georgia = 'georgia',
    germany = 'germany',
    ghana = 'ghana',
    gibraltar = 'gibraltar',
    greece = 'greece',
    greenland = 'greenland',
    grenada = 'grenada',
    guadeloupe = 'guadeloupe',
    guam = 'guam',
    guatemala = 'guatemala',
    guernsey = 'guernsey',
    guinea = 'guinea',
    guineaBissau = 'guineaBissau',
    guyana = 'guyana',
    haiti = 'haiti',
    heardIslandMcdonaldIslands = 'heardIslandMcdonaldIslands',
    holySeeVaticanCityState = 'holySeeVaticanCityState',
    honduras = 'honduras',
    hongkong = 'hongkong',
    hungary = 'hungary',
    iceland = 'iceland',
    india = 'india',
    indonesia = 'indonesia',
    iran = 'iran',
    iraq = 'iraq',
    ireland = 'ireland',
    isleOfMan = 'isleOfMan',
    israel = 'israel',
    italy = 'italy',
    jamaica = 'jamaica',
    japan = 'japan',
    jersey = 'jersey',
    jordan = 'jordan',
    kazakhstan = 'kazakhstan',
    kenya = 'kenya',
    kiribati = 'kiribati',
    korea = 'korea',
    kuwait = 'kuwait',
    kyrgyzstan = 'kyrgyzstan',
    laoPeoplesDemocraticRepublic = 'laoPeoplesDemocraticRepublic',
    latvia = 'latvia',
    lebanon = 'lebanon',
    lesotho = 'lesotho',
    liberia = 'liberia',
    libyanArabJamahiriya = 'libyanArabJamahiriya',
    liechtenstein = 'liechtenstein',
    lithuania = 'lithuania',
    luxembourg = 'luxembourg',
    macao = 'macao',
    macedonia = 'macedonia',
    madagascar = 'madagascar',
    malawi = 'malawi',
    malaysia = 'malaysia',
    maldives = 'maldives',
    mali = 'mali',
    malta = 'malta',
    marshallIslands = 'marshallIslands',
    martinique = 'martinique',
    mauritania = 'mauritania',
    mauritius = 'mauritius',
    mayotte = 'mayotte',
    mexico = 'mexico',
    micronesia = 'micronesia',
    moldova = 'moldova',
    monaco = 'monaco',
    mongolia = 'mongolia',
    montenegro = 'montenegro',
    montserrat = 'montserrat',
    morocco = 'morocco',
    mozambique = 'mozambique',
    myanmar = 'myanmar',
    namibia = 'namibia',
    nauru = 'nauru',
    nepal = 'nepal',
    netherlands = 'netherlands',
    netherlandsAntilles = 'netherlandsAntilles',
    newCaledonia = 'newCaledonia',
    newZealand = 'newZealand',
    nicaragua = 'nicaragua',
    niger = 'niger',
    nigeria = 'nigeria',
    niue = 'niue',
    norfolkIsland = 'norfolkIsland',
    northernMarianaIslands = 'northernMarianaIslands',
    norway = 'norway',
    oman = 'oman',
    pakistan = 'pakistan',
    palau = 'palau',
    palestinianTerritory = 'palestinianTerritory',
    panama = 'panama',
    papuaNewGuinea = 'papuaNewGuinea',
    paraguay = 'paraguay',
    peru = 'peru',
    philippines = 'philippines',
    pitcairn = 'pitcairn',
    poland = 'poland',
    portugal = 'portugal',
    puertoRico = 'puertoRico',
    qatar = 'qatar',
    reunion = 'reunion',
    romania = 'romania',
    russianFederation = 'russianFederation',
    rwanda = 'rwanda',
    saintBarthelemy = 'saintBarthelemy',
    saintHelena = 'saintHelena',
    saintKittsAndNevis = 'saintKittsAndNevis',
    saintLucia = 'saintLucia',
    saintMartin = 'saintMartin',
    saintPierreAndMiquelon = 'saintPierreAndMiquelon',
    saintVincentAndGrenadines = 'saintVincentAndGrenadines',
    samoa = 'samoa',
    sanMarino = 'sanMarino',
    saoTomeAndPrincipe = 'saoTomeAndPrincipe',
    saudiArabia = 'saudiArabia',
    senegal = 'senegal',
    serbia = 'serbia',
    seychelles = 'seychelles',
    sierraLeone = 'sierraLeone',
    singapore = 'singapore',
    slovakia = 'slovakia',
    slovenia = 'slovenia',
    solomonIslands = 'solomonIslands',
    somalia = 'somalia',
    southAfrica = 'southAfrica',
    southGeorgiaAndSandwichIsl = 'southGeorgiaAndSandwichIsl',
    spain = 'spain',
    sriLanka = 'sriLanka',
    sudan = 'sudan',
    suriname = 'suriname',
    svalbardAndJanMayen = 'svalbardAndJanMayen',
    swaziland = 'swaziland',
    sweden = 'sweden',
    switzerland = 'switzerland',
    syrianArabRepublic = 'syrianArabRepublic',
    taiwan = 'taiwan',
    tajikistan = 'tajikistan',
    tanzania = 'tanzania',
    thailand = 'thailand',
    timorLeste = 'timorLeste',
    togo = 'togo',
    tokelau = 'tokelau',
    tonga = 'tonga',
    trinidadAndTobago = 'trinidadAndTobago',
    tunisia = 'tunisia',
    turkey = 'turkey',
    turkmenistan = 'turkmenistan',
    turksAndCaicosIslands = 'turksAndCaicosIslands',
    tuvalu = 'tuvalu',
    uganda = 'uganda',
    ukraine = 'ukraine',
    unitedArabEmirates = 'unitedArabEmirates',
    unitedKingdom = 'unitedKingdom',
    unitedStates = 'unitedStates',
    unitedStatesOutlyingIslands = 'unitedStatesOutlyingIslands',
    uruguay = 'uruguay',
    uzbekistan = 'uzbekistan',
    vanuatu = 'vanuatu',
    venezuela = 'venezuela',
    vietNam = 'vietNam',
    virginIslandsBritish = 'virginIslandsBritish',
    virginIslandsUS = 'virginIslandsUS',
    wallisAndFutuna = 'wallisAndFutuna',
    westernSahara = 'westernSahara',
}
