import { defineMessages } from 'react-intl';

const scope = 'denomination.';
/**
 * denomination names
 * is used for denomination picker
 */
export const denominationMessages = defineMessages({
    rk: {
        id: scope + 'rk',
        defaultMessage: 'Roman Catholic Church Tax',
    },
    ev: { id: scope + 'ev', defaultMessage: 'Evangelical Church Tax' },
    lt: { id: scope + 'it', defaultMessage: 'Evangelical Lutheran' },
    rf: { id: scope + 'rf', defaultMessage: 'Evangelical reformer' },
    ak: { id: scope + 'ak', defaultMessage: 'Old Catholic church tax' },
    is: {
        id: scope + 'is',
        defaultMessage: 'Israeli/Jewish Worship Tax',
    },
    fb: {
        id: scope + 'fb',
        defaultMessage: 'Free-religious state community in Baden',
    },
    ib: {
        id: scope + 'ib',
        defaultMessage: 'Israeli religious community in Baden',
    },
    fs: {
        id: scope + 'fs',
        defaultMessage: 'Free-religious community in Offenbach/Main',
    },
    fg: {
        id: scope + 'fq',
        defaultMessage: 'Free-religious state community in Pfalz',
    },
    fm: {
        id: scope + 'fm',
        defaultMessage: 'Free religious community in Mainz',
    },
    jh: { id: scope + 'jh', defaultMessage: 'Jewish cult tax (jh)' },
    jd: { id: scope + 'jd', defaultMessage: 'Jewish cult tax (jd)' },
    ih: { id: scope + 'ih', defaultMessage: 'Jewish cult tax (ih)' },
    iw: {
        id: scope + 'iw',
        defaultMessage: 'Jewish religious community of Württemberg',
    },
    il: {
        id: scope + 'il',
        defaultMessage:
            'Jewish religious tax of the communities entitled to worship',
    },
    fr: { id: scope + 'fr', defaultMessage: 'French reformed' },
    fa: {
        id: scope + 'fa',
        defaultMessage: 'Free religious community Alzey',
    },
    none: {
        id: scope + 'none',
        defaultMessage: 'No church tax',
    },
});
