export class MNotification {
    public documentId = '';

    public for: string | null = null;

    public readBy: { uid: string; on: number }[] = [];
    public pinnedBy: { uid: string; on: number }[] = [];

    public icon = 'bell';
    public title = '';
    public titleKey = '';
    public message = '';
    public messageKey = '';

    public linkTo = '';
    public url = '';

    public editedOn = Date.now();
    public createdOn = Date.now();

    public variables: any = {};

    constructor(params: Partial<MNotification> = {}) {
        Object.assign(this, params);
    }
}
