export enum EUserType {
    default = 'default',
    moderator = 'moderator',
    agent = 'agent',
    employer = 'employer',
    user = 'user',
    agency = 'agency',
    admin = 'admin',
    basic = 'basic',
    talent = 'talent',
}
