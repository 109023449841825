import { createContext } from 'react';
import { FullMetadata, ListResult } from 'firebase/storage';

export const FireStorageContext = createContext(
    {} as {
        uploadToStorage: (
            filename: string,
            source: Uint8Array | string,
        ) => Promise<FullMetadata>;
        getFileMetadata: (filename: string) => Promise<FullMetadata>;
        getFileDownloadUrl: (filename: string) => Promise<string>;
        getFile: (filename: string) => Promise<ArrayBuffer>;
        removeFile: (fullPath: string) => Promise<void>;
        // downloadFile: (filename: string) => Promise<any>;
    },
);
