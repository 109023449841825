import { StyleSheet } from 'react-native';
import { ITheme } from '../../../utilities/styles/ITheme';

export const makeCButtonStyles = (theme: ITheme) =>
    StyleSheet.create({
        transparentButton: {
            backgroundColor: 'transparent',
            color: theme.accentColor,
            borderWidth: 0,
            shadowOffset: {
                width: 0,
                height: 0,
            },
            shadowOpacity: 0,
            shadowRadius: 0,
            elevation: 0,
        },
        antiTransparentText: {
            color: theme.textMainColor,
        },
        button: {
            backgroundColor: theme.accentColor,
            color: theme.textAccentColor,
            text: theme.textAccentColor,
            borderRadius: 10,
            borderWidth: 0,
            paddingHorizontal: 25,
            margin: 5,
            alignItems: 'center',
            minHeight: 40,
            justifyContent: 'center',
            zIndex: 2,
            shadowColor: theme.shadowColor,
            shadowOffset: {
                width: 0,
                height: 1,
            },
            shadowOpacity: 0.18,
            shadowRadius: 1.0,
            elevation: 1,
            flexDirection: 'row',
        },
        minorButton: {
            backgroundColor: theme.backgroundMainColor,
            borderWidth: 1,
            borderStyle: 'solid',
            paddingHorizontal: 15,
            borderColor: theme.accentColor,
            flexDirection: 'row',
        },
        minorButtonText: {
            color: theme.accentColor,
        },
        buttonCheckbox: {
            text: theme.accentColor,
            borderRadius: 4,
            padding: 0,
            margin: 0,
            alignItems: 'center',
            minHeight: 20,
            justifyContent: 'center',
            zIndex: 2,
        },
        buttonSmaller: {
            backgroundColor: theme.accentColor,
            text: theme.textAccentColor,
            borderRadius: 10,
            padding: 2,
            alignItems: 'center',
            minHeight: 24,
            justifyContent: 'center',
            zIndex: 2,
        },
        buttonSmall: {
            backgroundColor: theme.accentColor,
            text: theme.textAccentColor,
            borderRadius: 10,
            padding: 2,
            paddingHorizontal: 15,
            alignItems: 'center',
            minHeight: 24,
            justifyContent: 'center',
            zIndex: 2,
            flexDirection: 'row',
        },
        buttonSmallest: {
            backgroundColor: theme.accentColor,
            text: theme.textAccentColor,
            borderRadius: 10,
            padding: 2,
            paddingHorizontal: 5,
            alignItems: 'center',
            minHeight: 18,
            justifyContent: 'center',
            zIndex: 2,
            flexDirection: 'row',
        },
        buttonIconContainer: {
            paddingRight: 10,
        },
        buttonIconContainerVertical: {
            paddingRight: 0,
        },
        warningButton: {
            backgroundColor: theme.warningColor,
        },
        dangerButton: {
            backgroundColor: theme.errorColor,
        },
    });
