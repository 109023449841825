import { EApplicationType, EContractStatus } from '../enums';
/**
 * Model for basic contracts between a timeployees user and anyone.
 * Preferably between a user and a potentially new user
 */
export class MBasicContract {
    public documentId = '';

    public type = EApplicationType.default;
    public status = EContractStatus.creating;

    public editedOn = Date.now();
    public createdOn = Date.now();
    /**
     * free text title
     */
    public title = '';

    public chatId = '';
    public pastChats: string[] = [];

    public participants: string[] = [];

    constructor(params: Partial<MBasicContract> = {}) {
        Object.assign(this, params);
    }
}
