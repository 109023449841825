import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import {
    CText,
    CIcon,
    CMultiButton,
    CImage,
    CCard,
    CButton,
} from '../../../../components';
import { ProfilePicture } from '../../../../components/ProfilePicture';
import { ECollections } from '../../../../enums';
import { MChat, MUserData } from '../../../../models';
import { userMessages } from '../../user.messages';
import { VerifyButton } from '../../components/VerifyButton';
import { useFormat } from '../../../../utilities/intl';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { useSecureNavigate } from '../../../../utilities/routing';
import { useFireBase } from '../../../../utilities/firebase';
import { TouchableView } from '../../../../components/TouchableView';
import { isSuperUser } from '../../../../utilities/auth';
import { UserTypeLabel } from '../../../../components/UserTypeLabel';

interface IUserRow {
    user: MUserData;
    handleChange?: (next: MUserData) => void;
    minimal?: boolean;
    embedded?: boolean;
    canSign?: boolean;
    onChangeCanSign?: () => void;
    onRemove?: () => void;
    isDefaultSigner?: boolean;
    onChangeIsDefaultSigner?: () => void;
}
/**
 * user row
 * @param param0
 * @returns
 */
export const UserRow: FC<IUserRow> = ({
    user,
    handleChange,
    minimal,
    canSign,
    onChangeCanSign,
    onRemove,
    isDefaultSigner,
    onChangeIsDefaultSigner,
    embedded,
}) => {
    const format = useFormat();
    const style = useStyle();
    const { theme } = useTheme();
    const { secureNavigate } = useSecureNavigate();
    const { userData, post, getDataById, getDataIndex } = useFireBase();
    const [activeChat, setActiveChat] = useState<MChat>();
    const [agents, setAgents] = useState<MUserData[]>([]);
    /**
     * effect to load assigned agents
     * and active chats
     */
    useEffect(() => {
        const promises = user.assignedAgents.map(async (a) => {
            return new MUserData(await getDataById(ECollections.users, a));
        });
        Promise.all(promises).then((next) => {
            setAgents(next);
        });
        if (userData.documentId) {
            getDataIndex(ECollections.chats, {
                filter: [
                    {
                        operator: 'array-contains',
                        value: userData.documentId,
                        field: 'participants',
                    },
                ],
            }).then((results) => {
                const chats = (results as MChat[])
                    .map((c) => new MChat(c))
                    .filter((c) => c.participants.includes(user.documentId));
                if (chats.length) {
                    setActiveChat(chats[0]);
                }
            });
        }
    }, [user, userData]);
    /**
     * memoized buttons for multi button
     */
    const buttons = useMemo(() => {
        const toReturn: any[] = [
            {
                cy: 'view',
                title: format(userMessages.view),
                onPress: (): void => {
                    secureNavigate(`/user/${user.documentId}`);
                },
            },
            {
                cy: 'chat',
                icon: 'chat',
                onPress: () => {
                    console.log(activeChat);
                    if (!activeChat) {
                        post(
                            ECollections.chats,
                            new MChat({
                                createdOn: Date.now(),
                                participants: [
                                    userData.documentId,
                                    user.documentId,
                                ],
                            }),
                        ).then((result) => {
                            if (result) {
                                secureNavigate(`/chat/${result?.id}`);
                            }
                        });
                    } else {
                        secureNavigate(`/chat/${activeChat.documentId}`);
                    }
                },
                title: 'startChat',
            },
        ];
        if (handleChange) {
            toReturn.push(
                <VerifyButton
                    style={{
                        margin: 0,
                        borderRadius: 0,
                        borderWidth: 0,
                    }}
                    minor
                    key={'verifyButton'}
                    user={user}
                    onChange={handleChange}
                    activeChat={activeChat}
                />,
            );
        }
        return toReturn;
    }, [activeChat, handleChange]);
    /**
     * render
     */
    return (
        <CCard
            hoverEffects
            cy={'open-user-' + user.lastName}
            style={[style.horizontal]}
            onPress={
                isSuperUser(userData)
                    ? () => secureNavigate(`/user/${user.documentId}`)
                    : undefined
            }
            embedded={embedded}
        >
            <View style={[style.horizontal, style.flex1]}>
                <ProfilePicture data={user} small />
                <View style={style.flex1}>
                    <View style={[style.horizontalWrap, style.centeredItems]}>
                        <CText
                            secondaryHeadline
                            style={{ marginHorizontal: 5 }}
                        >
                            {user.firstName} {user.lastName}
                        </CText>
                        <CText style={{ marginHorizontal: 5 }}>
                            {user.documentId}
                        </CText>
                    </View>
                    {!minimal && (
                        <View style={[style.horizontal, style.centeredItems]}>
                            <UserTypeLabel data={user} />
                            {/* TODO: reenable display of educations maybe */}
                            {/* {user.type === EUserType.user &&
                            !!user.educations.length && (
                                <CText>
                                {`${user.educations.map((edu) =>
                                    format(
                                        educations[
                                            edu as keyof typeof educations
                                        ],
                                    ),
                                )}`}
                                </CText>
                            )} */}
                        </View>
                    )}
                    {!minimal && (
                        <View style={style.horizontalPadded}>
                            <TouchableView
                                style={[style.horizontal, style.centeredItems]}
                                // onPress={() => {
                                //     Linking.openURL(`mailto:${user.mail}`);
                                // }}
                            >
                                <CIcon
                                    icon="email"
                                    size={16}
                                    tint={theme.textSecondaryColor}
                                />
                                <CText style={style.leftPadded}>
                                    {user.mail}
                                </CText>
                            </TouchableView>
                            <TouchableView
                                style={[style.horizontal, style.centeredItems]}
                            >
                                <CIcon
                                    icon="phone"
                                    size={16}
                                    tint={theme.textSecondaryColor}
                                />
                                <CText style={style.leftPadded}>
                                    {user.phone}
                                </CText>
                            </TouchableView>
                        </View>
                    )}
                </View>
            </View>
            <View style={{ minWidth: 130, alignItems: 'flex-end' }}>
                {onChangeCanSign && onChangeIsDefaultSigner && (
                    <View style={[style.horizontal, style.centeredItems]}>
                        <CButton
                            onPress={onChangeCanSign}
                            icon="signature"
                            transparent
                            iconColor={
                                canSign ? theme.accentColor : theme.borderColor
                            }
                        />
                        <CButton
                            onPress={onChangeIsDefaultSigner}
                            icon="star"
                            transparent
                            iconColor={
                                isDefaultSigner
                                    ? theme.accentColor
                                    : theme.borderColor
                            }
                        />
                        {onRemove && (
                            <CButton
                                onPress={onRemove}
                                icon="minus"
                                transparent
                                iconColor={theme.errorColor}
                            />
                        )}
                    </View>
                )}
                {!minimal && (
                    <>
                        <View style={[style.horizontal, style.centeredItems]}>
                            <CIcon
                                icon={user.extraMobile ? 'car' : 'car_outline'}
                                style={[style.horizontalPadded]}
                                tint={
                                    user.extraMobile
                                        ? theme.accentColor
                                        : theme.borderColor
                                }
                            />
                            <CIcon
                                icon={
                                    user.extraAvailable
                                        ? 'clock'
                                        : 'clock_outline'
                                }
                                style={[style.horizontalPadded]}
                                tint={
                                    user.extraAvailable
                                        ? theme.accentColor
                                        : theme.borderColor
                                }
                            />
                            <CIcon
                                icon="check"
                                style={[style.horizontalPadded]}
                                tint={
                                    user.verified
                                        ? theme.successColor
                                        : theme.borderColor
                                }
                            />
                            <CMultiButton
                                cy={'multiButton-' + user.documentId}
                                buttons={buttons}
                            />
                        </View>
                        {!!agents.length && (
                            <View
                                style={[
                                    style.horizontalPadded,
                                    { alignItems: 'flex-end' },
                                ]}
                            >
                                <CText>
                                    {format(userMessages.assignedAgent)}
                                </CText>
                                <CText
                                    style={{ color: theme.textMainColor }}
                                >{`${agents[0].firstName} ${agents[0].lastName}`}</CText>
                            </View>
                        )}
                    </>
                )}
                <View style={[style.verticalPadded, style.horizontalPadded]}>
                    <CImage image={user.region} fixedHeight={20} />
                </View>
                {!!user.deleteIssuedOn && (
                    <CText
                        style={style.error}
                        message={userMessages.deleteIssued}
                    >
                        {' ' +
                            new Date(user.deleteIssuedOn).toLocaleDateString(
                                'de',
                            )}
                    </CText>
                )}
            </View>
        </CCard>
    );
};
