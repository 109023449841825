import React, { useCallback, useContext, useMemo } from 'react';
import { View } from 'react-native';
import { useStyle } from '../styles';
import { Spinner } from '../../components';
import { useDimensions } from './useDimensions';
import { CModalContext } from '../contexts/CModal';
/**
 * lock during longer operations
 * @returns functions to lock / unlock and current lock state
 */
export const useLock = () => {
    const style = useStyle();
    const { width } = useDimensions();
    const { pushModal, removeModal, removeLockModal, lockModal } =
        useContext(CModalContext);
    /**
     * determin if view is currently locked
     */
    const isLocked = useMemo(() => !!lockModal, [lockModal]);
    /**
     * lock current view with spinner
     */
    const lock = useCallback(() => {
        pushModal(
            <View
                style={[
                    style.card,
                    {
                        padding: width < 720 ? 10 : 20,
                        maxWidth: width < 720 ? width : 720,
                        alignItems: 'center',
                        justifyContent: 'center',
                    },
                ]}
            >
                <Spinner />
            </View>,
            {
                lock: true,
                id: 'loadinglock',
            },
        );
        return () => removeLockModal();
    }, [removeLockModal, pushModal]);
    /**
     * callback to remove lock
     */
    const unlock = useCallback(() => {
        removeLockModal;
    }, [removeLockModal]);
    /**
     * return lock, unlock and lock state
     */
    return {
        lock,
        unlock,
        isLocked,
    };
};
