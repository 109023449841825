import { StyleSheet } from 'react-native';
import { ITheme } from '../../../utilities/styles/ITheme';

export const makeTableStyles = (theme: ITheme) =>
    StyleSheet.create({
        row: {
            borderBottomColor: theme.borderColor,
            borderBottomWidth: 1,
        },
        column: {
            padding: 5,
        },
    });
