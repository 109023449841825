import {
    EAgencyFiles,
    EContractFiles,
    EEmployeeFiles,
    EFile,
    EGeneralFiles,
    EUserFiles,
    EUserType,
    EWorkplaceFiles,
} from '../enums';
import { year } from '../utilities/functions';
import { MSignatureRequest } from './MSignatureRequest';
import { MSignaturePosition } from './MSignaturePosition';
/**
 * model for file references
 */
export class MFile {
    public documentId = '';
    public name = '';
    public path = '';
    public author = '';
    public type: EFile = EGeneralFiles.other;

    public createdOn = Date.now();
    public validUntil = Date.now() + year;
    public validForever = true;
    /**
     * time the lock was issued (+5 min lock end)
     */
    public lockedOn = 0;
    public lockedBy?: string;
    /**
     * set together with lockedOn and refresh if lock request was blocked
     */
    public lockBlock = 0;
    /**
     * time lockrequest was issued in case a user accesses the locked file and the other party is finished but missed the unlock
     */
    public lockRequest = 0;
    public lockRequestBy?: string;

    public envelopeId = '';
    public envelopeOn = 0;
    public envelopeType?: 'namirial' | 'courier' | 'unqualified';
    public namirialFileId = '';

    public envelopeSigned = false;
    public signedBy: string[] = [];
    /**
     * signature Request
     */
    public signatureRequest?: MSignatureRequest;
    /**
     * positions for namirial enveloping
     */
    public signaturePositions: MSignaturePosition[] = [];
    /**
     * exposed position types to be signed unverified from anyone
     */
    public exposedPositions: EUserType[] = [];
    public exposed = false;
    public wasExposed = false;
    public exposeHistory: {
        exposedBy: string;
        exposedPosition: EUserType;
        exposedToMail?: string;
        timestamp: number;
    }[] = [];
    /**
     * autoload to signal these files may be added to all contracts
     */
    public autoload = false;

    constructor(params: Partial<MFile>) {
        Object.assign(this, params);
        MFile.fixConstructor(this, params);
    }

    static fixConstructor = (that: any, params: Partial<MFile>) => {
        if (params.signaturePositions) {
            that.signaturePositions = params.signaturePositions.map(
                (sP) => new MSignaturePosition(sP),
            );
        }
    };
}
/**
 * model for userfile references.
 * use enum for filetypes
 */
export class MUserFile extends MFile {
    public type: EUserFiles = EEmployeeFiles.resume;

    constructor(params: Partial<MUserFile>) {
        super(params);
        Object.assign(this, params);
        MFile.fixConstructor(this, params);
    }
}
/**
 * model for agencyfile references
 * use enum for filetypes
 */
export class MAgencyFile extends MFile {
    public type: EAgencyFiles = EAgencyFiles.license;

    constructor(params: Partial<MAgencyFile>) {
        super(params);
        Object.assign(this, params);
        MFile.fixConstructor(this, params);
    }
}
/**
 * model for workplacefile references
 * use enum for filetypes
 */
export class MWorkplaceFile extends MFile {
    public type: EWorkplaceFiles = EWorkplaceFiles.houseRules;

    constructor(params: Partial<MWorkplaceFile>) {
        super(params);
        Object.assign(this, params);
        MFile.fixConstructor(this, params);
    }
}
/**
 * model for contractfile references.
 * use enum for filetypes
 */
export class MContractFile extends MFile {
    public type: EContractFiles | EUserFiles = EGeneralFiles.other;
    /**
     * used for filtering
     */
    public for: 'yer' | 'yee' | 'null' = 'null';

    public contractId = '';

    constructor(params: Partial<MContractFile>) {
        super(params);
        Object.assign(this, params);
        MFile.fixConstructor(this, params);
    }
}
