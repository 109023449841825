import { createContext } from 'react';

export const DropContext = createContext(
    {} as {
        dropActive: boolean;
        setDropActive: (next: boolean) => void;
        setOnDrop: (next: (acceptedFiles: any[]) => void) => void;
        clearOnDrop: () => void;
    },
);
