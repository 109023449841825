/* eslint-disable react/no-string-refs */
import React, { FC } from 'react';
import './View.css';

interface IRichTextViewProps {
    html: string;
}

export const RichTextView: FC<IRichTextViewProps> = ({ html }) => {
    return (
        <div
            className="custom-rich-text"
            dangerouslySetInnerHTML={{ __html: html }}
        />
    );
};
