import { defineMessages } from 'react-intl';

const scope = 'com.signature.';

export const signatureMessages = defineMessages({
    clear: {
        id: scope + 'clear',
        defaultMessage: 'Clear',
    },
    apply: {
        id: scope + 'apply',
        defaultMessage: 'Apply',
    },
    placeSignatureHere: {
        id: scope + 'placeSignatureHere',
        defaultMessage: 'Use input method to apply signature here',
    },
});
