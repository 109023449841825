import {
    MShiftGroupChildCare,
    MShiftPlan,
    MShiftPlanShift,
} from '../../../../../models';
import { dayMessages } from '../../../../../utilities/messages';
/**
 * value to determin when to use 45 min breaks
 */
const BREAK_THRESHOLD = 9;
/**
 * value to determin the hour time frame in the middle of the day to spread breaks across
 */
const BREAK_PERIOD = 3;
/**
 * function to create a layout of shifts which could fit the workgroup requirements
 * @param shiftPlan shiftplan with workgroups to create shifts for
 * @returns shifts without workers assigned
 */
export const prepareChildCareShifts = (shiftPlan: MShiftPlan) => {
    const shifts: MShiftPlanShift[] = [];
    const groups = shiftPlan.groups.map((g) => {
        const group = new MShiftGroupChildCare(g);
        group.workerCount = Math.ceil(
            group.kidsUnder3 / 7.5 +
                group.kidsOver3 / 12.5 +
                group.kidsInSchool / 10,
        );
        group.activeDays = group.activeDays.map((d) => {
            const next = { ...d };
            if (next.preTime) {
                const preTimeInHours = next.preTime / 60;
                next.from = next.from + preTimeInHours;
            }
            if (next.postTime) {
                const postTimeInHours = next.postTime / 60;
                next.to = next.to - postTimeInHours;
            }
            return next;
        });
        return group;
    });
    const groupsWithoutBreaks = groups;
    const dayKeys = Object.keys(dayMessages).filter(
        (key) => !key.includes('Short'),
    );
    for (const dayKey of dayKeys) {
        const day = dayKeys.indexOf(dayKey);
        const todaysGroupsWOB = groupsWithoutBreaks.filter((g) =>
            g.activeDays.find((d) => d.day === day),
        );
        /**
         * amount of breaks of 45min to spread across groups
         */
        const amountOfBigBreaks = todaysGroupsWOB
            .filter((g) => {
                const d = g.activeDays.find((d) => d.day === day);
                d &&
                    d.to -
                        d.from +
                        ((d.preTime || 0) + (d.postTime || 0)) / 60 >=
                        BREAK_THRESHOLD;
            })
            .reduce((acc, g) => acc + 2, 0);
        /**
         * amount of breaks of 30min to spread across groups
         */
        const amountOfSmallBreaks = todaysGroupsWOB
            .filter((g) => {
                const d = g.activeDays.find((d) => d.day === day);
                d &&
                    d.to -
                        d.from +
                        ((d.preTime || 0) + (d.postTime || 0)) / 60 <
                        BREAK_THRESHOLD;
            })
            .reduce((acc, g) => acc + 2, 0);
        /**
         * total time spent on breaks
         */
        const sumBreakTime =
            amountOfBigBreaks * 0.75 + amountOfSmallBreaks * 0.5;
        /**
         * estimated breakcycle amount
         */
        const breakCycles = 1 + Math.floor(sumBreakTime / BREAK_PERIOD);
        /**
         * tracker of end of last break to start next break on
         */
        let lastBreakEnd = 0;
        for (const group of todaysGroupsWOB) {
            const dayData = group.activeDays.find((d) => d.day === day);
            if (!dayData) continue;
            const longBreak = dayData.to - dayData.from >= BREAK_THRESHOLD;
            const mid = (dayData.to + dayData.from) / 2;
            const breakTime = longBreak ? 0.75 : 0.5;
            let firstBreakInGroupStart = 0;
            const levels = [3, 2];
            /**
             * generate workerCount shifts with not overlapping breaks
             */
            for (let i = 0; i < 2; i++) {
                const initialOffset =
                    (breakCycles > 1 ? BREAK_PERIOD : breakTime) / 2;
                const breakStartTime = lastBreakEnd
                    ? lastBreakEnd
                    : mid - initialOffset;
                /**
                 * break from to
                 * TODO: improve the determination of break positions to overlap less
                 */
                const breakData = {
                    from: breakStartTime,
                    to: breakStartTime + breakTime,
                };
                if (!firstBreakInGroupStart) {
                    firstBreakInGroupStart = breakData.from;
                }
                const shiftLevel = levels.splice(0, 1)[0] || undefined;
                shifts.push(
                    new MShiftPlanShift({
                        from: dayData.from,
                        to: breakData.from,
                        group: group.id,
                        day: day,
                        level: shiftLevel,
                    }),
                );
                shifts.push(
                    new MShiftPlanShift({
                        from: breakData.to,
                        to: dayData.to,
                        group: group.id,
                        day: day,
                        level: shiftLevel,
                    }),
                );
                lastBreakEnd = breakData.to;
            }
            shifts.push(
                new MShiftPlanShift({
                    from: firstBreakInGroupStart,
                    to: lastBreakEnd,
                    group: group.id,
                    day: day,
                }),
            );
            const borderTimePersonCount =
                group.kidsUnder3 / 7.5 +
                group.kidsOver3 / 12.5 +
                group.kidsInSchool / 10;
            const borderLevels = [3, 2];
            for (let i = 0; i < borderTimePersonCount; i++) {
                const borderShiftLevel =
                    borderLevels.splice(0, 1)[0] || undefined;
                if (dayData.preTime) {
                    shifts.push(
                        new MShiftPlanShift({
                            from: dayData.from - dayData.preTime / 60,
                            to: dayData.from - 0.001,
                            group: group.id,
                            day: day,
                            level: borderShiftLevel,
                        }),
                    );
                }
                if (dayData.postTime) {
                    shifts.push(
                        new MShiftPlanShift({
                            from: dayData.to + 0.001,
                            to: dayData.to + dayData.postTime / 60,
                            group: group.id,
                            day: day,
                            level: borderShiftLevel,
                        }),
                    );
                }
            }
        }
    }

    return shifts;
};
