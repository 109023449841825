import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import { IDefaultProps } from '../../IDefaultProps';
import { useTheme } from '../../styles';
import { useDimensions } from '../../hooks/useDimensions';
import { CModalContext, IModalParams } from './CModalContext';
import { useLocation } from '../../routing';

export interface IModal extends IModalParams {
    modal: ReactNode;
}

export const CModalProvider: FC<IDefaultProps> = ({ children }) => {
    const { width, height } = useDimensions();
    const { theme } = useTheme();
    const [modals, setModals] = useState<IModal[]>([]);
    const [lock, setLock] = useState<IModal>();
    const [hide, setHide] = useState(false);
    const location = useLocation();
    const [curLocation, setCurLocation] = useState<string>();
    /**
     * callback to remove a specific modal determined by id
     */
    const removeModal = useCallback(
        (id: string) => {
            if (!id) {
                return;
            }
            if (lock && lock.id === id) {
                setLock(undefined);
            }
            setModals((prev) => {
                const index = prev.findIndex((m) => m.id === id);
                if (index >= 0) {
                    prev.splice(index, 1);
                    return [...prev];
                } else {
                    return prev;
                }
            });
        },
        [lock],
    );
    /**
     * callback to remove top most modal
     */
    const popModal = useCallback(() => {
        setModals((prev) => {
            prev.pop();
            return [...prev];
        });
    }, []);
    /**
     * callback to specifically get rid of lock modal
     */
    const removeLockModal = useCallback(() => setLock(undefined), []);
    /**
     * callback to add modal on top
     */
    const pushModal = useCallback((m: ReactNode, params?: IModalParams) => {
        const next: IModal = { modal: m };
        if (params) {
            next.x = params.x;
            next.y = params.y;
            next.id = params.id;
            next.onClose = params.onClose;
            next.transparentBackground = params.transparentBackground;
        }
        if (params?.lock) {
            setLock(next);
        } else {
            setModals((prev) => {
                return [...prev, next];
            });
        }
    }, []);
    /**
     * callback to add modal behind existing modals
     */
    const unshiftModal = useCallback((m: ReactNode, params?: IModalParams) => {
        const next: IModal = { modal: m };
        if (params) {
            next.x = params.x;
            next.y = params.y;
            next.id = params.id;
            next.onClose = params.onClose;
            next.transparentBackground = params.transparentBackground;
        }
        if (params?.lock) {
            setLock(next);
        } else {
            setModals((prev) => {
                return [next, ...prev];
            });
        }
    }, []);

    /**
     * effect to force clear lock on navigation
     */
    useEffect(() => {
        if (location.pathname !== curLocation) {
            setCurLocation(location.pathname);
            setLock(undefined);
        }
    }, [location, curLocation]);
    /**
     * render
     */
    return (
        <CModalContext.Provider
            value={{
                removeModal,
                popModal,
                pushModal,
                unshiftModal,
                setHideModals: setHide,
                removeLockModal,
                modals,
                lockModal: lock || undefined,
            }}
        >
            {children}
            {lock && (
                <View
                    style={{
                        position: 'absolute',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width,
                        height,
                    }}
                >
                    <View
                        style={{
                            width,
                            height,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            backgroundColor: lock.transparentBackground
                                ? 'transparent'
                                : theme.borderColor,
                            opacity: 0.5,
                        }}
                    />
                    {/* modal view */}
                    <View
                        style={[
                            {
                                zIndex: 100,
                                justifyContent: 'center',
                            },
                            !!(lock.x || lock.y) && {
                                position: 'absolute',
                                top: lock.y,
                                left: lock.x,
                            },
                        ]}
                    >
                        {lock.modal}
                    </View>
                </View>
            )}
            {!hide &&
                modals.map((m, i) => (
                    <View
                        key={i}
                        style={{
                            position: 'absolute',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width,
                            height,
                        }}
                    >
                        <TouchableWithoutFeedback
                            onPress={() => {
                                if (m.onClose) {
                                    m.onClose();
                                }
                                setTimeout(() => {
                                    setModals((prev) => {
                                        prev.splice(i, 1);
                                        return [...prev];
                                    });
                                }, 1);
                            }}
                        >
                            <View
                                {...{ dataSet: { cy: 'force-close-modal' } }}
                                style={{
                                    width,
                                    height,
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    backgroundColor: m.transparentBackground
                                        ? 'transparent'
                                        : theme.borderColor,
                                    opacity: 0.5,
                                }}
                            />
                        </TouchableWithoutFeedback>
                        {/* modal view */}
                        <View
                            style={[
                                {
                                    zIndex: 100,
                                    justifyContent: 'center',
                                },
                                !!(m.x || m.y) && {
                                    position: 'absolute',
                                    top: m.y,
                                    left: m.x,
                                },
                            ]}
                        >
                            {m.modal}
                        </View>
                    </View>
                ))}
        </CModalContext.Provider>
    );
};
