import React, { FC } from 'react';
import { CButton, CText, TouchableView } from '../../../components';
import { MSignaturePosition } from '../../../models';
import { useStyle, useTheme } from '../../../utilities/styles';
import { View } from 'react-native';
import { useFormat } from '../../../utilities/intl';
import { requestSignatureMessages } from '../requestSignature.messages';

export const PositionRow: FC<{
    onPress: () => void;
    onRemove: () => void;
    position: MSignaturePosition;
    active: boolean;
    index: number;
    generatePositionTitle: (p: MSignaturePosition) => string;
}> = ({ onPress, position, active, onRemove, generatePositionTitle }) => {
    const style = useStyle();
    const format = useFormat();
    const { theme } = useTheme();
    return (
        <TouchableView
            onPress={onPress}
            style={[
                style.card,
                { borderWidth: 1, borderColor: theme.backgroundMainColor },
                active && style.accentBorder,
                style.horizontalSplit,
                style.centeredItems,
            ]}
        >
            <View>
                <CText
                    message={generatePositionTitle(position)}
                    secondaryHeadline
                />
                <CText>
                    {format(requestSignatureMessages.page)} {position.page}
                </CText>
                <View style={style.horizontalSplit}>
                    <CText>
                        x: {position.x} y: {position.y}
                    </CText>
                    <CButton
                        icon="close"
                        onPress={onRemove}
                        transparent
                        minor
                        small
                        iconColor={theme.errorColor}
                    />
                </View>
            </View>
        </TouchableView>
    );
};
