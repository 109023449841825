export enum EIdentificationType {
    None = 'None',
    ForeignTaxCode = 'ForeignTaxCode',
    PersonalNumber = 'PersonalNumber',
    Passport = 'Passport',
    NationalIdentityCard = 'NationalIdentityCard',
    ItalianTaxCode = 'ItalianTaxCode',
    NoSerialNumber = 'NoSerialNumber',
    DrivingLicense = 'DrivingLicense',
}
