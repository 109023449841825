import React, { FC, ReactNode, useCallback, useState } from 'react';
import { View } from 'react-native';
import { MShiftGroup } from '../../../../models';
import {
    IGroupExtensionProps,
    IShiftPlanComponentProps,
} from '../IShiftPlanComponentProps';
import {
    CButton,
    CCard,
    CCheckBox,
    CText,
    CTextInput,
} from '../../../../components';
import { actionMessages, dayMessages } from '../../../../utilities/messages';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { shiftPlanMessages } from '../../shiftPlan.messages';
import { DayInput } from '../DayInput';

interface GroupSelectProps extends IShiftPlanComponentProps {
    extension?: FC<IGroupExtensionProps>;
}

export const GroupSelect: FC<GroupSelectProps> = ({
    shiftPlan,
    extension,
    setPlan,
    setEdit,
    goForward,
    goBackward,
}) => {
    const style = useStyle();
    const { theme } = useTheme();
    const [dayWidth, setDayWidth] = useState(0);

    const ExtensionComponent = extension;

    const onChangeGroup = useCallback(
        (next: Partial<MShiftGroup>, index: number) =>
            setPlan((prev) => {
                const nextGroups = [...prev.groups];
                nextGroups.splice(index, 1, { ...nextGroups[index], ...next });
                return { ...prev, groups: nextGroups };
            }),
        [],
    );

    const removeGroup = useCallback(
        (index: number) =>
            setPlan((prev) => {
                const nextGroups = [...prev.groups];
                nextGroups.splice(index, 1);
                return { ...prev, groups: nextGroups };
            }),
        [],
    );

    const changeDay = useCallback(
        (
            next: Partial<{
                day: number;
                to: number;
                from: number;
            }>,
            groupIndex: number,
            dayIndex: number,
        ) => {
            const nextActiveDays = [...shiftPlan.groups[groupIndex].activeDays];
            const i = nextActiveDays.findIndex((d) => d.day === dayIndex);
            nextActiveDays[i] = { ...nextActiveDays[i], ...next };
            console.log(nextActiveDays);
            onChangeGroup(
                {
                    activeDays: nextActiveDays,
                },
                groupIndex,
            );
        },
        [shiftPlan],
    );

    const addDayToGroup = useCallback(
        (day: number, index: number) => {
            const nextActiveDays = [...shiftPlan.groups[index].activeDays];
            nextActiveDays.push({
                day,
                from: 9,
                to: 17,
            });
            onChangeGroup(
                {
                    activeDays: nextActiveDays,
                },
                index,
            );
        },
        [shiftPlan],
    );

    const removeDayFromGroup = useCallback(
        (day: number, index: number) => {
            const nextActiveDays = [...shiftPlan.groups[index].activeDays];
            nextActiveDays.splice(
                nextActiveDays.findIndex((d) => d.day === day),
                1,
            );
            onChangeGroup(
                {
                    activeDays: nextActiveDays,
                },
                index,
            );
        },
        [shiftPlan],
    );

    return (
        <View>
            <CCard>
                <CText
                    secondaryHeadline
                    message={shiftPlanMessages.configureGroups}
                />
            </CCard>
            {!shiftPlan.groups.length && (
                <View style={style.centeredItems}>
                    <CText
                        secondaryHeadline
                        message={shiftPlanMessages.noGroups}
                    />
                </View>
            )}
            {shiftPlan.groups.map((group, gIndex) => (
                <CCard key={gIndex} style={style.verticalPadded}>
                    <View style={style.horizontal}>
                        <View style={style.flex1}>
                            <CTextInput
                                label={shiftPlanMessages.groupTitle}
                                value={group.name}
                                onChangeText={(name) =>
                                    onChangeGroup({ name }, gIndex)
                                }
                                autoExtend
                            />
                            <View style={style.horizontalSpaced}>
                                {Object.keys(dayMessages)
                                    .filter((v) => !v.includes('Short'))
                                    .map((key, i) => {
                                        const day = group.activeDays.find(
                                            (d) => d.day === i,
                                        );
                                        return (
                                            <View
                                                key={key}
                                                style={style.flex1}
                                                onLayout={(e) =>
                                                    !i &&
                                                    setDayWidth(
                                                        e.nativeEvent.layout
                                                            .width,
                                                    )
                                                }
                                            >
                                                <CCheckBox
                                                    title={
                                                        dayMessages[
                                                            key as keyof typeof dayMessages
                                                        ]
                                                    }
                                                    checked={!!day}
                                                    onCheckedChanged={(check) =>
                                                        check
                                                            ? addDayToGroup(
                                                                  i,
                                                                  gIndex,
                                                              )
                                                            : removeDayFromGroup(
                                                                  i,
                                                                  gIndex,
                                                              )
                                                    }
                                                />
                                                {day && (
                                                    <DayInput
                                                        week={shiftPlan.week}
                                                        year={shiftPlan.year}
                                                        day={day}
                                                        onChangeDay={(next) => {
                                                            changeDay(
                                                                {
                                                                    ...next,
                                                                    day: day.day,
                                                                },
                                                                gIndex,
                                                                i,
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </View>
                                        );
                                    })}
                            </View>
                            {!!ExtensionComponent ? (
                                <ExtensionComponent
                                    {...{
                                        gIndex,
                                        dayWidth,
                                        shiftPlan,
                                        extension,
                                        setPlan,
                                        setEdit,
                                        goForward,
                                        goBackward,
                                    }}
                                />
                            ) : (
                                <>
                                    <CTextInput
                                        label={shiftPlanMessages.workerCount}
                                        type="numeric"
                                        onChangeText={(t) =>
                                            !isNaN(+t) &&
                                            onChangeGroup(
                                                { workerCount: +t },
                                                gIndex,
                                            )
                                        }
                                        value={`${group.workerCount}`}
                                    />
                                    <CCheckBox
                                        onCheckedChanged={(c) =>
                                            onChangeGroup(
                                                { noBreaks: c },
                                                gIndex,
                                            )
                                        }
                                        checked={group.noBreaks}
                                        title={shiftPlanMessages.noBreaks}
                                    />
                                </>
                            )}
                        </View>
                        <CButton
                            style={{ marginTop: 34 }}
                            onPress={() => removeGroup(gIndex)}
                            icon={'close'}
                            transparent
                            minor
                            iconColor={theme.errorColor}
                        />
                    </View>
                </CCard>
            ))}
            <View style={style.verticalPadded}>
                <View
                    style={[
                        style.horizontal,
                        style.verticalPadded,
                        { justifyContent: 'center' },
                    ]}
                >
                    <CButton
                        icon="plus"
                        title={shiftPlanMessages.addGroup}
                        onPress={() =>
                            setPlan((prev) => ({
                                ...prev,
                                groups: [...prev.groups, new MShiftGroup()],
                            }))
                        }
                        minor
                    />
                </View>
                <View style={[style.horizontal, { justifyContent: 'center' }]}>
                    <CButton
                        title={actionMessages.back}
                        onPress={goBackward}
                        warning
                    />
                    <CButton
                        title={actionMessages.continue}
                        onPress={goForward}
                    />
                </View>
            </View>
        </View>
    );
};
