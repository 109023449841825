import React, {
    FC,
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { View } from 'react-native';
import { useParams, useSecureNavigate } from '../../utilities/routing';
import {
    MContract,
    MContractFile,
    MFile,
    MProfessionalProfile,
    MShift,
    MTimesheet,
    MWorkplace,
} from '../../models';
import {
    CButton,
    CCard,
    CPicker,
    CText,
    Signature,
    Spinner,
} from '../../components';
import { useFireBase } from '../../utilities/firebase';
import {
    ECollections,
    ELeaveType,
    EPureContractFiles,
    ETimesheetType,
} from '../../enums';
import { useStyle } from '../../utilities/styles';
import {
    actionMessages,
    generalMessages,
    monthMessages,
} from '../../utilities/messages';
import { ShiftRow } from './components/ShiftRow';
import { ShiftRowEdit } from './components/ShiftRowEdit';
import { timesheetMessages } from './timesheet.messages';
import { useDialog, useUnsavedChangesDialog } from '../../utilities/dialog';
import { useFileUpload, useLock } from '../../utilities/hooks';
import {
    convertWeekAndYearToDate,
    day,
    getWeekNumber,
    hour,
    weeksInYear,
} from '../../utilities/functions';
import { ContractTitle } from '../Contract/components/ContractTitle';
import { ScrollProvider } from '../../utilities/contexts';
import { capitalize, useFormat } from '../../utilities/intl';
import { isAgencyUser } from '../../utilities/auth';
import { useFilePicker } from 'use-file-picker';
import { usePostContractFile } from '../Contract/View/functions/usePostContractFile';
/**
 * Time tracking view
 * > https://trello.com/c/3ulgKNrj/289-timetracking-app-bauen-stundenzettel-designen-und-zu-contract-hochladen
 */
export const Timesheet: FC<{
    embedded?: boolean;
    signOff?: boolean;
    timesheetId?: string;
    onComplete?: (mail?: string, url?: string) => void;
}> = ({ embedded, signOff, timesheetId: propsTimesheetId, onComplete }) => {
    // global state
    const { lock } = useLock();
    const style = useStyle();
    const format = useFormat();
    const dialog = useDialog();
    const { secureNavigate, setNavigationLock } = useSecureNavigate();
    const { contractId, timesheetId: paramsTimesheetId } = useParams();
    const { getDataById, getDataIndex, callFunction, userData, post } =
        useFireBase();
    const [openFileSelector, { filesContent, clear }] = useFilePicker({
        accept: ['.jpg', '.png', '.pdf', '.jpeg'],
        multiple: false,
        readAs: 'ArrayBuffer',
    });
    // local state
    const timesheetId = useMemo(
        () => propsTimesheetId || paramsTimesheetId,
        [propsTimesheetId, paramsTimesheetId],
    );
    const [contract, setContract] = useState<MContract>();
    const [workplace, setWorkplace] = useState<MWorkplace>();
    const [timesheet, setTimesheet] = useState<MTimesheet>();
    const [file, setFile] = useState<MContractFile>();
    const [shifts, setShifts] = useState<MShift[]>([]);
    const [editingIndexes, setEditingIndexes] = useState<number[]>([]);
    const [cellWidth, setCellWidth] = useState(0);
    const handleFile = usePostContractFile(contract, (n) => console.log(n));
    /**
     * memoized check if timesheet can be edited or not
     */
    const canEdit = useMemo(() => {
        return !file && !timesheet?.complete;
    }, [timesheet, file]);
    /**
     * memoized selected month calendar weeks
     */
    const calendarWeeks = useMemo(() => {
        if (!timesheet) return [];
        const yoi = timesheet.year || new Date().getFullYear();
        const moi =
            timesheet.month !== undefined
                ? timesheet.month
                : new Date().getMonth();
        const thisYearsWeeks = Array.from(Array(weeksInYear(yoi))).map(
            (_, i) => {
                const inMonth =
                    convertWeekAndYearToDate(yoi, i).getMonth() === moi;
                return {
                    value: i,
                    label: `${i}`,
                    disabled: !inMonth,
                    hidden: !inMonth,
                };
            },
        );
        return thisYearsWeeks;
    }, [timesheet]);
    /**
     * memoized working time sum for current shifts
     */
    const netSum = useMemo(() => {
        return (
            shifts
                .filter((s) => s.leaveType === ELeaveType.none)
                .reduce((acc, s) => {
                    const shiftLength = s.to - s.from; // -
                    const breakLength = s.breaks.reduce(
                        (acc, b) => acc + (b.to - b.from),
                        0,
                    );
                    const shiftLengthWithoutBreaks = shiftLength - breakLength;
                    return acc + shiftLengthWithoutBreaks;
                }, 0) / hour
        );
    }, [shifts]);
    /**
     * memoized sick time sum for current shifts
     */
    const sickNetSum = useMemo(() => {
        return (
            shifts
                .filter((s) => s.leaveType !== ELeaveType.none)
                .reduce((acc, s) => {
                    const shiftLength = s.to - s.from; // -
                    const breakLength = s.breaks.reduce(
                        (acc, b) => acc + (b.to - b.from),
                        0,
                    );
                    const shiftLengthWithoutBreaks = shiftLength - breakLength;
                    return acc + shiftLengthWithoutBreaks;
                }, 0) / hour
        );
    }, [shifts]);
    /**
     * add editing index
     */
    const setEditingIndex = useCallback((i: number) => {
        setEditingIndexes((prev) => Array.from(new Set([...prev, i])));
    }, []);
    /**
     * add editing index
     */
    const popEditingIndex = useCallback((i: number) => {
        setEditingIndexes((prev) => prev.filter((v) => v !== i));
    }, []);
    /**
     * callback to handle shhift change
     */
    const onChangeShift = useCallback(
        (index: number, next: Partial<MShift>) =>
            setShifts((prev) => {
                if (prev.length < index) return prev;
                /**
                 * new shift object with updated value
                 */
                const shoi = new MShift({ ...prev[index], ...next });
                /**
                 * recalculate from
                 */
                shoi.from = new Date(
                    +shoi.year,
                    +shoi.month - 1,
                    +shoi.date,
                    shoi.fromHour,
                    shoi.fromMinute,
                ).getTime();
                /**
                 * recalculate to
                 */
                shoi.to = new Date(
                    +shoi.year,
                    +shoi.month - 1,
                    +shoi.date,
                    shoi.toHour,
                    shoi.toMinute,
                ).getTime();
                /**
                 * create new array
                 */
                const toReturn = [...prev];
                /**
                 * set index to new shift object
                 */
                toReturn[index] = shoi;

                return toReturn;
            }),
        [],
    );
    /**
     * callback to add a new shift
     */
    const addShift = useCallback(() => {
        if (!timesheet) return;
        setShifts((prev) => {
            let nextShiftDate =
                timesheet.type === ETimesheetType.weekly
                    ? convertWeekAndYearToDate(
                          new Date().getFullYear(),
                          timesheet.week || 0,
                      )
                    : new Date(new Date().getFullYear(), timesheet.month || 0);
            if (prev.length) {
                nextShiftDate = new Date(prev[prev.length - 1].from + day);
            }
            const date = nextShiftDate.getDate();
            const month = nextShiftDate.getMonth();
            const year = nextShiftDate.getFullYear();
            return [
                ...prev,
                new MShift({
                    date: `${date}`,
                    month: `${month + 1}`,
                    year: `${year}`,
                    fromHour: 9,
                    fromMinute: 0,
                    from: new Date(year, month, date, 9, 0).getTime(),
                    toHour: 17,
                    toMinute: 0,
                    to: new Date(year, month, date, 17, 0).getTime(),
                    complete: true,
                }),
            ];
        });
    }, [timesheet]);
    /**
     * callback to remove a shift from index
     */
    const removeShift = useCallback(
        (index: number) => {
            popEditingIndex(index);
            setShifts((prev) => {
                const next = [...prev];
                next.splice(index, 1);
                return next;
            });
        },
        [popEditingIndex],
    );
    /**
     * callback to handle timesheet save action
     */
    const handleSave = useCallback(
        async (signature?: string) => {
            if (!timesheet) return;
            const complete = false;
            let pdf = false;
            let signeeMail =
                timesheet.workplaceLeadMail ||
                contract?.workplaceLeadMail ||
                workplace?.leadMail;
            let qr = false;
            let ok = true;
            let upload = false;
            if (!signature) {
                /**
                 * ask agency user / initial timesheet creator to mark timesheet as complete and or create a pdf
                 */
                ok = await dialog({
                    icon: 'question',
                    title: timesheetMessages.saveAndComplete,
                    message: timesheetMessages.saveAndComplete,
                    buttons: [
                        // ! disabled save & complete since
                        // {
                        //     text: timesheetMessages.saveAndComplete,
                        //     onPress: () => (complete = true),
                        //     disabled: () => timesheet.complete,
                        // },
                        {
                            text: actionMessages.save,
                            disabled: () => timesheet.complete,
                        },
                        {
                            text: timesheetMessages.createTimesheetDocument,
                            onPress: () => (pdf = true),
                        },
                        {
                            text: timesheetMessages.saveWithCustomDocument,
                            onPress: () => (upload = true),
                        },
                    ],
                    verticalButtons: true,
                });
            }
            if (!ok) return;
            if (complete) {
                /**
                 * confirm completion of timesheet
                 */
                ok = !(await dialog({
                    icon: 'warning',
                    title: timesheetMessages.confirmComplete,
                    message: timesheetMessages.confirmCompleteText,
                    buttons: [{ text: actionMessages.confirm }],
                    cancelButton: { text: actionMessages.cancel },
                }));
            }
            if (!ok) return;
            if (signOff) {
                /**
                 * ask signee for secondary handling of signature
                 */
                ok = await dialog({
                    title: timesheetMessages.secondarySigneeMailTitle,
                    message: timesheetMessages.secondarySigneeMailText,
                    textInputs: [
                        {
                            id: 'signeeMail',
                            title: timesheetMessages.secondarySigneeMail,
                            defaultValue: signeeMail,
                        },
                    ],
                    buttons: [
                        // TODO: re-enable skip / have a proper authenticated handling for this whole process
                        // {
                        //     text: timesheetMessages.skipSecondary,
                        //     onPress: () => {
                        //         signeeMail = undefined;
                        //     },
                        // },
                        {
                            text: timesheetMessages.sendMail,
                            onPress: (inputs) => {
                                signeeMail = inputs?.find(
                                    (i) => i.id === 'signeeMail',
                                )?.value;
                            },
                        },
                        {
                            text: timesheetMessages.showQrCode,
                            onPress: () => {
                                qr = true;
                            },
                        },
                    ],
                    cancelButton: { text: actionMessages.cancel },
                });
            }
            if (!ok) return;
            const unlock = lock();
            /**
             * start saving of timesheet
             */
            const nextDocId = await callFunction('saveTimesheet', {
                ...timesheet,
                shifts,
            });
            setTimesheet(
                (prev) =>
                    new MTimesheet({ ...prev, documentId: `${nextDocId}` }),
            );
            let result: any = {};
            /**
             * create timesheet file if signed or whish for pdf
             */
            if (pdf || signature) {
                result = await callFunction('createTimesheetFile', {
                    contractId: timesheet.contractId,
                    timesheetId: timesheet.documentId || nextDocId,
                    signature,
                    signeeMail,
                    forceExpose: !!qr,
                });
            }
            unlock();
            /**
             * open file selector to init a file upload which in turn triggers a effect to upload and assign the file
             */
            if (upload) {
                openFileSelector();
                return;
            }
            if (onComplete) {
                onComplete(!qr ? signeeMail : undefined, result.url);
            } else if (!embedded) {
                secureNavigate(-1, { force: true });
            } else {
                secureNavigate('/', { force: true });
            }
        },
        [timesheet, shifts, signOff, workplace, contract],
    );
    /**
     * callback to issue expose of current sheet
     * TODO: expose in a meaningfull flow
     */
    const exposeTimesheet = useCallback(async () => {
        let mail = '';
        if (contract && contract.profileId) {
            const unlock = lock();
            const p = await getDataById(
                ECollections.profProfiles,
                contract.profileId,
            );
            const profile = new MProfessionalProfile(p);
            if (profile.mail) {
                mail = p.mail;
            }
            unlock();
        }
        const ok = await dialog({
            icon: 'rocketman',
            title: timesheetMessages.enterEmployeeMail,
            message: timesheetMessages.enterEmployeeMailDesc,
            textInputs: [
                { id: 'mail', title: generalMessages.mail, defaultValue: mail },
            ],
            buttons: [
                {
                    text: actionMessages.expose,
                    disabled: (inputs) =>
                        !inputs?.find(({ id }) => id === 'mail')?.value,
                    onPress: (inputs) =>
                        (mail = inputs?.find(({ id }) => id === 'mail')?.value),
                },
            ],
            cancelButton: { text: actionMessages.cancel },
        });
        if (!ok) return;
        const unlock = lock();
        const nextDocId = await callFunction('saveTimesheet', {
            ...timesheet,
            exposed: true,
            shifts,
            mail,
        });
        console.log(timesheet?.contractId, nextDocId);
        unlock();
        await dialog({
            title: timesheetMessages.exposeSuccess,
            message: format(timesheetMessages.exposeSuccessMessage, { mail }),
            icon: 'success',
        });
        secureNavigate(-1, { force: true });
    }, [timesheet, shifts]);
    /**
     * callback to remove timesheet
     */
    const removeTimesheet = useCallback(async () => {
        if (!timesheet?.documentId) return;
        let ok = await dialog({
            title: timesheetMessages.deleteTimesheet,
            message: timesheetMessages.deleteTimesheetMessage,
            buttons: [{ text: actionMessages.ok }],
            cancelButton: { text: actionMessages.cancel },
            icon: 'question',
        });
        if (ok && file?.signaturePositions.filter((s) => !s.signed).length) {
            ok = await dialog({
                title: timesheetMessages.deleteTimesheetSigned,
                message: timesheetMessages.deleteTimesheetMessageSigned,
                buttons: [{ text: actionMessages.ok }],
                cancelButton: { text: actionMessages.cancel },
                icon: 'error',
            });
        }
        if (!ok) return;
        const unlock = lock();
        await callFunction('removeTimesheet', {
            timesheetId: timesheet.documentId,
            contractId: timesheet.contractId,
        });
        unlock();
        secureNavigate(-1, { force: true });
    }, [timesheet, shifts]);
    /**
     * unsaved changes dialog
     */
    const unsavedChangesDialog = useUnsavedChangesDialog();
    /**
     * effect to load the contract the time sheet is for
     */
    useEffect(() => {
        if (!contractId || embedded) return;

        getDataById(ECollections.contracts, contractId).then((c) =>
            setContract(new MContract(c)),
        );
    }, [contractId]);
    /**
     * effect to load contractworkpalce
     */
    useEffect(() => {
        const wpId = contract?.workplaceId || timesheet?.workplaceId;
        if (!wpId) return;
        getDataById(ECollections.publicWorkplaces, wpId).then((res) => {
            const next = new MWorkplace(res);
            setWorkplace(next);
        });
    }, [contract, timesheet]);
    /**
     * effect to load all data related to contract after contract did load
     */
    useEffect(() => {
        if (!embedded && contract && !timesheetId) {
            const now = new Date();
            const week = getWeekNumber(now);
            setTimesheet(
                new MTimesheet({
                    contractId: contract.documentId,
                    agencyId: contract.agencyId,
                    employeeId: contract.employeeId,
                    workplaceId: contract.workplaceId,
                    workplaceLeadMail: contract.workplaceLeadMail || undefined,
                    type: contract.timesheetType,
                    week,
                    year: now.getFullYear(),
                    month: now.getMonth(),
                }),
            );
        } else if (!embedded && !contract) {
            return;
        } else if (timesheetId && contractId) {
            const timesheetCollection = `${ECollections.contracts}/${contractId}/${ECollections.timesheets}`;
            getDataById(timesheetCollection, timesheetId).then((ts) => {
                const next = new MTimesheet(ts);
                setTimesheet(next);
            });
        }
    }, [contract, contractId, timesheetId, embedded]);
    /**
     * effect to load current shifts
     */
    useEffect(() => {
        if (timesheet?.documentId) {
            getDataIndex(
                `${ECollections.contracts}/${contractId}/${ECollections.timesheets}/${timesheet.documentId}/${ECollections.shifts}`,
            ).then((ss) =>
                setShifts(
                    (ss as MShift[])
                        .map((s) => new MShift(s))
                        .sort(
                            (a, b) =>
                                +(
                                    a.month +
                                    (+a.date).toLocaleString('de', {
                                        minimumIntegerDigits: 2,
                                    })
                                ) -
                                +(
                                    b.month +
                                    (+b.date).toLocaleString('de', {
                                        minimumIntegerDigits: 2,
                                    })
                                ),
                        ),
                ),
            );
        } else if (timesheet && !timesheet.documentId && contract) {
            const from =
                timesheet.type === ETimesheetType.weekly
                    ? convertWeekAndYearToDate(
                          new Date().getFullYear(),
                          timesheet.week || 0,
                      )
                    : new Date(new Date().getFullYear(), timesheet.month || 0);
            const to = new Date(from);
            if (timesheet.type === ETimesheetType.montly) {
                to.setMonth(to.getMonth() + 1);
            } else if (timesheet.type === ETimesheetType.weekly) {
                to.setDate(to.getDate() + 7);
            }
            /**
             * init default shifts
             */
            setShifts(
                contract.activeDates
                    .filter((s) => {
                        if (timesheet.type === ETimesheetType.undefined)
                            return true;
                        const ss = s.split('.');
                        const d = new Date(+ss[2], +ss[1] - 1, +ss[0]);
                        return (
                            d.getTime() >= from.getTime() &&
                            d.getTime() < to.getTime()
                        );
                    })
                    .map((s) => {
                        const ss = s.split('.');
                        const d = new Date(+ss[2], +ss[1] - 1, +ss[0]);
                        const data = contract.days.find(
                            (v) => v.day === d.getDay(),
                        );
                        return new MShift({
                            date: d.getDate().toString(),
                            month: (d.getMonth() + 1).toString(),
                            year: d.getFullYear().toString(),
                            fromHour: data?.from || 9,
                            fromMinute: 0,
                            from: new Date(
                                +ss[2],
                                +ss[1] - 1,
                                +ss[0],
                                data?.from || 9,
                                0,
                            ).getTime(),
                            toHour: data?.to || 17,
                            toMinute: 0,
                            to: new Date(
                                +ss[2],
                                +ss[1] - 1,
                                +ss[0],
                                data?.to || 17,
                                0,
                            ).getTime(),
                            complete: true,
                        });
                    }),
            );
        }
    }, [timesheet, contract, contractId]);
    /**
     * effect to handle file upload post timesheet save (exits view)
     */
    useEffect(() => {
        if (!filesContent.length || !timesheet?.documentId) return;
        const f = filesContent[0];
        handleFile(f.name, f.content as any, {
            nextType: EPureContractFiles.timesheet,
        }).then(async (fid) => {
            if (!fid)
                return dialog({
                    title: timesheetMessages.noFile,
                    icon: 'error',
                });
            const unlock = lock();
            await callFunction('appendTimesheetFile', {
                fid,
                timesheetId: timesheet?.documentId,
                contractId,
            });
            unlock();
            // TODO: fix on complete
            // if (onComplete) {
            //     onComplete(!qr ? signeeMail : undefined, result.url);
            // } else
            if (!embedded) {
                secureNavigate(-1, { force: true });
            } else {
                secureNavigate('/', { force: true });
            }
        });
    }, [filesContent, embedded, contractId, timesheet]);
    /**
     * effect to load timesheet file ref if present
     */
    useEffect(() => {
        if (timesheet && timesheet.fileId && timesheet.contractId) {
            getDataById(
                `${ECollections.contracts}/${timesheet.contractId}/${ECollections.files}`,
                timesheet.fileId,
            ).then((f) => setFile(new MContractFile(f)));
        }
    }, [timesheet]);
    /**
     * set navigation lock
     */
    useEffect(
        () =>
            setNavigationLock(
                async () => canEdit && !(await unsavedChangesDialog()),
            ),
        [unsavedChangesDialog],
    );
    /**
     * return spinner during load
     */
    if (!timesheet) {
        return <Spinner />;
    }
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            {!embedded && (
                <View style={[style.headlineCard, style.horizontalSplit]}>
                    <View style={[style.horizontal, style.centeredItems]}>
                        <CButton
                            cy={'back'}
                            onPress={async () => {
                                secureNavigate(-1);
                            }}
                            icon={'chevronLeft'}
                            small
                        />
                        <CText
                            style={style.leftPadded}
                            message={timesheetMessages.timesheet}
                            headline
                        >
                            :
                        </CText>
                        {contract && (
                            <ContractTitle
                                contract={contract}
                                horizontalPadded
                            />
                        )}
                    </View>
                    {!!timesheet.documentId &&
                        !file?.signaturePositions.length &&
                        !file?.signaturePositions.filter((s) => !s.signed)
                            .length && (
                            <CButton
                                onPress={removeTimesheet}
                                icon="minus"
                                title={actionMessages.delete}
                                small
                                danger
                            />
                        )}
                </View>
            )}
            {timesheet.documentId ? (
                timesheet.type !== ETimesheetType.undefined && (
                    <CCard embedded={embedded} style={style.horizontal}>
                        <View style={style.horizontal}>
                            <CText message={timesheetMessages.interval} />
                            <CText
                                style={style.horizontalPadded}
                                message={
                                    timesheetMessages[
                                        timesheet.type === ETimesheetType.montly
                                            ? 'intervalMonthly'
                                            : timesheet.type ===
                                              ETimesheetType.weekly
                                            ? 'intervalWeekly'
                                            : 'intervalUndefined'
                                    ]
                                }
                            />
                        </View>
                        <View style={style.horizontal}>
                            {timesheet.month !== undefined && (
                                <View style={style.horizontal}>
                                    <CText message={generalMessages.month} />
                                    <CText
                                        style={style.horizontalPadded}
                                        message={
                                            monthMessages[
                                                Object.keys(
                                                    monthMessages,
                                                ).filter(
                                                    (k) => !k.includes('Short'),
                                                )[
                                                    timesheet.month
                                                ] as keyof typeof monthMessages
                                            ]
                                        }
                                    />
                                </View>
                            )}
                            {timesheet.week !== undefined &&
                                timesheet.type === ETimesheetType.weekly && (
                                    <View style={style.horizontal}>
                                        <CText message={generalMessages.week} />
                                        <CText
                                            style={style.horizontalPadded}
                                            message={`${timesheet.week}`}
                                        />
                                    </View>
                                )}
                        </View>
                    </CCard>
                )
            ) : (
                <CCard embedded={embedded} style={style.horizontal}>
                    <CPicker
                        title={timesheetMessages.interval}
                        values={[
                            {
                                value: ETimesheetType.montly,
                                label: timesheetMessages.intervalMonthly,
                            },
                            {
                                value: ETimesheetType.weekly,
                                label: timesheetMessages.intervalWeekly,
                            },
                            {
                                value: ETimesheetType.undefined,
                                label: timesheetMessages.intervalUndefined,
                            },
                        ]}
                        value={timesheet.type}
                        onChange={(next) =>
                            setTimesheet(
                                (prev) =>
                                    new MTimesheet({
                                        ...prev,
                                        type: next as ETimesheetType,
                                    }),
                            )
                        }
                    />
                    {timesheet && timesheet.type !== ETimesheetType.undefined && (
                        <CPicker
                            title={generalMessages.month}
                            value={(timesheet.month || 0) + 1} // ! +1 to avoid placeholder on month 0
                            values={Object.keys(monthMessages)
                                .filter((m) => !m.includes('Short'))
                                .map((m, i) => ({
                                    label: monthMessages[
                                        m as keyof typeof monthMessages
                                    ],
                                    value: i + 1,
                                }))}
                            onChange={(v) =>
                                setTimesheet(
                                    (prev) =>
                                        new MTimesheet({
                                            ...prev,
                                            month: v - 1,
                                        }),
                                )
                            }
                        />
                    )}
                    {timesheet && timesheet.type === ETimesheetType.weekly && (
                        <CPicker
                            title={generalMessages.week}
                            value={timesheet.week || 0}
                            values={calendarWeeks}
                            onChange={(v) =>
                                setTimesheet(
                                    (prev) =>
                                        new MTimesheet({
                                            ...prev,
                                            week: v,
                                        }),
                                )
                            }
                        />
                    )}
                </CCard>
            )}
            {!!shifts.length && (
                <CCard embedded={embedded}>
                    <View style={style.horizontalSplit}>
                        <View
                            style={[style.centeredItems, { width: cellWidth }]}
                        >
                            <CText
                                secondaryHeadline
                                message={timesheetMessages.date}
                            />
                        </View>
                        <View
                            style={[style.centeredItems, { width: cellWidth }]}
                        >
                            <CText
                                secondaryHeadline
                                message={timesheetMessages.from}
                            />
                        </View>
                        <View
                            style={[style.centeredItems, { width: cellWidth }]}
                        >
                            <CText
                                secondaryHeadline
                                message={timesheetMessages.breaks}
                            />
                        </View>
                        <View
                            style={[style.centeredItems, { width: cellWidth }]}
                        >
                            <CText
                                secondaryHeadline
                                message={timesheetMessages.to}
                            />
                        </View>
                    </View>
                </CCard>
            )}
            {shifts.map((shift, i) => (
                <Fragment key={i}>
                    {editingIndexes.includes(i) ? (
                        <ShiftRowEdit
                            key={i}
                            onChange={(next) => onChangeShift(i, next)}
                            onRemove={() => removeShift(i)}
                            shift={shift}
                            cellWidth={cellWidth}
                            toggleEdit={
                                canEdit ? () => popEditingIndex(i) : undefined
                            }
                        />
                    ) : (
                        <ShiftRow
                            key={i}
                            shift={shift}
                            toggleEdit={
                                canEdit ? () => setEditingIndex(i) : undefined
                            }
                            onLayout={(e) => {
                                setCellWidth(e.nativeEvent.layout.width / 4);
                            }}
                            onChange={(next) => onChangeShift(i, next)}
                            cellWidth={cellWidth}
                            preventHover={!canEdit}
                        />
                    )}
                </Fragment>
            ))}
            <CCard>
                <View style={style.horizontal}>
                    <CText
                        secondaryHeadline
                        message={timesheetMessages.netSum}
                    />
                    <CText
                        secondaryHeadline
                        message={`${netSum.toFixed(2)}`}
                        style={style.leftPadded}
                    />
                    <CText
                        secondaryHeadline
                        message={generalMessages.hours}
                        style={style.leftPadded}
                    />
                </View>
                {/* {!!sickNetSum && (
                    <View style={style.horizontal}>
                        <CText
                            secondaryHeadline
                            message={timesheetMessages.sickNetSum}
                        />
                        <CText
                            secondaryHeadline
                            message={`${sickNetSum.toFixed(2)}`}
                            style={style.leftPadded}
                        />
                        <CText
                            secondaryHeadline
                            message={generalMessages.hours}
                            style={style.leftPadded}
                        />
                    </View>
                )} */}
            </CCard>
            {signOff ? (
                <View>
                    <View
                        style={[
                            style.verticalPadded,
                            { marginHorizontal: 'auto' },
                        ]}
                    >
                        <CButton
                            onPress={addShift}
                            title={timesheetMessages.addShift}
                            icon="plus"
                        />
                    </View>
                    <View style={[style.verticalPadded]}>
                        <Signature
                            text={capitalize(format(actionMessages.sign))}
                            onOK={handleSave}
                            okTitle={timesheetMessages.createSignedDocument}
                        />
                    </View>
                </View>
            ) : (
                canEdit && (
                    <View
                        style={[
                            style.verticalPadded,
                            { marginHorizontal: 'auto' },
                        ]}
                    >
                        <CButton
                            onPress={addShift}
                            title={timesheetMessages.addShift}
                            icon="plus"
                        />
                        <CButton
                            onPress={handleSave}
                            title={actionMessages.save}
                            disabled={!!editingIndexes.length}
                        />
                        {isAgencyUser(userData) && (
                            <CButton
                                onPress={exposeTimesheet}
                                title={timesheetMessages.expose}
                                disabled={!!editingIndexes.length}
                            />
                        )}
                    </View>
                )
            )}
        </ScrollProvider>
    );
};
