import { useContext } from 'react';
import { FireAppContext } from './app';
import { FireAuthContext } from './auth';

import { FireBaseContext } from './FireBaseContext';
import { FireFunctionContext } from './function';
import { FireStorageContext } from './storage';
import { FirestoreContext } from './store';

export const useFireBase = () => {
    const appContext = useContext(FireAppContext);
    const authContext = useContext(FireAuthContext);
    const functionContext = useContext(FireFunctionContext);
    const sorageContext = useContext(FireStorageContext);
    const dbContext = useContext(FirestoreContext);
    const stateContext = useContext(FireBaseContext);

    return {
        ...appContext,
        ...authContext,
        ...functionContext,
        ...sorageContext,
        ...dbContext,
        ...stateContext,
    };
};

export type useFirebaseReturnType = ReturnType<typeof useFireBase>;
