import React, { FC, useCallback } from 'react';
import { View } from 'react-native';
import { CText, TouchableView } from '../../../../components';
import { MShiftWorker } from '../../../../models';
import { useStyle } from '../../../../utilities/styles';
import { IWorkerExtensionProps } from '../IShiftPlanComponentProps';
import { childcareShiftPlanMessages } from './childcareShiftPlan.messages';

export const ChildCareWorkerExtension: FC<IWorkerExtensionProps> = ({
    shiftPlan,
    setPlan,
    wIndex,
}) => {
    const style = useStyle();
    const onChangeWorker = useCallback(
        (next: Partial<MShiftWorker>, index: number) =>
            setPlan((prev) => {
                const nextWorkers = [...prev.workers];
                nextWorkers.splice(index, 1, {
                    ...nextWorkers[index],
                    ...next,
                });
                return { ...prev, workers: nextWorkers };
            }),
        [],
    );
    /**
     * render
     * TODO: add texts
     */
    return (
        <View>
            <View style={style.horizontalSpaced}>
                <TouchableView
                    onPress={() => onChangeWorker({ level: 3 }, wIndex)}
                    style={[
                        {
                            borderRadius: 5,
                            padding: 10,
                            borderWidth: 1,
                            borderColor: 'transparent',
                        },
                        shiftPlan.workers[wIndex].level === 3 &&
                            style.accentBorder,
                    ]}
                >
                    <CText message={childcareShiftPlanMessages.level3} />
                    <CText
                        message={childcareShiftPlanMessages.level3Description}
                    />
                </TouchableView>
                <TouchableView
                    onPress={() => onChangeWorker({ level: 2 }, wIndex)}
                    style={[
                        {
                            borderRadius: 5,
                            padding: 10,
                            borderWidth: 1,
                            borderColor: 'transparent',
                        },
                        shiftPlan.workers[wIndex].level === 2 &&
                            style.accentBorder,
                    ]}
                >
                    <CText message={childcareShiftPlanMessages.level2} />
                    <CText
                        message={childcareShiftPlanMessages.level2Description}
                    />
                </TouchableView>
                <TouchableView
                    onPress={() => onChangeWorker({ level: 1 }, wIndex)}
                    style={[
                        {
                            borderRadius: 5,
                            padding: 10,
                            borderWidth: 1,
                            borderColor: 'transparent',
                        },
                        shiftPlan.workers[wIndex].level === 1 &&
                            style.accentBorder,
                    ]}
                >
                    <CText message={childcareShiftPlanMessages.level1} />
                    <CText
                        message={childcareShiftPlanMessages.level1Description}
                    />
                </TouchableView>
            </View>
        </View>
    );
};
