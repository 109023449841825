import React, { FC, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useFormat } from '../../../utilities/intl';
import { generalMessages } from '../../../utilities/messages';
import { useStyle, useTheme } from '../../../utilities/styles';
import { CButton } from '../CButton';
import { CText } from '../CText';
import { makeTableStyles } from './styles';

interface IRowProps {
    values: {
        key: string;
        value: string;
        bold?: boolean;
        color?: string;
        flex?: number;
    }[];
    onPress?: () => void;
    subRows?: IRowProps[];
    key?: string;
}

export interface ICTableProps {
    rows: (IRowProps | false | undefined | null)[];
    headers: ({ key: string; label?: string } | false | undefined | null)[];
    hideHeaders?: boolean;
    orderBy?: string;
    moreRows?: (IRowProps | false | undefined | null)[];
}

export const CTable: FC<ICTableProps> = ({
    rows,
    headers: propHeaders,
    // orderBy,
    hideHeaders,
    moreRows,
}) => {
    // global state
    const style = useStyle();
    const { theme } = useTheme();
    const format = useFormat();
    // local state
    const localStyle = makeTableStyles(theme);
    const [more, setMore] = useState(false);
    /**
     * memoized headers extracted and validated from props
     */
    const headers = useMemo(() => {
        return propHeaders.filter((h) => !!h) as {
            key: string;
            label?: string | undefined;
        }[];
    }, [propHeaders]);
    /**
     * render
     */
    return (
        <View>
            {!hideHeaders && (
                <View style={style.horizontal}>
                    {headers.map((header) => (
                        <View key={header.key} style={{ flexGrow: 1, flex: 1 }}>
                            <CText bold>{header.label || header.key}</CText>
                        </View>
                    ))}
                </View>
            )}
            {rows.map((row, i) => {
                if (row) {
                    return (
                        <View
                            key={row.key || i}
                            style={[style.horizontal, localStyle.row]}
                        >
                            {headers.map((header) => {
                                const value = row.values.find(
                                    (v) => v.key === header.key,
                                );
                                return (
                                    <View
                                        key={header.key}
                                        style={[
                                            {
                                                flexGrow: value?.flex || 1,
                                                flex: value?.flex || 1,
                                            },
                                            localStyle.column,
                                        ]}
                                    >
                                        <CText
                                            style={
                                                !!value?.color && {
                                                    color: value.color,
                                                }
                                            }
                                            bold={value?.bold}
                                        >
                                            {value ? value.value : 'not found'}
                                        </CText>
                                    </View>
                                );
                            })}
                        </View>
                    );
                }
            })}
            {moreRows && !more && (
                <CButton
                    transparent
                    minor
                    title={format(generalMessages.more)}
                    onPress={() => setMore(true)}
                />
            )}
            {moreRows &&
                more &&
                moreRows.map((row, i) => {
                    if (row) {
                        return (
                            <View
                                key={row.key || i}
                                style={[style.horizontal, localStyle.row]}
                            >
                                {headers.map((header) => {
                                    const value = row.values.find(
                                        (v) => v.key === header.key,
                                    );
                                    return (
                                        <View
                                            key={header.key}
                                            style={[
                                                { flex: value?.flex || 1 },
                                                localStyle.column,
                                            ]}
                                        >
                                            <CText
                                                style={
                                                    !!value?.color && {
                                                        color: value.color,
                                                    }
                                                }
                                                bold={value?.bold}
                                            >
                                                {value
                                                    ? value.value
                                                    : 'not found'}
                                            </CText>
                                        </View>
                                    );
                                })}
                            </View>
                        );
                    }
                })}
        </View>
    );
};
