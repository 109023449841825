import { defineMessages } from 'react-intl';

const scope = 'education.teacher.position.';
/**
 * teacher education levels
 * is used for teacherEducation picker
 */
export const teacherPositionMessages = defineMessages({
    preSchool: {
        id: `${scope}preSchool`,
        defaultMessage: 'Pre-School',
    },
    elementarySchool: {
        id: `${scope}elementarySchool`,
        defaultMessage: 'Elementary School',
    },
    middleSchool: {
        id: `${scope}middleSchool`,
        defaultMessage: 'Middle School',
    },
    highSchool: {
        id: `${scope}highSchool`,
        defaultMessage: 'High School',
    },
    college: {
        id: `${scope}college`,
        defaultMessage: 'College',
    },
    graduateSchool: {
        id: `${scope}graduateSchool`,
        defaultMessage: 'Graduate School',
    },
    specialEducation: {
        id: scope + 'specialEducation',
        defaultMessage: 'special education',
    },
});
