import { useCallback, useContext, useMemo } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { LanguageContext } from './CustomIntlProvider';

export const useFormat = () => {
    const { intl } = useContext(LanguageContext);

    return intl.formatMessage;
};

export default useFormat;

export const useAutoFormat = () => {
    const format = useFormat();

    const autoFormat = useCallback(
        (message: string | MessageDescriptor, values?: any) => {
            if (typeof message === 'string') {
                return message;
            } else {
                return format(message, values);
            }
        },
        [format],
    );

    return autoFormat;
};
