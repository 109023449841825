import React, { FC, useEffect } from 'react';
import { useDimensions } from '../../utilities/hooks/useDimensions';
import { useEnvironment } from '../../utilities/contexts';
import { EEnvironment, ERegion, EUserType } from '../../enums';
import { useFireBase } from '../../utilities/firebase';
import { Linking } from 'react-native';
import { CText } from '../../components';
/**
 * IFrame to display latepoint in app
 * @returns
 */
export const Latepoint: FC = () => {
    const { height } = useDimensions();
    const { environment, region } = useEnvironment();
    const { userData } = useFireBase();

    useEffect(() => {
        Linking.openURL(
            environment === EEnvironment.SITUS
                ? 'https://www.situsdocs.de/termin-buchen/'
                : region === ERegion.de
                ? userData.type === EUserType.agency
                    ? 'https://timeployees.de/termin-buchen-de-anu/'
                    : 'https://timeployees.de/termin-buchen-de-wp/'
                : 'https://timeployees.de/termin-buchen/',
        );
    }, []);
    /**
     * render
     * TODO: remove body and show placeholder that external site opens
     */
    return (
        <CText message={'opened in new window'} />
        // <iframe
        //     src={
        //         environment === EEnvironment.SITUS
        //             ? 'https://www.situsdocs.de/termin-buchen/'
        //             : region === ERegion.de
        //             ? userData.type === EUserType.agency
        //                 ? 'https://timeployees.de/termin-buchen-de-anu/'
        //                 : 'https://timeployees.de/termin-buchen-de-wp/'
        //             : 'https://timeployees.de/termin-buchen/'
        //     }
        //     height={height}
        //     style={{ borderWidth: 0, width: '100%' }}
        // />
    );
};
