import React, { FC, useCallback, useState } from 'react';
import { View } from 'react-native';
import {
    CButton,
    CCheckBox,
    CIcon,
    CText,
    CTextInput,
} from '../../../components';
import {
    EducationPicker,
    FieldPicker,
    ProfessionPicker,
} from '../../../components/Pickers';
import { EField, EProfession } from '../../../enums';
import { useStyle, useTheme } from '../../../utilities/styles';
import { useFormat } from '../../../utilities/intl';
import { startMessages } from '../../Start/start.messages';
import { urlHandlerMessages } from '../urlHandler.messages';
import { useFireBase } from '../../../utilities/firebase';
import { jobMessages } from '../../Job/job.messages';
import { day } from '../../../utilities/functions';
import { CDatePicker } from '../../../components';
import { MAIL_PROVIDER } from '../../../utilities/mailProvider';
import { StandaloneWrapper } from '../../../utilities/wrappers';
import { useNavigate } from '../../../utilities/routing';
import base64 from 'base-64';
import { actionMessages } from '../../../utilities/messages';

export const CreateJobUnverified: FC = () => {
    const style = useStyle();
    const { theme } = useTheme();
    const format = useFormat();
    const navigate = useNavigate();
    const { callFunction } = useFireBase();

    const [
        { mail, profession, field, education, from, to, fromNow },
        setState,
    ] = useState({
        mail: '',
        field: '',
        profession: '',
        education: '',
        from: Date.now() + 7 * day,
        to: Date.now() + 8 * day,
        fromNow: false,
    });
    const [complete, setComplete] = useState(false);
    const [accountExists, setAccountExists] = useState(false);
    const onChange = useCallback(
        (next: {
            mail?: string;
            field?: string;
            profession?: string;
            education?: string;
            from?: number;
            to?: number;
            fromNow?: boolean;
        }) => {
            setState({
                ...{ mail, profession, education, from, to, fromNow, field },
                ...next,
            });
        },
        [mail, profession, education, field],
    );

    const mailValidity = useCallback((mail: string) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            const mailsplit = mail.split('@');
            if (MAIL_PROVIDER.has(mailsplit[1])) {
                return format(urlHandlerMessages.invalidMailProvider);
            }
            return;
        } else {
            return format(urlHandlerMessages.invalidMailFormat);
        }
    }, []);

    if (accountExists) {
        return (
            <StandaloneWrapper>
                <View style={[style.centeredItems, style.verticalPadded]}>
                    <CIcon icon="close" size={48} tint={theme.errorColor} />
                </View>
                <View style={style.centeredItems}>
                    <CText
                        message={format(startMessages.registerUserExistsError)}
                    />
                </View>
                <View style={style.verticalPadded} />
                <View style={style.centeredItems}>
                    <CButton
                        title={format(actionMessages.login)}
                        onPress={() =>
                            navigate('/?login=true&m=' + base64.encode(mail))
                        }
                    />
                </View>
            </StandaloneWrapper>
        );
    }

    if (complete) {
        return (
            <StandaloneWrapper>
                <View style={[style.centeredItems, style.verticalPadded]}>
                    <CIcon icon="check" size={48} tint={theme.successColor} />
                </View>
                <View style={style.centeredItems}>
                    <CText
                        message={format(urlHandlerMessages.createdJobListing)}
                    />
                </View>
                <View style={style.verticalPadded} />
                <View style={style.centeredItems}>
                    <CButton
                        title={format(urlHandlerMessages.toRegistration)}
                        onPress={() => navigate('/?m=' + base64.encode(mail))}
                    />
                </View>
            </StandaloneWrapper>
        );
    }

    return (
        <StandaloneWrapper>
            <View
                style={[
                    style.horizontal,
                    style.centeredItems,
                    style.verticalPadded,
                ]}
            >
                <CIcon icon="alert" size={46} tint={theme.accentColor} />
                <View style={style.horizontalPadded} />
                <CText message={format(urlHandlerMessages.provideMail)} />
            </View>
            <CTextInput
                label={format(startMessages.mail)}
                autoExtend
                value={mail}
                onChangeText={(s) => onChange({ mail: s })}
                customCheckInvalidity={mailValidity}
            />
            {!!mail && !mailValidity(mail) && (
                <FieldPicker
                    value={field as EField}
                    onChange={(f) => onChange({ field: f })}
                />
            )}
            {!!field && (
                <ProfessionPicker
                    field={field as EField}
                    value={profession as EProfession}
                    onChange={(p) => onChange({ profession: p })}
                />
            )}
            {!!profession && (
                <EducationPicker
                    field={field as EField}
                    profession={profession as EProfession}
                    value={education}
                    onChange={(e) => onChange({ education: e as string })}
                />
            )}
            {!!education && (
                <>
                    <View style={style.verticalPadded}>
                        <CCheckBox
                            cy={'start-now'}
                            title={format(jobMessages.fromNow)}
                            checked={fromNow}
                            onCheckedChanged={(c) =>
                                onChange({
                                    fromNow: c,
                                    from: Date.now(),
                                    to: Date.now() + day,
                                })
                            }
                        />
                    </View>
                    {!fromNow && (
                        <View style={[style.horizontal, { zIndex: 23 }]}>
                            <CDatePicker
                                cy={'from'}
                                title={format(jobMessages.from)}
                                value={new Date(from)}
                                onChange={(next) => {
                                    const t = next.getTime();
                                    onChange({
                                        from: t,
                                        to: to >= t ? to : t + day,
                                    });
                                }}
                                zIndex={22}
                                minDate={Date.now()}
                            />
                            <CDatePicker
                                cy={'to'}
                                title={format(jobMessages.to)}
                                value={new Date(to)}
                                onChange={(next) =>
                                    onChange({ to: next.getTime() })
                                }
                                minDate={from}
                            />
                        </View>
                    )}
                </>
            )}
            {!!(profession && education && mail && from && to) && (
                <CButton
                    title={format(urlHandlerMessages.createJobListing)}
                    onPress={() => {
                        callFunction('createUnverifiedJob', {
                            mail,
                            profession,
                            education,
                            from,
                            to,
                        }).then((response) => {
                            if (response === 'mail already linked') {
                                setAccountExists(true);
                            }
                            setComplete(true);
                        });
                    }}
                    disableOnClick
                />
            )}
        </StandaloneWrapper>
    );
};
