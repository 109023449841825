import { useCallback } from 'react';
import { actionMessages, generalMessages } from '../messages';
import { useDialog } from './useDialog';
import { useTheme } from '../styles';
import { IDialogButton } from './IDialog';

export const useUnsavedChangesDialog = (
    saveCallback?: (skipNavigate?: boolean) => Promise<void>,
) => {
    const dialog = useDialog();
    const { theme } = useTheme();
    const adminDialog = useCallback(async () => {
        const buttons: IDialogButton[] = [
            {
                text: actionMessages.continueAndDiscard,
                color: theme.errorColor,
            },
        ];
        if (saveCallback) {
            buttons.push({
                text: actionMessages.continueAndSave,
                color: theme.successColor,
                onPress: () => saveCallback(true),
            });
        }
        return dialog({
            title: generalMessages.unsavedChanges,
            message: generalMessages.ifContinueTheyDiscard,
            cancelButton: {
                text: actionMessages.back,
            },
            buttons,
            verticalButtons: !!saveCallback,
            icon: 'warning',
        });
    }, [dialog, saveCallback]);

    return adminDialog;
};
