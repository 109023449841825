import React, { FC, useMemo } from 'react';
import { MShift } from '../../../models';
import { CCard, CText } from '../../../components';
import { LayoutChangeEvent, View } from 'react-native';
import { useStyle } from '../../../utilities/styles';
import { timeString } from '../../../utilities/functions';
import { dayMessages } from '../../../utilities/messages';
import { useFormat } from '../../../utilities/intl';
import { timesheetMessages } from '../timesheet.messages';
import { ELeaveType } from '../../../enums';

export interface IShiftRowProps {
    toggleEdit?: () => void;
    shift: MShift;
    onChange: (next: Partial<MShift>) => void;
    onRemove?: () => void;
    onLayout?: (e: LayoutChangeEvent) => void;
    cellWidth: number;
    preventHover?: boolean;
}

export const ShiftRow: FC<IShiftRowProps> = ({
    shift,
    toggleEdit,
    onLayout,
    cellWidth,
    preventHover,
}) => {
    const style = useStyle();
    const format = useFormat();
    /**
     * memoized day message
     */
    const dayMessage = useMemo(() => {
        const date = new Date(+shift.year, +shift.month - 1, +shift.date);
        const shortKeys = Object.keys(dayMessages).filter((key) =>
            key.includes('Short'),
        );
        const weekday = date.getDay() - 1;
        const koi = shortKeys[weekday >= 0 ? weekday : 6];
        return dayMessages[koi as keyof typeof dayMessages] || undefined;
    }, [shift]);
    /**
     * render
     */
    return (
        <CCard
            hoverEffects={!preventHover}
            onLayout={onLayout}
            onPress={toggleEdit}
        >
            <View
                style={[
                    style.horizontalSplit,
                    style.centeredItems,
                    { height: 50 },
                ]}
            >
                <View style={[style.centeredItems, { width: cellWidth }]}>
                    <CText
                        message={`${format(dayMessage)} ${shift.date}.${
                            shift.month
                        }.${shift.year}`}
                    />
                </View>
                {shift.leaveType !== ELeaveType.none && (
                    <View style={[style.centeredItems, style.flex1]}>
                        <CText message={timesheetMessages[shift.leaveType]} />
                    </View>
                )}
                {shift.leaveType === ELeaveType.none && (
                    <View style={[style.centeredItems, { width: cellWidth }]}>
                        <CText
                            message={timeString(
                                shift.fromHour,
                                shift.fromMinute,
                            )}
                        />
                    </View>
                )}
                {shift.leaveType === ELeaveType.none && (
                    <View style={[style.centeredItems, { width: cellWidth }]}>
                        {shift.breaks.map((b, i) => (
                            <View key={i}>
                                <CText
                                    message={`${timeString(
                                        b.fromHour,
                                        b.fromMinute,
                                    )} - ${timeString(b.toHour, b.toMinute)}`}
                                />
                            </View>
                        ))}
                    </View>
                )}
                {shift.leaveType === ELeaveType.none && (
                    <View style={[style.centeredItems, { width: cellWidth }]}>
                        <CText
                            message={timeString(shift.toHour, shift.toMinute)}
                        />
                    </View>
                )}
            </View>
            {!!shift.note && (
                <View style={style.horizontal}>
                    <CText message={timesheetMessages.note} />
                    <CText message=":" />
                    <CText message={shift.note} style={style.leftPadded} />
                </View>
            )}
        </CCard>
    );
};
