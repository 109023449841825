import React, { FC, useEffect, useState } from 'react';
import { useFilePicker } from 'use-file-picker';
import { CButton } from '../elements';
import { IFilePickerProps } from './IFilePickerProps';

export const FilePicker: FC<IFilePickerProps> = ({
    onFile,
    style,
    icon,
    disabled,
    preFile,
}) => {
    const [openFileSelector, { filesContent, clear }] = useFilePicker({
        accept: ['.jpg', '.png', '.pdf', '.jpeg'],
        multiple: false,
        readAs: 'ArrayBuffer',
    });
    const [preFileResult, setPrefileResult] = useState<any>();
    /**
     * effect to handle incomming files
     */
    useEffect(() => {
        if (filesContent.length) {
            const f = filesContent[0];
            onFile(f.name, f.content as any, preFileResult).then(() => {
                clear();
            });
        }
    }, [filesContent]);
    /**
     * render
     */
    return (
        <CButton
            minor
            icon={icon || 'upload'}
            onPress={async () => {
                const preFileResult = preFile ? await preFile() : true;
                if (preFileResult) {
                    setPrefileResult(preFileResult);
                    openFileSelector();
                }
            }}
            style={style}
            disabled={disabled}
        />
    );
};
