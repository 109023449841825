import React, { FC, useCallback, useState } from 'react';
import { useFireBase } from '../../../../../utilities/firebase';
import { ECollections } from '../../../../../enums';
import { MAvailability } from '../../../../../models';
import { CButton } from '../../../../../components';

export const TouchAvailabilities: FC = () => {
    const { getDataIndex, put } = useFireBase();

    const [touchingAvailabilities, setTouchingAvailabilities] = useState(false);
    /**
     * callback to touch all availabilities
     */
    const touchAvailabilities = useCallback(() => {
        setTouchingAvailabilities(true);
        getDataIndex(ECollections.availabilities).then((as) => {
            Promise.all(
                (as as MAvailability[]).map((a) => {
                    const next = new MAvailability(a);
                    return put(ECollections.availabilities, a.documentId, {
                        ...next,
                        touched: Date.now(),
                    });
                }),
            ).then(() => setTouchingAvailabilities(false));
        });
    }, [getDataIndex, put]);

    return (
        <CButton
            onPress={touchAvailabilities}
            title="Touch all availabilities"
            warning
            disabled={touchingAvailabilities}
        />
    );
};
