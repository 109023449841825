import React, { FC, useMemo } from 'react';
import { Platform, View } from 'react-native';
import { useNavigate } from '../../../utilities/routing';
import { CButton, CIcon, CText } from '../../../components';
import { CCarousel } from '../../../components/elements';
import { TouchableView } from '../../../components/TouchableView';
import { useFormat } from '../../../utilities/intl';
import { useStyle, useTheme } from '../../../utilities/styles';
import { dashboardMessages } from '../Dashboard.messages';
import { useEnvironment } from '../../../utilities/contexts';
import { isAgencyUser, isEmployer } from '../../../utilities/auth';
import { useFireBase } from '../../../utilities/firebase';
import { EUserType } from '../../../enums';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useDialog } from '../../../utilities/dialog';
import { actionMessages } from '../../../utilities/messages';

export const HintCarousel: FC<{ changeHidden: () => void }> = ({
    changeHidden,
}) => {
    const dialog = useDialog();
    const format = useFormat();
    const style = useStyle();
    const { theme } = useTheme();
    const { environment } = useEnvironment();
    const navigate = useNavigate();
    const { userData } = useFireBase();
    /**
     * memoized slides
     */
    const slides = useMemo(() => {
        const nextHints: {
            message: string;
            linkto?: string;
            customFunction?: () => void;
        }[] = [
            {
                message: format(dashboardMessages.checkHelpCenter),
                linkto: '/helpcenter',
            },
            {
                message: format(dashboardMessages.contactAgents),
                linkto: '/latepoint',
            },
        ];
        if (Platform.OS === 'web') {
            nextHints.push({
                message: format(dashboardMessages.addToBookmarks, {
                    platform: environment,
                }),
                customFunction: () => {
                    // @ts-ignore
                    if (window.sidebar) {
                        // @ts-ignore Firefox
                        window.sidebar.addPanel(title, url, '');
                        // @ts-ignore
                    } else if (window.opera && window.print) {
                        // @ts-ignore // Opera
                        const elem = document.createElement('a');
                        elem.setAttribute(
                            'href',
                            // @ts-ignore
                            window.location.origin,
                        );
                        elem.setAttribute('title', environment);
                        elem.setAttribute('rel', 'sidebar');
                        elem.click(); //this.title=document.title;
                        // @ts-ignore
                    } else if (document.all) {
                        // @ts-ignore is
                        window.external.AddFavorite(url, title);
                    } else {
                        // @ts-ignore webkit - safari/chrome
                        alert(
                            format(dashboardMessages.bookmarkShortcut, {
                                shortcut: `${
                                    // @ts-ignore
                                    navigator.userAgent
                                        .toLowerCase()
                                        .indexOf('mac') != -1
                                        ? 'Command/Cmd'
                                        : 'CTRL'
                                } + D`,
                            }),
                        );
                    }
                },
            });
        }
        if (userData.type === EUserType.user) {
            nextHints.push({
                message: format(dashboardMessages.addMoreLanguages),
                linkto: '/profile/languages',
            });
        }
        if (isAgencyUser(userData)) {
            nextHints.push({
                message: format(dashboardMessages.letTalentsCreateProfiles),
                linkto: '/profUser',
            });
        }
        if (isEmployer(userData)) {
            nextHints.push({
                message: format(dashboardMessages.postOneJobPerTimeAndLocation),
                linkto: '/job/new',
            });
        }
        return nextHints;
    }, [environment, userData]);
    /**
     * render
     */
    return (
        <View style={style.card}>
            <View style={[style.horizontalSplit, style.centeredItems]}>
                <CText bold headline>
                    {format(dashboardMessages.tipsHelp)}
                </CText>
                <CButton
                    smallest
                    transparent
                    iconColor={theme.borderColor}
                    icon={'eyeStrikethrought'}
                    onPress={() => {
                        dialog({
                            title: dashboardMessages.confirmHideTips,
                            message: dashboardMessages.confirmHideTipsText,
                            buttons: [
                                {
                                    text: actionMessages.confirm,
                                    onPress: () => {
                                        AsyncStorage.setItem(
                                            'hintsHidden',
                                            `${userData.documentId}`,
                                        );
                                        changeHidden();
                                    },
                                },
                            ],
                            cancelButton: {
                                text: actionMessages.cancel,
                            },
                        });
                    }}
                />
            </View>
            <CCarousel
                autoPlay
                slides={slides.map((v, i) => (
                    <TouchableView
                        key={i}
                        style={[
                            style.horizontal,
                            style.centeredContent,
                            style.centeredItems,
                            { padding: 25, width: '100%' },
                        ]}
                        onPress={
                            v.linkto
                                ? () => {
                                      navigate(v.linkto || '/');
                                  }
                                : v.customFunction || undefined
                        }
                    >
                        <CIcon
                            size={32}
                            tint={theme.accentColor}
                            icon={'info_outline'}
                        />
                        <CText
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                            }}
                        >
                            {v.message}
                        </CText>
                    </TouchableView>
                ))}
            />
        </View>
    );
};
