import React, { FC } from 'react';
import { Message } from '../../../../utilities/types';
import { useAutoFormat } from '../../../../utilities/intl';
import { CDataTableCell } from './CDataTableCell';

export const CDataTableHeaderCell: FC<{
    headerKey: string;
    label?: Message;
    first?: boolean;
    rowKey: string;
}> = ({ headerKey, label, first, rowKey }) => {
    const format = useAutoFormat();
    /**
     * render
     */
    return (
        <CDataTableCell
            headerKey={headerKey}
            value={format(label || headerKey)}
            first={first}
            rowKey={rowKey}
            bold
            head
        />
    );
};
