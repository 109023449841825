import React, { FC, useEffect } from 'react';
import { ERegion } from '../../../enums';
import { View } from 'react-native';
import { useEnvironment } from '../../../utilities/contexts';
/**
 * Web region select (skipped)
 * @param param0
 * @returns
 */
export const RegionSelect: FC<{ onRegion: (next: ERegion) => void }> = ({
    onRegion,
}) => {
    const { region } = useEnvironment();
    /**
     * effect to skip region select on web since it is determined by url
     */
    useEffect(() => {
        onRegion(region);
    }, [region]);
    /**
     * empty render
     */
    return <View />;
};
