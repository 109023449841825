import { defineMessages } from 'react-intl';

const scope = 'con.mfa.';

export const multifactoreMessages = defineMessages({
    successfullEnrolled: {
        id: scope + 'successfullEnrolled',
        defaultMessage:
            'You have successfully enrolled in multi-factor authentication.',
    },
    successfullEnrolledText: {
        id: scope + 'successfullEnrolledText',
        defaultMessage:
            'You will be logged out now and have to log back in using multi-factor.',
    },
    putInYourPasswordFirst: {
        id: scope + 'putInYourPasswordFirst',
        defaultMessage:
            'Put in your account password first to verify it is you who is setting up multi-factor authentication.',
    },
});
