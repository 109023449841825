import React, {
    FC,
    ReactNode,
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { ScrollView, View } from 'react-native';
import { useFormat } from '../../../utilities/intl';
import { generalMessages } from '../../../utilities/messages';
import { useStyle } from '../../../utilities/styles';
import { CButton } from '../CButton';
import { CDataTableRow } from './components/CDataTableRow';
import { CDataTableHeaderCell } from './components/CDataTableHeaderCell';
import { Message } from '../../../utilities/types';

export interface IRowProps {
    values: {
        headerKey: string;
        value: string;
        component?: ReactNode;
        bold?: boolean;
        color?: string;
    }[];
    onPress?: () => void;
    subRows?: IRowProps[];
    rowKey?: string;
}

export interface ICTableProps {
    rows: (IRowProps | false | undefined | null)[];
    headers: (
        | { headerKey: string; label?: Message }
        | false
        | undefined
        | null
    )[];
    hideHeaders?: boolean;
    orderBy?: string;
    moreRows?: (IRowProps | false | undefined | null)[];
}

export const CDataTableContext = createContext(
    {} as {
        keyWidth: {
            headerKey: string;
            rowKey: string;
            width: number;
            value: string;
        }[];
        registerNewKeyWidth: (
            headerKey: string,
            rowKey: string,
            value: string,
            width: number,
        ) => void;
    },
);

export const CDataTable: FC<ICTableProps> = ({
    rows,
    headers: propHeaders,
    // orderBy,
    hideHeaders,
    moreRows,
}) => {
    // global state
    const style = useStyle();
    const format = useFormat();
    // local state
    const [more, setMore] = useState(false);
    const [keyWidth, setKeyWidth] = useState<
        {
            headerKey: string;
            rowKey: string;
            value: string;
            width: number;
        }[]
    >([]);
    /**
     * callback to register new key width
     * should be called from cell onLayout to find the biggest cell and set width accordingly
     * only triggers state update when actual update of width happens
     */
    const registerNewKeyWidth = useCallback(
        (headerKey: string, rowKey: string, value: string, width: number) =>
            setKeyWidth((prev) => {
                const prevWidth = prev.find(
                    (p) => p.headerKey === headerKey && p.rowKey === rowKey,
                );

                if (prevWidth) {
                    if (prevWidth.width < width) {
                        prevWidth.width = width;
                        return [...prev];
                    }
                } else {
                    return [...prev, { headerKey, width, rowKey, value }];
                }

                return prev;
            }),
        [],
    );
    /**
     * memoized headers extracted and validated from props
     */
    const headers = useMemo(() => {
        return propHeaders.filter((h) => !!h) as {
            headerKey: string;
            label?: string | undefined;
        }[];
    }, [propHeaders]);

    useEffect(() => {
        // console.log(keyWidth);
    }, [keyWidth]);
    /**
     * render
     */
    return (
        <CDataTableContext.Provider value={{ keyWidth, registerNewKeyWidth }}>
            <ScrollView horizontal>
                <View>
                    {!hideHeaders && (
                        <View style={style.horizontal}>
                            {headers.map((header, i) => (
                                <CDataTableHeaderCell
                                    key={header.headerKey}
                                    {...header}
                                    first={!i}
                                    rowKey="header"
                                />
                            ))}
                        </View>
                    )}
                    {rows.map((row, i) => {
                        if (row) {
                            return (
                                <CDataTableRow
                                    headers={headers}
                                    {...row}
                                    key={row.rowKey || `${i}`}
                                    rowKey={row.rowKey || `${i}`}
                                />
                            );
                        }
                    })}
                    {moreRows && !more && (
                        <CButton
                            transparent
                            minor
                            title={format(generalMessages.more)}
                            onPress={() => setMore(true)}
                        />
                    )}
                    {moreRows &&
                        more &&
                        moreRows.map((row, i) => {
                            if (row) {
                                return (
                                    <CDataTableRow
                                        headers={headers}
                                        {...row}
                                        key={row.rowKey || `${i}`}
                                    />
                                );
                            }
                        })}
                </View>
            </ScrollView>
        </CDataTableContext.Provider>
    );
};
