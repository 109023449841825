import { createContext } from 'react';

export const ProfileCompletionContext = createContext(
    {} as {
        emailVerified: boolean;
        completeBank: boolean;
        completeAddress: boolean;
        completePersonal: boolean;
        verified: boolean;
        mfa: boolean;
        idDoc: boolean;
        licensedAgency: boolean;
        addressAgency: boolean;
        pictureAgency: boolean;
        progress: number;
    },
);
