import React, { FC, ReactNode, useMemo } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { CText } from '../../elements';
import { convertNumberToCurrencyString } from '../../../utilities/functions';
import { useStyle } from '../../../utilities/styles';
import { MWage } from '../../../models';
import { makeDisplayWage } from '../../../utilities/functions/makeDisplayWage';
import { wageMessages } from '../wage.messages';
import { useEnvironment } from '../../../utilities/contexts';
/**
 * View to display Wage values.
 * uses the same fix to simplify wages to calculated values
 * @param param0
 * @returns
 */
export const WageView: FC<{
    value: MWage;
    horizontal?: boolean;
    horizontalSplitting?: boolean;
    style?: StyleProp<ViewStyle> | Array<StyleProp<ViewStyle>>;
    fragment?: boolean;
    original?: MWage;
    prev?: MWage;
    injectedFields?: ReactNode[];
}> = ({
    value,
    horizontal,
    horizontalSplitting,
    style: propStyle,
    fragment,
    original,
    prev,
    injectedFields,
}) => {
    const style = useStyle();
    const { region } = useEnvironment();
    /**
     * memoized style
     */
    const wageStyle = useMemo(() => {
        return [
            !horizontal && style.verticalPadded,
            !horizontal && { paddingHorizontal: 10 },
            horizontal && style.horizontalPadded,
        ];
    }, [horizontal]);
    /**
     * wages to display
     */
    const displayWage = useMemo(() => makeDisplayWage(value), [value]);
    /**
     * prevWage
     */
    const displayPrevWage = useMemo(
        () => (prev ? makeDisplayWage(prev) : undefined),
        [prev],
    );
    /**
     * og wage
     */
    const displayOgWage = useMemo(
        () => (original ? makeDisplayWage(original) : undefined),
        [original],
    );
    /**
     * render
     */
    const content = useMemo(
        () => (
            <>
                {!!displayWage.regularWage && (
                    <View style={wageStyle}>
                        <CText bold message={wageMessages.regularWage} />
                        <CText
                            style={[
                                displayOgWage &&
                                    displayOgWage.regularWage !==
                                        displayWage.regularWage &&
                                    style.warning,
                                displayPrevWage &&
                                    displayPrevWage.regularWage !==
                                        displayWage.regularWage &&
                                    style.error,
                            ]}
                        >
                            {convertNumberToCurrencyString(
                                displayWage.regularWage,
                                region,
                            )}
                        </CText>
                    </View>
                )}
                {!!displayWage.standbyWage && (
                    <View style={wageStyle}>
                        <CText bold message={wageMessages.standbyWage} />
                        <CText
                            style={[
                                displayOgWage &&
                                    displayOgWage.standbyWage !==
                                        displayWage.standbyWage &&
                                    style.warning,
                                displayPrevWage &&
                                    displayPrevWage.standbyWage !==
                                        displayWage.standbyWage &&
                                    style.error,
                            ]}
                        >
                            {convertNumberToCurrencyString(
                                displayWage.standbyWage,
                                region,
                            )}
                        </CText>
                    </View>
                )}
                {!!displayWage.onCallActiveWage && (
                    <View style={wageStyle}>
                        <CText bold message={wageMessages.onCallActiveWage} />
                        <CText
                            style={[
                                displayOgWage &&
                                    displayOgWage.onCallActiveWage !==
                                        displayWage.onCallActiveWage &&
                                    style.warning,
                                displayPrevWage &&
                                    displayPrevWage.onCallActiveWage !==
                                        displayWage.onCallActiveWage &&
                                    style.error,
                            ]}
                        >
                            {convertNumberToCurrencyString(
                                displayWage.onCallActiveWage,
                                region,
                            )}
                        </CText>
                    </View>
                )}
                {!!displayWage.onCallPassiveWage && (
                    <View style={wageStyle}>
                        <CText bold message={wageMessages.onCallPassiveWage} />
                        <CText
                            style={[
                                displayOgWage &&
                                    displayOgWage.onCallPassiveWage !==
                                        displayWage.onCallPassiveWage &&
                                    style.warning,
                                displayPrevWage &&
                                    displayPrevWage.onCallPassiveWage !==
                                        displayWage.onCallPassiveWage &&
                                    style.error,
                            ]}
                        >
                            {convertNumberToCurrencyString(
                                displayWage.onCallPassiveWage,
                                region,
                            )}
                        </CText>
                    </View>
                )}
                {injectedFields && injectedFields}
            </>
        ),
        [displayWage, displayOgWage, displayPrevWage],
    );
    /**
     * display render in fragments or contain in view
     */
    if (fragment) {
        return <>{content}</>;
    } else {
        return (
            <View
                style={[
                    style.verticalPadded,
                    horizontal && style.horizontal,
                    horizontalSplitting && style.horizontalSplit,
                    propStyle,
                ]}
            >
                {content}
            </View>
        );
    }
};
