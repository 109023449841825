import { useEffect, useState } from 'react';
import { useFireBase } from '../useFirebase';
import { IParamOptions } from './FirestoreContext';
import { Unsubscribe } from 'firebase/firestore';
interface IParamOptionsWS extends IParamOptions {
    suspend?: boolean;
}
/**
 * useCollectionIndex hook to activate watching that collection and having the resulting data as any[]
 */
export const useCollectionIndex = (
    collection: string,
    paramOptions: IParamOptionsWS,
) => {
    const [data, setData] = useState<any[]>([]);
    const [unsubscribe, setUnsubscribe] = useState<Unsubscribe>();
    const { watchDataIndex, getDataIndex } = useFireBase();
    /**
     * effect to register unsubscribe as cleanup function
     * it gets triggered if react thinks it is time to clean up
     */
    useEffect(() => {
        return unsubscribe;
    }, [unsubscribe]);
    /**
     * effect to hook into collection listener for current param options
     */
    useEffect(() => {
        if (paramOptions.suspend) return setUnsubscribe(undefined);
        // clear in case the load would result in nothing
        getDataIndex(collection, { ...paramOptions, getLength: true }).then(
            (res) => !res && setData([]),
        );
        /**
         * watch the data index for changes. load data index on change
         * TODO: improve! This is a duplicate read. on change the data index fully reloads.
         */
        watchDataIndex(collection, paramOptions, (event) => {
            getDataIndex(collection, paramOptions).then((res) =>
                setData(res as any[]),
            );
        }).then((us) => setUnsubscribe(() => us));
    }, [collection, paramOptions]);

    return data;
};
