export const second = 1000;
export const minute = second * 60;
export const hour = minute * 60;
export const day = hour * 24;
export const week = day * 7;
export const month = day * 30;
export const year = day * 365;
/**
 * extract days, hours and minutes from a time in milliseconds
 * @param time time in milliseconds
 * @returns object with days, hours and minutes
 */
export const extractDaysMinutesHours = (time: number) => {
    const days = Math.floor(time / day);
    const hours = Math.floor((time - days * day) / hour);
    const minutes = Math.floor((time - days * day - hours * hour) / minute);

    return { days, hours, minutes };
};
/**
 * get the week number of a date
 * @param date date to get the week number from
 * @returns week number
 */
export const getWeekNumber = (date: Date) => {
    const d = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    );
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil(((d.getTime() - yearStart.getTime()) / day + 1) / 7);
};
/**
 * calculate the number of weeks in a year
 * @param year year to calculate the number of weeks in
 * @returns number of weeks in the year
 */
export const weeksInYear = (year: number) => {
    const month = 11;
    let day = 31;
    let week;
    // Find week that 31 Dec is in. If is first week, reduce date until
    // get previous week.
    do {
        const d = new Date(year, month, day--);
        week = getWeekNumber(d);
    } while (week == 1);

    return week;
};
/**
 * convert a week number and a year to a date
 * @param year year of interest
 * @param weekNumber week number of interest
 * @returns date of the first day of the week of interest
 */
export const convertWeekAndYearToDate = (year: number, weekNumber: number) => {
    const simple = new Date(year, 0, 1 + (weekNumber - 1) * 7);
    const dow = simple.getDay();
    const ISOweekStart = simple;
    if (dow <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    return ISOweekStart;
};
