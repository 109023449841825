import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { CText } from '../../../components';
import {
    EApplicationType,
    ECollections,
    EContractStatus,
    EProfession,
} from '../../../enums';
import {
    educationMessages,
    fieldMessages,
    generalMessages,
} from '../../../utilities/messages';
import { contractMessages } from '../contract.messages';
import { useFormat } from '../../../utilities/intl';
import { useFireBase } from '../../../utilities/firebase';
import { MContract, MProfessionalProfile } from '../../../models';
import { useStyle } from '../../../utilities/styles';
import { isPeasant } from '../../../utilities/auth';

export const ContractTitle: FC<{
    contract: MContract;
    horizontalPadded?: boolean;
    numberOfLines?: number;
    minor?: boolean;
    secondary?: boolean;
}> = ({ contract, horizontalPadded, numberOfLines, minor, secondary }) => {
    const format = useFormat();
    const { userData, getDataById } = useFireBase();
    const style = useStyle();

    const [talent, setTalent] = useState<MProfessionalProfile>();

    const isContract = useMemo(
        () =>
            [EContractStatus.signed, EContractStatus.completed].includes(
                contract.status,
            ),
        [contract],
    );

    const title = useMemo(() => {
        const parts = [];
        if (contract.type === EApplicationType.default) {
            parts.push(format(contractMessages.unmanagedContract));
            if (contract.title) {
                parts.push('-');
                parts.push(contract.title);
            }
        } else if (
            isPeasant(userData) ||
            (!contract.title && contract.education)
        ) {
            parts.push(
                format(
                    isContract
                        ? contractMessages.contractForJobInX
                        : contractMessages.negotiationForJobInX,

                    {
                        x: [
                            // contract.profession &&
                            // contract.profession !== EProfession.undefined
                            //     ? format(generalMessages[contract.profession])
                            //     : contract.field
                            //     ? format(fieldMessages[contract.field])
                            //     : '',
                            format(
                                educationMessages[
                                    contract.education as keyof typeof educationMessages
                                ],
                            ),
                        ]
                            .filter((v) => !!v && v !== 'undefined')
                            .join(' - '),
                    },
                ),
            );
        } else {
            parts.push(
                format(
                    isContract
                        ? contractMessages.contractForCustomTitle
                        : contractMessages.negotiationForCustomTitle,
                    { x: contract.title },
                ),
            );
            if (contract.education) {
                parts.push(
                    format(
                        educationMessages[
                            contract.education as keyof typeof educationMessages
                        ],
                    ),
                );
            }
        }
        if (talent?.lastName) {
            parts.push(talent.lastName);
        }

        return parts.join(' ');
    }, [contract, talent, isContract]);

    useEffect(() => {
        if (!contract.profileId) return;

        getDataById(ECollections.publicProfProfiles, contract.profileId).then(
            (res) => setTalent(new MProfessionalProfile(res)),
        );
    }, [contract]);

    return (
        <View
            style={[
                horizontalPadded && style.horizontalPadded,
                { display: 'flex', flex: 1 },
            ]}
        >
            <CText
                numberOfLines={numberOfLines}
                headline={!minor && !secondary}
                secondaryHeadline={secondary}
                message={title}
            />
        </View>
    );
};
