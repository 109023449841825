import React, { FC, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { CButton, CText, InfoBox } from '../../../../components';
import { CDatePicker } from '../../../../components';
import { ECollections } from '../../../../enums';
import { MAgency, MAvailability } from '../../../../models';
import { useFireBase } from '../../../../utilities/firebase';
import { useFormat } from '../../../../utilities/intl';
import { useStyle } from '../../../../utilities/styles';
import { AvailabilityRow } from '../../../Profile/components/AvailabilityRow';
import { ProfileContext } from '../../../Profile/Profile.context';
import { profileMessages } from '../../../Profile/Profile.messages';
import { useSecureNavigate } from '../../../../utilities/routing';
import { profProfileMessages } from '../../ProfProfile.messages';
/**
 * Profile Section do display availabilities and
 * edit / set available from to lock account out of notifications until then
 * @param param0 profile section props
 * @returns profile section
 */
export const ProfessionalAvailabilities: FC<{ agency?: MAgency }> = ({
    agency,
}) => {
    const format = useFormat();
    const style = useStyle();
    const { getDataIndex } = useFireBase();
    const { secureNavigate } = useSecureNavigate();
    const { curData, prevData, onChange } = useContext(ProfileContext);
    const [availabilities, setAvailabilities] = useState<MAvailability[]>([]);
    /**
     * effect to load availabilities
     */
    useEffect(() => {
        if (!agency) return;
        getDataIndex(ECollections.availabilities, {
            filter: [
                { field: 'profileId', value: prevData.documentId },
                { field: 'agencyId', value: agency.documentId },
            ],
        }).then((results) => {
            setAvailabilities(
                (results as MAvailability[]).map((r) => new MAvailability(r)),
            );
        });
    }, [prevData, agency]);
    /**
     * render
     */
    return (
        <View style={{ zIndex: 1 }}>
            <CText
                secondaryHeadline
                message={profProfileMessages.availabilities}
            />
            {/* <CText style={style.verticalPadded}>
                {format(profileMessages.availableFromDescription)}
            </CText>
            <CDatePicker
                title={format(profileMessages.availableFrom)}
                value={
                    curData.availableFrom
                        ? new Date(curData.availableFrom)
                        : undefined
                }
                onChange={(nextDate) => {
                    onChange({ availableFrom: nextDate.getTime() });
                }}
            /> */}
            {/* <CText secondaryHeadline>
                {format(profileMessages.explicitAvailabilities)}
            </CText> */}
            <View style={style.verticalPadded}>
                <InfoBox
                    message={
                        profProfileMessages.explicitAvailabilitiesDescription
                    }
                />
            </View>
            {!!availabilities.length && (
                <View style={[style.horizontalSplit, style.verticalPadded]}>
                    <View style={{ flex: 1 }}>
                        <CText bold>{format(profileMessages.from)}</CText>
                    </View>
                    <View style={{ flex: 1 }}>
                        <CText bold>{format(profileMessages.to)}</CText>
                    </View>
                    <View style={{ flex: 1 }}>
                        <CText bold>{format(profileMessages.note)}</CText>
                    </View>
                    <View style={[{ flex: 1 }, style.horizontal]}>
                        <View style={{ flex: 1 }}>
                            <CText bold>{format(profileMessages.days)}</CText>
                        </View>
                        <View style={{ flex: 1 }}>
                            <CText bold>{format(profileMessages.times)}</CText>
                        </View>
                    </View>
                    <View style={{ width: 50 }} />
                </View>
            )}
            {availabilities.map((a, i) => (
                <View key={a.documentId}>
                    <AvailabilityRow availability={a} />
                    {i !== availabilities.length - 1 && (
                        <View style={style.paddedThinSeparator} />
                    )}
                </View>
            ))}
            {!availabilities.length && (
                <View style={[style.verticalPadded, style.horizontalSpaced]}>
                    <CText>
                        {format(profileMessages.noExplicitAvailabilities)}
                    </CText>
                </View>
            )}
            <View style={[style.horizontalSpaced]}>
                <CButton
                    onPress={() => {
                        secureNavigate(
                            '/calendar?profileId=' + curData.documentId,
                        );
                    }}
                    title={profileMessages.openCalendar}
                    icon={'calendar'}
                    small
                />
            </View>
        </View>
    );
};
