import React, { FC, useContext } from 'react';
import { ProfileContext } from '../Profile.context';
import { AgencyList } from '../../Agency';
/**
 * profile section agency. contains a list of all agencies for a user.
 * the list should never be longer than 1
 * @returns
 */
export const ProfileSectionAgency: FC = () => {
    const { curData } = useContext(ProfileContext);
    /**
     * render
     */
    return <AgencyList uid={curData.documentId} />;
};
