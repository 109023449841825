import React, { FC, useMemo } from 'react';
import { View } from 'react-native';
import { useTheme } from '../../utilities/styles';
import './index.css';
import { ISpinnerProps } from './props';

export const Spinner: FC<ISpinnerProps> = ({
    fullScreen,
    color,
    small,
    tiny,
}) => {
    const { theme } = useTheme();

    const jumperStyle = useMemo(() => {
        return {
            fontSize: tiny ? 14 : small ? 24 : 36,
            marginRight: tiny ? 1 : 5,
            marginLeft: tiny ? 1 : 5,
            color: color || theme.accentColor,
        };
    }, [small, color]);
    return (
        <View
            style={[
                {
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    padding: tiny || small ? undefined : 20,
                },
                fullScreen && { width: '100vw', height: '100vh' },
            ]}
        >
            <View
                style={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                    flexDirection: 'row',
                }}
            >
                <span
                    className={small ? 'smallJumper' : 'jumper'}
                    style={jumperStyle}
                >
                    •
                </span>
                <span
                    className={small ? 'smallJumper' : 'jumper'}
                    style={{
                        // @ts-ignore
                        '--delay': '0.2s',
                        ...jumperStyle,
                    }}
                >
                    •
                </span>
                <span
                    className={small ? 'smallJumper' : 'jumper'}
                    style={{
                        // @ts-ignore
                        '--delay': '0.4s',
                        ...jumperStyle,
                    }}
                >
                    •
                </span>
                <span
                    className={small ? 'smallJumper' : 'jumper'}
                    style={{
                        // @ts-ignore
                        '--delay': '0.6s',
                        ...jumperStyle,
                    }}
                >
                    •
                </span>
            </View>
        </View>
    );
};
