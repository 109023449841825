import { ECollections } from '../../../../../enums';
import { MContract, MContractFile, MFile } from '../../../../../models';
import { useDialog } from '../../../../../utilities/dialog';
import {
    filenameMessages,
    actionMessages,
} from '../../../../../utilities/messages';
import { contractMessages } from '../../../contract.messages';
import { useFireBase } from '../../../../../utilities/firebase';
import { useLock } from '../../../../../utilities/hooks';
import { useFormat } from '../../../../../utilities/intl';
import { Linking } from 'react-native';
import { useCallback } from 'react';

export const useShowCourierDialog = (issueReload: () => void) => {
    const format = useFormat();
    const { lock } = useLock();
    const dialog = useDialog();
    const { getDataById, callFunction, getFile } = useFireBase();
    /**
     * callback to show courier dialog.
     */
    return useCallback(
        async (file: MFile, contract: MContract) => {
            if (!file.signatureRequest || file.signatureRequest.sent) {
                return;
            }
            const address = file.signatureRequest.address;
            const otherFiles = (
                await Promise.all(
                    file.signatureRequest.files
                        .filter((fid) => fid !== file.documentId)
                        .map(
                            async (fid) =>
                                await getDataById(
                                    `${ECollections.contracts}/${contract.documentId}/${ECollections.files}`,
                                    fid,
                                ),
                        ),
                )
            ).map((fd) => new MContractFile(fd));

            let trackingUrl = '';
            if (
                await dialog({
                    icon: 'info',
                    title: format(contractMessages.courierRequest, {
                        x: `${file.signatureRequest.files.length}`,
                    }),
                    message: format(contractMessages.courierRequestText, {
                        street: address.street,
                        number: address.number,
                        city: address.city,
                        country: address.country,
                        zipCode: address.zipCode,
                        recipient: file.signatureRequest.recipient,
                    }),
                    subMessages: [
                        contractMessages.filesForCourier,
                        `${file.name} - ${format(filenameMessages[file.type])}`,
                        ...otherFiles.map(
                            (f) =>
                                `${f.name} - ${format(
                                    filenameMessages[f.type],
                                )}`,
                        ),
                    ],
                    textInputs: [
                        {
                            id: 'tracking',
                            title: contractMessages.trackingUrl,
                        },
                    ],
                    upperButtons: [
                        {
                            icon: 'download',
                            text: actionMessages.download,
                            dontResolve: true,
                            onPress: () => {
                                for (const f of [file, ...otherFiles]) {
                                    getFile(f.path).then((res) => {
                                        const fns = f.path.split('.');
                                        const type = fns[fns.length - 1];
                                        // @ts-ignore
                                        const blob = new Blob([res], {
                                            type:
                                                type === 'pdf'
                                                    ? 'application/pdf'
                                                    : 'image/jpeg',
                                        });
                                        Linking.openURL(
                                            URL.createObjectURL(blob),
                                        );
                                    });
                                }
                            },
                        },
                    ],
                    buttons: [
                        {
                            text: actionMessages.confirm,
                            disabled: (inputs) => {
                                const url =
                                    (inputs || []).find(
                                        (v) => v.id === 'tracking',
                                    )?.value || '';
                                const isUrl = url.match(
                                    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                );

                                return !url.length || !isUrl;
                            },
                            onPress: (inputs) => {
                                trackingUrl =
                                    (inputs || []).find(
                                        (v) => v.id === 'tracking',
                                    )?.value || '';
                            },
                        },
                    ],
                    cancelButton: { text: actionMessages.cancel },
                })
            ) {
                const unlock = lock();
                await callFunction('addCourierTracking', {
                    trackingUrl,
                    fileDocId: file.documentId,
                    contractId: contract.documentId,
                });
                unlock();
                issueReload();
            }
        },
        [getDataById, callFunction, getFile, lock, format, dialog, issueReload],
    );
};
