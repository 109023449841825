import React, { FC, useCallback, useState } from 'react';
import { useFireBase } from '../../../../../utilities/firebase';
import { ECollections } from '../../../../../enums';
import { MJob } from '../../../../../models';
import { CButton } from '../../../../../components';

export const FixJobIds: FC = () => {
    const { getDataIndex, put } = useFireBase();

    const [fixingJobIds, setFixingJobIds] = useState(false);
    /**
     * callback to touch all jobs
     */
    const fixJobIds = useCallback(() => {
        setFixingJobIds(true);
        getDataIndex(ECollections.jobs, {
            orderBy: 'createdOn',
            asc: true,
        }).then(async (jobs) => {
            let pivot = 1;
            for (const job of jobs as MJob[]) {
                const next = new MJob(job);
                await put(ECollections.jobs, job.documentId, {
                    ...next,
                    id: pivot,
                    touched: Date.now(),
                });
                pivot++;
            }
            setFixingJobIds(false);
        });
    }, [getDataIndex, put]);

    return (
        <CButton
            onPress={fixJobIds}
            title="Fix job ids"
            disabled={fixingJobIds}
            danger
        />
    );
};
