import { EUserType } from '../enums';

export class MSignaturePosition {
    public x?: number;
    public y?: number;
    public page?: number;
    public type = EUserType.user;
    public userId = '';
    public signed = false;
    public url?: string;

    public activityId?: string;
    /**
     * filename to memorize for which file this position is
     */
    public filename = '';
    /**
     * parent fileId
     */
    public fileId = '';

    public editedOn = Date.now();
    public createdOn = Date.now();

    constructor(params: Partial<MSignaturePosition> = {}) {
        Object.assign(this, params);
    }
}
