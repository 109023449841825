import React, { FC, useMemo } from 'react';
import { Pressable, View } from 'react-native';

import { useStyle, useTheme } from '../../../utilities/styles';
import { CButton, CIcon, CText } from '../../../components';
import {
    IDayAvailability,
    MAvailability,
    MContract,
    MJob,
} from '../../../models';
import { useSecureNavigate } from '../../../utilities/routing';
import { calendarMessages } from '../Calendar.messages';
import { useFormat } from '../../../utilities/intl';
import { ActivityColumnRow } from './ActivityColumnRow';
import { ContractColumnRow } from './ContractColumnRow';
import {
    convertWeekAndYearToDate,
    day,
    getWeekNumber,
    week,
} from '../../../utilities/functions';

interface IDayHoursProps {
    curDate: Date;
    returnToProfile: boolean;
    availabilities: MAvailability[];
    setSelectedHour: (next?: number) => void;
    setEdit: (next: boolean) => void;
    setPrev: (next?: MAvailability) => void;
    thisMonthsJobs: MJob[];
    selectedJob?: MJob;
    setSelectedJob: (next: MJob) => void;
    contracts: MContract[];
    negotiations: MContract[];
    selectedContract?: MContract;
    setSelectedContract: (next: MContract) => void;
}

export const DayHours: FC<IDayHoursProps> = ({
    curDate,
    availabilities,
    setSelectedHour,
    setEdit,
    setPrev,
    returnToProfile,
    thisMonthsJobs,
    setSelectedJob,
    selectedJob,
    contracts,
    setSelectedContract,
    selectedContract,
    negotiations,
}) => {
    // global state
    const style = useStyle();
    const { theme } = useTheme();
    const { secureNavigate } = useSecureNavigate();
    const format = useFormat();
    /**
     * matched availabilities to current day
     */
    const todaysAvailabilities = useMemo(
        () =>
            availabilities.reduce((acc, a) => {
                if (!a.days) return acc;
                const { start, year } = a;
                const weekStartDate = convertWeekAndYearToDate(year, start);
                const startDate = a.exactStartTime
                    ? new Date(a.exactStartTime)
                    : weekStartDate;

                const [dayOfInterest, monthOfInterest, yearOfInterest] = curDate
                    .toLocaleDateString('de')
                    .split('.');
                const filtered = a.days.filter((d) => {
                    const dayOffset = (d.day - 1) * day;
                    let midWeekDate = new Date(
                        weekStartDate.getTime() + week / 2,
                    );
                    const match = [
                        0,
                        ...Array.from(
                            Array(a.repeatCount > 0 ? a.repeatCount : 0),
                        ).map((_, i) => i + 1),
                    ].find(() => {
                        const wn = getWeekNumber(midWeekDate);
                        const wyear = midWeekDate.getFullYear();
                        const tempDayDate = new Date(
                            convertWeekAndYearToDate(wyear, wn).getTime() +
                                dayOffset,
                        );
                        const zeroedTempDate = new Date(
                            tempDayDate.toDateString(),
                        ).getTime();
                        const zeroedStartDate = new Date(
                            startDate.toDateString(),
                        ).getTime();
                        if (
                            zeroedTempDate < zeroedStartDate ||
                            (a.exactEndTime && a.exactEndTime < zeroedTempDate)
                        ) {
                            return false;
                        }
                        const selectedDate =
                            tempDayDate.toLocaleDateString('de');
                        const [curDay, curMonth, curYear] =
                            selectedDate.split('.');

                        midWeekDate = new Date(
                            convertWeekAndYearToDate(wyear, wn).getTime() +
                                week * 1.5,
                        );
                        return (
                            +curDay === +dayOfInterest &&
                            curMonth === monthOfInterest &&
                            curYear === yearOfInterest
                        );
                    });
                    return match !== undefined;
                });
                filtered.forEach((dayAv) => {
                    if (dayAv.inheriting) {
                        acc.push({
                            today: { ...dayAv, from: a.from, to: a.to },
                            parent: a,
                        });
                    } else {
                        acc.push({ today: dayAv, parent: a });
                    }
                });

                return acc;
            }, [] as { today: IDayAvailability; parent: MAvailability }[]),
        [availabilities, curDate],
    );

    const todaysContracts = useMemo(() => {
        return [...contracts, ...negotiations].filter((contract) => {
            return !!contract.activeDates.find(
                (dateString) => curDate.toLocaleDateString('de') === dateString,
            );
        });
    }, [curDate, contracts, negotiations]);

    return (
        <View style={[style.card]}>
            {returnToProfile && (
                <View style={style.horizontal}>
                    <CButton
                        small
                        icon={'chevronLeft'}
                        title={format(calendarMessages.returnToProfile)}
                        onPress={() => {
                            secureNavigate(-1);
                        }}
                    />
                </View>
            )}
            <View style={[style.horizontal, { paddingTop: 10 + 25 / 2 }]}>
                <View style={{ marginTop: -25 / 2 }}>
                    {Array.from(new Array(25)).map((v, i) => {
                        const digits = `${i}:00`.split('');
                        if (digits.length < 5) {
                            digits.unshift('0');
                        }
                        return (
                            <View
                                key={i}
                                style={{
                                    width: 40,
                                    height: 25,
                                    alignItems: 'flex-end',
                                    marginRight: 5,
                                }}
                            >
                                <View style={style.horizontal}>
                                    {digits.map((c, i) => (
                                        <View
                                            key={i}
                                            style={{
                                                width: 8,
                                                alignItems: 'center',
                                            }}
                                        >
                                            <CText>{c}</CText>
                                        </View>
                                    ))}
                                </View>
                            </View>
                        );
                    })}
                </View>
                {!todaysAvailabilities.length && !todaysContracts.length && (
                    <View style={{ flex: 1 }}>
                        {Array.from(new Array(24)).map((v, i) => (
                            <View key={i} style={style.horizontal}>
                                <Pressable
                                    style={[
                                        style.horizontal,
                                        {
                                            height: 25,
                                            flex: 1,
                                            borderWidth: 1,
                                            borderColor: 'transparent',
                                            borderBottomColor:
                                                theme.borderColor,
                                        },
                                        i === 0 && {
                                            borderTopColor: theme.borderColor,
                                        },
                                        i === 23 && {
                                            borderBottomColor:
                                                theme.borderColor,
                                        },
                                    ]}
                                    onPress={() => {
                                        setEdit(true);
                                        setSelectedHour(i);
                                    }}
                                ></Pressable>
                            </View>
                        ))}
                    </View>
                )}
                {todaysAvailabilities.map(({ today, parent }) => (
                    <View key={parent.documentId} style={{ flex: 1 }}>
                        {Array.from(new Array(24)).map((v, i) => {
                            return (
                                <ActivityColumnRow
                                    key={i}
                                    today={today}
                                    parent={parent}
                                    i={i}
                                    setEdit={setEdit}
                                    setSelectedHour={setSelectedHour}
                                    setPrev={setPrev}
                                />
                            );
                        })}
                    </View>
                ))}
                {todaysContracts.map((contract) => (
                    <View key={contract.documentId} style={{ flex: 1 }}>
                        {Array.from(new Array(24)).map((v, i) => {
                            return (
                                <ContractColumnRow
                                    key={i}
                                    i={i}
                                    contract={contract}
                                    selectedContract={selectedContract}
                                    setContract={setSelectedContract}
                                />
                            );
                        })}
                    </View>
                ))}
            </View>
        </View>
    );
};
