import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useFireBase } from '../../../utilities/firebase';
import { useNavigate } from '../../../utilities/routing';
import { useInvite } from '../../../utilities/hooks/useInvite';
import { useDialog } from '../../../utilities/dialog';
import { actionMessages } from '../../../utilities/messages';
import { urlHandlerMessages } from '../urlHandler.messages';
import { useStyle } from '../../../utilities/styles';
import { CText, Spinner } from '../../../components';
import { EInviteType } from '../../../enums';

export const HandleInvite: FC = () => {
    const navigate = useNavigate();
    const dialog = useDialog();
    const { isLoggedIn, callFunction } = useFireBase();
    const style = useStyle();

    const invite = useInvite();

    const [didResolve, setDidResolve] = useState(false);
    /**
     * effect to resolve invite after load completed && logged in
     */
    useEffect(() => {
        if (isLoggedIn && invite && !didResolve) {
            callFunction('resolveInvite', {
                inviteId: invite.documentId,
                targedId: invite.targetId,
                type: invite.type,
            }).then((res) => {
                setDidResolve(true);
                console.log(res);
                const succ = res === 'success';
                dialog({
                    icon: succ ? 'success' : 'error',
                    title: succ
                        ? urlHandlerMessages.inviteSuccess
                        : urlHandlerMessages.inviteError,
                    message: succ ? urlHandlerMessages.inviteSuccesstext : res,
                    buttons: [{ text: actionMessages.ok }],
                }).then(() => {
                    if (invite.type === EInviteType.contract) {
                        navigate('/contract/' + invite.targetId);
                    } else {
                        navigate('/');
                    }
                });
            });
        } else if (invite) {
            navigate(
                `/?invite=${invite.documentId}&target=${invite.targetId}&type=${invite.type}`,
            );
        }
    }, [isLoggedIn, invite, didResolve]);
    /**
     * render status of invite
     */
    return (
        <View style={[style.paddedMainView]}>
            <View style={[style.centeredContent, style.centeredItems]}>
                <Spinner />
                <CText
                    secondaryHeadline
                    message={urlHandlerMessages.resolvingInvite}
                />
            </View>
        </View>
    );
};
