import { Platform, StyleSheet } from 'react-native';
import { ITheme } from './utilities/styles/ITheme';

export const makeNavStyles = (theme: ITheme) =>
    StyleSheet.create({
        navWrap: {
            backgroundColor: theme.backgroundMainColor,
        },
        navSidebar: {
            height: '100%',
            shadowColor: theme.shadowColor,
            shadowOffset: {
                width: 0,
                height: 1,
            },
            shadowOpacity: 0.18,
            shadowRadius: 1.0,
            elevation: 1,
        },
        navHeader: {
            width: '100%',
            alignContent: 'center',
            justifyContent: 'center',
            backgroundColor: theme.backgroundMainColor,
            padding: 10,
            paddingVertical: 20,
        },
        navDrawer: {
            height: '100%',
            position: 'absolute',
            backgroundColor: theme.backgroundMainColor,
        },
        navDrawerUnderlay: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            opacity: 0.5,
            backgroundColor: theme.backgroundSecondaryColor,
        },
        navContainer: {
            backgroundColor: theme.backgroundSecondaryColor,
            width: '100vw',
            flexDirection: 'row',
            alignContent: 'flex-start',
            justifyContent: 'flex-start',
            paddingBottom: Platform.OS === 'ios' ? 40 : 0,
        },
    });
