import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { MWorkplace } from '../../../models';
import { ECollections } from '../../../enums';
import { isSuperUser } from '../../../utilities/auth';
import { useFireBase } from '../../../utilities/firebase';
import { IFilter } from '../../../utilities/firebase/store';
import { useStyle } from '../../../utilities/styles';
import { CPicker, CText } from '../../../components';
import { typeaheadMessages } from '../../../components/Typeahead/typeahead.messages';
import { jobApplicationMessages } from '../JobApplication.messages';

export const WorkplaceMultiPicker: FC<{
    currentWorkplaces: MWorkplace[];
    onChangeCurrentWorkplaces: (next: MWorkplace[]) => void;
}> = ({ currentWorkplaces, onChangeCurrentWorkplaces }) => {
    // global state
    const style = useStyle();
    const { getDataIndex, userData, userWorkplaces } = useFireBase();
    /**
     * local state
     */
    const [workplaces, setWorkplaces] = useState<MWorkplace[]>([]);
    const [srcWorkplaces, setSrcWorkplaces] = useState<MWorkplace[]>([]);
    /**
     * effect to set local current workplace based on prop workplace (also sets zip code)
     * ! only for singular workplaces
     */
    useEffect(() => {
        setWorkplaces((prev) => {
            if (
                currentWorkplaces.length === 1 &&
                prev.length !== currentWorkplaces.length
            ) {
                return currentWorkplaces;
            } else {
                return prev;
            }
        });
    }, [currentWorkplaces]);
    /**
     * effect to trigger change
     */
    useEffect(() => {
        if (
            (workplaces.length &&
                workplaces.length !== currentWorkplaces.length) ||
            (!workplaces.length && currentWorkplaces.length === 1)
        ) {
            onChangeCurrentWorkplaces(workplaces);
        } else if (
            !currentWorkplaces.length &&
            currentWorkplaces.length !== srcWorkplaces.length
        ) {
            onChangeCurrentWorkplaces(srcWorkplaces);
        }
    }, [workplaces, currentWorkplaces, srcWorkplaces]);
    /**
     * effect to load workplaces to display
     */
    useEffect(() => {
        const params: any = {};
        const filter: IFilter[] = [];
        if (!isSuperUser(userData) && userData.documentId) {
            setSrcWorkplaces(userWorkplaces);
            if (userWorkplaces.length === 1) {
                setWorkplaces(userWorkplaces);
            }
            return;
        }

        if (filter.length) {
            getDataIndex(ECollections.workplaces, {
                ...params,
                filter,
            }).then((result) => {
                const next = (result as MWorkplace[]).map(
                    (r) => new MWorkplace(r),
                );
                setSrcWorkplaces(next);
                if (next.length === 1) {
                    setWorkplaces(next);
                }
            });
        } else {
            setWorkplaces([]);
            setSrcWorkplaces([]);
        }
    }, [userData, userWorkplaces]);
    return (
        <View>
            {srcWorkplaces.length === 1 && (
                <View style={style.verticalPadded}>
                    <CText message={typeaheadMessages.workplace} />
                    <CText
                        secondaryHeadline
                        message={`${workplaces[0].address.zipCode} ${workplaces[0].name}`}
                    />
                </View>
            )}
            {srcWorkplaces.length > 1 && (
                <CPicker
                    values={srcWorkplaces
                        .map((wp) => ({
                            value: wp.documentId,
                            label: `${wp.address.zipCode} ${wp.name}`,
                        }))
                        .sort()}
                    multiValues={workplaces.map((wp) => wp.documentId)}
                    onChange={(v) => console.log(v)}
                    onChangeMulti={(v) =>
                        setWorkplaces(
                            srcWorkplaces.filter((wp) =>
                                v.includes(wp.documentId),
                            ),
                        )
                    }
                    placeholder={jobApplicationMessages.selectWorkplace}
                    multiLimit={5}
                />
            )}
        </View>
    );
};
