import { defineMessages } from 'react-intl';

const scope = 'country.';

export const countryMessages = defineMessages({
    afghanistan: {
        id: `${scope}afghanistan`,
        defaultMessage: `Afghanistan`,
    },
    alandIslands: {
        id: `${scope}alandIslands`,
        defaultMessage: `Aland Islands`,
    },
    albania: {
        id: `${scope}albania`,
        defaultMessage: `Albania`,
    },
    algeria: {
        id: `${scope}algeria`,
        defaultMessage: `Algeria`,
    },
    americanSamoa: {
        id: `${scope}americanSamoa`,
        defaultMessage: `American Samoa`,
    },
    andorra: {
        id: `${scope}andorra`,
        defaultMessage: `Andorra`,
    },
    angola: {
        id: `${scope}angola`,
        defaultMessage: `Angola`,
    },
    anguilla: {
        id: `${scope}anguilla`,
        defaultMessage: `Anguilla`,
    },
    antarctica: {
        id: `${scope}antarctica`,
        defaultMessage: `Antarctica`,
    },
    antiguaAndBarbuda: {
        id: `${scope}antiguaAndBarbuda`,
        defaultMessage: `Antigua and Barbuda`,
    },
    argentina: {
        id: `${scope}argentina`,
        defaultMessage: `Argentina`,
    },
    armenia: {
        id: `${scope}armenia`,
        defaultMessage: `Armenia`,
    },
    aruba: {
        id: `${scope}aruba`,
        defaultMessage: `Aruba`,
    },
    australia: {
        id: `${scope}australia`,
        defaultMessage: `Australia`,
    },
    austria: {
        id: `${scope}austria`,
        defaultMessage: `Austria`,
    },
    azerbaijan: {
        id: `${scope}azerbaijan`,
        defaultMessage: `Azerbaijan`,
    },
    bahamas: {
        id: `${scope}bahamas`,
        defaultMessage: `Bahamas`,
    },
    bahrain: {
        id: `${scope}bahrain`,
        defaultMessage: `Bahrain`,
    },
    bangladesh: {
        id: `${scope}bangladesh`,
        defaultMessage: `Bangladesh`,
    },
    barbados: {
        id: `${scope}barbados`,
        defaultMessage: `Barbados`,
    },
    belarus: {
        id: `${scope}belarus`,
        defaultMessage: `Belarus`,
    },
    belgium: {
        id: `${scope}belgium`,
        defaultMessage: `Belgium`,
    },
    belize: {
        id: `${scope}belize`,
        defaultMessage: `Belize`,
    },
    benin: {
        id: `${scope}benin`,
        defaultMessage: `Benin`,
    },
    bermuda: {
        id: `${scope}bermuda`,
        defaultMessage: `Bermuda`,
    },
    bhutan: {
        id: `${scope}bhutan`,
        defaultMessage: `Bhutan`,
    },
    bolivia: {
        id: `${scope}bolivia`,
        defaultMessage: `Bolivia`,
    },
    bosniaAndHerzegovina: {
        id: `${scope}bosniaAndHerzegovina`,
        defaultMessage: `Bosnia and Herzegovina`,
    },
    botswana: {
        id: `${scope}botswana`,
        defaultMessage: `Botswana`,
    },
    bouvetIsland: {
        id: `${scope}bouvetIsland`,
        defaultMessage: `Bouvet Island`,
    },
    brazil: {
        id: `${scope}brazil`,
        defaultMessage: `Brazil`,
    },
    britishIndianOceanTerritory: {
        id: `${scope}britishIndianOceanTerritory`,
        defaultMessage: `British Indian Ocean Territory`,
    },
    bruneiDarussalam: {
        id: `${scope}bruneiDarussalam`,
        defaultMessage: `Brunei Darussalam`,
    },
    bulgaria: {
        id: `${scope}bulgaria`,
        defaultMessage: `Bulgaria`,
    },
    burkinaFaso: {
        id: `${scope}burkinaFaso`,
        defaultMessage: `BurkinaFaso`,
    },
    burundi: {
        id: `${scope}burundi`,
        defaultMessage: `Burundi`,
    },
    cambodia: {
        id: `${scope}cambodia`,
        defaultMessage: `Cambodia`,
    },
    cameroon: {
        id: `${scope}cameroon`,
        defaultMessage: `Cameroon`,
    },
    canada: {
        id: `${scope}canada`,
        defaultMessage: `Canada`,
    },
    capeVerde: {
        id: `${scope}capeVerde`,
        defaultMessage: `Cape Verde`,
    },
    caymanIslands: {
        id: `${scope}caymanIslands`,
        defaultMessage: `Cayman Islands`,
    },
    centralAfricanRepublic: {
        id: `${scope}centralAfricanRepublic`,
        defaultMessage: `Central African Republic`,
    },
    chad: {
        id: `${scope}chad`,
        defaultMessage: `Chad`,
    },
    chile: {
        id: `${scope}chile`,
        defaultMessage: `Chile`,
    },
    china: {
        id: `${scope}china`,
        defaultMessage: `China`,
    },
    christmasIsland: {
        id: `${scope}christmasIsland`,
        defaultMessage: `Christmas Island`,
    },
    cocosKeelingIslands: {
        id: `${scope}cocosKeelingIslands`,
        defaultMessage: `Cocos Keeling Islands`,
    },
    colombia: {
        id: `${scope}colombia`,
        defaultMessage: `Colombia`,
    },
    comoros: {
        id: `${scope}comoros`,
        defaultMessage: `Comoros`,
    },
    congo: {
        id: `${scope}congo`,
        defaultMessage: `Congo`,
    },
    congoDemocraticRepublic: {
        id: `${scope}congoDemocraticRepublic`,
        defaultMessage: `Congo Democratic Republic`,
    },
    cookIslands: {
        id: `${scope}cookIslands`,
        defaultMessage: `Cook Islands`,
    },
    costaRica: {
        id: `${scope}costaRica`,
        defaultMessage: `Costa Rica`,
    },
    coteDIvoire: {
        id: `${scope}coteDIvoire`,
        defaultMessage: `Cote D'Ivoire`,
    },
    croatia: {
        id: `${scope}croatia`,
        defaultMessage: `Croatia`,
    },
    cuba: {
        id: `${scope}cuba`,
        defaultMessage: `Cuba`,
    },
    cyprus: {
        id: `${scope}cyprus`,
        defaultMessage: `Cyprus`,
    },
    czechRepublic: {
        id: `${scope}czechRepublic`,
        defaultMessage: `Czech Republic`,
    },
    denmark: {
        id: `${scope}denmark`,
        defaultMessage: `Denmark`,
    },
    djibouti: {
        id: `${scope}djibouti`,
        defaultMessage: `Djibouti`,
    },
    dominica: {
        id: `${scope}dominica`,
        defaultMessage: `Dominica`,
    },
    dominicanRepublic: {
        id: `${scope}dominicanRepublic`,
        defaultMessage: `Dominican Republic`,
    },
    ecuador: {
        id: `${scope}ecuador`,
        defaultMessage: `Ecuador`,
    },
    egypt: {
        id: `${scope}egypt`,
        defaultMessage: `Egypt`,
    },
    elSalvador: {
        id: `${scope}elSalvador`,
        defaultMessage: `El Salvador`,
    },
    equatorialGuinea: {
        id: `${scope}equatorialGuinea`,
        defaultMessage: `Equatorial Guinea`,
    },
    eritrea: {
        id: `${scope}eritrea`,
        defaultMessage: `Eritrea`,
    },
    estonia: {
        id: `${scope}estonia`,
        defaultMessage: `Estonia`,
    },
    ethiopia: {
        id: `${scope}ethiopia`,
        defaultMessage: `Ethiopia`,
    },
    falklandIslands: {
        id: `${scope}falklandIslands`,
        defaultMessage: `Falkland Islands`,
    },
    faroeIslands: {
        id: `${scope}faroeIslands`,
        defaultMessage: `Faroe Islands`,
    },
    fiji: {
        id: `${scope}fiji`,
        defaultMessage: `Fiji`,
    },
    finland: {
        id: `${scope}finland`,
        defaultMessage: `Finland`,
    },
    france: {
        id: `${scope}france`,
        defaultMessage: `France`,
    },
    frenchGuiana: {
        id: `${scope}frenchGuiana`,
        defaultMessage: `French Guiana`,
    },
    frenchPolynesia: {
        id: `${scope}frenchPolynesia`,
        defaultMessage: `French Polynesia`,
    },
    frenchSouthernTerritories: {
        id: `${scope}frenchSouthernTerritories`,
        defaultMessage: `French Southern Territories`,
    },
    gabon: {
        id: `${scope}gabon`,
        defaultMessage: `Gabon`,
    },
    gambia: {
        id: `${scope}gambia`,
        defaultMessage: `Gambia`,
    },
    georgia: {
        id: `${scope}georgia`,
        defaultMessage: `Georgia`,
    },
    germany: {
        id: `${scope}germany`,
        defaultMessage: `Germany`,
    },
    ghana: {
        id: `${scope}ghana`,
        defaultMessage: `Ghana`,
    },
    gibraltar: {
        id: `${scope}gibraltar`,
        defaultMessage: `Gibraltar`,
    },
    greece: {
        id: `${scope}greece`,
        defaultMessage: `Greece`,
    },
    greenland: {
        id: `${scope}greenland`,
        defaultMessage: `Greenland`,
    },
    grenada: {
        id: `${scope}grenada`,
        defaultMessage: `Grenada`,
    },
    guadeloupe: {
        id: `${scope}guadeloupe`,
        defaultMessage: `Guadeloupe`,
    },
    guam: {
        id: `${scope}guam`,
        defaultMessage: `Guam`,
    },
    guatemala: {
        id: `${scope}guatemala`,
        defaultMessage: `Guatemala`,
    },
    guernsey: {
        id: `${scope}guernsey`,
        defaultMessage: `Guernsey`,
    },
    guinea: {
        id: `${scope}guinea`,
        defaultMessage: `Guinea`,
    },
    guineaBissau: {
        id: `${scope}guineaBissau`,
        defaultMessage: `Guinea Bissau`,
    },
    guyana: {
        id: `${scope}guyana`,
        defaultMessage: `Guyana`,
    },
    haiti: {
        id: `${scope}haiti`,
        defaultMessage: `Haiti`,
    },
    heardIslandMcdonaldIslands: {
        id: `${scope}heardIslandMcdonaldIslands`,
        defaultMessage: `Heard Island Mcdonald Islands`,
    },
    holySeeVaticanCityState: {
        id: `${scope}holySeeVaticanCityState`,
        defaultMessage: `Holy See Vatican City State`,
    },
    honduras: {
        id: `${scope}honduras`,
        defaultMessage: `Honduras`,
    },
    hongkong: {
        id: `${scope}hongkong`,
        defaultMessage: `Hongkong`,
    },
    hungary: {
        id: `${scope}hungary`,
        defaultMessage: `Hungary`,
    },
    iceland: {
        id: `${scope}iceland`,
        defaultMessage: `Iceland`,
    },
    india: {
        id: `${scope}india`,
        defaultMessage: `India`,
    },
    indonesia: {
        id: `${scope}indonesia`,
        defaultMessage: `Indonesia`,
    },
    iran: {
        id: `${scope}iran`,
        defaultMessage: `Iran`,
    },
    iraq: {
        id: `${scope}iraq`,
        defaultMessage: `Iraq`,
    },
    ireland: {
        id: `${scope}ireland`,
        defaultMessage: `Ireland`,
    },
    isleOfMan: {
        id: `${scope}isleOfMan`,
        defaultMessage: `IsleOfMan`,
    },
    israel: {
        id: `${scope}israel`,
        defaultMessage: `Israel`,
    },
    italy: {
        id: `${scope}italy`,
        defaultMessage: `Italy`,
    },
    jamaica: {
        id: `${scope}jamaica`,
        defaultMessage: `Jamaica`,
    },
    japan: {
        id: `${scope}japan`,
        defaultMessage: `Japan`,
    },
    jersey: {
        id: `${scope}jersey`,
        defaultMessage: `Jersey`,
    },
    jordan: {
        id: `${scope}jordan`,
        defaultMessage: `Jordan`,
    },
    kazakhstan: {
        id: `${scope}kazakhstan`,
        defaultMessage: `Kazakhstan`,
    },
    kenya: {
        id: `${scope}kenya`,
        defaultMessage: `Kenya`,
    },
    kiribati: {
        id: `${scope}kiribati`,
        defaultMessage: `Kiribati`,
    },
    korea: {
        id: `${scope}korea`,
        defaultMessage: `Korea`,
    },
    kuwait: {
        id: `${scope}kuwait`,
        defaultMessage: `Kuwait`,
    },
    kyrgyzstan: {
        id: `${scope}kyrgyzstan`,
        defaultMessage: `Kyrgyzstan`,
    },
    laoPeoplesDemocraticRepublic: {
        id: `${scope}laoPeoplesDemocraticRepublic`,
        defaultMessage: `Lao Peoples Democratic Republic`,
    },
    latvia: {
        id: `${scope}latvia`,
        defaultMessage: `Latvia`,
    },
    lebanon: {
        id: `${scope}lebanon`,
        defaultMessage: `Lebanon`,
    },
    lesotho: {
        id: `${scope}lesotho`,
        defaultMessage: `Lesotho`,
    },
    liberia: {
        id: `${scope}liberia`,
        defaultMessage: `Liberia`,
    },
    libyanArabJamahiriya: {
        id: `${scope}libyanArabJamahiriya`,
        defaultMessage: `Libyan Arab Jamahiriya`,
    },
    liechtenstein: {
        id: `${scope}liechtenstein`,
        defaultMessage: `Liechtenstein`,
    },
    lithuania: {
        id: `${scope}lithuania`,
        defaultMessage: `Lithuania`,
    },
    luxembourg: {
        id: `${scope}luxembourg`,
        defaultMessage: `Luxembourg`,
    },
    macao: {
        id: `${scope}macao`,
        defaultMessage: `Macao`,
    },
    macedonia: {
        id: `${scope}macedonia`,
        defaultMessage: `Macedonia`,
    },
    madagascar: {
        id: `${scope}madagascar`,
        defaultMessage: `Madagascar`,
    },
    malawi: {
        id: `${scope}malawi`,
        defaultMessage: `Malawi`,
    },
    malaysia: {
        id: `${scope}malaysia`,
        defaultMessage: `Malaysia`,
    },
    maldives: {
        id: `${scope}maldives`,
        defaultMessage: `Maldives`,
    },
    mali: {
        id: `${scope}mali`,
        defaultMessage: `Mali`,
    },
    malta: {
        id: `${scope}malta`,
        defaultMessage: `Malta`,
    },
    marshallIslands: {
        id: `${scope}marshallIslands`,
        defaultMessage: `Marshall Islands`,
    },
    martinique: {
        id: `${scope}martinique`,
        defaultMessage: `Martinique`,
    },
    mauritania: {
        id: `${scope}mauritania`,
        defaultMessage: `Mauritania`,
    },
    mauritius: {
        id: `${scope}mauritius`,
        defaultMessage: `Mauritius`,
    },
    mayotte: {
        id: `${scope}mayotte`,
        defaultMessage: `Mayotte`,
    },
    mexico: {
        id: `${scope}mexico`,
        defaultMessage: `Mexico`,
    },
    micronesia: {
        id: `${scope}micronesia`,
        defaultMessage: `Micronesia`,
    },
    moldova: {
        id: `${scope}moldova`,
        defaultMessage: `Moldova`,
    },
    monaco: {
        id: `${scope}monaco`,
        defaultMessage: `Monaco`,
    },
    mongolia: {
        id: `${scope}mongolia`,
        defaultMessage: `Mongolia`,
    },
    montenegro: {
        id: `${scope}montenegro`,
        defaultMessage: `Montenegro`,
    },
    montserrat: {
        id: `${scope}montserrat`,
        defaultMessage: `Montserrat`,
    },
    morocco: {
        id: `${scope}morocco`,
        defaultMessage: `Morocco`,
    },
    mozambique: {
        id: `${scope}mozambique`,
        defaultMessage: `Mozambique`,
    },
    myanmar: {
        id: `${scope}myanmar`,
        defaultMessage: `Myanmar`,
    },
    namibia: {
        id: `${scope}namibia`,
        defaultMessage: `Namibia`,
    },
    nauru: {
        id: `${scope}nauru`,
        defaultMessage: `Nauru`,
    },
    nepal: {
        id: `${scope}nepal`,
        defaultMessage: `Nepal`,
    },
    netherlands: {
        id: `${scope}netherlands`,
        defaultMessage: `Netherlands`,
    },
    netherlandsAntilles: {
        id: `${scope}netherlandsAntilles`,
        defaultMessage: `Netherlands Antilles`,
    },
    newCaledonia: {
        id: `${scope}newCaledonia`,
        defaultMessage: `New Caledonia`,
    },
    newZealand: {
        id: `${scope}newZealand`,
        defaultMessage: `New Zealand`,
    },
    nicaragua: {
        id: `${scope}nicaragua`,
        defaultMessage: `Nicaragua`,
    },
    niger: {
        id: `${scope}niger`,
        defaultMessage: `Niger`,
    },
    nigeria: {
        id: `${scope}nigeria`,
        defaultMessage: `Nigeria`,
    },
    niue: {
        id: `${scope}niue`,
        defaultMessage: `Niue`,
    },
    norfolkIsland: {
        id: `${scope}norfolkIsland`,
        defaultMessage: `Norfolk Island`,
    },
    northernMarianaIslands: {
        id: `${scope}northernMarianaIslands`,
        defaultMessage: `Northern Mariana Islands`,
    },
    norway: {
        id: `${scope}norway`,
        defaultMessage: `Norway`,
    },
    oman: {
        id: `${scope}oman`,
        defaultMessage: `Oman`,
    },
    pakistan: {
        id: `${scope}pakistan`,
        defaultMessage: `Pakistan`,
    },
    palau: {
        id: `${scope}palau`,
        defaultMessage: `Palau`,
    },
    palestinianTerritory: {
        id: `${scope}palestinianTerritory`,
        defaultMessage: `Palestinian Territory`,
    },
    panama: {
        id: `${scope}panama`,
        defaultMessage: `Panama`,
    },
    papuaNewGuinea: {
        id: `${scope}papuaNewGuinea`,
        defaultMessage: `Papua New Guinea`,
    },
    paraguay: {
        id: `${scope}paraguay`,
        defaultMessage: `Paraguay`,
    },
    peru: {
        id: `${scope}peru`,
        defaultMessage: `Peru`,
    },
    philippines: {
        id: `${scope}philippines`,
        defaultMessage: `Philippines`,
    },
    pitcairn: {
        id: `${scope}pitcairn`,
        defaultMessage: `Pitcairn`,
    },
    poland: {
        id: `${scope}poland`,
        defaultMessage: `Poland`,
    },
    portugal: {
        id: `${scope}portugal`,
        defaultMessage: `Portugal`,
    },
    puertoRico: {
        id: `${scope}puertoRico`,
        defaultMessage: `Puerto Rico`,
    },
    qatar: {
        id: `${scope}qatar`,
        defaultMessage: `Qatar`,
    },
    reunion: {
        id: `${scope}reunion`,
        defaultMessage: `Reunion`,
    },
    romania: {
        id: `${scope}romania`,
        defaultMessage: `Romania`,
    },
    russianFederation: {
        id: `${scope}russianFederation`,
        defaultMessage: `Russian Federation`,
    },
    rwanda: {
        id: `${scope}rwanda`,
        defaultMessage: `Rwanda`,
    },
    saintBarthelemy: {
        id: `${scope}saintBarthelemy`,
        defaultMessage: `Saint Barthelemy`,
    },
    saintHelena: {
        id: `${scope}saintHelena`,
        defaultMessage: `Saint Helena`,
    },
    saintKittsAndNevis: {
        id: `${scope}saintKittsAndNevis`,
        defaultMessage: `Saint Kitts and Nevis`,
    },
    saintLucia: {
        id: `${scope}saintLucia`,
        defaultMessage: `Saint Lucia`,
    },
    saintMartin: {
        id: `${scope}saintMartin`,
        defaultMessage: `Saint Martin`,
    },
    saintPierreAndMiquelon: {
        id: `${scope}saintPierreAndMiquelon`,
        defaultMessage: `Saint Pierre and Miquelon`,
    },
    saintVincentAndGrenadines: {
        id: `${scope}saintVincentAndGrenadines`,
        defaultMessage: `Saint Vincent and Grenadines`,
    },
    samoa: {
        id: `${scope}samoa`,
        defaultMessage: `Samoa`,
    },
    sanMarino: {
        id: `${scope}sanMarino`,
        defaultMessage: `San Marino`,
    },
    saoTomeAndPrincipe: {
        id: `${scope}saoTomeAndPrincipe`,
        defaultMessage: `Sao Tome and Principe`,
    },
    saudiArabia: {
        id: `${scope}saudiArabia`,
        defaultMessage: `Saudi Arabia`,
    },
    senegal: {
        id: `${scope}senegal`,
        defaultMessage: `Senegal`,
    },
    serbia: {
        id: `${scope}serbia`,
        defaultMessage: `Serbia`,
    },
    seychelles: {
        id: `${scope}seychelles`,
        defaultMessage: `Seychelles`,
    },
    sierraLeone: {
        id: `${scope}sierraLeone`,
        defaultMessage: `SierraLeone`,
    },
    singapore: {
        id: `${scope}singapore`,
        defaultMessage: `Singapore`,
    },
    slovakia: {
        id: `${scope}slovakia`,
        defaultMessage: `Slovakia`,
    },
    slovenia: {
        id: `${scope}slovenia`,
        defaultMessage: `Slovenia`,
    },
    solomonIslands: {
        id: `${scope}solomonIslands`,
        defaultMessage: `Solomon Islands`,
    },
    somalia: {
        id: `${scope}somalia`,
        defaultMessage: `Somalia`,
    },
    southAfrica: {
        id: `${scope}southAfrica`,
        defaultMessage: `South Africa`,
    },
    southGeorgiaAndSandwichIsl: {
        id: `${scope}southGeorgiaAndSandwichIsl`,
        defaultMessage: `South Georgia and Sandwich Island`,
    },
    spain: {
        id: `${scope}spain`,
        defaultMessage: `Spain`,
    },
    sriLanka: {
        id: `${scope}sriLanka`,
        defaultMessage: `Sri Lanka`,
    },
    sudan: {
        id: `${scope}sudan`,
        defaultMessage: `Sudan`,
    },
    suriname: {
        id: `${scope}suriname`,
        defaultMessage: `Suriname`,
    },
    svalbardAndJanMayen: {
        id: `${scope}svalbardAndJanMayen`,
        defaultMessage: `Svalbard and Jan Mayen`,
    },
    swaziland: {
        id: `${scope}swaziland`,
        defaultMessage: `Swaziland`,
    },
    sweden: {
        id: `${scope}sweden`,
        defaultMessage: `Sweden`,
    },
    switzerland: {
        id: `${scope}switzerland`,
        defaultMessage: `Switzerland`,
    },
    syrianArabRepublic: {
        id: `${scope}syrianArabRepublic`,
        defaultMessage: `Syrian Arab Republic`,
    },
    taiwan: {
        id: `${scope}taiwan`,
        defaultMessage: `Taiwan`,
    },
    tajikistan: {
        id: `${scope}tajikistan`,
        defaultMessage: `Tajikistan`,
    },
    tanzania: {
        id: `${scope}tanzania`,
        defaultMessage: `Tanzania`,
    },
    thailand: {
        id: `${scope}thailand`,
        defaultMessage: `Thailand`,
    },
    timorLeste: {
        id: `${scope}timorLeste`,
        defaultMessage: `Timor Leste`,
    },
    togo: {
        id: `${scope}togo`,
        defaultMessage: `Togo`,
    },
    tokelau: {
        id: `${scope}tokelau`,
        defaultMessage: `Tokelau`,
    },
    tonga: {
        id: `${scope}tonga`,
        defaultMessage: `Tonga`,
    },
    trinidadAndTobago: {
        id: `${scope}trinidadAndTobago`,
        defaultMessage: `Trinidad and Tobago`,
    },
    tunisia: {
        id: `${scope}tunisia`,
        defaultMessage: `Tunisia`,
    },
    turkey: {
        id: `${scope}turkey`,
        defaultMessage: `Turkey`,
    },
    turkmenistan: {
        id: `${scope}turkmenistan`,
        defaultMessage: `Turkmenistan`,
    },
    turksAndCaicosIslands: {
        id: `${scope}turksAndCaicosIslands`,
        defaultMessage: `Turks and Caicos Islands`,
    },
    tuvalu: {
        id: `${scope}tuvalu`,
        defaultMessage: `Tuvalu`,
    },
    uganda: {
        id: `${scope}uganda`,
        defaultMessage: `Uganda`,
    },
    ukraine: {
        id: `${scope}ukraine`,
        defaultMessage: `Ukraine`,
    },
    unitedArabEmirates: {
        id: `${scope}unitedArabEmirates`,
        defaultMessage: `UAE / United Arab Emirates`,
    },
    unitedKingdom: {
        id: `${scope}unitedKingdom`,
        defaultMessage: `United Kingdom`,
    },
    unitedStates: {
        id: `${scope}unitedStates`,
        defaultMessage: `United States`,
    },
    unitedStatesOutlyingIslands: {
        id: `${scope}unitedStatesOutlyingIslands`,
        defaultMessage: `United States outlying Islands`,
    },
    uruguay: {
        id: `${scope}uruguay`,
        defaultMessage: `Uruguay`,
    },
    uzbekistan: {
        id: `${scope}uzbekistan`,
        defaultMessage: `Uzbekistan`,
    },
    vanuatu: {
        id: `${scope}vanuatu`,
        defaultMessage: `Vanuatu`,
    },
    venezuela: {
        id: `${scope}venezuela`,
        defaultMessage: `Venezuela`,
    },
    vietNam: {
        id: `${scope}vietNam`,
        defaultMessage: `Vietnam`,
    },
    virginIslandsBritish: {
        id: `${scope}virginIslandsBritish`,
        defaultMessage: `British Virgin Islands`,
    },
    virginIslandsUS: {
        id: `${scope}virginIslandsUS`,
        defaultMessage: `US Virgin Islands`,
    },
    wallisAndFutuna: {
        id: `${scope}wallisAndFutuna`,
        defaultMessage: `Wallis and Futuna`,
    },
    westernSahara: {
        id: `${scope}westernSahara`,
        defaultMessage: `Western Sahara`,
    },
    // yemen: {
    //     id: `${scope}yemen`,
    //     defaultMessage: `Yemen`,
    // },
    // zambia: {
    //     id: `${scope}zambia`,
    //     defaultMessage: `Zambia`,
    // },
    // zimbabw: {
    //     id: `${scope}zimbabw`,
    //     defaultMessage: `Zimbabw`,
    // },
    // italy: {
    //     id: scope + 'italy',
    //     defaultMessage: 'Italy',
    // },
    // spain: {
    //     id: scope + 'spain',
    //     defaultMessage: 'Spain',
    // },
    // turkey: {
    //     id: scope + 'turkey',
    //     defaultMessage: 'Turkey',
    // },
    // russia: {
    //     id: scope + 'russia',
    //     defaultMessage: 'Russia',
    // },
    // syria: {
    //     id: scope + 'syria',
    //     defaultMessage: 'syria',
    // },
    other: {
        id: scope + 'other',
        defaultMessage: 'Other, please specify below',
    },
});
