import React, { FC, useMemo } from 'react';
import { useFormat } from '../../utilities/intl';
import {
    doctorSpecializationMessages,
    nursingSpecializationMessages,
    socialWorkerSpecializationMessages,
} from '../../utilities/messages';
import { CPicker } from '../elements';
import { MessageDescriptor } from 'react-intl';
import { EProfession } from '../../enums';
import { pickerMessages } from './picker.messages';
import { View } from 'react-native';
import { keyof } from '../../utilities/functions';

interface ISpecializationPickerProps {
    multi?: boolean;
    onChange: (next: string[] | string) => void;
    values?: string[];
    value?: string;
    titleOverride?: string;
    cy?: string;
    validity?: string | MessageDescriptor | undefined;
    profession: EProfession;
}
/**
 * picker for specializations
 * will use value: string if not supplied with multi prop
 * @param param0
 * @returns
 */
export const SpecializationPicker: FC<ISpecializationPickerProps> = ({
    value,
    values,
    onChange,
    cy,
    titleOverride,
    validity,
    profession,
    multi,
}) => {
    const format = useFormat();
    /**
     * select edu messages
     */
    const specializations = useMemo(() => {
        switch (profession) {
            case EProfession.doctor:
                return doctorSpecializationMessages;
            case EProfession.nurse:
                return nursingSpecializationMessages;
            case EProfession.teacher:
                return {};
            case EProfession.socialWorker:
                return socialWorkerSpecializationMessages;
            case EProfession.undefined:
                return {};
            default:
                return {};
        }
    }, [profession]);
    /**
     * memoized specialization keys
     */
    const specializationKeys = useMemo(
        () => keyof(specializations),
        [specializations],
    );
    /**
     * values as empty array if undefined
     */
    const safeValues = useMemo(() => {
        return values || [];
    }, [values]);
    /**
     * memoized specializations filtered educations for picker options and sorted
     */
    const filteredSpecializations = useMemo(
        () =>
            specializationKeys
                .map((v) => {
                    return {
                        label: format(specializations[v]),
                        value: v,
                    };
                })
                .sort((a, b) => a.label.localeCompare(b.label)),
        [safeValues, specializations],
    );
    /**
     * return a empty view if there never where any contents to pick from
     */
    if (!specializationKeys.length) {
        return <View />;
    }
    /**
     * render
     */
    return (
        <CPicker
            cy={cy || 'specialization-picker'}
            title={titleOverride || format(pickerMessages.specialization)}
            value={multi ? '' : value || ''}
            values={filteredSpecializations}
            validity={validity}
            onChange={(spec: string) => {
                if (!spec) {
                    return;
                }
                console.log(spec);
                if (multi) {
                    if (!safeValues.includes(spec)) {
                        onChange([...safeValues, spec].sort());
                    }
                } else {
                    onChange(spec);
                }
            }}
            multiValues={(multi && safeValues) || undefined}
            onChangeMulti={
                (multi &&
                    ((next) => {
                        onChange([...next]);
                    })) ||
                undefined
            }
            placeholder={`-${format(
                pickerMessages.specializationPlaceholder,
            )}-`}
            keepOpen={multi}
            multiLimit={3}
        />
    );
};
