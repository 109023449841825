import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { CCard, CIcon, CLabel, CText } from '../../../../components';
import { ProfilePicture } from '../../../../components/ProfilePicture';
import { useFormat } from '../../../../utilities/intl';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { useSecureNavigate } from '../../../../utilities/routing';
import { fieldMessages, generalMessages } from '../../../../utilities/messages';
import { MAgency, MProfessionalProfile } from '../../../../models';
import { profProfileMessages } from '../../ProfProfile.messages';
import { profileMessages } from '../../../Profile/Profile.messages';
import { convertNumberToCurrencyString } from '../../../../utilities/functions';
import { UserTypeLabel } from '../../../../components/UserTypeLabel';
import { useEnvironment } from '../../../../utilities/contexts';
import { useFireBase } from '../../../../utilities/firebase';
import { ECollections, EProfession } from '../../../../enums';
import { isSuperUser } from '../../../../utilities/auth';

interface IUserRow {
    professionalUser: MProfessionalProfile;
    handleChange: (next: MProfessionalProfile) => void;
}
/**
 * professionalUser row
 * @param param0
 * @returns
 */
export const ProfessionalUserRow: FC<IUserRow> = ({ professionalUser }) => {
    // global state
    const style = useStyle();
    const format = useFormat();
    const { theme } = useTheme();
    const { region } = useEnvironment();
    const { secureNavigate } = useSecureNavigate();
    const { getDataById, userData } = useFireBase();
    // local state
    const [agency, setAgency] = useState<MAgency>();
    /**
     * memoized hourly rate with double digit fix applied
     */
    const hourlyRate = useMemo(() => {
        const next =
            professionalUser.wage.wage + professionalUser.wage.regularWage;
        /**
         * this addition can fuck up so fix it
         */
        return Math.round(next * 100) / 100;
    }, [professionalUser]);
    /**
     * memoized talentprofile name
     */
    const name = useMemo(() => {
        if (professionalUser.firstName && professionalUser.lastName) {
            return `${professionalUser.firstName} ${professionalUser.lastName}`;
        } else if (professionalUser.lastName) {
            return professionalUser.lastName;
        } else {
            return '';
        }
    }, [professionalUser]);
    /**
     * effect to load agency for this row for admins
     */
    useEffect(() => {
        if (isSuperUser(userData)) {
            getDataById(ECollections.agencies, professionalUser.agencyId).then(
                (a) => {
                    setAgency(new MAgency(a));
                },
            );
        }
    }, [userData, professionalUser]);
    /**
     * render
     */
    return (
        <CCard
            style={[!!professionalUser.filledOn && style.accentBorder]}
            onPress={() =>
                secureNavigate(`/profUser/${professionalUser.documentId}`)
            }
            hoverEffects
        >
            <View style={style.horizontalSplit}>
                <View
                    style={[
                        style.horizontalSplit,
                        style.flex1,
                        style.horizontalWrap,
                    ]}
                >
                    <View style={[style.horizontalWrap]}>
                        <ProfilePicture data={professionalUser} isProfProfile />
                        <View style={style.horizontalPadded}>
                            <View style={style.horizontal}>
                                <UserTypeLabel
                                    data={professionalUser}
                                    piorizeEducation
                                />
                                {!!professionalUser.isWatching && (
                                    <CLabel
                                        checked={true}
                                        title={profProfileMessages.watching}
                                        color={theme.errorColor}
                                        smaller
                                    />
                                )}
                                {!!professionalUser.hasContract && (
                                    <CLabel
                                        checked={true}
                                        title={
                                            profProfileMessages.hasContractLabel
                                        }
                                        color={theme.successColor}
                                        smaller
                                    />
                                )}
                                {!!professionalUser.terminated &&
                                    (professionalUser.terminateOn >
                                    Date.now() ? (
                                        <CLabel
                                            checked={true}
                                            title={format(
                                                profProfileMessages.terminationPlanned,
                                                {
                                                    date: new Date(
                                                        professionalUser.terminateOn,
                                                    ).toLocaleDateString('de'),
                                                },
                                            )}
                                            color={theme.errorColor}
                                            smaller
                                        />
                                    ) : (
                                        <CLabel
                                            checked={true}
                                            title={
                                                profProfileMessages.terminated
                                            }
                                            color={theme.errorColor}
                                            smaller
                                        />
                                    ))}
                            </View>
                            {!!name && <CText secondaryHeadline>{name}</CText>}
                            {/* {!!professionalUser.educations.length && (
                                <View
                                    style={[
                                        style.horizontalWrap,
                                        style.centeredItems,
                                    ]}
                                >
                                    <CIcon
                                        icon="school"
                                        size={16}
                                        tint={theme.textSecondaryColor}
                                    />
                                    <CText style={style.leftPadded}>
                                        {`${professionalUser.educations
                                            .map((edu) =>
                                                format(
                                                    educationMessages[
                                                        edu as keyof typeof educationMessages
                                                    ],
                                                ),
                                            )
                                            .join(', ')}`}
                                    </CText>
                                </View>
                            )} */}
                            <CText
                                message={format(
                                    professionalUser.profession ===
                                        EProfession.undefined
                                        ? fieldMessages[professionalUser.field]
                                        : generalMessages[
                                              professionalUser.profession
                                          ],
                                )}
                            />
                        </View>
                    </View>
                    <View style={{ alignItems: 'flex-end' }}>
                        {!!professionalUser.yearsOfExperience && (
                            <CText>
                                {`${
                                    professionalUser.yearsOfExperience
                                } ${format(profileMessages.yoe)}`}
                            </CText>
                        )}
                        {hourlyRate ? (
                            <CText>
                                {`${format(
                                    profProfileMessages.rate,
                                )} ${convertNumberToCurrencyString(
                                    hourlyRate,
                                    region,
                                )}`}
                            </CText>
                        ) : (
                            <CText message={profProfileMessages.noRate} />
                        )}
                        {!!professionalUser.availableHours && (
                            <CText>
                                {`${professionalUser.availableHours} ${format(
                                    generalMessages.hours,
                                )}`}
                            </CText>
                        )}
                    </View>
                </View>
                <View style={{ justifyContent: 'center' }}>
                    <CIcon icon="chevronRight" />
                </View>
            </View>
            {agency && (
                <View
                    style={[
                        style.horizontal,
                        style.centeredItems,
                        style.verticalPadded,
                    ]}
                >
                    <View style={{ paddingHorizontal: 17 }}>
                        <ProfilePicture data={agency} smaller />
                    </View>
                    <CText
                        message={agency.name}
                        secondaryHeadline
                        style={style.horizontalPadded}
                    />
                </View>
            )}
        </CCard>
    );
};
