import React, { FC, useContext } from 'react';
import { View } from 'react-native';
import { CText, Radio, TouchableView } from '../../components';
import { ExpandableCard } from '../../components/ExpandableCard';
import { CImage } from '../../components/elements/CImage';
import { ELanguage, EUserType } from '../../enums';
import { ScrollProvider } from '../../utilities/contexts/Scroll';
import { useFireBase } from '../../utilities/firebase';
import { LanguageContext, useFormat } from '../../utilities/intl';
import { generalMessages } from '../../utilities/messages/general.messages';
import { languageMessages } from '../../utilities/messages/language.messages';
import { useNavigate } from '../../utilities/routing';
import { useStyle, useTheme } from '../../utilities/styles';
import { AdminActions } from './components/AdminActions';
import { MailingList } from './components/MailingList';

export const Settings: FC = () => {
    const style = useStyle();
    const { theme } = useTheme();
    const { userData } = useFireBase();
    const { onChangeLanguage, language } = useContext(LanguageContext);
    const navigate = useNavigate();
    const format = useFormat();
    const { isDark, setIsDark } = useTheme();
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={[style.headlineCard]}>
                <CText bold headline>
                    {format(generalMessages.settings)}
                </CText>
            </View>
            <View style={style.card}>
                <CImage image="settings" />
            </View>
            <ExpandableCard
                head={
                    <>
                        <CText headline>
                            {format(generalMessages.profile)}
                        </CText>
                    </>
                }
                customOnClick={() => {
                    navigate('/profile/basic');
                }}
            />
            <View style={[style.card]}>
                <CText headline>{format(generalMessages.theme)}</CText>
                <Radio
                    value={isDark ? 'dark' : 'light'}
                    values={[
                        {
                            label: format(generalMessages.light),
                            value: 'light',
                        },
                        {
                            label: format(generalMessages.dark),
                            value: 'dark',
                        },
                    ]}
                    onChange={(v) => {
                        if (v === 'light') {
                            setIsDark(false);
                        } else {
                            setIsDark(true);
                        }
                    }}
                />
            </View>
            <View style={[style.card]}>
                <CText bold headline>
                    {format(generalMessages.language)}
                </CText>
                <View style={style.horizontal}>
                    <TouchableView
                        style={style.horizontalPadded}
                        onPress={() => onChangeLanguage(ELanguage.de)}
                    >
                        <CText
                            message={languageMessages.german}
                            style={
                                language === ELanguage.de && {
                                    color: theme.accentColor,
                                }
                            }
                        />
                        <CImage image="de" fixedHeight={20} />
                    </TouchableView>
                    <TouchableView
                        style={style.horizontalPadded}
                        onPress={() => onChangeLanguage(ELanguage.en)}
                    >
                        <CText
                            message={languageMessages.english}
                            style={
                                language === ELanguage.en && {
                                    color: theme.accentColor,
                                }
                            }
                        />
                        <CImage image="en" fixedHeight={20} />
                    </TouchableView>
                </View>
            </View>
            {userData.type === EUserType.admin && (
                <View style={style.card}>
                    <MailingList />
                </View>
            )}
            {userData.type === EUserType.admin && (
                <View style={style.card}>
                    <AdminActions />
                </View>
            )}
        </ScrollProvider>
    );
};
