import { defineMessages } from 'react-intl';

const scope = 'education.socialWorker.education.';
/**
 * social worker base educations
 * is used for socialWorkerEducation picker
 */
export const socialWorkerEducationMessages = defineMessages({
    kindergarten: {
        id: `${scope}kindergarten`,
        defaultMessage: 'Kindergarten teacher',
    },
    social: {
        id: `${scope}social`,
        defaultMessage: 'Social worker',
    },
    socialCare: {
        id: `${scope}socialCare`,
        defaultMessage: 'Social care worker',
    },
    housekeeping: {
        id: `${scope}housekeeping`,
        defaultMessage: 'Housekeeping and Consuming advisor',
    },
    theologie: {
        id: `${scope}theologie`,
        defaultMessage: 'Theologies and community work',
    },
    socialPedagogicalAssistant: {
        id: `${scope}socialPedagogicalAssistant`,
        defaultMessage: 'Pedagogical assistant',
    },
});
