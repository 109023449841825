import React, { FC, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { ICCarouselProps } from './props';
import { View, Pressable } from 'react-native';
import { second } from '../../../utilities/functions';
import { useStyle, useTheme } from '../../../utilities/styles';
import { CIcon } from '../CIcon';

export const CCarousel: FC<ICCarouselProps> = ({
    slides,
    autoPlay,
    autoPlaySpeed,
    vertical,
}) => {
    const style = useStyle();
    const { theme } = useTheme();
    const [width, setWidth] = useState(0);
    /**
     * render
     */
    return (
        <View
            style={[style.centeredItems, { width: '100%' }]}
            ref={(view) => {
                if (view) {
                    view.measure((x, y, nextWidth) => {
                        if (width !== nextWidth) {
                            setWidth(nextWidth);
                        }
                    });
                }
            }}
        >
            <Carousel
                showIndicators={true}
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                    if (isSelected) {
                        return (
                            <li
                                style={{
                                    background: theme.accentColor,
                                    width: 8,
                                    height: 8,
                                    borderRadius: 4,
                                    display: 'inline-block',
                                    margin: '0 4px',
                                }}
                                aria-label={`Selected: ${label} ${index + 1}`}
                                title={`Selected: ${label} ${index + 1}`}
                            />
                        );
                    }
                    return (
                        <li
                            style={{
                                background: theme.borderColor,
                                width: 8,
                                height: 8,
                                borderRadius: 4,
                                display: 'inline-block',
                                margin: '0 4px',
                            }}
                            onClick={onClickHandler}
                            onKeyDown={onClickHandler}
                            value={index}
                            key={index}
                            role="button"
                            tabIndex={0}
                            title={`${label} ${index + 1}`}
                            aria-label={`${label} ${index + 1}`}
                        />
                    );
                }}
                renderArrowPrev={(onClickHandler, hasPrev) =>
                    hasPrev && (
                        <Pressable
                            onPress={onClickHandler}
                            style={{
                                zIndex: 2,
                                top: 'calc(50% - 15px)',
                                width: 30,
                                height: 30,
                                position: 'absolute',
                                left: 0,
                                alignItems: 'center',
                            }}
                        >
                            <CIcon icon="chevronLeft" />
                        </Pressable>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext) =>
                    hasNext && (
                        <Pressable
                            onPress={onClickHandler}
                            style={{
                                position: 'absolute',
                                zIndex: 2,
                                top: 'calc(50% - 15px)',
                                width: 30,
                                height: 30,
                                right: 0,
                            }}
                        >
                            <CIcon icon="chevronRight" />
                        </Pressable>
                    )
                }
                showStatus={false}
                showThumbs={false}
                interval={second * (autoPlaySpeed || 6)}
                infiniteLoop
                autoPlay={autoPlay}
                swipeable
                width={width}
                axis={vertical ? 'vertical' : 'horizontal'}
            >
                {slides.filter((s) => !!s) as any}
            </Carousel>
        </View>
    );
};
