import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import {
    CButton,
    CCard,
    CImage,
    CPicker,
    CText,
    CTextInput,
    TouchableView,
} from '../../../components';
import { ECollections, ERegion } from '../../../enums';
import { MAgency } from '../../../models';
import { isAgencyUser, isSuperUser } from '../../../utilities/auth';
import { ScrollProvider } from '../../../utilities/contexts/Scroll';
import {
    useAdminDialog,
    useUnsavedChangesDialog,
} from '../../../utilities/dialog';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { actionMessages } from '../../../utilities/messages/action.messages';
import { generalMessages } from '../../../utilities/messages/general.messages';
import { useSecureNavigate } from '../../../utilities/routing';
import { useStyle } from '../../../utilities/styles';
import { agencyMessages } from '../agency.messages';
import { sortObjectKeys } from '../../../utilities/functions/sortObjectKeys';
import Clipboard from '@react-native-clipboard/clipboard';

export const UnwrappedAgencyCreate: FC = () => {
    const style = useStyle();
    const { secureNavigate, setNavigationLock } = useSecureNavigate();
    const format = useFormat();
    const { post, userData, reloadUserData } = useFireBase();
    const adminDialog = useAdminDialog();
    // local state
    const [agency, setAgency] = useState(new MAgency());
    /**
     * memoized check for unstaged changes
     */
    const unsavedChanges = useMemo(() => {
        /**
         * prev with to be sorted address
         */
        const prev = { address: {}, billingAddress: {} } as any;
        prev.address = sortObjectKeys(prev.address);
        prev.billingAddress = sortObjectKeys(prev.billingAddress);
        /**
         * current with to be sorted address
         */
        const current = agency || ({} as any);
        current.address = sortObjectKeys(current.address);
        current.billingAddress = sortObjectKeys(current.billingAddress);
        /**
         * compare sorted object for differences
         */
        const strcmp = JSON.stringify(prev) !== JSON.stringify(agency);
        /**
         * return conditions for unsaved changes
         */
        return strcmp;
    }, [agency]);
    /**
     * handle changes
     */
    const onChange = useCallback((next: Partial<MAgency>) => {
        if (next.address && !next.billingAddressDiffers) {
            next.billingAddress = next.address;
        }
        setAgency((prev) => {
            return new MAgency({ ...prev, ...next });
        });
    }, []);
    /**
     * handle saving of agency
     */
    const handleSave = useCallback(
        async (skiptNavigate?: boolean) => {
            if (isSuperUser(userData) && !(await adminDialog())) return;
            const postResult = await post(ECollections.agencies, {
                ...agency,
            });
            if (isAgencyUser(userData)) {
                reloadUserData();
            }
            if (!skiptNavigate) {
                secureNavigate('/agency/edit/' + postResult?.id, {
                    force: true,
                    replace: true,
                });
            }
        },
        [agency, userData],
    );
    /**
     * unsavedChanges dialog
     */
    const unsavedChangesDialog = useUnsavedChangesDialog(handleSave);
    /**
     * ask for discard of unsaved changes
     */
    const navigationLock = useCallback(async () => {
        const locking = unsavedChanges && !(await unsavedChangesDialog());
        return locking;
    }, [unsavedChanges, unsavedChangesDialog]);
    /**
     * register lock as current navigationlock
     */
    useEffect(() => setNavigationLock(navigationLock), [navigationLock]);
    /**
     * render
     */
    return (
        <>
            <View style={style.headlineCard}>
                <View style={[style.horizontalSplit, { width: '100%' }]}>
                    <View style={[style.horizontal, style.centeredItems]}>
                        <CButton
                            cy={'back'}
                            onPress={() => {
                                secureNavigate(-1);
                            }}
                            icon={'chevronLeft'}
                            small
                        />
                        <CText style={{ marginLeft: 5 }} bold headline>
                            {format(agencyMessages.creating)}
                        </CText>
                    </View>
                    {!!agency.documentId && (
                        <View style={{ alignItems: 'flex-end' }}>
                            <CText message={agencyMessages.agencyId} />
                            <TouchableView
                                onPress={() =>
                                    Clipboard.setString(agency.documentId)
                                }
                            >
                                <CText selectable message={agency.documentId} />
                            </TouchableView>
                        </View>
                    )}
                </View>
            </View>
            <CCard>
                {isSuperUser(userData) && (
                    <View
                        style={[
                            style.horizontal,
                            style.horizontalPadded,
                            style.centeredItems,
                        ]}
                    >
                        <View style={style.horizontalPadded}>
                            <CImage image={agency.region} fixedHeight={20} />
                        </View>
                        <CPicker
                            values={Object.values(ERegion).map((v) => ({
                                value: v,
                                label: v,
                            }))}
                            onChange={(v) => {
                                onChange({ region: v });
                            }}
                            value={agency.region}
                        />
                    </View>
                )}
                <CTextInput
                    cy={'agency-name'}
                    label={format(agencyMessages.name)}
                    value={agency.name}
                    onChangeText={(name) =>
                        onChange({
                            name,
                            bankDetails: {
                                ...agency.bankDetails,
                                accountHolderName: name,
                            },
                        })
                    }
                    autoExtend
                    disabled={!isSuperUser(userData)}
                />
                <CTextInput
                    cy={'tax-number'}
                    value={agency.taxNumber}
                    onChangeText={(taxNumber) => onChange({ taxNumber })}
                    // placeholder={format(profileMessages.workplacePlaceholder)}
                    label={format(agencyMessages.taxNumber)}
                    autoExtend
                    // customCheckInvalidity={() => taxNumberEmpty}
                />
                <CTextInput
                    cy={'register-number'}
                    value={agency.registerNumber}
                    onChangeText={(registerNumber) =>
                        onChange({ registerNumber })
                    }
                    // placeholder={format(profileMessages.workplacePlaceholder)}
                    label={format(agencyMessages.registerNumber)}
                    autoExtend
                    // customCheckInvalidity={() => registerNumberEmpty}
                />
            </CCard>
            <View style={[style.verticalPadded, style.centeredItems]}>
                {unsavedChanges && (
                    <CText style={style.warning}>
                        {format(generalMessages.unsavedChanges)}
                    </CText>
                )}
                <CButton
                    cy={'save'}
                    title={format(actionMessages.create)}
                    onPress={handleSave}
                />
            </View>
        </>
    );
};

export const AgencyCreate: FC = () => {
    const style = useStyle();
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <UnwrappedAgencyCreate />
        </ScrollProvider>
    );
};
