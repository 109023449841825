import React, { FC } from 'react';
import { CText, Spinner } from '../../../../components';
import { EApplicationType } from '../../../../enums/EApplicationType';
import { isSuperUser } from '../../../../utilities/auth';
import { useFireBase } from '../../../../utilities/firebase';
import { ContractContext } from './ContractContext';
import { AdminNegotiation } from './components';
import { useContractNegotiationState } from './useContractNegotiationState';
import { ShortNegotiation } from './components/ShortNegotiation';
/**
 * Contract Negotiation View. Will choose the right contract view on a per user type basis and provide context for contract data
 * @returns
 */
export const ContractNegotiationShort: FC = () => {
    // global state
    const { userData } = useFireBase();
    // local state
    const { originalContract, contract, onChange, handleClose, handleUndo } =
        useContractNegotiationState();
    /**
     * return spinner in case of no data
     */
    if (!contract || !originalContract) {
        return <Spinner />;
    }
    /**
     * return admin contract view for super users
     */
    if (isSuperUser(userData)) {
        return (
            <ContractContext.Provider
                value={{
                    contract,
                    originalContract,
                    onChange,
                    handleClose,
                    handleUndo,
                }}
            >
                <AdminNegotiation />
            </ContractContext.Provider>
        );
    }
    /**
     * return contract view for respsective user types
     */
    switch (contract.type) {
        case EApplicationType.user:
            return (
                <ContractContext.Provider
                    value={{
                        contract,
                        originalContract,
                        onChange,
                        handleClose,
                        handleUndo,
                    }}
                >
                    <ShortNegotiation />
                </ContractContext.Provider>
            );
        case EApplicationType.agency:
            return (
                <ContractContext.Provider
                    value={{
                        contract,
                        originalContract,
                        onChange,
                        handleClose,
                        handleUndo,
                    }}
                >
                    <ShortNegotiation />
                </ContractContext.Provider>
            );
        default:
            return <CText>ERROR</CText>;
    }
};
