import React, { FC } from 'react';
import { StyleProp, ViewStyle, View } from 'react-native';
import { CCheckBox } from '../elements/CCheckBox';
import { useStyle } from '../../utilities/styles';

interface IRadioProps {
    value: any;
    values: { value: any; label: string }[];
    onChange: (next: any) => void;
    layoutStyle?: StyleProp<ViewStyle>;
    cy?: string;
    horizontal?: boolean;
}

export const Radio: FC<IRadioProps> = ({
    value,
    values,
    onChange,
    layoutStyle,
    cy,
    horizontal,
}) => {
    const style = useStyle();
    return (
        <View style={[horizontal && style.horizontal, layoutStyle]}>
            {values.map((v, i) => {
                return (
                    <CCheckBox
                        cy={cy && `${cy}-${v.value}`}
                        key={i}
                        checked={v.value === value}
                        onCheckedChanged={() => {
                            onChange(v.value);
                        }}
                        title={v.label}
                        style={!!i && horizontal && { marginLeft: 10 }}
                    />
                );
            })}
        </View>
    );
};
