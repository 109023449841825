import React, { FC, useCallback, useEffect, useState } from 'react';
import { MAgency, MInvite, MUserData } from '../../../../models';
import { isSuperUser } from '../../../../utilities/auth';
import { useStyle } from '../../../../utilities/styles';
import { View } from 'react-native';
import { CText, InviteRow, CButton } from '../../../../components';
import { ECollections, EInviteStatus, EInviteType } from '../../../../enums';
import { useFireBase } from '../../../../utilities/firebase';
import { UserRow } from '../../../User/List/components/UserRow';
import { agencyMessages } from '../../agency.messages';
import { useDialog } from '../../../../utilities/dialog';
import { actionMessages } from '../../../../utilities/messages';

export const AgencyUsers: FC<{
    agency: MAgency;
    onChange: (next: Partial<MAgency>) => void;
}> = ({ agency, onChange }) => {
    const { post, getDataById, userData, getDataIndex } = useFireBase();
    const style = useStyle();
    const dialog = useDialog();
    // local state
    const [invites, setInvites] = useState<MInvite[]>([]);
    const [users, setUsers] = useState<MUserData[]>([]);
    /**
     * callback to remove user from users
     */
    const removeUser = useCallback(
        async (uid: string) => {
            if (
                await dialog({
                    title: agencyMessages.removeUser,
                    message: agencyMessages.removeUserText,
                    buttons: [{ text: actionMessages.remove }],
                    cancelButton: { text: actionMessages.cancel },
                })
            )
                onChange({ users: agency.users.filter((s) => s !== uid) });
        },
        [agency, onChange],
    );
    /**
     * load users for agency
     */
    useEffect(() => {
        Promise.all(
            agency.users.map(
                async (uid) =>
                    await getDataById(
                        isSuperUser(userData)
                            ? ECollections.users
                            : ECollections.publicUsers,
                        uid,
                    ),
            ),
        ).then((nextUsers) => setUsers(nextUsers.map((u) => new MUserData(u))));
    }, [agency, userData]);
    /**
     * load invites for agency
     */
    useEffect(() => {
        if (!agency.documentId) return;
        getDataIndex(
            `${ECollections.agencies}/${agency.documentId}/${ECollections.invites}`,
            {
                filter: [{ field: 'status', value: EInviteStatus.open }],
            },
        ).then((result) => {
            setInvites((result as any[]).map((r) => new MInvite(r)));
        });
    }, [agency]);
    /**
     * render
     */
    return (
        <>
            <CText secondaryHeadline message={agencyMessages.users} />
            {users.map((user) => {
                return (
                    <UserRow
                        key={user.documentId}
                        user={user}
                        handleChange={() => {
                            console.log('no');
                        }}
                        minimal={!isSuperUser(userData)}
                        embedded
                        onRemove={
                            (isSuperUser(userData) ||
                                user.documentId === agency.owner) &&
                            userData.documentId !== user.documentId &&
                            user.documentId !== agency.owner
                                ? () => removeUser(user.documentId)
                                : undefined
                        }
                    />
                );
            })}
            <CText secondaryHeadline message={agencyMessages.invites} />
            {invites.map((invite) => {
                return <InviteRow key={invite.documentId} invite={invite} />;
            })}
            <View style={style.horizontalSpaced}>
                <CButton
                    title={agencyMessages.createInvite}
                    onPress={() => {
                        const next = new MInvite({
                            targetId: agency.documentId,
                            type: EInviteType.agency,
                            authorId: userData.documentId,
                        });
                        post(
                            `${ECollections.agencies}/${agency.documentId}/${ECollections.invites}`,
                            next,
                        ).then((postResult) => {
                            if (postResult) {
                                next.documentId = postResult.id;
                                setInvites([...invites, next]);
                            }
                        });
                    }}
                />
            </View>
        </>
    );
};
