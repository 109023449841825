import { defineMessages } from 'react-intl';

const scope = 'medic.nurse.specialization.';
/**
 * nursingSpecialization names
 * is used for nursingSpecialization picker
 */
export const nursingSpecializationMessages = defineMessages({
    outpatientServiceOrEmergencyMedicine: {
        id: scope + 'outpatientServiceOrEmergencyMedicine',
        defaultMessage: 'Outpatient Service/Emergency Medicine',
    },
    outOfHospitalVentilation: {
        id: scope + 'outOfHospitalVentilation',
        defaultMessage: 'Out-of-hospital Ventilation',
    },
    endoscopyAndCardiacCatheterLaboratory: {
        id: scope + 'endoscopyAndCardiacCatheterLaboratory',
        defaultMessage: 'Endoscopy And Cardiac Catheter Laboratory',
    },
    specialistCertification1And2Sterilisation: {
        id: scope + 'specialistCertification1And2Sterilisation',
        defaultMessage: 'Specialist Certification 1 and 2 (Sterilisation)',
    },
    geriatrics: { id: scope + 'geriatrics', defaultMessage: 'Geriatrics' },
    gerontopsychiatry: {
        id: scope + 'gerontopsychiatry',
        defaultMessage: 'Gerontopsychiatry',
    },
    midwifeAndMaternityNurse: {
        id: scope + 'midwifeAndMaternityNurse',
        defaultMessage: 'Midwife/Maternity Nurse',
    },
    hygiene: { id: scope + 'hygiene', defaultMessage: 'Hygiene' },
    imc: { id: scope + 'imc', defaultMessage: 'IMC' },
    ITSAnaesthesia: {
        id: scope + 'ITSAnaesthesia',
        defaultMessage: 'ITS/Anaesthesia',
    },
    cardiotechnology: {
        id: scope + 'cardiotechnology',
        defaultMessage: 'Cardiotechnology',
    },
    paediatricITSNeonatologyLevel1To3AndPaediatricAnaesthesia: {
        id: scope + 'paediatricITSNeonatologyLevel1To3AndPaediatricAnaesthesia',
        defaultMessage:
            'Paediatric ITS (Neonatology Level 1-3)/Paediatric Anaesthesia',
    },
    paediatricNursing: {
        id: scope + 'paediatricNursing',
        defaultMessage: 'Paediatric Nursing',
    },
    nephrologyDialysis: {
        id: scope + 'nephrologyDialysis',
        defaultMessage: 'Nephrology/Dialysis',
    },
    op: { id: scope + 'op', defaultMessage: 'OP' },
    oncologyPalliativeMedicineHospiceCare: {
        id: scope + 'oncologyPalliativeMedicineHospiceCare',
        defaultMessage: 'Oncology/Palliative Medicine/Hospice Care',
    },
    nursingServiceDirector: {
        id: scope + 'nursingServiceDirector',
        defaultMessage: 'Nursing Service Director',
    },
    psychiatry: { id: scope + 'psychiatry', defaultMessage: 'Psychiatry' },
    qualityManagement: {
        id: scope + 'qualityManagement',
        defaultMessage: 'Quality Management',
    },
    RehabilitationAndLongTermCare: {
        id: scope + 'RehabilitationAndLongTermCare',
        defaultMessage: 'Rehabilitation and Long-Term Care',
    },
    wardManagement: {
        id: scope + 'wardManagement',
        defaultMessage: 'Ward Management',
    },
    radiationProtection: {
        id: scope + 'radiationProtection',
        defaultMessage: 'Radiation Protection',
    },
    strokeUnit: { id: scope + 'strokeUnit', defaultMessage: 'Stroke Unit' },
    studiesInHealthCare: {
        id: scope + 'studiesInHealthCare',
        defaultMessage: 'Studies in Health Care',
    },
    studyInNursing: {
        id: scope + 'studyInNursing',
        defaultMessage: 'Study in Nursing',
    },
    weaning: { id: scope + 'weaning', defaultMessage: 'Weaning' },
    residentialAreaManagement: {
        id: scope + 'residentialAreaManagement',
        defaultMessage: 'Residential Area Management',
    },
});
