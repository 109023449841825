import React, { FC, useEffect, useState } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './Editor.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

interface IRichTextEditorProps {
    text: string;
    onChange: (text: string) => void;
}

const RichTextEditor: FC<IRichTextEditorProps> = ({ text, onChange }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    /**
     * set editor state based on input text
     */
    useEffect(() => {
        const blocksFromHtml = htmlToDraft(text);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap,
        );
        const next = EditorState.createWithContent(contentState);
        setEditorState(next);
    }, []);
    /**
     * convert editor state to html and trigger on change
     */
    useEffect(() => {
        onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }, [editorState]);
    /**
     * render
     */
    return (
        <Editor
            editorState={editorState}
            toolbar={{
                options: [
                    'inline',
                    'blockType',
                    'fontSize',
                    'colorPicker',
                    'remove',
                    'list',
                ],
                inline: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: [
                        'bold',
                        'italic',
                        'underline',
                        'strikethrough',
                        // 'monospace',
                        // 'superscript',
                        // 'subscript',
                    ],
                },
                blockType: {
                    inDropdown: true,
                    options: [
                        'Normal',
                        'H1',
                        'H2',
                        'H3',
                        'H4',
                        'Blockquote',
                        'Code',
                    ],
                },
                fontSize: {
                    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60],
                },
                colorPicker: {
                    colors: ['rgb(90, 129, 250)', 'rgb(98, 193, 197)'],
                },
                list: {
                    options: ['unordered', 'ordered'],
                },
            }}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={(editorState) => {
                setEditorState(editorState);
            }}
        />
    );
};

export default RichTextEditor;
