import React, { FC, useCallback, useState } from 'react';
import { useFireBase } from '../../../../../utilities/firebase';
import { ECollections } from '../../../../../enums';
import { MJob } from '../../../../../models';
import { CButton } from '../../../../../components';

export const TouchJobs: FC = () => {
    const { getDataIndex, put } = useFireBase();

    const [touchingJobs, setTouchingJobs] = useState(false);
    /**
     * callback to touch all jobs
     */
    const touchJobs = useCallback(() => {
        setTouchingJobs(true);
        getDataIndex(ECollections.jobs).then((jobs) => {
            Promise.all(
                (jobs as MJob[]).map((job) => {
                    const next = new MJob(job);
                    return put(ECollections.jobs, job.documentId, {
                        ...next,
                        touched: Date.now(),
                    });
                }),
            ).then(() => setTouchingJobs(false));
        });
    }, [getDataIndex, put]);

    return (
        <CButton
            onPress={touchJobs}
            title="Touch all Jobs"
            warning
            disabled={touchingJobs}
        />
    );
};
