import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { CButton, CIcon, CText } from '../../../../components';
import { View, Platform } from 'react-native';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { MContract, MContractFile, MUserData } from '../../../../models';
import {
    actionMessages,
    filenameMessages,
    generalMessages,
} from '../../../../utilities/messages';
import { contractMessages } from '../../contract.messages';
import {
    getContractFileColor,
    isLocalhost,
    shouldUseEmulators,
} from '../../../../utilities/constants';
import { useFireBase } from '../../../../utilities/firebase';
import {
    isAgencyUser,
    isEmployer,
    isPeasant,
} from '../../../../utilities/auth';
import { ECollections, EUserType } from '../../../../enums';
import { useDialog } from '../../../../utilities/dialog';
import { useLocation, useNavigate } from '../../../../utilities/routing';
import { useLock } from '../../../../utilities/hooks';
import { requestCourierDialog } from './functions/requestCourierDialog';

interface IContractFileProps {
    file: MContractFile;
    contract: MContract;
    issueReload: () => void;
    signatureAvailable?: boolean;
    hideSignatures?: boolean;
}

export const ContractFile: FC<IContractFileProps> = ({
    file,
    contract,
    issueReload,
    signatureAvailable,
    hideSignatures,
}) => {
    // global state
    const style = useStyle();
    const {
        getFile,
        callFunction,
        userData,
        userAgencies,
        userWorkplaces,
        getDataById,
    } = useFireBase();
    const dialog = useDialog();
    const { theme } = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const { lock } = useLock();
    // local state
    const [author, setAuthor] = useState<MUserData>();
    /**
     * memoized signature position
     */
    const mySignaturePosition = useMemo(
        () =>
            file.signaturePositions.find((sP) =>
                sP.userId
                    ? userData.documentId === sP.userId
                    : isPeasant(userData)
                    ? sP.type === EUserType.user
                    : isEmployer(userData)
                    ? sP.type === EUserType.employer
                    : false,
            ),
        [file],
    );
    /**
     * memoize check if it is signed by all parties
     */
    const isSigned = useMemo(
        () => !file.signaturePositions.find((sP) => !sP.signed),
        [file],
    );
    /**
     * remove file callback
     */
    const removeFile = useCallback(async () => {
        if (
            !(await dialog({
                title: contractMessages.confirmDeletionOfContractFile,
                message: contractMessages.confirmDeletionOfContractFileText,
                cancelButton: { text: actionMessages.cancel },
                icon: 'warning',
            }))
        ) {
            return;
        }
        const unlock = lock();
        callFunction('removeContractFile', {
            fileId: file.documentId,
            contractId: contract.documentId,
        }).then(() => {
            issueReload();
            unlock();
        });
    }, [file, contract, issueReload]);
    /**
     * handle namirial
     */
    const handleNamirial = useCallback(async () => {
        if (
            await dialog({
                icon: 'namirial',
                title: contractMessages.signWithNamirial,
                message: contractMessages.signWithNamirialText,
                buttons: [{ text: actionMessages.ok }],
                cancelButton: { text: actionMessages.cancel },
            })
        ) {
            navigate(
                `/requestSigning?contractId=${contract.documentId}&fid=${file.documentId}&prev=${location.pathname}`,
            );
        }
    }, [file, contract, location]);
    /**
     * request signature using courier service
     */
    const handleCourier = useCallback(async () => {
        const resource = isEmployer(userData)
            ? userWorkplaces.find(
                  (wp) => wp.documentId === contract.workplaceId,
              )
            : isAgencyUser(userData)
            ? userAgencies[0]
            : undefined;

        const defaultAddress = resource ? resource.address : userData.address;
        const defaultRecipient = resource?.name || '';
        /**
         * dialog to set address informations
         */
        const result = await requestCourierDialog(
            dialog,
            defaultRecipient,
            defaultAddress,
        );
        if (result) {
            const unlock = lock();
            callFunction('requestCourierForFile', {
                ...result,
                contractId: contract.documentId,
                fileDocIds: file.documentId,
            }).then(() => {
                unlock();
                issueReload();
            });
        }
    }, [
        file,
        contract,
        location,
        userAgencies,
        userWorkplaces,
        userData,
        lock,
    ]);
    /**
     * callback to handle signature button press
     */
    const handleSignature = useCallback(async () => {
        let mode = 'courier';
        if (
            await dialog({
                icon: 'signature',
                title: contractMessages.selectSignatureType,
                message: contractMessages.selectSignatureTypeText,
                buttons: [
                    {
                        text: contractMessages.signUsingNamirial,
                        onPress: () => (mode = 'namirial'),
                        disabled: () => !signatureAvailable,
                    },
                    {
                        text: contractMessages.signUsingCourier,
                        onPress: () => (mode = 'courier'),
                    },
                ],
                verticalButtons: true,
                cancelButton: {
                    text: actionMessages.cancel,
                },
            })
        ) {
            if (mode === 'namirial') {
                handleNamirial();
            } else {
                handleCourier();
            }
        }
    }, [handleNamirial, handleCourier]);
    /**
     * callback to handle download of file (pre requests file access)
     */
    const handleDownload = useCallback(
        async (rerun = false) => {
            const unlock = lock();
            try {
                const res = await getFile(file.path);
                const fns = file.path.split('.');
                const type = fns[fns.length - 1];
                // @ts-ignore
                const blob = new Blob([res], {
                    type: type === 'pdf' ? 'application/pdf' : 'image/jpeg',
                });
                unlock();
                // @ts-ignore
                window.open(URL.createObjectURL(blob));
            } catch (e) {
                if (!rerun) {
                    await callFunction('requestFileAccess', {
                        fileId: file.documentId,
                        contractId: contract.documentId,
                    });
                    unlock();
                    await handleDownload(true);
                } else {
                    unlock();
                }
            }
        },
        [file],
    );
    /**
     * effect to load author data from public user data
     */
    useEffect(() => {
        getDataById(ECollections.publicUsers, file.author).then((ud) => {
            setAuthor(new MUserData(ud));
        });
    }, [file]);
    /**
     * render
     */
    return (
        <View>
            <View style={[style.horizontalSplit, style.centeredItems]}>
                <View
                    style={[
                        style.horizontalSplit,
                        style.centeredItems,
                        style.verticalPadded,
                        style.horizontalPadded,
                        style.flex1,
                    ]}
                >
                    <View style={{ flex: 1 }}>
                        <CText message={file.name} secondaryHeadline />
                        <View style={style.horizontalSplit}>
                            <View>
                                {!!author && (
                                    <View style={style.horizontal}>
                                        <CIcon
                                            icon="account"
                                            size={16}
                                            tint={theme.textSecondaryColor}
                                        />
                                        <CText
                                            message={
                                                author.firstName +
                                                ' ' +
                                                author.lastName
                                            }
                                        />
                                    </View>
                                )}
                                <View style={style.horizontal}>
                                    <CIcon
                                        icon="calendar_outline"
                                        size={16}
                                        tint={theme.textSecondaryColor}
                                    />
                                    <CText
                                        message={
                                            file.createdOn
                                                ? new Date(
                                                      file.createdOn,
                                                  ).toLocaleDateString('de')
                                                : generalMessages.unknown
                                        }
                                        style={{ flex: 1 }}
                                    />
                                </View>
                            </View>
                            <View>
                                <CText
                                    message={filenameMessages[file.type]}
                                    style={[
                                        {
                                            color: getContractFileColor(
                                                file.type,
                                            ),
                                            borderColor: getContractFileColor(
                                                file.type,
                                            ),
                                            borderWidth: 1,
                                            borderRadius: 3,
                                            padding: 2,
                                            fontSize: 12,
                                        },
                                        style.horizontalPadded,
                                    ]}
                                />
                            </View>
                        </View>
                    </View>
                </View>
            </View>
            <View style={[style.horizontal, { justifyContent: 'flex-end' }]}>
                {!file.envelopeId &&
                    !file.signatureRequest &&
                    file.author === userData.documentId && (
                        <CButton
                            onPress={removeFile}
                            icon={'close'}
                            transparent
                            mouseOverTitle={actionMessages.delete}
                            minor
                            iconColor={theme.errorColor}
                            style={{
                                marginHorizontal: 0,
                                padding: 5,
                                width: 'auto',
                            }}
                            fontStyles={{
                                color: theme.errorColor,
                            }}
                        />
                    )}
                {!hideSignatures && (
                    <>
                        {!!file.envelopeId &&
                            isNaN(+file.envelopeId) &&
                            isLocalhost() &&
                            shouldUseEmulators() &&
                            !isSigned && (
                                <CButton
                                    onPress={async () =>
                                        console.log(
                                            await callFunction(
                                                'getSignedDocument',
                                                {
                                                    envelopeId: file.envelopeId,
                                                    contractId:
                                                        contract.documentId,
                                                },
                                            ),
                                        )
                                    }
                                    icon={'eye'}
                                    minor
                                    transparent
                                />
                            )}
                        {/* {!file.envelopeId &&
                            file.path.split('.')[
                                file.path.split('.').length - 1
                            ] === 'pdf' &&
                            !file.signatureRequest &&
                            userData.documentId === file.author && (
                                <CButton
                                    onPress={handleSignature}
                                    icon="signature"
                                    minor
                                    transparent
                                    mouseOverTitle={
                                        contractMessages.requestSignature
                                    }
                                    style={{
                                        marginHorizontal: 0,
                                        padding: 5,
                                        width: 'auto',
                                    }}
                                />
                            )} */}
                        {/* SHOW COURIER REQUEST */}
                        {/* {!!file.signatureRequest &&
                            userData.documentId !== file.author &&
                            !file.signatureRequest.sent && (
                                <CButton
                                    onPress={showSignatureRequest}
                                    icon="signature"
                                    minor
                                    transparent
                                    style={{
                                        marginHorizontal: 0,
                                        width: 0,
                                    }}
                                />
                            )} */}
                        {/* SHOW NAMIRIAL SIGN BUTTON */}
                        {/* {!!file.envelopeId &&
                            !!mySignaturePosition &&
                            !isSigned &&
                            !mySignaturePosition.signed && (
                                <CButton
                                    onPress={() => {
                                        if (mySignaturePosition.url) {
                                            Linking.openURL(
                                                mySignaturePosition.url,
                                            );
                                        }
                                    }}
                                    icon="signature"
                                    minor
                                    transparent
                                    style={{ marginHorizontal: 0, width: 0 }}
                                    disabled={mySignaturePosition.signed}
                                />
                            )} */}
                        {/* SHOW SINGULAR CHECK IF ONLY YOU SIGNED */}
                        {!!file.envelopeId &&
                            !file.envelopeSigned &&
                            file.signedBy.includes(userData.documentId) && (
                                <View
                                    style={[
                                        style.centeredItems,
                                        style.centeredContent,
                                        { marginHorizontal: 25, width: 0 },
                                    ]}
                                >
                                    <CIcon icon="check" />
                                </View>
                            )}
                        {/* SHOW DOUBLE CHECK IF FILE (envelope containing this file) IS SIGNED */}
                        {!!file.envelopeId && file.envelopeSigned && (
                            <View
                                style={[
                                    style.centeredItems,
                                    style.centeredContent,
                                    { marginHorizontal: 25, width: 0 },
                                ]}
                            >
                                <CIcon
                                    icon="checkDouble"
                                    tint={theme.successColor}
                                />
                            </View>
                        )}
                    </>
                )}
                {Platform.OS === 'web' && (
                    <CButton
                        onPress={handleDownload}
                        icon="download"
                        mouseOverTitle={actionMessages.download}
                        minor
                        transparent
                        style={{
                            marginHorizontal: 0,
                            paddingHorizontal: 5,
                            width: 'auto',
                        }}
                    />
                )}
            </View>
            {/* {!!file.signatureRequest?.trackingUrl && (
                <CText
                    style={style.horizontalPadded}
                    message={file.signatureRequest.trackingUrl}
                />
            )} */}
            <View style={style.thinSeparator} />
        </View>
    );
};
