import { defineMessages } from 'react-intl';

const scope = 'con.referral.';

export const referralMessages = defineMessages({
    create: {
        id: scope + 'create',
        defaultMessage: 'Create your personal referral link',
    },
    gotToJobs: {
        id: scope + 'gotToJobs',
        defaultMessage: 'Go to jobs',
    },
    youCant: {
        id: scope + 'youCant',
        defaultMessage: 'You can not create a referral link',
    },
    youHaveToCompleteAJob: {
        id: scope + 'youHaveToCompleteAJob',
        defaultMessage:
            'You have to complete a Job before you can refer new users',
    },
    referrer: {
        id: scope + 'referrer',
        defaultMessage: 'Referrer',
    },
    referee: {
        id: scope + 'referee',
        defaultMessage: 'Referee',
    },
    referredOn: {
        id: scope + 'referredOn',
        defaultMessage: 'Referred on',
    },
    referralComplete: {
        id: scope + 'referralComplete',
        defaultMessage: 'Referral complete',
    },
    referralPaid: {
        id: scope + 'referralPaid',
        defaultMessage: 'Paid',
    },
    yourLink: {
        id: scope + 'yourLink',
        defaultMessage: 'Your link',
    },
});
