import React, { FC, useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { useStyle } from '../../../utilities/styles';
import { CPicker } from '../CPicker';
/**
 * time picker consisting of 2 pickers.
 * one featuring 24 hours and one featuring 60 minuts.
 * operates on a date object.
 * @param param0
 * @returns
 */
export const CSplitTimePicker: FC<{
    maxDate?: number;
    minDate?: number;
    cy?: string;
    onChange: (value: Date) => void;
    value?: Date;
}> = ({ value, onChange, minDate, maxDate, cy }) => {
    // global state
    const style = useStyle();
    // local state
    /**
     * memoized currently selected minute
     */
    const selectedMinute = useMemo(() => {
        return value?.getMinutes() || 0;
    }, [value]);
    /**
     * callback to change minute value
     */
    const setMinute = useCallback(
        (nm: number) => {
            const next = new Date(value || Date.now());
            next.setMinutes(nm);
            onChange(next);
        },
        [value, onChange],
    );
    /**
     * memoized currently selected hour
     */
    const selectedHour = useMemo(() => {
        return value?.getHours() || 0;
    }, [value]);
    /**
     * callback to change hour value
     */
    const setHour = useCallback(
        (nh: number) => {
            const next = new Date(value || Date.now());
            const maxDateDate = maxDate && new Date(maxDate);
            if (
                maxDateDate &&
                nh === maxDateDate.getHours() &&
                next.getMinutes() > maxDateDate.getMinutes()
            ) {
                next.setMinutes(maxDateDate.getMinutes());
            }
            const minDateDate = minDate && new Date(minDate);
            if (
                minDateDate &&
                nh === minDateDate.getHours() &&
                next.getMinutes() < minDateDate.getMinutes()
            ) {
                next.setMinutes(minDateDate.getMinutes());
            }
            next.setHours(nh);
            onChange(next);
        },
        [value, onChange, maxDate, minDate],
    );
    /**
     * memoized valid hour values
     */
    const hourValues = useMemo(() => {
        const lowerLimit = minDate ? new Date(minDate).getHours() : 0;
        const upperLimit = maxDate ? new Date(maxDate).getHours() || 24 : 24;
        return Array.from(new Array(24)).map((_, i) => ({
            label: `${i.toLocaleString('de', { minimumIntegerDigits: 2 })}`,
            value: i,
            disabled: lowerLimit > i || upperLimit < i,
        }));
    }, [minDate, maxDate, value]);
    /**
     * memoized available minute values
     */
    const minuteValues = useMemo(() => {
        const lowerLimit = minDate ? new Date(minDate).getMinutes() : 0;
        if (minDate) {
        }
        const enforceLowerLimit = !!(
            minDate && value?.getHours() === new Date(minDate).getHours()
        );
        const upperLimit = maxDate ? new Date(maxDate).getMinutes() || 60 : 60;
        const enforceUpperLimit = !!(
            maxDate && value?.getHours() === new Date(maxDate).getHours()
        );

        return Array.from(new Array(12)).map((_, i) => ({
            label: `${(i * 5).toLocaleString('de', {
                minimumIntegerDigits: 2,
            })}`,
            value: i * 5,
            disabled:
                (enforceLowerLimit && lowerLimit > i * 5) ||
                (enforceUpperLimit && upperLimit < i * 5),
        }));
    }, [minDate, maxDate, value]);
    /**
     * render
     */
    return (
        <View style={style.horizontal}>
            <CPicker
                values={hourValues}
                onChange={setHour}
                value={selectedHour}
                noPlaceholder
                cy={cy && cy + 'hour'}
            />
            <CPicker
                values={minuteValues}
                onChange={setMinute}
                value={selectedMinute}
                noPlaceholder
                cy={cy && cy + 'minute'}
            />
        </View>
    );
};
