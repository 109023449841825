import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    useNavigate,
    useParams,
    useSearchParams,
} from '../../../utilities/routing';
import {
    MContract,
    MFile,
    MJob,
    MProfessionalProfile,
    MRates,
    MUserFile,
} from '../../../models';
import { MApplication } from '../../../models/MApplication';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { actionMessages } from '../../../utilities/messages';
import { applicationMessages } from '../application.messages';
import {
    EApplicationStatus,
    EApplicationType,
    ECollections,
    EContractStatus,
    EEmployeeFiles,
    EUserType,
} from '../../../enums';
import { useDialog } from '../../../utilities/dialog';
import {
    convertNumberToCurrencyString,
    day,
} from '../../../utilities/functions';
import { useEnvironment } from '../../../utilities/contexts';
import { useFileUpload } from '../../../utilities/hooks/useFileUpload';
import { IParamOptions } from '../../../utilities/firebase/store';
import { useLock } from '../../../utilities/hooks';
/**
 * apply screen
 * @returns
 */
export const useApplicationCreateState = () => {
    // global state
    const { region, environment } = useEnvironment();
    const navigate = useNavigate();
    const { jobId } = useParams<{ id: string; jobId: string }>();
    const [searchParams] = useSearchParams();
    const {
        post,
        put,
        getDataById,
        userData,
        getDataIndex,
        callFunction,
        reloadUserData,
        userAgencies,
    } = useFireBase();
    const format = useFormat();
    const dialog = useDialog();
    const { lock } = useLock();
    const fileUpload = useFileUpload();
    // local state
    const [expandResume, setExpandResume] = useState(false);
    const [job, setJob] = useState<MJob>();
    const [wage, setWage] = useState(new MRates());
    const [customDocumentPath, setCustomDocumentPath] = useState<string>();
    const [profProfiles, setProfProfiles] = useState<MProfessionalProfile[]>(
        [],
    );
    const [currentProfile, setCurrentProfile] =
        useState<MProfessionalProfile>();
    const [applications, setApplications] = useState<MApplication[]>([]);
    const [contracts, setContracts] = useState<MContract[]>([]);
    const [from, setFrom] = useState(Date.now());
    const [to, setTo] = useState(Date.now() + day);
    const [fromNow, setFromNow] = useState(false);
    const [profileDistances, setProfileDistances] = useState<
        { profileId: string; distance: number }[]
    >([]);
    const [files, setFiles] = useState<MFile[]>([]);
    /**
     * memoized application preview
     */
    const application = useMemo(
        () =>
            new MApplication(
                currentProfile?.documentId
                    ? {
                          type: EApplicationType.agency,
                          profileId: currentProfile.documentId,
                          profession: currentProfile.profession,
                          educations: currentProfile.educations,
                          position: currentProfile.position,
                          yearsOfExperience: currentProfile.yearsOfExperience,
                          specializations: currentProfile.specializations,
                          languages: currentProfile.languages,
                          gender: currentProfile.gender,
                      }
                    : {
                          profession: userData.profession,
                          educations: userData.educations,
                          position: userData.position,
                          yearsOfExperience: userData.yearsOfExperience,
                          specializations: userData.specializations,
                          languages: userData.languages,
                          gender: userData.gender,
                      },
            ),
        [currentProfile],
    );
    /**
     * memoized best matching existing profile
     */
    const matchingProfile = useMemo(
        () =>
            profProfiles.find(
                (pp) =>
                    !!pp.educations.find((ppe) =>
                        job?.employeeEducations.includes(ppe),
                    ),
            ),
        [profProfiles, job],
    );
    /**
     * memoized check if the job is overlapping with other open applications
     */
    const overlappingApplications = useMemo(() => {
        if (!job) return [];
        return applications.filter(
            (a) =>
                a.status === EApplicationStatus.open &&
                !!job.activeDates.find((ad) => a.job.activeDates.includes(ad)),
        );
    }, [applications, job]);
    /**
     * memoized check if the job is overlapping with other open contracts
     */
    const overlappingContracts = useMemo(() => {
        if (!job) return [];
        return contracts.filter(
            (c) => !!job.activeDates.find((ad) => c.activeDates.includes(ad)),
        );
    }, [contracts, job]);
    /**
     * memoized filtered contracts for actives and negotiatings
     */
    const [overlappingActiveContracts, overlappingNegotiations] = useMemo(
        () =>
            overlappingContracts.reduce(
                (acc, c) => {
                    if (c.status === EContractStatus.signed) {
                        acc[0].push(c);
                    } else {
                        acc[1].push(c);
                    }
                    return acc;
                },
                [[], []] as MContract[][],
            ),
        [overlappingContracts],
    );
    /**
     * callback to create a matching profile
     */
    const createMatchingProfile = useCallback(() => {
        if (!userAgencies.length || !job) return;
        const next = new MProfessionalProfile({
            moderators: [userData.documentId],
            educations: job.employeeEducations,
            specializations: job.employeeSpecializations,
            profession: job.employeeProfession,
            position: job.employeePosition,
            yearsOfExperience: job.employeeYoe,
            field: job.employeeField,
            agencyId: userAgencies[0].documentId,
            temp: true,
        });
        post(ECollections.profProfiles, next).then((response) => {
            if (response) {
                setProfProfiles([
                    ...profProfiles,
                    { ...next, documentId: response.id },
                ]);
            }
        });
    }, [job, userData, userAgencies, profProfiles]);
    /**
     * callback to handle a freshly uploaded resume file
     */
    const handleNewResumeFile = useCallback(
        async (newFn: string, file: any) => {
            if (userData.type === EUserType.user) {
                const baseFile = await fileUpload(
                    `${ECollections.users}/${userData.documentId}/temp`,
                    newFn,
                    file,
                );
                await callFunction('setUserFile', {
                    filename: baseFile.name,
                    path: baseFile.path,
                    fileType: EEmployeeFiles.resume,
                });
                reloadUserData();
            } else if (userData.type === EUserType.agency && currentProfile) {
                const unlock = lock();
                const baseFile = await fileUpload(
                    `${ECollections.agencies}/${currentProfile.agencyId}/profiles/${currentProfile.documentId}`,
                    newFn,
                    file,
                );
                const next = new MUserFile({
                    ...baseFile,
                    type: EEmployeeFiles.resume,
                });
                const postResult = await post(
                    `${ECollections.profProfiles}/${currentProfile.documentId}/${ECollections.files}`,
                    next,
                );
                if (!postResult) return unlock();
                next.documentId = postResult.id;
                setCustomDocumentPath(baseFile.path);
                setFiles((prev) => [...prev, next]);
                unlock();
            }
        },
        [currentProfile, userData, profProfiles, fileUpload],
    );
    /**
     * callback to issue dialogues pre application
     */
    const askForConfirmation = useCallback(async () => {
        return (
            (currentProfile?.temp &&
                !(await dialog({
                    icon: 'question',
                    title: applicationMessages.confirmTempProfile,
                    message: applicationMessages.confirmTempProfileText,
                    cancelButton: { text: actionMessages.cancel },
                    buttons: [{ text: actionMessages.ok }],
                }))) ||
            (overlappingActiveContracts.length &&
                !(await dialog({
                    icon: 'warning',
                    title: currentProfile
                        ? applicationMessages.ensureYourTalentIsAvailable
                        : applicationMessages.ensureYouAreAvailable,
                    message: currentProfile
                        ? applicationMessages.overlappingContractsTalent
                        : applicationMessages.overlappingContracts,
                    cancelButton: { text: actionMessages.cancel },
                    buttons: [{ text: actionMessages.ok }],
                }))) ||
            (overlappingNegotiations.length &&
                !(await dialog({
                    icon: 'warning',
                    title: currentProfile
                        ? applicationMessages.ensureYourTalentIsAvailable
                        : applicationMessages.ensureYouAreAvailable,
                    message: currentProfile
                        ? applicationMessages.overlappingNegotiationsTalent
                        : applicationMessages.overlappingNegotiations,
                    cancelButton: { text: actionMessages.cancel },
                    buttons: [{ text: actionMessages.ok }],
                }))) ||
            (overlappingApplications.length &&
                !(await dialog({
                    icon: 'warning',
                    title: currentProfile
                        ? applicationMessages.ensureYourTalentIsAvailable
                        : applicationMessages.ensureYouAreAvailable,
                    message: currentProfile
                        ? applicationMessages.overlappingApplicationsTalent
                        : applicationMessages.overlappingApplications,
                    cancelButton: { text: actionMessages.cancel },
                    buttons: [{ text: actionMessages.ok }],
                })))
        );
    }, [
        currentProfile,
        overlappingActiveContracts,
        overlappingApplications,
        overlappingNegotiations,
    ]);
    /**
     * callback to handly apply action
     */
    const handleApply = useCallback(async () => {
        if (!userData.verified || !job) return navigate('/job');
        if (await askForConfirmation()) {
            return;
        }
        const v = await callFunction('createApplication', {
            jobId: job.documentId,
            profileId: currentProfile?.documentId || userData.documentId,
            // wage values
            ...wage,
            customDocumentPath: customDocumentPath,
            from,
            to,
            fromNow,
        });
        if (v) {
            await dialog({
                icon: 'success',
                title: applicationMessages.successfullyApplied,
                message: wage.wage
                    ? format(applicationMessages.successfullyAppliedWithRate, {
                          rate: convertNumberToCurrencyString(
                              wage.wage + wage.regularWage,
                              region,
                          ),
                      })
                    : applicationMessages.successfullyAppliedText,
                buttons: [{ text: actionMessages.ok }],
            });
            navigate('/application?open=true');
        }
    }, [
        userData,
        job,
        currentProfile,
        customDocumentPath,
        wage,
        from,
        to,
        fromNow,
        askForConfirmation,
    ]);
    /**
     * effect to kick unverified user from this view
     */
    useEffect(() => {
        if (userData.documentId) {
            if (!userData.verified) {
                dialog({
                    title: format(applicationMessages.youAreNotVerified),
                    message: format(applicationMessages.verifyYourself, {
                        platform: environment,
                    }),
                    buttons: [
                        {
                            text: format(actionMessages.ok),
                            onPress: () => {
                                navigate('/job');
                            },
                        },
                    ],
                    icon: 'error',
                }).then(() => {
                    navigate('/job');
                });
            }
        }
    }, [userData, environment]);
    /**
     * effect to load job details
     */
    useEffect(() => {
        if (jobId) {
            getDataById(ECollections.jobs, jobId).then((res) => {
                const j = new MJob(res);
                setJob(j);
                if (j.from > Date.now()) {
                    setFrom(j.from);
                    setTo(j.to || j.from + day);
                    // setFromNow(j.fromNowUntillUndefined);
                } else {
                    // setFromNow(true);
                }
            });
        }
    }, [jobId]);
    /**
     * effect to load available prof profiles
     */
    useEffect(() => {
        if (job && userData.type === EUserType.agency) {
            getDataIndex(ECollections.profProfiles, {
                filter: [
                    {
                        field: 'agencyId',
                        operator: 'in',
                        value: userAgencies.map((a) => a.documentId),
                    },
                    { field: 'temp', value: false },
                    { field: 'field', value: job.employeeField },
                ],
            }).then((res) => {
                setProfProfiles(
                    (res as []).map((v) => new MProfessionalProfile(v)),
                );
            });
        }
    }, [userAgencies, userData, job]);
    /**
     * effect to handle profile with wage
     */
    useEffect(() => {
        if (currentProfile?.wage.wage) {
            setWage(currentProfile.wage);
        }
    }, [currentProfile]);
    /**
     * effect to set initial prof profile from params
     */
    useEffect(() => {
        if (currentProfile) {
            return;
        }
        const profId = searchParams.get('pid');
        if (profId) {
            const profile = profProfiles.find((pp) => pp.documentId === profId);
            if (profile) {
                setCurrentProfile(profile);
            }
        } else if (matchingProfile) {
            setCurrentProfile(matchingProfile);
        }
    }, [profProfiles, searchParams, matchingProfile, currentProfile]);
    /**
     * effect to init application based on prev application, job, userData, prof profile
     */
    useEffect(() => {
        if (job && userData) {
            if (userData.type === EUserType.user) {
                setExpandResume(true);
            }
        }
    }, [job, userData]);
    /**
     * effect to load files
     */
    useEffect(() => {
        if (currentProfile) {
            getDataIndex(
                `${ECollections.profProfiles}/${currentProfile.documentId}/${ECollections.files}`,
            ).then((res) => {
                setFiles((res as MFile[]).map((r) => new MFile(r)));
            });
        } else if (userData) {
            setFiles(userData.files);
        }
    }, [userData, currentProfile]);
    /**
     * effect to load other applications for user or talent
     */
    useEffect(() => {
        const applicationParams: IParamOptions = {
            filter: [
                {
                    field: 'status',
                    operator: 'in',
                    value: [
                        EApplicationStatus.open,
                        EApplicationStatus.negotiating,
                        EApplicationStatus.accepted,
                    ],
                },
            ],
        };
        if (currentProfile) {
            applicationParams.filter?.push({
                field: 'profileId',
                value: currentProfile.documentId,
            });
            applicationParams.filter?.push({
                field: 'agencyId',
                operator: 'in',
                value: userAgencies.map((a) => a.documentId),
            });
        } else {
            applicationParams.filter?.push({
                field: 'applicantUid',
                value: userData.documentId,
            });
        }
        getDataIndex(ECollections.applications, {
            ...applicationParams,
        }).then((result) => {
            const next = (result as MApplication[]).map(
                (a) => new MApplication(a),
            );
            setApplications(next);
        });
    }, [currentProfile, userData, job, userAgencies]);
    /**
     * effect to load contracts based on loaded other applications
     */
    useEffect(() => {
        if (!applications) return;
        Promise.all(
            applications.map(async (a) => {
                if (!a.contractId) return undefined;
                return await getDataById(ECollections.contracts, a.contractId);
            }),
        ).then((result: any[]) => {
            const next = result
                .filter((r) => !!r)
                .map((r) => new MContract(r as MContract));
            setContracts(next);
        });
    }, [applications]);
    /**
     * effect to load prof profile distances
     */
    useEffect(() => {
        if (!job) return;
        if (profileDistances.length === profProfiles.length) return;
        const nextPromise = profProfiles.map(async (profile) => {
            if (!profile.address.lat || !profile.address.lng)
                return { distance: 0, profileId: profile.documentId };
            const distance = await callFunction('getJobDistance', {
                jobId: job.documentId,
                profileId: profile.documentId,
            });
            return { distance, profileId: profile.documentId };
        });
        Promise.all(nextPromise).then((next) => {
            setProfileDistances(next);
        });
    }, [profileDistances, profProfiles, job]);
    /**
     * return render relevant values
     */
    return {
        application,
        job,
        profProfiles,
        setCurrentProfile,
        profileDistances,
        currentProfile,
        matchingProfile,
        createMatchingProfile,
        customDocumentPath,
        setCustomDocumentPath,
        handleNewResumeFile,
        expandResume,
        setExpandResume,
        wage,
        setWage,
        handleApply,
        overlappingApplications,
        overlappingActiveContracts,
        overlappingNegotiations,
        from,
        setFrom,
        to,
        setTo,
        fromNow,
        setFromNow,
        files,
    };
};
