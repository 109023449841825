import React, { FC, ReactNode, useEffect, useState } from 'react';
import { View } from 'react-native';
import { MFile } from '../../../../models';
import { useFireBase } from '../../../../utilities/firebase';
import { ECollections } from '../../../../enums';
import { useStyle } from '../../../../utilities/styles';
import { CButton, CText } from '../../../../components';
import { Unsubscribe } from 'firebase/firestore';

export const SupportChatFileList: FC<{
    supportRequestId: string;
    filePickerComponent: ReactNode;
}> = ({ supportRequestId, filePickerComponent }) => {
    const style = useStyle();
    const { watchDataIndex, getFile, getDataIndex } = useFireBase();
    const [files, setFiles] = useState<MFile[]>([]);
    const [unsubscribe, setUnsubscribe] = useState<Unsubscribe>();
    /**
     * effect to enable watch
     */
    useEffect(() => {
        const collectionPath = `${ECollections.supportRequests}/${supportRequestId}/${ECollections.files}`;
        watchDataIndex(collectionPath, {}, () =>
            getDataIndex(collectionPath).then((res) =>
                setFiles((res as any[]).map((r) => new MFile(r))),
            ),
        ).then((us) => setUnsubscribe(() => us));
    }, [supportRequestId]);
    /**
     * effect to register unsubscribe as cleanup function
     * it gets triggered if react thinks it is time to clean up
     */
    useEffect(() => {
        return unsubscribe;
    }, [unsubscribe]);
    /**
     * render
     */
    return (
        <View>
            <View
                style={[
                    style.horizontal,
                    { width: '100%', justifyContent: 'flex-end' },
                ]}
            >
                {filePickerComponent}
            </View>
            {files.map((f) => (
                <View key={f.documentId}>
                    <View
                        style={[
                            style.horizontalSplit,
                            style.centeredItems,
                            style.verticalPadded,
                        ]}
                    >
                        <CText message={f.name} />
                        <CButton
                            small
                            onPress={() => {
                                getFile(f.path).then((res) => {
                                    const fns = f.path.split('.');
                                    const type = fns[fns.length - 1];
                                    // @ts-ignore
                                    const blob = new Blob([res], {
                                        type:
                                            type === 'pdf'
                                                ? 'application/pdf'
                                                : 'image/jpeg',
                                    });
                                    // @ts-ignore
                                    window.open(URL.createObjectURL(blob));
                                });
                            }}
                            icon="download"
                            minor
                            transparent
                        />
                    </View>
                    <View style={style.thinSeparator} />
                </View>
            ))}
        </View>
    );
};
