import React, { FC } from 'react';
import {
    View,
    Pressable,
    StyleProp,
    ViewStyle,
    GestureResponderEvent,
    LayoutChangeEvent,
} from 'react-native';
import { IDefaultProps } from '../../utilities/IDefaultProps';

interface ITouchabelViewProps extends IDefaultProps {
    onPress?: (event: GestureResponderEvent) => void;
    style?: StyleProp<ViewStyle> | Array<StyleProp<ViewStyle>>;
    /**
     * @deprecated is the same as style since switch from touchableOpacity to pressable
     */
    outsideStyle?: StyleProp<ViewStyle> | Array<StyleProp<ViewStyle>>;
    disabled?: boolean;
    cy?: string;
    nativeId?: string;
    onLayout?: (event: LayoutChangeEvent) => void;
    onRef?: (view: View) => void;
}

export const TouchableView: FC<ITouchabelViewProps> = (props) => {
    if (props.onPress && !props.disabled) {
        return (
            <Pressable
                nativeID={props.nativeId}
                style={[props.outsideStyle, props.style]}
                disabled={props.disabled}
                onPress={props.onPress}
                onLayout={props.onLayout}
                ref={(view) => {
                    if (!view || !props.onRef) return;
                    props.onRef(view);
                }}
                {...{
                    dataSet: { cy: props.cy },
                }}
            >
                {props.children}
            </Pressable>
        );
    } else {
        return (
            <View
                {...props}
                {...{ dataSet: { cy: props.cy } }}
                nativeID={props.nativeId}
            >
                {props.children}
            </View>
        );
    }
};
