import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useParams, useSecureNavigate } from '../../../utilities/routing';
import {
    Spinner,
    CButton,
    CText,
    AddressInput,
    CCheckBox,
    CTextInput,
    CPicker,
    CImage,
    CCard,
} from '../../../components';
import { ProfilePicture } from '../../../components/ProfilePicture';
import { MUserData, MWorkplace } from '../../../models';
import { isEmployer, isSuperUser } from '../../../utilities/auth';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { actionMessages } from '../../../utilities/messages/action.messages';
import { generalMessages } from '../../../utilities/messages/general.messages';
import { useStyle, useTheme } from '../../../utilities/styles';
import { workplaceMessages } from '../workplace.messages';
import { ECollections, ERegion } from '../../../enums';
import { useAdminDialog, useDialog } from '../../../utilities/dialog';
import { RichTextEditor, RichTextView } from '../../../components/RichText';
import { ScrollProvider } from '../../../utilities/contexts/Scroll';
import { sortObjectKeys } from '../../../utilities/functions/sortObjectKeys';
import { MyAgencies } from '../../Agency';
import { WorkplaceUsers } from './components/WorkplaceUsers';
import { WorkplaceDocuments } from './components/WorkplaceDocuments';

export const WorkplaceEdit: FC = () => {
    const style = useStyle();
    const { theme } = useTheme();
    const { secureNavigate, setNavigationLock } = useSecureNavigate();
    const format = useFormat();
    const { id } = useParams<{ id: string }>();
    const { post, put, getDataById, userData, reloadUserData } = useFireBase();
    const dialog = useDialog();
    const adminDialog = useAdminDialog();
    // local state
    const [curValidity, setCurValidity] = useState('');
    const [subValidity, setCurSubValidity] = useState('');
    const [workplace, setWorkplace] = useState(new MWorkplace());
    const [prevWorkplace, setPrevWorkplace] = useState<MWorkplace>();
    const [editingNote, setEditingNote] = useState(false);
    const [note, setNote] = useState('');
    const [prevNote, setPrevNote] = useState('');
    const [loading, setLoading] = useState(true);
    const [owner, setOwner] = useState<MUserData>();
    const isCreating = useMemo(() => id === 'new', [id]);
    /**
     * memoized check for unstaged changes
     */
    const unsavedChanges = useMemo(() => {
        if (!workplace) {
            return false;
        }
        /**
         * prev with to be sorted address
         */
        const prev =
            prevWorkplace || ({ address: {}, billingAddress: {} } as any);
        prev.address = sortObjectKeys(prev.address);
        prev.billingAddress = sortObjectKeys(prev.billingAddress);
        /**
         * current with to be sorted address
         */
        const current = workplace || ({} as any);
        current.address = sortObjectKeys(current.address);
        current.billingAddress = sortObjectKeys(current.billingAddress);
        /**
         * compare sorted object for differences
         */
        const strcmp = JSON.stringify(prev) !== JSON.stringify(current);
        /**
         * return conditions for unsaved changes
         */
        return !prevWorkplace || strcmp || prevNote !== note;
    }, [prevWorkplace, workplace, note, prevNote]);
    /**
     * handle changes
     */
    const onChange = useCallback((next: Partial<MWorkplace>) => {
        setWorkplace((prev) => {
            if (next.address && !prev.billingAddressDiffers) {
                next.billingAddress = next.address;
            }
            return { ...prev, ...next };
        });
    }, []);
    /**
     * validity functions
     */
    const ibanValidity = useCallback((IBAN: string) => {
        function smellsLikeIban(str: string) {
            return /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/.test(
                str,
            );
        }
        const ibanStripped = IBAN.replace(/[^A-Z0-9]+/gi, '') //keep numbers and letters only
            .toUpperCase(); //calculation expects upper-case
        if (IBAN && !smellsLikeIban(ibanStripped)) {
            return format(workplaceMessages.ibanInvalidError);
        }
    }, []);
    const bicValidity = useCallback((BIC: string) => {
        if (BIC && !/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(BIC)) {
            return format(workplaceMessages.bicInvalidError);
        }
    }, []);
    /**
     * handle save of note
     */
    const handleNoteSave = useCallback(async () => {
        if (!workplace) {
            return console.error('unexpected access to this function');
        }

        await put(ECollections.workplaceNotes, workplace.documentId, { note });

        setPrevNote(note);
    }, [workplace, note]);
    /**
     * handle save of workplace
     */
    const handleSave = useCallback(
        async (disable?: boolean) => {
            if (isSuperUser(userData) && !(await adminDialog())) return;

            const next = {
                ...workplace,
                owner: workplace.owner && owner ? owner.documentId : '',
            };
            if (next.owner && !next.users.includes(next.owner)) {
                next.users.push(next.owner);
            }
            next.users = next.users.filter(
                (v) => !!v.replace(/[^0-9a-z]/gi, ''),
            );
            if (isCreating) {
                await post(ECollections.workplaces, next);
            } else if (id) {
                await put(ECollections.workplaces, id, {
                    ...next,
                    disabled: !!disable,
                });
            } else {
                throw 'no id for this workplace';
            }
            if (isEmployer(userData)) {
                reloadUserData();
            }
            secureNavigate('/workplace', { force: true });
        },
        [workplace, owner, isCreating],
    );
    /**
     * ask for discard of unsaved changes
     */
    const navigationLock = useCallback(async () => {
        const locking =
            unsavedChanges &&
            !(await dialog({
                title: generalMessages.unsavedChanges,
                message: generalMessages.ifContinueTheyDiscard,
                cancelButton: {
                    text: actionMessages.back,
                },
                buttons: [
                    {
                        text: actionMessages.continueAndDiscard,
                        color: theme.errorColor,
                    },
                ],
                icon: 'warning',
            }));
        return locking;
    }, [unsavedChanges]);
    /**
     * register lock as current navigationlock
     */
    useEffect(() => setNavigationLock(navigationLock), [navigationLock]);
    /**
     * effect to load prev data
     */
    useEffect(() => {
        if (id && id !== 'new') {
            if (isSuperUser(userData)) {
                getDataById(ECollections.workplaceNotes, id).then((result) => {
                    if (result && result.note) {
                        setNote(result.note);
                        setPrevNote(result.note);
                    }
                });
            }
            getDataById(ECollections.workplaces, id).then((result) => {
                const nextWP = new MWorkplace(result);
                setWorkplace(nextWP);
                setPrevWorkplace(nextWP);
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, [id, userData]);
    /**
     * effect to load owner data
     */
    useEffect(() => {
        if (workplace.owner && workplace.owner !== owner?.documentId) {
            getDataById(ECollections.publicUsers, workplace.owner).then((n) =>
                n ? setOwner(new MUserData(n)) : setOwner(undefined),
            );
        } else if (owner && !workplace.owner) {
            setOwner(undefined);
        }
    }, [workplace, owner]);
    /**
     * loading indicator while loading
     */
    if (loading) {
        return <Spinner />;
    }
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={style.headlineCard}>
                <View style={[style.horizontal, style.centeredItems]}>
                    <CButton
                        cy={'back'}
                        onPress={() => {
                            secureNavigate(-1);
                        }}
                        icon={'chevronLeft'}
                        small
                    />
                    <CText
                        style={{ marginLeft: 5 }}
                        bold
                        headline
                        message={
                            isCreating
                                ? workplaceMessages.creating
                                : format(workplaceMessages.editing, {
                                      workplace: prevWorkplace?.name || '...',
                                  })
                        }
                    />
                </View>
            </View>
            <CCard>
                {!!workplace.documentId && (
                    <View style={style.verticalPadded}>
                        <CText message={workplaceMessages.picture} />
                        <View style={[style.horizontalSplit]}>
                            <View
                                style={[
                                    style.horizontalSplit,
                                    style.centeredItems,
                                ]}
                            >
                                <ProfilePicture
                                    data={workplace}
                                    large
                                    onChange={(picture) =>
                                        onChange({ picture })
                                    }
                                />
                            </View>
                            <View />
                        </View>
                    </View>
                )}
                {isSuperUser(userData) && (
                    <View
                        style={[
                            style.horizontal,
                            style.horizontalPadded,
                            style.centeredItems,
                        ]}
                    >
                        <View style={style.horizontalPadded}>
                            <CImage image={workplace.region} fixedHeight={20} />
                        </View>
                        <CPicker
                            values={Object.values(ERegion).map((v) => ({
                                value: v,
                                label: v,
                            }))}
                            onChange={(v) => {
                                onChange({ region: v });
                            }}
                            value={workplace.region}
                        />
                    </View>
                )}
                <CTextInput
                    cy={'workplace-name'}
                    label={workplaceMessages.name}
                    value={workplace.name}
                    onChangeText={(name) =>
                        onChange({
                            name,
                            bankDetails: {
                                ...workplace.bankDetails,
                                accountHolderName: name,
                            },
                        })
                    }
                    autoExtend
                    disabled={!isSuperUser(userData)}
                />
                {isSuperUser(userData) && (
                    <CTextInput
                        cy={'workplace-owner'}
                        label={workplaceMessages.owner}
                        value={workplace.owner}
                        onChangeText={(owner) =>
                            onChange({
                                owner,
                            })
                        }
                        autoExtend
                    />
                )}
                {isSuperUser(userData) && (
                    <>
                        <View style={style.verticalPadded}>
                            <CText>{format(workplaceMessages.note)}</CText>
                        </View>
                        {editingNote ? (
                            <RichTextEditor text={note} onChange={setNote} />
                        ) : (
                            <RichTextView html={note} />
                        )}
                        <View
                            style={[
                                style.horizontalSpaced,
                                style.verticalPadded,
                            ]}
                        >
                            {editingNote ? (
                                <CButton
                                    cy={'save-note'}
                                    icon={'check'}
                                    title={format(
                                        workplaceMessages.finishEditing,
                                    )}
                                    onPress={() => {
                                        handleNoteSave().then(() => {
                                            setEditingNote(false);
                                        });
                                    }}
                                />
                            ) : (
                                <CButton
                                    cy={'edit-note'}
                                    title={format(workplaceMessages.editNote)}
                                    onPress={() => {
                                        setEditingNote(true);
                                    }}
                                />
                            )}
                        </View>
                    </>
                )}
            </CCard>
            <CCard>
                <CText secondaryHeadline message={workplaceMessages.lead} />
                <CTextInput
                    cy={'workplace-leadName'}
                    label={workplaceMessages.leadName}
                    value={workplace.leadName}
                    onChangeText={(leadName) =>
                        onChange({
                            leadName,
                        })
                    }
                    autoExtend
                />
                <CTextInput
                    cy={'workplace-leadMail'}
                    label={workplaceMessages.mail}
                    value={workplace.leadMail}
                    onChangeText={(leadMail) =>
                        onChange({
                            leadMail,
                        })
                    }
                    autoExtend
                />
            </CCard>
            <CCard>
                <CText message={workplaceMessages.general} secondaryHeadline />
                <CTextInput
                    cy={'workplace-website'}
                    label={workplaceMessages.website}
                    value={workplace.website}
                    onChangeText={(website) =>
                        onChange({
                            website,
                        })
                    }
                    autoExtend
                />
                <CTextInput
                    cy={'workplace-phone'}
                    label={format(workplaceMessages.phone)}
                    value={workplace.phone}
                    onChangeText={(phone) =>
                        onChange({
                            phone,
                        })
                    }
                    autoExtend
                />
                <CTextInput
                    cy={'workplace-mail'}
                    label={format(workplaceMessages.mail)}
                    value={workplace.mail}
                    onChangeText={(mail) =>
                        onChange({
                            mail,
                        })
                    }
                    autoExtend
                />

                <CPicker
                    cy={'workplace-numberOfEmployees'}
                    title={workplaceMessages.numberOfEmployees}
                    values={[
                        {
                            label: format(workplaceMessages.bedPlaceholder),
                            value: '',
                        },
                        ...[
                            '0 - 50',
                            '50 - 100',
                            '101 - 250',
                            '251 - 500',
                            '501 -1000',
                            '1000+',
                        ].map((v) => {
                            return { value: v, label: v };
                        }),
                    ]}
                    value={workplace.numberOfEmployees}
                    onChange={(numberOfEmployees) =>
                        onChange({ numberOfEmployees })
                    }
                />
                {/* <CTextInput
                    value={workplace.bankDetails.IBAN}
                    onChangeText={(IBAN) =>
                        onChange({
                            bankDetails: {
                                ...workplace.bankDetails,
                                IBAN,
                            },
                        })
                    }
                    placeholder={format(workplaceMessages.ibanPlaceholder)}
                    label={format(workplaceMessages.iban)}
                    customCheckInvalidity={ibanValidity}
                    autoExtend
                />
                <CTextInput
                    value={workplace.bankDetails.BIC}
                    onChangeText={(BIC) =>
                        onChange({
                            bankDetails: {
                                ...workplace.bankDetails,
                                BIC,
                            },
                        })
                    }
                    placeholder={format(workplaceMessages.bicPlaceholder)}
                    label={format(workplaceMessages.bic)}
                    customCheckInvalidity={bicValidity}
                    autoExtend
                /> */}
            </CCard>
            <View style={style.card}>
                <AddressInput
                    cy={'workplace-address'}
                    title={workplaceMessages.address}
                    titleAsHeadline
                    address={workplace.address}
                    setAddress={(address) => onChange({ address })}
                />
                <View style={style.verticalPadded}>
                    <CCheckBox
                        cy={'workplace-abe'}
                        checked={workplace.billingAddressDiffers}
                        onCheckedChanged={(billingAddressDiffers) =>
                            onChange({ billingAddressDiffers })
                        }
                        title={workplaceMessages.billingAddressDiffers}
                    />
                </View>
                {workplace.billingAddressDiffers && (
                    <AddressInput
                        cy={'workplace-billingaddress'}
                        title={workplaceMessages.billingAddress}
                        address={workplace.billingAddress}
                        setAddress={(billingAddress) =>
                            onChange({ billingAddress })
                        }
                    />
                )}
            </View>
            <View style={style.card}>
                <WorkplaceDocuments workplace={workplace} onChange={onChange} />
            </View>
            {!!workplace.documentId && (
                <CCard>
                    <MyAgencies workplace={workplace} />
                </CCard>
            )}
            {!!workplace.documentId && (
                <CCard>
                    <WorkplaceUsers workplace={workplace} onChange={onChange} />
                </CCard>
            )}
            <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                {isSuperUser(userData) && id && !workplace.disabled && (
                    <CButton
                        cy={'delete'}
                        disabled={!!curValidity}
                        title={format(actionMessages.delete)}
                        onPress={() => handleSave(true)}
                        style={{ backgroundColor: theme.errorColor }}
                    />
                )}
                <View>
                    {!!curValidity && (
                        <View>
                            <CText
                                style={style.error}
                                message={generalMessages.somethingWrong}
                            />
                        </View>
                    )}
                    {unsavedChanges && (
                        <CText
                            style={style.warning}
                            message={generalMessages.unsavedChanges}
                        />
                    )}
                    <CButton
                        cy={'save'}
                        disabled={!!curValidity}
                        title={format(actionMessages.save)}
                        onPress={handleSave}
                    />
                </View>
            </View>
        </ScrollProvider>
    );
};
