import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams, useSecureNavigate } from '../../../utilities/routing';
import { MUserData } from '../../../models';
import { useFireBase } from '../../../utilities/firebase';
import {
    EUsersFilterMode,
    IUsersMoreFilter,
} from './components/UsersFilterBar';
import { ECollections, EField, EProfession, EUserType } from '../../../enums';
import { IFilter, IInequality } from '../../../utilities/firebase/store';
import { day } from '../../../utilities/functions';
import { ScrollContext } from '../../../utilities/contexts/Scroll';
/**
 * Unwrapped User List since it interacts in its state with the scroll provider it is supposed to provide
 */
export const useUserListState = () => {
    // global state
    const [searchParams, setParams] = useSearchParams();
    const { y, setY } = useContext(ScrollContext);
    const { getDataIndex, userData, getLength, getDataById } = useFireBase();
    const { setNavigationLock } = useSecureNavigate();
    // local state
    const [users, setUsers] = useState<MUserData[]>([]);
    const [totalUsersLength, setTotalUsersLength] = useState(0);
    const [currentUsersLength, setCurrUsersLength] = useState(0);
    const [loading, setLoading] = useState(true);
    const [lodaingMore, setLoadingMore] = useState(false);
    const [endReached, setEndReached] = useState(false);
    // filters
    const [fakeLimit, setFakeLimit] = useState<number>();
    const [mode, setMode] = useState<EUsersFilterMode[]>([]);
    const [moreFilter, setMoreFilter] = useState<IUsersMoreFilter>({});
    const [customStringFilter, setCustomStringFilter] =
        useState<{ field: string; value: string }>();
    /**
     * memoized query params to better control reload behavior
     */
    const queryParams = useMemo(() => {
        const { userType, education, position, profession, region, field } =
            moreFilter;
        const params = {
            filter: [] as IFilter[],
            inequalities: [] as IInequality[],
            orderBy: undefined as string | undefined,
            partialMatch: undefined as
                | { field: string; value: string }
                | undefined,
            asc: false,
        };
        /**
         * set inequality filters (they can not run parallel)
         * and handle custom string filtering (if id get id only)
         */
        if (mode.includes(EUsersFilterMode.today)) {
            params.inequalities.push({
                field: 'createdOn',
                value: Date.now() - day,
                operator: '>',
            });
            params.asc = false;
        } else if (
            customStringFilter &&
            customStringFilter.field &&
            customStringFilter.value
        ) {
            const { field, value } = customStringFilter;
            params.partialMatch = {
                field: field === 'zipCode' ? 'address.zipCode' : field,
                value,
            };
            params.asc = true;
        } else {
            params.orderBy = 'lastName';
            params.asc = true;
        }
        /**
         * assign equality filters
         */
        if (mode.includes(EUsersFilterMode.verified)) {
            params.filter.push({
                field: 'verified',
                value: true,
            });
        } else if (mode.includes(EUsersFilterMode.unverified)) {
            params.filter.push({
                field: 'verified',
                value: false,
            });
        }
        if (mode.includes(EUsersFilterMode.verifiedByMe)) {
            params.filter.push({
                field: 'verifiedFrom',
                value: userData.documentId,
            });
        }
        if (mode.includes(EUsersFilterMode.extraAvailable)) {
            params.filter.push({
                field: 'extraAvailable',
                value: true,
            });
        }
        if (mode.includes(EUsersFilterMode.extraMobile)) {
            params.filter.push({
                field: 'extraMobile',
                value: true,
            });
        }
        if (userType) {
            params.filter.push({
                field: 'type',
                value: userType,
            });
        }
        if (education || position) {
            if (education) {
                params.filter.push({
                    field: 'educations',
                    value: education,
                    operator: 'array-contains',
                });
            }
            if (position) {
                params.filter.push({
                    field: 'position',
                    value: position,
                });
            }
        } else if (profession) {
            params.filter.push({
                field: 'profession',
                value: profession,
            });
        } else if (field) {
            params.filter.push({
                field: 'field',
                value: field,
            });
        }
        if (region) {
            params.filter.push({
                field: 'region',
                value: region,
            });
        }
        if (mode.includes(EUsersFilterMode.disabled)) {
            params.filter.push({ field: 'disabled', value: true });
        } else {
            params.filter.push({ field: 'disabled', value: false });
        }
        return params;
    }, [mode, moreFilter, customStringFilter, userData]);
    /**
     * memoized length of current query settings to pick est total length for no filters
     */
    const length = useMemo(() => {
        return currentUsersLength;
    }, [currentUsersLength]);
    /**
     * calback to add a filtermode
     * ! removes mode if was set
     */
    const addMode = useCallback((nextMode: EUsersFilterMode) => {
        setMode((prev) => {
            const next = Array.from(prev);
            const prevIndex = next.indexOf(nextMode);
            if (prevIndex >= 0) {
                // remove if contained
                next.splice(prevIndex, 1);
            } else if (
                [
                    EUsersFilterMode.verified,
                    EUsersFilterMode.unverified,
                ].includes(nextMode)
            ) {
                // remove verified/unverified (opposite)
                const toRemove =
                    nextMode === EUsersFilterMode.verified
                        ? EUsersFilterMode.unverified
                        : EUsersFilterMode.verified;
                const indexToRemove = next.findIndex((v) => v === toRemove);
                if (indexToRemove >= 0) {
                    next.splice(indexToRemove, 1);
                }
                next.push(nextMode);
            } else {
                next.push(nextMode);
            }
            if (nextMode !== EUsersFilterMode.today) {
                // remove today if anything but today is added to the filter
                const prevIndexOfToday = next.indexOf(EUsersFilterMode.today);
                if (prevIndexOfToday >= 0) {
                    next.splice(prevIndexOfToday, 1);
                }
            }

            return next;
        });
    }, []);
    /**
     * load users or more users
     * queryparams are set separate memo hook
     */
    const loadMore = useCallback(
        async (offset?: MUserData) => {
            const params = queryParams;
            if (params.partialMatch?.field === 'id') {
                getDataById(ECollections.users, params.partialMatch.value).then(
                    (result) => {
                        if (result && Object.keys(result).length > 1) {
                            setUsers([new MUserData(result)]);
                            setLoading(false);
                            setEndReached(true);
                            setCurrUsersLength(1);
                        } else {
                            setUsers([]);
                            setLoading(false);
                            setEndReached(true);
                            setCurrUsersLength(0);
                        }
                    },
                );
                return;
            }
            /**
             * get length if no offset
             * default to est total length if no filter
             */
            if (
                !offset &&
                (params.partialMatch ||
                    params.inequalities.length + params.filter.length > 1 ||
                    (params.filter.length === 1 &&
                        params.filter[0].field === 'disabled' &&
                        params.filter[0].value === true))
            ) {
                getDataIndex(ECollections.users, {
                    ...params,
                    getLength: true,
                }).then((res) => {
                    setCurrUsersLength(res as number);
                });
            } else if (!offset) {
                setCurrUsersLength(totalUsersLength);
            }
            /**
             * query
             */
            getDataIndex(ECollections.users, {
                ...params,
                limit: +(!offset
                    ? +(fakeLimit && +fakeLimit > 10 ? fakeLimit : 10)
                    : 10),
                startDocumentId: offset ? offset.documentId : undefined,
            }).then((result) => {
                const next = (result as MUserData[]).map(
                    (r) => new MUserData(r),
                );
                if (!offset) {
                    setUsers(next);
                    setLoading(false);
                    setEndReached(false);
                } else {
                    setUsers((current) => {
                        const combined = [...current, ...next];
                        return combined;
                    });
                    setLoadingMore(false);
                    if (next.length === 0) {
                        setEndReached(true);
                    }
                }
            });
        },
        [queryParams, fakeLimit, totalUsersLength],
    );
    /**
     * change index
     */
    const handleChange = useCallback(
        (next: MUserData) => {
            setUsers((prev) => {
                const index = prev.findIndex(
                    (u) => u.documentId === next.documentId,
                );
                prev[index] = next;
                return Array.from(prev);
            });
        },
        [setUsers],
    );
    /**
     * navigation lock that doesnt lock to set search params on navigate
     */
    const preNavigate = useCallback(async () => {
        const { userType, education, position, field, profession } = moreFilter;
        /**
         * search params to set
         */
        const nextSp = [];
        if (mode.length) {
            nextSp.push(['mode', mode.join(',')]);
        }
        if (education) {
            nextSp.push(['education', education]);
        }
        if (userType) {
            nextSp.push(['userType', userType]);
        }
        if (position) {
            nextSp.push(['position', position]);
        }
        if (field) {
            nextSp.push(['field', field]);
        }
        if (profession) {
            nextSp.push(['profession', profession]);
        }
        if (customStringFilter && customStringFilter.value) {
            nextSp.push([
                'csf',
                `${customStringFilter.field},${customStringFilter.value}`,
            ]);
        }
        /**
         * if filter resulted in uneven length (%10) load more will be bugged if other filters are used
         */
        if (users.length > 10) {
            nextSp.push(['l', `${users.length}`]);
        }
        if (y) {
            nextSp.push(['y', `${y}`]);
        }
        setParams(nextSp as [string, string][], { replace: true });
        return false;
    }, [mode, moreFilter, customStringFilter, users, y]);
    /**
     * effect to setinitialstate (read from search params)
     */
    useEffect(() => {
        const fSplit = searchParams.get('mode')?.split(',');
        if (fSplit) {
            setMode(fSplit as EUsersFilterMode[]);
        } else {
            setMode([]);
        }
        const csfSplit = searchParams.get('csf')?.split(',');
        if (csfSplit) {
            setCustomStringFilter({
                field: csfSplit[0],
                value: csfSplit[1],
            });
        } else {
            setCustomStringFilter(undefined);
        }
        const nextMof: IUsersMoreFilter = {};
        const position = searchParams.get('position');
        if (position) {
            nextMof.position = position;
        }
        const education = searchParams.get('education');
        if (education) {
            nextMof.education = education;
        }
        const userType = searchParams.get('userType');
        if (userType) {
            nextMof.userType = userType as EUserType;
        }
        const profession = searchParams.get('profession');
        if (profession) {
            nextMof.profession = profession as EProfession;
        }
        const field = searchParams.get('field');
        if (field) {
            nextMof.field = field as EField;
        }
        const y = searchParams.get('y');
        if (y) {
            setTimeout(() => setY(+y), 1000);
        }
        setMoreFilter(nextMof);
        const nextL = searchParams.get('l');
        if (nextL && !isNaN(+nextL)) {
            setFakeLimit(+nextL);
        }
    }, [searchParams]);
    /**
     * effect to cleanse filters of invalid configs
     */
    useEffect(() => {
        if (
            Object.keys(moreFilter).length &&
            mode.includes(EUsersFilterMode.today)
        ) {
            addMode(EUsersFilterMode.today);
        } else if (
            (Object.keys(moreFilter).length || mode.length) &&
            customStringFilter
        ) {
            setCustomStringFilter(undefined);
        }
    }, [mode, moreFilter, customStringFilter]);
    /**
     * effect to trigger reload
     */
    useEffect(() => {
        setLoading(true);

        loadMore();
    }, [loadMore]);
    /**
     * register preNavigate as current navigationlock
     */
    useEffect(() => setNavigationLock(preNavigate), [preNavigate]);
    /**
     * load number of non disabled users
     */
    useEffect(() => {
        getLength('users').then((res) => {
            setTotalUsersLength(res as number);
        });
    }, [userData]);
    /**
     * return render relevant state
     */
    return {
        totalUsersLength,
        length,
        loading,
        lodaingMore,
        loadMore,
        users,
        handleChange,
        setLoadingMore,
        endReached,
        mode,
        setMode,
        addMode,
        moreFilter,
        setMoreFilter,
        customStringFilter,
        setCustomStringFilter,
    };
};
