import {
    RecaptchaVerifier,
    multiFactor,
    PhoneAuthProvider,
    PhoneMultiFactorGenerator,
} from 'firebase/auth';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useFireBase } from '../../utilities/firebase';
import {
    CButton,
    CText,
    CTextInput,
    InfoBox,
    PhoneNumberInput,
} from '../../components';
import { ECollections } from '../../enums';
import { useStyle, useTheme } from '../../utilities/styles';
import { profileMessages } from '../Profile/Profile.messages';
import { useSecureNavigate } from '../../utilities/routing';
import { generalMessages } from '../../utilities/messages';
import { minute } from '../../utilities/functions';
import { useDialog } from '../../utilities/dialog';
import { startMessages } from '../Start/start.messages';
import { multifactoreMessages } from './multifactor.messages';
import { useLock } from '../../utilities/hooks';

export const MultifactorSetup: FC = () => {
    // global state
    const dialog = useDialog();
    const { secureNavigate } = useSecureNavigate();
    const {
        auth,
        user,
        userData,
        put,
        reloadUserData,
        mfaInfo,
        reauth,
        currentAuthProviderId,
        logOut,
    } = useFireBase();
    const { lock } = useLock();
    const style = useStyle();
    const { theme } = useTheme();
    // local state
    const [phone, setPhone] = useState(userData.phone);
    const [pw, setPw] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [flowId, setFlowId] = useState('');
    const [currentTime, setCurrentTime] = useState(0);
    const [flowEnd, setFlowEnd] = useState(0);
    /**
     * memoized check for validity of phone number
     */
    const phoneValid = useMemo(() => {
        if (
            !phone.match(
                /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
            )
        ) {
            return profileMessages.phoneMalformatedError;
        }
    }, [phone]);
    /**
     * memoized button text shenanigains to lock buttun while cooldown
     */
    const buttonText = useMemo(() => {
        if (currentTime < flowEnd) {
            setTimeout(() => setCurrentTime(Date.now()), 1000);
            const remainingMS = flowEnd - currentTime;
            const remainingS = Math.floor(remainingMS / 1000);
            return `${remainingS} s`;
        } else {
            return profileMessages.requestVerificationCode;
        }
    }, [currentTime, flowEnd]);
    /**
     * callback to init flow
     */
    const requestVerification = useCallback(
        async (number: string) => {
            if (!user) {
                return;
            }
            const unlock = lock();
            if (number !== userData.phone) {
                put(ECollections.users, userData.documentId, {
                    ...userData,
                    phone: number,
                }).then(reloadUserData);
            }
            const recaptchaVerifier = new RecaptchaVerifier(
                auth,
                'recaptcha-container-id',
                {
                    size: 'invisible',
                },
            );
            try {
                await reauth(user, user.email || '', pw);
            } catch (e) {
                unlock();
                if (`${e}`.includes('(auth/wrong-password')) {
                    return dialog({
                        title: startMessages.wrongPassword,
                        message: startMessages.wrongHint,
                        icon: 'error',
                    });
                } else {
                    return dialog({
                        title: generalMessages.errorOccured,
                        message: `${e}`,
                        icon: 'error',
                    });
                }
            }
            try {
                const mfa = multiFactor(user);
                const multiFactorSession = await mfa.getSession();

                const strippedNumber = number.replace(/\D/g, '');

                // Specify the phone number and pass the MFA session.
                const phoneInfoOptions = {
                    phoneNumber: `+${strippedNumber}`,
                    session: multiFactorSession,
                };

                const phoneAuthProvider = new PhoneAuthProvider(auth);

                // Send SMS verification code && set flow id to state
                const fid = await phoneAuthProvider.verifyPhoneNumber(
                    phoneInfoOptions,
                    recaptchaVerifier,
                );
                console.log(fid);
                setFlowId(fid);
                setFlowEnd(Date.now() + minute);
                recaptchaVerifier.clear();
                unlock();
            } catch (e) {
                unlock();
                dialog({
                    title: generalMessages.somethingWrong,
                    message: `${e}`,
                    icon: 'error',
                });
            }
            // });
        },
        [auth, user, userData, pw],
    );
    /**
     * callback to finish current flow with code
     */
    const finishVerification = useCallback(
        async (code: string) => {
            if (!user || !flowId) {
                return;
            }
            const unlock = lock();
            try {
                // Ask user for the verification code. Then:
                const cred = PhoneAuthProvider.credential(flowId, code);
                const multiFactorAssertion =
                    PhoneMultiFactorGenerator.assertion(cred);

                console.log((multiFactorAssertion as any).credential.params);

                // Complete enrollment.
                await multiFactor(user).enroll(
                    multiFactorAssertion,
                    'Personal phone number',
                );
                unlock();
                dialog({
                    title: multifactoreMessages.successfullEnrolled,
                    message: multifactoreMessages.successfullEnrolledText,
                    icon: 'success',
                }).then(logOut);
            } catch (e) {
                unlock();
                return dialog({
                    title: generalMessages.errorOccured,
                    message: `${e}`,
                    icon: 'error',
                });
            }
        },
        [flowId, user],
    );

    if (mfaInfo) {
        return (
            <View style={style.paddedMainView}>
                <View style={style.card}>
                    <InfoBox
                        type="error"
                        message={generalMessages.alreadyMFA}
                    />
                </View>
            </View>
        );
    }
    /**
     * render
     */
    return (
        <View style={style.paddedMainView}>
            <View style={style.headlineCard}>
                <View style={[style.horizontal, style.centeredItems]}>
                    <CButton
                        cy={'back'}
                        onPress={async () => {
                            secureNavigate(-1);
                        }}
                        icon={'chevronLeft'}
                        small
                    />
                    <CText
                        style={style.horizontalPadded}
                        bold
                        headline
                        message={profileMessages.setup2FA}
                    />
                </View>
            </View>
            <View style={style.card}>
                {!user?.emailVerified && (
                    <InfoBox
                        type="error"
                        message={generalMessages.eMailNotVerified}
                    />
                )}
                {currentAuthProviderId === 'password' && (
                    <>
                        <InfoBox
                            message={
                                multifactoreMessages.putInYourPasswordFirst
                            }
                        />
                        <CTextInput
                            cy={'password'}
                            value={pw}
                            placeholder={startMessages.passwordPlaceholder}
                            onChangeText={setPw}
                            secure
                            autoExtend
                            label={startMessages.password}
                        />
                    </>
                )}
                <CText message={profileMessages.phone} />
                <PhoneNumberInput value={phone} onChange={setPhone} />
                <View style={style.horizontalSpaced}>
                    <View nativeID="recaptcha-container-id" />
                </View>
                {!!phoneValid && (
                    <View style={style.horizontalSpaced}>
                        <CText
                            message={phoneValid}
                            style={{ color: theme.errorColor }}
                        />
                    </View>
                )}
                {!phoneValid && (
                    <CButton
                        title={buttonText}
                        onPress={() => requestVerification(phone)}
                        disabled={
                            !user?.emailVerified ||
                            typeof buttonText === 'string' ||
                            !pw ||
                            !!phoneValid
                        }
                    />
                )}
                {!!flowId && (
                    <>
                        <CTextInput
                            label={profileMessages.verificationCode}
                            onChangeText={setVerificationCode}
                            value={verificationCode}
                            autoExtend
                        />
                        <CButton
                            title={profileMessages.confirmVerificationCode}
                            onPress={() => finishVerification(verificationCode)}
                            disabled={!verificationCode || !flowId}
                        />
                    </>
                )}
            </View>
        </View>
    );
};
