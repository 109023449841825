import React, { FC } from 'react';
import { View } from 'react-native';
import { useStyle, useTheme } from '../../utilities/styles';
import { CIcon } from '../elements';

export const StarDisplay: FC<{
    stars: number;
}> = ({ stars }) => {
    const style = useStyle();
    const { theme } = useTheme();
    return (
        <View style={style.horizontal}>
            {[1, 2, 3, 4, 5].map((i) => (
                <View key={i}>
                    <CIcon
                        icon={
                            stars >= i
                                ? 'star'
                                : stars < i && stars > i - 1 && stars !== i - 1
                                ? 'star_half'
                                : 'star_outline'
                        }
                        tint={
                            stars >= i ||
                            (stars < i && stars > i - 1 && stars !== i - 1)
                                ? theme.accentColor
                                : theme.textMainColor
                        }
                        size={24}
                    />
                </View>
            ))}
        </View>
    );
};
