export class MAddress {
    public street = '';

    public number = '';

    public city = '';

    public country = '';

    public zipCode = '';

    public lat?: number;

    public lng?: number;

    constructor(params: Partial<MAddress> = {}) {
        Object.assign(this, params);
    }
}
