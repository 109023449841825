import { defineMessages } from 'react-intl';

const scope = 'con.start.';

export const startMessages = defineMessages({
    invalidMail: {
        id: scope + 'invalidMail',
        defaultMessage: 'Mail is not formatted correctly',
    },
    mail: {
        id: scope + 'mail',
        defaultMessage: 'E-Mail',
    },
    mailPlaceholder: {
        id: scope + 'mailPlaceholder',
        defaultMessage: 'E.g.: example@web.site',
    },
    password: {
        id: scope + 'password',
        defaultMessage: 'Password',
    },
    confirmPassword: {
        id: scope + 'confirmPassword',
        defaultMessage: 'Confirm password',
    },
    passwordsDoNotMatch: {
        id: scope + 'passwordsDoNotMatch',
        defaultMessage: 'Passwords do not match',
    },
    passwordToShort: {
        id: scope + 'passwordToShort',
        defaultMessage: 'The password has to be at least 6 characters long',
    },
    passwordMustContainNumber: {
        id: scope + 'passwordMustContainNumber',
        defaultMessage: 'The password has to contain at least one number',
    },
    passwordMustContainCharacter: {
        id: scope + 'passwordMustContainCharacter',
        defaultMessage: 'The password has to contain at least one letter',
    },
    passwordPlaceholder: {
        id: scope + 'passwordPlaceholder',
        defaultMessage: 'Password',
    },
    confirmPasswordPlaceholder: {
        id: scope + 'confirmPasswordPlaceholder',
        defaultMessage: 'Password (again)',
    },
    forgotPassword: {
        id: scope + 'forgotPassword',
        defaultMessage: 'Forgot password?',
    },
    signInWithGoogle: {
        id: scope + 'signInWithGoogle',
        defaultMessage: 'Sign in with Google',
    },
    signUpToGetStarted: {
        id: scope + 'signUpToGetStarted',
        defaultMessage: 'Sign up to get started',
    },
    phoneCode: {
        id: scope + 'phoneCode',
        defaultMessage: '2FA phone code',
    },
    phoneCodeMessage: {
        id: scope + 'phoneCodeMessage',
        defaultMessage:
            'Check your phone for your two factor authentication code',
    },
    wrongUsername: {
        id: scope + 'wrongUsername',
        defaultMessage: 'The mail is not valid!',
    },
    wrongPassword: {
        id: scope + 'wrongPassword',
        defaultMessage: 'The combination of credentials is invalid!',
    },
    wrongCode: {
        id: scope + 'wrongCode',
        defaultMessage: 'The 2FA code is invalid!',
    },
    wrongHint: {
        id: scope + 'wrongHint',
        defaultMessage:
            'Please verify that your supplied credentials are valid and try again.',
    },
    weakPassword: {
        id: scope + 'weakPassword',
        defaultMessage: 'Weak password',
    },
    okPassword: {
        id: scope + 'okPassword',
        defaultMessage: 'Ok password',
    },
    strongPassword: {
        id: scope + 'strongPassword',
        defaultMessage: 'Strong password',
    },
    registerUserExistsError: {
        id: scope + 'registerUserExistsError',
        defaultMessage: 'The user already exists',
    },
    requestPasswordReset: {
        id: scope + 'requestPasswordReset',
        defaultMessage: 'Request password reset link',
    },
    aMailHasBeenSent: {
        id: scope + 'aMailHasBeenSent',
        defaultMessage: 'Check your inbox',
    },
    clickOnTheLink: {
        id: scope + 'clickOnTheLink',
        defaultMessage:
            'If the provided e-mail is associated with an account, a reset link has been sent to your inbox.',
    },
    noCredentials: {
        id: scope + 'noCredentials',
        defaultMessage: 'Please enter an e-mail and password.',
    },
});
