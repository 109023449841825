import React, { FC } from 'react';
import { CText } from '../elements/CText';
import { oocMessages } from './ooc.messages';
import { CIcon } from '../elements';
import { useTheme } from '../../utilities/styles';
import { View } from 'react-native';

export const OutOfContext: FC<{ component: string }> = ({ component }) => {
    const { theme } = useTheme();
    return (
        <View>
            <CIcon icon="close" tint={theme.errorColor} />
            <CText
                message={oocMessages.componentOutOfContext}
                variables={{ component }}
            />
        </View>
    );
};
