import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { MShiftPlan } from '../../models';
import { useParams, useSecureNavigate } from '../../utilities/routing';
import { CButton, CCard, CText, Spinner } from '../../components';
import { ECollections, EShiftPlannerType } from '../../enums';
import { PreSelect } from './components/PreSelect';
import { GroupSelect } from './components/default/GroupSelect';
import { WorkerSelect } from './components/default/WorkerSelect';
import { useStyle } from '../../utilities/styles';
import { shiftPlanMessages } from './shiftPlan.messages';
import { ScrollProvider } from '../../utilities/contexts';
import { CreatePlan } from './components/default/CreatePlan';
import { ShiftPlanDisplay } from './components/default/ShiftPlanDisplay';
import { View } from 'react-native';
import { useUnsavedChangesDialog } from '../../utilities/dialog';
import { useFireBase } from '../../utilities/firebase';
import { ChildCareGroupExtension } from './components/childcare/GroupExtension';
import { ChildCareWorkerExtension } from './components/childcare/WorkerExtension';

export const ShiftPlan: FC = () => {
    const { id, workplaceId } = useParams();
    const style = useStyle();
    const { secureNavigate, setNavigationLock } = useSecureNavigate();
    const { getDataById } = useFireBase();
    const [shiftPlan, setPlan] = useState(new MShiftPlan());
    const [loading, setLoading] = useState(true);
    const [edit, setEdit] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const goForward = useCallback(() => setCurrentIndex((i) => i + 1), []);
    const goBackward = useCallback(() => setCurrentIndex((i) => i - 1), []);

    const currentComponent = useMemo(() => {
        const props = { shiftPlan, setPlan, goForward, goBackward, setEdit };
        if (loading) {
            return <Spinner />;
        }
        if (!edit) {
            return <ShiftPlanDisplay {...props} />;
        }
        if (!currentIndex) {
            return <PreSelect {...props} />;
        }
        switch (shiftPlan.type) {
            case EShiftPlannerType.default:
                switch (currentIndex) {
                    case 1:
                        return <GroupSelect {...props} />;
                    case 2:
                        return <WorkerSelect {...props} />;
                    case 3:
                        //     return <EventSelect {...props} />;
                        // case 4:
                        return <CreatePlan {...props} />;
                }
            case EShiftPlannerType.childcare:
                switch (currentIndex) {
                    case 1:
                        return (
                            <GroupSelect
                                {...props}
                                extension={ChildCareGroupExtension}
                            />
                        );
                    case 2:
                        return (
                            <WorkerSelect
                                {...props}
                                extension={ChildCareWorkerExtension}
                            />
                        );
                    case 3:
                        //     return <EventSelect {...props} />;
                        // case 4:
                        return <CreatePlan {...props} />;
                }
        }
        /**
         * default return
         */
        return <ShiftPlanDisplay {...props} />;
    }, [shiftPlan, currentIndex, edit]);
    /**
     * unsaved changes dialog
     */
    const unsavedChangesDialog = useUnsavedChangesDialog();
    /**
     * ask for discard of unsaved changes
     */
    const navigationLock = useCallback(async () => {
        const locking = (edit || !id) && !(await unsavedChangesDialog());
        return locking;
    }, [unsavedChangesDialog, edit, id]);
    /**
     * effect to load workplace or set previous input values
     */
    useEffect(() => {
        if (!id || !workplaceId) {
            // TODO: load most recent shift plan for workplace
            setLoading(false);
            setEdit(true);
        } else {
            getDataById(
                `${ECollections.workplaces}/${workplaceId}/${ECollections.shiftPlans}`,
                id,
            ).then((r) => {
                setLoading(false);
                setEdit(false);
                setPlan(new MShiftPlan(r));
            });
        }
    }, [id]);
    /**
     * register lock as current navigationlock
     */
    useEffect(() => setNavigationLock(navigationLock), [navigationLock]);
    /**
     * return spinner during load
     */
    if (loading) {
        return <Spinner />;
    }
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <CCard>
                <View style={[style.horizontal, style.centeredItems]}>
                    <CButton
                        cy={'back'}
                        onPress={async () => {
                            secureNavigate(-1);
                        }}
                        icon={'chevronLeft'}
                        small
                    />
                    <CText
                        bold
                        headline
                        style={style.horizontalPadded}
                        message={shiftPlanMessages.createShiftPlanner}
                    />
                </View>
            </CCard>
            {currentComponent}
        </ScrollProvider>
    );
};
