import React, { FC, useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useCollectionIndex } from '../../../utilities/firebase/store/useIndex';
import { ECollections } from '../../../enums';
import { MMailingListEntry } from '../../../models/MMailingListEntry';
import { useStyle } from '../../../utilities/styles';
import { CButton, CCard, CText, CTextInput } from '../../../components';
import { useDialog } from '../../../utilities/dialog';
import { generalMessages } from '../../../utilities/messages';
import { useFireBase } from '../../../utilities/firebase';
/**
 * component to view and edit mailing list
 */
export const MailingList: FC = () => {
    const style = useStyle();
    const dialog = useDialog();
    const { post } = useFireBase();
    // local state
    const [input, setInput] = useState('');
    const [expanded, setExpanded] = useState(false);
    const params = useMemo(() => ({}), []);
    /**
     * persistend mailing list data
     */
    const mailingListData = useCollectionIndex(
        ECollections.mailingList,
        params,
    );
    /**
     * parsed persistend mailing list data
     */
    const mailingList = useMemo(
        () => mailingListData.map((d) => new MMailingListEntry(d)),
        [mailingListData],
    );
    /**
     * callback to consume input
     * ? valid JSON:
     * ? [{"name": "test", "zipCode": "30163", "mail": "me@nestoro.de" }]
     */
    const consumeInput = useCallback(async () => {
        try {
            const unparsedArray: [] = JSON.parse(input);
            unparsedArray.map((entry: any) => {
                post(
                    ECollections.mailingList,
                    new MMailingListEntry({
                        mail: entry.mail || '',
                        name: entry.name || '',
                        zipCode: entry.zipCode || '',
                    }),
                );
            });
        } catch (e) {
            await dialog({
                title: generalMessages.errorOccured,
                message: `${e}`,
                icon: 'error',
            });
        }
        setInput('');
    }, [input]);

    return (
        <View>
            <View style={[style.horizontal, style.centeredItems]}>
                <View style={style.flex1}>
                    <CTextInput
                        value={input}
                        onChangeText={setInput}
                        autoExtend
                    />
                </View>
                <CButton onPress={consumeInput} disableOnClick icon="upload" />
            </View>
            <CCard>
                <CButton
                    onPress={() => setExpanded(!expanded)}
                    minor={expanded}
                    icon={expanded ? 'chevronDown' : 'chevronRight'}
                />
                {expanded &&
                    mailingList.map((entry) => (
                        <View key={entry.documentId} style={style.horizontal}>
                            <CText message={entry.name} />
                            <CText message={entry.mail} />
                            <CText message={entry.zipCode} />
                        </View>
                    ))}
            </CCard>
        </View>
    );
};
