import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import {
    User,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    signInWithPopup,
    GoogleAuthProvider,
    sendPasswordResetEmail,
    PhoneAuthProvider,
    PhoneMultiFactorGenerator,
    getMultiFactorResolver,
    ApplicationVerifier,
    MultiFactorError,
    AuthCredential,
    reauthenticateWithCredential,
    EmailAuthProvider,
} from 'firebase/auth';

import { EFBSignInTypes } from './EFBSignInTypes';
import { IDefaultProps } from '../../IDefaultProps';
import { FireAuthContext } from './FireAuthContext';
import { FireAppContext } from '../app';
import { useNavigate } from '../../routing';
import { useDialog } from '../../dialog';
import { actionMessages } from '../../messages';
import { startMessages } from '../../../container/Start/start.messages';
import { LanguageContext } from '../../intl';

export const FireAuthProvider: FC<IDefaultProps> = ({ children }) => {
    const navigate = useNavigate();
    const { auth } = useContext(FireAppContext);
    const dialog = useDialog();
    const { language } = useContext(LanguageContext);
    const [curCred, setCurCred] = useState<AuthCredential>();
    /**
     * function to hook into authStateChangedEvent
     * @param cb callback to trigger with user data
     */
    const onAuthStateChanged = useCallback(
        (cb: (user?: User) => void) => {
            auth.onAuthStateChanged((res) => cb(res || undefined));
        },
        [auth],
    );
    /**
     * function to login
     * @param params type of login mail and password
     */
    const logIn = useCallback(
        async (params: {
            type?: EFBSignInTypes;
            mail?: string;
            password?: string;
        }) => {
            switch (params.type) {
                case EFBSignInTypes.google:
                    const provider = new GoogleAuthProvider();
                    auth.useDeviceLanguage();
                    try {
                        const result = await signInWithPopup(auth, provider);
                        // This gives you a Google Access Token. You can use it to access the Google API.
                        const credential =
                            GoogleAuthProvider.credentialFromResult(result);
                        if (credential) {
                            const token = credential.accessToken;
                            setCurCred(credential);
                            console.log(token);
                        }

                        return !!result;
                    } catch (error: any) {
                        // Handle Errors here.
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        // The email of the user's account used.
                        const email = error.customData.email;
                        // The AuthCredential type that was used.
                        const credential =
                            GoogleAuthProvider.credentialFromError(error);
                        console.log(errorCode, errorMessage, email, credential);
                        // ...
                        throw error;
                    }
                case EFBSignInTypes.userAndPassword:
                default:
                    if (!params.mail || !params.password) {
                        throw 'no credentials supplied';
                    } else {
                        const next = await signInWithEmailAndPassword(
                            auth,
                            params.mail,
                            params.password,
                        );
                        return !!next;
                    }
            }
        },
        [auth],
    );
    /**
     * callback for multifactor login using phone number
     */
    const multiFactorLogin = useCallback(
        async (
            error: MultiFactorError,
            recaptchaVerifier?: ApplicationVerifier,
        ) => {
            if (!recaptchaVerifier) {
                console.error('called mfa without recaptcha');
                return false;
            }
            const resolver = getMultiFactorResolver(auth, error);
            // Ask user which second factor to use.
            const selectedIndex = resolver.hints.findIndex(
                (v) => v.factorId === PhoneMultiFactorGenerator.FACTOR_ID,
            );
            const phoneInfoOptions = {
                multiFactorHint: resolver.hints[selectedIndex],
                session: resolver.session,
            };
            const phoneAuthProvider = new PhoneAuthProvider(auth);
            // Send SMS verification code
            const verificationId = await phoneAuthProvider.verifyPhoneNumber(
                phoneInfoOptions,
                recaptchaVerifier,
            );
            // Ask user for the SMS verification code.
            let code = '';
            await dialog({
                textInputs: [
                    {
                        id: 'code',
                        title: startMessages.phoneCode,
                        entercontinue: true,
                    },
                ],
                title: startMessages.phoneCode,
                message: startMessages.phoneCodeMessage,
                buttons: [
                    {
                        text: actionMessages.verify,
                        disabled: (inputs) =>
                            !inputs?.find((i) => i.id)?.value.length,
                        onPress: (inputs) =>
                            (code = inputs?.find((i) => i.id)?.value || ''),
                    },
                ],
                icon: 'phone',
            });
            // Then:
            const cred = PhoneAuthProvider.credential(verificationId, code);
            const multiFactorAssertion =
                PhoneMultiFactorGenerator.assertion(cred);
            // Complete sign-in.
            const next = await resolver
                .resolveSignIn(multiFactorAssertion)
                .catch((e) =>
                    dialog({
                        title: startMessages.wrongCode,
                        message: startMessages.wrongHint,
                        icon: 'error',
                    }),
                );

            return !!next;
        },
        [],
    );
    /**
     * function to register
     * @param params type of login mail and password
     */
    const register = useCallback(
        (params: { mail?: string; password?: string }) => {
            if (!params.mail || !params.password) {
                throw 'no credentials supplied';
            } else {
                return createUserWithEmailAndPassword(
                    auth,
                    params.mail,
                    params.password,
                );
            }
        },
        [auth],
    );
    /**
     * function to issue reset Password
     */
    const sendPwResetMail = useCallback(
        (user: User) => {
            if (user && user.email) {
                sendPasswordResetEmail(auth, user.email);
            }
        },
        [auth],
    );
    /**
     * function to log out
     */
    const logOut = useCallback(async () => {
        await signOut(auth);
        setCurCred(undefined);
        navigate('/');
    }, [auth]);
    /**
     * callback to issue reauth
     */
    const reauth = useCallback(
        (user: User, username?: string, password?: string) => {
            if (curCred && user?.providerId === 'google.com') {
                return reauthenticateWithCredential(user, curCred);
            } else if (username && password) {
                return reauthenticateWithCredential(
                    user,
                    EmailAuthProvider.credential(username, password),
                );
            }
        },
        [curCred],
    );
    /**
     * callback to refresh the token
     * - reloads custom claims
     */
    const refreshToken = useCallback(async () => {
        if (auth.currentUser) await auth.currentUser.getIdTokenResult(true);
    }, [auth]);
    /**
     * effect to set language on auth
     */
    useEffect(() => {
        auth.languageCode = language;
    }, [language]);
    /**
     * provide context
     */
    return (
        <FireAuthContext.Provider
            value={{
                onAuthStateChanged,
                logIn,
                multiFactorLogin,
                register,
                logOut,
                sendPwResetMail,
                reauth,
                currentAuthProviderId: curCred
                    ? curCred.providerId
                    : 'password',
                refreshToken,
            }}
        >
            {children}
        </FireAuthContext.Provider>
    );
};
