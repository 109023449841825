import React, { FC, useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { CCheckBox, CSplitTimePicker, CText } from '../../../components';
import { TouchableView } from '../../../components/TouchableView';
import { IDayAvailability } from '../../../models';
import { useStyle, useTheme } from '../../../utilities/styles';
import { calendarMessages } from '../Calendar.messages';

export interface IAllDayAvailabilityOnDaysProps {
    days: IDayAvailability[];
    startDate: Date;
    onChange: (next: IDayAvailability[]) => void;
    excludeWeekend?: boolean;
}
/**
 * day availability row for availability edit
 */
export const AllDayAvailabilityOnDays: FC<IAllDayAvailabilityOnDaysProps> = ({
    days,
    onChange,
    excludeWeekend,
    startDate,
}) => {
    const style = useStyle();
    const { theme } = useTheme();
    /**
     * from to from self or inherited
     */
    const { from, fromMinute, to, toMinute } = useMemo(() => days[0], [days]);
    /**
     * memoized time picker readable from
     */
    const fromDate = useMemo(
        () =>
            new Date(
                startDate.getFullYear(),
                startDate.getMonth(),
                startDate.getDate(),
                from,
                fromMinute,
            ),
        [startDate, from],
    );
    /**
     * memoized time picker readable from
     */
    const toDate = useMemo(
        () =>
            new Date(
                startDate.getFullYear(),
                startDate.getMonth(),
                startDate.getDate(),
                to === 24 ? 23 : to,
                toMinute,
            ),
        [startDate, to],
    );
    /**
     * callback to remove inheriting from all days
     */
    const stopInheriting = useCallback(() => onChange([]), [onChange, days]);
    /**
     * set from callback
     */
    const setFrom = useCallback(
        (from: number, fromMinute = 0) =>
            onChange(days.map((d) => ({ ...d, from, fromMinute }))),
        [onChange, days],
    );
    /**
     * set to callback
     */
    const setTo = useCallback(
        (to: number, toMinute = 0) =>
            onChange(days.map((d) => ({ ...d, to, toMinute }))),
        [onChange, days],
    );
    /**
     * render
     */
    return (
        <View
            style={[
                {
                    borderWidth: 1,
                    borderColor: 'transparent',
                    borderRadius: 10,
                    zIndex: 1,
                },
            ]}
        >
            <TouchableView
                style={[
                    style.horizontal,
                    style.centeredItems,
                    { paddingVertical: 10 },
                ]}
                onPress={stopInheriting}
            >
                <CCheckBox checked={true} onCheckedChanged={stopInheriting} />
                <CText
                    style={style.leftPadded}
                    secondaryHeadline
                    message={
                        excludeWeekend
                            ? calendarMessages.everyWeekDay
                            : calendarMessages.everyDay
                    }
                />
            </TouchableView>
            <View
                style={[
                    {
                        zIndex: 1,
                        borderWidth: 1,
                        borderColor: theme.borderColor,
                        borderRadius: 10,
                        backgroundColor: theme.backgroundSecondaryColor,
                        marginVertical: 10,
                    },
                ]}
            >
                <View
                    style={[
                        style.horizontalSplit,
                        style.horizontalHeavyPadded,
                        style.centeredItems,
                        { zIndex: 2 },
                    ]}
                >
                    <CText message={calendarMessages.from} />
                    <CSplitTimePicker
                        value={fromDate}
                        onChange={(next) =>
                            setFrom(next.getHours(), next.getMinutes())
                        }
                    />
                </View>
                <View style={style.thinSeparator} />
                <View
                    style={[
                        style.horizontalSplit,
                        style.horizontalHeavyPadded,
                        style.centeredItems,
                        { zIndex: 1 },
                    ]}
                >
                    <CText message={calendarMessages.to} />
                    <CSplitTimePicker
                        value={toDate}
                        onChange={(next) =>
                            setTo(next.getHours(), next.getMinutes())
                        }
                    />
                </View>
            </View>
        </View>
    );
};
