export class MReferral {
    referrer = '';
    referee = '';
    referredOn = 0;
    referralComplete = false;
    referralPaid = false;

    public editedOn = Date.now();
    public createdOn = Date.now();

    constructor(params: Partial<MReferral> = {}) {
        Object.assign(this, params);
    }
}
