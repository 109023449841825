import { MRates } from './MRates';

export class MNegotiationHistory {
    public documentId = '';

    public createdOn = Date.now();

    public author = '';

    public wage = new MRates();

    public from = 0;
    public to = 0;

    constructor(params: Partial<MNegotiationHistory> = {}) {
        Object.assign(this, params);

        if (params.wage) {
            this.wage = new MRates(params.wage);
        }
    }
}
