export class MSupportRequest {
    public createdOn = Date.now();
    public editedOn = Date.now();

    public state: 'open' | 'closed' | 'active' = 'open';

    public documentId = '';
    public userId = '';
    public chatId = '';
    public managedBy = '';
    public closedBy = '';

    public reason = '';

    constructor(params: Partial<MSupportRequest>) {
        Object.assign(this, params);
    }
}
