import { defineMessages } from 'react-intl';

const scope = 'lang.level.';

export const languageLevelMessages = defineMessages({
    a1: {
        id: scope + 'a1',
        defaultMessage: 'A1 - No Proficiency',
    },
    a2: {
        id: scope + 'a2',
        defaultMessage: 'A2 - Elementary Proficiency',
    },
    b1: {
        id: scope + 'b1',
        defaultMessage: 'B1 - Limited Working Proficiency',
    },
    b2: {
        id: scope + 'b2',
        defaultMessage: 'B2 - Professional Working Proficiency',
    },
    c1: {
        id: scope + 'c1',
        defaultMessage: 'C1 - Full Professional Proficiency',
    },
    c2: {
        id: scope + 'c2',
        defaultMessage: 'C2 - Native / Bilingual Proficiency',
    },
});
