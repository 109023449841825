import React, { FC, useContext, useEffect, useRef } from 'react';
import { View } from 'react-native';
import RCalendar from 'react-calendar';

import './Calendar.css';
import { LanguageContext } from '../../../utilities/intl';
import { ICCalendarProps } from './ICCalendarProps';
import { useStyle, useTheme } from '../../../utilities/styles';
import { useDimensions } from '../../../utilities/hooks/useDimensions';
import { CText } from '../CText';
import { calendarMessages } from './calendar.messages';
import { useFireBase } from '../../../utilities/firebase';
import { isAgencyUser, isEmployer } from '../../../utilities/auth';
import { EUserType } from '../../../enums';
import { useCalendarState } from './useCalendarState';

const radius = 8;
const offset = 24;
const bubbleStyle = {
    width: radius,
    height: radius,
    borderRadius: radius / 2,
    marginHorizontal: 2,
};
/**
 * web calendar
 * @param param0
 * @returns
 */
export const CCalendar: FC<ICCalendarProps> = ({
    curDate,
    setCurDate,
    availabilities,
    negotiations,
    contracts,
    range,
    jobs,
}) => {
    // global state
    const { language } = useContext(LanguageContext);
    const style = useStyle();
    const { theme } = useTheme();
    const { width } = useDimensions();
    const { userData } = useFireBase();
    // local state
    const {
        startDate,
        availableDays,
        unAvailableDays,
        negotiationDays,
        contractDays,
        jobDays,
        disableActiveStartDate,
    } = useCalendarState({
        curDate,
        setCurDate,
        availabilities,
        negotiations,
        contracts,
        range,
        jobs,
    });
    const refCalendarContainer = useRef<View>(null);
    /**
     * stolen effect to hook onto disabling activeStartDate
     */
    useEffect(() => {
        // @ts-ignore
        const refNode = refCalendarContainer.current as HTMLDivElement;
        // @ts-ignore
        const navigationButtons = refNode.querySelectorAll(
            '.react-calendar__navigation__arrow',
        );

        navigationButtons.forEach((button: any) => {
            button.addEventListener('click', disableActiveStartDate);
        });

        return () => {
            if (refNode) {
                navigationButtons.forEach((button: any) => {
                    button.removeEventListener('click', disableActiveStartDate);
                });
            }
        };
    }, []);
    /**
     * render
     */
    return (
        <View ref={refCalendarContainer}>
            <RCalendar
                locale={language}
                value={curDate}
                onClickDay={setCurDate}
                activeStartDate={startDate}
                showWeekNumbers={width > 750}
                tileContent={(props) => {
                    const available = availableDays.includes(
                        props.date.toLocaleDateString('de'),
                    );
                    const unavailable = unAvailableDays.includes(
                        props.date.toLocaleDateString('de'),
                    );
                    const negotiating = negotiationDays.includes(
                        props.date.toLocaleDateString('de'),
                    );
                    const active = contractDays.includes(
                        props.date.toLocaleDateString('de'),
                    );
                    const openJob = jobDays.includes(
                        props.date.toLocaleDateString('de'),
                    );
                    return (
                        <View
                            style={{
                                marginTop: -offset,
                                marginBottom: offset - radius,
                                height: radius,
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                            }}
                        >
                            {available && (
                                <View
                                    style={{
                                        ...bubbleStyle,
                                        backgroundColor: theme.accentColor,
                                    }}
                                />
                            )}
                            {unavailable && (
                                <View
                                    style={{
                                        ...bubbleStyle,
                                        backgroundColor: theme.errorColor,
                                    }}
                                />
                            )}
                            {negotiating && (
                                <View
                                    style={{
                                        ...bubbleStyle,
                                        backgroundColor: theme.warningColor,
                                    }}
                                />
                            )}
                            {active && (
                                <View
                                    style={{
                                        ...bubbleStyle,
                                        backgroundColor: theme.successColor,
                                    }}
                                />
                            )}
                            {openJob && (
                                <View
                                    style={{
                                        ...bubbleStyle,
                                        backgroundColor: theme.borderColor,
                                    }}
                                />
                            )}
                        </View>
                    );
                }}
                tileClassName={(props) => {
                    const currentRangeIndex = range
                        ? range.findIndex(
                              (d) => props.date.toLocaleDateString('de') === d,
                          )
                        : -1;
                    const currentRangeLength = range ? range.length : 0;

                    return `withDot ${
                        currentRangeIndex > -1
                            ? currentRangeIndex === currentRangeLength - 1
                                ? 'endPiece'
                                : !currentRangeIndex
                                ? 'startPiece'
                                : 'centerPiece'
                            : ''
                    }`;
                }}
            />
            {userData.type === EUserType.user && (
                <>
                    <View style={[style.horizontal, style.centeredItems]}>
                        <View
                            style={{
                                ...bubbleStyle,
                                backgroundColor: theme.accentColor,
                            }}
                        />
                        <CText message={calendarMessages.available} />
                    </View>
                    <View style={[style.horizontal, style.centeredItems]}>
                        <View
                            style={{
                                ...bubbleStyle,
                                backgroundColor: theme.errorColor,
                            }}
                        />
                        <CText message={calendarMessages.unavailable} />
                    </View>
                </>
            )}
            {isAgencyUser(userData) && (
                <View style={[style.horizontal, style.centeredItems]}>
                    <View
                        style={{
                            ...bubbleStyle,
                            backgroundColor: theme.accentColor,
                        }}
                    />
                    <CText message={calendarMessages.talentAvailable} />
                </View>
            )}
            {isEmployer(userData) && (
                <View style={[style.horizontal, style.centeredItems]}>
                    <View
                        style={{
                            ...bubbleStyle,
                            backgroundColor: theme.borderColor,
                        }}
                    />
                    <CText message={calendarMessages.openJob} />
                </View>
            )}
            {(isEmployer(userData) || userData.type === EUserType.user) && (
                <>
                    <View style={[style.horizontal, style.centeredItems]}>
                        <View
                            style={{
                                ...bubbleStyle,
                                backgroundColor: theme.warningColor,
                            }}
                        />
                        <CText message={calendarMessages.activeNegotiation} />
                    </View>
                    <View style={[style.horizontal, style.centeredItems]}>
                        <View
                            style={{
                                ...bubbleStyle,
                                backgroundColor: theme.successColor,
                            }}
                        />
                        <CText message={calendarMessages.activeContract} />
                    </View>
                </>
            )}
            {isAgencyUser(userData) && (
                <>
                    <View style={[style.horizontal, style.centeredItems]}>
                        <View
                            style={{
                                ...bubbleStyle,
                                backgroundColor: theme.errorColor,
                            }}
                        />
                        <CText message={calendarMessages.talentUnavailable} />
                    </View>
                    <View style={[style.horizontal, style.centeredItems]}>
                        <View
                            style={{
                                ...bubbleStyle,
                                backgroundColor: theme.warningColor,
                            }}
                        />
                        <CText
                            message={calendarMessages.talentActiveNegotiation}
                        />
                    </View>
                    <View style={[style.horizontal, style.centeredItems]}>
                        <View
                            style={{
                                ...bubbleStyle,
                                backgroundColor: theme.successColor,
                            }}
                        />
                        <CText
                            message={calendarMessages.talentActiveContract}
                        />
                    </View>
                </>
            )}
        </View>
    );
};
