import React, { FC } from 'react';
import { Platform, View } from 'react-native';
import { generalMessages } from '../../../../utilities/messages';
import { useStyle } from '../../../../utilities/styles';
import { CLabel } from '../../CLabel';
import { CText } from '../../CText';
import { CTextInput } from '../../CTextInput';
import { Message } from '../../../../utilities/types';

interface IAdditionalInputsProps {
    other: boolean;
    values: {
        label: Message;
        value: any;
        hidden?: boolean;
        disabled?: boolean;
    }[];
    onChangeOther?: (value: string) => void;
    valueOther?: string;
    multiValues?: any[];
    onChangeMulti?: (next: any[]) => void;
    validity?: Message;
}

export const AdditionalInputs: FC<IAdditionalInputsProps> = ({
    other,
    values,
    onChangeOther,
    valueOther,
    multiValues,
    onChangeMulti,
    validity,
}) => {
    // global state
    const style = useStyle();
    /**
     * render
     */
    return (
        <>
            {other && onChangeOther && (
                <CTextInput
                    value={valueOther || ''}
                    placeholder={generalMessages.otherPlaceholder}
                    onChangeText={onChangeOther}
                    autoExtend
                />
            )}
            {multiValues && !!multiValues.length && Platform.OS !== 'web' && (
                <View style={[style.verticalPadded, style.horizontalWrap]}>
                    {multiValues.map((value) => (
                        <View key={value} style={{ margin: 5 }}>
                            <CLabel
                                checked={true}
                                endIcon={'close'}
                                title={
                                    values.find((v) => v.value === value)
                                        ?.label || '???'
                                }
                                onCheckedChanged={() => {
                                    if (onChangeMulti) {
                                        const next = [...multiValues];
                                        next.splice(
                                            multiValues.indexOf(value),
                                            1,
                                        );
                                        onChangeMulti(next);
                                    }
                                }}
                            />
                        </View>
                    ))}
                </View>
            )}
            {!!validity && (
                <View style={style.verticalPadded}>
                    <CText style={style.error} message={validity} />
                </View>
            )}
        </>
    );
};
