import { EInviteStatus } from '../enums/EInviteStatus';
import { EInviteType } from '../enums/EInviteType';

export class MInvite {
    public documentId = '';
    public createdOn = Date.now();
    public status: EInviteStatus = EInviteStatus.open;
    public type: EInviteType = EInviteType.referral;
    public title = '';
    public targetId = '';
    public authorId = '';
    public claimedBy?: string;

    constructor(params: Partial<MInvite> & { targetId: string }) {
        Object.assign(this, params);
    }
}
