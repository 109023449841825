import React, { FC, useCallback, useState } from 'react';
import { useFireBase } from '../../../../../utilities/firebase';
import { ECollections } from '../../../../../enums';
import { MJob, MWorkplace } from '../../../../../models';
import { CButton } from '../../../../../components';
import { week, month } from '../../../../../utilities/functions';

export const RandomizeJobsCreatedOn: FC = () => {
    const { getDataIndex, getDataById, put } = useFireBase();

    const [randomizingJobs, setRandomizingJobs] = useState(false);
    /**
     * callback to touch all jobs
     */
    const randomizeJobs = useCallback(() => {
        setRandomizingJobs(true);
        const to = Date.now() - week;
        const from = to - month;
        getDataIndex(ECollections.jobs).then((jobs) => {
            Promise.all(
                (jobs as MJob[]).map(async (job) => {
                    if (job.workPlaceId) {
                        const workplace = new MWorkplace(
                            await getDataById(
                                ECollections.workplaces,
                                job.workPlaceId,
                            ),
                        );
                        if (!workplace.name.includes('BSP:')) {
                            return;
                        }
                        const next = new MJob({
                            ...job,
                            createdOn: Math.round(
                                Math.random() * (to - from) + from,
                            ),
                        });
                        return put(ECollections.jobs, job.documentId, {
                            ...next,
                            touched: Date.now(),
                        });
                    }
                }),
            ).then(() => setRandomizingJobs(false));
        });
    }, [getDataIndex, put]);

    return (
        <CButton
            onPress={randomizeJobs}
            title="Randomize Jobs created on"
            disabled={randomizingJobs}
            danger
        />
    );
};
