import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import {
    MProfessionalInfo,
    MAgency,
    MAvailability,
    MRequest,
    MWorkplace,
    IDayAvailability,
} from '../../../models';
import { ScrollProvider, useEnvironment } from '../../../utilities/contexts';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { useStyle, useTheme } from '../../../utilities/styles';
import { EApplicationType, ECollections } from '../../../enums';
import { useNavigate, useParams } from '../../../utilities/routing';
import {
    CText,
    CCheckBox,
    CDatePicker,
    CButton,
    WorkplaceZipCodeTypeahead,
    UserWorkplacePicker,
    ResumeView,
    ExpandableCard,
    InfoBox,
} from '../../../components';
import { applicationMessages } from '../../Application/application.messages';
import { jobMessages } from '../../Job/job.messages';
import {
    convertWeekAndYearToDate,
    week,
    day,
} from '../../../utilities/functions';
import { actionMessages, generalMessages } from '../../../utilities/messages';
import { WorkplaceRow } from '../../Workplace/List/components/WorkplaceRow';
import { ApplicantData } from '../../Application/components/ApplicantData';
import { availabilityMessages } from '../availability.messages';
import { isEmployer, isSuperUser } from '../../../utilities/auth';
import { WorkingTimes } from '../../Job/Edit/components/WorkingTimes';
import { useDialog } from '../../../utilities/dialog';
import { useLock } from '../../../utilities/hooks';

export const CreateRequest: FC = () => {
    // global state
    const style = useStyle();
    const format = useFormat();
    const { id } = useParams();
    const { theme } = useTheme();
    const navigate = useNavigate();
    const dialog = useDialog();
    const { lock } = useLock();
    const { region } = useEnvironment();
    const { getDataById, userData, userWorkplaces, callFunction } =
        useFireBase();
    // local resource state
    const [availability, setAvailability] = useState<MAvailability>();
    const [workplace, setWorkplace] = useState<MWorkplace>();
    const [resource, setResource] = useState<MProfessionalInfo>();
    const [agency, setAgency] = useState<MAgency>();
    // local value state
    const [from, setFrom] = useState(Date.now());
    const [to, setTo] = useState(Date.now() + day);
    const [fromNow, setFromNow] = useState(false);
    const [days, setDays] = useState<IDayAvailability[]>([]);
    /**
     * memoized hours per week
     */
    const workingHoursSum = useMemo(
        () => days.reduce((acc, d) => (acc += d.to - d.from), 0),
        [days],
    );
    /**
     * callback to handle new request
     */
    const handleRequest = useCallback(async () => {
        const unlock = lock();
        const next = new MRequest({
            agencyId: agency?.documentId,
            profileId: agency ? resource?.documentId : undefined,
            userId: agency ? undefined : resource?.documentId,
            availabilityId: availability?.documentId,
            author: userData.documentId,
            workplaceId: workplace?.documentId,
            from,
            to,
            fromNow,
            type: agency ? EApplicationType.agency : EApplicationType.user,
            days,
        });
        await callFunction('createRequest', { request: next });
        unlock();
        await dialog({
            title: availabilityMessages.createdRequest,
            message: availabilityMessages.createdRequestText,
            icon: 'success',
        });
        navigate(-1);
    }, [
        agency,
        resource,
        availability,
        userData,
        workplace,
        from,
        to,
        fromNow,
        days,
    ]);
    /**
     * effect to load availabilities
     */
    useEffect(() => {
        if (!id) return;
        getDataById(ECollections.availabilities, id).then((a) => {
            setAvailability(new MAvailability(a));
        });
    }, [id]);
    /**
     * effect to load resources based on availability
     */
    useEffect(() => {
        if (!availability) return;

        setFrom(
            convertWeekAndYearToDate(
                availability.year,
                availability.start,
            ).getTime(),
        );
        setTo(
            convertWeekAndYearToDate(
                availability.year,
                availability.start,
            ).getTime() +
                (availability.repeatCount + 1) * week -
                day,
        );
        if (availability.agencyId) {
            getDataById(
                ECollections.publicAgencies,
                availability.agencyId,
            ).then((a) => setAgency(new MAgency(a)));
        }
        if (availability.profileId) {
            getDataById(
                ECollections.publicProfProfiles,
                availability.profileId,
            ).then((p) => setResource(new MProfessionalInfo(p)));
        } else if (availability.uid) {
            getDataById(ECollections.publicUsers, availability.uid).then((u) =>
                setResource(new MProfessionalInfo(u)),
            );
        }
    }, [availability]);
    /**
     * effect to load / set workpalce
     */
    useEffect(() => {
        if (userWorkplaces.length === 1) {
            setWorkplace(userWorkplaces[0]);
        }
    }, [userWorkplaces]);

    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={[style.headlineCard]}>
                <View style={[style.horizontal, style.centeredItems]}>
                    <CButton
                        cy={'back'}
                        onPress={async () => {
                            navigate(-1);
                        }}
                        icon={'chevronLeft'}
                        small
                    />
                    <CText
                        bold
                        headline
                        style={style.horizontalPadded}
                        message={availabilityMessages.createRequest}
                    />
                </View>
            </View>
            <View
                style={[
                    style.card,
                    { zIndex: 1 },
                    !workplace && style.errorBorder,
                ]}
            >
                {!workplace && (
                    <InfoBox
                        type={'error'}
                        message={availabilityMessages.selectAWorkplace}
                    />
                )}
                {isSuperUser(userData) && (
                    <WorkplaceZipCodeTypeahead
                        currentWorkplace={workplace}
                        onChangeWorkplace={setWorkplace}
                    />
                )}
                {isEmployer(userData) && (
                    <UserWorkplacePicker
                        value={workplace}
                        onChange={setWorkplace}
                    />
                )}
            </View>
            {!!workplace && <WorkplaceRow workplace={workplace} />}
            {resource && (
                <View style={style.card}>
                    <ApplicantData applicantData={resource} />
                </View>
            )}
            {availability && (
                <ExpandableCard
                    head={
                        <CText
                            message={generalMessages.resume}
                            secondaryHeadline
                        />
                    }
                    body={
                        <ResumeView
                            self
                            profProfileId={
                                availability.profileId || availability.uid
                            }
                            availabilityId={availability.documentId}
                        />
                    }
                    preventHover
                />
            )}
            <View style={style.card}>
                <CText
                    secondaryHeadline
                    message={applicationMessages.timeToApplyFor}
                />
                <View style={style.verticalPadded}>
                    <CCheckBox
                        onCheckedChanged={setFromNow}
                        checked={fromNow}
                        title={applicationMessages.fromNowTilUndefined}
                    />
                </View>
                {!fromNow && (
                    <View style={style.horizontal}>
                        <CDatePicker
                            cy={'from'}
                            title={format(jobMessages.from)}
                            value={new Date(from)}
                            onChange={(next) => {
                                const nextFrom = next.getTime();
                                setFrom(nextFrom);
                                if (nextFrom > to) {
                                    setTo(nextFrom + day);
                                }
                            }}
                            zIndex={22}
                            minDate={Date.now() + day}
                        />
                        <CDatePicker
                            cy={'to'}
                            title={format(jobMessages.to)}
                            value={new Date(to)}
                            onChange={(next) => setTo(next.getTime())}
                            minDate={from}
                        />
                    </View>
                )}
            </View>
            <View style={[style.card, !workingHoursSum && style.errorBorder]}>
                <CText secondaryHeadline message={jobMessages.workingTimes} />
                {!workingHoursSum && (
                    <CText
                        style={style.error}
                        message={availabilityMessages.enterDays}
                    />
                )}
                <WorkingTimes
                    date={new Date(from)}
                    days={days || []}
                    onChangeDays={setDays}
                />
            </View>
            <View style={style.horizontalSpaced}>
                <View style={style.horizontal}>
                    <CButton
                        cy={'apply'}
                        disabled={
                            !availability || !workplace || !workingHoursSum
                        }
                        title={actionMessages.create}
                        style={{ backgroundColor: theme.successColor }}
                        onPress={handleRequest}
                        disableOnClick
                    />
                </View>
            </View>
        </ScrollProvider>
    );
};
