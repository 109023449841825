import { defineMessages } from 'react-intl';

const scope = 'medic.doctor.specialization.';
/**
 * dcotorSpecialization names
 * is used for dcotorSpecialization picker
 */
export const doctorSpecializationMessages = defineMessages({
    additionalallergology: {
        id: scope + 'additionalallergology',
        defaultMessage: 'Additionalallergology',
    },
    anaesthesiologyIntensiveMedicine: {
        id: scope + 'anaesthesiologyIntensiveMedicine',
        defaultMessage: 'Anaesthesiology Intensive Medicine',
    },
    andrology: {
        id: scope + 'andrology',
        defaultMessage: 'Andrology',
    },
    medicalQualityManagement: {
        id: scope + 'medicalQualityManagement',
        defaultMessage: 'Medical Quality Management',
    },
    visceralSurgery: {
        id: scope + 'visceralSurgery',
        defaultMessage: 'Visceral Surgery',
    },
    trafficMedicine: {
        id: scope + 'trafficMedicine',
        defaultMessage: 'Traffic Medicine',
    },
    traumaSurgery: {
        id: scope + 'traumaSurgery',
        defaultMessage: 'Trauma Surgery',
    },
    tropicalMedicine: {
        id: scope + 'tropicalMedicine',
        defaultMessage: 'Tropical Medicine',
    },
    environmentalMedicine: {
        id: scope + 'environmentalMedicine',
        defaultMessage: 'Environmental Medicine',
    },
    transfusionMedicine: {
        id: scope + 'transfusionMedicine',
        defaultMessage: 'Transfusion Medicine',
    },
    addictionBasicCare: {
        id: scope + 'addictionBasicCare',
        defaultMessage: 'Addiction Basic Care',
    },
    bathingPhysician: {
        id: scope + 'bathingPhysician',
        defaultMessage: 'Bathing Physician',
    },
    balneologyAndMedicalClimatology: {
        id: scope + 'balneologyAndMedicalClimatology',
        defaultMessage: 'Balneology and Medical Climatology',
    },
    occupationalHealth: {
        id: scope + 'occupationalHealth',
        defaultMessage: 'Occupational Health',
    },
    chirotherapy: {
        id: scope + 'chirotherapy',
        defaultMessage: 'Chirotherapy',
    },
    bloodTransfusion: {
        id: scope + 'bloodTransfusion',
        defaultMessage: 'Blood Transfusion',
    },
    surgicalIntensiveCare: {
        id: scope + 'surgicalIntensiveCare',
        defaultMessage: 'Surgical Intensive Care',
    },
    dermatohistology: {
        id: scope + 'dermatohistology',
        defaultMessage: 'Dermatohistology',
    },
    diabetology: { id: scope + 'diabetology', defaultMessage: 'Diabetology' },
    endocrinology: {
        id: scope + 'endocrinology',
        defaultMessage: 'Endocrinology',
    },
    nutritionalSciences: {
        id: scope + 'nutritionalSciences',
        defaultMessage: 'Nutritional Sciences',
    },
    aviationMedicine: {
        id: scope + 'aviationMedicine',
        defaultMessage: 'Aviation Medicine',
    },
    gastroenterology: {
        id: scope + 'gastroenterology',
        defaultMessage: 'Gastroenterology',
    },
    obstetricsAndPerinatalMedicine: {
        id: scope + 'obstetricsAndPerinatalMedicine',
        defaultMessage: 'Obstetrics and Perinatal Medicine',
    },
    vascularSurgery: {
        id: scope + 'vascularSurgery',
        defaultMessage: 'Vascular Surgery',
    },
    geriatrics: { id: scope + 'geriatrics', defaultMessage: 'Geriatrics' },
    gynaecologyExfoliativeCytology: {
        id: scope + 'gynaecologyExfoliativeCytology',
        defaultMessage: 'Gynaecology Exfoliative Cytology',
    },
    haematology: { id: scope + 'haematology', defaultMessage: 'Haematology' },
    gynaecologicalOncology: {
        id: scope + 'gynaecologicalOncology',
        defaultMessage: 'Gynaecological Oncology',
    },
    haematologyAndInternalOncology: {
        id: scope + 'haematologyAndInternalOncology',
        defaultMessage: 'Haematology and Internal Oncology',
    },
    haematologyAndOncology: {
        id: scope + 'haematologyAndOncology',
        defaultMessage: 'Haematology and Oncology',
    },
    haemostaseology: {
        id: scope + 'haemostaseology',
        defaultMessage: 'Haemostaseology',
    },
    cardiacSurgery: {
        id: scope + 'cardiacSurgery',
        defaultMessage: 'Cardiac Surgery',
    },
    handSurgery: { id: scope + 'handSurgery', defaultMessage: 'Hand Surgery' },
    homoeopathy: { id: scope + 'homoeopathy', defaultMessage: 'Homoeopathy' },
    infectiology: {
        id: scope + 'infectiology',
        defaultMessage: 'Infectiology',
    },
    intensiveCareMedicine: {
        id: scope + 'intensiveCareMedicine',
        defaultMessage: 'Intensive Care Medicine',
    },
    internisticIntensiveMedicine: {
        id: scope + 'internisticIntensiveMedicine',
        defaultMessage: 'Internistic Intensive Medicine',
    },
    internisticMedicine: {
        id: scope + 'internisticMedicine',
        defaultMessage: 'Internistic Medicine',
    },
    cardiology: { id: scope + 'cardiology', defaultMessage: 'Cardiology' },
    cardiovascularPreventiveMedicine: {
        id: scope + 'cardiovascularPreventiveMedicine',
        defaultMessage: 'cardiovascular Preventive Medicine',
    },
    paediatricEndocrinologyAndDiabetology: {
        id: scope + 'paediatricEndocrinologyAndDiabetology',
        defaultMessage: 'Paediatric Endocrinology and Diabetology',
    },
    paediatricGastroenterology: {
        id: scope + 'paediatricGastroenterology',
        defaultMessage: 'Paediatric Gastroenterology',
    },
    paediatricNephrology: {
        id: scope + 'paediatricNephrology',
        defaultMessage: 'Paediatric Nephrology',
    },
    paediatricPneumology: {
        id: scope + 'paediatricPneumology',
        defaultMessage: 'Paediatric Pneumology',
    },
    paediatricOrthopaedics: {
        id: scope + 'paediatricOrthopaedics',
        defaultMessage: 'Paediatric Orthopaedics',
    },
    childrenRheumatology: {
        id: scope + 'childrenRheumatology',
        defaultMessage: 'Children Rheumatology',
    },
    laboratoryDiagnostics: {
        id: scope + 'laboratoryDiagnostics',
        defaultMessage: 'Laboratory Diagnostics',
    },
    lungAndBronchialMedicine: {
        id: scope + 'lungAndBronchialMedicine',
        defaultMessage: 'Lung and Bronchial Medicine',
    },
    magneticResonanceImaging: {
        id: scope + 'magneticResonanceImaging',
        defaultMessage: 'Magnetic Resonance Imaging',
    },
    manualMedicine: {
        id: scope + 'manualMedicine',
        defaultMessage: 'Manual Medicine',
    },
    manualMedicineChirotherapy: {
        id: scope + 'manualMedicineChirotherapy',
        defaultMessage: 'Manual Medicine Chirotherapy',
    },
    manualTherapy: {
        id: scope + 'manualTherapy',
        defaultMessage: 'Manual Therapy',
    },
    medicalTumourTherapy: {
        id: scope + 'medicalTumourTherapy',
        defaultMessage: 'Medical Tumour Therapy',
    },
    medicalGenetics: {
        id: scope + 'medicalGenetics',
        defaultMessage: 'Medical Genetics',
    },
    medicalInformatics: {
        id: scope + 'medicalInformatics',
        defaultMessage: 'Medical Informatics',
    },
    naturopathy: { id: scope + 'naturopathy', defaultMessage: 'Naturopathy' },
    naturopathicTreatment: {
        id: scope + 'naturopathicTreatment',
        defaultMessage: 'Naturopathic Treatment',
    },
    neonatology: { id: scope + 'neonatology', defaultMessage: 'Neonatology' },
    nephrology: { id: scope + 'nephrology', defaultMessage: 'Nephrology' },
    neuropaediatrics: {
        id: scope + 'neuropaediatrics',
        defaultMessage: 'Neuropaediatrics',
    },
    neuroradiology: {
        id: scope + 'neuroradiology',
        defaultMessage: 'Neuroradiology',
    },
    radiology: {
        id: scope + 'radiology',
        defaultMessage: 'Radiology',
    },
    emergencyMedicine: {
        id: scope + 'emergencyMedicine',
        defaultMessage: 'Emergency Medicine',
    },
    oncology: { id: scope + 'oncology', defaultMessage: 'Oncology' },
    orthopaedics: {
        id: scope + 'orthopaedics',
        defaultMessage: 'Orthopaedics',
    },
    orthopaedicsAndAccidentSurgery: {
        id: scope + 'orthopaedicsAndAccidentSurgery',
        defaultMessage: 'Orthopaedics And Accident Surgery',
    },
    orthopaedicRheumatology: {
        id: scope + 'orthopaedicRheumatology',
        defaultMessage: 'Orthopaedic Rheumatology',
    },
    palliativeMedicine: {
        id: scope + 'palliativeMedicine',
        defaultMessage: 'Palliative Medicine',
    },
    phlebology: { id: scope + 'phlebology', defaultMessage: 'Phlebology' },
    physicalTherapyAndBalneology: {
        id: scope + 'physicalTherapyAndBalneology',
        defaultMessage: 'Physical Therapy and Balneology',
    },
    physicalTherapy: {
        id: scope + 'physicalTherapy',
        defaultMessage: 'physicalTherapy',
    },
    plasticSurgery: {
        id: scope + 'plasticSurgery',
        defaultMessage: 'Plastic Surgery',
    },
    proctology: { id: scope + 'proctology', defaultMessage: 'Proctology' },
    pneumology: { id: scope + 'pneumology', defaultMessage: 'Pneumology' },
    psychoanalysis: {
        id: scope + 'psychoanalysis',
        defaultMessage: 'Psychoanalysis',
    },
    psychiatryAndPsychotherapy: {
        id: scope + 'psychiatryAndPsychotherapy',
        defaultMessage: 'Psychiatry and Psychotherapy',
    },
    psychotherapy: {
        id: scope + 'psychotherapy',
        defaultMessage: 'Psychotherapy',
    },
    rehabilitation: {
        id: scope + 'rehabilitation',
        defaultMessage: 'Rehabilitation',
    },
    travelMedicine: {
        id: scope + 'travelMedicine',
        defaultMessage: 'Travel Medicine',
    },
    rheumatology: {
        id: scope + 'rheumatology',
        defaultMessage: 'Rheumatology',
    },
    roentgenDiagnostics: {
        id: scope + 'roentgenDiagnostics',
        defaultMessage: 'Roentgen Diagnostics',
    },
    sleepMedicine: {
        id: scope + 'sleepMedicine',
        defaultMessage: 'Sleep Medicine',
    },
    socialMedicine: {
        id: scope + 'socialMedicine',
        defaultMessage: 'Social Medicine',
    },
    specialAnaesthesiologyIntensiveMedicine: {
        id: scope + 'specialAnaesthesiologyIntensiveMedicine',
        defaultMessage: 'Special Anaesthesiology Intensive Medicine',
    },
    specialObstetricsAndPerinatalMedicine: {
        id: scope + 'specialObstetricsAndPerinatalMedicine',
        defaultMessage: 'Special ObstetricsAndP Perinatal Medicine',
    },
    specialIntensiveMedicine: {
        id: scope + 'specialIntensiveMedicine',
        defaultMessage: 'Special Intensive Medicine',
    },
    specialOrthopaedicSurgery: {
        id: scope + 'specialOrthopaedicSurgery',
        defaultMessage: 'Special Orthopaedic Surgery',
    },
    specialInternalIntensiveMedicine: {
        id: scope + 'specialInternalIntensiveMedicine',
        defaultMessage: 'Special Internal Intensive Medicine',
    },
    specialRythmology: {
        id: scope + 'specialRythmology',
        defaultMessage: 'Special Rythmology',
    },
    specialAccidentSurgery: {
        id: scope + 'specialAccidentSurgery',
        defaultMessage: 'Special Accident Surgery',
    },
    specialPainTherapy: {
        id: scope + 'specialPainTherapy',
        defaultMessage: 'Special Pain Therapy',
    },
    specialVisceralSurgery: {
        id: scope + 'specialVisceralSurgery',
        defaultMessage: 'Special Visceral Surgery',
    },
    voiceAndSpeechDisorders: {
        id: scope + 'voiceAndSpeechDisorders',
        defaultMessage: 'Voice and Speech Disorders',
    },
    sportsMedicine: {
        id: scope + 'sportsMedicine',
        defaultMessage: 'Sports Medicine',
    },
});
