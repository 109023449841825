import React, { FC, useEffect, useMemo, useState } from 'react';
import { lieblingsjobTheme, situsTheme, timeployeesTheme } from './themes';
import StyleContext from './StyleContext';
import { makeGlobalStyles } from './styles';
import { IDefaultProps } from '../IDefaultProps';
import { Platform } from 'react-native';
// web specific stylings
import './index.css';
import { useEnvironment } from '../contexts';
import { EEnvironment } from '../../enums';
/**
 * Provider for global theme state and main style sheet.
 * also controls css
 * @param param0 children
 * @returns children wrapped in style context
 */
export const StyleProvider: FC<IDefaultProps> = ({ children }) => {
    const { environment } = useEnvironment();

    const [isDark, setIsDark] = useState(false);
    /**
     * memoized theme value
     */
    const theme = useMemo(() => {
        // @ts-ignore
        document.body.classList.remove(
            'dark',
            'timePloyees',
            'darkTimePloyees',
            'lieblingsjob',
            'lieblingsjobDark',
        );
        if (environment === EEnvironment.SITUS) {
            if (isDark) {
                // @ts-ignore
                document.body.classList.add('dark');
                return situsTheme.dark;
            } else {
                return situsTheme.light;
            }
        } else if (environment === EEnvironment.Lieblingsjob) {
            if (isDark) {
                // @ts-ignore
                document.body.classList.add('lieblingsjobDark');
                return lieblingsjobTheme.dark;
            } else {
                // @ts-ignore
                document.body.classList.add('lieblingsjob');
                return lieblingsjobTheme.light;
            }
        } else {
            if (isDark) {
                // @ts-ignore
                document.body.classList.add('darkTimePloyees');
                return timeployeesTheme.dark;
            } else {
                // @ts-ignore
                document.body.classList.add('timePloyees');
                return timeployeesTheme.light;
            }
        }
    }, [environment, isDark]);
    /**
     * memoized main style sheet
     */
    const styles = useMemo(() => {
        return makeGlobalStyles(theme);
    }, [theme]);
    /**
     * effect to set dark if preferred
     */
    useEffect(() => {
        if (
            Platform.OS === 'web' &&
            // @ts-ignore
            window.matchMedia &&
            // @ts-ignore
            window.matchMedia('(prefers-color-scheme: dark)').matches
        ) {
            // dark mode
            setIsDark(true);
        }
    }, []);

    return (
        <StyleContext.Provider
            value={{
                theme,
                styles,
                isDark,
                setIsDark,
            }}
            data-theme={isDark ? 'dark' : 'light'}
        >
            {children}
        </StyleContext.Provider>
    );
};
