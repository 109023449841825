import React, { FC, useCallback, useContext } from 'react';
import {
    collection,
    collectionGroup,
    doc,
    setDoc,
    addDoc,
    getDocsFromServer,
    query,
    orderBy,
    where,
    getDocFromServer,
    limit,
    deleteDoc,
    documentId,
    DocumentChange,
    DocumentData,
    onSnapshot,
    Unsubscribe,
    FieldPath,
    startAfter,
} from 'firebase/firestore';

import { IDefaultProps } from '../../IDefaultProps';
import { flattenObject, unflattenObject } from '../../functions/flattenObject';
import { ECollections } from '../../../enums';
import { FirestoreContext, IParamOptions } from './FirestoreContext';
import { FireAppContext } from '../app';
import { Linking } from 'react-native';
import { FireAuthContext } from '../auth';
/**
 * Provider for everything Firestore
 * @param param0
 * @returns
 */
export const FirestoreProvider: FC<IDefaultProps> = ({ children }) => {
    const { db } = useContext(FireAppContext);
    const { refreshToken } = useContext(FireAuthContext);
    /**
     * post a document to collection
     * @param table collection to post to
     * @param data document
     */
    const post = useCallback(
        async (table: ECollections | string, data: any) => {
            try {
                const collectionRef = collection(db, table);
                return await addDoc(
                    collectionRef,
                    flattenObject({ ...data, editedOn: Date.now() }, true),
                );
            } catch (e) {
                console.log(table, data);
                console.error('Error posting document: ', e);
            }
        },
        [db],
    );
    /**
     * put a document into a collection
     * @param table collection to post to
     * @param id id to put into
     * @param data document
     */
    const put = useCallback(
        async (table: ECollections | string, id: string, data: any) => {
            try {
                const docRef = doc(db, `${table}/${id}`);
                return await setDoc(
                    docRef,
                    flattenObject({ ...data, editedOn: Date.now() }, true),
                );
            } catch (e) {
                const stringifed = `${e}`;
                if (
                    stringifed.includes('Missing or insufficient permissions.')
                ) {
                    console.warn(stringifed, table, data);
                } else {
                    console.log(table, data);
                    console.error('Error putting document: ', e);
                }
            }
        },
        [db],
    );
    /**
     * put a document into a collection
     * @param table collection to post to
     * @param id id to put into
     * @param data document
     */
    const remove = useCallback(
        async (table: ECollections | string, id: string) => {
            try {
                const docRef = doc(db, `${table}/${id}`);
                return await deleteDoc(docRef);
            } catch (e) {
                console.error('Error removing document: ', e);
            }
        },
        [db],
    );
    /**
     * get documents
     * @param table collection
     * @param paramOptions options for query
     * @returns document or undefined
     */
    const getDataIndex = useCallback(
        async (
            table: ECollections | string,
            paramOptions?: IParamOptions,
            subscriptionCB?: (event: DocumentChange<DocumentData>) => void,
            unsubscribeHandler?: (cb: Unsubscribe) => void,
        ): Promise<any[] | number> => {
            const options = {
                filter: [],
                inequalities: [],
                ...paramOptions,
            };
            if (
                (options.inequalities.length || options.partialMatch) &&
                options.filter.length > 1
            ) {
                throw 'missuse of getDataIndex. It is not allowed to filter for multiple values and use inequalities';
            }
            if (
                options.filter.find(
                    (f) =>
                        f.operator === 'in' && (f.value as any[]).length === 0,
                )
            ) {
                return new Promise<any[] | number>((resolve) =>
                    resolve(paramOptions?.getLength ? 0 : []),
                );
            }
            const orderByConstraint = [];
            if (options.orderBy) {
                orderByConstraint.push(
                    orderBy(options.orderBy, options.asc ? 'asc' : 'desc'),
                );
            }
            options.inequalities.forEach((f) => {
                if (options.orderBy !== f.field) {
                    const oB = new FieldPath(f.field);
                    orderByConstraint.unshift(
                        orderBy(oB, options.asc ? 'asc' : 'desc'),
                    );
                }
            });
            const limitConstraint = [];
            if (options.limit) {
                limitConstraint.push(limit(options.limit));
            }
            if (options.startDocumentId) {
                const docRef = doc(db, `${table}/${options.startDocumentId}`);
                const d = await getDocFromServer(docRef);
                limitConstraint.push(startAfter(d));
            }
            const partialMatching = [];
            if (options.partialMatch) {
                const oB = new FieldPath(options.partialMatch.field);
                partialMatching.push(
                    where(oB, '>=', options.partialMatch.value),
                );
                partialMatching.push(
                    where(oB, '<=', options.partialMatch.value + '\uf8ff'),
                );
                orderByConstraint.unshift(
                    orderBy(oB, options.asc ? 'asc' : 'desc'),
                );
            }
            try {
                const q = query(
                    (options.useCollectionGroup ? collectionGroup : collection)(
                        db,
                        table,
                    ),
                    ...[...options.filter, ...options.inequalities].map((f) =>
                        f.field === 'documentId'
                            ? where(documentId(), f.operator || '==', f.value)
                            : where(
                                  new FieldPath(f.field),
                                  f.operator || '==',
                                  f.value,
                              ),
                    ),
                    ...partialMatching,
                    ...orderByConstraint,
                    ...limitConstraint,
                );
                const querySnapshot = await getDocsFromServer(q);

                /**
                 * subscribe to query for live data
                 */
                if (subscriptionCB && unsubscribeHandler) {
                    const unsubscribe = onSnapshot(q, (snapshot) => {
                        snapshot.docChanges().forEach(subscriptionCB);
                    });
                    unsubscribeHandler(unsubscribe);
                    return [];
                } else {
                    return options.getLength
                        ? querySnapshot.size
                        : querySnapshot.docs.map((d) => {
                              // extract createdOn and edited on and add them if needed
                              const data = d.data();
                              const createdOn = data.createdOn;
                              const editedOn = data.editedOn;
                              const flatObject = {
                                  ...data,
                                  documentId: d.id,
                                  documentPath: d.ref.path,
                                  createdOn: createdOn || Date.now(),
                                  editedOn: editedOn || Date.now(),
                              };

                              return unflattenObject(flatObject, true);
                          });
                }
            } catch (e) {
                const code = (e as { code: string }).code;
                if (
                    code === 'permission-denied' &&
                    !paramOptions?.didRefreshToken
                ) {
                    await refreshToken();
                    console.log('retrying after token refresh');
                    return await getDataIndex(
                        table,
                        { ...paramOptions, didRefreshToken: true },
                        subscriptionCB,
                        unsubscribeHandler,
                    );
                }
                console.log(table, options);
                const stringified = `${e}`;
                if (
                    stringified.includes(
                        'The query requires an index. You can create it here:',
                    )
                ) {
                    const url = 'http' + stringified.split('http')[1];
                    Linking.openURL(url);
                } else if (code === 'permission-denied') {
                    console.warn(stringified);
                } else {
                    console.error(e);
                }
                return paramOptions?.getLength ? 0 : [];
            }
        },
        [db],
    );
    /**
     * getIndexAbstraction to call it like watch index
     * @param table collection
     * @param paramOptions options for query
     * @param subscriptionCB callback to receive changes
     * @returns unsubscribe
     */
    const watchDataIndex = useCallback(
        async (
            table: ECollections | string,
            paramOptions?: IParamOptions,
            subscriptionCB?: (event: DocumentChange<DocumentData>) => void,
        ) => {
            return await new Promise<Unsubscribe>(
                async (resolve) =>
                    await getDataIndex(
                        table,
                        paramOptions,
                        subscriptionCB,
                        resolve,
                    ),
            );
        },
        [getDataIndex],
    );
    /**
     * get single document by Id
     * @param table collection
     * @param id id
     * @returns document or undefined
     */
    const getDataById = useCallback(
        async (table: ECollections | string, id: string) => {
            const docRef = doc(db, `${table}/${id}`);
            const d = await getDocFromServer(docRef);
            const data = d.data();
            if (d && data) {
                return unflattenObject({ ...data, documentId: d.id }, true);
            }

            return undefined;
        },
        [db],
    );
    /**
     * watch single document by id
     * @param table collection
     * @param id id
     * @param watchCallback callback that gets called on server changes
     * @returns unsubscribe callback
     */
    const watchDataById = useCallback(
        (
            table: ECollections | string,
            id: string,
            watchCallback: (data: any) => void,
        ) => {
            const docRef = doc(db, `${table}/${id}`);
            return onSnapshot(docRef, (doc) => {
                const data = doc.data();
                watchCallback(
                    unflattenObject({ ...data, documentId: doc.id }, true),
                );
            });
        },
        [db, getDataById],
    );
    /**
     * get a predefined estimated length of supported collection
     * @param length to get
     * @returns length
     */
    const getLength = useCallback(
        async (length: string) => {
            const docRef = doc(db, `lengths/${length}`);
            const d = await getDocFromServer(docRef);
            if (d) {
                return { length: 0, ...d.data() }.length;
            }
            return 0;
        },
        [db],
    );
    /**
     * provide context
     */
    return (
        <FirestoreContext.Provider
            value={{
                post,
                put,
                remove,
                getDataById,
                getDataIndex,
                getLength,
                watchDataById,
                watchDataIndex,
            }}
        >
            {children}
        </FirestoreContext.Provider>
    );
};
