import React, { FC, useCallback, useMemo, useState } from 'react';
import {
    NativeSyntheticEvent,
    TextInput,
    TextInputKeyPressEventData,
    View,
} from 'react-native';

import { KeyboardType, StyleProp, ViewStyle } from 'react-native';
import { useStyle, useTheme } from '../../../utilities/styles';
import { CText } from '../CText';
import { Message } from '../../../utilities/types';
import { useAutoFormat } from '../../../utilities/intl/useFormat';
import { generalMessages } from '../../../utilities/messages';

interface ICTextInput {
    label?: Message;
    value: string;
    style?: StyleProp<ViewStyle>;
    containerStyle?: StyleProp<ViewStyle>;
    placeholder?: Message;
    type?: KeyboardType;
    disabled?: boolean;
    cy?: string;
    unit?: Message;
    largeUnit?: boolean;
    hint?: Message;
    onChangeText: (s: string) => void;
    horizontal?: boolean;
    customCheckInvalidity?: (input: string) => Message | undefined;
    multiline?: number;
    length?: number;
    textfieldRef?: React.RefObject<TextInput>;
    secure?: boolean;
    autoExtend?: boolean;
    onKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    maximumLength?: number;
    displayMaximumLength?: boolean;
}

export const CTextInput: FC<ICTextInput> = ({
    cy,
    label,
    disabled,
    style: propStyles,
    containerStyle,
    type,
    value,
    onChangeText,
    placeholder,
    unit,
    horizontal,
    customCheckInvalidity,
    hint,
    multiline,
    length,
    textfieldRef,
    secure,
    autoExtend,
    onKeyPress,
    onFocus,
    onBlur,
    maximumLength,
    displayMaximumLength,
}) => {
    // global state
    const styles = useStyle();
    const { theme } = useTheme();
    const format = useAutoFormat();
    // local state
    const [open, onChangeOpen] = useState(false);
    const [preventError, setPreventError] = useState(true);
    const fieldLength = length ? (length + 2) * 9 : 174;

    const defaultCheck = (input: any) => {
        if (typeof input === 'string') {
            return undefined;
        }
        return 'the field input is not a string';
    };

    const getValidity = useCallback(
        (input: any) => {
            if (customCheckInvalidity) {
                return customCheckInvalidity(input);
            }
            return defaultCheck(input);
        },
        [customCheckInvalidity],
    );

    const textProps = textfieldRef
        ? {}
        : {
              value: value ? value : '',
              onChangeText: (s: string) => {
                  if (!maximumLength || s.length <= maximumLength) {
                      onChangeText(s);
                  } else if (s.length > maximumLength) {
                      onChangeText(s.substring(0, maximumLength));
                  }
              },
          };
    const validity = useMemo(() => {
        return !preventError ? getValidity(value) : undefined;
    }, [getValidity, value, preventError]);
    return (
        <View
            style={[
                horizontal ? styles.horizontalLayout : styles.verticalLayout,
                styles.verticalPadded,
                horizontal && styles.centeredItems,
                horizontal && autoExtend && { flex: 1 },
                containerStyle,
            ]}
        >
            {!!label && (
                <CText
                    style={horizontal && styles.horizontalPadded}
                    message={label}
                />
            )}
            <View
                style={[
                    styles.horizontalLayout,
                    horizontal && autoExtend && { flex: 1 },
                ]}
            >
                <TextInput
                    {...{ dataSet: { cy } }}
                    multiline={!!multiline}
                    numberOfLines={multiline || 1}
                    style={[
                        styles.input,
                        propStyles,
                        disabled && styles.disabled,
                        { width: fieldLength },
                        autoExtend && { flex: 1 },
                        !!validity && styles.errorBorder,
                    ]}
                    placeholderTextColor={theme.textTertiaryColor}
                    ref={textfieldRef}
                    keyboardType={type}
                    editable={!disabled}
                    placeholder={placeholder && format(placeholder)}
                    onFocus={() => {
                        onChangeOpen(true);
                        if (onFocus) {
                            onFocus();
                        }
                    }}
                    onBlur={() => {
                        onChangeOpen(false);
                        setPreventError(false);
                        if (onBlur) {
                            onBlur();
                        }
                    }}
                    secureTextEntry={secure}
                    onKeyPress={onKeyPress}
                    {...textProps}
                />
                {!!unit && (
                    <View style={[styles.centeredContent, styles.leftPadded]}>
                        <CText message={unit} />
                    </View>
                )}
            </View>
            {maximumLength && displayMaximumLength && (
                <CText
                    message={`(${value.length}/${maximumLength}) ${format(
                        generalMessages.characters,
                    )}`}
                    style={value.length === maximumLength && styles.error}
                />
            )}
            {!!validity && <CText style={styles.error} message={validity} />}
            {!!open && !!hint && <CText message={hint} />}
        </View>
    );
};
