import React, { FC, useEffect, useState } from 'react';
import { ScrollProvider } from '../../../utilities/contexts';
import { useStyle } from '../../../utilities/styles';
import { useFireBase } from '../../../utilities/firebase';
import { MUserIdentDoc } from '../../../models';
import { ECollections } from '../../../enums';
import { View } from 'react-native';
import { CText, CButton } from '../../../components';
import { supportRequestMessages } from '../../SupportRequest/supportRequest.messages';
import { DocumentScanRow } from './components/DocumentScanRow';
import { documentScanMessages } from '../documentScan.messages';

export const DocumentScanList: FC = () => {
    // global state
    const style = useStyle();
    const { getDataIndex } = useFireBase();
    // local state
    const [scans, setScans] = useState<MUserIdentDoc[]>([]);
    const [mode, setMode] = useState<'open' | 'reviewed'>('open');
    const [reload, setReload] = useState(Date.now());
    /**
     * effect to load requests
     */
    useEffect(() => {
        getDataIndex(ECollections.identDocuments, {
            filter: [
                { field: 'reviewed', value: mode !== 'open' },
                { field: 'complete', value: true },
            ],
            orderBy: 'createdOn',
            useCollectionGroup: true,
        }).then((response) => {
            setScans((response as any[]).map((v) => new MUserIdentDoc(v)));
        });
    }, [mode, reload]);
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={style.card}>
                <View style={[style.horizontalSplit, style.verticalPadded]}>
                    <CText
                        message={documentScanMessages.documentScanList}
                        headline
                        bold
                    />
                </View>
                <View style={style.horizontalWrap}>
                    <CButton
                        title={supportRequestMessages.open}
                        onPress={() => setMode('open')}
                        minor={mode !== 'open'}
                        small
                        style={style.horizontalPadded}
                    />
                    <CButton
                        title={supportRequestMessages.closed}
                        onPress={() => setMode('reviewed')}
                        minor={mode !== 'reviewed'}
                        small
                        style={style.horizontalPadded}
                    />
                </View>
            </View>
            {scans.map((scan) => (
                <DocumentScanRow
                    key={scan.documentId}
                    doc={scan}
                    reload={() => setReload(Date.now())}
                />
            ))}
        </ScrollProvider>
    );
};
