import Clipboard from '@react-native-clipboard/clipboard';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';
import { ECollections } from '../../../enums';
import {
    MAgency,
    MAvailability,
    MChat,
    MProfessionalProfile,
} from '../../../models';
import { isAgencyUser, isSuperUser } from '../../../utilities/auth';
import { useEnvironment } from '../../../utilities/contexts';
import {
    useAdminDialog,
    useDialog,
    useUnsavedChangesDialog,
} from '../../../utilities/dialog';
import { useFireBase } from '../../../utilities/firebase';
import { sortObjectKeys } from '../../../utilities/functions';
import { useLock } from '../../../utilities/hooks';
import { useFormat } from '../../../utilities/intl';
import { actionMessages, educationMessages } from '../../../utilities/messages';
import {
    useNavigate,
    useParams,
    useSecureNavigate,
} from '../../../utilities/routing';
import { useStyle, useTheme } from '../../../utilities/styles';
import { profProfileMessages } from '../ProfProfile.messages';
/**
 * ProfProfileEdit
 * used by agencies and admins to view & edit profiles
 *
 * ! Opens user context with excessive values to prevent new context type
 * @returns
 */
export const useTalentEditState = (params?: {
    nested?: boolean;
    saveOverride?: (next: MProfessionalProfile) => void;
    saveAndRegisterNewUser?: (next: MProfessionalProfile) => void;
    predefinedData?: MProfessionalProfile;
    showUnsafeDocSection?: boolean;
}) => {
    // params
    const {
        nested,
        saveOverride,
        saveAndRegisterNewUser,
        predefinedData,
        showUnsafeDocSection,
    } = params || {};
    // global state
    const style = useStyle();
    const { theme } = useTheme();
    const { region } = useEnvironment();
    const { secureNavigate, setNavigationLock } = useSecureNavigate();
    const navigate = useNavigate();
    const format = useFormat();
    const dialog = useDialog();
    const adminDialog = useAdminDialog();
    const { lock } = useLock();
    const {
        getDataById,
        userData,
        userAgencies,
        post,
        put,
        remove,
        callFunction,
        getDataIndex,
        isLoggedIn,
    } = useFireBase();
    const { id } = useParams<{ id: string }>();
    // local state
    const [agencies, setAgencies] = useState<MAgency[]>([]);
    const [profInfos, setProfInfos] = useState<MProfessionalProfile>();
    const [prevProfInfos, setPrevProfInfos] = useState<MProfessionalProfile>();
    const [curValidity, setCurValidity] = useState<any>({});
    const [chat, setChat] = useState<MChat>();
    const [note, setNote] = useState('');
    const [prevNote, setPrevNote] = useState('');
    const [editingNote, setEditingNote] = useState(false);
    /**
     * memoized profile name for headline
     */
    const prevProfileName = useMemo(() => {
        if (!prevProfInfos) {
            return '';
        }
        const edukey = prevProfInfos
            .educations[0] as keyof typeof educationMessages;
        if (prevProfInfos.lastName) {
            return `${prevProfInfos.lastName} ${
                educationMessages[edukey]
                    ? format(educationMessages[edukey])
                    : ''
            }`;
        } else {
            return educationMessages[edukey]
                ? format(educationMessages[edukey])
                : '';
        }
    }, [prevProfInfos]);
    /**
     * memoized connected agency
     */
    const agency = useMemo(() => {
        return agencies.find((a) => a.documentId === prevProfInfos?.agencyId);
    }, [agencies, prevProfInfos]);
    /**
     * memoized current data
     */
    const curData = useMemo(
        () =>
            new MProfessionalProfile({
                ...profInfos,
                region: agency?.region || region,
            }),
        [profInfos, agency, region],
    );
    /**
     * memoized prev data
     */
    const prevData = useMemo(
        () =>
            new MProfessionalProfile({
                ...prevProfInfos,
                region: agency?.region || region,
            }),
        [prevProfInfos, agency, region],
    );
    /**
     * memoized value if any content differs from content initial loadet
     */
    const unsavedChanges = useMemo(() => {
        const cur = JSON.stringify(sortObjectKeys(profInfos || {}));
        const prev = JSON.stringify(sortObjectKeys(prevProfInfos || {}));
        // console.log(
        //     Object.keys(profInfos || {}).filter(
        //         (key) => !Object.keys(prevProfInfos || {}).includes(key),
        //     ),
        // );
        // console.log(cur !== prev, profInfos, prevProfInfos);
        return cur !== prev || prevNote !== note;
    }, [profInfos, prevProfInfos, note, prevNote]);
    /**
     * memoized invite url if profile is open invite
     */
    const inviteUrl = useMemo(() => {
        if (!profInfos || !profInfos.invite || !profInfos.open) return;
        const basepath =
            Platform.OS === 'web'
                ? // @ts-ignore
                  window?.location?.hostname
                : 'app.timeployees.de';
        const isLocalhost = basepath === 'localhost';
        const inviteURL = `${isLocalhost ? '' : 'https://'}${basepath}${
            isLocalhost ? ':3000' : ''
        }/fill?profile=${profInfos.documentId}`;
        return inviteURL;
    }, [profInfos]);
    /**
     * memoized iterable validities
     */
    const validities = useMemo(() => {
        const keys = Object.keys(curValidity) as (keyof typeof curValidity)[];

        return keys
            .filter((k) => !!curValidity[k])
            .map((k) => {
                return { key: k, value: curValidity[k] };
            });
    }, [curValidity]);
    /**
     * callback to handle changes based on next data and index
     */
    const onChange = useCallback((change: Partial<MProfessionalProfile>) => {
        setProfInfos((prev) => {
            if (!prev) {
                return new MProfessionalProfile(change);
            }
            return { ...prev, ...change };
        });
    }, []);
    /**
     * callback to handle save
     */
    const handleSave = useCallback(
        async (skiptNavigate?: boolean) => {
            if (isSuperUser(userData) && !(await adminDialog())) return;
            const next = new MProfessionalProfile({
                ...profInfos,
            });
            next.employmentHistory.sort((a, b) => a.from - b.from);
            next.educationHistory.sort((a, b) => a.from - b.from);
            if (saveOverride) {
                return saveOverride(next);
            }
            const unlock = lock();
            if (id === 'new') {
                next.moderators = [userData.documentId];
                const postResult = await post(ECollections.profProfiles, next);
                if (postResult) {
                    next.documentId = postResult.id;
                }
            } else if (id) {
                await put(ECollections.profProfiles, id, next);
            }
            unlock();
            if (!skiptNavigate) {
                secureNavigate(-1, { force: true });
            } else if (id === 'new' && next.documentId) {
                dialog({
                    title: profProfileMessages.createdTalent,
                    message: profProfileMessages.createdTalentText,
                    icon: 'success',
                    verticalButtons: true,
                    buttons: [
                        {
                            text: profProfileMessages.createAvailabilities,
                            onPress: () => {
                                secureNavigate(
                                    '/calendar?profileId=' + next.documentId,
                                    {
                                        force: true,
                                    },
                                );
                            },
                        },
                        {
                            text: profProfileMessages.toJobs,
                            onPress: () => {
                                secureNavigate('/job', {
                                    force: true,
                                });
                            },
                        },
                        { text: actionMessages.continue },
                    ],
                });
                secureNavigate('/profUser/' + next.documentId, {
                    force: true,
                    replace: true,
                });
            } else {
                setPrevProfInfos(next);
                setProfInfos(next);
            }
        },
        [profInfos, userData, id, post, put, saveOverride],
    );
    const unsavedChangesDialog = useUnsavedChangesDialog(handleSave);
    /**
     * callback to resend form to fill in
     */
    const handleReinvite = useCallback(async () => {
        if (!profInfos) return;
        let mail = '';
        const ok = await dialog({
            icon: 'info',
            title: profProfileMessages.invite,
            message: profProfileMessages.inviteText,
            textInputs: [
                {
                    title: profProfileMessages.mail,
                    defaultValue: profInfos?.mail,
                    id: 'mail',
                },
            ],
            buttons: [
                {
                    text: profProfileMessages.createInvite,
                },
                {
                    text: profProfileMessages.createInviteAndSendMail,
                    onPress: (inputs) => {
                        mail =
                            inputs?.find((i) => i.id === 'mail')?.value || '';
                    },
                    disabled: (inputs) =>
                        !inputs?.find((i) => i.id === 'mail')?.value,
                },
            ],
            cancelButton: { text: actionMessages.cancel },
            verticalButtons: true,
        });

        if (!ok) return;

        const unlock = lock();

        const next = new MProfessionalProfile({
            ...profInfos,
            open: true,
            invite: true,
            mail: mail || profInfos.mail,
        });
        await put(ECollections.profProfiles, next.documentId, next);
        setPrevProfInfos(next);
        setProfInfos(next);
        if (mail) {
            const mailresult = await callFunction('sendProfProfileInvite', {
                profileId: next.documentId,
            });
            console.log(mailresult);
            unlock();
            if (mailresult === 'Ok') {
                await dialog({
                    icon: 'success',
                    title: format(profProfileMessages.mailSend, { mail }),
                    message: format(profProfileMessages.mailSendText, {
                        mail,
                    }),
                });
            }
        } else {
            unlock();
        }
    }, [profInfos]);
    /**
     * callback to close invite
     */
    const handleInviteCancel = useCallback(async () => {
        if (
            await dialog({
                title: profProfileMessages.confirmFormulaClose,
                message: profProfileMessages.confirmFormulaCloseText,
                buttons: [
                    {
                        text: actionMessages.ok,
                    },
                ],
                cancelButton: {
                    text: actionMessages.cancel,
                },
            })
        )
            onChange({ open: false });
    }, []);
    /**
     * callback to handle delete
     */
    const handleDelete = useCallback(async () => {
        if (!profInfos) return;
        const ok = await dialog({
            icon: 'error',
            title: profProfileMessages.confirmDelete,
            message: profProfileMessages.confirmDeleteMessage,
            buttons: [
                {
                    text: actionMessages.delete,
                    color: theme.errorColor,
                },
            ],
            cancelButton: {
                text: actionMessages.cancel,
            },
        });
        if (!ok) return;
        const unlock = lock();
        /**
         * get all avails related to remove them
         */
        const availRes = await getDataIndex(ECollections.availabilities, {
            filter: [{ field: 'profileId', value: profInfos.documentId }],
        });
        for (const avail of availRes as any[]) {
            await remove(ECollections.availabilities, avail.documentId);
        }
        await put(ECollections.profProfiles, profInfos.documentId, {
            ...profInfos,
            disabled: true,
        });
        unlock();
        navigate(-1);
    }, [profInfos]);
    /**
     * callback to revert terminate
     */
    const handleTerminationRevert = useCallback(async () => {
        if (!prevProfInfos) return;
        const ok = await dialog({
            icon: 'question',
            title: profProfileMessages.revertTerminateTitle,
            message: profProfileMessages.revertTerminateMessage,
            cancelButton: {
                text: actionMessages.cancel,
            },
            buttons: [
                {
                    text: profProfileMessages.revertTerminate,
                    color: theme.successColor,
                },
            ],
        });
        if (!ok) return;
        const terminated = false;
        const unlock = lock();
        await put(ECollections.profProfiles, prevProfInfos.documentId, {
            ...prevProfInfos,
            terminated,
        });
        unlock();
        setPrevProfInfos({ ...prevProfInfos, terminated });
        setProfInfos({ ...prevProfInfos, terminated });
    }, [prevProfInfos]);
    /**
     * callback to handle press of terminate button
     */
    const handleTermination = useCallback(
        async (forceNow = false) => {
            if (!prevProfInfos) return;
            let ok = false;
            let date = new Date();
            await dialog({
                icon: 'error',
                title: profProfileMessages.confirmTerminate,
                message: profProfileMessages.confirmTerminateMessage,
                dateInputs: !forceNow
                    ? [
                          {
                              title: profProfileMessages.terminationDate,
                              lowerLimit: Date.now(),
                              id: 'termOn',
                          },
                      ]
                    : undefined,
                buttons: [
                    {
                        text: actionMessages.delete,
                        onPress: () => handleDelete(),
                        color: theme.errorColor,
                    },
                    !forceNow
                        ? {
                              text: profProfileMessages.terminateOnDate,
                              onPress: (i) => {
                                  ok = true;
                                  date = new Date(
                                      i?.find(
                                          ({ id }) => id === 'termOn',
                                      )?.value,
                                  );
                              },
                              disabled: (i) =>
                                  new Date(
                                      i?.find(
                                          ({ id }) => id === 'termOn',
                                      )?.value,
                                  ).toLocaleDateString('de') ===
                                  new Date().toLocaleDateString('de'),
                              color: theme.warningColor,
                          }
                        : undefined,
                    {
                        text: profProfileMessages.terminateNow,
                        onPress: () => (ok = true),
                        color: theme.warningColor,
                    },
                ],
                cancelButton: {
                    text: actionMessages.cancel,
                },
            });
            if (!ok) return;
            /**
             * get all avails related to remove them
             * or set end to now
             */
            const availRes = await getDataIndex(ECollections.availabilities, {
                filter: [
                    { field: 'profileId', value: prevProfInfos.documentId },
                ],
            });
            for (const avail of availRes as any[]) {
                const availability = new MAvailability(avail);
                if (availability.exactStartTime > date.getTime()) {
                    await remove(ECollections.availabilities, avail.documentId);
                } else if (availability.exactEndTime > date.getTime()) {
                    await put(
                        ECollections.availabilities,
                        availability.documentId,
                        new MAvailability({
                            ...availability,
                            exactEndTime: date.getTime(),
                        }),
                    );
                }
            }
            prevProfInfos.hasContract = false;
            prevProfInfos.terminateOn = date.getTime();
            prevProfInfos.terminated = true;
            await put(ECollections.profProfiles, prevProfInfos.documentId, {
                ...prevProfInfos,
            });
            navigate(-1);
        },
        [handleDelete, prevProfInfos],
    );
    /**
     * navigation lock. ask for discard before navigation
     */
    const navigationLock = useCallback(async () => {
        const locking = unsavedChanges && !(await unsavedChangesDialog());
        if (locking) {
            return true;
        } else {
            return false;
        }
    }, [unsavedChanges, unsavedChangesDialog]);
    /**
     * handle save of note
     */
    const handleNoteSave = useCallback(async () => {
        if (!curData) {
            return console.error('unexpected access to this function');
        }

        await put(ECollections.profProfileNotes, curData.documentId, { note });

        setPrevNote(note);
    }, [curData, note]);
    /**
     * register lock as current navigationlock
     */
    useEffect(() => setNavigationLock(navigationLock), [navigationLock]);
    /**
     * effecct to load agencies for super users. and assign userAgencies for regulars
     */
    useEffect(() => {
        if (!isLoggedIn) {
            return;
        }
        if (isAgencyUser(userData)) {
            setAgencies(userAgencies);
        } else if (isSuperUser(userData)) {
            getDataIndex(ECollections.agencies).then((res) =>
                setAgencies((res as any[]).map((a) => new MAgency(a))),
            );
        }
    }, [userData, userAgencies, isLoggedIn]);
    /**
     * effect to load prof profile
     */
    useEffect(() => {
        if (predefinedData) {
            setProfInfos(predefinedData);
            console.log(predefinedData);
            if (predefinedData.agencyId) {
                getDataById(
                    ECollections.publicAgencies,
                    predefinedData.agencyId,
                ).then((res) => {
                    const nextAgency = new MAgency(res);
                    console.log(nextAgency);
                    setAgencies([nextAgency]);
                });
            }
        } else if (id && id !== 'new') {
            getDataById(ECollections.profProfiles, id).then((res) => {
                const next = new MProfessionalProfile(res);
                const nextPrev = new MProfessionalProfile(res);
                if (next.filledOn) {
                    delete next.filledOn;
                }
                setProfInfos(next);
                setPrevProfInfos(nextPrev);
            });
            getDataById(ECollections.profProfileNotes, id).then((result) => {
                if (result && result.note) {
                    setNote(result.note);
                    setPrevNote(result.note);
                }
            });
        } else if (userAgencies.length === 1) {
            const next = new MProfessionalProfile({
                agencyId: userAgencies[0].documentId,
            });
            setProfInfos(next);
        } else {
            setProfInfos(new MProfessionalProfile());
        }
    }, [id, userData, userAgencies, predefinedData]);
    /**
     * effect to load talent chat for registered user
     */
    useEffect(() => {
        if (!prevProfInfos || !prevProfInfos.uid) return;
        getDataIndex(ECollections.chats, {
            filter: [
                {
                    field: 'participants',
                    operator: 'array-contains',
                    value: userData.documentId,
                },
            ],
        }).then((r) => {
            const coi = (r as MChat[]).find((c) =>
                c.participants.includes(prevProfInfos.uid || ''),
            );
            if (coi) {
                setChat(new MChat(coi));
            }
        });
    }, [prevProfInfos, userData]);

    return {
        profInfos,
        prevProfInfos,
        curData,
        prevData,
        prevProfileName,
        chat,
        agency,
        agencies,
        onChange,
        setNote,
        setEditingNote,
        handleNoteSave,
        note,
        editingNote,
        inviteUrl,
        handleReinvite,
        handleInviteCancel,
        handleTermination,
        handleTerminationRevert,
        handleDelete,
        handleSave,
        unsavedChanges,
        curValidity,
        setCurValidity,
        validities,
    };
};
