import React, { FC } from 'react';
import { View } from 'react-native';
import { CButton, CText, CLabel } from '../../../../components';
import { generalMessages } from '../../../../utilities/messages';
import { profProfileMessages } from '../../ProfProfile.messages';
import { useFormat } from '../../../../utilities/intl';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { useSecureNavigate } from '../../../../utilities/routing';
import { MChat, MProfessionalProfile } from '../../../../models';
import { useFireBase } from '../../../../utilities/firebase';

export const TalentHeadlineCard: FC<{
    profInfos: MProfessionalProfile;
    prevProfileName: string;
    chat?: MChat;
    handleReinvite: () => void;
    handleTermination: (forceNow?: boolean) => void;
    handleTerminationRevert: () => void;
}> = ({
    profInfos,
    prevProfileName,
    chat,
    handleReinvite,
    handleTermination,
    handleTerminationRevert,
}) => {
    const { secureNavigate } = useSecureNavigate();
    const { callFunction } = useFireBase();
    const { theme } = useTheme();
    const format = useFormat();
    const style = useStyle();
    /**
     * render
     */
    return (
        <View
            style={[
                style.headlineCard,
                style.centeredItems,
                style.horizontalSplit,
            ]}
        >
            <View style={style.horizontal}>
                <CButton
                    cy={'back'}
                    onPress={async () => {
                        secureNavigate(-1);
                    }}
                    icon={'chevronLeft'}
                    small
                />
                <CText
                    message={`${format(
                        generalMessages.professionalProfile,
                    )} - ${prevProfileName}`}
                    bold
                    headline
                    style={style.horizontalPadded}
                />
            </View>
            <View style={[style.horizontal, style.centeredItems]}>
                {profInfos.terminated ? (
                    new Date(profInfos.terminateOn) < new Date() ? (
                        <>
                            <CLabel
                                checked={true}
                                title={profProfileMessages.terminated}
                                color={theme.errorColor}
                                smaller
                            />
                            <CButton
                                onPress={handleTerminationRevert}
                                transparent
                                small
                                title={profProfileMessages.revertTerminate}
                            />
                        </>
                    ) : (
                        <>
                            <CLabel
                                checked={true}
                                title={format(
                                    profProfileMessages.terminationPlanned,
                                    {
                                        date: new Date(
                                            profInfos.terminateOn,
                                        ).toLocaleDateString('de'),
                                    },
                                )}
                                color={theme.errorColor}
                                smaller
                            />
                            <CButton
                                onPress={() => handleTermination(true)}
                                transparent
                                small
                                title={profProfileMessages.terminateNow}
                            />
                        </>
                    )
                ) : (
                    <CButton
                        onPress={handleTermination}
                        transparent
                        small
                        title={profProfileMessages.terminate}
                    />
                )}
                {!!chat && (
                    <CButton
                        onPress={() =>
                            secureNavigate('/chat/' + chat.documentId)
                        }
                        transparent
                        small
                        icon={'chat'}
                    />
                )}
                {!!(profInfos.uid && !chat) && (
                    <CButton
                        onPress={() => {
                            callFunction('openChatWithTalent', {
                                talentId: profInfos.documentId,
                                talentUid: profInfos.uid,
                            }).then((chatId) => {
                                secureNavigate('/chat/' + chatId);
                            });
                        }}
                        transparent
                        small
                        icon={'chat'}
                    />
                )}
                {!!(
                    profInfos.mail &&
                    profInfos.firstName &&
                    profInfos.lastName &&
                    !profInfos.uid
                ) &&
                    !profInfos.open && (
                        <CButton
                            onPress={handleReinvite}
                            transparent
                            small
                            icon={'plane'}
                            mouseOverTitle={profProfileMessages.reSendForm}
                            fontStyles={{ color: theme.accentColor }}
                        />
                    )}
            </View>
        </View>
    );
};
