import { format, doc } from 'prettier';
import React, { FC } from 'react';
import { CTable } from '../../../components';
import { documentScanMessages } from '../documentScan.messages';
import { useFormat } from '../../../utilities/intl';
import { MUserIdentDoc } from '../../../models';

export const DocumentValues: FC<{ doc: MUserIdentDoc }> = ({ doc }) => {
    const format = useFormat();

    return (
        <>
            <CTable
                hideHeaders
                headers={[
                    {
                        key: 'key',
                    },
                    {
                        key: 'value',
                    },
                ]}
                rows={[
                    {
                        key: 'documentNumber',
                        values: [
                            {
                                key: 'key',
                                value: format(
                                    documentScanMessages.documentNumber,
                                ),
                            },
                            {
                                key: 'value',
                                value: doc.documentNumber,
                            },
                        ],
                    },
                    {
                        key: 'documentIssuedBy',
                        values: [
                            {
                                key: 'key',
                                value: format(
                                    documentScanMessages.documentIssuedBy,
                                ),
                            },
                            {
                                key: 'value',
                                value: doc.documentIssuedBy,
                            },
                        ],
                    },
                    {
                        key: 'documentIssuedOn',
                        values: [
                            {
                                key: 'key',
                                value: format(
                                    documentScanMessages.documentIssuedOn,
                                ),
                            },
                            {
                                key: 'value',
                                value: doc.documentIssuedOn,
                            },
                        ],
                    },
                    {
                        key: 'documentExpiryDate',
                        values: [
                            {
                                key: 'key',
                                value: format(
                                    documentScanMessages.documentExpiryDate,
                                ),
                            },
                            {
                                key: 'value',
                                value: doc.documentExpiryDate,
                            },
                        ],
                    },
                    !!doc.reviewed && {
                        key: 'reviewed',
                        values: [
                            {
                                key: 'key',
                                value: format(documentScanMessages.reviewedBy),
                            },
                            {
                                key: 'value',
                                value: doc.reviewedBy,
                            },
                        ],
                    },
                ]}
            />
        </>
    );
};
