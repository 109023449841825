import React, { FC } from 'react';
import { Line } from 'rc-progress';
import { IProgressProps } from './IProgressProps';
import { useTheme } from '../../utilities/styles';

export const ProgressBar: FC<IProgressProps> = ({ percentage, color }) => {
    const { theme } = useTheme();
    return (
        <Line
            percent={percentage * 100}
            strokeWidth={1}
            strokeColor={
                color ||
                (percentage > 0.7 ? theme.successColor : theme.accentColor)
            }
            trailColor={theme.backgroundSecondaryColor}
        />
    );
};
