import React, { FC } from 'react';
import { View } from 'react-native';
import { CButton, CText, Spinner } from '../../../components';
import { useFormat } from '../../../utilities/intl';
import { generalMessages } from '../../../utilities/messages/general.messages';
import { useStyle } from '../../../utilities/styles';
import { UsersFilterBar } from './components/UsersFilterBar';
import { UserRow } from './components/UserRow';
import { actionMessages } from '../../../utilities/messages';
import { userMessages } from '../user.messages';
import { ScrollProvider } from '../../../utilities/contexts/Scroll';
import { useUserListState } from './useUserListState';
/**
 * Unwrapped User List since it interacts in its state with the scroll provider it is supposed to provide
 */
const UnwrappedUserList: FC = () => {
    // global state
    const style = useStyle();
    const format = useFormat();
    // local state
    const {
        totalUsersLength,
        length,
        loading,
        lodaingMore,
        loadMore,
        users,
        handleChange,
        setLoadingMore,
        endReached,
        mode,
        setMode,
        addMode,
        moreFilter,
        setMoreFilter,
        customStringFilter,
        setCustomStringFilter,
    } = useUserListState();
    /**
     * render
     */
    return (
        <>
            <View style={[style.card]}>
                <CText style={style.verticalPadded} bold headline>
                    {format(generalMessages.users)}
                </CText>
                <UsersFilterBar
                    {...{
                        mode,
                        setMode,
                        addMode,
                        moreFilter,
                        setMoreFilter,
                        customStringFilter,
                        setCustomStringFilter,
                    }}
                />
            </View>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <View style={style.horizontalSpaced}>
                        <CText>
                            {format(userMessages.searchMatchesXofY, {
                                y: totalUsersLength,
                                x: length,
                            })}
                        </CText>
                    </View>
                    {/* <CList
                        data={users}
                        keyExtractor={(user) => user.documentId}
                        renderItem={({ item }) => (
                            <UserRow user={item} handleChange={handleChange} />
                        )}
                    /> */}
                    {users.map((user) => (
                        <UserRow
                            key={user.documentId}
                            user={user}
                            handleChange={handleChange}
                        />
                    ))}
                    {lodaingMore ? (
                        <Spinner />
                    ) : (
                        !!users.length &&
                        users.length % 10 === 0 &&
                        !endReached && (
                            <View
                                style={[
                                    style.horizontalSpaced,
                                    style.verticalPadded,
                                ]}
                            >
                                <CButton
                                    onPress={() => {
                                        setLoadingMore(true);
                                        loadMore(users[users.length - 1]);
                                    }}
                                    minor
                                    title={format(actionMessages.loadMore)}
                                />
                            </View>
                        )
                    )}
                </>
            )}
        </>
    );
};
/**
 * List of users
 */
export const UserList: FC = () => {
    const style = useStyle();
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <UnwrappedUserList />
        </ScrollProvider>
    );
};
