import { defineMessages } from 'react-intl';

const scope = 'education.teacher.education.';
/**
 * teacher spechializations
 * is used for teacherSpecialization picker
 */
export const teacherEducationMessages = defineMessages({
    math: {
        id: `${scope}math`,
        defaultMessage: 'Math',
    },
    science: {
        id: `${scope}science`,
        defaultMessage: 'Science',
    },
    english: {
        id: `${scope}english`,
        defaultMessage: 'English',
    },
    history: {
        id: `${scope}history`,
        defaultMessage: 'History',
    },
    geography: {
        id: `${scope}geography`,
        defaultMessage: 'Geography',
    },
    music: {
        id: `${scope}music`,
        defaultMessage: 'Music',
    },
    art: {
        id: `${scope}art`,
        defaultMessage: 'Art',
    },
    physicalEducation: {
        id: `${scope}physicalEducation`,
        defaultMessage: 'Physical Education',
    },
    computerScience: {
        id: `${scope}computerScience`,
        defaultMessage: 'Computer Science',
    },
    foreignLanguage: {
        id: `${scope}foreignLanguage`,
        defaultMessage: 'Foreign Language',
    },
    specialEducation: {
        id: `${scope}specialEducation`,
        defaultMessage: 'Special Education',
    },
});
