import { ECollections } from '../enums';

export class MReview {
    public documentId = '';
    public createdOn = Date.now();

    public authorId = '';
    public targetId = '';
    public targetCollection: ECollections = ECollections.users;
    public contractId = '';
    public ratings: {
        id: string;
        labelKey: string;
        value: number;
    }[] = [];
    public text = '';

    constructor(params: Partial<MReview> = {}) {
        Object.assign(this, params);
    }
}
