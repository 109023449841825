import { StyleSheet } from 'react-native';
import { ITheme } from '../../../utilities/styles/ITheme';

export const makeCTextStyles = (theme: ITheme) =>
    StyleSheet.create({
        text: {
            fontFamily: theme.mainFontFamily,
            fontWeight: 'normal',
            color: theme.textSecondaryColor,
        },
        boldText: {
            fontFamily: theme.mainFontFamily,
            fontWeight: '600',
            color: theme.textSecondaryColor,
        },
        wrap: {
            flex: 1,
            flexWrap: 'wrap',
        },
        buttonText: {
            color: theme.textAccentColor,
        },
        disabledText: {
            color: theme.textTertiaryColor,
        },
        centered: {
            textAlign: 'center',
        },
        headline: {
            color: theme.textMainColor,
            fontSize: 20,
            fontFamily: theme.headlineFontFamily,
        },
        secondaryHeadline: {
            color: theme.textMainColor,
            fontSize: 18,
            fontFamily: theme.headlineFontFamily,
        },
    });
