import React, { FC } from 'react';
import { View } from 'react-native';
import { CButton, CText } from '../../../../components';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { TouchAllUsers } from './components/TouchAllUsers';
import { RemovePubUsers } from './components/RemovePubUsers';
import { TouchProfiles } from './components/TouchProfiles';
import { TouchApplications } from './components/TouchApplications';
import { TouchJobs } from './components/TouchJobs';
import { FixJobIds } from './components/FixJobIds';
import { RandomizeJobsCreatedOn } from './components/RandomizeJobsCreatedOn';
import { FixUserFileStructure } from './components/FixUserFileStructure';
import { TouchWorkplaces } from './components/TouchWorkplaces';
import { TouchHelps } from './components/TouchHelps';
import { TouchChats } from './components/TouchChats';
import { TouchAvailabilities } from './components/TouchAvailabilities';
import { useFireBase } from '../../../../utilities/firebase';

export const AdminActions: FC = () => {
    const { theme } = useTheme();
    const style = useStyle();
    const { callFunction } = useFireBase();

    // const [creatingAgencies, setCreatingAgencies] = useState(false);
    // /**
    //  * callback to create agencies from agency users
    //  */
    // const createAgencies = useCallback(() => {
    //     setCreatingAgencies(true);
    //     getDataIndex(ECollections.users, {
    //         filter: [
    //             {
    //                 field: 'type',
    //                 value: EUserType.agency,
    //             },
    //         ],
    //     }).then((users) => {
    //         Promise.all(
    //             (users as any[]).map(async (rawUser) => {
    //                 const user = new MUserData(rawUser);
    //                 const postResponse = await post(ECollections.agencies, {
    //                     ...new MAgency({
    //                         owner: user.documentId,
    //                         name: user.agency,
    //                         taxNumber: user.taxNumber,
    //                         registerNumber: user.registerNumber,
    //                         bankDetails: user.bankDetails,
    //                         address: user.address,
    //                         billingAddress: user.billingAddress,
    //                         users: [user.documentId],
    //                     }),
    //                 });
    //                 if (postResponse) {
    //                     const rawProfiles = await getDataIndex(
    //                         ECollections.profProfiles,
    //                         {
    //                             filter: [
    //                                 {
    //                                     field: 'moderators',
    //                                     value: user.documentId,
    //                                     operator: 'array-contains',
    //                                 },
    //                             ],
    //                         },
    //                     );
    //                     for (const rawProfile of rawProfiles as any[]) {
    //                         await put(
    //                             ECollections.profProfiles,
    //                             rawProfile.documentId,
    //                             { ...rawProfile, agencyId: postResponse?.id },
    //                         );
    //                     }
    //                 }
    //             }),
    //         ).then(() => setCreatingAgencies(false));
    //     });
    // }, [getDataIndex, put]);
    /**
     * render
     */
    return (
        <View>
            <CText headline bold style={[{ color: theme.errorColor }]}>
                Danger Zone
            </CText>
            <View style={style.verticalPadded}>
                <CButton
                    title={'Issue daily checks'}
                    onPress={async () =>
                        console.log(await callFunction('issueDailyChecks'))
                    }
                />
                <CButton
                    title={'Issue events'}
                    onPress={async () =>
                        console.log(await callFunction('issueEventCheck'))
                    }
                />
                <TouchAllUsers />
                <RemovePubUsers />
                <TouchProfiles />
                <TouchApplications />
                <TouchJobs />
                <TouchChats />
                <TouchHelps />
                <TouchWorkplaces />
                <TouchAvailabilities />
                <CText headline bold style={[{ color: theme.errorColor }]}>
                    Extra Danger Zone
                </CText>
                <FixJobIds />
                <RandomizeJobsCreatedOn />
                {/* <DuplicateHelpsToTP /> */}
                {/* <CButton
                    onPress={createAgencies}
                    title="Create agencies for existing users"
                    danger
                    disabled={creatingAgencies}
                /> */}
                <FixUserFileStructure />
            </View>
        </View>
    );
};
