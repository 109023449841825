import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { View } from 'react-native';
import { CPicker, CTextInput } from '../elements';
import { phonePrefixes } from './phonePrefixes';
import { useEnvironment } from '../../utilities/contexts';
import { ERegion } from '../../enums';
import { useStyle } from '../../utilities/styles';

export const PhoneNumberInput: FC<{
    value: string;
    onChange: (next: string) => void;
}> = ({ value, onChange }) => {
    const { region } = useEnvironment();
    const style = useStyle();
    /**
     * memoized destruction of phone number
     */
    const { prefix, mainNumber } = useMemo(() => {
        const nextPP = phonePrefixes.find(
            (pp) => value.includes('+' + pp.code) || value === '+' + pp.code,
        );
        let nextMainNumber = value;
        if (nextPP) {
            const p = '+' + nextPP.code;
            while (nextMainNumber.includes(p) || nextMainNumber === p) {
                nextMainNumber = value.replace(p, '');
                if (nextMainNumber === p) {
                    nextMainNumber = '';
                }
            }
        }
        const next = {
            prefix: nextPP?.code,
            mainNumber: nextMainNumber,
        };

        return next;
    }, [value]);
    /**
     * handle prefix change
     */
    const handlePrefix = useCallback(
        (nextPrefix: string) => {
            onChange('+' + nextPrefix + mainNumber);
        },
        [mainNumber],
    );
    /**
     * handle main number change
     */
    const handleMainNumber = useCallback(
        (nextNumber: string) => {
            const numberAsNumber = +nextNumber;
            if (isNaN(numberAsNumber)) return;
            onChange('+' + prefix + numberAsNumber);
        },
        [prefix],
    );
    /**
     * effect to always have a number with valid prefix
     * could not find prefix so trigger a change with a valid prefix for the current number
     */
    useEffect(() => {
        if (!prefix) {
            const defaultCode = region === ERegion.de ? '49' : '27';
            const numberAsNumber = +mainNumber;
            onChange(
                '+' +
                    defaultCode +
                    (isNaN(numberAsNumber) ? 0 : numberAsNumber),
            );
        }
    }, [prefix, mainNumber]);

    return (
        <View style={[style.horizontal, style.centeredItems]}>
            <CPicker
                value={prefix}
                values={phonePrefixes
                    .filter((v) => ['DE', 'ZA'].includes(v.iso))
                    .map((pp) => ({
                        label: '+' + pp.code + ' ' + pp.iso,
                        value: pp.code,
                    }))}
                onChange={handlePrefix}
                style={style.horizontalPadded}
            />
            <CTextInput
                value={mainNumber}
                onChangeText={handleMainNumber}
                autoExtend
                horizontal
                type={'numeric'}
                style={[style.flex1, style.leftPadded]}
            />
        </View>
    );
};
