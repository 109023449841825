import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-native';
import { CText, Spinner } from '../../../../components';
import { ECollections } from '../../../../enums';
import { EApplicationType } from '../../../../enums/EApplicationType';
import { MContract } from '../../../../models';
import { isEmployer, isSuperUser } from '../../../../utilities/auth';
import { useDialog } from '../../../../utilities/dialog';
import { useFireBase } from '../../../../utilities/firebase';
import { week } from '../../../../utilities/functions';
import { actionMessages } from '../../../../utilities/messages';
import { ContractContext } from './ContractContext';
import {
    AdminNegotiation,
    AgencyNegotiation,
    UserNegotiation,
} from './components';
import { contractMessages } from '../../contract.messages';
import { useLock } from '../../../../utilities/hooks';
/**
 * Contract Negotiation View. WIll choose the right contract view on a per user type basis and provide context for contract data
 * @returns
 */
export const useContractNegotiationState = () => {
    // global state
    const { id } = useParams<{ id: string }>();
    const { lock } = useLock();
    const { watchDataById, userData, callFunction, userWorkplaces } =
        useFireBase();
    const dialog = useDialog();
    // local state
    const [contract, setContract] = useState<MContract>();
    const [originalContract, setOG] = useState<MContract>();
    const [askedForTakeover, setAskedForTakeover] = useState(false);
    /**
     * on change callback to handle partial new contracct infos and fuse them with the contact infos
     */
    const onChange = useCallback((next: Partial<MContract>) => {
        setContract((prev) => {
            return new MContract({ ...prev, ...next });
        });
    }, []);

    const handleUndo = useCallback(async () => {
        const ok = await dialog({
            title: contractMessages.confirmUndo,
            message: contractMessages.confirmUndoText,
            cancelButton: { text: actionMessages.cancel },
            buttons: [{ text: actionMessages.ok }],
        });
        if (ok) {
            const unlock = lock();
            const res = await callFunction('undoContractChange', {
                contractId: contract?.documentId,
            });
            console.log(res);
            unlock();
        }
    }, [contract]);
    /**
     * callback to handle close of contract
     */
    const handleClose = useCallback(async () => {
        if (!contract) {
            return;
        }
        let reason = '';
        const ok = await dialog({
            title: contractMessages.youIssuedAClose,
            message: contractMessages.youIssuedACloseText,
            cancelButton: {
                text: actionMessages.cancel,
            },
            buttons: [
                {
                    text: actionMessages.ok,
                    onPress: (inputs) => {
                        reason =
                            inputs?.find((i) => i.id === 'reason')?.value || '';
                    },
                    disabled: (inputs) => {
                        const tip = inputs?.find((i) => i.id === 'reason');
                        if (!tip) {
                            return true;
                        } else {
                            return !tip.value.length;
                        }
                    },
                },
            ],
            textInputs: [
                {
                    id: 'reason',
                    title: contractMessages.negotiationCancellationReason,
                },
            ],
            icon: 'warning',
        });
        console.log(ok, 'yeet');
        if (ok) {
            const unlock = lock();
            await callFunction('cancelContract', {
                contractId: contract.documentId,
                cancelReason: reason,
            });
            unlock();
        }
    }, [contract]);
    /**
     * effect to get contract
     */
    useEffect(() => {
        if (!id) {
            return;
        }
        return watchDataById(ECollections.contracts, id, (res) => {
            const next = new MContract(res);
            if (!next.from) {
                next.from = Date.now() + week;
            }
            if (!next.to) {
                next.to = next.from + week;
            }
            setContract(next);
            setOG(next);
        });
    }, [id, watchDataById]);
    /**
     * effect to take over contract if same workplace user
     */
    useEffect(() => {
        if (!contract || askedForTakeover) {
            return;
        }
        if (
            isEmployer(userData) &&
            contract.employerId !== userData.documentId &&
            userWorkplaces.find((wp) => wp.documentId === contract.workplaceId)
        ) {
            setAskedForTakeover(true);
            dialog({
                icon: 'question',
                title: contractMessages.takeOverNegotiation,
                message: contractMessages.takeOverNegotiationText,
                buttons: [
                    {
                        text: actionMessages.ok,
                        onPress: () => {
                            callFunction('takeOverContract', {
                                contractId: contract.documentId,
                            });
                        },
                    },
                ],
                cancelButton: { text: actionMessages.cancel },
            });
        }
    }, [contract, userData, userWorkplaces, askedForTakeover]);
    return { originalContract, contract, onChange, handleClose, handleUndo };
};
