import { defineMessages } from 'react-intl';

const scope = 'component.timePicker.';

export const timePickerMessages = defineMessages({
    time: {
        id: scope + 'time',
        defaultMessage: 'Time',
    },
});
