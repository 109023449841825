/**
 * flatten keys of object
 * @param input object
 * @returns flat object
 */
export function flattenObject(input: any, convertNull?: boolean) {
    const toReturn: any = {};
    Object.keys(input).forEach((key: string) => {
        if (
            input[key] &&
            typeof input[key] === 'object' &&
            !isArray(input[key])
        ) {
            const flatObject = flattenObject(input[key]);
            Object.keys(flatObject).forEach((subKey: string) => {
                toReturn[key + '.' + subKey] = flatObject[subKey];
            });
        } else if (isArray(input[key])) {
            toReturn[key] = input[key].map((arrayItem: any) => {
                if (typeof arrayItem === 'object') {
                    return flattenObject(arrayItem);
                } else {
                    return arrayItem;
                }
            });
        } else if (input[key]) {
            toReturn[key] = input[key];
        } else if (typeof input[key] === 'boolean') {
            toReturn[key] = input[key];
        } else if (input[key] === null) {
            if (convertNull) {
                toReturn[key] = 'null';
            } else {
                toReturn[key] = input[key];
            }
        } else if (input[key] === 0) {
            toReturn[key] = input[key];
        }
    });

    return toReturn;
}
/**
 * unflatten keys of object
 * @param input object
 * @returns unflat object
 */
export function unflattenObject(input: any, convertNull?: boolean) {
    const toReturn: any = {};
    if (input === null) {
        return undefined;
    }
    Object.keys(input)
        .sort((k1, k2) => k2.length - k1.length)
        .forEach((fkey: string) => {
            let pivot = toReturn;
            fkey.split('.').map((k, i, arr) => {
                const safeK = k.replace('`', '');

                if (!pivot[safeK]) {
                    pivot[safeK] = {};
                }
                // set value if end is reached
                if (i === arr.length - 1) {
                    if (convertNull && input[fkey] === 'null') {
                        pivot[safeK] = null;
                    } else if (typeof input[fkey] === 'object') {
                        pivot[safeK] = unflattenObject(input[fkey]);
                    } else {
                        pivot[safeK] = input[fkey];
                    }
                } else {
                    // move pivot
                    pivot = pivot[safeK];
                }
            });
        });
    // ! sketchy array parsing
    const arrayParseFunction = (ob: any) => {
        if (ob !== null) {
            Object.keys(ob).forEach((fkey: string) => {
                const pivot = ob[fkey];
                if (isArray(pivot)) {
                    const arr = [];
                    for (const elem in pivot) {
                        arr.push(pivot[elem]);
                    }
                    ob[fkey] = arr;
                } else if (pivot && typeof pivot === 'object') {
                    arrayParseFunction(pivot);
                }
            });
        }
    };
    arrayParseFunction(toReturn);

    return toReturn;
}
/**
 * detect arrays disguised as objects e.g.: {"0": "value", "1": "value"}
 * @param input anything
 * @returns if input is array
 */
function isArray(input: any) {
    const isObject = input && typeof input === 'object';
    if (isObject) {
        const keys = Object.keys(input);
        return (
            keys.length &&
            !keys.find((key) => {
                if (isNaN(Number(key))) {
                    return true;
                }
            })
        );
    }
    return false;
}
