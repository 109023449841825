import React, { FC, useEffect, useMemo, useState } from 'react';
import { ECollections, EUserType } from '../../../enums';
import { useFireBase } from '../../firebase';
import { IDefaultProps } from '../../IDefaultProps';
import { ProfileCompletionContext } from './ProfileCompletionContext';
import { MUserIdentDoc } from '../../../models';

export const ProfileCompletionProvider: FC<IDefaultProps> = (props) => {
    const { userData, user, mfaInfo, getDataIndex, userAgencies } =
        useFireBase();
    /**
     * state to store id docs in
     */
    const [idDocs, setIdDocs] = useState<MUserIdentDoc[]>([]);
    const reviewedAndActiveIdDocs = useMemo(
        () => idDocs.filter((d) => d.reviewed && d.expiresOn > Date.now()),
        [idDocs],
    );
    /**
     * effect to load id docs
     */
    useEffect(() => {
        if (!userData.documentId) {
            return;
        }
        getDataIndex(
            `${ECollections.users}/${userData.documentId}/${ECollections.identDocuments}`,
        ).then((res) => {
            setIdDocs((res as any[]).map((r) => new MUserIdentDoc(r)));
        });
    }, [userData]);
    /**
     * memoized if user has id doc
     */
    const idDoc = useMemo(
        () => !!reviewedAndActiveIdDocs.length,
        [reviewedAndActiveIdDocs],
    );
    /**
     * check if email is verified
     */
    const emailVerified = useMemo(() => !!user?.emailVerified, [user]);
    /**
     * check if essential banking information is complete
     */
    const completeBank = useMemo(
        () =>
            !!(
                userData.bankDetails.IBAN &&
                userData.bankDetails.BIC &&
                userData.bankDetails.accountHolderName
            ) || userData.type === EUserType.admin,
        [userData],
    );
    /**
     * check if address is filled in
     */
    const completeAddress = useMemo(
        () =>
            !!(
                userData.address.city &&
                userData.address.country &&
                userData.address.number &&
                userData.address.street &&
                userData.address.zipCode
            ),
        [userData],
    );
    /**
     * check if education informations are present
     */
    const completePersonal = useMemo(() => {
        const userComplete =
            userData.type === EUserType.user && userData.educations.length;

        const adminComplete = userData.type === EUserType.admin;

        return !!(userComplete || adminComplete);
    }, [userData]);
    /**
     * check if userData is verified by agent
     */
    const verified = useMemo(
        () =>
            !!(
                userData.verified &&
                userData.verifiedFrom &&
                userData.verifiedOn
            ),
        [userData],
    );
    /**
     * memoized search for unlicensed agencies on user agencies if user is agency
     */
    const hasUnlicensedAgencyAndIsAgencyUser = useMemo(() => {
        if (userData.type === EUserType.agency) {
            return userAgencies.find((a) => !a.licenseUntil);
        }
    }, [userData, userAgencies]);
    /**
     * memoized search for unlicensed agencies on user agencies if user is agency
     */
    const hasNoAgencyProfilePicture = useMemo(() => {
        if (userData.type === EUserType.agency) {
            return userAgencies.find((a) => !a.picture);
        }
    }, [userData, userAgencies]);
    /**
     * memoized search for agencies w/o address on user agencies if user is agency
     */
    const hasNoAgencyAddress = useMemo(() => {
        if (userData.type === EUserType.agency) {
            return userAgencies.find((a) => !a.address.street);
        }
    }, [userData, userAgencies]);
    /**
     * check if 2fa is setup
     */
    const mfa = useMemo(() => !!mfaInfo, [mfaInfo]);
    /**
     * combine checks to determin progress
     */
    const progress = useMemo(() => {
        const steps: boolean[] = [emailVerified, verified, mfa]; // idDoc
        if (userData.type === EUserType.user) {
            steps.push(completePersonal);
            steps.push(completeBank);
            steps.push(completeAddress);
        }
        if (userData.type === EUserType.agency) {
            steps.push(!hasUnlicensedAgencyAndIsAgencyUser);
            steps.push(!hasNoAgencyAddress);
            steps.push(!hasNoAgencyProfilePicture);
        }
        const completetSteps = steps.filter((s) => !!s);
        return completetSteps.length ? completetSteps.length / steps.length : 0;
    }, [
        mfa,
        emailVerified,
        verified,
        completeBank,
        completeAddress,
        completePersonal,
        userData,
        hasUnlicensedAgencyAndIsAgencyUser,
        hasNoAgencyAddress,
        hasNoAgencyProfilePicture,
        idDoc,
    ]);
    /**
     * return context with children
     */
    return (
        <ProfileCompletionContext.Provider
            value={{
                emailVerified: emailVerified ? true : false,
                completeBank: completeBank ? true : false,
                completeAddress: completeAddress ? true : false,
                completePersonal: completePersonal ? true : false,
                verified: verified ? true : false,
                licensedAgency: !hasUnlicensedAgencyAndIsAgencyUser
                    ? true
                    : false,
                mfa,
                idDoc,
                progress,
                addressAgency: !hasNoAgencyAddress,
                pictureAgency: !hasNoAgencyProfilePicture,
            }}
        >
            {props.children}
        </ProfileCompletionContext.Provider>
    );
};
