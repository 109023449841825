import Clipboard from '@react-native-clipboard/clipboard';
import React, { FC } from 'react';
import { View } from 'react-native';
import {
    CButton,
    CCard,
    CCheckBox,
    CPicker,
    CText,
    ProfilePicture,
    RichTextEditor,
    RichTextView,
    Spinner,
} from '../../../components';
import { MProfessionalProfile } from '../../../models';
import { isAgencyUser, isSuperUser } from '../../../utilities/auth';
import { ScrollProvider } from '../../../utilities/contexts/Scroll';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { actionMessages, generalMessages } from '../../../utilities/messages';
import { useParams, useSecureNavigate } from '../../../utilities/routing';
import { useStyle, useTheme } from '../../../utilities/styles';
import { ProfileContext } from '../../Profile/Profile.context';
import { profProfileMessages } from '../ProfProfile.messages';
import {
    ProfessionalAvailabilities,
    ProfessionalBasic,
    ProfessionalDocuments,
    ProfessionalEmployment,
    ProfessionalHistory,
    ProfessionalInformation,
    ProfessionalLanguage,
    ProfessionalResume,
    ProfessionalTimesheetSection,
    ProfessionalWage,
    TalentSectionAddress,
    UnsafeProfessionalDocuments,
} from './components';
import { useTalentEditState } from './useTalentEditState';
import { TalentHeadlineCard } from './components/TalentHeadlineCard';
/**
 * ProfProfileEdit
 * used by fill profile from url (talent formular)
 * @returns
 */
export const ProfProfileEdit: FC<{
    nested?: boolean;
    saveOverride?: (next: MProfessionalProfile) => void;
    saveAndRegisterNewUser?: (next: MProfessionalProfile) => void;
    predefinedData?: MProfessionalProfile;
    showUnsafeDocSection?: boolean;
}> = ({
    nested,
    saveOverride,
    saveAndRegisterNewUser,
    predefinedData,
    showUnsafeDocSection,
}) => {
    // global state
    const style = useStyle();
    const { theme } = useTheme();
    const { secureNavigate } = useSecureNavigate();
    const format = useFormat();
    const { userData } = useFireBase();
    const { id } = useParams<{ id: string }>();
    // local state
    const {
        profInfos,
        prevProfInfos,
        curData,
        prevData,
        prevProfileName,
        chat,
        agency,
        agencies,
        onChange,
        setNote,
        setEditingNote,
        handleNoteSave,
        note,
        editingNote,
        inviteUrl,
        handleReinvite,
        handleInviteCancel,
        handleTermination,
        handleTerminationRevert,
        handleDelete,
        handleSave,
        unsavedChanges,
        curValidity,
        setCurValidity,
        validities,
    } = useTalentEditState({
        nested,
        saveOverride,
        saveAndRegisterNewUser,
        predefinedData,
        showUnsafeDocSection,
    });
    /**
     * display spinner during load
     */
    if (!profInfos) {
        return <Spinner />;
    }
    /**
     * render
     */
    return (
        <ProfileContext.Provider
            value={{
                curData,
                prevData,
                onChange,
                onValidityChange: (v, key) =>
                    setCurValidity((prev: any) => {
                        if (key) {
                            const next = {} as any;
                            next[key] = v;
                            return { ...prev, ...next };
                        }
                        return { ...prev, basic: v };
                    }),
            }}
        >
            <ScrollProvider
                style={style.paddedScrollableMainView}
                nested={nested}
            >
                {!nested && (
                    <TalentHeadlineCard
                        {...{
                            profInfos,
                            prevProfileName,
                            chat,
                            handleReinvite,
                            handleTermination,
                            handleTerminationRevert,
                        }}
                    />
                )}
                {!nested && !!inviteUrl && (
                    <CCard>
                        <View
                            style={[style.horizontalSplit, style.centeredItems]}
                        >
                            <View>
                                <CText message={inviteUrl} />
                            </View>
                            <View style={style.horizontal}>
                                <CButton
                                    icon={'close'}
                                    onPress={handleInviteCancel}
                                    mouseOverTitle={actionMessages.close}
                                    minor
                                    iconColor={theme.errorColor}
                                    transparent
                                    fontStyles={{ color: theme.errorColor }}
                                />
                                <CButton
                                    icon={'clipboardMulti_outline'}
                                    onPress={() => {
                                        Clipboard.setString(inviteUrl);
                                    }}
                                    minor
                                    transparent
                                />
                            </View>
                        </View>
                    </CCard>
                )}
                {agencies.length > 1 && (
                    <View style={style.card}>
                        <CPicker
                            values={agencies.map((agency) => {
                                return {
                                    value: agency.documentId,
                                    label: agency.name,
                                };
                            })}
                            onChange={(v) => {
                                onChange({ agencyId: v });
                            }}
                            value={agency?.documentId}
                        />
                        {agency && (
                            <View style={style.horizontal}>
                                <CButton
                                    onPress={() => {
                                        secureNavigate(
                                            '/agency/edit/' + agency.documentId,
                                        );
                                    }}
                                    minor
                                    transparent
                                    title={profProfileMessages.openAgency}
                                />
                            </View>
                        )}
                    </View>
                )}
                {nested && agencies.length === 1 && (
                    <View style={[style.centeredItems, { width: '100%' }]}>
                        <ProfilePicture data={agencies[0]} />
                        <CText secondaryHeadline message={agencies[0].name} />
                        <View
                            style={[
                                style.paddedThinSeparator,
                                { width: '100%' },
                            ]}
                        />
                    </View>
                )}
                {/* basic info edit for professional profiles */}
                <View style={[style.card, { zIndex: 1 }]}>
                    <ProfessionalBasic />
                </View>
                {(isAgencyUser(userData) || isSuperUser(userData)) && (
                    <CCard>
                        <View style={style.verticalPadded}>
                            <CText message={profProfileMessages.note} />
                        </View>
                        {editingNote ? (
                            <RichTextEditor text={note} onChange={setNote} />
                        ) : (
                            <RichTextView html={note} />
                        )}
                        <View
                            style={[
                                style.horizontalSpaced,
                                style.verticalPadded,
                            ]}
                        >
                            {editingNote ? (
                                <CButton
                                    cy={'save-note'}
                                    icon={'check'}
                                    title={format(
                                        profProfileMessages.finishEditing,
                                    )}
                                    onPress={() => {
                                        handleNoteSave().then(() => {
                                            setEditingNote(false);
                                        });
                                    }}
                                />
                            ) : (
                                <CButton
                                    cy={'edit-note'}
                                    title={format(profProfileMessages.editNote)}
                                    onPress={() => {
                                        setEditingNote(true);
                                    }}
                                />
                            )}
                        </View>
                    </CCard>
                )}
                <CCard>
                    <TalentSectionAddress />
                </CCard>
                <View
                    style={[
                        style.card,
                        !curData.educations.length && style.errorBorder,
                    ]}
                >
                    <ProfessionalInformation />
                </View>
                <View
                    style={[style.card, curValidity.lang && style.errorBorder]}
                >
                    <ProfessionalLanguage />
                </View>
                <View style={[style.card]}>
                    <ProfessionalEmployment />
                </View>
                <View style={[style.card, { zIndex: 1 }]}>
                    <ProfessionalHistory />
                </View>
                {/* collapsable resume preview for created professional profiles */}
                {id !== 'new' && !nested && <ProfessionalResume />}
                {id !== 'new' && agency && (
                    <View style={style.card}>
                        <ProfessionalDocuments agency={agency} />
                    </View>
                )}
                {!!curData.documentId && showUnsafeDocSection && (
                    <View style={style.card}>
                        <UnsafeProfessionalDocuments
                            agencyId={curData.agencyId}
                        />
                    </View>
                )}
                {!nested && (
                    <CCard>
                        <ProfessionalTimesheetSection />
                    </CCard>
                )}
                {/* wage rates for professional profiles */}
                {!nested && (
                    <View style={style.card}>
                        <ProfessionalWage
                            curData={profInfos}
                            prevData={prevProfInfos}
                            onChange={onChange}
                            onValidityChange={(v, key) =>
                                setCurValidity((prev: any) => {
                                    if (key) {
                                        const next = {} as any;
                                        next[key] = v;
                                        return { ...prev, ...next };
                                    }
                                    return { ...prev, basic: v };
                                })
                            }
                        />
                    </View>
                )}
                {!!curData.documentId && !nested && (
                    <CCard style={{ zIndex: 1 }} outsideStyle={{ zIndex: 1 }}>
                        <ProfessionalAvailabilities agency={agency} />
                    </CCard>
                )}
                {/* watch for jobs check for professional profiles */}
                {!nested && (
                    <View style={style.card}>
                        <CCheckBox
                            checked={curData.hasContract}
                            onCheckedChanged={(hasContract) => {
                                onChange({ hasContract, terminated: false });
                            }}
                            title={profProfileMessages.hasContract}
                            style={style.verticalPadded}
                        />
                        <CPicker
                            title={profProfileMessages.internalRating}
                            style={style.verticalPadded}
                            values={Array.from(Array(10)).map((_, i) => ({
                                value: i + 1,
                                label: `${i}`,
                            }))}
                            value={curData.internalRating + 1}
                            onChange={(v) => {
                                onChange({ internalRating: v - 1 });
                            }}
                        />
                        <CText
                            secondaryHeadline
                            message={
                                profProfileMessages.watchForNewJobsForThisProfile
                            }
                        />
                        <CText
                            message={
                                profProfileMessages.watchForNewJobsForThisProfileDescription
                            }
                        />
                        <CCheckBox
                            title={profProfileMessages.watchForNewJobs}
                            checked={profInfos.isWatching}
                            onCheckedChanged={(isWatching) =>
                                onChange({ isWatching })
                            }
                        />
                    </View>
                )}
                <View style={style.verticalPadded}>
                    <View style={style.horizontalSplit}>
                        <View />
                        <View style={{ alignItems: 'flex-end' }}>
                            {!!validities.length && (
                                <View style={{ marginHorizontal: 'auto' }}>
                                    {validities.map((v) => (
                                        <CText
                                            key={v.key as string}
                                            style={style.error}
                                        >
                                            {v.value}
                                        </CText>
                                    ))}
                                </View>
                            )}
                            {unsavedChanges && (
                                <CText style={style.warning}>
                                    {format(generalMessages.unsavedChanges)}
                                </CText>
                            )}
                            <View style={style.horizontal}>
                                {!nested && !!profInfos.documentId && (
                                    <CButton
                                        onPress={handleDelete}
                                        title={profProfileMessages.deleteTalent}
                                        danger
                                    />
                                )}
                                <CButton
                                    disabled={!!validities.length}
                                    onPress={() => handleSave(true)}
                                    title={format(
                                        nested
                                            ? actionMessages.submitForm
                                            : actionMessages.save,
                                    )}
                                />
                                {/* {saveAndRegisterNewUser && isLocalhost() && (
                                    <CButton
                                        disabled={!!validities.length}
                                        onPress={() =>
                                            saveAndRegisterNewUser(
                                                new MProfessionalProfile({
                                                    ...profInfos,
                                                }),
                                            )
                                        }
                                        title={format(
                                            profProfileMessages.saveAndCreateAccount,
                                        )}
                                    />
                                )} */}
                            </View>
                        </View>
                    </View>
                </View>
            </ScrollProvider>
        </ProfileContext.Provider>
    );
};
