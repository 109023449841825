import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFireBase } from '../firebase';
import { IDefaultProps } from '../IDefaultProps';
import { PushNotificationContext } from './PushNotificationContext';
import { useEnvironment } from '../contexts';
import { EEnvironment } from '../../enums';

const situsLogoSrc =
    'https://www.situsdocs.de/wp-content/uploads/2022/09/path177.png';
const tpLogoSrc =
    'https://timeployees.de/wp-content/uploads/2023/09/Thumbnail.svg';

export const PushNotificationProvider: FC<IDefaultProps> = ({ children }) => {
    const { isLoggedIn } = useFireBase();
    const { environment } = useEnvironment();

    const [inBackground, setInBackground] = useState(false);

    const currentLogoSrc = useMemo(
        () => (environment === EEnvironment.SITUS ? situsLogoSrc : tpLogoSrc),
        [environment],
    );

    const retryRegister = useCallback(async (retry?: boolean) => {
        // @ts-ignore
        if (!('Notification' in window)) {
            console.warn('This browser does not support system notifications!');
        } else if (
            // @ts-ignore
            !['granted', 'denied'].includes(Notification.permission) ||
            retry
        ) {
            // @ts-ignore
            Notification.requestPermission((permission) => {
                if (permission === 'granted') {
                    console.log('permission granted');
                }
            });
        }
    }, []);

    const sendNotification = useCallback(
        (title: string, message: string, urL: string, force?: boolean) => {
            if (inBackground || force) {
                // @ts-ignore
                const browserNote = new Notification(title, {
                    icon: currentLogoSrc,
                    image: currentLogoSrc,
                    badge: currentLogoSrc,
                    body: message,
                });
                browserNote.onclick = () =>
                    function () {
                        // @ts-ignore
                        window.open(urL);
                    };
            }
        },
        [currentLogoSrc],
    );

    useEffect(() => {
        if (isLoggedIn) {
            retryRegister();
        }
    }, [isLoggedIn]);

    useEffect(() => {
        // @ts-ignore
        document.onvisibilitychange = () => {
            // @ts-ignore
            setInBackground(!!document.hidden);
        };
    });

    return (
        <PushNotificationContext.Provider
            value={{
                retryRegister: () => retryRegister(true),
                sendNotification,
            }}
        >
            {children}
        </PushNotificationContext.Provider>
    );
};
