import React, { FC, useEffect, useState } from 'react';

import { CButton } from '../../../../components';
import { ECollections, EApplicationStatus } from '../../../../enums';
import { isAgencyUser, isSuperUser } from '../../../../utilities/auth';
import { useFireBase } from '../../../../utilities/firebase';
import { IFilter } from '../../../../utilities/firebase/store';
import { useFormat } from '../../../../utilities/intl';
import { useStyle } from '../../../../utilities/styles';
import { applicationMessages } from '../../application.messages';
/**
 * job application filter button with self aware length check
 */
export const JobApplicationFilterButton: FC<{
    status: EApplicationStatus;
    statusFilter?: EApplicationStatus;
    onPress: (status: EApplicationStatus) => void;
    hasContentCallback?: (hasContent: boolean) => void;
}> = ({ status, statusFilter, onPress, hasContentCallback }) => {
    // global state
    const { userData, userAgencies, getDataIndex } = useFireBase();
    const style = useStyle();
    const format = useFormat();
    // local state
    const [length, setLength] = useState(0);
    /**
     * effect to load current length of query result with this status as filter
     */
    useEffect(() => {
        const filter: IFilter[] = [];
        if (!isSuperUser(userData)) {
            if (isAgencyUser(userData)) {
                filter.push({
                    field: 'agencyId',
                    operator: 'in',
                    value: userAgencies.map((a) => a.documentId),
                });
            } else {
                filter.push({
                    field: 'applicantUid',
                    value: userData.documentId,
                });
            }
        }

        filter.push({ field: 'status', value: status });
        getDataIndex(ECollections.applications, {
            filter,
            getLength: true,
        }).then((result) => {
            if (result && !isNaN(result as number)) {
                setLength(result as number);
                if (hasContentCallback) {
                    hasContentCallback(true);
                }
            } else {
                setLength(0);
                if (hasContentCallback) {
                    hasContentCallback(false);
                }
            }
        });
    }, [userData, status, userAgencies]);
    /**
     * render
     */
    return (
        <CButton
            key={status}
            minor={statusFilter !== status}
            small
            title={`${format(applicationMessages[status])} (${length})`}
            onPress={() => {
                onPress(status);
            }}
            style={style.smallMargin}
        />
    );
};
