import React, { FC, useMemo } from 'react';
import { MProfessionalInfo, MUserData } from '../../models';
import { CLabel } from '../elements/CLabel';
import {
    educationMessages,
    fieldMessages,
    generalMessages,
} from '../../utilities/messages';
import { getUserTypeColor } from '../../utilities/constants';
import { EProfession, EUserType } from '../../enums';
import { useFormat } from '../../utilities/intl';
import { CText } from '..';
/**
 * User Type label. Displays Profession for EUserType.User and ProfProfiles
 */
export const UserTypeLabel: FC<{
    data: MProfessionalInfo;
    small?: boolean;
    simple?: boolean;
    piorizeEducation?: boolean;
}> = ({ data, small, simple, piorizeEducation }) => {
    const format = useFormat();
    /**
     * memoized text to display usually usertype or profession
     */
    const textToDisplay = useMemo(() => {
        const userData = data as MUserData;
        if (piorizeEducation) {
            return data.educations.map((e) =>
                format(educationMessages[e as keyof typeof educationMessages]),
            );
        }
        if (userData.type && userData.type !== EUserType.user) {
            if (userData.type === EUserType.talent) {
                return [
                    format(generalMessages[userData.type]),
                    format(
                        userData.profession === EProfession.undefined
                            ? fieldMessages[data.field]
                            : generalMessages[data.profession],
                    ),
                ].join(': ');
            }
            return format(generalMessages[userData.type]);
        } else {
            return format(
                data.profession === EProfession.undefined
                    ? fieldMessages[data.field]
                    : generalMessages[data.profession],
            );
        }
    }, [data]);
    /**
     * memoized color of label
     */
    const colorToDisplay = useMemo(() => {
        const userData = data as MUserData;

        if (userData.type) {
            return getUserTypeColor(userData.type);
        } else {
            return getUserTypeColor(EUserType.user);
        }
    }, [data]);
    if (simple) {
        if (typeof textToDisplay === 'string') {
            return <CText message={textToDisplay} numberOfLines={1} />;
        } else {
            return (
                <>
                    {(textToDisplay as string[]).map((t) => (
                        <CText key={t} message={t} numberOfLines={1} />
                    ))}
                </>
            );
        }
    }
    /**
     * render
     */
    if (typeof textToDisplay === 'string') {
        return (
            <CLabel
                title={textToDisplay}
                color={colorToDisplay}
                smaller
                checked
                style={small && { margin: 0 }}
            />
        );
    } else {
        return (
            <>
                {(textToDisplay as string[]).map((t) => (
                    <CLabel
                        key={t}
                        title={t}
                        color={colorToDisplay}
                        smaller
                        checked
                        style={small && { margin: 0 }}
                    />
                ))}
            </>
        );
    }
};
