import { useCallback } from 'react';
import { MFile } from '../../models';
import { useFireBase } from '../firebase';

export const useFileUpload = () => {
    const { uploadToStorage, userData } = useFireBase();

    return useCallback(
        async (path: string, filename: string, file: Uint8Array) => {
            const fnS = filename.split('.');
            const ending = fnS.pop();
            const name = fnS.shift();
            const basename = Date.now().toString(36) + name;
            const storagename = `${basename}.${ending}`;
            const displayname = `${name}.${ending}`;
            const fullPath = `${path}/${storagename}`;

            await uploadToStorage(fullPath, file);

            return new MFile({
                name: displayname,
                path: fullPath,
                author: userData.documentId,
            });
        },
        [uploadToStorage, userData],
    );
};
