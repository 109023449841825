import { useState, useEffect } from 'react';
import { EInviteType } from '../../enums';
import { MInvite } from '../../models';
import { useSearchParams } from '../routing';
/**
 * checks url for invite params and if so sets invite
 * @returns invite if invite params are present
 */
export const useInvite = () => {
    const [invite, setInvite] = useState<MInvite>();
    const [params] = useSearchParams();
    /**
     * effect to load invite from url
     */
    useEffect(() => {
        const documentId = params.get('invite');
        const type = params.get('type');
        const targetId = params.get('target');
        if (documentId && type && targetId) {
            if (Object.values(EInviteType).includes(type as EInviteType)) {
                setInvite(
                    new MInvite({
                        documentId,
                        type: type as EInviteType,
                        targetId,
                    }),
                );
            }
        }
    }, [params]);

    return invite;
};
