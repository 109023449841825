import React, { FC, useCallback, useContext, useMemo } from 'react';
import { View } from 'react-native';
import { CButton, CText } from '../../../components';
import { MAvailability } from '../../../models';
import { useFormat } from '../../../utilities/intl';
import { useStyle } from '../../../utilities/styles';
import { useLocation, useSecureNavigate } from '../../../utilities/routing';
import {
    convertWeekAndYearToDate,
    day,
    week,
} from '../../../utilities/functions';
import { isSuperUser } from '../../../utilities/auth';
import { useFireBase } from '../../../utilities/firebase';
import { ProfileContext } from '../Profile.context';
import { dayMessages } from '../../../utilities/messages/day.messages';

export const AvailabilityRow: FC<{ availability: MAvailability }> = ({
    availability,
}) => {
    const style = useStyle();
    const format = useFormat();
    const { secureNavigate } = useSecureNavigate();
    const location = useLocation();
    const { curData } = useContext(ProfileContext);
    const { userData } = useFireBase();
    /**
     * days translated
     */
    const days = useMemo(() => {
        return Object.keys(dayMessages)
            .filter((v) => !v.includes('Short'))
            .map((key) => format(dayMessages[key as keyof typeof dayMessages]));
    }, []);
    /**
     * memoized from to
     */
    const { from, to } = useMemo(() => {
        if (availability.exactStartTime && availability.exactEndTime) {
            const from = new Date(availability.exactStartTime);
            const to = new Date(availability.exactEndTime);

            return { from, to };
        } else {
            const from = convertWeekAndYearToDate(
                availability.year,
                availability.start,
            );
            const to = new Date(
                from.getTime() + (availability.repeatCount + 1) * week - day,
            );

            return { from, to };
        }
    }, [availability]);
    /**
     * style for views
     */
    const viewStyle = useMemo(() => {
        return { flex: 1, justifyContent: 'center' as const };
    }, []);
    /**
     * callback to open availability
     */
    const openAvailability = useCallback(() => {
        const ps = location.pathname.split('/');
        const params = ['aId=' + availability.documentId];
        if (ps.includes('profUser')) {
            params.push('profileId=' + ps.pop());
        }
        if (isSuperUser(userData)) {
            params.push('uid=' + curData.documentId);
        }
        secureNavigate('/calendar?' + params.join('&'));
    }, [location, userData, curData, availability]);
    /**
     * render
     */
    return (
        <View style={[style.horizontalSplit]}>
            <View style={viewStyle}>
                <CText>{from.toLocaleDateString('de')}</CText>
            </View>
            <View style={viewStyle}>
                <CText>{to.toLocaleDateString('de')} </CText>
            </View>
            <View style={viewStyle}>
                <CText>{availability.note}</CText>
            </View>
            <View style={viewStyle}>
                {availability.days.map((d, i) => (
                    <View key={i} style={style.horizontal}>
                        <CText style={style.flex1}>{`${
                            days[d.day - 1]
                        }`}</CText>
                        <CText style={style.flex1}>{`${
                            d.inheriting
                                ? `${availability.from} - ${availability.to}`
                                : `${d.from} - ${d.to}`
                        }`}</CText>
                    </View>
                ))}
            </View>
            <CButton transparent small icon="cog" onPress={openAvailability} />
        </View>
    );
};
