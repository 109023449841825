import React, { FC, useContext, useEffect } from 'react';
import RDatePicker, { registerLocale } from 'react-datepicker';
import { View } from 'react-native';
import 'react-datepicker/dist/react-datepicker.css';
import de from 'date-fns/locale/de';
import '../CDatePicker/CDatePicker.css';
import { ICDatePickerProps } from '../CDatePicker/ICDatePickerProps';
import { useStyle } from '../../../utilities/styles';
import { CText } from '../../elements';
import { LanguageContext, useFormat } from '../../../utilities/intl';
import { timePickerMessages } from './timePicker.messages';

import { Portal } from 'react-overlays';
import { IDefaultProps } from '../../../utilities/IDefaultProps';

const CalendarContainer: FC<IDefaultProps> = ({ children }) => {
    // @ts-ignore
    const el = document.getElementById('calendar-portal');

    return <Portal container={el}>{children as any}</Portal>;
};

export const CTimePicker: FC<ICDatePickerProps> = ({
    value,
    onChange,
    title,
    customCheckInvalidity,
    zIndex,
    maxDate,
    minDate,
    cy,
    timeIntervals,
    disabled,
}) => {
    const style = useStyle();
    const format = useFormat();
    const { language } = useContext(LanguageContext);
    /**
     * effect to register non english locales
     */
    useEffect(() => registerLocale('de', de), []);
    /**
     * render
     */
    return (
        <View style={[style.verticalPadded, { zIndex: zIndex || 21 }]}>
            <CText message={title} />
            <RDatePicker
                {...{ dataSet: cy }}
                disabled={disabled}
                popperClassName="cp"
                selected={value}
                onChange={(date: Date) => onChange(date)}
                dropdownMode={'select'}
                popperContainer={CalendarContainer}
                locale={language}
                showTimeSelect
                showTimeSelectOnly
                timeCaption={format(timePickerMessages.time)}
                dateFormat={'HH:mm'}
                wrapperClassName="time"
                className="timeinput"
                timeIntervals={timeIntervals}
                {...(maxDate ? { maxTime: new Date(maxDate) } : {})}
                {...(minDate ? { minTime: new Date(minDate) } : {})}
            />
            {!!customCheckInvalidity && (
                <CText style={style.error}>
                    {customCheckInvalidity(value)}
                </CText>
            )}
        </View>
    );
};
