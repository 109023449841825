import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import { CText, Spinner } from '../../../../components';
import { MContract, MTimesheet } from '../../../../models';
import { useFireBase } from '../../../../utilities/firebase';
import { ECollections } from '../../../../enums';
import { TimesheetRow } from './TimesheetRow';
import { timesheetMessages } from '../../../Timesheet/timesheet.messages';
import { useStyle } from '../../../../utilities/styles';
import { ScrollProvider } from '../../../../utilities/contexts';

export const TimesheetList: FC<{ contract: MContract; maxHeight?: number }> = ({
    contract,
    maxHeight,
}) => {
    const style = useStyle();
    const { getDataIndex } = useFireBase();
    const [sheets, setSheets] = useState<MTimesheet[]>([]);
    const [didLoad, setDidLoad] = useState(false);
    /**
     * effect to load all data related to contract after contract did load
     */
    useEffect(() => {
        if (!contract) return;
        const timesheetCollection = `${ECollections.contracts}/${contract.documentId}/${ECollections.timesheets}`;
        getDataIndex(timesheetCollection).then((ts) => {
            const results = (ts as any[]).map((t) => new MTimesheet(t));

            setSheets(results.sort((a, b) => b.createdOn - a.createdOn));
            setDidLoad(true);
        });
    }, [contract]);
    /**
     * return spinner pre first load
     */
    if (!didLoad) {
        return <Spinner />;
    }
    /**
     * render
     */
    return (
        <ScrollProvider style={{ maxHeight: maxHeight || undefined }}>
            {sheets.map((sheet) => (
                <TimesheetRow key={sheet.documentId} sheet={sheet} />
            ))}
            {!sheets.length && (
                <View style={style.horizontalSpaced}>
                    <CText message={timesheetMessages.noTimesheets} />
                </View>
            )}
        </ScrollProvider>
    );
};
