import { ERegion, EUserType } from '../enums';
/**
 * Model for mailing list entries
 */
export class MMailingListEntry {
    public documentId = '';
    public createdOn = Date.now();
    public editedOn = Date.now();
    public mail = '';
    /**
     * name to personalize text
     */
    public name?: string;
    /**
     * source. where did we get this entry from
     */
    public source?: string;

    public zipCode = '';

    public region = ERegion.de;
    /**
     * set to true from cloud function if unsubscribe is hit.
     */
    public unsubscribed = false;
    /**
     * agency id if set there is a linked agency and this entry should not be used anymore
     */
    public agencyId?: string;
    /**
     * workplace id if set there is a linked workplace and this entry should not be used anymore
     */
    public workplaceId?: string;
    /**
     * user id if set there is a linked user and this entry should not be used anymore
     */
    public userId?: string;
    /**
     * agency / employer (workplace)
     */
    public type = EUserType.agency;
    /**
     * constructor
     * @param params
     */
    constructor(params: Partial<MMailingListEntry> = {}) {
        Object.assign(this, params);
    }
}
