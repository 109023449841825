import { createContext } from 'react';
import { ApplicationVerifier, MultiFactorError, User } from 'firebase/auth';
import { EFBSignInTypes } from './EFBSignInTypes';

export const FireAuthContext = createContext(
    {} as {
        logIn: (params: {
            type?: EFBSignInTypes;
            mail?: string;
            password?: string;
        }) => Promise<boolean>;
        multiFactorLogin: (
            error: MultiFactorError,
            recaptchaVerifier?: ApplicationVerifier,
        ) => Promise<boolean>;
        register: (params: { mail?: string; password?: string }) => void;
        sendPwResetMail: (user: User) => void;
        logOut: () => void;
        onAuthStateChanged: (cb: (user?: User) => void) => void;
        currentAuthProviderId: string;
        reauth: (user: User, username?: string, password?: string) => void;
        refreshToken: () => Promise<void>;
    },
);
