import React, { FC } from 'react';
import { View } from 'react-native';
import { CButton, CTextInput } from '../../../../components';
import { CDatePicker } from '../../../../components';
import { useFormat } from '../../../../utilities/intl';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { profileMessages } from '../../../Profile/Profile.messages';
import { IEducationProps } from './EducationView';

interface IEducationEditProps extends IEducationProps {
    endEdit: () => void;
}

export const EducationEdit: FC<IEducationEditProps> = ({
    onChange,
    education,
    endEdit,
    zIndex,
}) => {
    const format = useFormat();
    const style = useStyle();
    const { theme } = useTheme();
    return (
        <View
            style={{
                zIndex,
                borderWidth: 1,
                borderColor: theme.borderColor,
                borderRadius: 5,
                padding: 10,
                marginVertical: 10,
            }}
        >
            <CTextInput
                value={education.institution}
                onChangeText={(institution) =>
                    onChange({
                        institution,
                    })
                }
                placeholder={format(profileMessages.institutionPlaceholder)}
                label={format(profileMessages.institution)}
                autoExtend
            />
            <CTextInput
                value={education.degree}
                onChangeText={(degree) =>
                    onChange({
                        degree,
                    })
                }
                placeholder={format(profileMessages.degreePlaceholder)}
                label={format(profileMessages.degree)}
                autoExtend
            />
            <CTextInput
                value={education.field}
                onChangeText={(field) =>
                    onChange({
                        field,
                    })
                }
                placeholder={format(profileMessages.fieldPlaceholder)}
                label={format(profileMessages.field)}
                autoExtend
            />
            <View style={[style.horizontal, { zIndex: 23 }]}>
                <CDatePicker
                    title={format(profileMessages.from)}
                    value={
                        education.from ? new Date(education.from) : undefined
                    }
                    onChange={(next) => onChange({ from: next.getTime() })}
                    zIndex={22}
                    showMonthYearPicker
                />
                <CDatePicker
                    title={format(profileMessages.to)}
                    value={education.to ? new Date(education.to) : undefined}
                    onChange={(next) => onChange({ to: next.getTime() })}
                    minDate={education.from}
                    showMonthYearPicker
                />
            </View>
            <View style={style.horizontalSpaced}>
                <CButton
                    title={format(profileMessages.finishEditing)}
                    small
                    icon={'check'}
                    onPress={endEdit}
                />
            </View>
        </View>
    );
};
