import { defineMessages } from 'react-intl';

const scope = 'address.';

export const addressMessages = defineMessages({
    street: {
        id: scope + 'street',
        defaultMessage: 'Street',
    },
    streetPlaceholder: {
        id: scope + 'streetPlaceholder',
        defaultMessage: 'Street e.g.: Hillroad',
    },
    number: {
        id: scope + 'number',
        defaultMessage: 'Number',
    },
    numberPlaceholder: {
        id: scope + 'numberPlaceholder',
        defaultMessage: 'Number e.g.: 14a',
    },
    zipCode: {
        id: scope + 'zipCode',
        defaultMessage: 'ZIP code',
    },
    zipCodePlaceholder: {
        id: scope + 'zipCodePlaceholder',
        defaultMessage: 'ZIP code e.g.: 12345',
    },
    country: {
        id: scope + 'country',
        defaultMessage: 'Country',
    },
    countryPlaceholder: {
        id: scope + 'countryPlaceholder',
        defaultMessage: 'Country e.g.: Germany',
    },
    city: {
        id: scope + 'city',
        defaultMessage: 'City',
    },
    cityPlaceholder: {
        id: scope + 'cityPlaceholder',
        defaultMessage: 'City e.g.: Munich',
    },
    checkDiffers: {
        id: scope + 'checkDiffers',
        defaultMessage: 'Address check did result in different address',
    },
    unableToCheck: {
        id: scope + 'unableToCheck',
        defaultMessage: 'Unable to check the address',
    },
    maybeSomeThingWrongWithAddress: {
        id: scope + 'maybeSomeThingWrongWithAddress',
        defaultMessage: 'There could be an issue with your address.',
    },
});
