import { defineMessages } from 'react-intl';

const scope = 'tit.';
/**
 * title names
 * is used for title picker
 */
export const doctorTitleMessages = defineMessages({
    noTitle: { id: scope + 'noTitle', defaultMessage: 'No title' },
    doctor: { id: scope + 'doctor', defaultMessage: 'Dr.' },
    doctordoctor: { id: scope + 'doctordoctor', defaultMessage: 'Dr. Dr.' },
    doctormedic: { id: scope + 'doctormedic', defaultMessage: 'Dr. medic' },
    professor: { id: scope + 'professor', defaultMessage: 'Prof.' },
    professordoctor: {
        id: scope + 'professordoctor',
        defaultMessage: 'Prof. Dr.',
    },
    professordoctordoctor: {
        id: scope + 'professordoctordoctor',
        defaultMessage: 'Prof. Dr. Dr.',
    },
});
