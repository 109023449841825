import React, { FC, useEffect, useMemo, useState } from 'react';
import { View, Pressable } from 'react-native';
import { ECollections } from '../../enums';
import { MWorkplace } from '../../models';
import { isSuperUser } from '../../utilities/auth';
import { useFireBase } from '../../utilities/firebase';
import { IFilter } from '../../utilities/firebase/store';
import { useStyle, useTheme } from '../../utilities/styles';
import { useDebounce } from '../../utilities/hooks/useDebounce';
import { CText, CTextInput } from '../elements';
import { typeaheadMessages } from './typeahead.messages';
/**
 * props for workplace typeahead
 */
interface IWorkplaceZipCodeTypeaheadProps {
    onChangeWorkplace: (next: MWorkplace) => void;
    currentWorkplace?: MWorkplace;
}
/**
 * typeahead to get workplaces by zip code input
 * @param param0
 * @returns
 */
export const WorkplaceZipCodeTypeahead: FC<IWorkplaceZipCodeTypeaheadProps> = ({
    currentWorkplace,
    onChangeWorkplace,
}) => {
    // global state
    const { theme } = useTheme();
    const style = useStyle();
    const { getDataIndex, userData } = useFireBase();
    /**
     * local state
     */
    const [workplace, setWorkplace] = useState<MWorkplace>();
    const [workplaces, setWorkplaces] = useState<MWorkplace[]>([]);
    const [height, setHeight] = useState(0);
    const [zipCode, setZipCode] = useState('');
    const [focused, setFocused] = useState(false);
    const debouncedZipCode = useDebounce(zipCode, 500);
    const [hasMoreThanOne, setHasMoreThanOne] = useState(false);
    /**
     * memoized text to display
     */
    const displayText = useMemo(() => {
        if (!focused && workplace) {
            return `${zipCode} ${workplace.name}`;
        } else {
            return zipCode;
        }
    }, [zipCode, focused, workplace]);
    /**
     * effect to set local current workplace based on prop workplace (also sets zip code)
     */
    useEffect(() => {
        setWorkplace((prev) => {
            if (
                currentWorkplace &&
                (!prev || prev.documentId !== currentWorkplace.documentId)
            ) {
                setZipCode(currentWorkplace.address.zipCode);
                return currentWorkplace;
            } else if (prev) {
                setZipCode(prev.address.zipCode);
                return prev;
            } else {
                return prev;
            }
        });
    }, [currentWorkplace]);
    /**
     * effect to trigger change
     */
    useEffect(() => {
        if (
            workplace &&
            (!currentWorkplace ||
                workplace.documentId !== currentWorkplace?.documentId)
        ) {
            onChangeWorkplace(workplace);
        }
    }, [workplace, currentWorkplace]);
    /**
     * effect to load workplaces to display
     */
    useEffect(() => {
        const params: any = {};
        const filter: IFilter[] = [];
        if (!isSuperUser(userData) && userData.documentId) {
            filter.push({
                field: 'users',
                operator: 'array-contains',
                value: userData.documentId,
            });
        }
        if (debouncedZipCode) {
            params.partialMatch = {
                field: 'address.zipCode',
                value: debouncedZipCode,
            };
        }

        if (filter.length || params.partialMatch) {
            getDataIndex(ECollections.workplaces, {
                ...params,
                filter,
                orderBy: 'name',
                limit: 10,
            }).then((result) => {
                const next = (result as MWorkplace[]).map(
                    (r) => new MWorkplace(r),
                );
                setWorkplaces(next);
                if (next.length > 1) {
                    setHasMoreThanOne(true);
                }
                if (next.length === 1) {
                    setWorkplace(next[0]);
                }
            });
        } else {
            setWorkplaces([]);
            setWorkplace(undefined);
        }
    }, [debouncedZipCode]);
    /**
     * render
     */
    return (
        <View
            style={{ zIndex: 100 }}
            onLayout={(e) => {
                if (!e || !e.nativeEvent) return;
                const nextHeigt = e.nativeEvent.layout.height;
                if (height !== nextHeigt) {
                    setHeight(nextHeigt);
                }
            }}
        >
            {workplaces.length === 1 &&
                !zipCode &&
                !debouncedZipCode &&
                !hasMoreThanOne &&
                !isSuperUser(userData) && (
                    <View style={style.verticalPadded}>
                        <CText message={typeaheadMessages.workplace} />
                        <CText secondaryHeadline>{displayText}</CText>
                    </View>
                )}
            {(workplaces.length > 1 ||
                zipCode ||
                debouncedZipCode ||
                hasMoreThanOne ||
                isSuperUser(userData)) && (
                <CTextInput
                    label={typeaheadMessages.workplace}
                    value={displayText}
                    placeholder={typeaheadMessages.workplaceZipCodePlaceholder}
                    onChangeText={(s) => {
                        if (!isNaN(+s)) {
                            setZipCode(s);
                        }
                    }}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setTimeout(() => setFocused(false), 500)}
                    autoExtend
                />
            )}
            {focused && (
                <View
                    style={{
                        position: 'absolute',
                        zIndex: 100,
                        top: height,

                        backgroundColor: theme.backgroundMainColor,
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: theme.accentColor,
                        overflow: 'hidden',
                        shadowColor: theme.shadowColor,
                        shadowOffset: {
                            width: 0,
                            height: 2,
                        },
                        shadowOpacity: 0.2,
                        shadowRadius: 3.8,
                        elevation: 5,
                    }}
                >
                    {workplaces.map((wp) => (
                        <Pressable
                            key={wp.documentId}
                            onPress={() => {
                                setWorkplace(wp);
                                setZipCode(wp.address.zipCode);
                                setFocused(false);
                            }}
                            style={{
                                borderBottomWidth: 1,
                                borderColor: theme.accentColor,
                                padding: 10,
                            }}
                        >
                            <CText>{`${wp.address.zipCode} ${wp.name}`}</CText>
                        </Pressable>
                    ))}
                    {!workplaces.length && (
                        <View
                            style={{
                                borderBottomWidth: 1,
                                borderColor: theme.accentColor,
                                padding: 10,
                            }}
                        >
                            <CText
                                message={
                                    typeaheadMessages.noWorkplacesWithCurrentZipCode
                                }
                            />
                        </View>
                    )}
                </View>
            )}
        </View>
    );
};
