import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Linking, Pressable, View } from 'react-native';
import {
    CText,
    CIcon,
    CButton,
    CCheckBox,
    Radio,
} from '../../../../../../../components';
import {
    EUserType,
    EContractStatus,
    ECollections,
    EApplicationType,
    ERegion,
} from '../../../../../../../enums';
import { MUserData, MNegotiationHistory } from '../../../../../../../models';
import { isPeasant } from '../../../../../../../utilities/auth';
import { useDialog } from '../../../../../../../utilities/dialog';
import { useFireBase } from '../../../../../../../utilities/firebase';
import { sortObjectKeys } from '../../../../../../../utilities/functions';
import { useFormat } from '../../../../../../../utilities/intl';
import { actionMessages } from '../../../../../../../utilities/messages';
import { useStyle, useTheme } from '../../../../../../../utilities/styles';
import { contractMessages } from '../../../../../contract.messages';
import { ContractContext } from '../../../ContractContext';
import { ContractValueCard } from '../../ContractValueCard';
import { NegotiationHistory } from '../../NegotiationHistory';
import { WorkHoursDisplay } from '../../../../../components/WorkHoursDisplay';

/**
 * review and accept or decline the contract as user / agemcy
 * @param param0 props
 * @returns
 */
export const ConfirmNegotiation: FC = () => {
    // global context
    const style = useStyle();
    const { theme } = useTheme();
    const format = useFormat();
    const dialog = useDialog();
    const { callFunction, userData, getDataById, getDataIndex } = useFireBase();
    // context
    const { contract, originalContract, handleClose, onChange } =
        useContext(ContractContext);
    // local state
    const [extConsentType, setExtConsent] = useState(0);
    const [confirmContract, setConfirmContract] = useState(false);
    const [edit, setEdit] = useState(false);
    const [changeAuthor, setChangeAuthor] = useState<MUserData>();
    const [openHistory, setOpenHistory] = useState(false);
    const [prevStep, setPrevStep] = useState<MNegotiationHistory>();
    const [readPrevStep, setReadPrevStep] = useState<MNegotiationHistory>();
    const [acceptLeasingConditions, setAcceptLeasingConditions] =
        useState(false);
    /**
     * memoized if changed happened
     */
    const changedContract = useMemo(
        () => JSON.stringify(originalContract) !== JSON.stringify(contract),
        [originalContract, contract],
    );
    /**
     * not original memo
     */
    const isNotOriginal = useMemo(() => {
        const og = JSON.stringify(
            sortObjectKeys({
                ...originalContract.originalParameters,
                wage: sortObjectKeys(originalContract.originalParameters.wage),
            }),
        );
        const cur = JSON.stringify(
            sortObjectKeys({
                from: originalContract.from,
                to: originalContract.to,
                wage: sortObjectKeys(originalContract.wage),
            }),
        );
        return og !== cur;
    }, [originalContract]);
    /**
     * callback to issue accept
     */
    const acceptContract = useCallback(async () => {
        await callFunction('acceptContract', {
            contractId: contract.documentId,
        });
        if (userData.type === EUserType.employer) {
            await dialog({
                icon: 'success',
                title: contractMessages.acceptedOfferWorkplace,
                message: contractMessages.acceptedOfferWorkplaceText,
            });
        }
        if (contract.status === EContractStatus.review_yer) {
            return;
        }
        if (userData.type === EUserType.agency) {
            await dialog({
                icon: 'success',
                title: contractMessages.acceptedOfferAgency,
                message: contractMessages.acceptedOfferAgencyText,
            });
        } else {
            await dialog({
                icon: 'success',
                title: contractMessages.acceptedOffer,
                message: contractMessages.acceptedOfferText,
            });
        }
    }, [contract, userData]);
    /**
     * callback to issue a change
     */
    const changeContract = useCallback(async () => {
        await callFunction('changeContract', {
            contractId: contract.documentId,
            ...contract.wage,
            from: contract.from,
            to: contract.to,
        });
        await dialog({
            icon: 'info',
            title: contractMessages.changedOffer,
            message: contractMessages.changedOfferText,
        });
    }, [contract]);
    /**
     * effect to load previous step
     */
    useEffect(() => {
        getDataIndex(
            `${ECollections.contracts}/${originalContract.documentId}/${ECollections.negotiationHistory}`,
            { orderBy: 'createdOn', limit: 2 },
        ).then((res) => {
            const next = (res as any[]).map((v) => new MNegotiationHistory(v));
            if (next.length === 2) {
                setPrevStep(next[1]);
            } else if (next.length === 1) {
                setPrevStep(
                    new MNegotiationHistory(
                        originalContract.originalParameters,
                    ),
                );
            }
        });
    }, [originalContract]);
    /**
     * effect to load author name
     */
    useEffect(() => {
        if (
            contract.changedBy &&
            contract.changedBy !== changeAuthor?.documentId
        ) {
            getDataById(ECollections.publicUsers, contract.changedBy).then(
                (v) => {
                    setChangeAuthor(new MUserData(v));
                },
            );
        }
    }, [contract]);
    /**
     * effect to load author name
     */
    useEffect(() => {
        if (
            prevStep &&
            (originalContract.from !== prevStep.from ||
                originalContract.to !== prevStep.to) &&
            (!readPrevStep || prevStep.documentId !== readPrevStep?.documentId)
        ) {
            setReadPrevStep(prevStep);
            if (isPeasant(userData)) {
                dialog({
                    title: contractMessages.timeChanged,
                    message: contractMessages.timeChangedText,
                    buttons: [{ text: actionMessages.ok }],
                    icon: 'warning',
                });
            }
        }
    }, [originalContract, prevStep, readPrevStep]);
    /**
     * render
     */
    return (
        <>
            <View style={style.card}>
                <View style={style.verticalPadded}>
                    <WorkHoursDisplay days={contract.days} />
                </View>
                <CCheckBox
                    cy={'confirmTerms'}
                    checked={confirmContract}
                    onCheckedChanged={setConfirmContract}
                    title={format(contractMessages.confirmTermsCheckBoxText)}
                />
                {contract.type === EApplicationType.agency &&
                    userData.type === EUserType.agency && (
                        <>
                            <View
                                style={[style.horizontal, style.verticalPadded]}
                            >
                                <CButton
                                    title={contractMessages.openTarif}
                                    onPress={() =>
                                        Linking.openURL(
                                            'https://timeployees.de/gebuehrenordnung',
                                        )
                                    }
                                    minor
                                />
                            </View>
                            <CCheckBox
                                cy="agencyConditions"
                                checked={acceptLeasingConditions}
                                onCheckedChanged={setAcceptLeasingConditions}
                                title={contractMessages.confirmAgencyConditions}
                            />
                        </>
                    )}
            </View>
            {!changedContract &&
                contract.region === ERegion.de &&
                contract.type === EApplicationType.user &&
                userData.type === EUserType.user && (
                    <View style={style.card}>
                        <View style={style.verticalPadded}>
                            <CText bold>
                                {format(contractMessages.extConsentTitle)}
                            </CText>
                            <Radio
                                values={[
                                    {
                                        label: format(
                                            contractMessages.extConsent0,
                                        ),
                                        value: 0,
                                    },
                                    {
                                        label: format(
                                            contractMessages.extConsent1,
                                        ),
                                        value: 1,
                                    },
                                    {
                                        label: format(
                                            contractMessages.extConsent2,
                                        ),
                                        value: 2,
                                    },
                                ]}
                                value={extConsentType}
                                onChange={setExtConsent}
                            />
                        </View>
                    </View>
                )}
            <View style={[style.card, style.horizontalSpaced]}>
                <CButton
                    cy={'cancel'}
                    title={actionMessages.close}
                    onPress={handleClose}
                    danger
                />
                <CButton
                    disabled={
                        changedContract ||
                        !confirmContract ||
                        (userData.type === EUserType.agency &&
                            !acceptLeasingConditions)
                    }
                    cy={'continue'}
                    title={format(actionMessages.continue)}
                    onPress={acceptContract}
                    disableOnClick
                />
            </View>
        </>
    );
};
