import React, { FC, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { CImage, CText, TouchableView } from '../../components';
import { TabButtons } from '../../components/TabButtons/TabButtons';
import { ELanguage } from '../../enums';
import { LanguageContext, useFormat } from '../../utilities/intl';
import { actionMessages, generalMessages } from '../../utilities/messages';
import { useSearchParams } from '../../utilities/routing';
import { useStyle } from '../../utilities/styles';
import { StandaloneWrapper } from '../../utilities/wrappers';
import { Login, Register } from './components';
import { Forgot } from './components/Forgot';

export const Start: FC = () => {
    const style = useStyle();
    const [state, setState] = useState('register');
    const { language, onChangeLanguage } = useContext(LanguageContext);
    const [searchParams] = useSearchParams();
    const format = useFormat();

    useEffect(() => {
        if (searchParams.get('forgot')) {
            setState('forgot');
        } else if (searchParams.get('login')) {
            setState('login');
        }
    }, [searchParams]);

    return (
        <StandaloneWrapper nativeId={'start'}>
            {state === 'forgot' ? (
                <Forgot goBack={() => setState('login')} />
            ) : (
                <>
                    <TabButtons
                        values={[
                            {
                                value: 'register',
                                label: format(actionMessages.register),
                            },
                            {
                                value: 'login',
                                label: format(actionMessages.login),
                            },
                        ]}
                        value={state}
                        onChange={setState}
                        layoutStyle={{
                            marginTop: -10,
                            marginHorizontal: -10,
                        }}
                    />
                    {state === 'login' && (
                        <Login forgot={() => setState('forgot')} />
                    )}
                    {state === 'register' && <Register />}
                </>
            )}
            <View style={style.centeredItems}>
                <CText message={generalMessages.language} />
            </View>
            <View
                style={[
                    style.horizontal,
                    style.verticalPadded,
                    style.centeredContent,
                ]}
            >
                <TouchableView
                    style={style.horizontalPadded}
                    onPress={() => onChangeLanguage(ELanguage.de)}
                >
                    <CImage image="de" fixedHeight={20} />
                </TouchableView>
                <TouchableView
                    style={style.horizontalPadded}
                    onPress={() => onChangeLanguage(ELanguage.en)}
                >
                    <CImage image="en" fixedHeight={20} />
                </TouchableView>
            </View>
        </StandaloneWrapper>
    );
};
