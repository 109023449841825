import React, { FC, useCallback, useState } from 'react';
import { CButton } from '../../../../../components';
import { ECollections } from '../../../../../enums';
import { MHelp } from '../../../../../models';
import { useFireBase } from '../../../../../utilities/firebase';

export const TouchHelps: FC = () => {
    const { getDataIndex, put } = useFireBase();

    const [touchingHelps, setTouchingHelps] = useState(false);
    /**
     * callback to touch all jobs
     */
    const touchHelps = useCallback(() => {
        setTouchingHelps(true);
        getDataIndex(ECollections.helps).then((helps) => {
            Promise.all(
                (helps as MHelp[]).map((help) => {
                    const next = new MHelp(help);
                    // * fix for old helps
                    // if (!next.groups.length) {
                    //     if (next.environment === EEnvironment.SITUS) {
                    //         next.groups = [EUserType.user];
                    //     } else {
                    //         next.groups = [EUserType.agency];
                    //     }
                    // }
                    return put(ECollections.helps, help.documentId, {
                        ...next,
                        touched: Date.now(),
                    });
                }),
            ).then(() => setTouchingHelps(false));
        });
    }, [getDataIndex, put]);

    return (
        <CButton
            onPress={touchHelps}
            title="Touch all Helps"
            warning
            disabled={touchingHelps}
        />
    );
};
