import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { JoyrideContext } from './JoyrideContext';
import { IDefaultProps } from '../../IDefaultProps';
import Joyride, { ACTIONS } from 'react-joyride';
import { useStyle, useTheme } from '../../styles';
import { useLocation } from '../../routing';
import { useAutoFormat } from '../../intl';
import { selectRide } from './functions/selectRide';
import { useEnvironment } from '../Environment';
import { View } from 'react-native';
import { CButton, CText } from '../../../components';
import { actionMessages } from '../../messages';
import { makeCButtonStyles } from '../../../components/elements/CButton/styles';
import { useFireBase } from '../../firebase';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CModalContext } from '../CModal';
import { isSuperUser } from '../../auth';
import { useDimensions } from '../../hooks';

export const JoyrideProvider: FC<IDefaultProps> = ({ children }) => {
    const { userData, requiresOnboarding } = useFireBase();
    const { theme } = useTheme();
    const style = useStyle();
    const location = useLocation();
    const format = useAutoFormat();
    const { environment } = useEnvironment();
    const { setHideModals } = useContext(CModalContext);
    const buttonStyle = useMemo(() => {
        return makeCButtonStyles(theme);
    }, [theme]);
    const { wrapperHeight } = useDimensions();
    // local state
    const [run, setRun] = useState(false);
    const [stepIndex, setStepIndex] = useState(0);
    /**
     * memoized steps based on url
     */
    const steps = useMemo(() => {
        // console.log(location.pathname);
        return selectRide(location.pathname, { userType: userData.type }).map(
            (rs) => {
                return {
                    ...rs,
                    content: format(rs.content, { platform: environment }),
                };
            },
        );
    }, [location, environment, format, userData]);
    /**
     * callback to start current ride
     */
    const startRide = useCallback(() => {
        setRun(true);
        setHideModals(true);
        setStepIndex(0);
    }, [setHideModals]);
    /**
     * effect to check for current path if joyride already played for user
     */
    useEffect(() => {
        if (
            steps &&
            steps.length &&
            userData.documentId &&
            !requiresOnboarding
        ) {
            let path = location.pathname;
            while (path.includes('/')) {
                path = path.replace('/', '-');
            }
            if (path === '-') {
                path = 'dashboard';
            } else {
                path = path.substring(1, path.length);
            }
            if (path.split('-').length > 1) {
                path = path.split('-').slice(0, 1).join('-');
            }
            AsyncStorage.getItem('joyride-' + path, (e, result) => {
                if (e) {
                    throw e;
                } else {
                    if (result !== userData.documentId) {
                        AsyncStorage.setItem(
                            'joyride-' + path,
                            `${userData.documentId}`,
                        );
                        if (!isSuperUser(userData)) startRide();
                    }
                }
            });
        }
    }, [location, steps, userData, startRide]);
    /**
     * effect to always run
     * ! dev
     */
    // useEffect(() => {
    //     setRun(true);
    // }, []);
    /**
     * render
     */
    return (
        <JoyrideContext.Provider value={{ startRide }}>
            <Joyride
                steps={steps}
                run={run}
                continuous
                scrollToFirstStep
                showProgress
                stepIndex={stepIndex}
                styles={{
                    options: {
                        zIndex: 10000,
                        backgroundColor: theme.backgroundMainColor,
                        arrowColor: theme.accentColor,
                        textColor: theme.textMainColor,
                    },
                    buttonNext: {
                        color: theme.textAccentColor,
                        backgroundColor: theme.accentColor,
                        fontFamily: theme.fontMain,
                    },
                    buttonBack: {
                        color: theme.accentColor,
                        fontFamily: theme.fontMain,
                    },
                    tooltip: {
                        fontFamily: theme.fontMain,
                    },
                    overlay: {
                        height: wrapperHeight,
                    },
                }}
                callback={(data) => {
                    if (data.action === 'close' && data.type === 'step:after') {
                        setRun(false);
                        setHideModals(false);
                    } else if (data.type === 'step:after') {
                        setStepIndex(
                            stepIndex + (data.action === ACTIONS.PREV ? -1 : 1),
                        );
                    }
                }}
                tooltipComponent={({
                    continuous,
                    index,
                    step,
                    backProps,
                    closeProps,
                    primaryProps,
                    tooltipProps,
                }) => (
                    <View {...(tooltipProps as any)} style={style.card}>
                        {!!step.title && <CText>{step.title}</CText>}
                        <View style={[style.horizontalSplit]}>
                            <View />
                            <CButton
                                cy={'skip-joyride'}
                                small
                                transparent
                                icon={'close'}
                                onPress={() => {
                                    setRun(false);
                                    setHideModals(false);
                                }}
                            />
                        </View>
                        <View
                            style={[
                                style.verticalPadded,
                                style.horizontalPadded,
                            ]}
                        >
                            <CText wrap secondaryHeadline>
                                {step.content}
                            </CText>
                        </View>
                        <View style={style.horizontalSplit}>
                            <View />
                            <View style={style.horizontal}>
                                {index > 0 && (
                                    <button
                                        {...backProps}
                                        style={buttonStyle.transparentButton}
                                    >
                                        {format(actionMessages.back)}
                                    </button>
                                )}
                                {!!continuous && index < steps.length - 1 && (
                                    <button
                                        {...primaryProps}
                                        style={buttonStyle.button}
                                    >
                                        {format(actionMessages.continue)}
                                    </button>
                                )}
                                {(!continuous ||
                                    index === steps.length - 1) && (
                                    <button
                                        {...closeProps}
                                        style={buttonStyle.button}
                                    >
                                        {format(actionMessages.close)}
                                    </button>
                                )}
                            </View>
                        </View>
                    </View>
                )}
            />
            {children}
        </JoyrideContext.Provider>
    );
};
