import React, { FC, Fragment, useCallback, useState } from 'react';
import { IDefaultProps } from '../../IDefaultProps';
import Dropzone from 'react-dropzone';
import { useDimensions } from '../../hooks';
import { DropContext } from './DropContext';
import { View } from 'react-native';
import { useTheme } from '../../styles';
import { CText } from '../../../components';
import { dropMessages } from './drop.messages';

export const DropProvider: FC<IDefaultProps> = ({ children }) => {
    const { theme } = useTheme();
    const { height, width } = useDimensions();
    const [dropActive, setDropActive] = useState(false);
    const [onDrop, setOnDrop] = useState<(acceptedFiles: any[]) => void>();
    const clearOnDrop = useCallback(() => setOnDrop(undefined), []);
    const localOnDrop = useCallback(
        (acceptedFiles: any[]) => {
            if (onDrop) onDrop(acceptedFiles);
        },
        [onDrop],
    );

    return (
        <DropContext.Provider
            value={{
                dropActive,
                setDropActive,
                setOnDrop: (n) => setOnDrop(() => n),
                clearOnDrop,
            }}
        >
            <Dropzone onDrop={localOnDrop} noClick>
                {({ getRootProps, getInputProps, isDragActive }) => (
                    <section>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {children}
                            {isDragActive && dropActive && (
                                <>
                                    <View
                                        style={{
                                            width,
                                            height,
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            backgroundColor: theme.borderColor,
                                            opacity: 0.5,
                                        }}
                                    />
                                    <View
                                        style={{
                                            width,
                                            height,
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            zIndex: 10,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <View
                                            style={{
                                                padding: 20,
                                                borderStyle: 'dashed',
                                                borderWidth: 1,
                                                borderRadius: 5,
                                                borderColor: theme.borderColor,
                                                backgroundColor:
                                                    theme.backgroundMainColor,
                                                opacity: 1.5,
                                            }}
                                        >
                                            <CText
                                                secondaryHeadline
                                                message={dropMessages.drop}
                                            />
                                        </View>
                                    </View>
                                </>
                            )}
                        </div>
                    </section>
                )}
            </Dropzone>
        </DropContext.Provider>
    );
};
