import { createContext } from 'react';
import { MProfessionalInfo, MUserData } from '../../models';

export const ProfileContext = createContext(
    {} as {
        onChange: (next: Partial<MProfessionalInfo>) => void;
        onChangeUserData?: (next: Partial<MUserData>) => void;
        onValidityChange: (issue: string, key?: string) => void;
        curData: MProfessionalInfo;
        curUserData?: MUserData;
        prevData: MProfessionalInfo;
        prevUserData?: MUserData;
        onboarding?: boolean;
        save?: () => void;
    },
);
