import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useParams, useSecureNavigate } from '../../../utilities/routing';
import { MAgency, MShift, MUserData } from '../../../models';
import { isSuperUser } from '../../../utilities/auth';
import { ECollections } from '../../../enums';
import { useFireBase } from '../../../utilities/firebase';
import { IFilter } from '../../../utilities/firebase/store';
import {
    CButton,
    CCard,
    CDataTable,
    CPicker,
    CText,
    Spinner,
} from '../../../components';
import { agencyMessages } from '../agency.messages';
import { useStyle } from '../../../utilities/styles';
import { ScrollProvider } from '../../../utilities/contexts';
import { timeViewMessages } from './timeView.messages';
import { generalMessages } from '../../../utilities/messages';
import { minute } from '../../../utilities/functions';
import { monthMessages } from '../../../utilities/messages/month.messages';
import { calendarMessages } from '../../Calendar/Calendar.messages';

export const TimeView: FC = () => {
    // global state
    const { id } = useParams<{ id: string }>();
    const style = useStyle();
    const { getDataById, getDataIndex, userData } = useFireBase();
    const { secureNavigate } = useSecureNavigate();
    // local state
    const [agency, setAgency] = useState(new MAgency());
    const [users, setUsers] = useState<MUserData[]>([]);
    const [shifts, setShifts] = useState<{ shift: MShift; user: MUserData }[]>(
        [],
    );
    const [loading, setLoading] = useState(true);
    // filter state
    const [uid, setUid] = useState('');
    const [date, setDate] = useState(new Date().getDate());
    const [month, setMonth] = useState(new Date().getMonth());
    const [year, setYear] = useState(new Date().getFullYear());
    /**
     * desired shift sorting
     */
    const sortedShifts = useMemo(() => {
        const shiftsGroupedByUser = shifts.reduce((acc, shift) => {
            const pivot = acc.find(({ uid }) => uid === shift.user.documentId);
            if (pivot) {
                pivot.shifts.push(shift);
            } else {
                acc.push({ uid: shift.user.documentId, shifts: [shift] });
            }
            return acc;
        }, [] as { uid: string; shifts: { shift: MShift; user: MUserData }[] }[]);
        return shiftsGroupedByUser
            .map(({ shifts }) =>
                shifts.sort((a, b) => b.shift.from - a.shift.from),
            )
            .reduce((acc, shifts) => {
                return [...acc, ...shifts];
            }, [] as { shift: MShift; user: MUserData }[]);
    }, [shifts]);
    /**
     * effect to load agency data
     */
    useEffect(() => {
        if (!id) return;
        getDataById(ECollections.agencies, id).then((result) => {
            const nextAgency = new MAgency(result);
            setAgency(nextAgency);
        });
    }, [id]);
    /**
     * effect to load user data
     */
    useEffect(() => {
        if (!agency.documentId) return;
        Promise.all(
            agency.users.map(
                async (uid) =>
                    await getDataById(
                        isSuperUser(userData)
                            ? ECollections.users
                            : ECollections.publicUsers,
                        uid,
                    ),
            ),
        ).then((nextUsers) => setUsers(nextUsers.map((u) => new MUserData(u))));
    }, [agency]);
    /**
     * effect to load shifts
     */
    useEffect(() => {
        if (!agency.documentId || users.length !== agency.users.length) return;

        setLoading(true);
        const filter: IFilter[] = [];
        if (uid) {
            filter.push({ field: 'timesheetId', value: uid });
        }
        // if (date) {
        //     filter.push({ field: 'date', value: `${date}` });
        // }
        if (month || month === 0) {
            filter.push({ field: 'month', value: `${month + 1}` });
        }
        if (year) {
            filter.push({ field: 'year', value: `${year}` });
        }
        Promise.all(
            agency.users.map(
                async (uid) =>
                    await getDataIndex(
                        `${ECollections.agencies}/${agency.documentId}/${ECollections.timesheets}/${uid}/${ECollections.shifts}`,
                        { filter },
                    ),
            ),
        ).then((r) => {
            setShifts(
                (r as any[][]).reduce((acc, v, i) => {
                    const uid = agency.users[i];
                    const user = users.find((u) => u.documentId === uid);
                    for (const shift of v) {
                        acc.push({ shift: new MShift(shift), user });
                    }
                    return acc;
                }, []),
            );
            setLoading(false);
        });
    }, [agency, users, uid, date, month, year]);
    /**
     * return loading indicator until agency and agency users are present
     */
    if (!agency || users.length !== agency.users.length) {
        return <Spinner />;
    }
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <CCard>
                <View style={style.horizontal}>
                    <CButton
                        cy={'back'}
                        onPress={() => {
                            secureNavigate(-1, { force: true });
                        }}
                        icon={'chevronLeft'}
                        small
                    />
                    <CText
                        message={agencyMessages.timeTracking}
                        style={{ marginLeft: 5 }}
                        headline
                    />
                </View>
                <View style={[style.horizontalWrap]}>
                    <CPicker
                        values={[
                            { value: '', label: generalMessages.all },
                            ...users.map((u) => ({
                                value: u.documentId,
                                label: `${u.firstName} ${u.lastName}`,
                            })),
                        ]}
                        onChange={(next) => setUid(next)}
                        value={uid}
                        allowEmpty
                        title={timeViewMessages.user}
                        horizontal
                    />
                    <CPicker
                        title={calendarMessages.month}
                        value={month + 1}
                        values={Object.keys(monthMessages)
                            .filter((m) => !m.includes('Short'))
                            .map((m, i) => ({
                                label: monthMessages[
                                    m as keyof typeof monthMessages
                                ],
                                value: i + 1,
                            }))}
                        onChange={(v) => setMonth(v - 1)}
                        horizontal
                    />
                    <CPicker
                        horizontal
                        title={calendarMessages.year}
                        value={year}
                        values={Array.from(
                            new Set([
                                ...Array(10).keys(),
                                new Date().getFullYear() -
                                    (new Date().getFullYear() - 2),
                            ]),
                        ).map((y, i) => ({
                            label: `${y + new Date().getFullYear() - 2}`,
                            value: y + new Date().getFullYear() - 2,
                        }))}
                        onChange={setYear}
                    />
                </View>
            </CCard>
            <CCard>
                {loading ? (
                    <Spinner />
                ) : (
                    <View>
                        {!!sortedShifts.length ? (
                            <CDataTable
                                rows={sortedShifts.map(({ shift, user }, i) => {
                                    const shiftLength = Math.round(
                                        (shift.to - shift.from) / minute,
                                    );
                                    const timeOnBreak = shift.breaks.reduce(
                                        (acc, b) => {
                                            const minutes = Math.round(
                                                (b.to - b.from) / minute,
                                            );
                                            return acc + minutes;
                                        },
                                        0,
                                    );
                                    return {
                                        values: [
                                            {
                                                headerKey: 'name',
                                                value: `${user.firstName} ${user.lastName}`,
                                            },
                                            {
                                                headerKey: 'date',
                                                value: new Date(
                                                    shift.from,
                                                ).toLocaleDateString('de'),
                                            },
                                            {
                                                headerKey: 'start',
                                                value: new Date(
                                                    shift.from,
                                                ).toLocaleTimeString('de'),
                                            },
                                            {
                                                headerKey: 'end',
                                                value: new Date(
                                                    shift.to,
                                                ).toLocaleTimeString('de'),
                                            },
                                            {
                                                headerKey: 'shiftLength',
                                                value: `${shiftLength.toFixed(
                                                    2,
                                                )}`,
                                            },
                                            {
                                                headerKey: 'timeOnBreak',
                                                value: `${timeOnBreak.toFixed(
                                                    2,
                                                )}`,
                                            },
                                            {
                                                headerKey: 'totalLength',
                                                value: `${(
                                                    (shiftLength -
                                                        timeOnBreak) /
                                                    60
                                                ).toFixed(2)}`,
                                            },
                                        ],
                                        rowKey: shift.documentId,
                                    };
                                })}
                                headers={[
                                    {
                                        headerKey: 'date',
                                        label: timeViewMessages.date,
                                    },
                                    {
                                        headerKey: 'name',
                                        label: timeViewMessages.user,
                                    },
                                    {
                                        headerKey: 'start',
                                        label: timeViewMessages.start,
                                    },
                                    {
                                        headerKey: 'end',
                                        label: timeViewMessages.end,
                                    },
                                    {
                                        headerKey: 'shiftLength',
                                        label: timeViewMessages.shiftLength,
                                    },
                                    {
                                        headerKey: 'timeOnBreak',
                                        label: timeViewMessages.timeOnBreak,
                                    },
                                    {
                                        headerKey: 'totalLength',
                                        label: timeViewMessages.totalLength,
                                    },
                                ]}
                            />
                        ) : (
                            <CText
                                centered
                                secondaryHeadline
                                message={timeViewMessages.noShiftsForSelection}
                            />
                        )}
                    </View>
                )}
            </CCard>
        </ScrollProvider>
    );
};
