import React, { FC } from 'react';
import { View } from 'react-native';
import { CText, ExpandableCard } from '../../../components';
import { useFormat } from '../../../utilities/intl';
import {
    educationMessages,
    specializationMessages,
    positionMessages,
} from '../../../utilities/messages';
import { useStyle } from '../../../utilities/styles';
import { jobMessages } from '../../Job/job.messages';
import { MJob } from '../../../models';
import { JobRowBody } from '../../Job/List/components/JobRow/components/JobRowBody';
/**
 * overview of applicant connected to contract
 * @param param0
 * @returns
 */
export const JobOverviewBar: FC<{
    job: MJob;
}> = ({ job }) => {
    const format = useFormat();
    const style = useStyle();
    return (
        <ExpandableCard
            head={
                <View style={[style.horizontal, { width: '100%' }]}>
                    <View
                        style={[style.flex1, style.horizontalWrap, { flex: 1 }]}
                    >
                        <View
                            style={[
                                style.horizontalPadded,
                                { flex: 1, minWidth: 150 },
                            ]}
                        >
                            <CText message={jobMessages.educations} />
                            <CText
                                headline
                                message={job.employeeEducations
                                    .map((e) =>
                                        format(
                                            educationMessages[
                                                e as keyof typeof educationMessages
                                            ],
                                        ),
                                    )
                                    .join(', ')}
                            />
                        </View>
                        <View
                            style={[
                                style.horizontalPadded,
                                { flex: 1, minWidth: 150 },
                            ]}
                        >
                            <CText message={jobMessages.specializations} />
                            <CText
                                headline
                                message={job.employeeSpecializations
                                    .map((spec) =>
                                        format(
                                            specializationMessages[
                                                spec as keyof typeof specializationMessages
                                            ],
                                        ),
                                    )
                                    .join(', ')}
                            />
                        </View>
                        <View
                            style={[
                                style.horizontalPadded,
                                { flex: 1, minWidth: 130 },
                            ]}
                        >
                            <CText message={jobMessages.employeePosition} />
                            <CText
                                headline
                                message={
                                    positionMessages[
                                        job.employeePosition as keyof typeof positionMessages
                                    ]
                                }
                            />
                        </View>
                        <View
                            style={[
                                style.horizontalPadded,
                                { flex: 1, minWidth: 130 },
                            ]}
                        >
                            <CText message={jobMessages.zipCodeArea} />
                            <CText
                                headline
                                message={
                                    job.useFullAddress && job.fullAddress
                                        ? job.fullAddress.zipCode
                                        : job.location
                                }
                            />
                        </View>
                    </View>
                </View>
            }
            body={<JobRowBody job={job} />}
        />
    );
};
