import React, { FC } from 'react';

import { useFireBase } from './utilities/firebase';
import { Route, Routes, Navigate, useLocation } from './utilities/routing';

import { Spinner } from './components';
import { NavigationWrapper } from './App.navigation';
import {
    isAgencyUser,
    isEmployer,
    isPeasant,
    isSuperUser,
} from './utilities/auth';

import Calendar from './container/Calendar';
import Dashboard from './container/Dashboard';
import HelpCenter from './container/HelpCenter';
import JobEdit from './container/Job/Edit';
import JobList from './container/Job/List';
import Onboarding from './container/Onboarding';
import Settings from './container/Settings';
import Start from './container/Start';
import Profile from './container/Profile';
import Notifications from './container/Notifications';
import WorkplaceList from './container/Workplace/List';
import { WorkplaceEdit, WorkplaceCreate } from './container/Workplace/Edit';
import BillingCenter from './container/BillingCenter';
import Referrals from './container/Referrals';
import JobApplications from './container/JobApplications';
import ChatList from './container/Chat';
import Latepoint from './container/Latepoint';
import {
    ApplicationList,
    ApplicationView,
    ApplicationCreate,
} from './container/Application';
import SignContractFromUrl from './container/UrlHandler/SignContractFromUrl';
import UnsubscribeFromNotifications from './container/UrlHandler/UnsubscribeFromNotifications';
import UserList from './container/User/List';
import UserView from './container/User/View';
import ContractList from './container/Contract/List';
import ContractView from './container/Contract/View';
import { SectionedTalentEdit } from './container/ProfProfile/Edit';
import ProfProfileList from './container/ProfProfile/List';
import CreateJobUnverified, {
    ConfirmJobUnverified,
} from './container/UrlHandler/CreateJobUnverified';
import { EUserType } from './enums';
import {
    ContractNegotiationShort,
    NegotiationList,
} from './container/Contract/Negotiation';
import { WorkplaceNegotiationList } from './container/Contract/Negotiation';
import { JobApplicationView } from './container/JobApplications/View/JobApplicationView';
import {
    AgencyCreate,
    AgencyEdit,
    AgencyList,
    MyAgencies,
    TimeView,
} from './container/Agency';
import { Resume } from './container/Resume';
import { SupportRequestList } from './container/SupportRequest';
import {
    FillProfProfile,
    FillTimesheet,
    HandleInvite,
} from './container/UrlHandler';
import { ContractHistory } from './container/Contract';
import { DocumentScan, DocumentScanList } from './container/DocumentScan';
import { MultifactorSetup } from './container/MultifactorSetup';
import { RequestSignature } from './container/RequestSignature';
import { JobView } from './container/Job/View';
import { Logout } from './container/Logout';
import { Timesheet } from './container/Timesheet';
import { Reviews } from './container/Reviews';
import { AvailableUsersList } from './container/AvailableUsers';
import { CreateRequest } from './container/AvailableUsers/Request/CreateRequest';
import { RequestList } from './container/AvailableUsers/Request/List/RequestList';
import { ShiftPlan } from './container/ShiftPlan';
import { ShiftPlanList } from './container/ShiftPlan/ShiftPlanList';
import { Sign } from './container/Sign';
/**
 * routes accessible logged in and not logged in
 */
const omniRoutes = (
    <>
        <Route path="/job/confirm/:id" element={<ConfirmJobUnverified />} />
        <Route path="/invite" element={<HandleInvite />} />
        <Route
            path="/unsubscribe/:uid/:notification"
            element={<UnsubscribeFromNotifications />}
        />
        <Route
            path="/sign/contract/:negotiationId/:hash"
            element={<SignContractFromUrl />}
        />
        <Route
            path="/sign/envelope/:contractId/:envelopeId/:type"
            element={<Sign />}
        />
        <Route
            path="/open/timesheet/:contractId/:timesheetId"
            element={<FillTimesheet />}
        />
        <Route path="/fill" element={<FillProfProfile />} />
        <Route path="/jobs" element={<JobList />} />
        <Route path="/job/view/:id" element={<JobView />} />
    </>
);
/**
 * App Routing
 * @returns
 */
export const AppRouting: FC = () => {
    const { isLoggedIn, requiresOnboarding, loading, userData } = useFireBase();
    const location = useLocation();
    /**
     * effect to start onboarding if required
     */
    // useEffect(() => {
    //     if (!loading && !isLoggedIn) {
    //         navigate('/');
    //     }
    // }, [isLoggedIn, loading]);
    /**
     * show spinner while initializing firebase
     */
    if (loading) {
        return <Spinner fullScreen />;
    }
    /**
     * routes if not logged in
     */
    if (!isLoggedIn) {
        return (
            <Routes>
                <Route path="/" element={<Start />} />
                <Route path="/job/new" element={<CreateJobUnverified />} />
                {omniRoutes}
                {/* Redirect if no route found */}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        );
    } else if (requiresOnboarding) {
        return (
            <Routes>
                <Route path="/" element={<Onboarding />} />
                {/* Redirect if no route found */}
                <Route
                    path="*"
                    element={<Navigate to={'/' + location.search} replace />}
                />
            </Routes>
        );
    }
    /**
     * routes
     */
    return (
        <NavigationWrapper>
            <Route path="/" element={<Dashboard />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/job" element={<JobList />} />
            <Route path="/job/:id" element={<JobEdit />} />
            <Route path="/job/duplicate/:id" element={<JobEdit />} />
            <Route path="/job/publish/:id" element={<JobEdit />} />
            <Route path="/job/draft/:id" element={<JobEdit />} />
            <Route path="/japplications" element={<JobApplications />} />
            <Route
                path="/japplication/view/:id"
                element={<JobApplicationView />}
            />
            <Route path="/application" element={<ApplicationList />} />
            <Route path="/application/:id" element={<ApplicationView />} />
            <Route path="/apply/:jobId" element={<ApplicationCreate />} />
            <Route
                path="/apply/:jobId/:profProfi"
                element={<ApplicationCreate />}
            />
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/:subMenu" element={<Profile />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/helpcenter" element={<HelpCenter />} />
            <Route path="/helpcenter/:help" element={<HelpCenter />} />
            <Route path="/helpcenter/:help/:child" element={<HelpCenter />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/billingcenter" element={<BillingCenter />} />
            <Route path="/referrals" element={<Referrals />} />
            <Route path="/chat/:id" element={<ChatList />} />
            <Route path="/chat" element={<ChatList />} />
            <Route path="/latepoint" element={<Latepoint />} />
            <Route path="/contracts" element={<ContractList />} />
            <Route path="/contract/:id" element={<ContractView />} />
            <Route path="/contract/:id/:m" element={<ContractView />} />
            <Route
                path="/contract/:contractId/newTimesheet"
                element={<Timesheet />}
            />
            <Route
                path="/contract/:contractId/timesheet/:timesheetId"
                element={<Timesheet />}
            />
            <Route path="/contractHistory" element={<ContractHistory />} />
            <Route
                path="/contract/negotiation/:id"
                element={<ContractNegotiationShort />}
            />
            <Route
                path="/negotiations/:id"
                element={<ContractNegotiationShort />}
            />
            <Route path="/multifactorsetup" element={<MultifactorSetup />} />
            <Route path="/documentScan/" element={<DocumentScan />} />
            <Route path="/requestSigning" element={<RequestSignature />} />
            <Route path="/sign" element={<Sign />} />
            <Route path="/reviews/:type/:id" element={<Reviews />} />
            <Route path="/shiftplan" element={<ShiftPlanList />} />
            <Route path="/shiftplan/create" element={<ShiftPlan />} />
            <Route
                path="/shiftplan/view/:workplaceId/:id"
                element={<ShiftPlan />}
            />
            <Route path="/logout" element={<Logout />} />
            {isSuperUser(userData) && (
                <Route
                    path="/documentScan/list"
                    element={<DocumentScanList />}
                />
            )}
            {(isSuperUser(userData) || userData.type === EUserType.agency) && (
                <Route path="/profUser" element={<ProfProfileList />} />
            )}
            {(isSuperUser(userData) || userData.type === EUserType.agency) && (
                <Route path="/profUser/:id" element={<SectionedTalentEdit />} />
            )}
            {(isSuperUser(userData) || userData.type === EUserType.agency) && (
                <Route
                    path="/profUser/:id/:m"
                    element={<SectionedTalentEdit />}
                />
            )}
            {isSuperUser(userData) && (
                <Route path="/user" element={<UserList />} />
            )}
            {isSuperUser(userData) && (
                <Route path="/user/:id" element={<UserView />} />
            )}
            {isSuperUser(userData) && (
                <Route
                    path="/supportrequests"
                    element={<SupportRequestList />}
                />
            )}
            {isEmployer(userData) && (
                <Route path="/myAgencies" element={<MyAgencies />} />
            )}
            {(isEmployer(userData) || isSuperUser(userData)) && (
                <Route path="/workplace" element={<WorkplaceList />} />
            )}
            {(isEmployer(userData) || isSuperUser(userData)) && (
                <Route path="/workplace/create" element={<WorkplaceCreate />} />
            )}
            {(isEmployer(userData) || isSuperUser(userData)) && (
                <Route path="/workplace/edit/:id" element={<WorkplaceEdit />} />
            )}
            {(isEmployer(userData) || isSuperUser(userData)) && (
                <Route
                    path="/negotiations"
                    element={<WorkplaceNegotiationList />}
                />
            )}
            {(isEmployer(userData) || isSuperUser(userData)) && (
                <Route
                    path="/availableUsers"
                    element={<AvailableUsersList />}
                />
            )}
            {(isEmployer(userData) || isSuperUser(userData)) && (
                <Route
                    path="/availableUsers/request/:id"
                    element={<CreateRequest />}
                />
            )}
            {(isPeasant(userData) || isSuperUser(userData)) && (
                <Route path="/requests" element={<RequestList />} />
            )}
            {isPeasant(userData) && (
                <Route path="/negotiations" element={<NegotiationList />} />
            )}
            {(isAgencyUser(userData) || isSuperUser(userData)) && (
                <Route path="/agency" element={<AgencyList />} />
            )}
            {(isAgencyUser(userData) || isSuperUser(userData)) && (
                <Route path="/agency/create" element={<AgencyCreate />} />
            )}
            {(isAgencyUser(userData) || isSuperUser(userData)) && (
                <Route path="/agency/edit/:id" element={<AgencyEdit />} />
            )}
            {(isAgencyUser(userData) || isSuperUser(userData)) && (
                <Route path="/agency/times/:id" element={<TimeView />} />
            )}
            {omniRoutes}
        </NavigationWrapper>
    );
};
