import React, { FC, useContext, useEffect } from 'react';
import { useFormat } from '../../../utilities/intl';
import { paymentIntervalMessages } from '../../../utilities/messages';
import { CText, OutOfContext, Radio } from '../../../components';
import { CImage } from '../../../components/elements/CImage';
import { ProfileContext } from '../Profile.context';

export const ProfileSectionPayment: FC = () => {
    const format = useFormat();
    const { curUserData, onChangeUserData, onValidityChange } =
        useContext(ProfileContext);

    useEffect(() => {
        onValidityChange('');
    }, [curUserData]);
    /**
     * check if context is correct
     */
    if (!curUserData || !onChangeUserData) {
        return <OutOfContext component="ProfileSectionPayment" />;
    }
    return (
        <>
            <CImage image="payment" />
            <Radio
                cy={'payment'}
                values={[
                    {
                        label: format(paymentIntervalMessages.weekly),
                        value: 'weekly',
                    },
                    {
                        label: format(paymentIntervalMessages.monthly),
                        value: 'monthly',
                    },
                ]}
                value={curUserData.paymentInterval}
                onChange={(paymentInterval) =>
                    onChangeUserData({ paymentInterval })
                }
            />
        </>
    );
};
