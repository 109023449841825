import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { CButton, CTextInput } from '../../../../components';
import {
    EducationPicker,
    FieldPicker,
    ProfessionPicker,
} from '../../../../components/Pickers';
import { WorkplaceZipCodeTypeahead } from '../../../../components/Typeahead/WorkplaceZipCodeTypeahead';
import { EField, EProfession, EUserType } from '../../../../enums';
import { MWorkplace } from '../../../../models';
import { isSuperUser } from '../../../../utilities/auth';
import { useFireBase } from '../../../../utilities/firebase';
import { useDebounce } from '../../../../utilities/hooks/useDebounce';
import { useFormat } from '../../../../utilities/intl';
import { generalMessages } from '../../../../utilities/messages';
import { useStyle } from '../../../../utilities/styles';
import { jobMessages } from '../../job.messages';

export enum EFilterMode {
    nearby = 'nearby',
    all = 'all',
    localMore = 'localMore',
    forMe = 'forMe',
    // pinned = 'pinned',
}

interface IJobsFilterBarProps {
    mode: EFilterMode[];
    toggleMode: (next: EFilterMode) => void;
    clearMode: () => void;
    pins: any[];
    setDateFilter: (next: number) => void;
    dateFilter: number;
    curWorkplace?: MWorkplace;
    setCurWorkplace: (next?: MWorkplace) => void;
    curId?: number;
    setCurId: (next: number) => void;
    curField?: EField;
    setCurField: (next?: EField) => void;
    curProfession?: EProfession;
    setCurProfession: (next?: EProfession) => void;
    curEducation?: string;
    setCurEducation: (next?: string) => void;
}

export const JobsFilterBar: FC<IJobsFilterBarProps> = ({
    mode,
    toggleMode,
    clearMode,
    setDateFilter,
    dateFilter,
    curWorkplace,
    setCurWorkplace,
    curId,
    setCurId,
    curField,
    setCurField,
    curProfession,
    setCurProfession,
    curEducation,
    setCurEducation,
}) => {
    const style = useStyle();
    const format = useFormat();
    const { userData, getDataIndex } = useFireBase();
    const [localMore, setLocalMore] = useState(false);
    const [id, setId] = useState(curId);
    const debouncedId = useDebounce(id, 500);

    const more = useMemo(
        () => !!(localMore || id || dateFilter),
        [localMore, id, dateFilter],
    );

    const filtering = useMemo(
        () => !!(id || dateFilter || mode.length || curWorkplace),
        [mode, id, dateFilter, curWorkplace],
    );

    useEffect(() => {
        if (debouncedId !== curId) {
            setCurId(debouncedId || 0);
        }
    }, [debouncedId]);

    return (
        <View style={{ zIndex: 100 }}>
            <View style={[style.horizontalWrap, style.centeredItems]}>
                <CButton
                    cy={EFilterMode.all}
                    title={format(generalMessages.all)}
                    onPress={() => {
                        setCurWorkplace(undefined);
                        setDateFilter(0);
                        setCurId(0);
                        setId(0);
                        setCurField(undefined);
                        setLocalMore(false);
                        clearMode();
                    }}
                    minor={filtering}
                    small
                    style={style.smallMargin}
                />
                <View style={style.verticalPaddedThinSeparator} />
                {userData.type === EUserType.user && (
                    <CButton
                        title={format(generalMessages.forMe)}
                        onPress={() => toggleMode(EFilterMode.forMe)}
                        minor={!mode.includes(EFilterMode.forMe)}
                        small
                        style={style.smallMargin}
                    />
                )}
                <CButton
                    title={format(generalMessages.nearby)}
                    onPress={() => {
                        toggleMode(EFilterMode.nearby);
                    }}
                    minor={!mode.includes(EFilterMode.nearby)}
                    small
                    style={style.smallMargin}
                />
                {/* {!!pins.length && (
                    <CButton
                        title={format(generalMessages.pinned)}
                        onPress={() => {
                            toggleMode(EFilterMode.pinned);
                        }}
                        minor={!mode.includes(EFilterMode.pinned)}
                        small
                        style={{ margin: 5 }}
                    />
                )} */}
                <CButton
                    title={format(generalMessages.more)}
                    onPress={() => setLocalMore(!localMore)}
                    minor={!localMore}
                    small
                    style={style.smallMargin}
                    icon={'tune'}
                />
            </View>
            {more && (
                <View style={{ zIndex: 100 }}>
                    {/* <View style={style.verticalPadded}>
                            <CTextInput
                                label={format(jobMessages.filterByText)}
                                value={sValue}
                                onChangeText={setSValue}
                                placeholder={format(
                                    jobMessages.enterTextToFilterBy,
                                )}
                                hint={format(jobMessages.searchRestrictions)}
                                autoExtend
                            />
                        </View> */}
                    {/* <CPicker
                        title={jobMessages.timeFilter}
                        onChange={setDateFilter}
                        value={dateFilter}
                        values={[
                            {
                                label: `-${format(
                                    jobMessages.timeFilterPlaceholder,
                                )}-`,
                                value: 0,
                            },
                            ...Array.from(Array(20)).map((v, i) => {
                                const d = new Date(
                                    new Date().getFullYear(),
                                    new Date().getMonth() + i,
                                    1,
                                );

                                const keys = Object.keys(monthMessages);

                                return {
                                    value: d.getTime(),
                                    label: `${d.getFullYear()} ${format(
                                        // @ts-ignore
                                        monthMessages[keys[d.getMonth()]],
                                    )}`,
                                };
                            }),
                        ]}
                    /> */}
                    {userData.type !== EUserType.user && (
                        <>
                            <FieldPicker
                                onChange={(field) => {
                                    setCurField(field);
                                    setCurProfession(undefined);
                                    setCurEducation(undefined);
                                }}
                                value={curField}
                            />
                            {curField && (
                                <ProfessionPicker
                                    field={curField}
                                    onChange={(p) => {
                                        setCurProfession(p);
                                        setCurEducation(undefined);
                                    }}
                                    value={curProfession}
                                />
                            )}
                            {curProfession && (
                                <EducationPicker
                                    field={curField}
                                    profession={curProfession}
                                    onChange={(p) => {
                                        setCurEducation(p as string);
                                    }}
                                    value={curEducation}
                                />
                            )}
                        </>
                    )}
                    {isSuperUser(userData) && (
                        <WorkplaceZipCodeTypeahead
                            currentWorkplace={curWorkplace}
                            onChangeWorkplace={setCurWorkplace}
                        />
                    )}
                    <CTextInput
                        label={format(jobMessages.id)}
                        autoExtend
                        value={id ? `${id}` : ''}
                        onChangeText={(s) => {
                            if (!isNaN(+s)) {
                                setId(+s);
                            }
                        }}
                    />
                </View>
            )}
        </View>
    );
};
