import React, { FC } from 'react';
import { View } from 'react-native';
import { CText, CPicker, CButton, Spinner } from '../../../../components';
import { ProfilePicture } from '../../../../components/ProfilePicture';
import { MUserData } from '../../../../models';
import { useFormat } from '../../../../utilities/intl';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { userMessages } from '../../user.messages';

interface IAssignedAgentsProps {
    user: MUserData;
    agents: MUserData[];
    onChange: (next: Partial<MUserData>) => void;
}
/**
 * view to display assigned agents for current user
 * @param param0
 * @returns
 */
export const AssignedAgents: FC<IAssignedAgentsProps> = ({
    agents,
    onChange,
    user,
}) => {
    // global state
    const style = useStyle();
    const format = useFormat();
    const { theme } = useTheme();
    /**
     * render
     */
    return (
        <View>
            <CText>{format(userMessages.assignedAgents)}</CText>
            <CPicker
                values={[
                    {
                        value: undefined,
                        hidden: true,
                        label: `-${format(userMessages.selectAgent)}-`,
                    },
                    ...agents
                        .filter(
                            (a) => !user.assignedAgents.includes(a.documentId),
                        )
                        .map((a) => {
                            return {
                                value: a.documentId,
                                label: `${a.firstName} ${a.lastName}`,
                            };
                        }),
                ]}
                onChange={(agentId) => {
                    onChange({
                        assignedAgents: [...user.assignedAgents, agentId],
                    });
                }}
            />
            {user.assignedAgents
                .filter((a) => !!a)
                .map((aid, i) => {
                    const agent = agents.find((a) => a.documentId === aid);
                    if (!agent) {
                        return <Spinner key={aid} />;
                    }
                    return (
                        <View
                            key={aid}
                            style={[
                                style.horizontalSplit,
                                style.centeredItems,
                                style.verticalPadded,
                            ]}
                        >
                            <View
                                style={[style.horizontal, style.centeredItems]}
                            >
                                <ProfilePicture small data={agent} />
                                <CText>{`${agent.firstName} ${agent.lastName}`}</CText>
                            </View>
                            <View
                                style={[style.horizontal, style.centeredItems]}
                            >
                                {i > 0 && (
                                    <CButton
                                        icon={'chevronUp'}
                                        transparent
                                        onPress={() => {
                                            const next = Array.from(
                                                user.assignedAgents,
                                            );
                                            next.splice(i, 1);
                                            next.splice(i - 1, 0, aid);
                                            onChange({
                                                assignedAgents: next,
                                            });
                                        }}
                                    />
                                )}
                                <CButton
                                    icon={'close'}
                                    iconColor={theme.errorColor}
                                    transparent
                                    onPress={() => {
                                        const next = Array.from(
                                            user.assignedAgents,
                                        );
                                        next.splice(i, 1);
                                        onChange({
                                            assignedAgents: next,
                                        });
                                    }}
                                />
                            </View>
                        </View>
                    );
                })}
        </View>
    );
};
