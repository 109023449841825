import React, { FC, useEffect, useState } from 'react';
import { Linking, View } from 'react-native';
import {
    CButton,
    CCard,
    CDataTable,
    CPicker,
    CText,
    Spinner,
} from '../../components';
import { CImage } from '../../components/elements/CImage';
import { ScrollProvider } from '../../utilities/contexts/Scroll';
import { useFormat } from '../../utilities/intl';
import {
    educationMessages,
    fieldMessages,
    generalMessages,
    monthMessages,
} from '../../utilities/messages';
import { useStyle } from '../../utilities/styles';
import { billingCenterMessages } from './billingCenter.messages';
import { useFireBase } from '../../utilities/firebase';
import {
    EUserType,
    ECollections,
    EField,
    EProfession,
    EContractStatus,
} from '../../enums';
import { MContract, MProfessionalInfo, MWorkplace } from '../../models';
import { isPeasant, isSuperUser } from '../../utilities/auth';
import { IFilter } from '../../utilities/firebase/store';
import { ContractState } from '../Contract/components/ContractState';
import { useLock } from '../../utilities/hooks';
import { calendarMessages } from '../Calendar/Calendar.messages';
/**
 * Billing center to list contract history and track payment status
 * @returns
 */
export const BillingCenter: FC = () => {
    // global state
    const style = useStyle();
    const format = useFormat();
    const {
        getDataIndex,
        getDataById,
        userData,
        userWorkplaces,
        callFunction,
        getFileDownloadUrl,
    } = useFireBase();
    const { lock } = useLock();
    // local state
    const [month, setMonth] = useState(new Date().getMonth());
    const [year, setYear] = useState(new Date().getFullYear());
    const [contracts, setContracts] = useState<MContract[]>([]);
    const [workplaces, setWorkplaces] = useState<MWorkplace[]>([]);
    const [pubUsers, setPubUsers] = useState<MProfessionalInfo[]>([]);
    const [pubTalents, setPubTalents] = useState<MProfessionalInfo[]>([]);
    const [loadingContracts, setLoadingContracts] = useState(true);
    /**
     * effect to load contracts
     */
    useEffect(() => {
        const params = {
            filter: [] as IFilter[],
        };
        if (!isSuperUser(userData)) {
            if (userData.type === EUserType.employer) {
                if (!userWorkplaces.length) {
                    return;
                }
                params.filter.push({
                    field: 'workplaceUsers',
                    value: userData.documentId,
                    operator: 'array-contains',
                });
            } else {
                params.filter.push({
                    field: 'employeeId',
                    value: userData.documentId,
                    operator: '==',
                });
            }
        }
        params.filter.push({
            field: 'status',
            operator: 'in',
            value: [EContractStatus.completed, EContractStatus.paid],
        });
        params.filter.push({ field: `activeYears.${year}`, value: true });
        if (month) {
            params.filter.push({
                field: Object.keys(monthMessages).filter(
                    (m) => !m.includes('Short'),
                )[month % 12],
                value: true,
            });
        }
        getDataIndex(ECollections.contracts, params).then((res) => {
            const nextContracts = (res as MContract[]).map(
                (n) => new MContract(n),
            );
            setContracts(nextContracts);
            setLoadingContracts(false);
        });
    }, [userData, userWorkplaces]);
    /**
     * effect to load pub infos for current contracts
     */
    useEffect(() => {
        /**
         * public workplace infos
         */
        const wpids = Array.from(new Set(contracts.map((c) => c.workplaceId)));
        Promise.all(
            wpids.map((wpid) =>
                getDataById(ECollections.publicWorkplaces, wpid),
            ),
        ).then((wps) => setWorkplaces(wps.map((wp) => new MWorkplace(wp))));
        /**
         * public user infos
         */
        const uids = Array.from(
            new Set(
                contracts.filter((c) => !c.profileId).map((c) => c.employeeId),
            ),
        );
        Promise.all(
            uids.map((uid) => getDataById(ECollections.publicUsers, uid)),
        ).then((users) =>
            setPubUsers(users.map((u) => new MProfessionalInfo(u))),
        );
        /**
         * public talent infos
         */
        const tids = Array.from(
            new Set(contracts.map((c) => c.profileId).filter((id) => !!id)),
        ) as string[];
        Promise.all(
            tids.map((tid) =>
                getDataById(ECollections.publicProfProfiles, tid),
            ),
        ).then((talents) =>
            setPubTalents(talents.map((t) => new MProfessionalInfo(t))),
        );
    }, [contracts]);
    /**
     * render spinner during load
     */
    if (loadingContracts) {
        return <Spinner />;
    }
    /**
     * render
     */
    return (
        <ScrollProvider
            style={!contracts.length && style.paddedScrollableMainView}
        >
            <View style={[style.card, style.horizontal, style.centeredItems]}>
                <CText bold headline message={generalMessages.billingCenter} />
                <View style={[style.horizontal, style.horizontalPadded]}>
                    <CPicker
                        title={calendarMessages.month}
                        value={month + 1}
                        values={Object.keys(monthMessages)
                            .filter((m) => !m.includes('Short'))
                            .map((m, i) => ({
                                label: monthMessages[
                                    m as keyof typeof monthMessages
                                ],
                                value: i + 1,
                            }))}
                        onChange={(v) => setMonth(v - 1)}
                        horizontal
                    />
                    <CPicker
                        horizontal
                        title={calendarMessages.year}
                        value={year}
                        values={Array.from(
                            new Set([
                                ...Array(10).keys(),
                                new Date().getFullYear() -
                                    (new Date().getFullYear() - 9),
                            ]),
                        ).map((y, i) => ({
                            label: `${y + new Date().getFullYear() - 9}`,
                            value: y + new Date().getFullYear() - 9,
                        }))}
                        onChange={setYear}
                    />
                </View>
            </View>
            {!contracts.length ? (
                <CCard>
                    <CImage image={'payment'} relativeWidth={0.7} />
                    <View
                        style={[style.horizontalSpaced, style.verticalPadded]}
                    >
                        <CText
                            bold
                            headline
                            message={billingCenterMessages.empty}
                        />
                    </View>
                </CCard>
            ) : (
                <CCard>
                    <CDataTable
                        headers={[
                            {
                                headerKey: 'talentName',
                                label: billingCenterMessages.talentName,
                            },
                            {
                                headerKey: 'status',
                                label: billingCenterMessages.status,
                            },
                            {
                                headerKey: 'from',
                                label: billingCenterMessages.from,
                            },
                            {
                                headerKey: 'to',
                                label: billingCenterMessages.to,
                            },
                            {
                                headerKey: 'workplace',
                                label: billingCenterMessages.workplace,
                            },
                            !isPeasant(userData)
                                ? {
                                      headerKey: 'jobdescription',
                                      label: billingCenterMessages.jobDescription,
                                  }
                                : undefined,
                            {
                                headerKey: 'field',
                                label: billingCenterMessages.field,
                            },
                            {
                                headerKey: 'profession',
                                label: billingCenterMessages.profession,
                            },
                            {
                                headerKey: 'education',
                                label: billingCenterMessages.education,
                            },
                            {
                                headerKey: 'createdOn',
                                label: billingCenterMessages.createdOn,
                            },
                            { headerKey: 'documentId', label: 'ID' },
                            {
                                headerKey: 'files',
                                label: billingCenterMessages.files,
                            },
                        ]}
                        rows={contracts.map((c) => ({
                            values: [
                                {
                                    headerKey: 'documentId',
                                    value: c.documentId,
                                },
                                {
                                    headerKey: 'workplace',
                                    value:
                                        workplaces.find(
                                            (wp) =>
                                                wp.documentId === c.workplaceId,
                                        )?.name || c.workplaceId,
                                },
                                {
                                    headerKey: 'createdOn',
                                    value: new Date(
                                        c.createdOn,
                                    ).toLocaleDateString('de'),
                                },
                                {
                                    headerKey: 'from',
                                    value: new Date(c.from).toLocaleDateString(
                                        'de',
                                    ),
                                },
                                {
                                    headerKey: 'to',
                                    value: new Date(c.to).toLocaleDateString(
                                        'de',
                                    ),
                                },
                                {
                                    headerKey: 'status',
                                    value: '',
                                    component: (
                                        <View style={style.horizontal}>
                                            <ContractState contract={c} />
                                        </View>
                                    ),
                                },
                                {
                                    headerKey: 'field',
                                    value: format(
                                        fieldMessages[
                                            c.field || EField.undefined
                                        ],
                                    ),
                                },
                                {
                                    headerKey: 'profession',
                                    value: format(
                                        generalMessages[
                                            c.profession ||
                                                EProfession.undefined
                                        ],
                                    ),
                                },
                                {
                                    headerKey: 'education',
                                    value: c.education
                                        ? format(
                                              educationMessages[
                                                  c.education as keyof typeof educationMessages
                                              ],
                                          )
                                        : '?',
                                },
                                {
                                    headerKey: 'files',
                                    value: '',
                                    component: (
                                        <CButton
                                            onPress={async () => {
                                                const unlock = lock();
                                                const path = await callFunction(
                                                    'getContractZip',
                                                    {
                                                        contractId:
                                                            c.documentId,
                                                    },
                                                );
                                                const url =
                                                    await getFileDownloadUrl(
                                                        path,
                                                    );
                                                await Linking.openURL(url);
                                                unlock();
                                            }}
                                            smallest
                                            icon={'download'}
                                        />
                                    ),
                                },
                                {
                                    headerKey: 'jobdescription',
                                    value: c.title || '',
                                },
                                {
                                    headerKey: 'talentName',
                                    value:
                                        (c.profileId
                                            ? pubTalents.find(
                                                  (t) =>
                                                      t.documentId ===
                                                      c.profileId,
                                              )?.lastName
                                            : pubUsers.find(
                                                  (u) =>
                                                      u.documentId ===
                                                      c.employeeId,
                                              )?.lastName) || '',
                                },
                            ],
                        }))}
                    />
                </CCard>
            )}
        </ScrollProvider>
    );
};
