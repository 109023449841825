/**
 * sort object keys alphabetically to avoid order missmatch in json.stringify comparison of prev and cur object state
 * @param obj object to sort keys on
 * @returns object with sorted keys
 */
export const sortObjectKeys = (obj: any) =>
    Object.keys(obj)
        .sort()
        .reduce((accumulator, key) => {
            accumulator[key] = obj[key];

            return accumulator;
        }, {} as any);
