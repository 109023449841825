import { defineMessages } from 'react-intl';

const scope = 'gen.';
/**
 * gender names
 * is used for gender picker
 */
export const genderMessages = defineMessages({
    male: { id: scope + 'male', defaultMessage: 'Male' },
    female: { id: scope + 'female', defaultMessage: 'Female' },
    diverse: { id: scope + 'diverse', defaultMessage: 'Diverse' },
});
