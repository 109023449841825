import React, { FC, useMemo, useState } from 'react';
import { View } from 'react-native';
import { CButton, CImage, CPicker, CText, Spinner } from '../../../components';
import { useFormat } from '../../../utilities/intl';
import { useStyle, useTheme } from '../../../utilities/styles';
import { jobApplicationMessages } from '../JobApplication.messages';
import { useJobApplicationViewState } from './useJobApplicationViewState';
import { ApplicationRow } from './components/ApplicationRow';
import { useNavigate } from 'react-router-native';
import { useSearchParams } from '../../../utilities/routing';
import { ScrollProvider } from '../../../utilities/contexts';
import { JobSideCard } from './components/JobSideCard';
import { ContractRow } from '../../Contract/List/components/ContractRow';
import { EJobStatus } from '../../../enums';

export const JobApplicationView: FC = () => {
    // global state
    const style = useStyle();
    const format = useFormat();
    const { theme } = useTheme();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    // local state
    const [tableWidth, setTableWidth] = useState(0);
    const cellWidth = useMemo(() => {
        return tableWidth / 6;
    }, [tableWidth]);
    const {
        job,
        loading,
        openContracts,
        activeNegotiations,
        orderedApplications,
        orderedApplicationsPreferred,
        orderedApplicationsRegular,
        handleStatusChange,
        orderBy,
        setOrderBy,
        share,
        closeJob,
    } = useJobApplicationViewState();

    if (loading || !job) {
        return <Spinner />;
    }
    return (
        <ScrollProvider
            style={[
                style.paddedScrollableMainView,
                { maxWidth: Number.MAX_SAFE_INTEGER },
            ]}
        >
            <View style={[style.horizontal, style.flex1, { width: '100%' }]}>
                <View style={style.flex1}>
                    <View style={[style.card]}>
                        <View style={[style.horizontal]}>
                            <View style={style.horizontalPadded}>
                                <CButton
                                    cy={'back'}
                                    onPress={async () => {
                                        if (searchParams.get('fl')) {
                                            navigate('/japplications');
                                        } else {
                                            navigate(-1);
                                        }
                                    }}
                                    icon={'chevronLeft'}
                                    small
                                />
                            </View>
                            <CText bold headline>
                                {format(jobApplicationMessages.jobOverview)}
                            </CText>
                        </View>
                    </View>
                    {!!openContracts.length && (
                        <View style={style.card}>
                            <CText
                                bold
                                headline
                                message={jobApplicationMessages.contracts}
                            />
                        </View>
                    )}
                    {openContracts.map((c) => (
                        <ContractRow key={c.documentId} negotiation={c} />
                    ))}
                    {!!activeNegotiations.length && (
                        <View style={style.card}>
                            <CText
                                bold
                                headline
                                message={jobApplicationMessages.negotiations}
                            />
                        </View>
                    )}
                    {activeNegotiations.map((c) => (
                        <ContractRow key={c.documentId} negotiation={c} />
                    ))}
                    <View style={style.card}>
                        <CText
                            bold
                            headline
                            message={jobApplicationMessages.applicants}
                        />
                    </View>
                    <View style={[style.horizontal, style.horizontalPadded]}>
                        <CPicker
                            title={jobApplicationMessages.orderBy}
                            values={[
                                {
                                    label: jobApplicationMessages.rate,
                                    value: 'rate',
                                },
                                {
                                    label: jobApplicationMessages.yearsOfExperience,
                                    value: 'yearsOfExperience',
                                },
                            ]}
                            value={orderBy}
                            onChange={(v) => setOrderBy(v)}
                        />
                    </View>
                    {!!orderedApplicationsPreferred.length && (
                        <CText
                            message={
                                jobApplicationMessages.applicationsFromPreferred
                            }
                            secondaryHeadline
                        />
                    )}
                    {orderedApplicationsPreferred.map((application) => (
                        <ApplicationRow
                            key={application.documentId}
                            application={application}
                            job={job}
                            handleStatusChange={handleStatusChange}
                            cellWidth={cellWidth}
                            onLayout={(event) => {
                                const { width } = event.nativeEvent.layout;
                                setTableWidth(width);
                            }}
                        />
                    ))}
                    {!!orderedApplicationsPreferred.length && (
                        <View style={style.paddedThinSeparator} />
                    )}
                    {!!orderedApplicationsPreferred.length && (
                        <CText
                            message={jobApplicationMessages.otherApplications}
                            secondaryHeadline
                        />
                    )}
                    {orderedApplicationsRegular.map((application) => (
                        <ApplicationRow
                            key={application.documentId}
                            application={application}
                            job={job}
                            handleStatusChange={handleStatusChange}
                            cellWidth={cellWidth}
                            onLayout={(event) => {
                                const { width } = event.nativeEvent.layout;
                                setTableWidth(width);
                            }}
                        />
                    ))}
                    {!orderedApplications.length && (
                        <CImage image="applicants" />
                    )}
                    <View style={style.horizontalSpaced}>
                        <CButton
                            icon={'share'}
                            onPress={share}
                            small
                            style={[
                                style.horizontalPadded,
                                { marginVertical: 10 },
                            ]}
                            title={jobApplicationMessages.share}
                        />
                    </View>
                </View>
                <View style={{ flex: 1, maxWidth: 450 }}>
                    <JobSideCard
                        closeJob={
                            [EJobStatus.public, EJobStatus.private].includes(
                                job.status,
                            )
                                ? closeJob
                                : undefined
                        }
                        job={job}
                        applicationsLength={orderedApplications.length}
                        share={share}
                    />
                </View>
            </View>
        </ScrollProvider>
    );
};
