import React, { FC } from 'react';
import { StyleProp, Pressable, View, ViewStyle } from 'react-native';
import { useStyle, useTheme } from '../../utilities/styles';
import { CIcon, CText } from '../';
import { makeTabButtonsStyles } from './styles';
import { Message } from '../../utilities/types';

interface ITabButtonsProps {
    values: { value: string; label: Message; icon?: string }[];
    onChange: (value: string) => void;
    value: string;
    layoutStyle?: StyleProp<ViewStyle>;
}

export const TabButtons: FC<ITabButtonsProps> = ({
    value,
    values,
    onChange,
    layoutStyle,
}) => {
    const styles = useStyle();
    const { theme } = useTheme();
    const localStyle = makeTabButtonsStyles(theme);
    return (
        <View style={[styles.horizontalSplit, layoutStyle]}>
            {values.map(({ value: v, label, icon }) => (
                <Pressable
                    {...{ dataSet: { cy: `${v}-tab` } }}
                    key={v}
                    style={[
                        localStyle.tabButton,
                        v === value && localStyle.activeTab,
                    ]}
                    onPress={() => onChange(v)}
                >
                    {!!icon && <CIcon icon={icon} tint={theme.accentColor} />}
                    <CText
                        style={localStyle.tabtext}
                        isButtonText
                        message={label}
                    />
                </Pressable>
            ))}
        </View>
    );
};
