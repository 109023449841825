import Clipboard from '@react-native-clipboard/clipboard';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { CIcon, CText, TouchableView } from '../../components';
import { ExpandableCard } from '../../components/ExpandableCard';
import { CButton } from '../../components/elements';
import { ECollections, EUserType } from '../../enums';
import { MUserData, MUserIdentDoc } from '../../models';
import { ScrollProvider } from '../../utilities/contexts/Scroll';
import { useUnsavedChangesDialog } from '../../utilities/dialog';
import { useFireBase } from '../../utilities/firebase';
import { year } from '../../utilities/functions';
import { sortObjectKeys } from '../../utilities/functions/sortObjectKeys';
import { useFormat } from '../../utilities/intl';
import { actionMessages } from '../../utilities/messages/action.messages';
import { generalMessages } from '../../utilities/messages/general.messages';
import { useParams, useSecureNavigate } from '../../utilities/routing';
import { useStyle, useTheme } from '../../utilities/styles';
import { ProfessionalLanguage } from '../ProfProfile/Edit/components';
import { profProfileMessages } from '../ProfProfile/ProfProfile.messages';
import { ProfileContext } from './Profile.context';
import { profileMessages } from './Profile.messages';
import { ProfileSectionAccountActions } from './components/PSAccountActions';
import { ProfileSectionAddress } from './components/PSAddress';
import { ProfileSectionAgency } from './components/PSAgency';
import { ProfileSectionAvailabilities } from './components/PSAvailabilities';
import { ProfileSectionBank } from './components/PSBank';
import { ProfileSectionBasic } from './components/PSBasic';
import { ProfileSectionDocuments } from './components/PSDocuments';
import { ProfileSectionProfessional } from './components/PSProfessional';
import { ProfileSectionWorkplace } from './components/PSWorkplace';
import { ProfileSectionMultiFactor } from './components/PSMFA';
import { PSTalentDocuments } from './components/PSTalentDocuments';
import { ProfileSectionDigitalCertification } from './components/PSDigiCert';

export const Profile: FC = () => {
    // global state
    const style = useStyle();
    const { theme } = useTheme();
    const unsavedChangesDialog = useUnsavedChangesDialog();
    const { secureNavigate, setNavigationLock } = useSecureNavigate();
    const { subMenu } = useParams<{ subMenu: string }>();
    const format = useFormat();
    const {
        user,
        userData: prevData,
        put,
        reloadUserData,
        userAgencies,
        mfaInfo,
        getDataIndex,
    } = useFireBase();
    // local state
    const [curData, setCurData] = useState(
        new MUserData({ dateOfBirth: Date.now() - year * 18 }),
    );
    const [curDocs, setCurDocs] = useState<MUserIdentDoc[]>([]);
    const reviewedAndActiveDocs = useMemo(
        () => curDocs.filter((d) => d.reviewed && d.expiresOn > Date.now()),
        [curDocs],
    );

    const [curValidity, setCurValidity] = useState('');
    /**
     * memoized value if any content differs from content initial loadet
     */
    const unsavedChanges = useMemo(() => {
        if (!curData) {
            return false;
        }
        /**
         * prev with to be sorted address
         */
        const prev = prevData || ({ address: {}, billingAddress: {} } as any);
        prev.address = sortObjectKeys(prev.address);
        prev.billingAddress = sortObjectKeys(prev.billingAddress);
        /**
         * current with to be sorted address
         */
        const current = curData || ({} as any);
        current.address = sortObjectKeys(current.address);
        current.billingAddress = sortObjectKeys(current.billingAddress);
        /**
         * compare sorted object for differences
         */
        const strcmp = JSON.stringify(prev) !== JSON.stringify(current);
        /**
         * return conditions for unsaved changes
         */
        return strcmp;
    }, [curData, prevData]);
    /**
     * handle changes
     */
    const onChange = useCallback((next: Partial<MUserData>) => {
        setCurData((prev) => {
            return { ...prev, ...next };
        });
    }, []);
    /**
     * navigation lock. ask for discard before navigation
     */
    const navigationLock = useCallback(async () => {
        const locking = unsavedChanges && !(await unsavedChangesDialog());
        if (locking) {
            return true;
        } else {
            setCurData(prevData ? prevData : new MUserData());
            return false;
        }
    }, [unsavedChanges, unsavedChangesDialog]);
    /**
     * effect to load id docs
     */
    useEffect(() => {
        if (!curData.documentId) {
            return;
        }
        getDataIndex(
            `${ECollections.users}/${curData.documentId}/${ECollections.identDocuments}`,
        ).then((res) => {
            setCurDocs((res as any[]).map((r) => new MUserIdentDoc(r)));
        });
    }, [curData]);
    /**
     * register lock as current navigationlock
     */
    useEffect(() => setNavigationLock(navigationLock), [navigationLock]);
    /**
     * handle save
     */
    const handleSave = useCallback(async () => {
        if (!user) {
            return console.error('unexpected access to this function');
        }

        const parsedUd = new MUserData({
            ...curData,
        });
        parsedUd.employmentHistory.sort((a, b) => a.from - b.from);
        parsedUd.educationHistory.sort((a, b) => a.from - b.from);
        await put(ECollections.users, user.uid, parsedUd);
        reloadUserData();

        secureNavigate('/profile', { force: true });

        return true;
    }, [user, curData]);
    /**
     * effect to set data to prevData
     */
    useEffect(() => {
        if (prevData) {
            setCurData(prevData);
        }
    }, [prevData]);
    /**
     * function to render save button
     * @returns save button mini-component
     */
    const saveButton = useMemo(() => {
        const saveButton = () => {
            return (
                <View style={style.verticalPadded}>
                    <View style={[style.verticalPadded, style.horizontalSplit]}>
                        <View />
                        <View>
                            {unsavedChanges && (
                                <CText style={style.warning}>
                                    {format(generalMessages.unsavedChanges)}
                                </CText>
                            )}
                            <CButton
                                title={format(actionMessages.save)}
                                disabled={!!curValidity}
                                disableOnClick
                                onPress={handleSave}
                            />
                        </View>
                    </View>
                </View>
            );
        };
        return saveButton;
    }, [handleSave, curValidity, unsavedChanges]);
    /**
     * render
     */
    return (
        <ProfileContext.Provider
            value={{
                curData: curData,
                curUserData: curData,
                prevData: prevData,
                prevUserData: prevData,
                onChange: onChange,
                onChangeUserData: onChange,
                onValidityChange: setCurValidity,
            }}
        >
            <ScrollProvider style={style.paddedScrollableMainView}>
                <View style={[style.headlineCard]}>
                    <View style={[style.horizontalSplit, { width: '100%' }]}>
                        <CText
                            bold
                            headline
                            message={format(generalMessages.profile)}
                        />
                        <View style={{ alignItems: 'flex-end' }}>
                            <CText message={profileMessages.supportId} />
                            <TouchableView
                                onPress={() =>
                                    Clipboard.setString(prevData.documentId)
                                }
                            >
                                <CText
                                    selectable
                                    message={prevData.documentId}
                                />
                            </TouchableView>
                            {!!userAgencies.length && (
                                <>
                                    <CText message={profileMessages.agencyId} />
                                    <TouchableView
                                        onPress={() =>
                                            Clipboard.setString(
                                                userAgencies[0].documentId,
                                            )
                                        }
                                    >
                                        <CText
                                            selectable
                                            message={userAgencies[0].documentId}
                                        />
                                    </TouchableView>
                                </>
                            )}
                        </View>
                    </View>
                </View>
                <ExpandableCard
                    head={
                        <View style={[style.horizontalSplit, { flex: 1 }]}>
                            <CText bold headline>
                                {format(profileMessages.basic)}
                            </CText>

                            <CIcon icon={'check'} tint={theme.successColor} />
                        </View>
                    }
                    body={
                        <>
                            <ProfileSectionBasic />
                            {saveButton()}
                        </>
                    }
                    customExpand={subMenu === 'basic'}
                    customOnClick={async (is) => {
                        secureNavigate(is ? '/profile' : '/profile/basic');
                    }}
                    preventHover
                />
                {(curData.type === EUserType.user ||
                    curData.type === EUserType.talent) && (
                    <ExpandableCard
                        head={
                            <View style={[style.horizontalSplit, { flex: 1 }]}>
                                <CText bold headline>
                                    {format(profileMessages.personal)}
                                </CText>
                                {curData.educations.length ? (
                                    <CIcon
                                        icon={'check'}
                                        tint={theme.successColor}
                                    />
                                ) : (
                                    <CIcon
                                        icon={'close'}
                                        tint={theme.errorColor}
                                    />
                                )}
                            </View>
                        }
                        body={
                            <>
                                <ProfileSectionProfessional />
                                {saveButton()}
                            </>
                        }
                        customExpand={subMenu === 'personal'}
                        customOnClick={async (is) => {
                            secureNavigate(
                                is ? '/profile' : '/profile/personal',
                            );
                        }}
                        preventHover
                    />
                )}
                {(curData.type === EUserType.user ||
                    curData.type === EUserType.talent) && (
                    <ExpandableCard
                        head={
                            <View style={[style.horizontalSplit, { flex: 1 }]}>
                                <CText bold headline>
                                    {format(profProfileMessages.languages)}
                                </CText>
                                <CIcon
                                    icon={'check'}
                                    tint={theme.successColor}
                                />
                            </View>
                        }
                        body={
                            <>
                                <ProfessionalLanguage />
                                {saveButton()}
                            </>
                        }
                        customExpand={subMenu === 'language'}
                        customOnClick={async (is) => {
                            secureNavigate(
                                is ? '/profile' : '/profile/language',
                            );
                        }}
                        preventHover
                    />
                )}
                {/* <ExpandableCard
                head={
                    <View style={[style.horizontalSplit, { flex: 1 }]}>
                        <CText bold headline>
                            {format(profileMessages.interest)}
                        </CText>

                        <CIcon
                            icon={'check'}
                            tint={theme.successColor }}
                        />
                    </View>
                }
                body={
                    <>
                        <ProfileSectionEmployment
                            curData={curData}prevData={prevData}
                            onChange={onChange}
                            onValidityChange={setCurValidity}
                        />
                        {saveButton()}
                    </>
                }
                customExpand={subMenu === 'employment'}
                customOnClick={async (is) => {
                    if (!unsavedChanges || (await askForDiscard())) {
                        setCurData(prevData ? prevData : new MUserData());
                        secureNavigate( (is ? '/profile' : '/profile/employment');
                    }
                }}
            /> */}
                {/* <ExpandableCard
                head={
                    <View style={[style.horizontalSplit, { flex: 1 }]}>
                        <CText bold headline>
                            {format(profileMessages.paymentInterval)}
                        </CText>

                        <CIcon
                            icon={'check'}
                            tint={theme.successColor }}
                        />
                    </View>
                }
                body={
                    <>
                        <ProfileSectionPayment
                            curData={curData}prevData={prevData}
                            onChange={onChange}
                            onValidityChange={setCurValidity}
                        />
                        {saveButton()}
                    </>
                }
                customExpand={subMenu === 'payment'}
                customOnClick={async (is) => {
                    if (!unsavedChanges || (await askForDiscard())) {
                        setCurData(prevData ? prevData : new MUserData());
                        secureNavigate( (is ? '/profile' : '/profile/payment');
                    }
                }}
            /> */}
                {curData.type === EUserType.user && (
                    <ExpandableCard
                        head={
                            <View style={[style.horizontalSplit, { flex: 1 }]}>
                                <CText bold headline>
                                    {format(profileMessages.addressInfo)}
                                </CText>
                                {curData.address.city &&
                                curData.address.country &&
                                curData.address.number &&
                                curData.address.street &&
                                curData.address.zipCode ? (
                                    <CIcon
                                        icon={'check'}
                                        tint={theme.successColor}
                                    />
                                ) : (
                                    <CIcon
                                        icon={'close'}
                                        tint={theme.errorColor}
                                    />
                                )}
                            </View>
                        }
                        body={
                            <>
                                <ProfileSectionAddress />
                                {saveButton()}
                            </>
                        }
                        customExpand={subMenu === 'address'}
                        customOnClick={async (is) => {
                            secureNavigate(
                                is ? '/profile' : '/profile/address',
                            );
                        }}
                        preventHover
                    />
                )}
                {curData.type === EUserType.user && (
                    <ExpandableCard
                        head={
                            <View style={[style.horizontalSplit, { flex: 1 }]}>
                                <CText bold headline>
                                    {format(profileMessages.bankInfo)}
                                </CText>
                                {curData.bankDetails.IBAN &&
                                curData.bankDetails.BIC &&
                                curData.bankDetails.accountHolderName ? (
                                    <CIcon
                                        icon={'check'}
                                        tint={theme.successColor}
                                    />
                                ) : (
                                    <CIcon
                                        icon={'close'}
                                        tint={theme.errorColor}
                                    />
                                )}
                            </View>
                        }
                        body={
                            <>
                                <ProfileSectionBank />
                                {saveButton()}
                            </>
                        }
                        customExpand={subMenu === 'bank'}
                        customOnClick={async (is) => {
                            secureNavigate(is ? '/profile' : '/profile/bank');
                        }}
                        preventHover
                    />
                )}
                {curData.type == EUserType.user && (
                    <ExpandableCard
                        head={
                            <View style={[style.horizontalSplit, { flex: 1 }]}>
                                <CText bold headline>
                                    {format(profileMessages.documents)}
                                </CText>
                            </View>
                        }
                        body={
                            <>
                                <ProfileSectionDocuments />
                                {saveButton()}
                            </>
                        }
                        customExpand={subMenu === 'documents'}
                        customOnClick={async (is) => {
                            secureNavigate(
                                is ? '/profile' : '/profile/documents',
                            );
                        }}
                        preventHover
                    />
                )}
                {curData.type == EUserType.talent && curData.talentId && (
                    <PSTalentDocuments />
                )}
                {curData.type === EUserType.user && (
                    <ExpandableCard
                        head={
                            <View style={[style.horizontalSplit, { flex: 1 }]}>
                                <CText bold headline>
                                    {format(profileMessages.availabilities)}
                                </CText>
                            </View>
                        }
                        body={
                            <>
                                <ProfileSectionAvailabilities />
                                <View style={style.horizontalSplit}>
                                    <View />
                                    <View
                                        style={[
                                            style.horizontal,
                                            style.centeredItems,
                                        ]}
                                    >
                                        <CButton
                                            onPress={() => {
                                                secureNavigate('/calendar');
                                            }}
                                            title={format(
                                                profileMessages.openCalendar,
                                            )}
                                        />
                                        {saveButton()}
                                    </View>
                                </View>
                            </>
                        }
                        customExpand={subMenu === 'availabilities'}
                        customOnClick={async (is) => {
                            secureNavigate(
                                is ? '/profile' : '/profile/availabilities',
                            );
                        }}
                        preventHover
                    />
                )}
                {curData.type === EUserType.employer && (
                    <ExpandableCard
                        head={
                            <View style={[style.horizontalSplit, { flex: 1 }]}>
                                <CText bold headline>
                                    {format(profileMessages.workplaces)}
                                </CText>
                            </View>
                        }
                        body={<ProfileSectionWorkplace />}
                        customExpand={subMenu === 'workplaces'}
                        customOnClick={async (is) => {
                            secureNavigate(
                                is ? '/profile' : '/profile/workplaces',
                            );
                        }}
                        preventHover
                    />
                )}
                {curData.type === EUserType.agency && (
                    <ExpandableCard
                        head={
                            <View style={[style.horizontalSplit, { flex: 1 }]}>
                                <CText bold headline>
                                    {format(profileMessages.agencies)}
                                </CText>
                            </View>
                        }
                        body={<ProfileSectionAgency />}
                        customExpand={subMenu === 'workplaces'}
                        customOnClick={async (is) => {
                            secureNavigate(
                                is ? '/profile' : '/profile/workplaces',
                            );
                        }}
                        preventHover
                    />
                )}
                <ExpandableCard
                    head={
                        <View style={[style.horizontalSplit, { flex: 1 }]}>
                            <CText bold headline>
                                {format(profileMessages.mfa)}
                            </CText>
                            {mfaInfo ? (
                                <CIcon
                                    icon={'check'}
                                    tint={theme.successColor}
                                />
                            ) : (
                                <CIcon icon={'close'} tint={theme.errorColor} />
                            )}
                        </View>
                    }
                    body={
                        <>
                            <ProfileSectionMultiFactor />
                        </>
                    }
                    customExpand={subMenu === 'mfa'}
                    customOnClick={async (is) => {
                        secureNavigate(is ? '/profile' : '/profile/mfa');
                    }}
                    preventHover
                    style={
                        !!(!mfaInfo || !reviewedAndActiveDocs.length) &&
                        style.errorBorder
                    }
                />
                <ExpandableCard
                    head={
                        <View style={[style.horizontalSplit, { flex: 1 }]}>
                            <CText bold headline>
                                {format(profileMessages.digicert)}
                            </CText>
                            {mfaInfo && reviewedAndActiveDocs.length ? (
                                <CIcon
                                    icon={'check'}
                                    tint={theme.successColor}
                                />
                            ) : (
                                <CIcon icon={'close'} tint={theme.errorColor} />
                            )}
                        </View>
                    }
                    body={
                        <>
                            <ProfileSectionDigitalCertification />
                        </>
                    }
                    customExpand={subMenu === 'digicert'}
                    customOnClick={async (is) => {
                        secureNavigate(is ? '/profile' : '/profile/digicert');
                    }}
                    preventHover
                    style={
                        !!(!mfaInfo || !reviewedAndActiveDocs.length) &&
                        style.errorBorder
                    }
                />
                <ExpandableCard
                    head={
                        <View style={[style.horizontalSplit, { flex: 1 }]}>
                            <CText bold headline>
                                {format(profileMessages.accountActions)}
                            </CText>
                        </View>
                    }
                    body={
                        <>
                            <ProfileSectionAccountActions />
                            {saveButton()}
                        </>
                    }
                    customExpand={subMenu === 'actions'}
                    customOnClick={async (is) => {
                        secureNavigate(is ? '/profile' : '/profile/actions');
                    }}
                    preventHover
                />
            </ScrollProvider>
        </ProfileContext.Provider>
    );
};
