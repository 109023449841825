import React, { FC } from 'react';
import { CText } from '../../../../components';
import { useStyle } from '../../../../utilities/styles';
import { View } from 'react-native';
import { MJob } from '../../../../models';
import { jobMessages } from '../../job.messages';
import { Rates } from '../../../../components/Wage/Rates';

interface IJobWageProps {
    onChange: (next: Partial<MJob>) => void;
    curData: MJob;
    prevData?: MJob;
    onboarding?: boolean;
    save?: () => void;
}

export const JobWage: FC<IJobWageProps> = ({ curData, onChange }) => {
    const style = useStyle();

    return (
        <>
            <View style={style.verticalPadded}>
                <CText secondaryHeadline message={jobMessages.rate} />
                <CText message={jobMessages.rateHint} />
                <Rates
                    curData={curData.wage}
                    onChange={(wage) => onChange({ wage })}
                />
            </View>
        </>
    );
};
