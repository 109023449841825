import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Platform, View } from 'react-native';
import { CCard, CText, CIcon, CButton } from '../../../../components';
import { ECollections, ETimesheetType, EUserType } from '../../../../enums';
import { MFile, MTimesheet } from '../../../../models';
import {
    actionMessages,
    generalMessages,
    monthMessages,
} from '../../../../utilities/messages';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { useSecureNavigate } from '../../../../utilities/routing';
import { useFireBase } from '../../../../utilities/firebase';
import { useLock } from '../../../../utilities/hooks';
import { timesheetMessages } from '../../../Timesheet/timesheet.messages';

export const TimesheetRow: FC<{ sheet: MTimesheet }> = ({ sheet }) => {
    const style = useStyle();
    const { theme } = useTheme();
    const { secureNavigate } = useSecureNavigate();
    const { getDataById, callFunction, getFile } = useFireBase();
    const { lock } = useLock();
    const [file, setFile] = useState<MFile>();
    /**
     * memoize check if it is signed by all parties
     */
    const isSigned = useMemo(
        () =>
            !!file &&
            file?.signaturePositions.length &&
            !file?.signaturePositions.find((sP) => !sP.signed),
        [file],
    );
    /**
     * memoized check if only employee signed
     */
    const employeeSigned = useMemo(
        () =>
            !!file &&
            file?.signaturePositions.length &&
            !file.signaturePositions.filter(
                (sP) => sP.type === EUserType.user && !sP.signed,
            ).length,
        [file],
    );
    /**
     * callback to handle download of file (pre requests file access)
     */
    const handleDownload = useCallback(async () => {
        if (!file) return;
        const unlock = lock();
        await callFunction('requestFileAccess', {
            fileId: file.documentId,
            contractId: sheet.documentId,
        });
        unlock();
        getFile(file.path).then((res) => {
            const fns = file.path.split('.');
            const type = fns[fns.length - 1];
            // @ts-ignore
            const blob = new Blob([res], {
                type: type === 'pdf' ? 'application/pdf' : 'image/jpeg',
            });
            // @ts-ignore
            window.open(URL.createObjectURL(blob));
        });
    }, [file]);
    /**
     * effect to load file
     */
    useEffect(() => {
        if (!sheet.fileId) return;

        getDataById(
            `${ECollections.contracts}/${sheet.contractId}/${ECollections.files}`,
            sheet.fileId,
        ).then((res) => {
            setFile(new MFile(res));
        });
    }, [sheet]);
    /**
     * render
     */
    return (
        <CCard
            key={sheet.documentId}
            style={style.horizontalSplit}
            hoverEffects
            onPress={() =>
                secureNavigate(
                    `/contract/${sheet.contractId}/timesheet/${sheet.documentId}`,
                )
            }
            embedded
        >
            <View>
                <CText
                    message={file ? file.name : timesheetMessages.timesheet}
                    secondaryHeadline
                />
                <View style={[style.horizontal, style.centeredItems]}>
                    {sheet.month !== undefined && (
                        <View style={style.horizontal}>
                            <CText message={generalMessages.month} />
                            <CText
                                style={style.horizontalPadded}
                                message={
                                    monthMessages[
                                        Object.keys(monthMessages).filter(
                                            (k) => !k.includes('Short'),
                                        )[
                                            sheet.month
                                        ] as keyof typeof monthMessages
                                    ]
                                }
                            />
                        </View>
                    )}
                    {sheet.week !== undefined &&
                        sheet.type === ETimesheetType.weekly && (
                            <View style={style.horizontal}>
                                <CText message={generalMessages.week} />
                                <CText
                                    style={style.horizontalPadded}
                                    message={`${sheet.week}`}
                                />
                            </View>
                        )}
                </View>
                {/* <View style={style.horizontal}>
                    <CText message={timesheetMessages.interval} />
                    <CText
                        style={style.horizontalPadded}
                        message={
                            timesheetMessages[
                                sheet.type === ETimesheetType.montly
                                    ? 'intervalMonthly'
                                    : sheet.type === ETimesheetType.weekly
                                    ? 'intervalWeekly'
                                    : 'intervalUndefined'
                            ]
                        }
                    />
                </View> */}
                <View style={[style.horizontal]}>
                    <CIcon
                        icon="calendar_outline"
                        size={16}
                        tint={theme.textSecondaryColor}
                    />
                    <CText
                        style={style.horizontalPadded}
                        message={generalMessages.createdOn}
                    />
                    <CText
                        message={new Date(
                            sheet.createdOn || Date.now(),
                        ).toLocaleDateString('de')}
                    />
                </View>
                {!!sheet.primarySigneeMail && !!sheet.exposedOn && (
                    <View style={style.horizontal}>
                        <CText message={timesheetMessages.exposedOn} />
                        <CText
                            style={style.leftPadded}
                            message={new Date(
                                sheet.exposedOn,
                            ).toLocaleDateString('de')}
                        />
                        <CText
                            message={timesheetMessages.exposedToTalent}
                            style={style.leftPadded}
                        />
                        <CText
                            message={sheet.primarySigneeMail}
                            style={style.leftPadded}
                        />
                    </View>
                )}
                {!!sheet.primarySigneeMail && !!sheet.fileCreatedOn && (
                    <View style={style.horizontal}>
                        <CText message={timesheetMessages.timesheetFilledOn} />
                        <CText
                            style={style.leftPadded}
                            message={new Date(
                                sheet.fileCreatedOn,
                            ).toLocaleDateString('de')}
                        />
                        {sheet.secondarySigneeMail && (
                            <View style={style.horizontal}>
                                <CText
                                    style={style.horizontalPadded}
                                    message={timesheetMessages.secondaryMail}
                                />
                                <CText message={sheet.secondarySigneeMail} />
                            </View>
                        )}
                    </View>
                )}
            </View>
            <View style={[style.centeredItems, style.horizontal]}>
                {!!file && (
                    <>
                        {/* SHOW SINGULAR CHECK IF ONLY FIRST PARTY SIGNED */}
                        {employeeSigned && !isSigned && (
                            <View
                                style={[
                                    style.centeredItems,
                                    style.centeredContent,
                                    { marginHorizontal: 25, width: 0 },
                                ]}
                            >
                                <CIcon icon="check" />
                            </View>
                        )}
                        {/* SHOW DOUBLE CHECK IF FILE (envelope containing this file) IS SIGNED */}
                        {isSigned && (
                            <View
                                style={[
                                    style.centeredItems,
                                    style.centeredContent,
                                    { marginHorizontal: 25, width: 0 },
                                ]}
                            >
                                <CIcon
                                    icon="checkDouble"
                                    tint={theme.successColor}
                                />
                            </View>
                        )}
                        {Platform.OS === 'web' && (
                            <CButton
                                onPress={handleDownload}
                                icon="download"
                                mouseOverTitle={actionMessages.download}
                                minor
                                transparent
                                style={{
                                    marginHorizontal: 0,
                                    paddingHorizontal: 5,
                                    width: 'auto',
                                }}
                            />
                        )}
                    </>
                )}
                <CIcon icon={'chevronRight'} />
            </View>
        </CCard>
    );
};
