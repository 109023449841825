import React, { FC, useMemo } from 'react';

import { useStyle } from '../../../utilities/styles';
import { CText, Spinner, TouchableView } from '../../../components';
import { ChatListRow } from './components/ChatListRow/ChatListRow';
import { ChatView } from '../View/ChatView';
import { useParams } from '../../../utilities/routing';
import { useDimensions } from '../../../utilities/hooks/useDimensions';
import { useFormat } from '../../../utilities/intl';
import { chatMessages } from '../Chat.messages';
import { useChatListState } from './useChatListState';
import { CImage } from '../../../components/elements/CImage';
import { MOBILEWIDTH } from '../../../utilities/constants';
import { Platform, ScrollView, View } from 'react-native';
/**
 * chat list component to view chat list with open chats on larger screens
 * and just the selected chat or chat list on smaller
 * @returns
 */
export const ChatList: FC = () => {
    // global state
    const format = useFormat();
    const style = useStyle();
    const { id } = useParams<{ id: string }>();
    const { width, mainHeight } = useDimensions();
    // local state
    const {
        loading,
        chats,
        orderedChats,
        addToOrder,
        closedChats,
        showClosed,
        setShowClosed,
    } = useChatListState();
    /**
     * memoized check if head is shown or not
     */
    const showHead = useMemo(() => {
        return width < MOBILEWIDTH;
    }, [width]);
    /**
     * return spinner during load
     */
    if (loading) {
        return <Spinner />;
    }
    /**
     * return standalone chat if small view
     */
    if (width < 1000 && id) {
        return (
            <View style={[style.paddedMainView]}>
                <ChatView />
            </View>
        );
    }
    /**
     * render if no chat &| large view
     */
    return (
        <View
            style={[
                style.paddedMainView,
                {
                    height: mainHeight,
                    paddingBottom: Platform.OS === 'ios' ? 40 : 0,
                },
            ]}
        >
            {(!!chats.length || !!closedChats.length) && (
                <View
                    style={[
                        style.card,
                        style.horizontal,
                        {
                            height: mainHeight - 60,
                        },
                    ]}
                >
                    {!showClosed && (
                        <View style={[{ height: mainHeight - 80, flex: 1 }]}>
                            <View style={[style.centeredContent]}>
                                {!chats.length && (
                                    <>
                                        <View
                                            style={[
                                                style.horizontalSpaced,
                                                style.verticalPadded,
                                            ]}
                                        >
                                            <CText
                                                bold
                                                centered
                                                headline
                                                message={format(
                                                    chatMessages.noOpenChats,
                                                )}
                                            />
                                        </View>
                                    </>
                                )}
                            </View>
                            <ScrollView>
                                {orderedChats.map((chat) => (
                                    <ChatListRow
                                        key={chat.documentId}
                                        chat={chat}
                                        setLastMessageCreatedOn={(createdOn) =>
                                            addToOrder({
                                                createdOn,
                                                chat: chat.documentId,
                                            })
                                        }
                                    />
                                ))}
                            </ScrollView>
                            {!!closedChats.length && (
                                <TouchableView
                                    onPress={() => setShowClosed(!showClosed)}
                                    style={style.centeredItems}
                                >
                                    <CText
                                        message={chatMessages.showClosedChats}
                                        style={style.accent}
                                    />
                                </TouchableView>
                            )}
                        </View>
                    )}
                    {!!showClosed && (
                        <View style={{ height: mainHeight - 80, flex: 1 }}>
                            {!!closedChats.length && (
                                <TouchableView
                                    onPress={() => setShowClosed(!showClosed)}
                                    style={style.centeredItems}
                                >
                                    <CText
                                        message={chatMessages.showOpenChats}
                                        style={style.accent}
                                    />
                                </TouchableView>
                            )}
                            <ScrollView>
                                {closedChats.map((chat) => (
                                    <ChatListRow
                                        key={chat.documentId}
                                        chat={chat}
                                        setLastMessageCreatedOn={(createdOn) =>
                                            addToOrder({
                                                createdOn,
                                                chat: chat.documentId,
                                            })
                                        }
                                    />
                                ))}
                            </ScrollView>
                        </View>
                    )}
                    {width >= 1000 && (
                        <View
                            style={{
                                flex: 2,
                                height: mainHeight - 80,
                            }}
                        >
                            {!!id ? (
                                <ChatView />
                            ) : (
                                <View
                                    style={[
                                        style.centeredItems,
                                        { flex: 1, justifyContent: 'center' },
                                    ]}
                                >
                                    <CImage image={'notifications'} />
                                    <CText style={style.verticalPadded}>
                                        {format(chatMessages.noChatSelected)}
                                    </CText>
                                </View>
                            )}
                        </View>
                    )}
                </View>
            )}
            {!chats.length && !closedChats.length && (
                <View style={style.card}>
                    <CImage image="notifications" />
                    <View
                        style={[style.horizontalSpaced, style.verticalPadded]}
                    >
                        <CText
                            bold
                            centered
                            headline
                            message={format(chatMessages.noOpenChats)}
                        />
                    </View>
                </View>
            )}
        </View>
    );
};
