import React, { FC, useMemo } from 'react';
import { Image } from 'react-native';
import { icons } from '../../../assets';
import { useTheme } from '../../../utilities/styles';
import { ICIconProps } from './ICIconProps';

export const CIcon: FC<ICIconProps> = (props) => {
    const { theme } = useTheme();
    /**
     * memoized width x height
     */
    const [width, height] = useMemo(() => {
        const size = props.fontSize || 24;
        return [
            props.width || props.size || size,
            props.height || props.size || size,
        ];
    }, [props]);
    /**
     * memoized icon data
     */
    const icon = useMemo(
        () =>
            icons[props.icon as keyof typeof icons] ||
            icons['helpCircle_outline'],
        [props],
    );
    /**
     * render
     */
    return (
        <Image
            source={icon as any}
            style={[
                !props.colored && { tintColor: theme.textMainColor },
                { width, height },
                !!props.tint && { tintColor: props.tint },
                props.style as any,
            ]}
        />
    );
};
