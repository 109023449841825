import React, { FC, useMemo } from 'react';
import Clipboard from '@react-native-clipboard/clipboard';
import { View } from 'react-native';
import { useStyle } from '../../../utilities/styles';
import { useFireBase } from '../../../utilities/firebase';
import { CText, TouchableView, ProfilePicture } from '../../../components';
import {
    doctorTitleMessages,
    generalMessages,
} from '../../../utilities/messages';
import { profileMessages } from '../../Profile/Profile.messages';
import { useNavigate } from '../../../utilities/routing';
import { useFormat } from '../../../utilities/intl';

export const Welcome: FC = () => {
    const style = useStyle();
    const navigate = useNavigate();
    const format = useFormat();
    const { userData, userAgencies } = useFireBase();
    /**
     * memoized welcome string
     */
    const welcomeString = useMemo(() => {
        let text = `${format(generalMessages.welcome)}`;
        if (userData.title) {
            if (doctorTitleMessages[userData.title]) {
                text += ` ${format(doctorTitleMessages[userData.title])}`;
            }
        }
        text += ` ${userData.firstName} ${userData.lastName}`;
        return text;
    }, [userData]);
    /**
     * render
     */
    return (
        <View
            style={[
                style.headlineCard,
                style.horizontalSplit,
                { alignItems: 'flex-start' },
            ]}
        >
            <View
                style={{
                    flex: 1,
                }}
            >
                <CText bold headline message={welcomeString} />
                <CText message={generalMessages.checkOut} />
            </View>
            {!!userAgencies.length && (
                <View
                    style={{
                        alignItems: 'flex-end',
                        paddingRight: 10,
                    }}
                >
                    <CText message={profileMessages.agencyId} />
                    <TouchableView
                        onPress={() =>
                            Clipboard.setString(userAgencies[0].documentId)
                        }
                    >
                        <CText
                            selectable
                            message={userAgencies[0].documentId}
                        />
                    </TouchableView>
                </View>
            )}
            <ProfilePicture
                data={userData}
                onPress={() => navigate('/profile/basic')}
            />
        </View>
    );
};
