import React, { FC, useMemo } from 'react';
import { Platform, SafeAreaView, View } from 'react-native';
import { CImage } from '../../components/elements/CImage';
import { useStyle } from '../styles';
import { IDefaultProps } from '../IDefaultProps';
import { TouchableView } from '../../components';
import { useLocation, useNavigate } from 'react-router-native';
import { ScrollProvider } from '../contexts';
import { useDimensions } from '../hooks';
interface IStandaloneWrapper extends IDefaultProps {
    nativeId?: string;
}
/**
 * Wrapper for components that are accessible outside of navigation wrapper
 * (outside of logged in)
 * @param param0
 * @returns
 */
export const StandaloneWrapper: FC<IStandaloneWrapper> = ({
    children,
    nativeId,
}) => {
    const style = useStyle();
    const navigate = useNavigate();
    const location = useLocation();
    const { wrapperHeight } = useDimensions();
    /**
     * render
     */
    return (
        <SafeAreaView style={[style.outsideContainer, { flex: 1 }]}>
            <View style={{ height: wrapperHeight }}>
                <ScrollProvider style={style.paddedScrollableMainView}>
                    <View style={style.verticalPadded} />
                    <TouchableView
                        style={style.verticalPadded}
                        onPress={
                            location.pathname !== '/'
                                ? () => navigate('/')
                                : undefined
                        }
                    >
                        <CImage image="logo" maxWidth={350} maxHeight={70} />
                    </TouchableView>
                    <View style={style.insideContainer} nativeID={nativeId}>
                        {children}
                    </View>
                </ScrollProvider>
            </View>
        </SafeAreaView>
    );
};
