import React, { FC, useMemo } from 'react';
import { CTable } from '../../../../components';
import { MUserData } from '../../../../models';
import { profileMessages } from '../../../Profile/Profile.messages';
import { useFormat } from '../../../../utilities/intl';
import { userMessages } from '../../user.messages';
/**
 * table that contains basic account information
 * @param param0
 * @returns
 */
export const PersonalUserTable: FC<{
    user: MUserData;
    verificator: MUserData;
}> = ({ user, verificator }) => {
    const format = useFormat();
    /**
     * key/values for current user lower table (personal infos)
     */
    const tableRows = useMemo(() => {
        const rows: { key: string; value: string }[] = [];

        rows.push({
            key: 'Identification',
            value: user.documentId,
        });
        rows.push({
            key: format(userMessages.mail),
            value: user.mail,
        });
        rows.push({
            key: format(profileMessages.phone),
            value: user.phone,
        });
        rows.push({
            key: format(profileMessages.address),
            value: `${user.address.street} ${user.address.number}, ${user.address.zipCode} ${user.address.city}`,
        });
        return rows;
    }, [user, verificator]);
    /**
     * key/values for more informations that can be expandet
     */
    const moreTableRows = useMemo(() => {
        const rows: { key: string; value: string }[] = [];
        rows.push({
            key: format(userMessages.createdOn),
            value: new Date(user.createdOn).toLocaleDateString('de'),
        });
        if (user.verified) {
            rows.push({
                key: format(userMessages.verifiedOn),
                value: new Date(user.verifiedOn).toLocaleDateString('de'),
            });
            rows.push({
                key: format(userMessages.verifiedFrom),
                value: verificator.firstName + ' ' + verificator.lastName,
            });
        }
        return rows;
    }, [user, verificator]);
    /**
     * render
     */
    return (
        <CTable
            headers={[{ key: 'key' }, { key: 'value' }]}
            hideHeaders
            rows={[...tableRows].map((entry) => {
                return {
                    values: [
                        {
                            key: 'key',
                            bold: true,
                            value: entry.key,
                        },
                        {
                            key: 'value',
                            value: entry.value,
                            flex: 3,
                        },
                    ],
                };
            })}
            moreRows={[...moreTableRows].map((entry) => {
                return {
                    values: [
                        {
                            key: 'key',
                            bold: true,
                            value: entry.key,
                        },
                        {
                            key: 'value',
                            value: entry.value,
                            flex: 3,
                        },
                    ],
                };
            })}
        />
    );
};
