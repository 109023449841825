import React, { FC, useEffect, useState } from 'react';
import { MShiftPlan } from '../../models';
import { CButton, CCard, CText } from '../../components';
import { useNavigate } from 'react-router-native';
import { actionMessages, generalMessages } from '../../utilities/messages';
import { useStyle } from '../../utilities/styles';
import { ScrollProvider } from '../../utilities/contexts';
import { useFireBase } from '../../utilities/firebase';
import { ECollections } from '../../enums';
import { View } from 'react-native';
import { shiftPlanMessages } from './shiftPlan.messages';
import { convertWeekAndYearToDate } from '../../utilities/functions';

export const ShiftPlanList: FC = () => {
    const navigate = useNavigate();
    const style = useStyle();
    const { getDataIndex, userWorkplaces } = useFireBase();
    const [plans, setPlans] = useState<MShiftPlan[]>([]);

    useEffect(() => {
        getDataIndex(ECollections.shiftPlans, {
            filter: [
                {
                    field: 'workplaceId',
                    // operator: 'in',
                    // value: userWorkplaces.map((w) => w.documentId),
                    value: userWorkplaces[0]?.documentId,
                },
            ],
            orderBy: 'createdOn',
            useCollectionGroup: true,
        }).then((r) => {
            setPlans((r as MShiftPlan[]).map((s) => new MShiftPlan(s)));
        });
    }, [userWorkplaces]);

    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <CCard>
                <View style={[style.horizontalSplit, { alignItems: 'center' }]}>
                    <CText bold headline message={generalMessages.shiftplan} />
                    <CButton
                        icon={'plus'}
                        title={actionMessages.create}
                        onPress={() => navigate('/shiftplan/create')}
                    />
                </View>
            </CCard>
            {plans.map((plan) => {
                const workplace = userWorkplaces.find(
                    (w) => w.documentId === plan.workplaceId,
                );
                return (
                    <CCard
                        key={plan.documentId}
                        onPress={() =>
                            navigate(
                                '/shiftplan/view/' +
                                    plan.workplaceId +
                                    '/' +
                                    plan.documentId,
                            )
                        }
                        hoverEffects
                    >
                        <View style={style.horizontalSplit}>
                            <View>
                                <CText message={workplace?.name} />
                                <CText
                                    secondaryHeadline
                                    message={
                                        convertWeekAndYearToDate(
                                            plan.year,
                                            plan.week,
                                        ).toLocaleDateString('de') +
                                        ' ' +
                                        plan.name
                                    }
                                />
                            </View>
                            {!!plan.shifts.find((s) => !s.worker) && (
                                <View style={style.centeredItems}>
                                    <CText
                                        message={shiftPlanMessages.incomplete}
                                    />
                                    <CButton
                                        smallest
                                        title={'Find a Talent'}
                                        onPress={() => {
                                            // TODO
                                        }}
                                    />
                                </View>
                            )}
                        </View>
                    </CCard>
                );
            })}
        </ScrollProvider>
    );
};
