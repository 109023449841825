import { EEnvironment, EUserType } from '../enums';

export class MHelp {
    public documentId = '';
    public title = '';
    public lang = '';
    public message = '';
    public url = '';
    public buttonText = '';
    public parent: string | null = null;
    public order = 0;

    public environment = EEnvironment.SITUS;
    public groups: EUserType[] = [];

    public editedOn = Date.now();
    public createdOn = Date.now();

    constructor(params: Partial<MHelp> = {}) {
        Object.assign(this, params);
    }
}
