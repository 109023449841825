import { EIdentificationType } from '../enums/EIdentificationType';
import { EIdentityDocument } from '../enums/EIdentityDocument';

export class MUserIdentDoc {
    public documentId = '';
    /**
     * track if doc is complete with valid automatic validated or validation requested pictures
     */
    public complete = false;
    /**
     * track if doc got reviewed or not
     */
    public reviewed = false;
    /**
     * track who reviewd the doc
     */
    public reviewedBy = '';
    /**
     * id of user
     */
    public uid = '';
    /**
     * alpha 2 country code
     */
    public documentIssuingCountry = '';
    /**
     * alpha 2 country code
     */
    public identificationIssuingCountry = '';
    public identificationType = EIdentificationType.NationalIdentityCard;
    public documentType = EIdentityDocument.IdentityCard;
    public documentIssuedBy = '';
    /**
     * date formated yyyy-mm-dd
     */
    public documentIssuedOn = '';
    /**
     * date formated yyyy-mm-dd
     */
    public documentExpiryDate = '';

    public serialNumber = '';
    public documentNumber = '';
    /**
     * path to front picture document
     */
    public front = '';
    public frontHash = '';
    /**
     * track if front picture is valid
     */
    public frontValid = false;
    /**
     * path to back picture document
     */
    public back = '';
    public backHash = '';
    /**
     * track if back picture is valid
     */
    public backValid = false;
    /**
     * path to user with doc pic
     */
    public userWithDoc = '';
    public userWithDocHash = '';
    /**
     * track if user picture is valid
     */
    public userPicValid = false;

    public expiresOn = 0;
    public createdOn = Date.now();
    public editedOn = Date.now();
    /**
     * hash of this object without itself
     */
    public hash = '';
    /**
     * K_REVISION ENV var of firebase cloud function
     */
    public kRevision?: string;

    constructor(params: Partial<MUserIdentDoc> = {}) {
        Object.assign(this, params);
    }
}
