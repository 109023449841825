import React, { FC, useCallback } from 'react';
import { View } from 'react-native';
import { MShiftWorker } from '../../../../models';
import {
    IShiftPlanComponentProps,
    IWorkerExtensionProps,
} from '../IShiftPlanComponentProps';
import {
    CButton,
    CCard,
    CPicker,
    CText,
    CTextInput,
} from '../../../../components';
import { actionMessages } from '../../../../utilities/messages';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { shiftPlanMessages } from '../../shiftPlan.messages';

interface WorkerSelectProps extends IShiftPlanComponentProps {
    extension?: FC<IWorkerExtensionProps>;
}

export const WorkerSelect: FC<WorkerSelectProps> = ({
    shiftPlan,
    extension,
    setPlan,
    setEdit,
    goForward,
    goBackward,
}) => {
    const style = useStyle();
    const { theme } = useTheme();

    const ExtensionComponent = extension;

    const onChangeWorker = useCallback(
        (next: Partial<MShiftWorker>, index: number) =>
            setPlan((prev) => {
                const nextWorkers = [...prev.workers];
                nextWorkers.splice(index, 1, {
                    ...nextWorkers[index],
                    ...next,
                });
                return { ...prev, workers: nextWorkers };
            }),
        [],
    );

    const removeWorker = useCallback(
        (index: number) =>
            setPlan((prev) => {
                const nextWorkers = [...prev.workers];
                nextWorkers.splice(index, 1);
                return { ...prev, workers: nextWorkers };
            }),
        [],
    );

    return (
        <View>
            <CCard>
                <CText
                    secondaryHeadline
                    message={shiftPlanMessages.configureWorkers}
                />
            </CCard>
            {!shiftPlan.workers.length && (
                <View style={style.centeredItems}>
                    <CText
                        secondaryHeadline
                        message={shiftPlanMessages.noWorkers}
                    />
                </View>
            )}
            {shiftPlan.workers.map((w, wIndex) => {
                return (
                    <CCard key={wIndex} style={style.verticalPadded}>
                        <View style={style.horizontal}>
                            <View style={style.flex1}>
                                <CTextInput
                                    label={shiftPlanMessages.workerTitle}
                                    value={w.name}
                                    onChangeText={(name) =>
                                        onChangeWorker({ name }, wIndex)
                                    }
                                    autoExtend
                                />
                                <CTextInput
                                    label={shiftPlanMessages.workerHoursPerWeek}
                                    type="numeric"
                                    value={`${w.hoursPerWeek}`}
                                    onChangeText={(t) =>
                                        !isNaN(+t) &&
                                        onChangeWorker(
                                            { hoursPerWeek: +t },
                                            wIndex,
                                        )
                                    }
                                />
                                <CPicker
                                    title={shiftPlanMessages.group}
                                    values={shiftPlan.groups.map((group) => ({
                                        value: group.id,
                                        label: group.name,
                                    }))}
                                    multiValues={w.groups}
                                    onChangeMulti={(next) =>
                                        onChangeWorker({ groups: next }, wIndex)
                                    }
                                />
                                {!!ExtensionComponent && (
                                    <ExtensionComponent
                                        {...{
                                            wIndex,
                                            shiftPlan,
                                            extension,
                                            setPlan,
                                            setEdit,
                                            goForward,
                                            goBackward,
                                        }}
                                    />
                                )}
                            </View>
                            <CButton
                                style={{ marginTop: 34 }}
                                onPress={() => removeWorker(wIndex)}
                                icon={'close'}
                                transparent
                                minor
                                iconColor={theme.errorColor}
                            />
                        </View>
                    </CCard>
                );
            })}
            <View style={style.verticalPadded}>
                <View
                    style={[
                        style.horizontal,
                        style.verticalPadded,
                        { justifyContent: 'center' },
                    ]}
                >
                    <CButton
                        icon="plus"
                        title={shiftPlanMessages.addWorker}
                        onPress={() =>
                            setPlan((prev) => ({
                                ...prev,
                                workers: [...prev.workers, new MShiftWorker()],
                            }))
                        }
                        minor
                    />
                </View>
                <View style={[style.horizontal, { justifyContent: 'center' }]}>
                    <CButton
                        title={actionMessages.back}
                        onPress={goBackward}
                        warning
                    />
                    <CButton
                        title={actionMessages.continue}
                        onPress={goForward}
                    />
                </View>
            </View>
        </View>
    );
};
