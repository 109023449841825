import { createIntl, createIntlCache } from '@formatjs/intl';
import 'intl';
import { useMemo } from 'react';
import { flattenObject } from '../functions/flattenObject';
import lang from '../../assets/translations';
import { ELanguage } from '../../enums';

export const useCustomFormat = (locale: ELanguage) => {
    const cache = useMemo(() => createIntlCache(), []);
    const intl = useMemo(() => {
        const messages = flattenObject(lang[locale]);
        return createIntl(
            {
                locale,
                messages,
            },
            cache,
        );
    }, [locale]);

    return intl.formatMessage;
};
