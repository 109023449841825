import { useCallback } from 'react';
import {
    EContractFiles,
    EGeneralFiles,
    EPureContractFiles,
} from '../../../../enums';
import { useDialog } from '../../../../utilities/dialog';
import { useFireBase } from '../../../../utilities/firebase';
import {
    filenameMessages,
    actionMessages,
} from '../../../../utilities/messages';
import { contractMessages } from '../../contract.messages';
import { useCopyFileToContract } from './useCopyFileToContract';

export const usePreContractFile = (
    contractId: string | undefined,
    setReload: (next: number) => void,
) => {
    const { callFunction, userData } = useFireBase();
    const dialog = useDialog();
    const copyFileToContract = useCopyFileToContract(contractId, setReload);

    const preContractFile = useCallback(async () => {
        let nextType: EContractFiles = EGeneralFiles.other;
        /**
         * execute copy instead precheck dialog.
         * ? if this returns true the user selected that he wanted to copy so return false to cancel file picking
         */
        if (await copyFileToContract()) return false;

        if (
            await dialog({
                title: contractMessages.selectFileType,
                message: contractMessages.selectFileTypeText,
                icon: 'info',
                pickerInputs: [
                    {
                        id: 'type',
                        title: contractMessages.fileType,
                        placeholder: contractMessages.fileTypePlaceholder,
                        values: [
                            ...[
                                ...Object.values(EPureContractFiles),
                                EGeneralFiles.other,
                            ].map((ecf) => {
                                return {
                                    label: filenameMessages[ecf],
                                    value: ecf,
                                };
                            }),
                        ],
                    },
                ],
                buttons: [
                    {
                        text: actionMessages.ok,
                        disabled: (inputs) =>
                            !inputs?.find((i) => i.id === 'type'),
                        onPress: (inputs) => {
                            const ioi = inputs?.find((i) => i.id === 'type');
                            if (ioi) {
                                nextType = ioi.value as EContractFiles;
                            }
                        },
                    },
                ],
                cancelButton: { text: actionMessages.cancel },
            })
        ) {
            return { nextType };
        }
    }, [dialog, userData, callFunction, setReload]);

    return preContractFile;
};
