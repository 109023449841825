import React, { FC, useCallback, useMemo } from 'react';
import { IShiftRowProps } from './ShiftRow';
import { useStyle, useTheme } from '../../../utilities/styles';
import { View } from 'react-native';
import {
    CText,
    CDatePicker,
    CButton,
    CTextInput,
    CSplitTimePicker,
    CPicker,
    CCard,
    CCheckBox,
} from '../../../components';
import { MFromTo } from '../../../models';
import { useFireBase } from '../../../utilities/firebase';
import { hour, minute, timeString } from '../../../utilities/functions';
import { timesheetMessages } from '../timesheet.messages';
import { useDialog } from '../../../utilities/dialog';
import { actionMessages } from '../../../utilities/messages';
import { ELeaveType, EShiftType } from '../../../enums';

export const ShiftRowEdit: FC<IShiftRowProps> = ({
    shift,
    onChange,
    onRemove,
    cellWidth,
    toggleEdit,
}) => {
    const style = useStyle();
    const dialog = useDialog();
    const { userAgencies } = useFireBase();
    const { theme } = useTheme();
    /**
     * check if this is a sick shift
     */
    const isSick = useMemo(() => {
        return shift.leaveType !== ELeaveType.none;
    }, [shift]);
    /**
     * shift day start
     * ? date where shift day starts for picker min
     */
    const dayStart = useMemo(
        () => new Date(+shift.year, +shift.month - 1, +shift.date, 0, 0),
        [shift],
    );
    /**
     * shift day end
     * ? date where shift day ends for picker max
     */
    const dayEnd = useMemo(
        () => new Date(+shift.year, +shift.month - 1, +shift.date, 24, 0),
        [shift],
    );
    /**
     * from date
     */
    const fromDate = useMemo(
        () =>
            new Date(
                +shift.year,
                +shift.month - 1,
                +shift.date,
                shift.fromHour,
                shift.fromMinute,
            ),
        [shift],
    );
    /**
     * to date
     */
    const toDate = useMemo(
        () =>
            new Date(
                +shift.year,
                +shift.month - 1,
                +shift.date,
                shift.toHour,
                shift.toMinute,
            ),
        [shift],
    );
    /**
     * callback to add a break
     */
    const addBreak = useCallback(async () => {
        if (!onChange) {
            return;
        }
        const bFrom = new Date((shift.from + shift.to) / 2);
        bFrom.setMinutes(Math.round(bFrom.getMinutes() / 5) * 5);
        const cBT = userAgencies.length
            ? bFrom.getTime() + userAgencies[0].breakLength * minute
            : bFrom.getTime() + hour;
        const bTo = new Date(cBT < shift.to ? cBT : shift.to);
        await dialog({
            title: timesheetMessages.addBreak,
            timeInputs: [
                {
                    id: 'from',
                    title: timesheetMessages.from,
                    upperLimit: shift.to,
                    lowerLimit: shift.from,
                    defaultValue: bFrom.getTime(),
                },
                {
                    id: 'to',
                    title: timesheetMessages.to,
                    upperLimit: shift.to,
                    lowerLimit: shift.from,
                    defaultValue: bTo.getTime(),
                },
            ],
            buttons: [
                {
                    text: timesheetMessages.addBreak,
                    onPress: (inputs) => {
                        const inputFrom = inputs?.find(
                            (i) => i.id === 'from',
                        )?.value;
                        const inputTo = inputs?.find(
                            (i) => i.id === 'to',
                        )?.value;
                        if (!inputFrom || !inputTo) {
                            return;
                        }
                        const inputFromDate = new Date(inputFrom);
                        const inputToDate = new Date(inputTo);
                        const fromHour = inputFromDate.getHours();
                        const fromMinute = inputFromDate.getMinutes();
                        const toHour = inputToDate.getHours();
                        const toMinute = inputToDate.getMinutes();
                        onChange({
                            breaks: [
                                ...shift.breaks,
                                new MFromTo({
                                    from: bFrom.getTime(),
                                    fromHour,
                                    fromMinute,
                                    to: bTo.getTime(),
                                    toHour,
                                    toMinute,
                                }),
                            ],
                        });
                    },
                },
            ],
            cancelButton: { text: actionMessages.cancel },
        });
    }, [onChange, shift]);
    /**
     * remove break callback
     */
    const removeBreak = useCallback(
        (index: number) => {
            if (!onChange) return;
            const nextBreaks = [...shift.breaks];
            nextBreaks.splice(index, 1);
            onChange({ breaks: nextBreaks });
        },
        [shift, onChange],
    );
    /**
     * callback to change from based on new date input
     */
    const onChangeFrom = useCallback(
        (e: Date) => {
            if (!onChange) return;
            onChange({
                from: e.getTime(),
                fromHour: e.getHours(),
                fromMinute: e.getMinutes(),
            });
        },
        [onChange],
    );
    /**
     * callback to change to based on new date input
     */
    const onChangeTo = useCallback(
        (e: Date) => {
            if (!onChange) return;
            onChange({
                to: e.getTime(),
                toHour: e.getHours(),
                toMinute: e.getMinutes(),
            });
        },
        [onChange],
    );
    /**
     * render
     */
    return (
        <CCard style={style.accentBorder}>
            <View style={[style.horizontalSplit, style.centeredItems]}>
                <View
                    style={[
                        style.horizontal,
                        style.centeredItems,
                        style.centeredContent,
                        { width: cellWidth, zIndex: 1 },
                    ]}
                >
                    <CDatePicker
                        value={
                            new Date(+shift.year, +shift.month - 1, +shift.date)
                        }
                        onChange={(d) => {
                            if (!onChange) return;
                            onChange({
                                year: `${d.getFullYear()}`,
                                month: `${d.getMonth() + 1}`,
                                date: `${d.getDate()}`,
                            });
                        }}
                    />
                </View>
                {!isSick && (
                    <>
                        <View
                            style={[style.centeredItems, { width: cellWidth }]}
                        >
                            <CSplitTimePicker
                                onChange={onChangeFrom}
                                value={fromDate}
                                minDate={dayStart.getTime()}
                                maxDate={toDate.getTime()}
                            />
                        </View>
                        <View
                            style={[
                                style.centeredContent,
                                style.centeredItems,
                                { width: cellWidth },
                            ]}
                        >
                            {shift.breaks.map((b, i) => (
                                <View key={i} style={style.horizontal}>
                                    <CText
                                        message={`${timeString(
                                            b.fromHour,
                                            b.fromMinute,
                                        )} - ${timeString(
                                            b.toHour,
                                            b.toMinute,
                                        )}`}
                                    />
                                    <CButton
                                        onPress={() => removeBreak(i)}
                                        icon="close"
                                        smallest
                                        transparent
                                        minor
                                        iconColor={theme.errorColor}
                                    />
                                </View>
                            ))}
                            <CButton
                                onPress={() => {
                                    addBreak();
                                }}
                                icon="plus"
                                smallest
                                transparent
                                minor
                                title={timesheetMessages.addBreak}
                            />
                        </View>
                        <View
                            style={[style.centeredItems, { width: cellWidth }]}
                        >
                            <CSplitTimePicker
                                onChange={onChangeTo}
                                value={toDate}
                                minDate={fromDate.getTime()}
                                maxDate={dayEnd.getTime()}
                            />
                        </View>
                    </>
                )}
            </View>
            {onChange && (
                <View style={[style.horizontal, style.centeredItems]}>
                    <View style={style.flex1}>
                        <CTextInput
                            autoExtend
                            value={shift.note}
                            onChangeText={(note) => onChange({ note })}
                            label={timesheetMessages.note}
                        />
                    </View>
                    <CCheckBox
                        checked={isSick}
                        onCheckedChanged={(c) =>
                            onChange({
                                leaveType: c
                                    ? ELeaveType.sick
                                    : ELeaveType.none,
                            })
                        }
                        title={timesheetMessages.didLeave}
                    />
                </View>
            )}
            {onChange && isSick && (
                <CPicker
                    title={timesheetMessages.leaveReason}
                    values={[
                        ELeaveType.none,
                        ELeaveType.sick,
                        ELeaveType.vacation,
                        ELeaveType.holidayLeave,
                        ELeaveType.unexcused,
                        ELeaveType.free,
                    ].map((v) => ({
                        label: timesheetMessages[v],
                        value: v,
                    }))}
                    value={
                        shift.leaveType !== ELeaveType.none
                            ? shift.leaveType
                            : undefined
                    }
                    placeholder={timesheetMessages.leaveReasonPlaceholder}
                    onChange={(v) => onChange({ leaveType: v })}
                />
            )}
            {onChange && !isSick && (
                <CPicker
                    title={timesheetMessages.shiftType}
                    values={Object.values(EShiftType).map((v) => ({
                        label: timesheetMessages[v],
                        value: v,
                    }))}
                    value={shift.type}
                    onChange={(v) => onChange({ type: v })}
                />
            )}
            <View style={style.horizontalSpaced}>
                {onRemove && (
                    <CButton
                        onPress={onRemove}
                        icon="close"
                        smallest
                        transparent
                        minor
                        iconColor={theme.errorColor}
                        title={timesheetMessages.deleteRow}
                        fontStyles={{ color: theme.errorColor }}
                    />
                )}
                {toggleEdit && (
                    <CButton
                        onPress={toggleEdit}
                        icon="check"
                        smallest
                        transparent
                        minor
                        iconColor={theme.successColor}
                        title={timesheetMessages.saveRow}
                        fontStyles={{ color: theme.successColor }}
                    />
                )}
            </View>
        </CCard>
    );
};
