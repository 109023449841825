import { FullMetadata } from 'firebase/storage';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Image } from 'react-native';
import { CText } from '../elements/CText';
import PDFViewer from '../PDFViewer';
import { TouchableView } from '../TouchableView';
import { useFireBase } from '../../utilities/firebase';
import { useStyle } from '../../utilities/styles';
import { useDimensions } from '../../utilities/hooks/useDimensions';
import { profileMessages } from '../../container/Profile/Profile.messages';

interface IDocumentPreviewProps {
    file?: FullMetadata;
    filename?: string;
    forceOpen?: boolean;
}
/**
 * component to preview a document (pdf or image)
 */
export const DocumentPreview: FC<IDocumentPreviewProps> = ({
    file,
    filename,
    forceOpen,
}) => {
    // global state
    const { getFileDownloadUrl } = useFireBase();
    const style = useStyle();
    const { width } = useDimensions();
    // local state
    const [url, setUrl] = useState<string>();
    const [open, setOpen] = useState(false);
    /**
     * dimensions of current image memoized
     */
    const imgDimensions = useMemo(() => {
        const imgWidth = width < 700 ? width - 100 : 600;
        return { width: imgWidth, height: imgWidth * Math.sqrt(2) };
    }, [width]);
    /**
     * memoized type of file based on ending
     * TODO: smarter distinction to prevent missmatches if possible
     */
    const type = useMemo(() => {
        if (file) {
            const dotSplit = file.fullPath.split('.');
            return dotSplit.pop() || '';
        } else if (filename) {
            const dotSplit = filename.split('.');
            return dotSplit.pop() || '';
        }
    }, [file]);
    /**
     * effect to get file download url based on current file
     */
    useEffect(() => {
        if (file) {
            getFileDownloadUrl(file.fullPath).then((v) => {
                setUrl(v);
            });
        } else if (filename) {
            getFileDownloadUrl(filename).then((v) => {
                setUrl(v);
            });
        }
    }, [file]);
    /**
     * render
     */
    return (
        <TouchableView
            onPress={() => {
                setOpen(!open);
            }}
            style={style.horizontalSpaced}
        >
            {open || forceOpen ? (
                type === 'pdf' ? (
                    <PDFViewer
                        filename={file ? file.fullPath : filename || 'error'}
                    />
                ) : (
                    <Image source={{ uri: url }} style={imgDimensions} />
                )
            ) : (
                <CText message={profileMessages.showPreview} />
            )}
        </TouchableView>
    );
};
