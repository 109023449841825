import React, { FC, useContext, useEffect } from 'react';
import { CPicker, CText, InfoBox, OutOfContext } from '../../../components';
import { ProfileContext } from '../../Profile/Profile.context';
import { profileMessages } from '../../Profile/Profile.messages';
import { EField } from '../../../enums';
import { fieldMessages } from '../../../utilities/messages';
import { onboardingMessages } from '../Onboarding.messages';

export const FieldInfo: FC = () => {
    const { curUserData, onChangeUserData, onValidityChange } =
        useContext(ProfileContext);
    /**
     * set validity
     */
    useEffect(() => {
        onValidityChange('');
    }, [curUserData]);

    if (!curUserData || !onChangeUserData) {
        return <OutOfContext component="FieldInfo" />;
    }
    return (
        <>
            <InfoBox
                message={onboardingMessages.restrictiveFields}
                type="info"
            />
            <CPicker
                title={profileMessages.restrictiveFields}
                placeholder={profileMessages.restrictiveFieldsPlaceholder}
                multiValues={curUserData.fields}
                values={Object.values(EField)
                    .filter((v) => v !== EField.undefined)
                    .map((field) => ({
                        value: field,
                        label: fieldMessages[field],
                    }))}
                onChange={() => {
                    // is always multichanging
                }}
                onChangeMulti={(fields) => onChangeUserData({ fields })}
            />
        </>
    );
};
