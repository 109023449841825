import React, { FC, useState } from 'react';
import { View } from 'react-native';
import { CButton } from '../../../components/elements';
import { CTextInput } from '../../../components';
import { useFireBase } from '../../../utilities/firebase';
import { startMessages } from '../start.messages';
import { useFormat } from '../../../utilities/intl';
import { useDialog } from '../../../utilities/dialog';
import { actionMessages } from '../../../utilities/messages';

interface IForgotProps {
    goBack: () => void;
}

export const Forgot: FC<IForgotProps> = ({ goBack }) => {
    const { callFunction } = useFireBase();
    const format = useFormat();
    const dialog = useDialog();
    const [mail, setMail] = useState('');

    return (
        <>
            <CTextInput
                label={format(startMessages.mail)}
                value={mail}
                placeholder={format(startMessages.mailPlaceholder)}
                onChangeText={setMail}
                autoExtend
            />
            <View style={{ marginHorizontal: 'auto' }}>
                <CButton
                    title={format(startMessages.requestPasswordReset)}
                    onPress={async () => {
                        await callFunction('passwordReset', { mail });
                        await dialog({
                            message: format(startMessages.clickOnTheLink),
                            title: format(startMessages.aMailHasBeenSent),
                            buttons: [{ text: format(actionMessages.ok) }],
                            icon: 'success',
                        });
                        goBack();
                    }}
                    disableOnClick
                />
            </View>
        </>
    );
};
