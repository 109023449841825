import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Linking, View } from 'react-native';
import { useFireBase } from '../../../utilities/firebase';
import { MApplication } from '../../../models/MApplication';
import { CText, CButton, ProfilePicture } from '../../../components';
import { ApplicationPicture } from '../../../components/ProfilePicture/ApplicationPicture';
import { LanguageContext, useFormat } from '../../../utilities/intl';
import {
    educationMessages,
    specializationMessages,
    positionMessages,
    generalMessages,
} from '../../../utilities/messages';
import { useStyle } from '../../../utilities/styles';
import { jobMessages } from '../../Job/job.messages';
import { profileMessages } from '../../Profile/Profile.messages';
import { useEnvironment } from '../../../utilities/contexts';
import { useLock } from '../../../utilities/hooks';
import {
    MProfessionalInfo,
    MProfessionalProfile,
    MRequest,
} from '../../../models';
import { ECollections, EUserType } from '../../../enums';
/**
 * overview of applicant connected to contract
 * @param param0
 * @returns
 */
export const ApplicantOverviewBar: FC<{
    application?: MApplication;
    applicant?: MProfessionalInfo;
    request?: MRequest;
}> = ({ application, applicant, request }) => {
    // global state
    const { getFile, callFunction, getDataById } = useFireBase();
    const format = useFormat();
    const style = useStyle();
    const { lock } = useLock();
    const { environment } = useEnvironment();
    const { language } = useContext(LanguageContext);
    // local state
    const [talent, setTalent] = useState<MProfessionalProfile>();

    /**
     * callback to get resume
     */
    const getResume = useCallback(async () => {
        if (!application && !request) return;
        const unlock = lock();
        try {
            const filename = await callFunction('getResume', {
                applicationId: application?.documentId,
                environment,
                availabilityId: request?.availabilityId,
                lang: language,
            });
            const res = await getFile(filename);
            const blob = new Blob(
                // @ts-ignore
                [res],
                {
                    type: 'application/pdf',
                },
            );

            Linking.openURL(URL.createObjectURL(blob));
        } finally {
            unlock();
        }
    }, [application, request, language, environment]);
    /**
     * effect to load talent for request from pub collection
     */
    useEffect(() => {
        if (request?.profileId) {
            getDataById(
                ECollections.publicProfProfiles,
                request.profileId,
            ).then((t) => setTalent(new MProfessionalProfile(t)));
        }
    }, []);
    /**
     * render
     */
    return (
        <View style={[style.card, style.horizontalWrap, style.horizontalSplit]}>
            <View
                style={[
                    style.horizontalPadded,
                    style.horizontal,
                    { flexGrow: 1 },
                ]}
            >
                {application && <ApplicationPicture data={application} />}
                {talent && (
                    <ProfilePicture
                        data={{ ...talent, type: EUserType.talent }}
                    />
                )}
                {!!applicant && (
                    <View style={[style.horizontalPadded]}>
                        <CText
                            message={
                                applicant?.firstName +
                                (applicant.firstName ? ' ' : '') +
                                applicant?.lastName
                            }
                        />
                        <CText
                            headline
                            message={(
                                application?.educations ||
                                applicant?.educations ||
                                []
                            )
                                .map((e) =>
                                    format(
                                        educationMessages[
                                            e as keyof typeof educationMessages
                                        ],
                                    ),
                                )
                                .join(',\n')}
                        />
                    </View>
                )}
            </View>
            {!!(application || applicant)?.specializations.length && (
                <View style={[style.horizontalPadded, { flexGrow: 1 }]}>
                    <CText message={jobMessages.specializations} />
                    <CText
                        headline
                        message={(
                            application?.specializations ||
                            applicant?.specializations ||
                            []
                        )
                            .map((spec) =>
                                format(
                                    specializationMessages[
                                        spec as keyof typeof specializationMessages
                                    ],
                                ),
                            )
                            .join(',\n')}
                    />
                </View>
            )}
            {!!(application || applicant) &&
                !!(application?.position || applicant?.position) && (
                    <View style={[style.horizontalPadded, { flexGrow: 1 }]}>
                        <CText message={jobMessages.employeePosition} />
                        <CText
                            headline
                            message={
                                positionMessages[
                                    (
                                        (application || applicant) as {
                                            position: string;
                                        }
                                    ).position as keyof typeof positionMessages
                                ]
                            }
                        />
                    </View>
                )}
            {!!(
                application?.yearsOfExperience || applicant?.yearsOfExperience
            ) && (
                <View style={[style.horizontalPadded, { flexGrow: 1 }]}>
                    <CText message={profileMessages.yoe} />
                    <CText
                        headline
                        message={`${
                            (application || applicant)?.yearsOfExperience
                        } ${format(
                            (application || applicant)?.yearsOfExperience !== 1
                                ? generalMessages.years
                                : generalMessages.year,
                        )}`}
                    />
                </View>
            )}
            <View style={[style.horizontalPadded, style.centeredItems]}>
                <CText message={jobMessages.resume} />
                <CButton onPress={getResume} icon="download" />
            </View>
        </View>
    );
};
