/**
 * dynamicalli calculated image widht and height based on current screen width: ratio from 1 to 0
 * @param screenWidth
 * @param ratio
 * @param heightRatio
 * @param maxWidth
 * @returns
 */
export const imageSize = (
    screenWidth: number,
    ratio: number,
    heightRatio: number,
    maxWidth: number,
    maxHeight: number,
) => {
    let width = screenWidth * ratio;
    if (maxWidth && width > maxWidth) {
        width = maxWidth;
    }
    let height = width * heightRatio;

    if (maxHeight && height > maxHeight) {
        height = maxHeight;
        width = maxHeight / heightRatio;
    }

    return {
        width,
        height,
    };
};
