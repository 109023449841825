import { EShiftPlannerType } from '../../enums';
import { getWeekNumber } from '../../utilities/functions';
import { MShiftEvent } from './MEvent';
import { MShiftGroup } from './MGroup';
import { MShiftPlanShift } from './MShift';
import { MShiftWorker } from './MWorker';

export class MShiftPlan {
    public documentId = '';

    public type = EShiftPlannerType.default;

    public name = '';

    public workplaceId?: string;

    public groups: MShiftGroup[] = [];
    public workers: MShiftWorker[] = [];
    public shifts: MShiftPlanShift[] = [];
    public manualShifts: MShiftPlanShift[] = [];

    public events: MShiftEvent[] = [];

    public createdOn = Date.now();

    public week = getWeekNumber(new Date());
    public year = new Date().getFullYear();

    constructor(params: Partial<MShiftPlan> = {}) {
        Object.assign(this, params);
        if (params.groups) {
            this.groups = params.groups.map((g) => new MShiftGroup(g));
        }
        if (params.workers) {
            this.workers = params.workers.map((w) => new MShiftWorker(w));
        }
        if (params.shifts) {
            this.shifts = params.shifts.map((s) => new MShiftPlanShift(s));
        }
        if (params.events) {
            this.events = params.events.map((e) => new MShiftEvent(e));
        }
    }
}
