import { defineMessages } from 'react-intl';

const scope = 'ride.requestSignature.';

export const requestSignatureRideMessages = defineMessages({
    welcome: {
        id: scope + 'welcome',
        defaultMessage:
            'In this screen you can select the placement of your signatures in this envelope.',
    },
    yourPositions: {
        id: scope + 'yourPositions',
        defaultMessage: 'Your signature positions will be displayed here.',
    },
    partnerPositiosn: {
        id: scope + 'partnerPositiosn',
        defaultMessage:
            'The positions for the other contract party will be displayed here.',
    },
    moreSignatureLocations: {
        id: scope + 'moreSignatureLocations',
        defaultMessage:
            'You can add further signature positions by clicking anywhere on the currently displayed document',
    },
    saveAndContinue: {
        id: scope + 'saveAndContinue',
        defaultMessage:
            'Here you can save your selection and continue with the creation of your envelope, once the minimal requirement of one position per party is met.',
    },
});
