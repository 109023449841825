import { Unsubscribe } from 'firebase/firestore';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import {
    EApplicationStatus,
    ECollections,
    EContractStatus,
    EUserType,
} from '../../../enums';
import { useFireBase } from '../../../utilities/firebase';
import { useTheme } from '../../../utilities/styles';
import { IMenuButtonBaseProps, MenuButton } from './MenuButton';
import { CText } from '../../elements';
import { makeMenuStyles } from '../menu.style';
import { isPeasant, isSuperUser } from '../../../utilities/auth';
import { IFilter } from '../../../utilities/firebase/store';
import { generalMessages } from '../../../utilities/messages';
/**
 * Self conscious notification menu button who watches for new unread messages
 * @param param0
 * @returns
 */
export const RequestsMenuButton: FC<IMenuButtonBaseProps> = ({
    closeSidebar,
    detail,
}) => {
    // gloabl
    const { theme } = useTheme();
    const { watchDataIndex, getDataIndex, userData, userAgencies } =
        useFireBase();
    // local
    const localStyles = useMemo(() => makeMenuStyles(theme), [theme]);
    const [unsubscribe, setUnsubscribe] = useState<Unsubscribe>();
    const [requestCount, setRequestCount] = useState(0);
    /**
     * effect to hook into application collection
     */
    useEffect(() => {
        if (isSuperUser(userData)) return;
        const basefilter = [] as IFilter[];
        if (userAgencies.length) {
            basefilter.push({
                field: 'agencyId',
                value: userAgencies.map((a) => a.documentId),
                operator: 'in',
            });
        } else if (isPeasant(userData)) {
            basefilter.push({
                field: 'userId',
                value: userData.documentId,
            });
        }

        const queryOptions = {
            filter: [
                ...basefilter,
                {
                    field: 'status',
                    value: EApplicationStatus.open,
                },
            ],
        };
        // getDataIndex(ECollections.requests, {
        //     ...queryOptions,
        //     getLength: true,
        // }).then((l) => {
        //     setRequestCount(l as number);
        // });
        watchDataIndex(ECollections.requests, queryOptions, (event) => {
            // if (event.type === 'added') {
            //     setRequestCount((prev) => prev + 1);
            // }
            getDataIndex(ECollections.requests, {
                ...queryOptions,
                getLength: true,
            }).then((v) => setRequestCount(v as number));
        }).then((us) => setUnsubscribe(() => us));
    }, [, userData, userAgencies]);
    /**
     * effect to register unsubscribe as cleanup function
     * it gets triggered if react thinks it is time to clean up
     */
    useEffect(() => {
        return unsubscribe;
    }, [unsubscribe]);
    /**
     * render (could have used menu button component but it did cause warnings)
     */
    return (
        <View>
            <MenuButton
                closeSidebar={closeSidebar}
                detail={detail}
                icon={'clipboardAccount'}
                title={
                    userData.type === EUserType.user
                        ? generalMessages.requests
                        : generalMessages.talentRequests
                }
                targetLocation={'/requests'}
                targetLocations={['requests']}
            />
            {!!requestCount &&
                (detail ? (
                    <CText
                        {...{ dataSet: { cy: 'request-menu-count' } }}
                        style={{
                            zIndex: 50,
                            position: 'absolute',
                            color: theme.accentColor,
                            borderRadius: 5,
                            height: 10,
                            top: 16,
                            right: 0,
                        }}
                        message={`${requestCount}`}
                    />
                ) : (
                    <View
                        {...{ dataSet: { cy: 'new-request-menu-bubble' } }}
                        style={localStyles.notificationBubble}
                    />
                ))}
        </View>
    );
};
