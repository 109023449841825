import { Unsubscribe } from 'firebase/auth';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { ECollections } from '../../../enums';
import { MSupportRequest } from '../../../models';
import { useFireBase } from '../../../utilities/firebase';
import { useLocation } from '../../../utilities/routing';
import { useTheme } from '../../../utilities/styles';
import { makeMenuStyles } from '../menu.style';
import { IMenuButtonProps, MenuButton } from './MenuButton';
/**
 * Self conscious support request menu button who watches for new unread messages
 * @param param0
 * @returns
 */
export const SupportRequestMenuButton: FC<IMenuButtonProps> = ({
    closeSidebar,
    detail,
    icon,
    title,
    customFunction,
    targetLocation,
    exact,
}) => {
    // gloabl
    const { theme } = useTheme();
    const currentLocation = useLocation();
    const { watchDataIndex, userData } = useFireBase();
    // local
    const localStyles = useMemo(() => makeMenuStyles(theme), [theme]);
    const [unsubscribe, setUnsubscribe] = useState<Unsubscribe>();
    const [hasUpdatesFrom, setHasUpdates] = useState(0);
    const [hasSeenUpdatesFrom, setHasSeenUpdates] = useState(0);
    /**
     * has updates
     */
    const hasUpdates = useMemo(
        () => hasSeenUpdatesFrom < hasUpdatesFrom,
        [hasUpdatesFrom, hasSeenUpdatesFrom],
    );
    /**
     * check if button is active and remove hasUpdates in case of acitve
     */
    const active = useMemo(() => {
        const isActive = exact
            ? targetLocation === currentLocation.pathname
            : targetLocation &&
              currentLocation.pathname.includes(targetLocation);
        if (isActive && hasUpdates) {
            setHasSeenUpdates(hasUpdatesFrom);
        }
        return isActive;
    }, [exact, currentLocation, hasUpdates]);
    /**
     * callback to handle events from get dataIndex effect
     */
    const handleNewSupportRequest = useCallback(
        (data: any) => {
            const request = new MSupportRequest(data);
            if (request.state === 'open') {
                setHasUpdates(request.createdOn);
                // ? there is a notification notification being created for support requests which also triggers notification
                // if (hasSeenUpdatesFrom < request.createdOn) {
                //     sendNotification(
                //         format(generalMessages.newSupportRequest),
                //         request.reason,
                //         `https://app.situsdocs.de/supportrequests`,
                //         !active,
                //     );
                // }
            }
        },
        [active, hasSeenUpdatesFrom],
    );
    /**
     * effect to hook into support request
     */
    useEffect(() => {
        const queryOptions = {
            orderBy: 'createdOn',
            filter: [
                {
                    field: 'state',
                    value: 'open',
                },
            ],
            limit: 1,
        };
        watchDataIndex(ECollections.supportRequests, queryOptions, (event) => {
            if (event.type === 'added') {
                handleNewSupportRequest(event.doc.data());
            }
        }).then((us) => setUnsubscribe(() => us));
    }, [userData, handleNewSupportRequest]);
    /**
     * effect to register unsubscribe as cleanup function
     * it gets triggered if react thinks it is time to clean up
     */
    useEffect(() => {
        return unsubscribe;
    }, [unsubscribe]);
    /**
     * render (could have used menu button component but it did cause warnings)
     */
    return (
        <View>
            <MenuButton
                closeSidebar={closeSidebar}
                detail={detail}
                icon={icon}
                title={title}
                customFunction={customFunction}
                targetLocation={targetLocation}
                exact={exact}
            />
            {hasUpdates && (
                <View
                    {...{ dataSet: { cy: 'new-notification-menu-bubble' } }}
                    style={localStyles.notificationBubble}
                />
            )}
        </View>
    );
};
