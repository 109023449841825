import React, { FC, useCallback } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { CButton } from '../../../components';
import { ECollections } from '../../../enums';
import { MChat, MUserData } from '../../../models';
import { useDialog } from '../../../utilities/dialog';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { actionMessages } from '../../../utilities/messages';
import { userMessages } from '../user.messages';

interface IVerifyButton {
    user: MUserData;
    onChange: (next: MUserData) => void;
    activeChat?: MChat;
    style?: StyleProp<ViewStyle>;
    minor?: boolean;
    small?: boolean;
}

export const VerifyButton: FC<IVerifyButton> = ({
    user,
    onChange,
    style,
    minor,
    small,
    activeChat,
}) => {
    const dialog = useDialog();
    const { put, post, userData } = useFireBase();
    const format = useFormat();
    /**
     * effect to handle verification change of user
     */
    const handleVerificationChange = useCallback(() => {
        const current = user.verified;
        const next = new MUserData({
            ...user,
        });
        dialog({
            title: format(userMessages.confirmVerificationStatusChange),
            message: format(
                current
                    ? userMessages.doYouWantToUnverifyX
                    : userMessages.doYouWantToVerifyX,
                {
                    x: user.firstName + ' ' + user.lastName,
                },
            ),
            cancelButton: {
                text: format(actionMessages.cancel),
            },
            buttons: [
                {
                    text: format(actionMessages.ok),
                    onPress: () => {
                        if (current) {
                            next.verified = false;
                            next.verifiedOn = Date.now();
                            next.verifiedFrom = userData.documentId;
                        } else {
                            next.verified = true;
                            next.verifiedOn = Date.now();
                            next.verifiedFrom = userData.documentId;
                            if (
                                !next.assignedAgents.includes(
                                    userData.documentId,
                                )
                            ) {
                                next.assignedAgents.push(userData.documentId);
                            }
                            // TODO: ACTIVATE to auto open conversation on verification
                            if (!activeChat && false) {
                                post(
                                    ECollections.chats,
                                    new MChat({
                                        createdOn: Date.now(),
                                        participants: [
                                            userData.documentId,
                                            next.documentId,
                                        ],
                                    }),
                                );
                            }
                        }
                        put(ECollections.users, user.documentId, next).then(
                            () => {
                                onChange(next);
                            },
                        );
                    },
                },
            ],
            icon: 'question',
        });
    }, [dialog, user, onChange]);

    return (
        <CButton
            cy={'verify-button'}
            title={format(
                user.verified ? userMessages.verified : userMessages.verify,
            )}
            icon={user.verified ? 'check' : undefined}
            minor={user.verified || minor}
            onPress={handleVerificationChange}
            style={style}
            small={small}
        />
    );
};
