import React, { FC, useMemo, useState } from 'react';
import { Platform, Pressable, SafeAreaView, View } from 'react-native';
import { makeNavStyles } from './App.navigation.styles';
import { Menu } from './components/Menu';
import { CButton } from './components/elements';
import { CImage } from './components/elements/CImage';
import { IDefaultProps } from './utilities/IDefaultProps';
import { isSuperUser } from './utilities/auth';
import { HEADERHEIGHT } from './utilities/constants';
import { useFireBase } from './utilities/firebase';
import { useDimensions } from './utilities/hooks/useDimensions';
import { Routes } from './utilities/routing';
import { useTheme } from './utilities/styles';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
/**
 * wrapper for all routes when user is logged in
 */
export const NavigationWrapper: FC<IDefaultProps> = ({ children }) => {
    // global styles
    const { width, showHead, mainHeight, wrapperHeight } = useDimensions();
    const { theme } = useTheme();
    const { userData } = useFireBase();
    const insets = useSafeAreaInsets();
    // local styles
    const localStyles = useMemo(() => makeNavStyles(theme), [theme]);
    const [drawer, setDrawer] = useState('closed');
    /**
     * render
     */
    return (
        <SafeAreaView style={{ flex: 1, width }}>
            {showHead && (
                <View style={localStyles.navHeader}>
                    <CImage
                        image={isSuperUser(userData) ? 'adminLogo' : 'logo'}
                        fixedHeight={HEADERHEIGHT}
                    />
                    <View
                        style={{
                            position: 'absolute',
                            zIndex: 20,
                        }}
                    >
                        <CButton
                            transparent
                            icon={'hamburger'}
                            onPress={() => setDrawer('open')}
                            style={{ padding: 0, margin: 0 }}
                        />
                    </View>
                </View>
            )}
            <View
                style={[
                    localStyles.navContainer,
                    {
                        height: mainHeight,
                        width: width,
                    },
                ]}
            >
                {!showHead && (
                    <View style={localStyles.navSidebar}>
                        <Menu />
                    </View>
                )}
                <Routes>{children}</Routes>
                {/* bottm margin to ensure nothing is sticking to the end of the window */}
            </View>
            {drawer === 'open' && showHead && (
                <Pressable
                    style={[
                        localStyles.navDrawerUnderlay,
                        { height: wrapperHeight },
                    ]}
                    onPress={() => setDrawer('closed')}
                />
            )}
            {drawer === 'open' && showHead && (
                <View
                    style={[
                        localStyles.navDrawer,
                        {
                            height: wrapperHeight,
                            paddingTop: Platform.OS === 'ios' ? insets.top : 0,
                        },
                    ]}
                >
                    <Menu closeDrawer={() => setDrawer('closed')} />
                </View>
            )}
        </SafeAreaView>
    );
};
