import React, { FC, useMemo } from 'react';
import { View } from 'react-native';
import { useStyle, useTheme } from '../../../../../utilities/styles';
import { CImage, CLabel, CText } from '../../../../../components';
import { CButton, CMultiButton } from '../../../../../components/elements';
import { useNavigate } from '../../../../../utilities/routing';
import { ExpandableCard } from '../../../../../components/ExpandableCard';
import { useFormat } from '../../../../../utilities/intl';
import { jobMessages } from '../../../job.messages';
import { actionMessages } from '../../../../../utilities/messages';
import { useFireBase } from '../../../../../utilities/firebase';
import { isSuperUser } from '../../../../../utilities/auth';
import { EApplicationStatus } from '../../../../../enums';
import { JobRowBody } from './components/JobRowBody';
import { JobTitle } from '../../../components/JobTitle';
import { JobRowSubHead } from './components/JobRowSubHead';
import { IJobRow } from '.';
import { MApplication } from '../../../../../models';

interface IJobRowSlim extends IJobRow {
    handleApply: () => void;
    handleQuickPublish: () => void;
    isOwner: boolean;
    application: MApplication | undefined;
    memoizedMultiButton: JSX.Element;
}
/**
 * Job Row version for devices with low screen width
 *
 * - recycles state from parent regular job row
 *
 * @param param0
 * @returns
 */
export const JobRowSlim: FC<IJobRowSlim> = ({
    job,
    noButtons,
    noSubHead,
    inCard,
    forceOpen,
    smallButtons,
    highlight,
    customOnClick,
    handleApply,
    handleQuickPublish,
    isOwner,
    application,
    memoizedMultiButton,
}) => {
    const style = useStyle();
    const format = useFormat();
    const { theme } = useTheme();
    const navigate = useNavigate();
    const { userData, isLoggedIn } = useFireBase();
    /**
     * render
     */
    return (
        <>
            <ExpandableCard
                style={
                    highlight ? [style.card, style.accentBorder] : inCard && {}
                }
                head={
                    <View>
                        <JobTitle job={job} showId={!!job.id} />
                        {!noButtons && (
                            <View
                                style={[
                                    style.horizontal,

                                    {
                                        width: '100%',
                                        justifyContent: 'flex-end',
                                    },
                                ]}
                            >
                                {isSuperUser(userData) && (
                                    <View
                                        style={[
                                            style.verticalPadded,
                                            style.horizontalPadded,
                                        ]}
                                    >
                                        <CImage
                                            image={job.region}
                                            fixedHeight={20}
                                        />
                                    </View>
                                )}
                                {isOwner || isSuperUser(userData) ? (
                                    <View
                                        style={[
                                            style.horizontal,
                                            style.centeredItems,
                                        ]}
                                    >
                                        <CButton
                                            minor
                                            small
                                            title={format(
                                                jobMessages[job.status],
                                            )}
                                            onPress={handleQuickPublish}
                                        />
                                        {memoizedMultiButton}
                                    </View>
                                ) : !application ||
                                  application.status ===
                                      EApplicationStatus.closed ? (
                                    <CButton
                                        cy={`apply-for-${job.documentId}`}
                                        title={format(actionMessages.apply)}
                                        onPress={handleApply}
                                        small={smallButtons}
                                    />
                                ) : (
                                    <CLabel
                                        title={
                                            application.status !==
                                            EApplicationStatus.rejected
                                                ? jobMessages.applied
                                                : jobMessages.applicationRejected
                                        }
                                        color={
                                            application.status !==
                                            EApplicationStatus.rejected
                                                ? theme.successColor
                                                : theme.errorColor
                                        }
                                        startIcon={
                                            application.status !==
                                            EApplicationStatus.rejected
                                                ? 'check'
                                                : 'close'
                                        }
                                        small={smallButtons}
                                    />
                                )}
                            </View>
                        )}
                    </View>
                }
                subHead={!noSubHead && <JobRowSubHead job={job} showDistance />}
                body={<JobRowBody job={job} />}
                customExpand={forceOpen}
                customOnClick={
                    (!isLoggedIn &&
                        (() => navigate('/job/view/' + job.documentId))) ||
                    customOnClick
                }
            />
            {inCard && <View style={style.paddedThinSeparator} />}
        </>
    );
};
