import React, { FC, useCallback } from 'react';
import { View } from 'react-native';
import { IShiftPlanComponentProps } from '../IShiftPlanComponentProps';
import { CButton, CCard, CText } from '../../../../components';
import { actionMessages } from '../../../../utilities/messages';
import { useStyle } from '../../../../utilities/styles';
import { assignWorkersToShifts } from './functions/assignWorkersToShifts';
import { prepareShifts } from './functions/prepareShifts';
import { shiftPlanMessages } from '../../shiftPlan.messages';
import { useFireBase } from '../../../../utilities/firebase';
import { ECollections, EShiftPlannerType } from '../../../../enums';
import { useLock } from '../../../../utilities/hooks';
import { useSecureNavigate } from '../../../../utilities/routing';
import { prepareChildCareShifts } from '../childcare/functions/prepareShifts';

export const CreatePlan: FC<IShiftPlanComponentProps> = ({
    shiftPlan,
    setPlan,
    goBackward,
    setEdit,
}) => {
    const style = useStyle();
    const { secureNavigate } = useSecureNavigate();
    const { post, put } = useFireBase();
    const { lock } = useLock();

    const createPlan = useCallback(async () => {
        const unlock = lock();
        if (shiftPlan.type === EShiftPlannerType.default) {
            shiftPlan.shifts = prepareShifts(shiftPlan);
            setPlan(assignWorkersToShifts(shiftPlan));
        } else if (shiftPlan.type === EShiftPlannerType.childcare) {
            shiftPlan.shifts = prepareChildCareShifts(shiftPlan);
            setPlan(assignWorkersToShifts(shiftPlan));
        }
        if (!shiftPlan.documentId) {
            const postResult = await post(
                `${ECollections.workplaces}/${shiftPlan.workplaceId}/${ECollections.shiftPlans}`,
                { ...shiftPlan },
            );
            setEdit(false);
            unlock();
            if (!postResult) {
                console.error('Posting failed smh');
            }
            secureNavigate(
                `/shiftPlan/view/${shiftPlan.workplaceId}/${postResult?.id}`,
                { replace: true, force: true },
            );
        } else {
            await put(
                `${ECollections.workplaces}/${shiftPlan.workplaceId}/${ECollections.shiftPlans}`,
                shiftPlan.documentId,
                { ...shiftPlan },
            );
            setEdit(false);
            unlock();
            secureNavigate(
                `/shiftPlan/view/${shiftPlan.workplaceId}/${shiftPlan.documentId}`,
                { replace: true, force: true },
            );
        }
    }, [shiftPlan]);

    return (
        <View>
            <CCard>
                <CText
                    secondaryHeadline
                    message={shiftPlanMessages.generateShiftPlan}
                />
            </CCard>
            <View style={[style.horizontal, { justifyContent: 'center' }]}>
                <CButton
                    title={actionMessages.back}
                    onPress={goBackward}
                    warning
                />
                <CButton title={actionMessages.create} onPress={createPlan} />
            </View>
        </View>
    );
};
