import { defineMessages } from 'react-intl';

const scope = 'month.';

export const monthMessages = defineMessages({
    january: {
        id: scope + 'january',
        defaultMessage: 'January',
    },
    february: {
        id: scope + 'february',
        defaultMessage: 'February',
    },
    march: {
        id: scope + 'march',
        defaultMessage: 'March',
    },
    april: {
        id: scope + 'april',
        defaultMessage: 'April',
    },
    may: {
        id: scope + 'may',
        defaultMessage: 'May',
    },
    june: {
        id: scope + 'june',
        defaultMessage: 'June',
    },
    july: {
        id: scope + 'july',
        defaultMessage: 'July',
    },
    august: {
        id: scope + 'august',
        defaultMessage: 'August',
    },
    september: {
        id: scope + 'september',
        defaultMessage: 'September',
    },
    october: {
        id: scope + 'october',
        defaultMessage: 'October',
    },
    november: {
        id: scope + 'november',
        defaultMessage: 'November',
    },
    december: {
        id: scope + 'december',
        defaultMessage: 'December',
    },
    januaryShort: {
        id: scope + 'januaryShort',
        defaultMessage: 'Jan',
    },
    februaryShort: {
        id: scope + 'februaryShort',
        defaultMessage: 'Feb',
    },
    marchShort: {
        id: scope + 'marchShort',
        defaultMessage: 'Mar',
    },
    aprilShort: {
        id: scope + 'aprilShort',
        defaultMessage: 'Apr',
    },
    mayShort: {
        id: scope + 'mayShort',
        defaultMessage: 'May',
    },
    juneShort: {
        id: scope + 'juneShort',
        defaultMessage: 'Jun',
    },
    julyShort: {
        id: scope + 'julyShort',
        defaultMessage: 'Jul',
    },
    augustShort: {
        id: scope + 'augustShort',
        defaultMessage: 'Aug',
    },
    septemberShort: {
        id: scope + 'septemberShort',
        defaultMessage: 'Sep',
    },
    octoberShort: {
        id: scope + 'octoberShort',
        defaultMessage: 'Oct',
    },
    novemberShort: {
        id: scope + 'novemberShort',
        defaultMessage: 'Nov',
    },
    decemberShort: {
        id: scope + 'decemberShort',
        defaultMessage: 'Dec',
    },
});
