import React, { FC, useMemo } from 'react';
import { View } from 'react-native';
import { generalMessages } from '../../utilities/messages/general.messages';
import { useNavigate } from '../../utilities/routing';
import { useStyle, useTheme } from '../../utilities/styles';
import { CIcon, Spinner } from '../';
import { CText } from '../';
import { TouchableView } from '../TouchableView';
import { Message } from '../../utilities/types';

export interface IInfoBoxProps {
    message: Message;
    path?: string;
    customFunction?: () => void;
    text?: Message;
    type?: 'warning' | 'success' | 'error' | 'working' | 'info';
}

export const InfoBox: FC<IInfoBoxProps> = ({
    message,
    path,
    type,
    customFunction,
    text,
}) => {
    const style = useStyle();
    const navigate = useNavigate();
    const { theme } = useTheme();
    const { color, icon } = useMemo(() => {
        switch (type || 'info') {
            case 'success':
                return { color: theme.successColor, icon: 'check' };
            case 'error':
                return { color: theme.errorColor, icon: 'close' };
            case 'warning':
                return { color: theme.warningColor, icon: 'alert' };
            case 'info':
                return { color: theme.accentColor, icon: 'info_outline' };
            case 'working':
                return { color: theme.errorColor, icon: undefined };
        }
    }, [type, theme]);
    return (
        <TouchableView
            onPress={
                path || customFunction
                    ? () => {
                          if (path) {
                              navigate(path);
                          } else if (customFunction) {
                              customFunction();
                          }
                      }
                    : undefined
            }
            style={{
                borderColor: color,
                backgroundColor: theme.backgroundMainColor,
                borderWidth: 1,
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',
                padding: 5,
                borderRadius: 5,
                flex: 1,
            }}
        >
            <View
                style={[
                    style.centeredContent,
                    style.horizontal,
                    { marginLeft: 5 },
                ]}
            >
                {icon ? <CIcon icon={icon} tint={color} /> : <Spinner tiny />}
            </View>
            <View style={{ marginLeft: 10, flex: 1 }}>
                <CText
                    style={{ color: theme.textMainColor }}
                    message={message}
                />
                {!!((type !== 'success' && path) || text) && (
                    <CText
                        style={{ color: theme.textSecondaryColor }}
                        message={text || generalMessages.clickHereToResolve}
                    />
                )}
            </View>
        </TouchableView>
    );
};
