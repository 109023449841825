import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
    KeyboardAvoidingView,
    Platform,
    ScrollView,
    StyleProp,
    View,
    ViewProps,
    ViewStyle,
} from 'react-native';
import { useDebounce } from '../../hooks/useDebounce';
import { ScrollContext } from './ScrollContext';
import { useDimensions } from '../../hooks';
interface ScrollProviderProps extends ViewProps {
    contentContainerStyle?: StyleProp<ViewStyle>;
    nested?: boolean;
}
export const ScrollProvider: FC<ScrollProviderProps> = (props) => {
    const [y, setY] = useState(0);
    const [maxY, setMaxY] = useState(0);
    const [height, setHeight] = useState(0);
    const [canScroll, setCanScroll] = useState(true);
    const { mainHeight } = useDimensions();
    const scrollViewRef = useRef<ScrollView>(null);
    const debouncedY = useDebounce(y, 500);
    /**
     * typesafe debounced y memoized
     */
    const parsedDebouncedY = useMemo(() => y || 0, [debouncedY]);
    /**
     * maximum scrollable value (content length - height)
     */
    const maxScrollY = useMemo(() => maxY - height, [maxY, height]);
    /**
     * cap y to maxScrollY
     */
    useEffect(() => {
        if (maxScrollY < y) {
            setY(maxScrollY);
        }
    }, [maxScrollY, y]);

    return (
        <ScrollContext.Provider
            value={{
                y: parsedDebouncedY,
                maxScrollY,
                setY: (nextY: number) => {
                    if (scrollViewRef.current) {
                        // TODO: figure out what is wrong here
                        // console.log(nextY, maxScrollY);
                        // const safeY = maxScrollY && nextY < maxScrollY ? nextY : maxScrollY;
                        scrollViewRef.current.scrollTo({
                            y: nextY,
                            animated: true,
                        });
                        setY(nextY);
                    } else {
                        setY(nextY);
                    }
                },
                disableScroll: () => setCanScroll(false),
                enableScroll: () => setCanScroll(true),
            }}
        >
            <KeyboardAvoidingView
                behavior={Platform.OS == 'ios' ? 'padding' : 'height'}
                keyboardVerticalOffset={100}
                style={{ flex: 1 }}
            >
                <ScrollView
                    ref={scrollViewRef}
                    onScroll={(event) => {
                        const nextOffset = event.nativeEvent.contentOffset;
                        if (nextOffset) {
                            setY(nextOffset.y);
                        }
                    }}
                    scrollEnabled={canScroll}
                    scrollEventThrottle={16} // test if a greater throttle would be aplicable
                    onLayout={(e) => {
                        setHeight(e.nativeEvent.layout.height);
                    }}
                    onContentSizeChange={(contentWidth, contentHeight) => {
                        setMaxY(contentHeight);
                    }}
                    contentContainerStyle={props.contentContainerStyle}
                    style={!props.nested && { maxHeight: mainHeight }}
                >
                    <View {...props} />
                </ScrollView>
            </KeyboardAvoidingView>
        </ScrollContext.Provider>
    );
};
