export class MShiftEvent {
    public name = '';
    /**
     * ms since 1970
     */
    public day = 0;
    /**
     * 0 = Monday; 6 = Sunday
     */
    public weekday = 0;
    /**
     * ms since 1970
     */
    public from = 0;
    public fromHour = 0;
    public fromMinute = 0;
    /**
     * ms since 1970
     */
    public to = 0;
    public toHour = 0;
    public toMinute = 0;

    public participants: string[] = [];

    constructor(params: Partial<MShiftEvent> = {}) {
        Object.assign(this, params);
    }
}
