import { ITheme } from '../ITheme';

const light: ITheme = {
    backgroundMainColor: '#ffffff',
    backgroundSecondaryColor: '#f8f9fd',
    backgroundTertiaryColor: '#f5f5f5',
    borderColor: '#cdced5',
    textMainColor: '#1f1f1f',
    textSecondaryColor: '#6a6e83',
    textTertiaryColor: '#a7aeb3',
    accentColor: '#5a81fa',
    darkAccentColor: '#2c3d8f',
    textAccentColor: '#f8f9fd',
    fontMain: 'Poppins',
    fontThin: 'Poppins',
    errorColor: '#e85b55',
    warningColor: '#feae7f',
    successColor: '#39d08b',
    mainFontFamily: 'Poppins',
    headlineFontFamily: 'DM Sans',
    shadowColor: '#000',
};

const dark: ITheme = {
    backgroundMainColor: '#282c34',
    backgroundSecondaryColor: '#21252b',
    backgroundTertiaryColor: '#474646',
    borderColor: '#404042',
    textMainColor: '#d4d2d2',
    textSecondaryColor: '#a1a7c7',
    textTertiaryColor: '#656c70',
    accentColor: '#5a81fa',
    darkAccentColor: '#2c3d8f',
    textAccentColor: '#d4d2d2',
    fontMain: 'Poppins',
    fontThin: 'Poppins',
    errorColor: '#e85b55',
    warningColor: '#feae7f',
    successColor: '#39d08b',
    mainFontFamily: 'Poppins',
    headlineFontFamily: 'DM Sans',
    shadowColor: '#000',
};

export const situsTheme = { light, dark };
