import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useStyle } from '../../../utilities/styles';
import { useDebounce } from '../../../utilities/hooks/useDebounce';
import { CPicker } from '../CPicker';
import { CTextInput } from '../CTextInput';
import { cFieldStringInputMessages } from './CFieldStringInput.messages';
import { Message } from '../../../utilities/types';
import { useAutoFormat } from '../../../utilities/intl/useFormat';

interface ICFieldStringInputProps {
    cy?: string;
    fields: { field: string; label: Message }[];
    value?: { field: string; value: string };
    onChange: (next: { field: string; value: string }) => void;
}

export const CFieldStringInput: FC<ICFieldStringInputProps> = ({
    cy,
    fields,
    value,
    onChange,
}) => {
    const style = useStyle();
    const format = useAutoFormat();
    const [filterField, setFilterField] = useState(fields[0].field || '');
    const [textFilter, setTextFilter] = useState('');
    const filter = useMemo(
        () => ({ field: filterField, value: textFilter || '' }),
        [filterField, textFilter],
    );
    const debouncedFilter = useDebounce(filter, 500);
    /**
     * set custom string filter after debounce
     */
    useEffect(() => {
        // setMode([]);
        // clearFilters();
        if (debouncedFilter) {
            onChange(debouncedFilter);
        }
    }, [debouncedFilter]);
    /**
     * react on remote changes on value
     */
    useEffect(() => {
        if (value?.field !== filterField) {
            setFilterField(value?.field || fields[0].field || '');
        }
        if (value?.value !== textFilter || !value) {
            setTextFilter(value?.value || '');
        }
    }, [value]);
    /**
     * render
     */
    return (
        <View
            style={[
                style.horizontalWrap,
                style.verticalPadded,
                style.centeredItems,
                { width: '100%', zIndex: 1 },
            ]}
        >
            <CPicker
                cy={cy ? cy + '-field' : undefined}
                value={filterField}
                values={fields.map((f) => {
                    return {
                        label: format(f.label),
                        value: f.field,
                    };
                })}
                onChange={(v) => setFilterField(v)}
            />
            <View style={style.horizontalPadded} />
            <View style={{ flex: 1, minWidth: 200 }}>
                <CTextInput
                    cy={cy ? cy + '-input' : undefined}
                    value={textFilter}
                    placeholder={cFieldStringInputMessages.exactSearch}
                    onChangeText={(s) => setTextFilter(s)}
                    autoExtend
                />
            </View>
        </View>
    );
};
