import React, { FC } from 'react';
import { View } from 'react-native';
import { TouchableView, CIcon, CText } from '../../../components';
import { useStyle, useTheme } from '../../../utilities/styles';
import { IDayAvailability, MAvailability } from '../../../models';

export const ActivityColumnRow: FC<{
    i: number;
    today: IDayAvailability;
    parent: MAvailability;
    setEdit: (b: boolean) => void;
    setPrev: (prev: MAvailability) => void;
    setSelectedHour: (h: number) => void;
}> = ({ i, today, parent, setEdit, setPrev, setSelectedHour }) => {
    const style = useStyle();
    const { theme } = useTheme();

    const adjustedFrom = today.from;
    const marked = adjustedFrom <= i && today.to > i;
    const first = adjustedFrom === i;
    const last = today.to === i + 1;
    const i0 = i === 0;
    const i24 = i === 23;

    return (
        <View key={i} style={style.horizontal}>
            <TouchableView
                style={[
                    style.horizontal,
                    {
                        height: 25,
                        flex: 1,
                        borderWidth: 1,
                        borderColor: 'transparent',
                        borderBottomColor: theme.borderColor,
                        backgroundColor: marked ? theme.accentColor : undefined,
                    },
                    i0 && {
                        borderTopColor: theme.borderColor,
                    },
                    i24 && {
                        borderBottomColor: theme.borderColor,
                    },
                    marked && {
                        borderColor: 'transparent',
                        borderBottomColor: 'transparent',
                    },
                    first && {
                        borderTopLeftRadius: 5,
                        borderTopRightRadius: 5,
                    },
                    last && {
                        borderBottomLeftRadius: 5,
                        borderBottomRightRadius: 5,
                    },
                    marked &&
                        parent.negate && {
                            backgroundColor: theme.errorColor,
                        },
                ]}
                onPress={() => {
                    setEdit(true);

                    if (marked) {
                        setPrev(parent);
                    } else {
                        setSelectedHour(i);
                    }
                }}
            >
                {first && (
                    <View style={[style.horizontal, style.centeredItems]}>
                        {parent.exactStartTime && parent.exactEndTime ? (
                            <View
                                style={[
                                    style.horizontal,
                                    style.centeredItems,
                                    style.horizontalPadded,
                                ]}
                            >
                                <CText
                                    style={{ color: theme.textAccentColor }}
                                    message={`${new Date(
                                        parent.exactStartTime,
                                    ).toLocaleDateString('de')} - ${new Date(
                                        parent.exactEndTime,
                                    ).toLocaleDateString('de')}`}
                                />
                            </View>
                        ) : (
                            parent.repeat &&
                            parent.repeatCount > 0 && (
                                <View
                                    style={[
                                        style.horizontal,
                                        style.centeredItems,
                                        style.horizontalPadded,
                                    ]}
                                >
                                    <CIcon
                                        icon="repeat"
                                        tint={theme.textSecondaryColor}
                                    />
                                    <CText
                                        style={{ color: theme.textAccentColor }}
                                    >
                                        {parent.repeatCount + 1}
                                    </CText>
                                </View>
                            )
                        )}
                        <CText style={{ color: theme.textAccentColor }}>
                            {parent.note}
                        </CText>
                    </View>
                )}
            </TouchableView>
        </View>
    );
};
