import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useNavigate } from '../../../utilities/routing';
import { CButton, CCard, CText, Spinner } from '../../../components';
import { MNotification } from '../../../models/MNotification';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { generalMessages } from '../../../utilities/messages/general.messages';
import { useStyle } from '../../../utilities/styles';
import { week } from '../../../utilities/functions';
import { NotificationRow } from '../../Notifications/components/NotificationRow';
import { ECollections, EUserType } from '../../../enums';
import { CImage } from '../../../components/elements/CImage';
/**
 * the 5 most recent notifications get displayed in here
 * @param param0
 * @returns
 */
export const RecentNotifications: FC = () => {
    // global state
    const style = useStyle();
    const navigate = useNavigate();
    const format = useFormat();
    const { getDataIndex, userData, userAgencies, userWorkplaces } =
        useFireBase();
    // local state
    const [notifications, setNotifications] = useState<MNotification[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!userData.documentId) return;
        new Promise<void>((resolve) => {
            const fors = ['null'];
            fors.push(`user_${userData.documentId}`);
            fors.push(`group_${userData.type}`);
            userAgencies.forEach((a) => fors.push(`a_${a.documentId}`));
            // ! this is problematic since 30+ workplace users
            // userWorkplaces.forEach((wp) => fors.push(`wp_${wp.documentId}`));
            getDataIndex(ECollections.notifications, {
                orderBy: 'createdOn',
                filter: [
                    {
                        field: 'for',
                        value: fors,
                        operator: 'in',
                    },
                ],
                inequalities: [
                    {
                        field: 'createdOn',
                        value: Date.now() - week,
                        operator: '>',
                    },
                ],
                limit: 5,
            }).then((result) => {
                setNotifications(
                    (result as MNotification[]).map(
                        (r) => new MNotification(r),
                    ),
                );
                resolve();
            });
        }).then(() => setLoading(false));
    }, [userData, userAgencies, userWorkplaces]);

    if (loading) {
        return <Spinner />;
    }
    /**
     * render flex 1 to occupy space for 2 cards if side by side
     */
    return (
        <CCard style={[{ flex: 1 }]}>
            <CText bold headline>
                {format(generalMessages.recentNotifications)}
            </CText>
            {notifications.map((notification) => (
                <View
                    style={style.verticalPadded}
                    key={notification.documentId}
                >
                    <NotificationRow
                        notification={notification}
                        onChange={(next) => {
                            const index = notifications.findIndex(
                                (note) => note.documentId === next.documentId,
                            );
                            if (index >= 0) {
                                notifications[index] = next;
                                setNotifications([...notifications]);
                            }
                        }}
                        inCard
                    />
                </View>
            ))}
            {!notifications.length && (
                <View style={style.verticalPadded}>
                    <CImage image={'notifications'} maxWidth={250} />
                </View>
            )}
            <View style={{ flex: 1 }} />
            <View style={style.horizontalSpaced}>
                <CButton
                    title={format(generalMessages.showMore)}
                    onPress={() => navigate('/notifications')}
                    transparent
                    minor
                />
            </View>
        </CCard>
    );
};
