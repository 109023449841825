import React, { FC } from 'react';
import { useStyle, useTheme } from '../../../utilities/styles';
import { Message } from '../../../utilities/types';
import { View } from 'react-native';
import { TouchableView, CImage, CText } from '../../../components';
import { assets } from '../../../assets';

export const OnboardingPreselectButton: FC<{
    onPress: () => void;
    cy: string;
    image: keyof typeof assets;
    title: Message;
    description: Message;
}> = ({ onPress, cy, image, title, description }) => {
    const style = useStyle();
    const { theme } = useTheme();
    return (
        <TouchableView
            cy={cy}
            style={[
                style.horizontal,
                style.card,
                style.accentBorder,
                style.centeredItems,
            ]}
            onPress={onPress}
        >
            <CImage image={image} fixedHeight={50} />
            <View
                style={[
                    style.horizontalPadded,
                    style.verticalPadded,
                    style.flex1,
                ]}
            >
                <CText secondaryHeadline message={title} />
                <CText message={description} wrap />
            </View>
        </TouchableView>
    );
};
