import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useNavigate } from '../../../../utilities/routing';
import { CCard, CIcon, CImage, CText, WageView } from '../../../../components';
import { MContract, MWorkplace } from '../../../../models';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { jobMessages } from '../../../Job/job.messages';
import { isSuperUser } from '../../../../utilities/auth';
import { useFireBase } from '../../../../utilities/firebase';
import { ContractTitle } from '../../components/ContractTitle';
import { ContractState } from '../../components/ContractState';
import { EApplicationType, ECollections, EUserType } from '../../../../enums';
import { contractMessages } from '../../contract.messages';
import { generalMessages } from '../../../../utilities/messages';

interface IContractRowProps {
    negotiation: MContract;
    onPress?: () => void;
    embedded?: boolean;
    small?: boolean;
    highlight?: boolean;
}

export const ContractRow: FC<IContractRowProps> = ({
    negotiation,
    onPress,
    embedded,
    small,
    highlight,
}) => {
    const style = useStyle();
    const { userData, userWorkplaces, getDataById } = useFireBase();
    const { theme } = useTheme();
    const navigate = useNavigate();
    const [workplace, setWorkplace] = useState<MWorkplace>();

    useEffect(() => {
        if (userWorkplaces.length) {
            const woi = userWorkplaces.find(
                (wp) => wp.documentId === negotiation.workplaceId,
            );
            if (woi) setWorkplace(woi);
        } else if (negotiation.workplaceId) {
            getDataById(
                ECollections.publicWorkplaces,
                negotiation.workplaceId,
            ).then((res) => {
                setWorkplace(new MWorkplace(res));
            });
        }
    }, [negotiation, userWorkplaces]);

    return (
        <CCard
            cy={'negotiation-' + negotiation.jobId}
            style={highlight && style.accentBorder}
            onPress={() => {
                if (onPress) {
                    onPress();
                } else {
                    navigate('/contract/negotiation/' + negotiation.documentId);
                }
            }}
            embedded={embedded}
            hoverEffects
        >
            <View style={style.horizontalSplit}>
                <View
                    style={[style.horizontal, style.centeredItems, style.flex1]}
                >
                    <CIcon icon="chevronRight" />
                    <View style={style.flex1}>
                        <ContractTitle contract={negotiation} />
                        {negotiation.type !== EApplicationType.default && (
                            <View
                                style={[style.horizontal, style.centeredItems]}
                            >
                                <CIcon
                                    icon="calendar_outline"
                                    tint={theme.textSecondaryColor}
                                    size={14}
                                />
                                <CText
                                    style={style.leftPadded}
                                    message={jobMessages.fromTo}
                                />
                                <CText style={style.leftPadded}>
                                    {`${new Date(
                                        negotiation.from,
                                    ).toLocaleDateString('de')} - ${new Date(
                                        negotiation.to,
                                    ).toLocaleDateString('de')}`}
                                </CText>
                                <CText style={style.leftPadded}>
                                    {`${workplace?.name}`}
                                </CText>
                            </View>
                        )}
                        {negotiation.workplace?.address.lat &&
                            negotiation.workplace?.address.lng && (
                                <View
                                    style={[
                                        style.horizontal,
                                        style.centeredItems,
                                    ]}
                                >
                                    <CIcon
                                        icon="mapMarker_outline"
                                        tint={theme.textSecondaryColor}
                                        size={14}
                                    />
                                    <CText
                                        style={style.leftPadded}
                                        message={`${negotiation.workplace.address.street} ${negotiation.workplace.address.number}, ${negotiation.workplace.address.zipCode} ${negotiation.workplace.address.city}`}
                                    />
                                </View>
                            )}
                        <View style={[style.horizontal, style.centeredItems]}>
                            <CIcon
                                icon="calendar_outline"
                                tint={theme.textSecondaryColor}
                                size={14}
                            />
                            <CText
                                style={style.leftPadded}
                                message={generalMessages.createdOn}
                            />
                            <CText style={style.leftPadded}>
                                {new Date(
                                    negotiation.createdOn,
                                ).toLocaleDateString('de')}
                            </CText>
                        </View>
                    </View>
                </View>
                <View style={style.horizontal}>
                    {isSuperUser(userData) && (
                        <View
                            style={[
                                style.verticalPadded,
                                style.horizontalPadded,
                            ]}
                        >
                            <CImage
                                image={negotiation.region}
                                fixedHeight={20}
                            />
                        </View>
                    )}
                    <ContractState contract={negotiation} />
                </View>
            </View>
            {!small && userData.type !== EUserType.talent && (
                <View
                    style={[
                        style.verticalPadded,
                        style.horizontalSplit,
                        style.centeredItems,
                        { paddingHorizontal: 20 },
                    ]}
                >
                    <View style={[style.horizontal]}>
                        <WageView
                            value={negotiation.wage}
                            horizontal
                            fragment
                        />
                    </View>
                    {/* <View>
                        <CText bold>{format(jobMessages.fromTo)}</CText>
                        <CText>
                            {`${new Date(negotiation.from).toLocaleDateString(
                                'de',
                            )} - ${new Date(negotiation.to).toLocaleDateString(
                                'de',
                            )}`}
                        </CText>
                    </View> */}
                </View>
            )}
        </CCard>
    );
};
