import React, { FC, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useStyle, useTheme } from '../../../../../utilities/styles';
import { CIcon, CText } from '../../../../../components';
import { contractMessages } from '../../../contract.messages';
import { MUserData } from '../../../../../models';
import { useFireBase } from '../../../../../utilities/firebase';
import { ECollections } from '../../../../../enums';
import { ContractContext } from '../ContractContext';
import { isSuperUser } from '../../../../../utilities/auth';
import { useFormat } from '../../../../../utilities/intl';
/**
 * view if contract was canceled
 * @returns
 */
export const ContractCanceled: FC = () => {
    const style = useStyle();
    const format = useFormat();
    const { theme } = useTheme();
    const { contract } = useContext(ContractContext);
    const { getDataById, userData } = useFireBase();
    const [canceledFrom, setCanceledFrom] = useState<MUserData>();

    useEffect(() => {
        if (contract.canceledBy) {
            getDataById(ECollections.publicUsers, contract.canceledBy).then(
                (v) => {
                    setCanceledFrom(new MUserData(v));
                },
            );
        }
    }, [contract]);

    return (
        <View style={[style.card, style.horizontalSpaced]}>
            <View style={style.centeredItems}>
                <CIcon icon="close" tint={theme.errorColor} size={150} />
                {isSuperUser(userData) ? (
                    <CText
                        message={format(contractMessages.contractWasCanceled, {
                            cancelator:
                                canceledFrom?.firstName +
                                ' ' +
                                canceledFrom?.lastName,
                        })}
                        headline
                    />
                ) : (
                    <CText
                        message={contractMessages.contractWasCanceled}
                        headline
                    />
                )}
            </View>
        </View>
    );
};
