import React, { FC, useMemo } from 'react';
import { View, Linking } from 'react-native';
import { CCard, CIcon, CText } from '../../../components';
import { MNotification } from '../../../models/MNotification';
import { useFormat } from '../../../utilities/intl';
import { useSecureNavigate } from '../../../utilities/routing';
import { useStyle, useTheme } from '../../../utilities/styles';
import { useDimensions } from '../../../utilities/hooks/useDimensions';
import { useFireBase } from '../../../utilities/firebase';
import { ECollections } from '../../../enums';
import { timeString } from '../../../utilities/functions';

interface INotificationRowProps {
    notification: MNotification;
    onChange: (next: MNotification) => void;
    inCard?: boolean;
}

export const NotificationRow: FC<INotificationRowProps> = ({
    notification,
    inCard,
}) => {
    const style = useStyle();
    const format = useFormat();
    const { width } = useDimensions();
    const { theme } = useTheme();
    const { secureNavigate } = useSecureNavigate();
    const { userData, put } = useFireBase();
    /**
     * memoized value if unread or not
     */
    const unread = useMemo(
        () =>
            !notification.readBy.find(({ uid }) => uid === userData.documentId),
        [notification, userData],
    );

    const createdOnString = useMemo(() => {
        const createdOnDate = new Date(notification.createdOn);
        const ds = createdOnDate.toLocaleDateString('de');
        const ts = timeString(
            createdOnDate.getHours(),
            createdOnDate.getMinutes(),
        );

        return `${ds} - ${ts}`;
    }, [notification]);
    // const isPinned = useMemo(
    //     () =>
    //         notification.pinnedBy.findIndex(
    //             (pB) => pB.uid === userData.documentId,
    //         ),
    //     [notification, userData],
    // );
    return (
        <CCard
            hoverEffects
            style={[
                inCard && { padding: 5, borderRadius: 5 },
                unread && style.accentBorder,
            ]}
            embedded={inCard}
            onPress={
                notification.linkTo || notification.url
                    ? () => {
                          if (unread) {
                              const next = new MNotification(notification);
                              next.readBy.push({
                                  uid: userData.documentId,
                                  on: Date.now(),
                              });
                              put(
                                  ECollections.notifications,
                                  next.documentId,
                                  next,
                              );
                          }
                          if (notification.linkTo) {
                              secureNavigate(notification.linkTo);
                          } else {
                              Linking.openURL(notification.url);
                          }
                      }
                    : undefined
            }
        >
            <View style={style.horizontalSplit}>
                <View style={[style.centeredContent, style.horizontalPadded]}>
                    <CIcon
                        icon={notification.for ? notification.icon : 'globe'}
                        size={24}
                        tint={unread ? theme.accentColor : undefined}
                    />
                </View>
                <View style={{ flex: 1 }}>
                    {width <= 500 && (
                        <View style={style.horizontalSplit}>
                            <View style={{ flex: 1 }} />
                            <CText>{createdOnString}</CText>
                        </View>
                    )}
                    <CText bold headline>
                        {notification.titleKey
                            ? format(
                                  {
                                      id: notification.titleKey,
                                  },
                                  notification.variables,
                              )
                            : notification.title}
                    </CText>
                </View>
                {width > 500 && <CText>{createdOnString}</CText>}
                {/* {notification.for && (
                    <CButton
                        icon="pin"
                        onPress={() => {
                            if (isPinned < 0) {
                                notification.pinnedBy.push({
                                    uid: userData.documentId,
                                    on: Date.now(),
                                });
                            } else {
                                notification.pinnedBy.splice(isPinned, 1);
                            }
                            onChange(notification);

                            put(
                                ECollections.notifications,
                                notification.documentId,
                                notification,
                            );
                        }}
                        minor={isPinned < 0}
                        small
                        style={style.horizontalPadded}
                    />
                )} */}
            </View>
            <View style={{ marginLeft: 34 }}>
                <CText>
                    {notification.messageKey
                        ? format(
                              {
                                  id: notification.messageKey,
                              },
                              notification.variables,
                          )
                        : notification.message}
                </CText>
            </View>
        </CCard>
    );
};
