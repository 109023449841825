import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useNavigate, useParams } from '../../../utilities/routing';
import { CButton, CText, Spinner } from '../../../components';
import { MApplication } from '../../../models/MApplication';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { actionMessages } from '../../../utilities/messages';
import { useStyle, useTheme } from '../../../utilities/styles';
import { JobRow } from '../../Job/List/components/JobRow';
import { applicationMessages } from '../application.messages';
import { ApplicantData } from '../components/ApplicantData';
import { EApplicationStatus, ECollections, EUserType } from '../../../enums';
import { useDialog } from '../../../utilities/dialog';
import { ResumeView } from '../../../components/ResumeView/ResumeView';
import { ScrollProvider } from '../../../utilities/contexts/Scroll';
import { WageView } from '../../../components/Wage';
import { useEnvironment } from '../../../utilities/contexts';
import { MProfessionalInfo } from '../../../models';
/**
 * apply screen
 * @returns
 */
export const ApplicationView: FC = () => {
    // global state
    const style = useStyle();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const { put, getDataById, userData } = useFireBase();
    const format = useFormat();
    const dialog = useDialog();
    const { theme } = useTheme();
    const { environment } = useEnvironment();
    // local state
    const [application, setApplication] = useState(new MApplication());
    /**
     * effect to kick unverified user from this view
     */
    useEffect(() => {
        if (userData.documentId) {
            if (!userData.verified) {
                dialog({
                    title: format(applicationMessages.youAreNotVerified),
                    message: format(applicationMessages.verifyYourself, {
                        platform: environment,
                    }),
                    buttons: [
                        {
                            text: format(actionMessages.ok),
                            onPress: () => {
                                navigate('/job');
                            },
                        },
                    ],
                    icon: 'error',
                }).then(() => {
                    navigate('/job');
                });
            }
        }
    }, [userData, environment]);
    /**
     * effect to init application based on prev application, job, userData, prof profile
     */
    useEffect(() => {
        if (id) {
            getDataById(ECollections.applications, id).then((res) => {
                setApplication(new MApplication(res));
            });
        }
    }, [id]);
    /**
     * return spinner
     */
    if (!id || !application.documentId) {
        return <Spinner />;
    }
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={[style.headlineCard]}>
                <View style={[style.horizontal, style.centeredItems]}>
                    <CButton
                        cy={'back'}
                        onPress={async () => {
                            navigate(-1);
                        }}
                        icon={'chevronLeft'}
                        small
                    />
                    <CText bold headline style={style.horizontalPadded}>
                        {id ? format(applicationMessages.viewApplication) : ''}
                    </CText>
                </View>
            </View>
            {!!application.job.documentId && (
                <JobRow job={application.job} noButtons />
            )}
            <View style={style.card}>
                <CText style={style.verticalPadded} bold headline>
                    {format(applicationMessages.yourData)}
                </CText>
                <ApplicantData
                    applicantData={new MProfessionalInfo(application)}
                />
            </View>
            <View style={style.card}>
                <CText style={style.verticalPadded} bold headline>
                    {format(applicationMessages.yourResume)}
                </CText>
                <CText style={style.verticalPadded}>
                    {format(applicationMessages.yourResumeWillBeAnonym)}
                </CText>
                <ResumeView self profProfileId={application.profileId} />
            </View>
            {userData.type == EUserType.agency && (
                <View style={style.card}>
                    <WageView value={application.wage} />
                </View>
            )}
            <View style={style.horizontalSpaced}>
                <View style={style.horizontal}>
                    <CButton
                        danger
                        title={format(actionMessages.close)}
                        onPress={() => {
                            dialog({
                                message: format(
                                    applicationMessages.confirmCloseMessage,
                                ),
                                title: format(applicationMessages.confirmClose),
                                buttons: [
                                    {
                                        text: format(actionMessages.close),
                                        color: theme.errorColor,
                                        onPress: () =>
                                            put(ECollections.applications, id, {
                                                ...application,
                                                status: EApplicationStatus.closed,
                                            }).then(() =>
                                                navigate('/application'),
                                            ),
                                    },
                                ],
                                cancelButton: {
                                    text: format(actionMessages.cancel),
                                },
                            });
                        }}
                    />
                </View>
            </View>
        </ScrollProvider>
    );
};
