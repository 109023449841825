import React, { FC } from 'react';
import { View } from 'react-native';
import { MApplication, MContract, MJob, MRequest } from '../../../../models';
import { ApplicantOverviewBar } from '../../components/ApplicantOverviewBar';
import { JobOverviewBar } from '../../components/JobOverviewBar';
import { ContractOverviewBar } from '../../components/ContractOverviewBar';
import {
    isAgencyUser,
    isEmployer,
    isSuperUser,
} from '../../../../utilities/auth';
import { useFireBase } from '../../../../utilities/firebase';
import { WorkplaceSettings } from './WorkplaceSettings';
import { CButton, CText } from '../../../../components';
import {
    filenameMessages,
    generalMessages,
} from '../../../../utilities/messages';
import { useSecureNavigate } from '../../../../utilities/routing';
import { ContractExtensionButton } from './ContractExtensionButton';
import { EContractStatus, EUserType } from '../../../../enums';
import { isLocalhost } from '../../../../utilities/constants';
import { contractMessages } from '../../contract.messages';
import { useStyle } from '../../../../utilities/styles';
import { ContractCancelButton } from './ContractCancelButton';
import { SelectSigningEmployer } from '../../components/SelectSigningEmployer';
import { useFormat } from '../../../../utilities/intl';
import { ScrollProvider } from '../../../../utilities/contexts';

export const ContractDetails: FC<{
    contract: MContract;
    job?: MJob;
    request?: MRequest;
    application?: MApplication;
    setReload: (n: number) => void;
    openReview: () => Promise<boolean>;
    handleSigningEmployerChange: (signingEmployerId: string) => void;
}> = ({
    contract,
    job,
    request,
    application,
    setReload,
    openReview,
    handleSigningEmployerChange,
}) => {
    const style = useStyle();
    const format = useFormat();
    const { userData } = useFireBase();
    const { secureNavigate } = useSecureNavigate();
    return (
        <ScrollProvider>
            {userData.type !== EUserType.talent && contract.to > Date.now() && (
                <View style={style.card}>
                    <CText message={contractMessages.mainInfoText} />
                    {[
                        filenameMessages.leasingContract,
                        filenameMessages.equalPayDeclaration,
                    ].map((m, i) => (
                        <CText key={m.id} message={`${i + 1}. ${format(m)}`} />
                    ))}
                    <View style={style.paddedThinSeparator} />
                    <CText message={contractMessages.secondaryInfoText} />
                    {[
                        filenameMessages.houseRules,
                        filenameMessages.locationPlan,
                        filenameMessages.contactDetails,
                        filenameMessages.educationCertificate,
                        filenameMessages.driversLicense,
                        filenameMessages.timesheetTemplate,
                    ].map((m, i) => (
                        <CText key={m.id} message={`${i + 1}. ${format(m)}`} />
                    ))}
                </View>
            )}
            <ContractOverviewBar contract={contract} />
            {job && <JobOverviewBar job={job} />}
            {(application || contract.employee) && (
                <ApplicantOverviewBar
                    request={request}
                    application={application}
                    applicant={contract.employee}
                />
            )}
            {(isSuperUser(userData) || isEmployer(userData)) && (
                <WorkplaceSettings
                    contract={contract}
                    issueReload={() => setReload(Date.now())}
                />
            )}
            <SelectSigningEmployer
                contract={contract}
                onChange={handleSigningEmployerChange}
            />
            {!!contract.profileId && isAgencyUser(userData) && (
                <CButton
                    icon={'account_outline'}
                    title={generalMessages.talent}
                    small
                    minor
                    style={style.smallMargin}
                    onPress={() =>
                        secureNavigate('/profUser/' + contract.profileId)
                    }
                />
            )}
            {contract.status !== EContractStatus.completed && (
                <ContractExtensionButton
                    contract={contract}
                    issueReload={() => setReload(Date.now())}
                />
            )}
            {(isLocalhost() ||
                contract.status === EContractStatus.completed) && (
                <CButton
                    title={contractMessages.provideReview}
                    onPress={openReview}
                    icon={'star'}
                    small
                    minor
                    style={style.smallMargin}
                />
            )}
            <ContractCancelButton contract={contract} />
        </ScrollProvider>
    );
};
