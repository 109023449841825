import React, { FC, useMemo } from 'react';
import { View } from 'react-native';
import { IShiftPlanComponentProps } from '../IShiftPlanComponentProps';
import { CButton, CCard, CDataTable, IRowProps } from '../../../../components';
import { useStyle } from '../../../../utilities/styles';
import { actionMessages, dayMessages } from '../../../../utilities/messages';
import { shiftPlanMessages } from '../../shiftPlan.messages';
import { timeStringFromHours } from '../../../../utilities/functions';

export const ShiftPlanDisplay: FC<IShiftPlanComponentProps> = ({
    shiftPlan,
    setEdit,
}) => {
    const style = useStyle();

    const rows = useMemo(() => {
        const nextRows: IRowProps[] = [];
        shiftPlan.groups.map((group) => {
            shiftPlan.workers
                .filter((w) =>
                    shiftPlan.shifts.find(
                        (s) => s.worker === w.id && s.group === group.id,
                    ),
                )
                .map((worker) =>
                    nextRows.push({
                        rowKey: group.id + worker.id,
                        values: [
                            { headerKey: 'group', value: group.name },
                            { headerKey: 'worker', value: worker.name },
                            ...Object.keys(dayMessages)
                                .filter((key) => !key.includes('Short'))
                                .map((key, i) => {
                                    const todaysShifts = shiftPlan.shifts
                                        .filter(
                                            (s) =>
                                                s.day === i &&
                                                s.worker === worker.id &&
                                                group.id === s.group,
                                        )
                                        .sort((a, b) => a.from - b.from);
                                    return {
                                        headerKey: key,
                                        value: todaysShifts.length
                                            ? todaysShifts
                                                  .map(
                                                      (s) =>
                                                          `${timeStringFromHours(
                                                              s.from,
                                                          )} - ${timeStringFromHours(
                                                              s.to,
                                                          )}`,
                                                  )
                                                  .join('\n')
                                            : `-`,
                                    };
                                }),
                        ],
                        subRows: [],
                    }),
                );
        });
        shiftPlan.groups.map((group) => {
            const unassignedShifts = shiftPlan.shifts.filter(
                (s) => !s.worker && s.group === group.id,
            );
            if (unassignedShifts.length) {
                nextRows.push({
                    rowKey: group.id,
                    values: [
                        { headerKey: 'group', value: group.name },
                        {
                            headerKey: 'worker',
                            value: 'unassigned',
                            component: (
                                <CButton
                                    smallest
                                    title={'Find a Talent'}
                                    onPress={() => {
                                        // TODO
                                    }}
                                />
                            ),
                        },
                        ...Object.keys(dayMessages)
                            .filter((key) => !key.includes('Short'))
                            .map((key, i) => {
                                const todaysShifts = unassignedShifts.filter(
                                    (s) => s.day === i,
                                );
                                return {
                                    headerKey: key,
                                    value: todaysShifts.length
                                        ? todaysShifts
                                              .map(
                                                  (s) =>
                                                      `${timeStringFromHours(
                                                          s.from,
                                                      )} - ${timeStringFromHours(
                                                          s.to,
                                                      )}`,
                                              )
                                              .join('\n')
                                        : `-`,
                                };
                            }),
                    ],
                    subRows: [],
                });
            }
        });
        return nextRows;
    }, []);

    return (
        <View>
            <CCard>
                <CButton
                    title={actionMessages.edit}
                    onPress={() => setEdit(true)}
                    warning
                />
            </CCard>
            <CCard>
                <CDataTable
                    headers={[
                        { headerKey: 'group', label: shiftPlanMessages.group },
                        {
                            headerKey: 'worker',
                            label: shiftPlanMessages.worker,
                        },
                        ...Object.keys(dayMessages)
                            .filter((key) => !key.includes('Short'))
                            .map((key) => ({
                                headerKey: key,
                                label: dayMessages[
                                    key as keyof typeof dayMessages
                                ],
                            })),
                    ]}
                    rows={rows}
                />
            </CCard>
            {/* <View style={[style.horizontal, { justifyContent: 'center' }]}>
                <CButton
                    title={actionMessages.back}
                    onPress={goBackward}
                    warning
                />
            </View> */}
        </View>
    );
};
