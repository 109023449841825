import { defineMessages } from 'react-intl';

const scope = 'ride.dashboard.';

export const dashboardRideMessages = defineMessages({
    welcome: {
        id: scope + 'welcome',
        defaultMessage:
            'Welcome to {platform}! We will briefly introduce you to this screen.',
    },
    progression: {
        id: scope + 'progression',
        defaultMessage: 'Here you can track the completion of your account',
    },
    thisIsYou: {
        id: scope + 'thisIsYou',
        defaultMessage: 'Click here to navigate to your profile!',
    },
    navigation: {
        id: scope + 'navigation',
        defaultMessage: 'This is the navigation menu',
    },
    youAreOnDashboard: {
        id: scope + 'youAreOnDashboard',
        defaultMessage:
            'You are currently on your dashboard, where you will be able to find most important informations',
    },
});
