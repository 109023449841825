import React, { FC } from 'react';
import GitInfo from 'react-git-info/macro';
import { CText } from '../../elements';

export const Version: FC = () => {
    return (
        <CText style={{ fontSize: 10 }}>
            client version {GitInfo().commit.shortHash}
        </CText>
    );
};
