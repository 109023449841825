import React, { FC, useEffect } from 'react';
import { View } from 'react-native';
import { useParams } from '../../../utilities/routing';
import { CText } from '../../../components';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { useStyle } from '../../../utilities/styles';
import { urlHandlerMessages } from '../urlHandler.messages';
import { ScrollProvider } from '../../../utilities/contexts/Scroll';

export const UnsubscribeFromNotifications: FC = () => {
    const style = useStyle();
    const format = useFormat();
    const { callFunction } = useFireBase();
    const { uid, notification } =
        useParams<{ uid: string; notification: string }>();

    useEffect(() => {
        if (uid && notification) {
            callFunction('unsubscribeFromNotifications', { uid, notification });
        }
    }, [uid, notification]);

    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={[style.card, style.horizontalSpaced]}>
                <CText bold headline>
                    {format(urlHandlerMessages.unsubscribe)}
                </CText>
            </View>
        </ScrollProvider>
    );
};
