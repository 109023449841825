import React, { FC, useEffect } from 'react';
import { MRates, MWage } from '../../../models';
import { MessageDescriptor } from 'react-intl';
import { View } from 'react-native';
import { useStyle } from '../../../utilities/styles';
import { CTextInput, CCheckBox } from '../../elements';
import { WageInput } from '../Input';
import { CalculatedWage } from './CalculatedWage';
import { wageMessages } from '../wage.messages';
/**
 * sub rate column component to reduce redundant code
 */
export const SubRateColumn: FC<{
    rates: MRates;
    useThis: boolean;
    useThisMessage: MessageDescriptor;
    onChangeUseThis: (next: boolean) => void;
    wage: MWage;
    onChangeWage: (next: MWage) => void;
    useFactor: boolean;
    onChangeUseFactor: (next: boolean) => void;
    factor: number;
    onChangeFactor: (next: number) => void;
}> = ({
    rates,
    useThis,
    useThisMessage,
    onChangeUseThis,
    wage,
    onChangeWage,
    useFactor,
    onChangeUseFactor,
    factor,
    onChangeFactor,
}) => {
    const style = useStyle();
    /**
     * effect to apply rate to current wage
     */
    useEffect(() => {
        if (factor && useFactor) {
            const next = new MWage({
                wage: rates.wage * factor,
                regularWage: rates.regularWage * factor,
                standbyWage: rates.standbyWage * factor,
                onCallActiveWage: rates.onCallActiveWage * factor,
                onCallPassiveWage: rates.onCallPassiveWage * factor,
            });
            if (JSON.stringify(next) !== JSON.stringify(wage)) {
                onChangeWage(next);
            }
        }
    }, [factor, useFactor, rates, wage]);
    /**
     * render
     */
    return (
        <View
            style={[
                style.horizontalPadded,
                { minWidth: 150, justifyContent: 'space-between' },
            ]}
        >
            <View
                style={{
                    height: 40,
                    justifyContent: 'center' as const,
                }}
            >
                <CCheckBox
                    title={useThisMessage}
                    checked={useThis}
                    onCheckedChanged={onChangeUseThis}
                />
            </View>
            {useThis && (
                <>
                    <View
                        style={[
                            {
                                height: 40,
                                justifyContent: 'center' as const,
                                alignItems: 'center',
                            },
                            style.horizontalSplit,
                        ]}
                    >
                        <CCheckBox
                            title={
                                useFactor
                                    ? wageMessages.multiplicator
                                    : wageMessages.useMultiplicator
                            }
                            checked={useFactor}
                            onCheckedChanged={onChangeUseFactor}
                        />
                        {useFactor && (
                            <CTextInput
                                value={factor.toFixed(2).replace('.', ',')}
                                onChangeText={(s) => {
                                    const strippedS = s.replace(/\D/g, '');
                                    if (isNaN(+strippedS)) return;
                                    onChangeFactor(Number(strippedS) / 100);
                                }}
                                length={4}
                                style={{ margin: 0 }}
                                containerStyle={{ padding: 0 }}
                            />
                        )}
                    </View>
                    {useFactor ? (
                        <CalculatedWage baseRate={rates} factor={factor} />
                    ) : (
                        <WageInput
                            noLabels
                            onChange={onChangeWage}
                            value={wage}
                            src={rates}
                        />
                    )}
                </>
            )}
        </View>
    );
};
