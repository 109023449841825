import { FullMetadata } from 'firebase/storage';
import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { View } from 'react-native';
import { Spinner } from '../../../../../components';
import PDFViewer from '../../../../../components/PDFViewer';
import { EUserType } from '../../../../../enums';
import { useFireBase } from '../../../../../utilities/firebase';
import { useStyle } from '../../../../../utilities/styles';
import { ContractContext } from '../ContractContext';
/**
 * see both documents in their respective current form
 * @param param0 props
 * @returns
 */
export const DocumentAdminView: FC = () => {
    // global state
    const { callFunction, getFileMetadata } = useFireBase();
    const style = useStyle();
    // context
    const { contract } = useContext(ContractContext);
    // local state
    const [workContract, setWorkContract] = useState<string>();
    const [workContractMeta, setWorkContractMeta] = useState<FullMetadata>();
    const [leasingContract, setLeasingContract] = useState<string>();
    const [leasingContractMeta, setLeasingContractMeta] =
        useState<FullMetadata>();
    /**
     * memoized loading value. returns true while filename or meta is unset
     */
    const loading = useMemo(
        () =>
            !leasingContract ||
            !leasingContractMeta ||
            !workContract ||
            !workContractMeta,
        [leasingContract, leasingContractMeta, workContract, workContractMeta],
    );
    /**
     * handle filename
     */
    const handleLeasingContractFileName = useCallback((fn: string) => {
        setLeasingContract(`${fn}`);
        getFileMetadata(fn).then((metaRes) => {
            setLeasingContractMeta(metaRes);
        });
    }, []);
    /**
     * handle filename
     */
    const handleWorkContractFlieName = useCallback((fn: string) => {
        setWorkContract(`${fn}`);
        getFileMetadata(fn).then((metaRes) => {
            setWorkContractMeta(metaRes);
        });
    }, []);
    /**
     * effect to create contract / get read access and get metadata
     */
    useEffect(() => {
        callFunction('getContractDocument', {
            contractId: contract.documentId,
            contract,
            type: EUserType.user,
        }).then((res) => {
            handleWorkContractFlieName(res);
        });
        callFunction('getContractDocument', {
            contractId: contract.documentId,
            contract,
            type: EUserType.employer,
        }).then((res) => {
            handleLeasingContractFileName(res);
        });
    }, []);
    /**
     * while document not present return spinner
     */
    if (loading) {
        return <Spinner />;
    }
    /**
     * render
     */
    return (
        <View style={[style.card]}>
            <View style={style.verticalPadded}>
                {leasingContract && <PDFViewer filename={leasingContract} />}
            </View>
            <View style={style.verticalPadded}>
                {workContract && <PDFViewer filename={workContract} />}
            </View>
        </View>
    );
};
