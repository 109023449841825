import React, { Dispatch, FC, SetStateAction } from 'react';
import { View } from 'react-native';
import { IDialogMultiRadio } from '../IDialog';
import { CText, CCheckBox } from '../../../components';

export const MultiRadioInput: FC<{
    values: { id: string; value: any }[];
    multiRadioInput: IDialogMultiRadio;
    onChange: Dispatch<SetStateAction<{ id: string; value: any }[]>>;
}> = ({ values, onChange, multiRadioInput }) => {
    return (
        <View>
            <CText secondaryHeadline message={multiRadioInput.title} />
            {multiRadioInput.values.map((v, i) => (
                <CCheckBox
                    key={i}
                    title={v.label}
                    onCheckedChanged={(c) =>
                        onChange((prev) => {
                            if (c) {
                                return [...prev, v];
                            } else {
                                const indexToRemove = prev.findIndex(
                                    (i) => i.id === v.id,
                                );
                                const next = [...prev];
                                next.splice(indexToRemove, 1);
                                return next;
                            }
                        })
                    }
                    checked={!!values.find((e) => e.id === v.id)}
                />
            ))}
        </View>
    );
};
