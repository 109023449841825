import React, { FC } from 'react';
import { View } from 'react-native';
import { CText } from '../../../components';
import { useFormat } from '../../../utilities/intl';
import { useStyle } from '../../../utilities/styles';
import { jobMessages } from '../../Job/job.messages';
import { MContract } from '../../../models';
import { RatesView } from '../../../components/Wage/RatesView';
import { contractMessages } from '../contract.messages';

export const ContractOverviewBar: FC<{ contract: MContract }> = ({
    contract,
}) => {
    const style = useStyle();
    const format = useFormat();
    /**
     * render
     */
    return (
        <View style={style.card} nativeID="contract-details">
            <View
                style={[
                    style.horizontalSplit,
                    style.horizontalPadded,
                    style.horizontalWrap,
                ]}
            >
                <View style={{ flexGrow: 1 }}>
                    <RatesView
                        curData={contract.wage}
                        horizontal
                        injectedFields={[
                            <View key={'fromto'}>
                                {!contract.tos.length && (
                                    <>
                                        <CText bold>
                                            {format(jobMessages.fromTo)}
                                        </CText>
                                        <CText>
                                            {`${new Date(
                                                contract.from,
                                            ).toLocaleDateString(
                                                'de',
                                            )} - ${new Date(
                                                contract.to,
                                            ).toLocaleDateString('de')}`}
                                        </CText>
                                    </>
                                )}
                                {!!contract.tos.length && (
                                    <>
                                        <CText bold>
                                            {format(jobMessages.fromTo)}
                                        </CText>
                                        <CText>
                                            {`${new Date(
                                                contract.from,
                                            ).toLocaleDateString(
                                                'de',
                                            )} - ${new Date(
                                                contract.tos[0].to,
                                            ).toLocaleDateString('de')}`}
                                        </CText>
                                        {contract.tos.map((t, i) => {
                                            if (i)
                                                return (
                                                    <View>
                                                        <CText
                                                            message={`${format(
                                                                contractMessages.extension,
                                                            )} ${i}: ${new Date(
                                                                t.to,
                                                            ).toLocaleDateString(
                                                                'de',
                                                            )}`}
                                                        />
                                                    </View>
                                                );
                                        })}
                                    </>
                                )}
                            </View>,
                        ]}
                    />
                </View>
            </View>
        </View>
    );
};
