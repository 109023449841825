import { createContext } from 'react';

export const PushNotificationContext = createContext(
    {} as {
        retryRegister: () => void;
        sendNotification: (
            title: string,
            message: string,
            url: string,
            force?: boolean,
        ) => void;
    },
);
