import React, { FC, useCallback, useState } from 'react';
import { CButton } from '../../../../../components';
import { ECollections } from '../../../../../enums';
import { MChat } from '../../../../../models';
import { useFireBase } from '../../../../../utilities/firebase';

export const TouchChats: FC = () => {
    const { getDataIndex, put } = useFireBase();

    const [touchingChats, setTouchingChats] = useState(false);
    /**
     * callback to touch all jobs
     */
    const touchChats = useCallback(() => {
        setTouchingChats(true);
        getDataIndex(ECollections.chats).then((chats) => {
            Promise.all(
                (chats as MChat[]).map((chat) => {
                    const next = new MChat(chat);
                    return put(ECollections.chats, chat.documentId, {
                        ...next,
                        touched: Date.now(),
                    });
                }),
            ).then(() => setTouchingChats(false));
        });
    }, [getDataIndex, put]);

    return (
        <CButton
            onPress={touchChats}
            title="Touch all Chats"
            warning
            disabled={touchingChats}
        />
    );
};
