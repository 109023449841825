export class MShiftPlanShift {
    public worker?: string;
    public group = '';
    public from = 0;
    public to = 0;
    public day = 0;

    public level = 0;

    constructor(params: Partial<MShiftPlanShift> = {}) {
        Object.assign(this, params);
    }
}
