import React, { FC, useMemo } from 'react';
import { Platform, View } from 'react-native';
import { CText, CButton } from '../elements';
import Clipboard from '@react-native-clipboard/clipboard';
import { useStyle } from '../../utilities/styles';
import { MInvite } from '../../models';

export const InviteRow: FC<{ invite: MInvite }> = ({ invite }) => {
    const style = useStyle();
    const url = useMemo(() => {
        const basepath =
            Platform.OS === 'web'
                ? // @ts-ignore
                  window?.location?.hostname
                : 'app.timeployees.de';
        const isLocalhost = basepath === 'localhost';
        return `${isLocalhost ? '' : 'https://'}${basepath}${
            isLocalhost ? ':3000' : ''
        }/invite?invite=${invite.documentId}&target=${invite.targetId}&type=${
            invite.type
        }`;
    }, [invite]);
    return (
        <View style={[style.horizontalSplit]}>
            <View style={style.centeredContent}>
                <CText bold message={url} />
                <CText
                    message={new Date(invite.createdOn).toLocaleDateString(
                        'de',
                    )}
                />
            </View>
            <View style={style.horizontal}>
                <CButton
                    icon={'clipboardMulti_outline'}
                    onPress={() => {
                        Clipboard.setString(url);
                    }}
                    minor
                    transparent
                />
            </View>
        </View>
    );
};
