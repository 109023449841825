import React, { FC } from 'react';
import { ProfessionalHistory } from '../../ProfProfile/Edit/components/ProfessionalHistory';
import { ProfessionalInformation } from '../../ProfProfile/Edit/components/ProfessionalInformation';
import { ProfessionalLanguage } from '../../ProfProfile/Edit/components/ProfessionalLanguage';
/**
 * profile section for professional data. selfloads data
 * agencies may see multiple professional information sets
 * @param param0
 * @returns
 */
export const ProfileSectionProfessional: FC = () => {
    /**
     * render
     */
    return (
        <>
            <ProfessionalInformation />
            <ProfessionalLanguage />
            <ProfessionalHistory />
        </>
    );
};
