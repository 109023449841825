import React, { FC, useCallback, useState } from 'react';
import { useFireBase } from '../../../../../utilities/firebase';
import { ECollections } from '../../../../../enums';
import { MUserData } from '../../../../../models';
import { CButton } from '../../../../../components';

export const RemovePubUsers: FC = () => {
    const { getDataIndex, remove } = useFireBase();

    const [refershingPubUsers, setRefreshingPubUsers] = useState(false);
    /**
     * callback to touch all userData
     */
    const refreshPubUsers = useCallback(() => {
        setRefreshingPubUsers(true);
        getDataIndex(ECollections.publicUsers).then((users) => {
            Promise.all(
                (users as MUserData[]).map((user) => {
                    const next = new MUserData(user);
                    return remove(ECollections.publicUsers, next.documentId);
                }),
            ).then(() => {
                setRefreshingPubUsers(false);
            });
        });
    }, []);

    return (
        <CButton
            onPress={refreshPubUsers}
            title="Refresh Pub users"
            warning
            disabled={refershingPubUsers}
        />
    );
};
