import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { CButton, CIcon, CText, Spinner } from '../../../components';
import { useFormat } from '../../../utilities/intl';
import { urlHandlerMessages } from '../urlHandler.messages';
import { useFireBase } from '../../../utilities/firebase';
import {
    useNavigate,
    useParams,
    useSearchParams,
} from '../../../utilities/routing';
import { StandaloneWrapper } from '../../../utilities/wrappers';
import { useStyle, useTheme } from '../../../utilities/styles';

export const ConfirmJobUnverified: FC = () => {
    const { id } = useParams<{ id: string }>();
    const format = useFormat();
    const navigate = useNavigate();
    const { callFunction, isLoggedIn } = useFireBase();
    const style = useStyle();
    const { theme } = useTheme();
    const [urlSearchParams] = useSearchParams();
    const [verified, setVerified] = useState(false);
    /**
     * effect to trigger confirm function
     */
    useEffect(() => {
        callFunction('confirmUnverifiedJob', { id }).then(() =>
            setVerified(true),
        );
    }, [id]);
    /**
     * body to return in render inside standalone wrapper or plain
     */
    const body = useMemo(() => {
        if (verified) {
            return (
                <View>
                    <View style={[style.centeredItems, style.verticalPadded]}>
                        <CIcon
                            icon="check"
                            size={48}
                            tint={theme.successColor}
                        />
                    </View>
                    <CText
                        message={format(urlHandlerMessages.confirmedJobListing)}
                    />
                    <CButton
                        title={format(urlHandlerMessages.toRegistration)}
                        onPress={() => {
                            const mailToPass = urlSearchParams.get('m');
                            if (mailToPass) {
                                navigate('/?m=' + mailToPass);
                            } else {
                                navigate('/');
                            }
                        }}
                    />
                </View>
            );
        } else {
            return (
                <View>
                    <Spinner />
                    <CText
                        message={format(
                            urlHandlerMessages.confirmingJobListing,
                        )}
                    />
                </View>
            );
        }
    }, [verified]);
    /**
     * just return the body if logged in or wrap it to be standalone
     */
    if (isLoggedIn) {
        return body;
    } else {
        return <StandaloneWrapper>{body}</StandaloneWrapper>;
    }
};
