import React, { FC, useCallback, useState } from 'react';
import { useFireBase } from '../../../../../utilities/firebase';
import { ECollections } from '../../../../../enums';
import { MUserData } from '../../../../../models';
import { CButton } from '../../../../../components';

export const FixUserFileStructure: FC = () => {
    const {
        getDataIndex,
        getFile,
        uploadToStorage,
        getFileMetadata,
        put,
        removeFile,
    } = useFireBase();

    const [fixingFiles, setFixingFiles] = useState(false);
    /**
     * callback to create agencies from agency users
     */
    const fixFiles = useCallback(() => {
        setFixingFiles(true);
        getDataIndex(ECollections.users, {
            filter: [],
        }).then((users) => {
            Promise.all(
                (users as any[]).map(async (rawUser) => {
                    const user = new MUserData(rawUser);
                    if (user.picture) {
                        const meta = await getFileMetadata(user.picture);
                        const nextFn = `pictures/${
                            user.documentId
                        }/${Date.now().toString(36)}.${
                            meta.name.split('.')[1]
                        }`;
                        const prevFileGetResponse = await getFile(user.picture);
                        await uploadToStorage(
                            nextFn,
                            new Uint8Array(prevFileGetResponse),
                        );
                        await removeFile(user.picture);
                        user.picture = nextFn;
                    }
                    for (const prevFile of user.files) {
                        const prevPathSplit = prevFile.name.split('/');
                        const nextFn = `user/${user.documentId}/${
                            prevPathSplit[prevPathSplit.length - 1]
                        }`;
                        if (nextFn !== prevFile.name) {
                            const prevFileGetResponse = await getFile(
                                prevFile.name,
                            );
                            await uploadToStorage(
                                nextFn,
                                new Uint8Array(prevFileGetResponse),
                            );
                            await removeFile(prevFile.name);
                            prevFile.name = nextFn;
                        }
                    }
                    return await put(ECollections.users, user.documentId, {
                        ...user,
                    });
                }),
            ).then(() => setFixingFiles(false));
        });
    }, [getDataIndex, put]);

    return (
        <CButton
            onPress={fixFiles}
            title="Fix user file structure"
            danger
            disabled={fixingFiles}
        />
    );
};
