import { defineMessages } from 'react-intl';

const scope = 'con.helpCenter.';

export const helpCenterMessages = defineMessages({
    searchPlaceholder: {
        id: scope + 'searchPlaceholder',
        defaultMessage: 'Search for a specific topic',
    },
    buttonText: {
        id: scope + 'buttonText',
        defaultMessage: 'Button text',
    },
    buttonUrl: {
        id: scope + 'buttonUrl',
        defaultMessage: 'Button url',
    },
    stillNeedHelp: {
        id: scope + 'stillNeedHelp',
        defaultMessage: 'Do you still need Help?',
    },
    aAgentWillContactYou: {
        id: scope + 'aAgentWillContactYou',
        defaultMessage: 'An agent will contact you as soon as possible.',
    },
    talkToYourAgent: {
        id: scope + 'talkToYourAgent',
        defaultMessage: 'Open your support chat',
    },
});
