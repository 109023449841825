import { createContext } from 'react';
import { User } from 'firebase/auth';
import { MAgency, MUserData, MWorkplace } from '../../models';

export const FireBaseContext = createContext(
    {} as {
        user: User | undefined;
        userData: MUserData;
        mfaInfo?: boolean;
        userWorkplaces: MWorkplace[];
        userAgencies: MAgency[];
        // downloadFile: (filename: string) => Promise<any>;
        isLoggedIn: boolean;
        requiresOnboarding: boolean;
        loading: boolean;
        reloadUserData: () => void;
        disabledAttempt: number;
    },
);
