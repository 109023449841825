import { MShiftPlan, MShiftPlanShift } from '../../../../../models';
import { dayMessages } from '../../../../../utilities/messages';
/**
 * value to determin when to use 45 min breaks
 */
const BREAK_THRESHOLD = 9;
/**
 * value to determin the hour time frame in the middle of the day to spread breaks across
 */
const BREAK_PERIOD = 3;
/**
 * function to create a layout of shifts which could fit the workgroup requirements
 * @param shiftPlan shiftplan with workgroups to create shifts for
 * @returns shifts without workers assigned
 */
export const prepareShifts = (shiftPlan: MShiftPlan) => {
    const shifts: MShiftPlanShift[] = [];

    const groupsWithoutBreaks = shiftPlan.groups.filter((g) => g.noBreaks);
    const groupsWithBreaks = shiftPlan.groups.filter((g) => !g.noBreaks);
    const dayKeys = Object.keys(dayMessages).filter(
        (key) => !key.includes('Short'),
    );
    for (const dayKey of dayKeys) {
        const day = dayKeys.indexOf(dayKey);
        const todaysGroupsWOB = groupsWithoutBreaks.filter((g) =>
            g.activeDays.find((d) => d.day === day),
        );
        /**
         * amount of breaks of 45min to spread across groups
         */
        const amountOfBigBreaks = todaysGroupsWOB
            .filter((g) => {
                const d = g.activeDays.find((d) => d.day === day);
                d && d.to - d.from >= BREAK_THRESHOLD;
            })
            .reduce((acc, g) => acc + g.workerCount, 0);
        /**
         * amount of breaks of 30min to spread across groups
         */
        const amountOfSmallBreaks = todaysGroupsWOB
            .filter((g) => {
                const d = g.activeDays.find((d) => d.day === day);
                d && d.to - d.from < BREAK_THRESHOLD;
            })
            .reduce((acc, g) => acc + g.workerCount, 0);
        /**
         * total time spent on breaks
         */
        const sumBreakTime =
            amountOfBigBreaks * 0.75 + amountOfSmallBreaks * 0.5;
        /**
         * estimated breakcycle amount
         */
        const breakCycles = 1 + Math.floor(sumBreakTime / BREAK_PERIOD);
        /**
         * tracker of end of last break to start next break on
         */
        let lastBreakEnd = 0;
        for (const group of todaysGroupsWOB) {
            const dayData = group.activeDays.find((d) => d.day === day);
            if (!dayData) continue;
            const longBreak = dayData.to - dayData.from >= BREAK_THRESHOLD;
            const mid = (dayData.to + dayData.from) / 2;
            const breakTime = longBreak ? 0.75 : 0.5;
            let firstBreakInGroupStart = 0;
            /**
             * generate workerCount shifts with not overlapping breaks
             */
            for (let i = 0; i < group.workerCount; i++) {
                const initialOffset =
                    (breakCycles > 1 ? BREAK_PERIOD : breakTime) / 2;
                const breakStartTime = lastBreakEnd
                    ? lastBreakEnd
                    : mid - initialOffset;
                /**
                 * break from to
                 * TODO: improve the determination of break positions to overlap less
                 */
                const breakData = {
                    from: breakStartTime,
                    to: breakStartTime + breakTime,
                };
                if (!firstBreakInGroupStart) {
                    firstBreakInGroupStart = breakData.from;
                }
                shifts.push(
                    new MShiftPlanShift({
                        from: dayData.from,
                        to: breakData.from,
                        group: group.id,
                        day: day,
                    }),
                );
                shifts.push(
                    new MShiftPlanShift({
                        from: breakData.to,
                        to: dayData.to,
                        group: group.id,
                        day: day,
                    }),
                );
                lastBreakEnd = breakData.to;
            }
            shifts.push(
                new MShiftPlanShift({
                    from: firstBreakInGroupStart,
                    to: lastBreakEnd,
                    group: group.id,
                    day: day,
                }),
            );
        }
        /**
         * todays groups which are allowed to have a break for the whole group
         */
        const todaysGroupsWB = groupsWithBreaks.filter((g) =>
            g.activeDays.find((d) => d.day === day),
        );
        for (const group of todaysGroupsWB) {
            const dayData = group.activeDays.find((d) => d.day === day);
            if (!dayData) continue;
            const longBreak = dayData.to - dayData.from >= BREAK_THRESHOLD;
            const mid = (dayData.to + dayData.from) / 2;
            const breakTime = longBreak ? 0.75 : 0.5;
            const b = { from: mid - breakTime / 2, to: mid + breakTime / 2 };
            for (let i = 0; i < group.workerCount; i++) {
                shifts.push(
                    new MShiftPlanShift({
                        from: dayData.from,
                        to: b.from,
                        group: group.id,
                        day: day,
                    }),
                );
                shifts.push(
                    new MShiftPlanShift({
                        from: b.to,
                        to: dayData.to,
                        group: group.id,
                        day: day,
                    }),
                );
            }
        }
    }

    return shifts;
};
