import { defineMessages } from 'react-intl';

const scope = 'con.requestSignature.';

export const requestSignatureMessages = defineMessages({
    selectPositionsOnDocument: {
        id: scope + 'selectPositionsOnDocument',
        defaultMessage: 'Select positions for signatures',
    },
    selectPositionsOnDocumentHint: {
        id: scope + 'selectPositionsOnDocumentHint',
        defaultMessage:
            'Please select at least one position for each party where the digital signature should be displayed on the document. You can create a new position by selecting any location on the current document.',
    },
    yourPositions: {
        id: scope + 'yourPositions',
        defaultMessage: 'Your positions',
    },
    otherPositions: {
        id: scope + 'otherPositions',
        defaultMessage: 'Other positions',
    },
    confirmSelection: {
        id: scope + 'confirmSelection',
        defaultMessage: 'Confirm selection',
    },
    positionX: {
        id: scope + 'positionX',
        defaultMessage: 'Position {x}',
    },
    yourPositionX: {
        id: scope + 'yourPositionX',
        defaultMessage: 'Your position {x}',
    },
    partnerPositionX: {
        id: scope + 'partnerPositionX',
        defaultMessage: 'Partner position {x}',
    },
    addSignaturePosition: {
        id: scope + 'addSignaturePosition',
        defaultMessage: 'Add signature position',
    },
    page: {
        id: scope + 'page',
        defaultMessage: 'Page',
    },
    addNewPosition: {
        id: scope + 'addNewPosition',
        defaultMessage:
            'Add the selected postion as signature position to this document?',
    },
    addNewPositionMessage: {
        id: scope + 'addNewPositionMessage',
        defaultMessage:
            'Do you want to add the selected position as signature position? If so please select if you have to sign there or your contract partner',
    },
    forPartner: {
        id: scope + 'forPartner',
        defaultMessage: 'For partner',
    },
    forSelf: {
        id: scope + 'forSelf',
        defaultMessage: 'For yourself',
    },
});
