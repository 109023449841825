import React, { FC, useCallback, useMemo } from 'react';
import { CText, WageInput } from '../../components';
import { useStyle } from '../../utilities/styles';
import { View } from 'react-native';
import { wageMessages } from './wage.messages';
import { MRates, MWage } from '../../models';
import { SubRateColumn } from './components/SubRateColumn';
/**
 * curData edit props
 */
interface IRatesProps {
    onChange: (next: MRates) => void;
    curData: MRates;
}
/**
 * Rates edit view
 */
export const Rates: FC<IRatesProps> = ({ curData, onChange }) => {
    // global state
    const style = useStyle();
    /**
     * memoized styles for labels on left
     */
    const labelContainerStyle = useMemo(
        () => ({
            height: 70,
            justifyContent: 'center' as const,
        }),
        [],
    );
    /**
     * callback to handle value changes on local curData state
     */
    const onValueChange = useCallback(
        (next: Partial<MRates>) =>
            onChange(new MRates({ ...curData, ...next })),
        [curData],
    );
    /**
     * callback to set night wage
     */
    const onNightWageChange = useCallback(
        (nightWage: MWage) => {
            onValueChange({ nightWage });
        },
        [onValueChange],
    );
    /**
     * callback to set sunday wage
     */
    const onSundayWageChange = useCallback(
        (sundayWage: MWage) => {
            onValueChange({ sundayWage });
        },
        [onValueChange],
    );
    /**
     * callback to set holiday wage
     */
    const onHolidayWageChange = useCallback(
        (holidayWage: MWage) => {
            onValueChange({ holidayWage });
        },
        [onValueChange],
    );
    /**
     * render
     */
    return (
        <View style={[style.horizontalWrap, { alignItems: 'stretch' }]}>
            <View
                style={[
                    style.horizontalPadded,
                    { justifyContent: 'space-between' },
                ]}
            >
                <View style={{ height: 40 }} />
                <View style={{ height: 40 }} />
                <View style={labelContainerStyle}>
                    <CText message={wageMessages.regularWage} />
                </View>
                <View style={labelContainerStyle}>
                    <CText message={wageMessages.standbyWage} />
                </View>
                <View style={labelContainerStyle}>
                    <CText message={wageMessages.onCallActiveWage} />
                </View>
                <View style={labelContainerStyle}>
                    <CText message={wageMessages.onCallPassiveWage} />
                </View>
            </View>
            <View style={[style.horizontalPadded, { minWidth: 150 }]}>
                <View
                    style={{
                        height: 40,
                        justifyContent: 'center' as const,
                    }}
                >
                    <CText message={wageMessages.regularRate} />
                </View>
                <View
                    style={{
                        height: 40,
                        justifyContent: 'center' as const,
                    }}
                />
                <WageInput
                    noLabels
                    onChange={(next) =>
                        onValueChange({
                            ...next,
                        })
                    }
                    value={curData}
                />
            </View>
            <SubRateColumn
                rates={curData}
                useThis={curData.useNightWage}
                useThisMessage={wageMessages.nightRate}
                onChangeUseThis={(useNightWage) =>
                    onValueChange({ useNightWage })
                }
                wage={curData.nightWage}
                onChangeWage={onNightWageChange}
                useFactor={curData.useNightFactor}
                onChangeUseFactor={(useNightFactor) =>
                    onValueChange({ useNightFactor })
                }
                factor={curData.nightFactor}
                onChangeFactor={(nightFactor) => onValueChange({ nightFactor })}
            />
            <SubRateColumn
                rates={curData}
                useThis={curData.useSundayWage}
                useThisMessage={wageMessages.sundayRate}
                onChangeUseThis={(useSundayWage) =>
                    onValueChange({ useSundayWage })
                }
                wage={curData.sundayWage}
                onChangeWage={onSundayWageChange}
                useFactor={curData.useSundayFactor}
                onChangeUseFactor={(useSundayFactor) =>
                    onValueChange({ useSundayFactor })
                }
                factor={curData.sundayFactor}
                onChangeFactor={(sundayFactor) =>
                    onValueChange({ sundayFactor })
                }
            />
            <SubRateColumn
                rates={curData}
                useThis={curData.useHolidayWage}
                useThisMessage={wageMessages.holidayRate}
                onChangeUseThis={(useHolidayWage) =>
                    onValueChange({ useHolidayWage })
                }
                wage={curData.holidayWage}
                onChangeWage={onHolidayWageChange}
                useFactor={curData.useHolidayFactor}
                onChangeUseFactor={(useHolidayFactor) =>
                    onValueChange({ useHolidayFactor })
                }
                factor={curData.holidayFactor}
                onChangeFactor={(holidayFactor) =>
                    onValueChange({ holidayFactor })
                }
            />
        </View>
    );
};
