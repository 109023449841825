import React, { FC, useContext } from 'react';
import { CCard, CTextInput, OutOfContext } from '../../../../components';
import { ProfileContext } from '../../../Profile/Profile.context';
import { userMessages } from '../../user.messages';

export const QESUser: FC = () => {
    const { curUserData, onChangeUserData } = useContext(ProfileContext);
    if (!curUserData || !onChangeUserData) {
        return <OutOfContext component="QESUser" />;
    }
    return (
        <CCard>
            <CTextInput
                type="numeric"
                value={`${curUserData.qes}`}
                onChangeText={(t) => {
                    if (!isNaN(+t)) {
                        onChangeUserData({ qes: +t });
                    }
                }}
                label={userMessages.userQES}
            />
        </CCard>
    );
};
