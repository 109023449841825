import { useEffect } from 'react';
import { useNavigate, useSearchParams } from '../../../../../utilities/routing';
import { MContract, MSignaturePosition } from '../../../../../models';
import { EUserType } from '../../../../../enums';
import { useFireBase } from '../../../../../utilities/firebase';
import { useLock } from '../../../../../utilities/hooks';
import { useDialog } from '../../../../../utilities/dialog';
import { contractMessages } from '../../../contract.messages';
import { Linking } from 'react-native';
import { actionMessages } from '../../../../../utilities/messages';

export const useSignatureEffect = (
    issueReload: () => void,
    contract?: MContract,
) => {
    // global state
    const [searchParams, setSearchParams] = useSearchParams();
    const { callFunction } = useFireBase();
    const { lock } = useLock();
    const navigate = useNavigate();
    const dialog = useDialog();
    /**
     * return the effect
     */
    return useEffect(() => {
        if (!contract) return;
        const spfid = searchParams.get('fid');
        const positionDataString = searchParams.get('posdata');
        const type = searchParams.get('type');
        if (!positionDataString) return;
        const unlock = lock();

        const positions = Array.from(JSON.parse(positionDataString)).map(
            (p) => {
                const next = new MSignaturePosition(p as MSignaturePosition);
                if (next.type === EUserType.user) {
                    next.userId = contract.employeeId;
                } else {
                    next.userId = contract.signingEmployerId;
                }
                return next;
            },
        );
        const post = {
            fileId: spfid,
            contractId: contract.documentId,
            positions,
        };
        if (type === 'namirial') {
            callFunction('createNamirialEnvelope', post).then((namirialUrl) => {
                unlock();
                setSearchParams([], { replace: true });
                issueReload();
                if (namirialUrl) {
                    dialog({
                        icon: 'success',
                        title: contractMessages.createdEnvelopeSignNow,
                        message: contractMessages.createdEnvelopeSignNowText,
                        buttons: [
                            {
                                text: contractMessages.signEnvelope,
                                onPress: () => {
                                    Linking.openURL(namirialUrl);
                                },
                            },
                        ],
                        cancelButton: { text: actionMessages.close },
                    });
                }
            });
        } else if (type === 'unqualified') {
            callFunction('createUnqualifiedEnvelope', post).then(
                (inAppSignatureLocation) => {
                    unlock();
                    setSearchParams([], { replace: true });
                    issueReload();
                    if (inAppSignatureLocation) {
                        dialog({
                            icon: 'success',
                            title: contractMessages.createdEnvelopeSignNow,
                            message:
                                contractMessages.createdEnvelopeSignNowText,
                            buttons: [
                                {
                                    text: contractMessages.signEnvelope,
                                    onPress: () => {
                                        navigate(inAppSignatureLocation);
                                    },
                                    icon: 'signature',
                                },
                            ],
                            cancelButton: { text: actionMessages.close },
                        });
                    }
                },
            );
        }
    }, [searchParams, contract]);
};
