import { EField, EProfession } from '../../enums';
import { MAvailability } from '../MAvailability';

export class MShiftWorker {
    public name = '';
    /**
     * hopefully identifying enought
     */
    public id = Date.now().toString(36);

    public hoursPerWeek = 32;

    public unavailabilities: MAvailability[] = [];
    public groups: string[] = [];

    public field?: EField;
    public profession?: EProfession;
    public educations: string[] = [];
    public specializations: string[] = [];

    public level = 0;

    constructor(params: Partial<MShiftWorker> = {}) {
        Object.assign(this, params);
    }
}

export class MShiftWorkerChildCare extends MShiftWorker {
    constructor(params: Partial<MShiftWorkerChildCare> = {}) {
        super(params);
        Object.assign(this, params);
    }
}
