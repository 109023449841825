import { defineMessages } from 'react-intl';

const scope = 'pay.intervals.';

export const paymentIntervalMessages = defineMessages({
    weekly: {
        id: scope + 'weekly',
        defaultMessage: 'Weekly payments',
    },
    biWeekly: {
        id: scope + 'biWeekly',
        defaultMessage: 'Bi-weekly payments',
    },
    monthly: {
        id: scope + 'monthly',
        defaultMessage: 'Monthly payments',
    },
});
