import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useNavigate } from 'react-router-native';
import { CButton, CText } from '../../../components';
import { EApplicationStatus, ECollections } from '../../../enums';
import { isEmployer, isSuperUser } from '../../../utilities/auth';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { generalMessages } from '../../../utilities/messages';
import { useStyle } from '../../../utilities/styles';
import { day } from '../../../utilities/functions';
import { dashboardMessages } from '../Dashboard.messages';
/**
 * displace todays new application counter (if they stopped being open they stop being counted)
 * @returns
 */
export const OpenApplications: FC = () => {
    const style = useStyle();
    const format = useFormat();
    const navigate = useNavigate();
    const { userData, getDataIndex, userWorkplaces } = useFireBase();

    const [count, setCount] = useState(0);
    /**
     * load count
     */
    useEffect(() => {
        if (!isEmployer(userData)) return;
        const promises = [];
        for (const wp of userWorkplaces) {
            promises.push(
                getDataIndex(ECollections.applications, {
                    filter: [
                        {
                            field: 'jobWorkplace',
                            value: wp.documentId,
                        },
                        // TODO: fix this technical hurdle
                        {
                            field: 'status',
                            value: EApplicationStatus.open,
                        },
                    ],
                    getLength: true,
                }),
            );
        }
        Promise.all(promises).then((res) => {
            const next = res.reduce((acc, c) => {
                if (typeof c === 'number') {
                    // TODO: find out why wonky typings needet
                    return (acc as number) + (c as number);
                }
                return acc;
            }, 0) as number;
            setCount(next);
        });
    }, [userData, userWorkplaces]);
    /**
     * render
     */
    return (
        <View style={style.card} nativeID="applicants-card">
            <View
                style={[
                    style.centeredItems,
                    { justifyContent: 'space-between', maxWidth: '100%' },
                ]}
            >
                <CText bold headline wrap>
                    {format(dashboardMessages.openApplications)}
                </CText>
                <CText style={[style.importantValue]} bold>
                    {count}
                </CText>
                <CButton
                    title={format(generalMessages.showMore)}
                    onPress={() =>
                        isSuperUser(userData)
                            ? navigate('/application?filter=today')
                            : navigate('/japplications')
                    }
                    transparent
                    minor
                />
            </View>
        </View>
    );
};
