import { defineMessages } from 'react-intl';

const scope = 'field.';
/**
 * teacher education levels
 * is used for teacherEducation picker
 */
export const fieldMessages = defineMessages({
    medic: {
        id: `${scope}medic`,
        defaultMessage: 'Medical',
    },
    education: {
        id: `${scope}education`,
        defaultMessage: 'Education',
    },
    gastro: {
        id: `${scope}gastro`,
        defaultMessage: 'Gastronomy',
    },
    unskilled: {
        id: `${scope}unskilled`,
        defaultMessage: 'Unskilled',
    },
    office: {
        id: `${scope}office`,
        defaultMessage: 'Office',
    },
    craftsmen: {
        id: `${scope}craftsmen`,
        defaultMessage: 'Craftsmen',
    },
    industry: {
        id: `${scope}industry`,
        defaultMessage: 'Industry',
    },
    general: {
        id: scope + 'general',
        defaultMessage: 'General',
    },
    undefined: {
        id: `${scope}undefined`,
        defaultMessage: 'Undefined',
    },
});
