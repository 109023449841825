import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useSecureNavigate } from '../../../utilities/routing';
import {
    CButton,
    CText,
    CTextInput,
    CPicker,
    CImage,
} from '../../../components';
import { MWorkplace } from '../../../models';
import { isEmployer, isSuperUser } from '../../../utilities/auth';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { actionMessages } from '../../../utilities/messages/action.messages';
import { generalMessages } from '../../../utilities/messages/general.messages';
import { useStyle, useTheme } from '../../../utilities/styles';
import { workplaceMessages } from '../workplace.messages';
import { ECollections, ERegion } from '../../../enums';
import { useAdminDialog, useDialog } from '../../../utilities/dialog';
import { ScrollProvider } from '../../../utilities/contexts/Scroll';
import { sortObjectKeys } from '../../../utilities/functions/sortObjectKeys';

export const WorkplaceCreate: FC = () => {
    const style = useStyle();
    const { theme } = useTheme();
    const { secureNavigate, setNavigationLock } = useSecureNavigate();
    const format = useFormat();
    const { post, userData, reloadUserData } = useFireBase();
    const dialog = useDialog();
    const adminDialog = useAdminDialog();
    // local state
    const [workplace, setWorkplace] = useState(new MWorkplace());
    /**
     * memoized check for unstaged changes
     */
    const unsavedChanges = useMemo(() => {
        if (!workplace) {
            return false;
        }
        /**
         * prev with to be sorted address
         */
        const prev = { address: {}, billingAddress: {} } as any;
        prev.address = sortObjectKeys(prev.address);
        prev.billingAddress = sortObjectKeys(prev.billingAddress);
        /**
         * current with to be sorted address
         */
        const current = workplace || ({} as any);
        current.address = sortObjectKeys(current.address);
        current.billingAddress = sortObjectKeys(current.billingAddress);
        /**
         * compare sorted object for differences
         */
        const strcmp = JSON.stringify(prev) !== JSON.stringify(current);
        /**
         * return conditions for unsaved changes
         */
        return strcmp;
    }, [workplace]);
    /**
     * handle changes
     */
    const onChange = useCallback((next: Partial<MWorkplace>) => {
        setWorkplace((prev) => {
            if (next.address && !prev.billingAddressDiffers) {
                next.billingAddress = next.address;
            }
            return { ...prev, ...next };
        });
    }, []);
    /**
     * handle save of workplace
     */
    const handleSave = useCallback(async () => {
        if (isSuperUser(userData) && !(await adminDialog())) return;

        const next = {
            ...workplace,
        };
        if (isEmployer(userData)) {
            next.owner = userData.documentId;
            next.users.push(userData.documentId);
        }
        next.users = next.users.filter((v) => !!v.replace(/[^0-9a-z]/gi, ''));
        const postResult = await post(ECollections.workplaces, next);

        if (isEmployer(userData)) {
            reloadUserData();
        }
        secureNavigate('/workplace/edit/' + postResult?.id, {
            force: true,
            replace: true,
        });
    }, [workplace]);
    /**
     * ask for discard of unsaved changes
     */
    const navigationLock = useCallback(async () => {
        const locking =
            unsavedChanges &&
            !(await dialog({
                title: generalMessages.unsavedChanges,
                message: generalMessages.ifContinueTheyDiscard,
                cancelButton: {
                    text: actionMessages.back,
                },
                buttons: [
                    {
                        text: actionMessages.continueAndDiscard,
                        color: theme.errorColor,
                    },
                ],
                icon: 'warning',
            }));
        return locking;
    }, [unsavedChanges]);
    /**
     * register lock as current navigationlock
     */
    useEffect(() => setNavigationLock(navigationLock), [navigationLock]);
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={style.headlineCard}>
                <View style={[style.horizontal, style.centeredItems]}>
                    <CButton
                        cy={'back'}
                        onPress={() => {
                            secureNavigate(-1);
                        }}
                        icon={'chevronLeft'}
                        small
                    />
                    <CText style={{ marginLeft: 5 }} bold headline>
                        {format(workplaceMessages.creating)}
                    </CText>
                </View>
            </View>
            <View style={[style.card]}>
                {isSuperUser(userData) && (
                    <View
                        style={[
                            style.horizontal,
                            style.horizontalPadded,
                            style.centeredItems,
                        ]}
                    >
                        <View style={style.horizontalPadded}>
                            <CImage image={workplace.region} fixedHeight={20} />
                        </View>
                        <CPicker
                            values={Object.values(ERegion).map((v) => ({
                                value: v,
                                label: v,
                            }))}
                            onChange={(v) => {
                                onChange({ region: v });
                            }}
                            value={workplace.region}
                        />
                    </View>
                )}
                <CTextInput
                    cy={'workplace-name'}
                    label={format(workplaceMessages.name)}
                    value={workplace.name}
                    onChangeText={(name) =>
                        onChange({
                            name,
                            bankDetails: {
                                ...workplace.bankDetails,
                                accountHolderName: name,
                            },
                        })
                    }
                    autoExtend
                    disabled={!isSuperUser(userData)}
                />
            </View>

            <View style={[style.verticalPadded, style.centeredItems]}>
                {unsavedChanges && (
                    <CText
                        style={style.warning}
                        message={generalMessages.unsavedChanges}
                    />
                )}
                <View style={{ marginHorizontal: 'auto' }}>
                    <CButton
                        cy={'save'}
                        title={format(actionMessages.create)}
                        onPress={handleSave}
                    />
                </View>
            </View>
        </ScrollProvider>
    );
};
