import React, { FC, useMemo } from 'react';
import { StyleProp, Text, View, ViewStyle } from 'react-native';
import { useTheme } from '../../../utilities/styles';
import { TouchableView } from '../../TouchableView';
import { CButton } from '../CButton';
import { CIcon } from '../CIcon';
import { makeCheckboxStyles } from './styles';
import { Message } from '../../../utilities/types';
import { useAutoFormat } from '../../../utilities/intl/useFormat';

interface ICCheckBoxProps {
    checked: boolean;
    style?: StyleProp<ViewStyle>;
    title?: Message;
    onCheckedChanged?: (check: boolean) => void;
    cyId?: string;
    disabled?: boolean;
    icon?: string;
    uncheckedIcon?: string;
    cy?: string;
}

export const CCheckBox: FC<ICCheckBoxProps> = ({
    onCheckedChanged,
    checked,
    icon,
    disabled,
    title,
    uncheckedIcon,
    cy,
    style,
}) => {
    // global state
    const { theme } = useTheme();
    const format = useAutoFormat();
    // local state
    const localStyles = useMemo(() => makeCheckboxStyles(theme), [theme]);

    if (icon) {
        return (
            <TouchableView
                {...{ dataSet: { cy } }}
                style={localStyles.container}
                onPress={() => {
                    if (onCheckedChanged) {
                        onCheckedChanged(!checked);
                    }
                }}
                disabled={disabled}
            >
                <CIcon
                    icon={checked ? icon : uncheckedIcon || `${icon}_outline`}
                    tint={
                        checked
                            ? theme.accentColor
                            : disabled
                            ? theme.borderColor
                            : theme.textMainColor
                    }
                />
            </TouchableView>
        );
    }
    return (
        <TouchableView
            style={[localStyles.container, { alignItems: 'center' }, style]}
            onPress={() => {
                if (onCheckedChanged) {
                    onCheckedChanged(!checked);
                }
            }}
            disabled={disabled}
        >
            <CButton
                cy={cy}
                onPress={() => {
                    if (onCheckedChanged) {
                        onCheckedChanged(!checked);
                    }
                }}
                icon={checked ? 'check' : undefined}
                iconColor={theme.accentColor}
                checkbox
                style={localStyles.check}
                disabled={disabled}
            />
            {!!title && <Text style={localStyles.label}>{format(title)}</Text>}
        </TouchableView>
    );
};
