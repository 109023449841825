import React, {
    FC,
    ReactNode,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { View } from 'react-native';
import { makeTableStyles } from '../CDataTableStyles';
import { useTheme } from '../../../../utilities/styles';
import { CText } from '../../CText';
import { CDataTableContext } from '../CDataTable';

export const CDataTableCell: FC<{
    headerKey: string;
    value: string;
    rowKey: string;
    component?: ReactNode;
    bold?: boolean;
    head?: boolean;
    color?: string;
    flex?: number;
    first?: boolean;
}> = ({ headerKey, value, bold, color, component, first, rowKey, head }) => {
    const { theme } = useTheme();

    const { keyWidth, registerNewKeyWidth } = useContext(CDataTableContext);

    const localStyle = makeTableStyles(theme);

    const [desiredWidth, setDesiredWidth] = useState(0);
    /**
     * get biggest width for this headerkey
     */
    const minWidth = useMemo(() => {
        const allWidths = keyWidth.filter((w) => w.headerKey === headerKey);
        const next = allWidths.sort((a, b) => b.width - a.width)[0];
        if (next) {
            // ! +1 to avoid running into .37 offs due to html doing whatever
            return next.width + 1;
        }
        return 0;
    }, [keyWidth, headerKey]);
    /**
     * determin if there is a width from this row / header to prevent endless trigger
     */
    const hasWidth = useMemo(() => {
        const next = !!keyWidth.find(
            (w) =>
                w.headerKey === headerKey &&
                w.rowKey === rowKey &&
                w.value === value,
        );
        return next;
    }, [keyWidth, headerKey, rowKey, value]);
    /**
     * effect to trigger key width register in case of change / new value
     */
    useEffect(() => {
        if (desiredWidth > minWidth + 1) {
            if (!hasWidth) {
                registerNewKeyWidth(headerKey, rowKey, value, desiredWidth);
            }
        }
    }, [desiredWidth, hasWidth, desiredWidth]);
    /**
     * render
     */
    return (
        <View
            key={headerKey}
            style={[
                { minWidth },
                localStyle.column,
                first && localStyle.firstColumn,
                head && localStyle.headerRow,
            ]}
            onLayout={(e) => setDesiredWidth(e.nativeEvent.layout.width)}
        >
            {component ? (
                component
            ) : (
                <CText
                    style={
                        !!color && {
                            color,
                        }
                    }
                    bold={bold}
                    secondaryHeadline={head}
                    message={value ? value : 'not found'}
                />
            )}
        </View>
    );
};
