import React, { FC, useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';
import {
    CButton,
    CFieldStringInput,
    CPicker,
    CText,
} from '../../../../components';
import { addressMessages } from '../../../../components/AddressInput/address.messages';
import { EField, EProfession, ERegion, EUserType } from '../../../../enums';
import { useFormat } from '../../../../utilities/intl';
import { generalMessages } from '../../../../utilities/messages';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { profileMessages } from '../../../Profile/Profile.messages';
import { userMessages } from '../../user.messages';
import {
    EducationPicker,
    FieldPicker,
    PositionPicker,
    ProfessionPicker,
} from '../../../../components/Pickers';
/**
 * filter modes
 */
export enum EUsersFilterMode {
    all = 'all',
    more = 'more',
    verified = 'verified',
    unverified = 'unverified',
    verifiedByMe = 'verifiedByMe',
    today = 'today',
    extraAvailable = 'extraAvailable',
    extraMobile = 'extraMobile',
    disabled = 'disabled',
}
/**
 * more user filter defintion
 */
export interface IUsersMoreFilter {
    zipCode?: string;
    education?: string;
    userType?: EUserType;
    position?: string;
    profession?: EProfession;
    field?: EField;
    region?: ERegion;
}
/**
 * user filter definition
 */
export interface IUsersFilter extends IUsersMoreFilter {
    mode: EUsersFilterMode[];
    customStringFilter?: { field: string; value: string };
}
/**
 * props for user filter bar
 */
interface IUsersFilterBarProps {
    setMode: React.Dispatch<React.SetStateAction<EUsersFilterMode[]>>;
    addMode: (nextMode: EUsersFilterMode) => void;
    setMoreFilter: React.Dispatch<React.SetStateAction<IUsersMoreFilter>>;
    setCustomStringFilter: React.Dispatch<
        React.SetStateAction<
            | {
                  field: string;
                  value: string;
              }
            | undefined
        >
    >;
    mode: EUsersFilterMode[];
    moreFilter: IUsersMoreFilter;
    customStringFilter?: { field: string; value: string };
}
/**
 * filters for userlist
 * @param param0
 * @returns
 */
export const UsersFilterBar: FC<IUsersFilterBarProps> = ({
    mode,
    setMode,
    addMode,
    moreFilter,
    setMoreFilter,
    customStringFilter,
    setCustomStringFilter,
}) => {
    // global state
    const style = useStyle();
    const { theme } = useTheme();
    const format = useFormat();
    // local state
    const [localMore, setMore] = useState(false);
    /**
     * shold more be open
     *
     * returns true if any value insibe more is set or more is set
     */
    const more = useMemo(() => {
        return !!(localMore || Object.keys(moreFilter).length);
    }, [localMore, moreFilter]);
    /**
     * memoized check if custom string filter is active or not
     */
    const customStringFilterActive = useMemo(
        () => customStringFilter && customStringFilter.value,
        [customStringFilter],
    );
    /**
     * determin if any filter is active
     *
     * returns true if any filter has a value
     */
    const filtering = useMemo(
        () =>
            !!(
                mode.length ||
                customStringFilterActive ||
                Object.keys(moreFilter).length
            ),
        [customStringFilterActive, mode, moreFilter],
    );
    /**
     * method to update moreFilter object
     */
    const updateMoreFilter = useCallback((next: Partial<IUsersMoreFilter>) => {
        setMoreFilter((prev) => {
            return { ...prev, ...next };
        });
    }, []);
    /**
     * method to clear filters
     */
    const clearFilters = useCallback(() => {
        setMoreFilter({});
        setMore(false);
    }, []);
    /**
     * set custom string filter
     */
    const handleNextCustomStringFilter = useCallback(
        (next: { value: string; field: string }) =>
            setCustomStringFilter((prev) => {
                if (next.value) {
                    setMode([]);
                    clearFilters();
                    return next;
                } else {
                    return { field: next.field, value: '' };
                }
            }),
        [],
    );
    /**
     * render
     */
    return (
        <View>
            <View style={style.horizontalWrap}>
                <CButton
                    title={format(generalMessages.all)}
                    onPress={() => {
                        setMode([]);
                        clearFilters();
                        setCustomStringFilter(undefined);
                    }}
                    minor={filtering}
                    small
                    style={style.smallMargin}
                />
                <View style={style.verticalPaddedThinSeparator} />
                <CButton
                    title={format(userMessages.today)}
                    onPress={() => {
                        setMode([EUsersFilterMode.today]);
                        clearFilters();
                        setCustomStringFilter(undefined);
                    }}
                    minor={!mode.includes(EUsersFilterMode.today)}
                    small
                    style={style.smallMargin}
                />
                <View style={style.verticalPaddedThinSeparator} />
                <CButton
                    title={format(userMessages.verified)}
                    onPress={() => {
                        addMode(EUsersFilterMode.verified);
                    }}
                    minor={!mode.includes(EUsersFilterMode.verified)}
                    small
                    style={style.smallMargin}
                />
                <CButton
                    title={format(userMessages.unverified)}
                    onPress={() => {
                        addMode(EUsersFilterMode.unverified);
                    }}
                    minor={!mode.includes(EUsersFilterMode.unverified)}
                    small
                    style={style.smallMargin}
                />
                <CButton
                    title={format(userMessages.extra)}
                    icon={
                        mode.includes(EUsersFilterMode.extraAvailable)
                            ? 'clock'
                            : 'clock_outline'
                    }
                    onPress={() => {
                        addMode(EUsersFilterMode.extraAvailable);
                    }}
                    minor={!mode.includes(EUsersFilterMode.extraAvailable)}
                    small
                    style={style.smallMargin}
                />
                <CButton
                    title={format(userMessages.extra)}
                    icon={
                        mode.includes(EUsersFilterMode.extraMobile)
                            ? 'car'
                            : 'car_outline'
                    }
                    onPress={() => {
                        addMode(EUsersFilterMode.extraMobile);
                    }}
                    minor={!mode.includes(EUsersFilterMode.extraMobile)}
                    small
                    style={style.smallMargin}
                />
                <CButton
                    title={format(userMessages.verifiedByMe)}
                    onPress={() => addMode(EUsersFilterMode.verifiedByMe)}
                    minor={!mode.includes(EUsersFilterMode.verifiedByMe)}
                    small
                    style={style.smallMargin}
                />
                <CButton
                    title={format(userMessages.disabled)}
                    onPress={() => addMode(EUsersFilterMode.disabled)}
                    minor={!mode.includes(EUsersFilterMode.disabled)}
                    small
                    style={style.smallMargin}
                />
                <CButton
                    title={format(generalMessages.more)}
                    onPress={() => setMore(!localMore)}
                    minor={!more}
                    small
                    style={style.smallMargin}
                    icon={'tune'}
                />
            </View>
            {more && (
                <>
                    <CPicker
                        title={format(profileMessages.accountType)}
                        values={[
                            {
                                value: '',
                                label: `-${format(
                                    userMessages.userTypePlaceholder,
                                )}-`,
                            },
                            ...Object.values(EUserType).map((v) => {
                                return {
                                    value: v,
                                    label: format(generalMessages[v]),
                                };
                            }),
                        ]}
                        value={moreFilter.userType}
                        onChange={(userType) => {
                            updateMoreFilter({ userType });
                        }}
                    />
                    <FieldPicker
                        value={moreFilter.field}
                        onChange={(field) => {
                            updateMoreFilter({
                                field,
                                profession: undefined,
                                education: undefined,
                                position: undefined,
                            });
                        }}
                        allowEmpty
                    />
                    {moreFilter.field && (
                        <ProfessionPicker
                            field={moreFilter.field}
                            value={
                                moreFilter.profession as EProfession | undefined
                            }
                            onChange={(profession) => {
                                updateMoreFilter({
                                    profession,
                                    education: undefined,
                                    position: undefined,
                                });
                            }}
                        />
                    )}
                    {moreFilter.profession && (
                        <PositionPicker
                            profession={moreFilter.profession}
                            value={moreFilter.position || ''}
                            onChange={(position) => {
                                updateMoreFilter({ position });
                            }}
                        />
                    )}
                    {moreFilter.profession && (
                        <EducationPicker
                            field={moreFilter.field}
                            profession={moreFilter.profession}
                            value={moreFilter.education}
                            onChange={(education) => {
                                updateMoreFilter({
                                    education: education as string,
                                });
                            }}
                        />
                    )}
                    <CPicker
                        title={format(userMessages.region)}
                        values={[
                            {
                                value: '',
                                label: `-${format(
                                    userMessages.regionPlaceholder,
                                )}-`,
                            },
                            ...Object.values(ERegion).map((v) => {
                                return {
                                    value: v,
                                    label: v,
                                };
                            }),
                        ]}
                        value={moreFilter.region}
                        onChange={(region) => {
                            updateMoreFilter({ region });
                        }}
                    />
                </>
            )}
            <CFieldStringInput
                value={customStringFilter}
                fields={[
                    ...['lastName', 'firstName'].map((f) => {
                        return {
                            label: format(
                                userMessages[f as keyof typeof userMessages],
                            ),
                            field: f,
                        };
                    }),
                    { label: 'id', field: 'id' },
                    {
                        label: format(addressMessages.zipCode),
                        field: 'zipCode',
                    },
                ]}
                onChange={handleNextCustomStringFilter}
                cy={'user-text-filter'}
            />
            {customStringFilter?.field === 'id' && (
                <CText
                    style={[style.verticalPadded, { color: theme.errorColor }]}
                    message={userMessages.searchingForIdWarning}
                />
            )}
        </View>
    );
};
