import React, { FC, useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useNavigate } from '../../../utilities/routing';
import { CButton, CText, Spinner } from '../../../components';
import { MAgency } from '../../../models';
import { isSuperUser } from '../../../utilities/auth';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { generalMessages } from '../../../utilities/messages/general.messages';
import { useStyle } from '../../../utilities/styles';
import { AgencyRow } from './components/AgencyRow';
import { ECollections } from '../../../enums';
// import {
//     EAgencyFilterModes,
//     AgencyFilterBar,
// } from '../components/AgencyFilterBar';
import { IFilter } from '../../../utilities/firebase/store';
import { actionMessages } from '../../../utilities/messages';
import { ScrollProvider } from '../../../utilities/contexts/Scroll';
import { AgencyFilterBar } from './components/AgencyFilterBar';

export const AgencyList: FC<{ uid?: string }> = ({ uid }) => {
    const style = useStyle();
    const format = useFormat();
    const navigate = useNavigate();
    const { getDataIndex, userData } = useFireBase();
    const [agencys, setAgencies] = useState<MAgency[]>([]);
    const [currentHospitals, setCurrentHospitals] = useState<number>(0);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [endReached, setEndReached] = useState(false);
    // const [mode, setMode] = useState<EAgencyFilterModes[]>([
    //     EAgencyFilterModes.all,
    // ]);
    const [customStringFilter, setCustomStringFilter] = useState<{
        field: string;
        value: string;
    }>();
    /**
     * callback to add a filtermode
     */
    // const addMode = useCallback((nextMode: EAgencyFilterModes) => {
    //     setMode((prev) => {
    //         const next = Array.from(prev);
    //         const prevIndex = next.indexOf(nextMode);
    //         if (prev.length === 1 && next[0] === EAgencyFilterModes.all) {
    //             return [nextMode];
    //         } else if (next.length === 1 && prevIndex >= 0) {
    //             return [EAgencyFilterModes.all];
    //         } else if (prevIndex >= 0) {
    //             next.splice(prevIndex, 1);
    //             return next;
    //         } else if (
    //             [
    //                 EAgencyFilterModes.verified,
    //                 EAgencyFilterModes.unverified,
    //             ].includes(nextMode)
    //         ) {
    //             const index1 = next.findIndex(
    //                 (v) => v === EAgencyFilterModes.verified,
    //             );
    //             const index2 = next.findIndex(
    //                 (v) => v === EAgencyFilterModes.unverified,
    //             );
    //             if (index1 >= 0) {
    //                 next.splice(index1, 1);
    //             }
    //             if (index2 >= 0) {
    //                 next.splice(index2, 1);
    //             }
    //             next.push(nextMode);
    //             return next;
    //         } else {
    //             next.push(nextMode);
    //             return next;
    //         }
    //     });
    // }, []);

    const loadMore = useCallback(
        async (offset?: MAgency) => {
            const filter: IFilter[] = [];
            let partialMatch: { field: string; value: string } | undefined =
                undefined;
            if (!isSuperUser(userData)) {
                filter.push({
                    field: 'users',
                    operator: 'array-contains',
                    value: userData.documentId,
                });
            } else if (uid) {
                filter.push({
                    field: 'users',
                    operator: 'array-contains',
                    value: uid,
                });
            }
            // if (mode.includes(EAgencyFilterModes.verified)) {
            //     filter.push({
            //         field: 'verified',
            //         operator: '==',
            //         value: true,
            //     });
            // }
            if (
                customStringFilter &&
                customStringFilter.field &&
                customStringFilter.value
            ) {
                const field =
                    customStringFilter.field === 'zipCode'
                        ? 'address.zipCode'
                        : customStringFilter.field;
                partialMatch = { field, value: customStringFilter.value };
            }
            const params: any = {
                filter,
                limit: 10,
                partialMatch,
            };
            if (offset) {
                params.startDocumentId = offset.documentId;
            } else {
                getDataIndex(ECollections.agencies, {
                    ...params,
                    getLength: true,
                }).then((res) => {
                    setCurrentHospitals(res as number);
                });
            }
            getDataIndex(ECollections.agencies, params).then((result) => {
                const next = (result as MAgency[]).map((r) => new MAgency(r));
                if (!offset) {
                    setAgencies(next);
                    setLoading(false);
                } else {
                    setAgencies((current) => {
                        return [...current, ...next];
                    });
                    setLoadingMore(false);
                }
            });
        },
        // [mode, customStringFilter],
        [customStringFilter, uid],
    );
    /**
     * effect to trigger reload
     */
    useEffect(() => {
        setLoading(true);
        loadMore();
    }, [loadMore]);

    return (
        <ScrollProvider
            style={!uid ? style.paddedScrollableMainView : undefined}
        >
            <View style={!uid ? [style.card] : undefined}>
                <View style={style.horizontalSplit}>
                    {!uid && (
                        <CText bold headline>
                            {format(generalMessages.agencies)}
                        </CText>
                    )}
                    {/* {isSuperUser(userData) && (
                        <CButton
                            cy={'add-agency'}
                            onPress={() => {
                                navigate('/agency/create');
                            }}
                            icon={'plus'}
                        />
                    )} */}
                </View>
                {isSuperUser(userData) && (
                    <AgencyFilterBar
                        // mode={mode}
                        // onSet={(p) => {
                        //     setMode(p.mode);
                        // }}
                        // addMode={addMode}
                        setCustomStringFilter={(next) => {
                            setCustomStringFilter(next);
                        }}
                        customStringFilter={customStringFilter}
                    />
                )}
            </View>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    {agencys.map((agency) => (
                        <AgencyRow
                            key={agency.documentId}
                            agency={agency}
                            embedded={!!uid}
                        />
                    ))}
                    {loadingMore ? (
                        <Spinner />
                    ) : (
                        !!agencys.length &&
                        agencys.length % 10 === 0 &&
                        !endReached && (
                            <View
                                style={[
                                    style.horizontalSpaced,
                                    style.verticalPadded,
                                ]}
                            >
                                <CButton
                                    onPress={() => {
                                        setLoadingMore(true);
                                        loadMore(agencys[agencys.length - 1]);
                                    }}
                                    minor
                                    title={format(actionMessages.loadMore)}
                                />
                            </View>
                        )
                    )}
                </>
            )}
        </ScrollProvider>
    );
};
