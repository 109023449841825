import React, { FC, useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';
import { CButton, CFieldStringInput } from '../../../../components';
import { useFormat } from '../../../../utilities/intl';
import { generalMessages } from '../../../../utilities/messages';
import { applicationMessages } from '../../application.messages';
import { useStyle } from '../../../../utilities/styles';
import { jobMessages } from '../../../Job/job.messages';
import { JobApplicationFilterButton } from './ApplicationFIlterButton';
import { EApplicationStatus } from '../../../../enums';

export enum EApplicationFilterMode {
    all = 'all',
    open = 'open',
    accepted = 'accepted',
    rejected = 'rejected',
    closed = 'closed',
    negotiating = 'negotiating',
}

interface IApplicationFilterBarProps {
    mode: EApplicationFilterMode[];
    onSet: (values: { mode: EApplicationFilterMode[] }) => void;
    addMode: (next: EApplicationFilterMode) => void;
    setCustomStringFilter: React.Dispatch<
        React.SetStateAction<
            | {
                  field: string;
                  value: string;
              }
            | undefined
        >
    >;
    customStringFilter?: { field: string; value: string };
    decidedOnInitialModeCallback: () => void;
}

export const ApplicationFilterBar: FC<IApplicationFilterBarProps> = ({
    mode,
    onSet,
    addMode: propsAddMode,
    setCustomStringFilter,
    customStringFilter,
    decidedOnInitialModeCallback,
}) => {
    const style = useStyle();
    const format = useFormat();
    const [localMore, setLocalMore] = useState(false);

    const more = useMemo(
        () => !!(localMore || customStringFilter?.value),
        [localMore, customStringFilter],
    );

    const filtering = useMemo(
        () => !!(mode.length || customStringFilter?.value),
        [mode, customStringFilter],
    );

    const clearModeOnly = useCallback(() => {
        onSet({
            mode: [],
        });
    }, []);

    const clearInequalities = useCallback(() => {
        setCustomStringFilter(undefined);
    }, []);

    const clear = useCallback(() => {
        clearModeOnly();
        clearInequalities();
        setLocalMore(false);
    }, []);

    const addMode = useCallback(
        (mode: EApplicationFilterMode) => {
            clearInequalities();
            propsAddMode(mode);
        },
        [propsAddMode],
    );

    const handleNextCustomStringFilter = useCallback(
        (next: { value: string; field: string }) =>
            setCustomStringFilter((prev) => {
                if (next.value) {
                    // setMode([]);
                    // clearFilters();
                    return next;
                } else if (prev?.value) {
                    return { field: next.field, value: '' };
                } else {
                    return prev;
                }
            }),
        [],
    );

    return (
        <View style={{ zIndex: 100 }}>
            <View style={style.horizontalWrap}>
                <CButton
                    title={format(generalMessages.all)}
                    onPress={() => {
                        clear();
                    }}
                    minor={filtering}
                    small
                    style={style.smallMargin}
                />
                <View style={style.verticalPaddedThinSeparator} />
                <JobApplicationFilterButton
                    status={EApplicationStatus.open}
                    statusFilter={
                        mode.includes(EApplicationFilterMode.open)
                            ? EApplicationStatus.open
                            : undefined
                    }
                    onPress={() => addMode(EApplicationFilterMode.open)}
                />
                <JobApplicationFilterButton
                    status={EApplicationStatus.negotiating}
                    statusFilter={
                        mode.includes(EApplicationFilterMode.negotiating)
                            ? EApplicationStatus.negotiating
                            : undefined
                    }
                    onPress={() => addMode(EApplicationFilterMode.negotiating)}
                    hasContentCallback={(hasContent) => {
                        if (hasContent) {
                            addMode(EApplicationFilterMode.negotiating);
                            decidedOnInitialModeCallback();
                        } else {
                            decidedOnInitialModeCallback();
                        }
                    }}
                />
                <CButton
                    title={format(applicationMessages.accepted)}
                    onPress={() => addMode(EApplicationFilterMode.accepted)}
                    minor={!mode.includes(EApplicationFilterMode.accepted)}
                    small
                    style={style.smallMargin}
                />
                <CButton
                    title={format(applicationMessages.rejected)}
                    onPress={() => addMode(EApplicationFilterMode.rejected)}
                    minor={!mode.includes(EApplicationFilterMode.rejected)}
                    small
                    style={style.smallMargin}
                />
                <CButton
                    title={format(applicationMessages.closed)}
                    onPress={() => addMode(EApplicationFilterMode.closed)}
                    minor={!mode.includes(EApplicationFilterMode.closed)}
                    small
                    style={style.smallMargin}
                />
                <CButton
                    title={format(generalMessages.more)}
                    onPress={() => setLocalMore(!localMore)}
                    minor={!more}
                    small
                    style={style.smallMargin}
                    icon={'tune'}
                />
            </View>
            {more && (
                <View style={{ zIndex: 100 }}>
                    <CFieldStringInput
                        value={customStringFilter}
                        fields={[
                            {
                                field: 'job.location',
                                label: format(jobMessages.location),
                            },
                        ]}
                        onChange={handleNextCustomStringFilter}
                        cy={'application-custom-filter'}
                    />
                </View>
            )}
        </View>
    );
};
