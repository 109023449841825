import { useState, useEffect, useCallback } from 'react';
import { ECollections, EJobStatus } from '../../enums';
import { MJob, MWorkplace } from '../../models';
import { isSuperUser } from '../../utilities/auth';
import { useFireBase } from '../../utilities/firebase';
import { IFilter } from '../../utilities/firebase/store';
import { actionMessages } from '../../utilities/messages';
import { applicationMessages } from '../Application/application.messages';
import { useNavigate, useSearchParams } from '../../utilities/routing';
import { useDialog } from '../../utilities/dialog';
import { useFormat } from '../../utilities/intl';
import { useEnvironment } from '../../utilities/contexts';
import { jobMessages } from '../Job/job.messages';

export const useJobApplicationsState = () => {
    const navigate = useNavigate();
    const dialog = useDialog();
    const { getDataIndex, userData, getDataById } = useFireBase();
    const format = useFormat();
    const { environment } = useEnvironment();
    const [searchParams] = useSearchParams();
    // local stae
    const [loading, setLoading] = useState(true);
    const [jobs, setJobs] = useState<MJob[]>([]);
    const [shouldReload, setShouldReload] = useState(Date.now());
    const [statusFilter, setStatusFilter] = useState(EJobStatus.public);
    const [curWorkplaces, setCurWorkplaces] = useState<MWorkplace[]>([]);
    /**
     * callback to issue a reload
     */
    const issueReload = useCallback(() => setShouldReload(Date.now()), []);
    /**
     * handle create button
     */
    const handleCreate = useCallback(() => {
        if (!userData.verified) {
            dialog({
                title: applicationMessages.youAreNotVerified,
                message: format(applicationMessages.verifyYourself, {
                    platform: environment,
                }),
                buttons: [
                    {
                        text: actionMessages.ok,
                        onPress: () => {
                            navigate(
                                '/helpcenter/SxEn23PTl0nTNBs98B3g/Kusb54mKu0p5QOO98fWp',
                            );
                        },
                    },
                ],
                icon: 'error',
            });
        } else {
            if (statusFilter !== EJobStatus.draft) {
                dialog({
                    title: jobMessages.createFromDraftDialog,
                    message: jobMessages.createFromDraftDialogText,
                    buttons: [
                        {
                            text: jobMessages.openDrafts,
                            onPress: () => setStatusFilter(EJobStatus.draft),
                        },
                        {
                            text: jobMessages.createNew,
                            onPress: () => navigate('/job/new'),
                        },
                    ],
                });
            } else {
                navigate('/job/new');
            }
        }
    }, [userData, navigate, statusFilter]);
    /**
     * effect to load jobs for current workplace
     */
    useEffect(() => {
        if (!curWorkplaces.length) {
            setJobs([]);
            setLoading(false);

            return;
        }

        const filter: IFilter[] = [];
        if (curWorkplaces.length) {
            if (curWorkplaces.length <= 5) {
                filter.push({
                    field: 'workPlaceId',
                    operator: 'in',
                    value: curWorkplaces.map((wp) => wp.documentId),
                });
            } else {
                filter.push({
                    field: 'workplaceUsers',
                    operator: 'array-contains',
                    value: userData.documentId,
                });
            }
        } else if (!isSuperUser(userData)) {
            return;
        }
        filter.push({ field: 'status', value: statusFilter });
        getDataIndex(ECollections.jobs, {
            orderBy: 'createdOn',
            filter,
        }).then((result) => {
            setJobs((result as MJob[]).map((r) => new MJob(r)));
            setLoading(false);
        });
    }, [userData, curWorkplaces, shouldReload, statusFilter]);
    /**
     * effect to handle search params
     */
    useEffect(() => {
        const queryId = searchParams.get('wp');
        if (queryId) {
            getDataById(ECollections.workplaces, queryId).then((response) => {
                if (response) {
                    const wp = new MWorkplace(response);
                    setCurWorkplaces([wp]);
                }
            });
        }
        const mode = searchParams.get('mode');
        if (mode === EJobStatus.draft) {
            setStatusFilter(mode);
        }
    }, [searchParams]);
    /**
     * return values for render
     */
    return {
        loading,
        jobs,
        curWorkplaces,
        setCurWorkplaces,
        issueReload,
        setStatusFilter,
        statusFilter,
        handleCreate,
    };
};
