import { EEmploymentType } from '../enums/EEmploymentTypes';

export class MEmployment {
    public title = '';
    public type?: EEmploymentType;
    public company = '';
    public location = '';

    public from = 0;
    public to = 0;

    constructor(params: Partial<MEmployment> = {}) {
        Object.assign(this, params);
    }
}
