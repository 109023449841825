import React, { FC } from 'react';
import { MRates } from '../../../models';
import { CText } from '../../elements';
import { convertNumberToCurrencyString } from '../../../utilities/functions';
import { useStyle } from '../../../utilities/styles';
import { View } from 'react-native';
import { useEnvironment } from '../../../utilities/contexts';

export const CalculatedWage: FC<{ baseRate: MRates; factor: number }> = ({
    baseRate,
    factor,
}) => {
    const style = useStyle();
    const { region } = useEnvironment();
    return (
        <View style={[style.verticalPadded]}>
            <View style={{ height: 70, justifyContent: 'center' }}>
                {!!baseRate.regularWage && (
                    <CText
                        message={convertNumberToCurrencyString(
                            (baseRate.regularWage + baseRate.wage) * factor,
                            region,
                        )}
                    />
                )}
            </View>
            <View style={{ height: 70, justifyContent: 'center' }}>
                {!!baseRate.standbyWage && (
                    <CText
                        message={convertNumberToCurrencyString(
                            (baseRate.standbyWage + baseRate.wage) * factor,
                            region,
                        )}
                    />
                )}
            </View>
            <View style={{ height: 70, justifyContent: 'center' }}>
                {!!baseRate.onCallActiveWage && (
                    <CText
                        message={convertNumberToCurrencyString(
                            (baseRate.onCallActiveWage + baseRate.wage) *
                                factor,
                            region,
                        )}
                    />
                )}
            </View>
            <View style={{ height: 70, justifyContent: 'center' }}>
                {!!baseRate.onCallPassiveWage && (
                    <CText
                        message={convertNumberToCurrencyString(
                            (baseRate.onCallPassiveWage + baseRate.wage) *
                                factor,
                            region,
                        )}
                    />
                )}
            </View>
        </View>
    );
};
