import React, { FC, useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { CTextInput } from '../../elements';
import { wageMessages } from '../wage.messages';
import {
    convertNumberToCurrencyString,
    convertCurrencyStringToNumber,
} from '../../../utilities/functions';
import { useStyle } from '../../../utilities/styles';
import { MRates, MWage } from '../../../models';
import { makeDisplayWage } from '../../../utilities/functions/makeDisplayWage';
import { useEnvironment } from '../../../utilities/contexts';
/**
 * input for vage values
 * automatically adjust base wage based on input individual wages
 * @param param0
 * @returns
 */
export const WageInput: FC<{
    onChange: (next: MWage) => void;
    value: MWage;
    src?: MRates;
    noLabels?: boolean;
}> = ({ onChange, value, noLabels, src }) => {
    const style = useStyle();
    const { region } = useEnvironment();
    /**
     * wages to display in inputs
     */
    const displayWage = useMemo(() => {
        return makeDisplayWage(value);
    }, [value]);
    /**
     * smart handle change to fill in base wage based on input values
     */
    const handleChange = useCallback(
        (change: Partial<typeof displayWage>) => {
            const next = { ...displayWage, ...change };
            const values: number[] = [];
            if (next.regularWage) {
                values.push(next.regularWage);
            }
            if (next.standbyWage) {
                values.push(next.standbyWage);
            }
            if (next.onCallActiveWage) {
                values.push(next.onCallActiveWage);
            }
            if (next.onCallPassiveWage) {
                values.push(next.onCallPassiveWage);
            }
            // if (next.nightWage) {
            //     values.push(next.nightWage);
            // }
            if (!values.length) {
                return onChange(new MWage());
            }

            const minimumWage = Math.min(...values) - 0.01;
            const adjustedMinWage = minimumWage >= 0 ? minimumWage : 0;

            const nextChange = new MWage({
                wage: adjustedMinWage,
                regularWage: next.regularWage
                    ? next.regularWage - adjustedMinWage
                    : 0,
                standbyWage: next.standbyWage
                    ? next.standbyWage - adjustedMinWage
                    : 0,
                onCallActiveWage: next.onCallActiveWage
                    ? next.onCallActiveWage - adjustedMinWage
                    : 0,
                onCallPassiveWage: next.onCallPassiveWage
                    ? next.onCallPassiveWage - adjustedMinWage
                    : 0,
                // nightWage: next.nightWage
                //     ? next.nightWage - adjustedMinWage
                //     : 0,
            });
            onChange(nextChange);
        },
        [displayWage, src],
    );
    /**
     * return input render
     */
    return (
        <>
            <View style={style.verticalPadded}>
                <CTextInput
                    cy={'regularWage'}
                    label={!noLabels ? wageMessages.regularWage : ''}
                    value={convertNumberToCurrencyString(
                        displayWage.regularWage,
                        region,
                    )}
                    onChangeText={(strtng) =>
                        handleChange({
                            regularWage: convertCurrencyStringToNumber(
                                strtng,
                                region,
                            ),
                        })
                    }
                />
                {/* <CTextInput
                    label={format(wageMessages.nightWage)}
                    value={convertNumberToCurrencyString(
                        displayWage.nightWage,
                        region,
                    )}
                    onChangeText={(strtng) =>
                        handleChange({
                            nightWage: convertCurrencyStringToNumber(
                                strtng,
                                region,
                            ),
                        })
                    }
                /> */}
                <CTextInput
                    label={!noLabels ? wageMessages.standbyWage : ''}
                    value={convertNumberToCurrencyString(
                        displayWage.standbyWage,
                        region,
                    )}
                    onChangeText={(strtng) =>
                        handleChange({
                            standbyWage: convertCurrencyStringToNumber(
                                strtng,
                                region,
                            ),
                        })
                    }
                />
                <CTextInput
                    label={!noLabels ? wageMessages.onCallActiveWage : ''}
                    value={convertNumberToCurrencyString(
                        displayWage.onCallActiveWage,
                        region,
                    )}
                    onChangeText={(strtng) =>
                        handleChange({
                            onCallActiveWage: convertCurrencyStringToNumber(
                                strtng,
                                region,
                            ),
                        })
                    }
                />
                <CTextInput
                    label={!noLabels ? wageMessages.onCallPassiveWage : ''}
                    value={convertNumberToCurrencyString(
                        displayWage.onCallPassiveWage,
                        region,
                    )}
                    onChangeText={(strtng) =>
                        handleChange({
                            onCallPassiveWage: convertCurrencyStringToNumber(
                                strtng,
                                region,
                            ),
                        })
                    }
                />
            </View>
        </>
    );
};
