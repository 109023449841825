import Clipboard from '@react-native-clipboard/clipboard';
import React, { FC, useCallback, useState } from 'react';
import { View } from 'react-native';
import {
    CButton,
    CCard,
    CCheckBox,
    CPicker,
    CText,
    ProfilePicture,
    RichTextEditor,
    RichTextView,
    Spinner,
} from '../../../components';
import { isAgencyUser, isSuperUser } from '../../../utilities/auth';
import { ScrollProvider } from '../../../utilities/contexts/Scroll';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { actionMessages, generalMessages } from '../../../utilities/messages';
import {
    useNavigate,
    useParams,
    useSecureNavigate,
} from '../../../utilities/routing';
import { useStyle, useTheme } from '../../../utilities/styles';
import { ProfileContext } from '../../Profile/Profile.context';
import { profProfileMessages } from '../ProfProfile.messages';
import {
    ProfessionalAvailabilities,
    ProfessionalBasic,
    ProfessionalDocuments,
    ProfessionalEmployment,
    ProfessionalHistory,
    ProfessionalInformation,
    ProfessionalLanguage,
    ProfessionalResume,
    ProfessionalTimesheetSection,
    ProfessionalWage,
    TalentSectionAddress,
} from './components';
import { useTalentEditState } from './useTalentEditState';
import { TalentHeadlineCard } from './components/TalentHeadlineCard';
/**
 * sections enum
 */
enum ETalentEditMode {
    basic = 'basic',
    professional = 'professional',
    employment = 'employment',
    availabilities = 'availabilities',
    documents = 'documents',
    timesheets = 'timesheets',
}
/**
 * ProfProfileEdit
 * used by agencies and admins to view & edit profiles
 * @returns
 */
export const SectionedTalentEdit: FC = () => {
    // global state
    const style = useStyle();
    const { theme } = useTheme();
    const navigate = useNavigate();
    const { secureNavigate } = useSecureNavigate();
    const format = useFormat();
    const { userData } = useFireBase();
    const { id, m } = useParams<{ id: string; m: ETalentEditMode }>();
    // local state
    const {
        profInfos,
        prevProfInfos,
        curData,
        prevData,
        prevProfileName,
        chat,
        agency,
        agencies,
        onChange,
        setNote,
        setEditingNote,
        handleNoteSave,
        note,
        editingNote,
        inviteUrl,
        handleReinvite,
        handleInviteCancel,
        handleTermination,
        handleTerminationRevert,
        handleDelete,
        handleSave,
        unsavedChanges,
        curValidity,
        setCurValidity,
        validities,
    } = useTalentEditState();
    const [mode, setMode] = useState(m || ETalentEditMode.basic);
    /**
     * change mode persistently
     */
    const changeMode = useCallback(
        (next: ETalentEditMode) => {
            navigate(`/profUser/${id}/${next}`, {
                replace: true,
            });
            setMode(next);
        },
        [id],
    );
    /**
     * display spinner during load
     */
    if (!profInfos) {
        return <Spinner />;
    }
    /**
     * render
     */
    return (
        <ProfileContext.Provider
            value={{
                curData,
                prevData,
                onChange,
                onValidityChange: (v, key) =>
                    setCurValidity((prev: any) => {
                        if (key) {
                            const next = {} as any;
                            next[key] = v;
                            return { ...prev, ...next };
                        }
                        return { ...prev, basic: v };
                    }),
            }}
        >
            <ScrollProvider style={style.paddedScrollableMainView}>
                <TalentHeadlineCard
                    {...{
                        profInfos,
                        prevProfileName,
                        chat,
                        handleReinvite,
                        handleTermination,
                        handleTerminationRevert,
                    }}
                />
                {!!inviteUrl && (
                    <CCard>
                        <View
                            style={[style.horizontalSplit, style.centeredItems]}
                        >
                            <View>
                                <CText message={inviteUrl} />
                            </View>
                            <View style={style.horizontal}>
                                <CButton
                                    icon={'close'}
                                    onPress={handleInviteCancel}
                                    mouseOverTitle={actionMessages.close}
                                    minor
                                    iconColor={theme.errorColor}
                                    transparent
                                    fontStyles={{ color: theme.errorColor }}
                                />
                                <CButton
                                    icon={'clipboardMulti_outline'}
                                    onPress={() => {
                                        Clipboard.setString(inviteUrl);
                                    }}
                                    minor
                                    transparent
                                />
                            </View>
                        </View>
                    </CCard>
                )}
                <CCard style={style.horizontal}>
                    <CButton
                        icon="account"
                        onPress={() => changeMode(ETalentEditMode.basic)}
                        minor={mode !== ETalentEditMode.basic}
                        small
                        style={style.rightPadded}
                    />
                    <CButton
                        title={profProfileMessages.professional}
                        onPress={() => changeMode(ETalentEditMode.professional)}
                        minor={mode !== ETalentEditMode.professional}
                        small
                        style={style.horizontalPadded}
                    />
                    <CButton
                        title={profProfileMessages.employment}
                        onPress={() => changeMode(ETalentEditMode.employment)}
                        minor={mode !== ETalentEditMode.employment}
                        small
                        style={style.horizontalPadded}
                    />
                    <CButton
                        title={profProfileMessages.documents}
                        onPress={() => changeMode(ETalentEditMode.documents)}
                        minor={mode !== ETalentEditMode.documents}
                        small
                        style={style.horizontalPadded}
                    />
                    <CButton
                        title={profProfileMessages.availabilitiesMode}
                        onPress={() =>
                            changeMode(ETalentEditMode.availabilities)
                        }
                        minor={mode !== ETalentEditMode.availabilities}
                        small
                        style={style.horizontalPadded}
                    />
                    <CButton
                        title={profProfileMessages.timesheetSection}
                        onPress={() => changeMode(ETalentEditMode.timesheets)}
                        minor={mode !== ETalentEditMode.timesheets}
                        small
                        style={style.horizontalPadded}
                    />
                    {!!curData.documentId && (
                        <CButton
                            onPress={() => {
                                secureNavigate(
                                    '/calendar?profileId=' + curData.documentId,
                                );
                            }}
                            icon={'calendar'}
                            small
                            minor
                        />
                    )}
                </CCard>
                {/* basic info edit for professional profiles */}
                {mode === ETalentEditMode.basic && (
                    <CCard>
                        {agencies.length > 1 && (
                            <View>
                                <CPicker
                                    values={agencies.map((agency) => {
                                        return {
                                            value: agency.documentId,
                                            label: agency.name,
                                        };
                                    })}
                                    onChange={(v) => {
                                        onChange({ agencyId: v });
                                    }}
                                    value={agency?.documentId}
                                />
                                {agency && (
                                    <View style={style.horizontal}>
                                        <CButton
                                            onPress={() => {
                                                secureNavigate(
                                                    '/agency/edit/' +
                                                        agency.documentId,
                                                );
                                            }}
                                            minor
                                            transparent
                                            title={
                                                profProfileMessages.openAgency
                                            }
                                        />
                                    </View>
                                )}
                            </View>
                        )}
                        {agencies.length === 1 && (
                            <View
                                style={[style.centeredItems, { width: '100%' }]}
                            >
                                <ProfilePicture data={agencies[0]} />
                                <CText
                                    secondaryHeadline
                                    message={agencies[0].name}
                                />
                                <View
                                    style={[
                                        style.paddedThinSeparator,
                                        { width: '100%' },
                                    ]}
                                />
                            </View>
                        )}
                        <ProfessionalBasic />
                    </CCard>
                )}
                {mode === ETalentEditMode.basic &&
                    (isAgencyUser(userData) || isSuperUser(userData)) && (
                        <CCard>
                            <View style={style.verticalPadded}>
                                <CText message={profProfileMessages.note} />
                            </View>
                            {editingNote ? (
                                <RichTextEditor
                                    text={note}
                                    onChange={setNote}
                                />
                            ) : (
                                <RichTextView html={note} />
                            )}
                            <View
                                style={[
                                    style.horizontalSpaced,
                                    style.verticalPadded,
                                ]}
                            >
                                {editingNote ? (
                                    <CButton
                                        cy={'save-note'}
                                        icon={'check'}
                                        title={format(
                                            profProfileMessages.finishEditing,
                                        )}
                                        onPress={() => {
                                            handleNoteSave().then(() => {
                                                setEditingNote(false);
                                            });
                                        }}
                                    />
                                ) : (
                                    <CButton
                                        cy={'edit-note'}
                                        title={format(
                                            profProfileMessages.editNote,
                                        )}
                                        onPress={() => {
                                            setEditingNote(true);
                                        }}
                                    />
                                )}
                            </View>
                        </CCard>
                    )}

                {mode === ETalentEditMode.basic && (
                    <CCard>
                        <TalentSectionAddress />
                    </CCard>
                )}
                {mode === ETalentEditMode.professional && (
                    <View
                        style={[
                            style.card,
                            !curData.educations.length && style.errorBorder,
                        ]}
                    >
                        <ProfessionalInformation />
                    </View>
                )}
                {mode === ETalentEditMode.professional && (
                    <View
                        style={[
                            style.card,
                            curValidity.lang && style.errorBorder,
                        ]}
                    >
                        <ProfessionalLanguage />
                    </View>
                )}
                {mode === ETalentEditMode.professional && (
                    <View style={[style.card, { zIndex: 1 }]}>
                        <ProfessionalHistory />
                    </View>
                )}
                {/* collapsable resume preview for created professional profiles */}
                {(mode === ETalentEditMode.professional ||
                    mode === ETalentEditMode.basic) &&
                    id !== 'new' && <ProfessionalResume />}
                {mode === ETalentEditMode.documents && id !== 'new' && agency && (
                    <View style={style.card}>
                        <ProfessionalDocuments agency={agency} />
                    </View>
                )}
                {mode === ETalentEditMode.timesheets && (
                    <CCard>
                        <ProfessionalTimesheetSection />
                    </CCard>
                )}
                {/* wage rates for professional profiles */}
                {mode === ETalentEditMode.employment && (
                    <View style={style.card}>
                        <ProfessionalWage
                            curData={profInfos}
                            prevData={prevProfInfos}
                            onChange={onChange}
                            onValidityChange={(v, key) =>
                                setCurValidity((prev: any) => {
                                    if (key) {
                                        const next = {} as any;
                                        next[key] = v;
                                        return { ...prev, ...next };
                                    }
                                    return { ...prev, basic: v };
                                })
                            }
                        />
                    </View>
                )}
                {mode === ETalentEditMode.employment && (
                    <View style={[style.card]}>
                        <ProfessionalEmployment />
                    </View>
                )}
                {!!curData.documentId &&
                    mode === ETalentEditMode.availabilities && (
                        <CCard
                            style={{ zIndex: 1 }}
                            outsideStyle={{ zIndex: 1 }}
                        >
                            <ProfessionalAvailabilities agency={agency} />
                        </CCard>
                    )}
                {/* watch for jobs check for professional profiles */}
                {mode === ETalentEditMode.employment && (
                    <View style={style.card}>
                        <CCheckBox
                            checked={curData.hasContract}
                            onCheckedChanged={(hasContract) => {
                                onChange({ hasContract, terminated: false });
                            }}
                            title={profProfileMessages.hasContract}
                            style={style.verticalPadded}
                        />
                        <CPicker
                            title={profProfileMessages.internalRating}
                            style={style.verticalPadded}
                            values={Array.from(Array(10)).map((_, i) => ({
                                value: i + 1,
                                label: `${i}`,
                            }))}
                            value={curData.internalRating + 1}
                            onChange={(v) => {
                                onChange({ internalRating: v - 1 });
                            }}
                        />
                        <CText
                            secondaryHeadline
                            message={
                                profProfileMessages.watchForNewJobsForThisProfile
                            }
                        />
                        <CText
                            message={
                                profProfileMessages.watchForNewJobsForThisProfileDescription
                            }
                        />
                        <CCheckBox
                            title={profProfileMessages.watchForNewJobs}
                            checked={profInfos.isWatching}
                            onCheckedChanged={(isWatching) =>
                                onChange({ isWatching })
                            }
                        />
                    </View>
                )}
                <View style={style.verticalPadded}>
                    <View style={style.horizontalSplit}>
                        <View />
                        <View style={{ alignItems: 'flex-end' }}>
                            {!!validities.length && (
                                <View style={{ marginHorizontal: 'auto' }}>
                                    {validities.map((v) => (
                                        <CText
                                            key={v.key as string}
                                            style={style.error}
                                        >
                                            {v.value}
                                        </CText>
                                    ))}
                                </View>
                            )}
                            {unsavedChanges && (
                                <CText style={style.warning}>
                                    {format(generalMessages.unsavedChanges)}
                                </CText>
                            )}
                            <View style={style.horizontal}>
                                {!!profInfos.documentId && (
                                    <CButton
                                        onPress={handleDelete}
                                        title={profProfileMessages.deleteTalent}
                                        danger
                                    />
                                )}
                                <CButton
                                    disabled={!!validities.length}
                                    onPress={() => handleSave(true)}
                                    title={actionMessages.save}
                                />
                                {/* {saveAndRegisterNewUser && isLocalhost() && (
                                <CButton
                                    disabled={!!validities.length}
                                    onPress={() =>
                                        saveAndRegisterNewUser(
                                            new MProfessionalProfile({
                                                ...profInfos,
                                            }),
                                        )
                                    }
                                    title={format(
                                        profProfileMessages.saveAndCreateAccount,
                                    )}
                                />
                            )} */}
                            </View>
                        </View>
                    </View>
                </View>
            </ScrollProvider>
        </ProfileContext.Provider>
    );
};
