import { ERegion } from '../enums';
import { MAddress } from './MAddress';
import { MBankDetails } from './MBankDetails';

export class MWorkplace {
    public documentId = '';

    public name = '';
    public picture = '';
    public website = '';
    public location = '';
    public address = new MAddress();
    public billingAddressDiffers = false;
    public billingAddress = new MAddress();
    public bankDetails = new MBankDetails();
    public phone = '';
    public mail = '';
    public numberOfEmployees = '';

    public leadMail = '';
    public leadName = '';

    public owner = '';
    public users: string[] = [];

    public defaultSignatureUser?: string;
    public signatureUsers: string[] = [];
    /**
     * preferred agencies
     */
    public agencies: string[] = [];

    public editedOn = Date.now();
    public createdOn = Date.now();
    public disabled = false;

    public region = ERegion.de;

    public averageRating = 0;
    public reviewCount = 0;

    constructor(params: Partial<MWorkplace> = {}) {
        Object.assign(this, params);

        if (params.bankDetails) {
            this.bankDetails = new MBankDetails(params.bankDetails);
        }

        if (params.address) {
            this.address = new MAddress(params.address);
        }
        if (params.billingAddress) {
            this.billingAddress = new MAddress(params.billingAddress);
        }
        if (params.owner && !params.users?.length) {
            this.users = [params.owner];
        }

        if ((params as any).beds) {
            this.numberOfEmployees = (params as any).beds;
        }
        if (params.address?.zipCode) {
            this.location = params.address.zipCode;
        }
    }
}
