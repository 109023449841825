import { useCallback, useContext } from 'react';
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useNavigate,
    useParams,
    useLocation,
    NavigateOptions,
    useSearchParams,
    Navigate,
} from 'react-router-dom';
import { SecureNavigateContext } from './SecureNavigateProvider';

export default Router;

interface ISecureNavigate extends NavigateOptions {
    force?: boolean;
}

const useSecureNavigate = () => {
    const navigate = useNavigate();

    const { preventNavigate, setNavigationLock, clearNavigationLock } =
        useContext(SecureNavigateContext);

    const secureNavigate = useCallback(
        async (to: string | number, options?: ISecureNavigate) => {
            if (options?.force || !(await preventNavigate())) {
                // if lock is broken remove it and navigate
                clearNavigationLock();
                navigate(to as any, options);
            }
        },
        [preventNavigate],
    );

    return { secureNavigate, setNavigationLock, clearNavigationLock };
};

export {
    Link,
    Navigate,
    Route,
    Routes,
    useNavigate,
    useParams,
    useLocation,
    useSecureNavigate,
    useSearchParams,
};
