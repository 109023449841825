import { ERegion } from '../../enums';
/**
 * get currency symbol for given region
 * @param region region to get currency symbol for
 * @returns symbol string
 */
export const getCurrencySymbol = (region: ERegion) => {
    switch (region) {
        case ERegion.de:
            return '€';
        case ERegion.za:
            return 'R';
    }
};
/**
 * convert a number to a price string
 * @param number
 * @returns
 */
export const convertNumberToCurrencyString = (
    number: number,
    region = ERegion.de,
) =>
    `${Number(number).toFixed(2)} ${getCurrencySymbol(region)}`.replace(
        '.',
        ',',
    );
/**
 * convert a price string to a number if not in currency format removes last digit
 * (probably removed it to delete last digit instead)
 * @param s
 * @returns
 */
export const convertCurrencyStringToNumber = (
    s: string,
    region = ERegion.de,
) => {
    const strippedS = s.replace(/\D/g, '');

    const next = !s.includes(getCurrencySymbol(region))
        ? strippedS.substring(0, strippedS.length - 1)
        : strippedS;
    return Number(next) / 100;
};
