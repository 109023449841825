import React, { FC, useEffect, useMemo, useState } from 'react';
import { Linking, View } from 'react-native';
import {
    CButton,
    CCard,
    CIcon,
    CImage,
    CText,
    WageView,
} from '../../../../components';
import { MContract, MReview } from '../../../../models';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { jobMessages } from '../../../Job/job.messages';
import { isSuperUser } from '../../../../utilities/auth';
import { useFireBase } from '../../../../utilities/firebase';
import { ContractTitle } from '../../components/ContractTitle';
import { ContractState } from '../../components/ContractState';
import { useCollectionIndex } from '../../../../utilities/firebase/store/useIndex';
import { ECollections, EUserType } from '../../../../enums';
import { useOpenReviewDialog } from '../../View/functions/openReviewDialog';
import { contractMessages } from '../../contract.messages';

interface IContractHistoryRowProps {
    contract: MContract;
    embedded?: boolean;
    small?: boolean;
    highlight?: boolean;
}

export const ContractHistoryRow: FC<IContractHistoryRowProps> = ({
    contract,
    embedded,
    small,
    highlight,
}) => {
    const style = useStyle();
    const { userData, callFunction, getFileDownloadUrl } = useFireBase();
    const { theme } = useTheme();
    /**
     * review collection of interest based on usertype
     * to check if there is a valid review in it
     */
    const reviewCollectionOI = useMemo(
        () =>
            (userData.type === EUserType.employer
                ? contract.agencyId
                    ? `${ECollections.agencies}/${contract.agencyId}`
                    : `${ECollections.users}/${contract.employeeId}`
                : `${ECollections.workplaces}/${contract.workplaceId}`) +
            `/${ECollections.reviews}`,
        [contract, userData],
    );
    /**
     * param options to filter review collection of interest for
     */
    const reviewParamOptions = useMemo(
        () => ({
            filter: [
                {
                    field: 'contractId',
                    value: contract.documentId,
                },
            ],
        }),
        [contract],
    );
    /**
     * live review data for given filters
     */
    const reviewData = useCollectionIndex(
        reviewCollectionOI,
        reviewParamOptions,
    );
    /**
     * callback to open review dialog
     */
    const openReview = useOpenReviewDialog(contract);
    /**
     * render
     */
    return (
        <CCard
            cy={'contract-' + contract.jobId}
            style={highlight && style.accentBorder}
            embedded={embedded}
        >
            <View style={style.horizontalSplit}>
                <View
                    style={[style.horizontal, style.centeredItems, style.flex1]}
                >
                    <View style={style.flex1}>
                        <ContractTitle contract={contract} />
                        <View style={[style.horizontal, style.centeredItems]}>
                            <CIcon
                                icon="calendar"
                                tint={theme.textSecondaryColor}
                                size={14}
                            />
                            <CText message={jobMessages.fromTo} />
                            <CText style={style.leftPadded}>
                                {/* {new Date(
                                    contract.createdOn,
                                ).toLocaleDateString('de')} */}
                                {`${new Date(contract.from).toLocaleDateString(
                                    'de',
                                )} - ${new Date(contract.to).toLocaleDateString(
                                    'de',
                                )}`}
                            </CText>
                        </View>
                    </View>
                </View>
                <View>
                    <View
                        style={[
                            {
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                            },
                        ]}
                    >
                        {!reviewData.length && (
                            <CButton
                                title={contractMessages.provideReview}
                                onPress={openReview}
                                icon="star"
                                minor
                                small
                                transparent
                            />
                        )}
                        {isSuperUser(userData) && (
                            <View
                                style={[
                                    style.verticalPadded,
                                    style.horizontalPadded,
                                ]}
                            >
                                <CImage
                                    image={contract.region}
                                    fixedHeight={20}
                                />
                            </View>
                        )}
                        <ContractState contract={contract} />
                    </View>
                    <View
                        style={[
                            {
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                            },
                        ]}
                    >
                        <CButton
                            onPress={() => {
                                callFunction('getContractZip', {
                                    contractId: contract.documentId,
                                }).then((path) =>
                                    getFileDownloadUrl(path).then((url) =>
                                        Linking.openURL(url),
                                    ),
                                );
                            }}
                            icon="download"
                            minor
                            small
                            transparent
                        />
                    </View>
                </View>
            </View>
            {!small && (
                <View
                    style={[
                        style.verticalPadded,
                        style.horizontalSplit,
                        style.centeredItems,
                    ]}
                >
                    <View style={[style.horizontal]}>
                        <WageView value={contract.wage} horizontal fragment />
                    </View>
                    {/* <View>
                        <CText bold>{format(jobMessages.fromTo)}</CText>
                        <CText>
                            {`${new Date(contract.from).toLocaleDateString(
                                'de',
                            )} - ${new Date(contract.to).toLocaleDateString(
                                'de',
                            )}`}
                        </CText>
                    </View> */}
                </View>
            )}
        </CCard>
    );
};
