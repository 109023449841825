import { EEmploymentType, EField, EProfession, ERegion } from '../enums';
import {
    convertWeekAndYearToDate,
    day,
    getWeekNumber,
    week,
} from '../utilities/functions';

export interface IDayAvailability {
    from: number;
    fromMinute: number;
    to: number;
    toMinute: number;
    inheriting: boolean;
    day: number;
}

export class MAvailability {
    public documentId = '';
    public uid = '';
    public agencyId?: string;
    public profileId?: string;
    public employmentType = EEmploymentType.personnelLeasing;
    public negate = false;
    public title = '';
    /**
     * 0 - 24
     */
    public from = 0;
    /**
     * 0 - 59
     */
    public fromMinute = 0;
    /**
     * from - from + 24
     */
    public to = 24;
    /**
     * 0 - 59
     */
    public toMinute = 0;
    /**
     * 1 - 7
     */
    public days: IDayAvailability[] = [];
    /**
     * start calendar week (kw kalenderwoche)
     */
    public start = 0;
    /**
     * start year
     */
    public year = 2022;
    /**
     * reflection of exact selected fromdate
     */
    public exactStartTime = 0;
    /**
     * reflection of exact selected todate
     */
    public exactEndTime = 0;

    public repeat = false;
    public repeatCount = 0;

    public note = '';

    public field = EField.undefined;
    public profession = EProfession.undefined;
    public educations: string[] = [];
    public region = ERegion.de;

    public activeDates: string[] = [];
    public activeMonths: number[] = [];
    public january?: boolean;
    public february?: boolean;
    public march?: boolean;
    public april?: boolean;
    public may?: boolean;
    public june?: boolean;
    public july?: boolean;
    public august?: boolean;
    public september?: boolean;
    public october?: boolean;
    public november?: boolean;
    public december?: boolean;
    public activeYears?: any;

    public editedOn = Date.now();
    public createdOn = Date.now();
    public deletedOn = 0;
    public deleted = false;

    constructor(params: Partial<MAvailability> = {}) {
        Object.assign(this, params);

        MAvailability.fixConstructor(this, params);
    }

    static fixConstructor(that: MAvailability, params: Partial<MAvailability>) {
        if (params.exactStartTime && params.exactEndTime) {
            const from = params.exactStartTime;
            const to = params.exactEndTime;
            const nextActiveDates: string[] = [];
            const nextActiveMonths: number[] = [];
            const nextActiveYears: number[] = [];
            let pivot = from;
            const toDate = new Date(to);
            toDate.setDate(toDate.getDate() + 1);
            const end = toDate.getTime();
            while (pivot < end) {
                const curDate = new Date(pivot);
                const standardWeekDay = curDate.getDay();
                const curWeekDay = !standardWeekDay ? 7 : standardWeekDay;
                const dayTime = that.days.find((d) => d.day === curWeekDay);
                if (!that.days.length || dayTime) {
                    nextActiveDates.push(curDate.toLocaleDateString('de'));
                    const curMonth = curDate.getMonth();
                    const curYear = curDate.getFullYear();
                    if (!nextActiveMonths.includes(curMonth)) {
                        nextActiveMonths.push(curMonth);
                    }
                    if (!nextActiveYears.includes(curYear)) {
                        nextActiveYears.push(curYear);
                    }
                }
                curDate.setDate(curDate.getDate() + 1);
                pivot = curDate.getTime();
            }

            that.activeDates = nextActiveDates;
            that.activeMonths = nextActiveMonths;
            that.activeYears = nextActiveYears.reduce((acc, y) => {
                acc[`y${y}`] = true;
                return acc;
            }, {} as any);
        } else if (params.days && params.start && params.year) {
            /**
             * legacy active dates
             */
            const nextActiveDates: string[] = [];
            const nextActiveMonths: number[] = [];
            const nextActiveYears: number[] = [];
            const startDate = new Date(
                convertWeekAndYearToDate(params.year, params.start),
            );
            for (const d of params.days) {
                const dayOffset = (d.day - 1) * day;
                let weekStartDate = new Date(startDate.getTime());
                [
                    0,
                    ...(params.repeatCount && params.repeatCount > 0
                        ? Array.from(Array(params.repeatCount)).map(
                              (_, i) => i + 1,
                          )
                        : []),
                ].forEach(() => {
                    const wn = getWeekNumber(new Date(weekStartDate));
                    const wyear = new Date(weekStartDate).getFullYear();
                    const tempDayDate = new Date(
                        convertWeekAndYearToDate(wyear, wn).getTime() +
                            dayOffset,
                    );
                    // skip if from to is supplied && date outside of from to
                    if (
                        !(
                            (params.exactStartTime &&
                                tempDayDate.getTime() <
                                    (params.exactStartTime as number)) ||
                            (params.exactEndTime &&
                                tempDayDate.getTime() >
                                    (params.exactEndTime as number))
                        )
                    ) {
                        nextActiveDates.push(
                            tempDayDate.toLocaleDateString('de'),
                        );
                        const curMonth = tempDayDate.getMonth();
                        const curYear = tempDayDate.getFullYear();

                        if (!nextActiveMonths.includes(curMonth)) {
                            nextActiveMonths.push(curMonth);
                        }

                        if (!nextActiveYears.includes(curYear)) {
                            nextActiveYears.push(curYear);
                        }
                    }

                    weekStartDate = new Date(
                        convertWeekAndYearToDate(wyear, wn).getTime() +
                            week * 1.5,
                    );
                });
            }

            that.activeDates = nextActiveDates;
            that.activeMonths = nextActiveMonths;

            that.activeYears = nextActiveYears.reduce((acc, y) => {
                acc[`y${y}`] = true;
                return acc;
            }, {} as any);
        }
        that.january = that.activeMonths.includes(0);
        that.february = that.activeMonths.includes(1);
        that.march = that.activeMonths.includes(2);
        that.april = that.activeMonths.includes(3);
        that.may = that.activeMonths.includes(4);
        that.june = that.activeMonths.includes(5);
        that.july = that.activeMonths.includes(6);
        that.august = that.activeMonths.includes(7);
        that.september = that.activeMonths.includes(8);
        that.october = that.activeMonths.includes(9);
        that.november = that.activeMonths.includes(10);
        that.december = that.activeMonths.includes(11);
        /**
         * fix days without minutes
         */
        if (params.days) {
            that.days = params.days.map((d) => ({
                fromMinute: 0,
                toMinute: 0,
                ...(d as any),
            }));
        }
    }
}
