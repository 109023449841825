/**
 * check for empty string
 * @param input string
 * @returns if string is empty or complete gibberish
 */
const stringIsEmpty = (input: string): boolean => {
    return !input || input.trim().length === 0;
};
/**
 * capitalize first char
 * @param input a string
 * @returns A string
 */
export const capitalize = (input: string): string => {
    if (stringIsEmpty(input)) {
        return '';
    }
    return input.charAt(0).toUpperCase() + input.slice(1);
};
/**
 * all uppercase
 * @param input string
 * @returns caps locked string
 */
export const capitalizeAll = (input: string): string => {
    if (stringIsEmpty(input)) {
        return '';
    }
    return input.toUpperCase();
};
