import { defineMessages } from 'react-intl';

const scope = 'action.';

export const actionMessages = defineMessages({
    login: {
        id: scope + 'login',
        defaultMessage: 'Login',
    },
    logout: {
        id: scope + 'logout',
        defaultMessage: 'Logout',
    },
    register: {
        id: scope + 'register',
        defaultMessage: 'Register',
    },
    ok: {
        id: scope + 'ok',
        defaultMessage: 'Ok',
    },
    edit: {
        id: scope + 'edit',
        defaultMessage: 'Edit',
    },
    back: {
        id: scope + 'back',
        defaultMessage: 'Back',
    },
    continue: {
        id: scope + 'continue',
        defaultMessage: 'Continue',
    },
    continueAndDiscard: {
        id: scope + 'continueAndDiscard',
        defaultMessage: 'Continue and discard',
    },
    continueAndSave: {
        id: scope + 'continueAndSave',
        defaultMessage: 'Continue and save',
    },
    skip: {
        id: scope + 'skip',
        defaultMessage: 'Skip',
    },
    save: {
        id: scope + 'save',
        defaultMessage: 'Save',
    },
    apply: {
        id: scope + 'apply',
        defaultMessage: 'Apply',
    },
    delete: {
        id: scope + 'delete',
        defaultMessage: 'Delete',
    },
    cancel: {
        id: scope + 'cancel',
        defaultMessage: 'Cancel',
    },
    loadMore: {
        id: scope + 'loadMore',
        defaultMessage: 'Load more',
    },
    create: {
        id: scope + 'create',
        defaultMessage: 'Create',
    },
    repeat: {
        id: scope + 'repeat',
        defaultMessage: 'Repeat',
    },
    send: {
        id: scope + 'send',
        defaultMessage: 'Send',
    },
    test: {
        id: scope + 'test',
        defaultMessage: 'Test',
    },
    confirm: {
        id: scope + 'confirm',
        defaultMessage: 'Confirm',
    },
    close: {
        id: scope + 'close',
        defaultMessage: 'Close',
    },
    sign: {
        id: scope + 'sign',
        defaultMessage: 'Sign',
    },
    bookNow: {
        id: scope + 'bookNow',
        defaultMessage: 'Book now',
    },
    later: {
        id: scope + 'later',
        defaultMessage: 'Later',
    },
    accept: {
        id: scope + 'accept',
        defaultMessage: 'Accept',
    },
    reject: {
        id: scope + 'reject',
        defaultMessage: 'Reject',
    },
    verify: {
        id: scope + 'verify',
        defaultMessage: 'Verify',
    },
    submitForm: {
        id: scope + 'submitForm',
        defaultMessage: 'Submit form',
    },
    markAllAsRead: {
        id: scope + 'markAllAsRead',
        defaultMessage: 'Mark all as read',
    },
    open: {
        id: scope + 'open',
        defaultMessage: 'Open',
    },
    download: {
        id: scope + 'download',
        defaultMessage: 'Download',
    },
    share: {
        id: scope + 'share',
        defaultMessage: 'Share',
    },
    remove: {
        id: scope + 'remove',
        defaultMessage: 'Remove',
    },
    undo: {
        id: scope + 'undo',
        defaultMessage: 'Undo',
    },
    expose: {
        id: scope + 'expose',
        defaultMessage: 'Expose',
    },
});
