import React, { FC, useContext } from 'react';
import { AddressInput } from '../../../../components/AddressInput';
import { CText } from '../../../../components';
import { useFormat } from '../../../../utilities/intl';
import { useStyle } from '../../../../utilities/styles';
import { EUserType } from '../../../../enums';
import { ProfileContext } from '../../../Profile/Profile.context';
import { profileMessages } from '../../../Profile/Profile.messages';

export const TalentSectionAddress: FC = () => {
    // global state
    const style = useStyle();
    const format = useFormat();
    const { curData, curUserData, onChange } = useContext(ProfileContext);
    /**
     * memoized travel level
     */
    // const w = useMemo(() => {
    //     if (curData.willingToTravel && !curData.notWillingToTravel) {
    //         return 3;
    //     } else if (!curData.willingToTravel && !curData.notWillingToTravel) {
    //         return 2;
    //     } else if (!curData.willingToTravel && curData.notWillingToTravel) {
    //         return 1;
    //     }
    // }, [curData]);
    /**
     * effect to clear validity
     */
    // useEffect(() => {
    //     const cadr = curData.address;
    //     onValidityChange(
    //         !cadr.lng || !cadr.lat
    //             ? format(onboardingMessages.provideAddress)
    //             : '',
    //     );
    // }, [curData]);
    /**
     * render
     */
    return (
        <>
            {curUserData?.type === EUserType.user && (
                <CText style={style.verticalPadded}>
                    {format(profileMessages.addressExplanationUser)}
                </CText>
            )}
            <AddressInput
                cy={'talent-address'}
                title={format(profileMessages.address)}
                address={curData.address}
                setAddress={(address) => onChange({ address })}
            />
        </>
    );
};
